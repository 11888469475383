import React from 'react'

import { useGetDiscountSofteningFreeDeliveryFromPromoCode } from 'hooks/useGetDiscountSofteningFreeDeliveryFromPromoCode'
import { useBasket } from 'routes/Menu/domains/basket'

import { Welcome } from './Welcome'

const WelcomeWrapper = (ownProps: any) => {
  const { addRecipe } = useBasket()
  // BEET-224: OFX Discount Softening Free Delivery
  const discountSofteningFreeDeliveryValue = useGetDiscountSofteningFreeDeliveryFromPromoCode()

  return (
    <Welcome
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      addRecipeToBasket={addRecipe}
      discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
    />
  )
}

export { WelcomeWrapper }
