// Regular expressions for validating and sanitizing UK-specific data fields.
//
// Valid Character Regex Patterns:
//
// Validates names: Permits letters (a-z, A-Z, European characters), hyphens, apostrophes (straight and curly),
// separated by spaces. Supports optional leading/trailing spaces.
const validNamePattern = new RegExp(
  "^(\\s)*[a-zA-ZÀ-ž]{1,}([\\-|\\'|\\‘][a-zA-ZÀ-ž]{1,}){0,1}(\\s[a-zA-ZÀ-ž]{1,}([\\-|\\'|\\‘][a-zA-ZÀ-ž]{1,}){0,1}){0,}(\\s)*$",
)

// Invalid Character Regex Patterns:
//
// Patterns to identify invalid characters in address fields, aiming to ensure data adheres to valid formats
// by excluding special characters. The regex specifies allowed characters on the left, and disallowed characters
// on the right.
//
// Note: Scurri.com has specific character restrictions for address fields: /, (, ), -, ’, ‘, !
// These are excluded to maintain data integrity and ensure compatibility with Scurri's systems.
//
// Identifies invalid characters in UK postcodes: Excludes non-alphanumeric characters except spaces,
// specifically disallowing characters as per Scurri's requirements and to manage regex special characters.
export const invalidUkPostcodePattern = new RegExp('[^a-zA-Z0-9 ]|[/‘’!%$()*+#"\\\\-]', 'g')

// Identifies invalid characters in UK place names: Allows alphanumeric characters, spaces, apostrophes,
// ampersands, periods, and commas, explicitly excluding characters to comply with Scurri's requirements
// and to correctly interpret regex special characters.
export const invalidUkPlaceNamePattern = new RegExp('[^a-zA-Z0-9 \'&.,]|[/‘’!%$()*+#"\\\\-]', 'g')

export const regularExpressions = {
  name: validNamePattern,
}

export const restrictedRegularExpressions = {
  postcode: invalidUkPostcodePattern,
  houseNo: invalidUkPlaceNamePattern,
  street: invalidUkPlaceNamePattern,
  town: invalidUkPlaceNamePattern,
}
