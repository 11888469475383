import React, { useEffect, memo } from 'react'

import Immutable from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import recipesActions from 'actions/recipes'
import { getBasketRecipes } from 'selectors/basket'
import { getRecipes } from 'selectors/root'

import { OrderedRecipe } from './OrderedRecipe/OrderedRecipe'

export const RecipeSummary = memo(() => {
  const dispatch = useDispatch()

  const menuRecipesStore = useSelector(getRecipes)
  const basketRecipes = useSelector(getBasketRecipes)

  useEffect(() => {
    if (menuRecipesStore && menuRecipesStore.size === 0) {
      const orderRecipeIds =
        basketRecipes && basketRecipes.size ? basketRecipes.keySeq().toArray() : []

      dispatch(recipesActions.recipesLoadRecipesById(orderRecipeIds))
    }
  }, [basketRecipes, menuRecipesStore, dispatch])

  return (
    <div data-testing="checkoutRecipeSummary">
      {basketRecipes
        .map((_: number, recipeId: string) => (
          <OrderedRecipe
            // eslint-disable-next-line react/no-array-index-key
            key={recipeId}
            title={menuRecipesStore.getIn([recipeId, 'title'], '')}
            media={menuRecipesStore.getIn(
              [recipeId, 'media', 'images', 0, 'urls'],
              Immutable.List([]),
            )}
            isFineDineIn={menuRecipesStore.getIn([recipeId, 'isFineDineIn'], false)}
          />
        ))
        .toArray()}
    </div>
  )
})

RecipeSummary.displayName = 'RecipeSummary'
