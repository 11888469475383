import React from 'react'

import { Login } from 'Login'
import ModalPanel from 'Modal/ModalPanel'
import Overlay from 'Overlay'
import { useDispatch, useSelector } from 'react-redux'

import css from 'routes/Checkout/Checkout.css'
import { closeLoginModal } from 'routes/Checkout/checkoutActions'

export const LoginModal = () => {
  const isLoginOpened = useSelector((state: any) => state.loginVisibility.get('login'))
  const dispatch = useDispatch()

  return (
    <Overlay open={isLoginOpened} contentClassName={css.modalOverlay} from="top">
      <ModalPanel
        closePortal={() => dispatch(closeLoginModal())}
        className={css.modal}
        containerClassName={css.modalContainer}
        disableOverlay
        isNarrow
      >
        <Login />
      </ModalPanel>
    </Overlay>
  )
}
