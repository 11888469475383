export const CTA_TEXT = {
  SKIP: 'Skip',
  CONTINUE: 'Continue',
}

export const DIETARY_PREFERENCES_TEXT = {
  title: 'Any dietary preferences?',
  description:
    'Let us know about any dietary preferences in your household and we’ll show you more suitable recipes first.',
  notice: 'Don’t worry you’ll still get to see our full menu!',
  NO_DIETARY_PREFERENCES_LABEL: 'We can eat everything',
  DIETARY_PREFERENCES_LABEL: 'We have dietary preferences',
  SELECT_ONE_OR_MORE_LABEL: 'Select at least one option to continue',
}

export const ORDERED_DIETARY_PREFERENCES = [
  {
    id: 'vegetarian',
    label: 'Vegetarian',
  },
  {
    id: 'plant-based',
    label: 'Plant-Based',
  },
  {
    id: 'pescatarian',
    label: 'Pescatarian',
  },
  {
    id: 'dairy-free',
    label: 'Dairy-Free',
  },
  {
    id: 'gluten-free',
    label: 'Gluten-Free',
  },
  {
    id: 'no-seafood',
    label: 'No Seafood',
  },
]
