import React from 'react'

import {
  AlignItems,
  Body3,
  Box,
  Display,
  FlexDirection,
  Heading4,
  Join,
  Space,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { MenuRecipeIngredient } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { RecipeAttributePill } from 'routes/Menu/components/RecipeAttributePill/RecipeAttributePill'
import { getNumPortions } from 'selectors/basket'

import { Ingredient } from './Ingredient'

import css from './Ingredients.css'

export type Basic = {
  name: string
  slug: string
}

type IngredientsProps = {
  ingredients: MenuRecipeIngredient[]
  loading: boolean
  basics: Basic[]
}

const LoadingSkeleton = ({ style }: { style?: React.CSSProperties }) => (
  <span className={css.loadingSkeleton} style={style} data-testid="LoadingSkeleton" />
)

const Ingredients = ({ ingredients, loading, basics }: IngredientsProps) => {
  const numPortion = useSelector(getNumPortions)
  const people = numPortion > 1 ? 'people' : 'person'

  return (
    <Box>
      <Box>
        <Join with={<Space size={2} />}>
          <Heading4>Ingredients</Heading4>
          <Body3>
            Ingredients shown are for {numPortion} {people}. For allergens see the &apos;Ingredients
            contain&apos; section.
          </Body3>
        </Join>
      </Box>
      <Box paddingV={3}>
        <Join with={<Space size={loading ? 4 : 2} />}>
          {ingredients
            .filter((ingredient) => {
              if (loading) return true
              const quantity = ingredient.quantities?.in_meal ?? 0

              return quantity > 0
            })
            .map((ingredient) => (
              <Box
                key={ingredient && ingredient.id}
                display={Display.Flex}
                flexDirection={FlexDirection.Column}
              >
                {ingredient && <Ingredient ingredient={ingredient} loading={loading} />}
              </Box>
            ))}
        </Join>
      </Box>
      <Box>
        <Join with={<Space size={1} />}>
          <Heading4>Ingredients (or dietary alternatives) not included:</Heading4>
          {loading ? (
            <Box>
              {[...Array(4)].map(() => (
                <LoadingSkeleton
                  key={Math.random()}
                  style={{
                    width: '90px',
                    height: '30px',
                    marginBottom: '10px',
                    marginRight: '5px',
                    borderRadius: '40px',
                  }}
                />
              ))}
            </Box>
          ) : (
            basics?.length > 0 && (
              <Box alignItems={AlignItems.FlexStart} wrap display={Display.Flex}>
                {basics.map((basic) => (
                  <RecipeAttributePill key={basic.slug} text={basic.name} />
                ))}
              </Box>
            )
          )}
        </Join>
      </Box>
    </Box>
  )
}

export { Ingredients }
