import actions from 'actions'
import { connect } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { boxSummaryDeliveryDaysLoad } from 'actions/boxSummary'
import { applyPromoCodeAndShowModal } from 'actions/home'
import { withIsActionBarRedesignEnabled } from 'routes/Menu/components/withIsActionBarRedesignEnabled'
import { getPromoCode } from 'selectors/basket'

import { MenuWrapper } from './MenuWrapper'
import { menuCalculateTimeToUsable } from './actions/menuCalculateTimeToUsable'
import fetchData from './fetchData'

function mapStateToProps(state, ownProps) {
  const query = ownProps.location && ownProps.location.query
  const showOverlay = state.boxSummaryShow.get('show') || state.menuBrowseCTAShow

  return {
    showOverlay,
    boxSummaryDeliveryDays: state.boxSummaryDeliveryDays,
    query: query || {},
    params: ownProps.params,
    disabled: state.auth.get('isAdmin'),
    isAuthenticated: state.auth.get('isAuthenticated'),
    tariffId: state.basket.get('tariffId'),
    menuLoadingBoxPrices: state.pending.get(actionTypes.MENU_BOX_PRICES_RECEIVE, false),
    postcode: state.basket.get('postcode'),
    hasBasketPromo: Boolean(getPromoCode(state)),
    hasMenuError: Boolean(state.error.get('MENU_FETCH_DATA')),
  }
}

const mapDispatchToProps = {
  menuLoadBoxPrices: actions.menuLoadBoxPrices,
  loginVisibilityChange: actions.loginVisibilityChange,
  basketNumPortionChange: actions.basketNumPortionChange,
  boxSummaryDeliveryDaysLoad,
  menuCalculateTimeToUsable,
  fetchData,
  applyPromoCodeAndShowModal,
}

const MenuContainer = withIsActionBarRedesignEnabled(
  connect(mapStateToProps, mapDispatchToProps)(MenuWrapper),
)

export { MenuContainer }
