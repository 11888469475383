import { CSSObject } from '@emotion/react'

import { colors, screen, typography } from '../../styles'

export const cssRecipeTile: CSSObject = {
  width: '100%',
  height: '100%',
}

export const cssRecipeTileContainer: CSSObject = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.white,
  color: colors.blackberry,
  position: 'relative',
  borderRadius: '4px',
  boxShadow: '0 1px 5px 0 rgba(51, 61, 71, 0.2)',

  '&:nth-of-type(2n)': {
    [`@media ${screen.screenSMMin} and ${screen.screenSMMax}`]: {
      marginRight: '0',
    },
  },

  '&:nth-of-type(3n)': {
    [`@media ${screen.screenMDMin} and ${screen.screenMDMax}`]: {
      marginRight: '0',
    },
  },

  '&:nth-of-type(4n)': {
    [`@media ${screen.screenLGMin}`]: {
      marginRight: '0',
    },
  },

  '&:hover': {
    boxShadow: '0 4px 14px 0 rgba(51, 61, 71, 0.2)',
    cursor: 'pointer',
  },
}

export const cssVariantPushDown: CSSObject = {
  paddingTop: '30px',
  [`@media ${screen.screenSMMin}`]: {
    paddingTop: '0',
  },
}

export const cssRecipeTileIsFineDineIn: CSSObject = {
  background: colors.blackberry,
  color: colors.white,
}

export const cssVariantDropdownContainer: CSSObject = {
  width: '100%',
  position: 'absolute',
  top: '98%',
  right: '0',
  zIndex: '150',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '3px',
  padding: '0.5rem',
  backgroundColor: colors.white,

  [`@media ${screen.screenSMMin}`]: {
    width: '112%',
    right: '-6%',
  },

  [`@media ${screen.screenMDMin}`]: {
    width: '112%',
    right: '-6%',
  },

  [`@media ${screen.screenLGMin}`]: {
    width: '112%',
    right: '-6%',
  },
}

export const cssRecipeTileTitle: CSSObject = {
  marginBottom: '1rem',
  flex: '1',
  fontFamily: typography.axiformaFontStack,
  fontSize: typography.sizeXS,
  fontWeight: '600',
  lineHeight: '1.6',
  [`@media ${screen.screenSMMin}`]: {
    fontSize: typography.sizeMD,
  },
}

export const cssRecipeTagHolder: CSSObject = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  display: 'flex',
}

export const cssAddRecipeButtonContainer: CSSObject = {
  position: 'absolute',
  // we need extra 1 rem because container element has 1 rem margin
  transform: 'translateY(calc(-50% - 1rem))',
  top: 0,
  right: 0,
  width: '48px',
  height: '48px',
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 12,
}
