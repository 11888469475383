import React from 'react'

import { Box, FlexDirection } from '@gousto-internal/citrus-react'

import { CARD_DETAILS } from 'routes/Checkout/checkoutConfig'

import { CardHolderName } from './components/CardHolderName/CardHolderName'
import { FrameField } from './components/FrameField/FrameField'

type CheckoutFrameProps = {
  formValidationError: Record<string, any>
  setHostedFieldsFrameLoaded?: (value: boolean) => void
}

export const CheckoutFrame = ({
  formValidationError,
  setHostedFieldsFrameLoaded,
}: CheckoutFrameProps) => (
  <>
    <FrameField
      header="Card number"
      hasLockIcon
      dataFrames={CARD_DETAILS.CARD_NUMBER}
      errorDataTesting="checkoutFrameCardNumberError"
      showError={formValidationError.showCardNumberError}
      errorMessage="Please enter a valid card number"
      setHostedFieldsFrameLoaded={setHostedFieldsFrameLoaded}
    />
    <Box data-testid="checkoutCardHolderName" paddingV={1}>
      <CardHolderName />
    </Box>
    <Box paddingV={2} display="flex" flexDirection={FlexDirection.Row}>
      <Box paddingRight={2}>
        <FrameField
          header="Expiry (MM/YY)"
          dataFrames={CARD_DETAILS.EXPIRY_DATE}
          errorDataTesting="checkoutFrameExpiryError"
          showError={formValidationError.showExpiryDateError}
          errorMessage="Please enter a valid expiry date"
        />
      </Box>
      <FrameField
        header="Security code (CVV)"
        dataFrames={CARD_DETAILS.CVV}
        errorDataTesting="checkoutFrameCVVError"
        showError={formValidationError.showCVVError}
        errorMessage="Please enter a valid CVV code"
      />
    </Box>
  </>
)
