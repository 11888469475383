import React, { PureComponent } from 'react'

import { Space, ScreenReaderLiveRegion } from '@gousto-internal/citrus-react'
import classNames from 'classnames'
import { StepIndicator } from 'goustouicomponents'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ReactReduxContext } from 'react-redux'

import { AVAILABLE_STEP_COMPONENTS } from 'routes/Signup/constants/AvailableStepComponents'
import { signupConfig } from 'routes/Signup/signupConfig'
import { stepByName } from 'routes/Signup/signupUtils'
import { fetchSignupData } from 'routes/Signup/utils/fetchSignupData'
import { openProperStep } from 'routes/Signup/utils/openProperStep'

import { DiscountAppliedBar } from '../../components/DiscountAppliedBar/DiscountAppliedBar'
import { ApplyVoucherPageContainer } from './Components/ApplyVoucherPage'
import { CheckAccountPageContainer } from './Components/CheckAccountPage'
import { EnterPromoCodeManuallyPage } from './Components/EnterPromoCodeManuallyPage'
import { SellThePropositionPage } from './Components/SellThePropositionPage/SellThePropositionPage'
import { getSignupSteps } from './utils/getSignupSteps'

import css from './Signup.css'

const propTypes = {
  secondarySlug: PropTypes.string,
  stepNames: PropTypes.instanceOf(Immutable.List),
  goToStep: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      promo_code: PropTypes.string,
    }),
    pathname: PropTypes.string,
  }),
  params: PropTypes.shape({
    secondarySlug: PropTypes.string,
  }),
  promoModalVisible: PropTypes.bool.isRequired,
  trackDiscountVisibility: PropTypes.func,
  isDiscountAppliedBarDismissed: PropTypes.bool,
  signupDismissDiscountAppliedBar: PropTypes.func,
  signupSetStep: PropTypes.func,
  signupStepsReceive: PropTypes.func.isRequired,
  discountTip: PropTypes.string,
  isDiscountEnabled: PropTypes.bool,
  isMenuPersonalisationEnabled: PropTypes.bool,
}

const defaultProps = {
  secondarySlug: null,
  stepNames: null,
  goToStep: () => {},
  location: {
    query: {
      promo_code: '',
    },
  },
  params: {
    secondarySlug: '',
  },
  trackDiscountVisibility: () => {},
  isDiscountAppliedBarDismissed: false,
  signupDismissDiscountAppliedBar: () => {},
  signupSetStep: () => {},
  discountTip: '',
  isDiscountEnabled: false,
  isMenuPersonalisationEnabled: false,
}

class Signup extends PureComponent {
  static fetchData = fetchSignupData

  async componentDidMount() {
    const { signupStepsReceive, isMenuPersonalisationEnabled } = this.props

    const stepNames = getSignupSteps(isMenuPersonalisationEnabled)
    signupStepsReceive(stepNames)
  }

  componentDidUpdate(prevProps) {
    const { location, params, stepNames } = this.props
    const { store } = this.context

    if (prevProps.stepNames !== stepNames && stepNames !== null) {
      openProperStep(store, stepNames, location?.query, params)
    }
  }

  getCurrentStepNumber(steps) {
    const { secondarySlug } = this.props
    const stepNumber = steps.findIndex((step) => step.get('slug') === secondarySlug)

    if (stepNumber < 0) {
      return 0
    }

    return stepNumber
  }

  getSteps() {
    // [TG-8869] - If the stepNames are not provided, we default to the config.
    // However, the steps in the Redux store, which are passed through the props, also come from the config.
    // Can we simplify by using the config in all cases and remove stepNames from the Redux store?
    const { stepNames, isMenuPersonalisationEnabled } = this.props

    const signupSteps = stepNames
      .filter((stepName) => !!AVAILABLE_STEP_COMPONENTS[stepName])
      .map((stepName) => stepByName(stepName))

    if (signupSteps.size === 0) {
      return getSignupSteps(isMenuPersonalisationEnabled).map((stepName) => stepByName(stepName))
    }

    return signupSteps
  }

  renderStep = (steps, currentStepNumber) => {
    const { goToStep, isMenuPersonalisationEnabled } = this.props
    const currentStepName = steps.getIn([currentStepNumber, 'name'])
    const nextStepName = steps.getIn([currentStepNumber + 1, 'name'])
    const isLastStep = currentStepNumber === steps.size - 1
    const Component = AVAILABLE_STEP_COMPONENTS[currentStepName]
    const { signupSetStep } = this.props
    signupSetStep(stepByName(currentStepName))

    return (
      <Component
        next={() => goToStep(nextStepName, isMenuPersonalisationEnabled)}
        nextStepName={nextStepName}
        currentStepName={currentStepName}
        stepNumber={currentStepNumber}
        isLastStep={isLastStep}
      />
    )
  }

  render() {
    const {
      stepNames,
      secondarySlug,
      promoModalVisible,
      trackDiscountVisibility,
      isDiscountAppliedBarDismissed,
      signupDismissDiscountAppliedBar,
      discountTip,
      isDiscountEnabled,
    } = this.props

    if (secondarySlug === signupConfig.sellThePropositionPageSlug) {
      return <SellThePropositionPage />
    }

    if (secondarySlug === signupConfig.checkAccountPageSlug) {
      return <CheckAccountPageContainer />
    }

    if (secondarySlug === signupConfig.applyVoucherPageSlug) {
      return <ApplyVoucherPageContainer />
    }

    if (secondarySlug === signupConfig.enterPromoCodeManuallyPageSlug) {
      return <EnterPromoCodeManuallyPage />
    }

    if (stepNames === null) {
      // Still loading.
      return null
    }

    const steps = this.getSteps()
    const stepNumber = this.getCurrentStepNumber(steps)
    const trackDiscountVisibilityHandler = () => trackDiscountVisibility(currentStepName)

    const currentStep = steps.find((step) => step.get('slug') === secondarySlug) || steps.get(0)
    const currentStepName = currentStep.get('name')

    const isDiscountAppliedBarVisible =
      !promoModalVisible && !isDiscountAppliedBarDismissed && isDiscountEnabled

    return (
      <div className={classNames(css.signupContainer)}>
        <Helmet
          style={[
            {
              cssText: `
              #react-root {
                height: 100%;
              }
            `,
            },
          ]}
        />
        <ScreenReaderLiveRegion urgency="polite">
          {isDiscountAppliedBarVisible && (
            <>
              <DiscountAppliedBar
                onShow={trackDiscountVisibilityHandler}
                onDismiss={signupDismissDiscountAppliedBar}
                boxDiscount={discountTip}
              />
              <Space direction="vertical" size={[4, 8]} />
            </>
          )}
        </ScreenReaderLiveRegion>
        <div className={classNames(css.stepsContainer)}>
          <StepIndicator current={stepNumber + 1} size={steps.size} />
          {this.renderStep(steps, stepNumber)}
        </div>
      </div>
    )
  }
}

Signup.propTypes = propTypes
Signup.defaultProps = defaultProps
Signup.contextType = ReactReduxContext

export { Signup }
