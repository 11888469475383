export * from './AddressLineInput'
export * from './AddressNameInput'
export * from './AddressTownInput'
export * from './AddressCountyInput'
export * from './AddressPostcodeInput'

export enum AddressFields {
  PostCode = 'postcode',
  Line1 = 'line1',
  Line2 = 'line2',
  Line3 = 'line3',
  Town = 'town',
  County = 'county',
  DeliveryInstructions = 'deliveryInstructions',
  DeliveryInstructionsCustom = 'deliveryInstructionsCustom',
}
