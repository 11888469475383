import React from 'react'

import {
  AlignItems,
  Box,
  Button,
  ButtonColorVariant,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  JustifyContent,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import {
  basketIsPortionSizeViewVisibleChange,
  basketIsShowDetailsViewFirstChange,
} from 'actions/basket'

export const PortionsButton = ({ numPortions }: { numPortions: number }) => {
  const dispatch = useDispatch()

  return (
    <Button
      colorVariant={ButtonColorVariant.Secondary}
      width="100%"
      data-testid="PortionsButton"
      data-testing="portionsButton"
      onClick={() => {
        dispatch(basketIsShowDetailsViewFirstChange(false))
        dispatch(basketIsPortionSizeViewVisibleChange(true))
      }}
    >
      <Box
        display={Display.Flex}
        width="100%"
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
      >
        <Text size={4} fontFamily={FontFamily.Bold}>
          {numPortions} person box
        </Text>
        <Box display={Display.Flex} alignItems={AlignItems.Center}>
          <Icon size={4} name="edit" variant={IconVariant.Actionable} />
          <Text size={1} fontFamily={FontFamily.Bold}>
            edit
          </Text>
        </Box>
      </Box>
    </Button>
  )
}
