import actions from 'actions'
import { RootStateOrAny, connect } from 'react-redux'

import { boxSummaryVisibilityChange } from 'actions/boxSummary'

import { BrowseCTA } from './BrowseCTA'

function mapStateToProps(state: RootStateOrAny) {
  return {
    menuBrowseCTAShow: state.menuBrowseCTAShow,
  }
}

const BrowseCTAContainer = connect(mapStateToProps, {
  boxDetailsVisibilityChange: boxSummaryVisibilityChange,
  menuBrowseCTAVisibilityChange: actions.menuBrowseCTAVisibilityChange,
})(BrowseCTA)

export { BrowseCTAContainer }
