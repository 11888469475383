import React from 'react'

import { Box, Grid, Col, Space, Heading5 } from '@gousto-internal/citrus-react'

import { SearchCategory, SearchCategoryProps } from '../SearchCategory/SearchCategory'

type SearchCategoriesGridProps = {
  categories: SearchCategoryProps[]
  title: string
}

export const SearchCategoriesGrid = ({ categories, title }: SearchCategoriesGridProps) => (
  <Box>
    <Heading5>{title}</Heading5>
    <Space size={4} />
    <Grid gap={[3, null, 4]}>
      {categories.map(({ id, imageUrl, name, recipeCount }) => (
        <Col size={[6, null, 4]} key={id}>
          <SearchCategory id={id} imageUrl={imageUrl} name={name} recipeCount={recipeCount} />
        </Col>
      ))}
    </Grid>
  </Box>
)
