import { DietaryPreference, MappedDietaryPreference } from './recipeTypes'

export const DEFAULT_PER_PREFERENCE = 2
export const NUMBER_OF_REQUIRED_RECIPES = 18
export const PREFERENCES_LOOKUP: Record<number, number> = { 1: 9, 2: 4, 3: 3, 4: 2 }

// Mapping dietary preferences to either dietary claims or dietary type based rules
export const DIETARY_PREFERENCE_MAPPING: Record<DietaryPreference, MappedDietaryPreference> = {
  vegetarian: 'vegetarian',
  'plant-based': 'vegan',
  pescatarian: 'fish',
  'gluten-free': 'gluten-free',
  'dairy-free': 'dairy-free',
  'no-seafood': 'notfish',
}

export const DIET_TYPE_CLAIMS = ['fish', 'notfish']
