import { createContext, Reducer, Dispatch, ReducerState } from 'react'
import type { AccountDetailsState } from './interfaces'
import type { AccountDetailsAction } from './reducers/accountDetailsReducer'

const AccountDetailsContext = createContext<{
  state: ReducerState<Reducer<AccountDetailsState, AccountDetailsAction>>
  dispatch: Dispatch<AccountDetailsAction>
}>({
  state: {
    paymentInfoSectionExpanded: false,
  },
  dispatch: () => {
    /* noop */
  },
})

export { AccountDetailsContext }
