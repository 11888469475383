import * as Yup from 'yup'
import React, { useState } from 'react'
import { fetchPostcodeAddresses } from '@library/api-postcode-lookup'
import { Form as FormikForm, FormikProvider, useFormik } from 'formik'
import {
  Box,
  Button,
  ButtonColorVariant,
  ButtonSize,
  Display,
  FlexDirection,
  Join,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'
import { Notification } from '../Notification'

interface FormProps {
  onFormSubmit: (values: Record<string, unknown>) => Promise<void>
  schemas?: Record<string, Yup.AnySchema>
  defaultValues?: Record<string, unknown>
  submitButtonText?: string
  cancelButtonText?: string
  onFormCancel?: () => void
  children?: React.ReactNode
}

const Form = (props: FormProps) => {
  const {
    schemas = {},
    onFormSubmit,
    onFormCancel,
    defaultValues = {},
    submitButtonText = 'Save',
    cancelButtonText = 'Cancel',
    children,
  } = props
  const [errorMessage, setErrorMessage] = useState<string>('')
  const onSubmit = async (values: Record<string, unknown>) => {
    setErrorMessage('')

    try {
      await fetchPostcodeAddresses(values.postcode as string)
      await onFormSubmit(values)
    } catch (error: unknown) {
      const name = error instanceof Error ? error.name : null
      if (name === 'PostcodeError') {
        setErrorMessage('No addresses found for specified postcode')
      } else {
        setErrorMessage('Sorry, we couldn’t process your request right now. Please try again.')
      }
    }
  }
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object().shape(schemas),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit,
  })
  const { isSubmitting, dirty } = formik

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Join with={<Space size={4} />}>
          {/* Citrus: https://github.com/Gousto/citrus/issues/267 */}
          {errorMessage && <Notification state="error" message={errorMessage} />}
          {children}
          <Box
            gap={4}
            display={Display.Flex}
            flexDirection={[FlexDirection.Column, FlexDirection.Row]}
            justifyContent={JustifyContent.SpaceEvenly}
          >
            {onFormCancel && (
              <Button
                type="button"
                colorVariant={ButtonColorVariant.Secondary}
                onClick={onFormCancel}
                size={ButtonSize.Medium}
                width="100%"
              >
                {cancelButtonText}
              </Button>
            )}
            <Button
              type="submit"
              disabled={isSubmitting || !dirty}
              colorVariant={ButtonColorVariant.Primary}
              size={ButtonSize.Medium}
              width="100%"
            >
              {submitButtonText}
            </Button>
          </Box>
        </Join>
      </FormikForm>
    </FormikProvider>
  )
}

export { Form }
