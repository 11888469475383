/**
 * Roles ultimately come from the auth database's oauth_scopes table
 * This is a subset of those used
 */
export type Role =
  | '*'
  | 'admin'
  | 'cancelled_user'
  | 'suspended_user'
  | 'user'
  | 'view_own_subscription'

export function isActive(roles: Role[]) {
  return roles.some((role) => ['user', 'admin', '*'].includes(role))
}

export function isSuspended(roles: Role[]) {
  return roles.includes('suspended_user')
}

export function needsReactivating(roles: Role[]) {
  return roles.includes('cancelled_user')
}

export function isAdmin(roles: Role[]) {
  return roles.some((role) => ['admin', '*'].includes(role))
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(email)
}
