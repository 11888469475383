export const summaryTexts = {
  orderSummaryTitle: 'Order total',
  orderSummaryTabletAndDesktopTitle: 'Your order ',
  highlightedText: 'Nice!',
  ordinaryTextStart: 'You get free delivery on your first box (usually',
  ordinaryTextEnd: ') as a welcome treat.',
}

export const boxDetailsAccordionTexts = {
  title: 'Your Gousto Box',
  recipeSummaryHeading: 'Your box ',
}
