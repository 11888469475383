import React, { useCallback } from 'react'
import { OrderBox, RecipeItem } from '@library/api-user'
import {
  Box,
  BorderStyle,
  Color,
  AspectRatio,
  Image,
  ImageProps,
  GridDisplay,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { useRecipeImageTile } from '../hooks/useRecipeImageTile'

import EmptyPlateJPG from '../assets/emptyPlate.png'
import { browserHistory } from 'react-router'

const ClickableBox = withCitrusTheme(Box, () => ({
  cursor: 'pointer',
}))

export interface RecipeImageTilesProps {
  orderId: string
  recipes: RecipeItem[]
  box: OrderBox
  trackPlateClick: () => void
  isOrderEditable: boolean
}

const EmptyRecipeImage = () => (
  <AspectRatio ratio={1 / 1}>
    <Box
      borderStyle={BorderStyle.Dashed}
      borderColor={Color.ColdGrey_800}
      borderWidth={0.5}
      width="100%"
      data-testid={'empty-recipe-image'}
    >
      <Image src={EmptyPlateJPG} alt="Add Recipe" />
    </Box>
  </AspectRatio>
)

const RecipeImage = (props: Partial<ImageProps>) => {
  const { src } = props
  const alt = 'Recipe Image'

  return (
    <AspectRatio ratio={1 / 1}>
      <Image src={src} alt={alt} />
    </AspectRatio>
  )
}

RecipeImage.displayName = 'RecipeImage'

export const RecipeImageTiles = ({
  orderId,
  recipes,
  box,
  isOrderEditable,
  trackPlateClick,
}: RecipeImageTilesProps) => {
  const { maxRecipes, recipeImageURLs } = useRecipeImageTile(recipes, box)

  const handleClick = useCallback(() => {
    if (!isOrderEditable) {
      return
    }

    browserHistory.push(`/menu/${orderId}`)
    trackPlateClick()
  }, [isOrderEditable, trackPlateClick, orderId])

  return (
    <Box
      width="100%"
      display={GridDisplay.Grid}
      gridTemplateColumns={`repeat(${maxRecipes}, 1fr)`}
      gap={1}
    >
      {recipeImageURLs.map((recipeImageUrl, index) => {
        return (
          <ClickableBox key={`${orderId}-recipe-image-${index}`} width="100%" onClick={handleClick}>
            {recipeImageUrl ? <RecipeImage src={recipeImageUrl} /> : <EmptyRecipeImage />}
          </ClickableBox>
        )
      })}
    </Box>
  )
}
