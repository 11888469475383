import actions from 'actions'
import { connect } from 'react-redux'

import { redirect, serverRedirectedFrom } from 'actions/redirect'
import {
  userGetReferralDetails,
} from 'actions/user'

import { getServerRedirectedFrom } from 'selectors/redirect'
import { trackClickRateRecipes } from '../../Ratings/actions/feedback'
import { MyGousto } from './MyGousto'

function mapStateToProps(state) {
  return {
    nameFirst: state.user.get('nameFirst'),
    referralDetails: state.user.get('referralDetails'),
    rateRecipeCount: state.feedback.get('feedbackCount'),
    goustoRef: state.user.get('goustoReference'),
    serverRedirectedFrom: getServerRedirectedFrom(state),
  }
}

const MyGoustoContainer = connect(mapStateToProps, {
  userLoadOrders: actions.userLoadOrders,
  userGetReferralDetails,
  redirect,
  trackClickRateRecipes,
  setServerRedirectedFrom: serverRedirectedFrom,
})(MyGousto)

export { MyGoustoContainer }
