import React from 'react'

import {
  AlignItems,
  Body2,
  BorderStyle,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
  Space,
} from '@gousto-internal/citrus-react'

import { VALIDATE_EMAIL_ERROR_DISPLAY } from './config'

export const ValidateEmailErrorDisplay = () => (
  <>
    <Box
      paddingH={4}
      paddingV={2}
      borderColor={Color.Error_400}
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderRadius={1.5}
      bg={Color.Error_50}
      display={Display.Flex}
      alignItems={AlignItems.Center}
      data-testid="ValidateEmailErrorDisplay"
    >
      <Box paddingRight={2}>
        <Icon name="info" variant={IconVariant.Error} />
      </Box>
      <Box>
        <Body2>{VALIDATE_EMAIL_ERROR_DISPLAY}</Body2>
      </Box>
    </Box>
    <Space size={2} />
  </>
)
