import {
  useEffect,
  useMemo,
} from 'react'

import { endpoint } from '@library/endpoint'
import routes from 'config/routes'

import { parseTimeRange } from 'utils/deliverySlot'
import { parseObjectKeysToCamelCase } from 'utils/jsonHelper'
import { buildSubscriptionQueryUrl } from '../../apis/subscription'
import { useFetch } from '../../../../hooks/useFetch'
import {
  getCurrentUserId,
  getCurrentUserPostcode,
  getCurrentUserDeliveryTariffId
} from '../context/selectors/currentUser'
import { actionTypes } from '../context/reducers'
import { mapSubscriptionPayload } from '../utils/mapping'
import { dayNumberMap } from '../enum/day'

export const useSubscriptionData = (
  accessToken,
  dispatch,
  triggerSlots,
  triggerSubscription,
  state
) => {
  const userId = getCurrentUserId(state)
  const postcode = getCurrentUserPostcode(state)
  const deliveryTariffId = getCurrentUserDeliveryTariffId(state)

  const slotsUrl = `${endpoint('deliveries')}/slots`
  const slotParams = useMemo(() => ({
    postcode,
    delivery_tariff_id: deliveryTariffId,
  }), [postcode, deliveryTariffId])

  const subscriptionUrl = buildSubscriptionQueryUrl(userId, routes.subscriptionQuery.subscriptions)

  useEffect(() => {
    if (userId) {
      triggerSubscription.setShouldRequest(true)
    }
  }, [userId, triggerSubscription])

  useEffect(() => {
    if (postcode) {
      triggerSlots.setShouldRequest(true)
    }
  }, [postcode, triggerSlots])

  const [, subscriptionResponse, subscriptionError
  ] = useFetch({
    url: subscriptionUrl,
    needsAuthorization: true,
    accessToken,
    trigger: triggerSubscription,
  })

  const [, slotResponse, slotError
  ] = useFetch({
    url: slotsUrl,
    parameters: slotParams,
    accessToken,
    trigger: triggerSlots,
  })

  useEffect(() => {
    const hasAnyErrors = subscriptionError || slotError
    const allRequestsComplete = subscriptionResponse && slotResponse

    if (!hasAnyErrors && allRequestsComplete) {
      const { subscription, box, projected } = mapSubscriptionPayload(subscriptionResponse.data.subscription)

      const mapTimeRangeAndDateToSlots = () => {
        if (!slotResponse.data) {
          return []
        }

        const { data: slots } = parseObjectKeysToCamelCase(slotResponse)

        const withTimeRangeAndDay = (data) => (
          data.map((slot) => ({
            ...slot,
            timeRange: parseTimeRange(slot.deliveryStartTime, slot.deliveryEndTime),
            day: dayNumberMap[slot.defaultDay],
          })
          )
        )

        const updatedSlots = withTimeRangeAndDay(slots)
        const currentDeliverySlot = updatedSlots.find((slot) => (
          slot.defaultDay === subscription.deliverySlotDay
          && slot.deliveryStartTime === subscription.deliverySlotStartTime
          && slot.deliveryEndTime === subscription.deliverySlotEndTime
        )) || []

        return {
          slots: {
            data: updatedSlots,
            currentDeliverySlot,
          }
        }
      }

      const slotsWithTimeRangeAndDate = mapTimeRangeAndDateToSlots()

      const data = {
        ...slotsWithTimeRangeAndDate,
        subscription: {
          subscription: {
            ...subscription,
            deliverySlotId: slotsWithTimeRangeAndDate.slots.currentDeliverySlot.coreSlotId,
          },
          box,
          projected
        },
      }

      dispatch({
        type: actionTypes.SUBSCRIPTION_DATA_RECEIVED,
        data
      })
    }
  }, [
    subscriptionResponse,
    dispatch,
    subscriptionError,
    slotResponse,
    slotError,
  ])
}
