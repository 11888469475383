import React from 'react'
import {
  Section,
  Box,
  Heading5,
  FontWeight,
  BorderStyle,
  Color,
} from '@gousto-internal/citrus-react'

export type SectionWrapperProps = {
  id: string
  title: string
  children?: React.ReactNode
}

export function SectionWrapper(props: SectionWrapperProps) {
  return (
    <Section id={props.id} aria-label={props.title} aria-labelledby={`${props.id}-section-title`}>
      <Box
        paddingH={6}
        paddingV={6}
        bg={Color.White}
        borderColor={Color.NeutralGrey_100}
        borderStyle={BorderStyle.Solid}
        borderWidth={0.5}
      >
        <Heading5 id={`${props.id}-section-title`} fontWeight={FontWeight.Bold}>
          {props.title}
        </Heading5>
        {props.children}
      </Box>
    </Section>
  )
}
