import React from 'react'

import { MenuRecipeIngredient } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import Image from 'components/Image'
import Svg from 'components/Svg'
import { getMenuRecipeImage } from 'utils/image'

import css from './IngredientImage.css'

type IngredientProps = {
  ingredient: MenuRecipeIngredient
}

const IngredientImage = ({ ingredient }: IngredientProps) => {
  const { images } = ingredient

  if (images.length <= 0) {
    return <Svg fileName="icon-vegs" className={css.placeholder} />
  }

  const src = getMenuRecipeImage(images[0].crops, 125)

  return <Image media={src} title={ingredient.name} className={css.image} />
}

export { IngredientImage }
