import { canUseWindow } from 'utils/browserEnvironment'
import { actionTypes } from 'actions/actionTypes'
import fbSettings from 'config/template'
import defaultOffer from 'config/referral'
import { getYourFriendFirstBoxOffer, getYourFriendFirstMonthOffer, getDiscountDurationFormatted } from 'selectors/rafOffer'

export const SOCIAL_TYPES = {
  email: 'Email',
  messenger: 'Messenger',
  facebook: 'Facebook',
  link: 'Link',
  text: 'Text Message',
  whatsapp: 'Whatsapp',
}

export const getMessage = (offer) => {
  const firstBoxOffer = offer ? getYourFriendFirstBoxOffer(offer) : getYourFriendFirstBoxOffer(defaultOffer)
  const firstMonthOffer = offer ? getYourFriendFirstMonthOffer(offer) : getYourFriendFirstMonthOffer(defaultOffer)
  const discountDuration = offer ? getDiscountDurationFormatted(offer) : getDiscountDurationFormatted(defaultOffer)

  return `I love Gousto and I think you will too! Use my link to get an exclusive ${firstBoxOffer} off your first box, PLUS ${firstMonthOffer} off other boxes in your first ${discountDuration}. \r\n`
}

export const getReferralLink = (referralCode, userFirstName = '', UTM = '') => {
  const userNameString = userFirstName ? `&name=${userFirstName}` : ''

  return `www.gousto.co.uk/raf/?promo_code=${referralCode}${userNameString}${UTM}`
}

export const getFacebookReferralLink = (
  referralCode,
  userFirstName,
  trackingReferFriendSocialSharing,
  trackUserFreeFoodLinkShare,
) => {
  trackingReferFriendSocialSharing(actionTypes.REFER_FRIEND_LINK_SHARE, 'ReferFriendLink Share', SOCIAL_TYPES.facebook)
  trackUserFreeFoodLinkShare({ target: SOCIAL_TYPES.facebook })

  const facebookUTM = '&utm_source=facebook&utm_medium=sharebutton_raf_page&utm_campaign=raf_facebook_share'
  const referralLink = getReferralLink(referralCode, userFirstName, facebookUTM)

  if (canUseWindow() && window.FB) {
    window.FB.ui({
      method: 'share',
      mobile_iframe: true,
      href: referralLink,
      redirect_uri: referralLink,
    })
  }
}

export const getMessengerReferralLink = (
  referralCode,
  userFirstName,
  trackingReferFriendSocialSharing,
  device,
  trackUserFreeFoodLinkShare,
) => {
  trackingReferFriendSocialSharing(actionTypes.REFER_FRIEND_LINK_SHARE, 'ReferFriendLink Share', SOCIAL_TYPES.messenger)
  trackUserFreeFoodLinkShare({ target: SOCIAL_TYPES.messenger })

  const messengerUTM = '&utm_source=messenger&utm_medium=sharebutton_raf_page&utm_campaign=raf_messenger_share'
  const referralLink = getReferralLink(referralCode, userFirstName, messengerUTM)

  if (canUseWindow()) {
    if (device === 'desktop' || device === 'tablet') {
      window.FB.ui({
        method: 'send',
        mobile_iframe: true,
        link: referralLink,
        redirect_uri: referralLink,
      })
    } else {
      const { fbAppID } = fbSettings.head
      const shareurl = `https://www.facebook.com/dialog/share?app_id=${fbAppID}&display=popup&href=${referralLink}&redirect_uri=https://${referralLink}`

      window.open(shareurl)
    }
  }
}

export const getWhatsappReferralLink = (
  referralCode,
  userFirstName,
  rafOffer,
  trackingReferFriendSocialSharing,
  trackUserFreeFoodLinkShare,
) => {
  trackingReferFriendSocialSharing(actionTypes.REFER_FRIEND_LINK_SHARE, 'ReferFriendLink Share', SOCIAL_TYPES.whatsapp)
  trackUserFreeFoodLinkShare({ target: SOCIAL_TYPES.whatsapp })

  const whatsappUTM = '&utm_source=whatsapp&utm_medium=sharebutton_raf_page&utm_campaign=raf_whatsapp_share'
  const referralLink = getReferralLink(referralCode, userFirstName, whatsappUTM)
  const message = getMessage(rafOffer)
  const encodedMessage = encodeURIComponent(`${message}${referralLink}`)

  return window.location.assign(`https://api.whatsapp.com/send?text=${encodedMessage}`)
}

export const getTextMessageReferralLink = (
  referralCode,
  userFirstName,
  rafOffer,
  trackingReferFriendSocialSharing,
  trackUserFreeFoodLinkShare,
) => {
  trackingReferFriendSocialSharing(actionTypes.REFER_FRIEND_LINK_SHARE, 'ReferFriendLink Share', SOCIAL_TYPES.text)
  trackUserFreeFoodLinkShare({ target: SOCIAL_TYPES.text })

  const textMessageUTM = '&utm_source=text_message&utm_medium=sharebutton_raf_page&utm_campaign=raf_text_message_share'
  const referralLink = getReferralLink(referralCode, userFirstName, textMessageUTM)
  const message = getMessage(rafOffer)
  const encodedMessage = encodeURIComponent(`${message}${referralLink}`)

  return window.location.assign(`sms:?&body=${encodedMessage}`)
}

