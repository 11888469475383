import React, { useState, useEffect } from 'react'

import { Color, Link, colors } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import routesConfig from 'config/routes'

import { DeliveryDate } from './DeliveryDate'
import { ExpandContainer } from './ExpandContainer'
import { OrderTotal } from './OrderTotal'
import { SectionHeading, Divider } from './SharedComponents'
import { RecipeList, Benefits, useGetYourBoxData } from './YourBox'
import { trackToggleExpandOrderSummary, trackClickEditOrderLink } from './snowplowActions'
import { MobileContainer, AnimatedContent } from './styled'

type BoxDetailsHighlightMobileProps = {
  isLoading: boolean
}

export const BoxDetailsHighlightMobile = ({ isLoading }: BoxDetailsHighlightMobileProps) => {
  const dispatch = useDispatch()
  const { recipesIdsList } = useGetYourBoxData()
  const [expanded, setExpanded] = useState(false)
  const [delayedExpanded, setDelayedExpanded] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setDelayedExpanded(expanded)
      },
      expanded ? 300 : 100,
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [expanded])

  const expandAction = () => {
    dispatch(trackToggleExpandOrderSummary(expanded === false))
    setExpanded(!expanded)
  }

  const onClickLink = () => {
    dispatch(trackClickEditOrderLink(recipesIdsList))
  }

  return (
    <MobileContainer borderColor={colors[Color.ColdGrey_100]}>
      <ExpandContainer expandAction={expandAction} expanded={expanded} isMobile />
      <RecipeList expanded={expanded} />
      <Benefits />

      <AnimatedContent expanded={delayedExpanded}>
        <Divider />

        <SectionHeading text="Order Total">
          <Link href={routesConfig.client.menu} onClick={onClickLink}>
            Edit order
          </Link>
        </SectionHeading>
        <OrderTotal isLoading={isLoading} />

        <Divider />

        <SectionHeading text="Delivery Date" />
        <DeliveryDate />
      </AnimatedContent>
    </MobileContainer>
  )
}
