import React from 'react'
import { PaymentCard3DSModalContext } from './PaymentCard3DSModalContext'
import { usePaymentCard3DSModalProvider } from '../PaymentInfo/hooks/usePaymentCard3DSModalProvider'
import { PaymentCard3DSModal } from './PaymentCard3DSModal'

type Props = {
  children: React.ReactNode
}
const PaymentCard3DSModalProvider = ({ children }: Props) => {
  const {
    cardConfirmationCompleted,
    cardConfirmationError,
    cardConfirmationRedirectURL,
    onCardConfirmationLoaded,
    setCardConfirmationRedirectURL,
    setCardConfirmationError,
    reset,
  } = usePaymentCard3DSModalProvider()

  return (
    <PaymentCard3DSModalContext.Provider
      value={{
        cardConfirmationCompleted,
        cardConfirmationError,
        cardConfirmationRedirectURL,
        onCardConfirmationLoaded,
        setCardConfirmationRedirectURL,
        setCardConfirmationError,
        reset,
      }}
    >
      <PaymentCard3DSModal />
      {children}
    </PaymentCard3DSModalContext.Provider>
  )
}

export { PaymentCard3DSModalProvider }
