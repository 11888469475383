import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik, Field, FieldProps, FormikProvider } from 'formik'
import useSWR from 'swr'
import {
  Accordion,
  Join,
  Space,
  Text,
  Button,
  InputField,
  FormFieldStatus,
} from '@gousto-internal/citrus-react'

import { updateCurrentUser, fetchCurrentUser } from '@library/api-user'

import { RenderedTitle } from '../RenderedTitle'
import { ApiKeys } from '../../enums'

export enum ErrorMessages {
  europeanChar = "Please use only letters (a-z), hyphens (-), apostrophes (' and ‘) and European special characters.",
  minLength = 'It must be at least 1 character',
  maxLength = 'It must be under 50 characters',
}

const stringPattern =
  /^(\\s)*[a-zA-ZÀ-ž]{1,}([\\-|\\'|\\‘][a-zA-ZÀ-ž]{1,}){0,1}(\\s[a-zA-ZÀ-ž]{1,}([\\-|\\'|\\‘][a-zA-ZÀ-ž]{1,}){0,1}){0,}(\\s)*$/

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(stringPattern, ErrorMessages.europeanChar)
    .min(1, ErrorMessages.minLength)
    .max(50, ErrorMessages.maxLength)
    .required(ErrorMessages.minLength),
  lastName: Yup.string()
    .matches(stringPattern, ErrorMessages.europeanChar)
    .min(1, ErrorMessages.minLength)
    .max(50, ErrorMessages.maxLength)
    .required(ErrorMessages.minLength),
})

function UserNameSection() {
  const { data, mutate } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const [submitError, setSubmitError] = useState<string>('')
  const [timestamp, setTimeStamp] = useState<number>(new Date().getTime())

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: data?.user.nameFirst || '',
      lastName: data?.user.nameLast || '',
    },
    onSubmit: async () => {
      try {
        setSubmitError('')

        await mutate(
          updateCurrentUser({
            name_first: values.firstName,
            name_last: values.lastName,
          }),
        )

        setTimeStamp(new Date().getTime())
      } catch (error) {
        setSubmitError('Sorry, we couldn’t process that. Please try again')
      }
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
  })
  const { errors, isSubmitting, resetForm, values } = formik

  return (
    <>
      <Space size={6} />
      <Accordion
        id={'user-name-accordion'}
        key={`accordion-opened-${timestamp}`}
        title={(isExpanded) => {
          return (
            <RenderedTitle isExpanded={isExpanded} title="Name" iconType="profile">
              <Text size={2}>
                {data?.user.nameFirst} {data?.user.nameLast}
              </Text>
            </RenderedTitle>
          )
        }}
        onChange={(isExpanded) => {
          if (isExpanded) {
            return
          }
          resetForm({ values })
        }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Join with={<Space size={6} />}>
              <Field name="firstName">
                {({ field }: FieldProps) => (
                  <InputField
                    id={field.name}
                    label="First name"
                    placeholder="First name"
                    validationMessage={errors.firstName}
                    status={errors.firstName ? FormFieldStatus.Error : undefined}
                    {...field}
                  />
                )}
              </Field>
              <Field name="lastName">
                {({ field }: FieldProps) => (
                  <InputField
                    id={field.name}
                    label="Last name"
                    placeholder="Last name"
                    validationMessage={errors.lastName}
                    status={errors.lastName ? FormFieldStatus.Error : undefined}
                    {...field}
                  />
                )}
              </Field>
              {submitError}
              <Button width="100%" type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Join>
          </form>
        </FormikProvider>
      </Accordion>
    </>
  )
}

export { UserNameSection }
