import { deliveryInstructionsFields } from 'DeliveryInstructions/DeliveryInstructionsConfig'
import { Map as ImmutableMap } from 'immutable'
import { createSelector } from 'reselect'

export type DeliveryDetailsStore = {
  deliveryDetails: ImmutableMap<'deliveryInstruction' | 'deliveryInstructionCustom', string>
}

export const getDeliveryDetailsInstructions = ({ deliveryDetails }: DeliveryDetailsStore) =>
  deliveryDetails.get('deliveryInstruction')

export const getDeliveryDetailsInstructionsCustom = ({ deliveryDetails }: DeliveryDetailsStore) =>
  deliveryDetails.get('deliveryInstructionCustom')

export const getIsValidDeliveryDetailsInstructionsCustom = createSelector(
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
  (deliveryDetailsInstructions, deliveryInstructionCustom) => {
    if (
      deliveryInstructionsFields.leaveBoxOptionsWithMessage.includes(deliveryDetailsInstructions)
    ) {
      return deliveryInstructionCustom.length > 4
    }

    return true
  },
)
