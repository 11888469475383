import React, { Provider, ReactElement } from 'react'

import { UseRecipeBrandContextProvider } from './useRecipeBrand'

type ExtractValueTypeFromContextProvider<T> = T extends Provider<infer U> ? U : never

type RecipeTileDependenciesArgs = {
  children: ReactElement
  useRecipeBrand: ExtractValueTypeFromContextProvider<typeof UseRecipeBrandContextProvider>
}

/**
 * Populates all contexts necessary for rendering `RecipeTile`.
 *
 * Note: the contexts include not only data but "hooks" which could be called from within `RecipeTile`
 * component sub-tree.
 */
export const RecipeTileDependencies = ({
  children,
  useRecipeBrand,
}: RecipeTileDependenciesArgs) => (
  // Each logical item uses its own provider to minimize re-renderings
  <UseRecipeBrandContextProvider value={useRecipeBrand}>{children}</UseRecipeBrandContextProvider>
)
