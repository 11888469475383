import calendarMonthPng from '../../assets/calendar-month.png'
import calendarMonthWebp from '../../assets/calendar-month.webp'
import cookingPng from '../../assets/cooking.png'
import cookingWebp from '../../assets/cooking.webp'
import shoppingBagPng from '../../assets/shopping-bag.png'
import shoppingBagWebp from '../../assets/shopping-bag.webp'

export const title = 'All your dinner problems sorted'

export const conveniences = [
  {
    key: 'info-text-0',
    text: 'Plan your weekly meals in a few clicks',
    icon: { png: calendarMonthPng, webp: calendarMonthWebp },
  },
  {
    key: 'info-text-1',
    text: 'No mid-week supermarket dashes',
    icon: { png: shoppingBagPng, webp: shoppingBagWebp },
  },
  {
    key: 'info-text-2',
    text: 'Enjoy home-cooked meals after a busy day',
    icon: { png: cookingPng, webp: cookingWebp },
  },
]

export const valueForMoneyTrackingSectionName = 'valueForMoney'
