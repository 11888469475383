import React from 'react'

import { Route } from 'react-router'

import { AccessibilityStatement } from '@features/legal-pages'
import { OFXTestRoute } from '@library/experimentation'

import { ErrorPage } from 'components/ErrorPage'
import { PageContainer } from 'containers/PageContainer'
import { FullPage } from 'layouts/FullPage/FullPage'
import { MainLayout } from 'layouts/MainLayout'

import configRoutes from '../config/routes'
import Account from './Account'
import { BoxPrices } from './BoxPrices'
import { Checkout } from './Checkout'
import { DeliveryDetails } from './DeliveryDetails'
import { GetHelp } from './GetHelp'
import { Home } from './Home'
import { MenuRoute } from './Menu'
import { ModernSlaveryStatement } from './ModernSlaveryStatement'
import { OrderConfirmationRoute } from './OrderConfirmation'
import { Payment } from './Payment'
import { PrivacyStatement } from './PrivacyStatement'
import { Raf } from './Raf'
import { ResetPasswordContainer } from './ResetPassword'
import { Signup } from './Signup'
import { UnsubscribeRoute } from './Unsubscribe'
import Welcome from './Welcome'

const routes = (store) => (
  <Route path="/" component={PageContainer}>
    {Home}
    {Signup}
    {DeliveryDetails}
    {MenuRoute}
    {BoxPrices}
    {Raf(store)}
    <Route component={FullPage} props={{ footerType: 'large' }}>
      <Route path={configRoutes.client.resetPassword} component={ResetPasswordContainer} />
    </Route>
    {UnsubscribeRoute}
    {PrivacyStatement}
    {GetHelp(store)}
    {ModernSlaveryStatement}
    <Route component={MainLayout}>
      <Route path={configRoutes.client.accessibilityStatement} component={AccessibilityStatement} />
    </Route>
    {Payment}

    <Route component={MainLayout}>
      {Welcome(store)}
      {Account(store)}
      {OrderConfirmationRoute(store)}
    </Route>

    {Checkout(store)}

    {/* FEF-1033 - Testing only */}
    {OFXTestRoute}

    <Route component={MainLayout}>
      <Route path="*" component={ErrorPage} />
    </Route>
  </Route>
)

export { routes }
