import { actionTypes } from 'actions/actionTypes'
import { identifyApplePayEligibility } from 'actions/trackingKeys'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

/**
 * Tracks in snowplow if browser has Apple Pay enabled.
 */
export const trackApplePayEligibility: CheckoutActionCreator = (isApplePayEligible: boolean) => ({
  type: actionTypes.IDENTIFY_APPLE_PAY_ELIGIBILITY,
  trackingData: {
    actionType: identifyApplePayEligibility,
    eligibility: isApplePayEligible,
  },
})
