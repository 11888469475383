import Immutable from 'immutable'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

// TODO remove these selectors later in the Menu API epic

type legacy_ReduxState = {
  recipes: Immutable.Map<string, any>
  menuRecipes: Immutable.List<string>
  menu: Immutable.Map<string, any>
}

type legacy_RecipeVariantState = Immutable.Map<string, Immutable.Map<string, any>>

const getRecipes = ({ recipes }: legacy_ReduxState) => recipes
const getMenuRecipeIds = ({ menuRecipes }: legacy_ReduxState) => menuRecipes
const getMenuVariants = (state: legacy_ReduxState): legacy_RecipeVariantState =>
  state.menu.get('menuVariants')

function isNotNull<T>(x: T | null): x is T {
  return x !== null
}

const getCurrentMenuRecipes = createSelector(
  [getRecipes, getMenuRecipeIds],
  (allRecipes, currentMenuIds: Immutable.List<string>) =>
    currentMenuIds &&
    currentMenuIds.map((recipeId) => allRecipes.get(recipeId!) || null).filter(isNotNull),
)

/**
 * Legacy Redux selector: get a list of recipe IDs for the current menu
 */
export function useLegacyCurrentMenuRecipes(numPortions?: number) {
  return useSelector(getCurrentMenuRecipes)
    .filter((recipe) => {
      return !numPortions || recipe.get('portionSizesOffered').includes(numPortions)
    })
    .toList()
}

/**
 * Legacy Redux selector: get a map of all loaded recipe objects
 */
export function useLegacyRecipes() {
  return useSelector(getRecipes)
}

/**
 * Legacy Redux selector: get a list of variant links for the current menu
 */
export function useLegacyCurrentMenuVariants(menuId: string) {
  const variants = useSelector(getMenuVariants)

  return variants.get(menuId) || null
}
