import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { canUseWindow } from '@library/environment'
import { Dict } from '@library/type-utils'
import { ListenerPayload, OptimizelyDecision } from '@optimizely/optimizely-sdk'

export type WebXFeature = { feature: string; value: boolean }

export type RolloutsDecisionInfo = {
  featureKey: string
  featureEnabled: boolean
  sourceInfo: Dict
}

export type OptimizelyDecisionInfo = {
  decisionInfo: OptimizelyDecision
} & ListenerPayload

/**
 * Legacy function: should be removed when we migrate from Optimizely Web Experimentation
 * to Feature Experimentation. Use on client-side only.
 */
export function DDInstrumentWebXExperiment(features: WebXFeature[]) {
  // Defensive gate as this will initially be invoked from JS
  if (!features || !Array.isArray(features) || !features.length) return

  for (const feature of features) {
    const { feature: key, value } = feature
    try {
      datadogRum.addFeatureFlagEvaluation(key, value)
    } catch (err) {
      datadogLogs.logger.warn(`Unable to instrument Optimizely WebX feature '${key}'`, { err })
    }
  }
}

/**
 * Legacy function: should be removed when we migrate from Optimizely Rollouts
 * to Feature Experimentation. Only runs client-side.
 */
export function DDInstrumentRolloutDecision(decision: RolloutsDecisionInfo) {
  if (!canUseWindow() || !decision) return

  const { featureKey, featureEnabled } = decision

  try {
    datadogRum.addFeatureFlagEvaluation(featureKey, featureEnabled)
  } catch (err) {
    datadogLogs.logger.warn(`Unable to instrument Optimizely Rollouts decision '${featureKey}'`, {
      err,
    })
  }
}

export function DDInstrumentOFXDecision(decision: OptimizelyDecisionInfo) {
  const { flagKey, variationKey } = decision.decisionInfo

  try {
    datadogRum.addFeatureFlagEvaluation(flagKey, variationKey)
    datadogRum.setUserProperty('OFXClientID', decision.userId)
    datadogLogs.setUserProperty('OFXClientID', decision.userId)
  } catch (err) {
    datadogLogs.logger.warn(`Unable to instrument Optimizely FX decision for flag '${flagKey}'`, {
      err,
    })
  }
}
