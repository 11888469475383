import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { PeekInTheBoxContent, PeekInTheBoxImage } from './components'

export const PeekInTheBox = () => {
  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} bg={Color.White}>
      <Space direction="vertical" size={10} />
      <Box
        display={Display.Flex}
        flexDirection={[FlexDirection.Column, null, FlexDirection.Row]}
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
      >
        <PeekInTheBoxImage />
        <Space direction="horizontal" size={10} />
        <Space direction="vertical" size={10} />
        <PeekInTheBoxContent />
      </Box>
    </Box>
  )
}
