import { Recipe } from '../model/recipe'

export function roundUp(value: number, precision = 0.01) {
  return Math.ceil(value / precision) * precision
}

export function getSurchargePerPortion(surcharge: number, numberOfPortions: number) {
  const perPortion = surcharge / numberOfPortions

  return roundUp(perPortion)
}

export const formatSurcharge = (recipe: Recipe, numPortions: number) => {
  const meal = recipe.meals.find((item) => Number(item.numPortions) === numPortions)
  const surcharge = meal?.surcharge?.listPrice ?? 0

  const surchargePerPortion = getSurchargePerPortion(surcharge, numPortions)

  return formatSurchargeValue(surchargePerPortion)
}

export const formatSurchargeValue = (value: number) => {
  const formattedValue = Math.abs(value).toFixed(2)

  if (value === 0) return null

  return value < 0 ? `Save £${formattedValue}` : `+£${formattedValue}`
}
