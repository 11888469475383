import Immutable from 'immutable'
import { Action } from 'redux'

import { actionTypes } from 'actions/actionTypes'

const deliveryDetailsInitialState = Immutable.fromJS({
  deliveryInstruction: '',
  deliveryInstructionCustom: '',
})

type DeliveryDetailsStateType = typeof deliveryDetailsInitialState
type DeliveryDetailsActionType = Action<string> & DeliveryDetailsStateType

export const deliveryDetailsReducer = {
  deliveryDetails: (state: DeliveryDetailsStateType, action: DeliveryDetailsActionType) => {
    if (!state) {
      return deliveryDetailsInitialState
    }

    switch (action.type) {
      case actionTypes.SAVE_DELIVERY_DETAILS_INSTRUCTION: {
        const { deliveryInstruction } = action

        return state.set('deliveryInstruction', deliveryInstruction)
      }

      case actionTypes.SAVE_DELIVERY_DETAILS_INSTRUCTION_CUSTOM: {
        const { deliveryInstructionCustom } = action

        return state.set('deliveryInstructionCustom', deliveryInstructionCustom)
      }
      default: {
        return state
      }
    }
  },
}
