import {
  Box,
  FlexDirection,
  JustifyContent,
  Heading5,
  Body2,
  Color,
  FontWeight,
  Body3,
  Icon,
  IconVariant,
  Button,
  ButtonColorVariant,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { DiscountPercentage } from './Discount'

type CommonProps = {
  humanDeliveryDate: string
  orderPhaseContent: string
  shouldCutOffTime: string
  shouldCutOffDate: string
}

type OrderDetailWithouRecipesChosenProps = CommonProps & {
  orderDiscount: string | null
}

type OrderDetailHeadingsForRecipesChosenProps = {
  humanDeliveryDate: string
  orderPhaseContent: string
  recipeCount: number
  productCount: number
}

type OrderDetailStatusForRecipesChosenProps = {
  orderId: string
  isOrderDelivered: boolean
  shouldCutOffTime: string
  shouldCutOffDate: string
  handleGetHelpClick: () => void
}

type OrderDetailExpandButtonProps = {
  isOrderExpanded: boolean
  setIsOrderExpanded: (isExpanded: boolean) => void
}

export const OrderDetailWithoutRecipesChosen = ({
  humanDeliveryDate,
  orderPhaseContent,
  shouldCutOffTime,
  shouldCutOffDate,
  orderDiscount,
}: OrderDetailWithouRecipesChosenProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={FlexDirection.Row}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Box>
        <Heading5>{humanDeliveryDate}</Heading5>
        <Body2 color={Color.Warning_700} fontWeight={FontWeight.Bold}>
          {orderPhaseContent}
        </Body2>
        <Body3
          color={Color.ColdGrey_800}
        >{`Choose by ${shouldCutOffTime} on ${shouldCutOffDate}`}</Body3>
      </Box>
      <Box display={['flex', 'none']} flexDirection={FlexDirection.Row}>
        <DiscountPercentage discount={orderDiscount} />
        <Icon name="chevron_down" variant={IconVariant.Actionable} />
      </Box>
    </Box>
  )
}

export const OrderDetailHeadingsForRecipesChosen = ({
  humanDeliveryDate,
  orderPhaseContent,
  recipeCount,
  productCount,
}: OrderDetailHeadingsForRecipesChosenProps) => {
  return (
    <Box height={'100%'}>
      <Heading5>{humanDeliveryDate}</Heading5>
      <Body2 color={Color.Success_800} fontWeight={FontWeight.Bold}>
        {orderPhaseContent}
      </Body2>
      <Body3>
        {recipeCount} {recipeCount <= 0 ? 'recipe' : 'recipes'}, {productCount}{' '}
        {productCount <= 0 ? 'item' : 'items'}
      </Body3>
    </Box>
  )
}

export const OrderDetailStatusForRecipesChosen = ({
  isOrderDelivered,
  shouldCutOffTime,
  shouldCutOffDate,
  handleGetHelpClick,
}: OrderDetailStatusForRecipesChosenProps) => {
  return (
    <>
      {!isOrderDelivered && (
        <Body3
          color={Color.ColdGrey_800}
        >{`Edit recipes up until on ${shouldCutOffTime} on ${shouldCutOffDate}`}</Body3>
      )}
      {isOrderDelivered && (
        <Button onClick={handleGetHelpClick} colorVariant={ButtonColorVariant.Secondary}>
          Get help
        </Button>
      )}
    </>
  )
}

export const OrderDetailExpandButton = ({
  isOrderExpanded,
  setIsOrderExpanded,
}: OrderDetailExpandButtonProps) => {
  const handleExpandClick = () => setIsOrderExpanded(!isOrderExpanded)

  return (
    <Box data-testid="order-detail-expand-button" onClick={handleExpandClick}>
      <Icon
        name={!isOrderExpanded ? 'chevron_down' : 'chevron_up'}
        variant={IconVariant.Actionable}
      />
    </Box>
  )
}
