import React from 'react'
import {
  Box,
  Icon,
  Text,
  Space,
  Color,
  Display,
  BorderStyle,
  JustifyContent,
  IconVariant
} from '@gousto-internal/citrus-react'

const OrderMultipleBoxesNotification = () => (
  <Box
    display={Display.Flex}
    paddingV={3}
    paddingH={3}
    bg={Color.Informative_50}
    borderColor={Color.Informative_200}
    borderStyle={BorderStyle.Solid}
    justifyContent={JustifyContent.SpaceBetween}
    borderWidth={0.5}
    borderRadius={1.5}
  >
    <Icon name="info" size={5} variant={IconVariant.Informative} />
    <Space direction="horizontal" size={2} />
    <Text size={1}>
      Heads up, your order could arive in 1 or 2 boxes. <br />
      { 'We\'ll let you know when it\'s packed.' }
    </Text>
  </Box>
)

export { OrderMultipleBoxesNotification }
