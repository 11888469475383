import React, { useCallback } from 'react'
import { Link, useModal } from '@gousto-internal/citrus-react'

type Props = {
  onClick: () => void
}

export const EditAddressButton = ({ onClick }: Props) => {
  const { openModal } = useModal()

  const openEditAddressModal = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      onClick()

      openModal('EditAddressModal')
    },
    [openModal, onClick],
  )

  return (
    <Link size={1} onClick={openEditAddressModal}>
      Edit
    </Link>
  )
}
