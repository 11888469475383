import { Recipe } from '../model/recipe/types'

type DietaryClaims = 'dairy_free' | 'vegan' | 'vegetarian' | 'gluten_free'
const streamlinedDietaryClaims = ['dairy_free', 'vegan', 'vegetarian', 'gluten_free']

function convertDashToUnderscore(string: string): string {
  return string.replace(/-/g, '_')
}

export const getDietaryClaims = (recipe: Recipe): DietaryClaims[] => {
  if (!recipe.dietaryClaims) {
    return []
  }

  return recipe.dietaryClaims
    .map((claim) => convertDashToUnderscore(claim.slug))
    .filter((claim) => streamlinedDietaryClaims.includes(claim)) as DietaryClaims[]
}
