import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  DisplayPosition,
  RadioGroup,
  TextArea,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { usePauseOSRData } from '../../hooks/usePauseOSRData'
import { PauseRadioGroup } from './PauseRadioGroup'
import { TextLengthCounter } from './TextLengthCounter'

const TEXT_AREA_LENGTH_LIMIT = 80

const CustomTextArea = withCitrusTheme(TextArea, (theme) => ({
  height: '100px',
  paddingBottom: theme.spacing(5),
}))

interface Tracking {
  reason: string
  secondaryReason?: string
}

interface AlternateOptionTrackingData {
  pause_reason: string
  recovery_action: string
}

export interface Handler {
  (reason: string, disableContinueToPause: boolean, tracking: Tracking): void
}

export interface AlternateOptionHandler {
  (data: { tracking: AlternateOptionTrackingData }): void
}

interface Props {
  handleChangeDeliveryFrequency: AlternateOptionHandler
  handleSkipMyDeliveries: AlternateOptionHandler
  handleOpenLiveChat: AlternateOptionHandler
  handleSelectedReason: Handler
  handleOtherReason: Handler
}

const PauseReasons = ({
  handleSelectedReason,
  handleOtherReason,
  handleChangeDeliveryFrequency,
  handleSkipMyDeliveries,
  handleOpenLiveChat,
}: Props) => {
  const [selected, setSelected] = useState('')
  const [somethingElseSelected, setSomethingElseSelected] = useState('')
  const [textareaValue, setTextareaValue] = useState('')
  const reasons = usePauseOSRData()
  const somethingElseOption = reasons.find((reason) => reason.value === 'Something else')

  const isEqualTo = useCallback(
    (value: string | number, equalsTo: string | number) => value === equalsTo,
    [],
  )
  const isSomethingElseReasonSelected = useCallback(
    () => isEqualTo(selected, 'Something else'),
    [selected, isEqualTo],
  )
  const isOtherReasonSelected = useCallback(
    () => isEqualTo(somethingElseSelected, 'Other'),
    [somethingElseSelected, isEqualTo],
  )

  const handleReasonChange = useCallback(
    (value) => {
      const disableContinueToPause = isEqualTo(value, 'Something else')

      setSelected(value)
      handleSelectedReason(value, disableContinueToPause, {
        reason: value,
      })
    },
    [handleSelectedReason, isEqualTo],
  )

  const handleSomethingElseChange = useCallback(
    (event) => {
      const disableContinueToPause = isEqualTo(event.target.value, 'Other')

      setSomethingElseSelected(event.target.value)
      handleSelectedReason(event.target.value, disableContinueToPause, {
        reason: 'Something else',
        secondaryReason: event.target.value,
      })
    },
    [handleSelectedReason, isEqualTo],
  )

  const handleOtherReasonChange = useCallback(
    (event) => {
      const textAreaFieldValue = event.target.value
      const disableContinueToPause = isEqualTo(textAreaFieldValue.length, 0)

      setTextareaValue(textAreaFieldValue)

      handleOtherReason(textAreaFieldValue, disableContinueToPause, {
        reason: 'Something else',
        secondaryReason: textAreaFieldValue,
      })
    },
    [handleOtherReason, isEqualTo],
  )

  useEffect(() => {
    if (!isOtherReasonSelected() && textareaValue.length > 0) {
      setTextareaValue('')
    }
  }, [isOtherReasonSelected, textareaValue])

  useEffect(() => {
    if (!isSomethingElseReasonSelected()) {
      setSomethingElseSelected('')
    }
  }, [isSomethingElseReasonSelected])

  return (
    <>
      <PauseRadioGroup
        onReasonChange={handleReasonChange}
        onChangeDeliveryFrequencyClick={handleChangeDeliveryFrequency}
        onSkipDeliveriesClick={handleSkipMyDeliveries}
        onOpenLiveChatClick={handleOpenLiveChat}
      />
      {isSomethingElseReasonSelected() && (
        <Box paddingH={5}>
          <RadioGroup
            value={somethingElseSelected}
            onChange={handleSomethingElseChange}
            defaultValue={somethingElseSelected}
            options={somethingElseOption?.reasons || []}
          />
          {isOtherReasonSelected() && (
            <Box position={DisplayPosition.Relative}>
              <CustomTextArea
                id="otherReason"
                name="otherReason"
                placeholder="Please tell us more..."
                onChange={handleOtherReasonChange}
                required={false}
                value={textareaValue}
                maxLength={TEXT_AREA_LENGTH_LIMIT}
              />
              <Box position={DisplayPosition.Absolute} bottom="8px" right="12px">
                <TextLengthCounter
                  textLength={textareaValue.length}
                  lengthLimit={TEXT_AREA_LENGTH_LIMIT}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export { PauseReasons }
