import React from 'react'

import {
  Box,
  Display,
  Icon,
  IconVariant,
  Space,
  IconNames,
  AlignItems,
} from '@gousto-internal/citrus-react'

type IconLineItemProps = {
  iconName: IconNames
  children: React.ReactNode
  align?: AlignItems
}

export const IconLineItem = ({
  iconName,
  children,
  align = AlignItems.FlexStart,
}: IconLineItemProps) => (
  <Box display={Display.Flex} alignItems={align} data-testid="IconLineItem">
    <Icon name={iconName} variant={IconVariant.Informative} />
    <Space size={2} direction="horizontal" />
    {children}
  </Box>
)
