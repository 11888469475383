import React from 'react'

import styled from '@emotion/styled'
import {
  colors,
  AlignItems,
  ButtonProps,
  Color,
  Display,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import { Loader } from 'goustouicomponents'

import { CheckoutButtonProps, CheckoutButtonStyleProps, ColorScheme } from './checkoutButtonModels'

const buildColorStyles = ({ textColor, bgColor, borderColor, outlineColor }: ColorScheme) => ({
  background: bgColor,
  border: `1px solid ${borderColor}`,
  color: textColor,
  outline: `1px solid ${outlineColor}`,
})

const StyledButton = styled.button(
  ({ kind, isFullWidth, hasHorizontalPadding }: CheckoutButtonStyleProps & ButtonProps) => {
    const blackColor = '#000000'
    const btnSchemes = {
      primary: {
        normal: {
          textColor: colors[Color.White],
          bgColor: colors[Color.Secondary_400],
          borderColor: colors[Color.Secondary_400],
          outlineColor: colors[Color.Transparent],
        },
        hover: {
          textColor: colors[Color.White],
          bgColor: colors[Color.Secondary_600],
          borderColor: colors[Color.Secondary_600],
          outlineColor: colors[Color.Transparent],
        },
        focus: {
          textColor: colors[Color.White],
          bgColor: colors[Color.Secondary_600],
          borderColor: colors[Color.White],
          outlineColor: colors[Color.Secondary_600],
        },
        disabled: {
          textColor: colors[Color.White],
          bgColor: colors[Color.NeutralGrey_200],
          borderColor: colors[Color.NeutralGrey_200],
          outlineColor: colors[Color.Transparent],
        },
      },
      secondary: {
        normal: {
          textColor: colors[Color.Secondary_400],
          bgColor: colors[Color.White],
          borderColor: colors[Color.Secondary_400],
          outlineColor: colors[Color.Transparent],
        },
        hover: {
          textColor: colors[Color.Secondary_600],
          bgColor: colors[Color.Secondary_50],
          borderColor: colors[Color.Secondary_600],
          outlineColor: colors[Color.Transparent],
        },
        focus: {
          textColor: colors[Color.Secondary_600],
          bgColor: colors[Color.Secondary_50],
          borderColor: colors[Color.Secondary_600],
          outlineColor: colors[Color.Secondary_600],
        },
        disabled: {
          textColor: colors[Color.ColdGrey_200],
          bgColor: colors[Color.White],
          borderColor: colors[Color.ColdGrey_200],
          outlineColor: colors[Color.Transparent],
        },
      },
      applepay: {
        normal: {
          textColor: colors[Color.White],
          bgColor: blackColor,
          borderColor: blackColor,
          outlineColor: colors[Color.Transparent],
        },
        hover: {
          textColor: colors[Color.White],
          bgColor: blackColor,
          borderColor: blackColor,
          outlineColor: colors[Color.Transparent],
        },
        focus: {
          textColor: colors[Color.White],
          bgColor: blackColor,
          borderColor: blackColor,
          outlineColor: colors[Color.Transparent],
        },
        disabled: {
          textColor: colors[Color.White],
          bgColor: colors[Color.NeutralGrey_200],
          borderColor: colors[Color.NeutralGrey_200],
          outlineColor: colors[Color.Transparent],
        },
      },
    }

    const btnScheme = btnSchemes[kind ?? 'primary']

    return {
      font: '18px/30px AxiformaSemiBold,Helvetica,sans-serif !important',
      display: Display.Flex,
      alignItems: AlignItems.Center,
      justifyContent: JustifyContent.Center,
      borderRadius: '3px',
      cursor: 'pointer',
      height: '3rem',
      width: isFullWidth ? '100%' : 'auto',
      padding: `0.5rem ${hasHorizontalPadding ? '1.5rem' : '0'}`,
      ...buildColorStyles(btnScheme.normal),
      '&:hover:not(:disabled)': buildColorStyles(btnScheme.hover),
      '&:focus:not(:disabled)': buildColorStyles(btnScheme.focus),
      '&:disabled, &:disabled:hover, &:disabled:focus': {
        cursor: 'not-allowed',
        ...buildColorStyles(btnScheme.disabled),
      },
    }
  },
)

export const CheckoutButton = ({
  children,
  onClick,
  testingSelector = 'checkoutCTA',
  kind,
  isFullWidth = true,
  isDisabled = false,
  isLoading = false,
  hasHorizontalPadding = true,
}: CheckoutButtonProps & CheckoutButtonStyleProps) => (
  <StyledButton
    disabled={isDisabled || isLoading}
    onClick={onClick}
    data-testid={testingSelector}
    type="submit"
    kind={kind}
    hasHorizontalPadding={hasHorizontalPadding}
    isFullWidth={isFullWidth}
  >
    {isLoading ? <Loader color={Color.White} /> : children}
  </StyledButton>
)
