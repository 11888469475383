import { ReactNode } from 'react'

import { BoxSizeStep } from 'routes/Signup/Steps/BoxSize/BoxSizeStep'
import { DeliveryStep } from 'routes/Signup/Steps/Delivery/DeliveryStep'
import { DietaryPreferencesStep } from 'routes/Signup/Steps/DietaryPreferences/DietaryPreferencesStep'
import { PostcodeStep } from 'routes/Signup/Steps/Postcode/PostcodeStep'
import { RecipePreferenceStep } from 'routes/Signup/Steps/RecipePreference/RecipePreferenceStep'
import { SignupSteps } from 'routes/Signup/constants/SignupSteps'

/**
 * SignupSteps to StepComponent relation.
 */
export const AVAILABLE_STEP_COMPONENTS: {
  [step in SignupSteps]: ReactNode
} = {
  [SignupSteps.BOX_SIZE]: BoxSizeStep,
  [SignupSteps.POSTCODE]: PostcodeStep,
  [SignupSteps.DELIVERY]: DeliveryStep,
  [SignupSteps.DIETARY_PREFERENCES]: DietaryPreferencesStep,
  [SignupSteps.RECIPE_PREFERENCES]: RecipePreferenceStep,
}
