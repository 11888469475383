import React, { useState } from 'react'

import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { ExpandContainer } from '../ExpandContainer'
import { trackToggleExpandOrderSummary } from '../snowplowActions'
import { Benefits } from './Benefits'
import { RecipeList } from './RecipeList'

export const YourBox = () => {
  const [expanded, setExpanded] = useState(false)
  const dispatch = useDispatch()

  const expandAction = () => {
    dispatch(trackToggleExpandOrderSummary(expanded === false))
    setExpanded(!expanded)
  }

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.FlexStart}
      alignItems={AlignItems.FlexStart}
      data-testid="YourBoxContainer"
    >
      <ExpandContainer expandAction={expandAction} expanded={expanded} />
      <RecipeList expanded={expanded} />
      <Benefits />
    </Box>
  )
}
