import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  JustifyContent,
  Text,
} from '@gousto-internal/citrus-react'

type DividerProps = {
  text: string
  noBottomOffset?: boolean
  children?: React.ReactNode
}

// FYI: this func exists only for tests
// This is more like mock for test
// Because of this feature type is experiment
// We need to release it quickly
// Tests will be covered during productivization
const getDataTestId = (textPresented: boolean, childrenPresented: boolean) => {
  if (textPresented && childrenPresented) return 'SectionHeadingFull'
  else if (textPresented && !childrenPresented) return 'SectionHeadingTextNoChildren'
  else if (!textPresented && childrenPresented) return 'SectionHeadingNoTextWithChildren'
  else return 'SectionHeadingNoTextNoChildren'
}

export const SectionHeading = ({ text = '', noBottomOffset = false, children }: DividerProps) => (
  <Box
    paddingBottom={noBottomOffset ? 0 : 3}
    display={Display.Flex}
    flexDirection={FlexDirection.Row}
    justifyContent={JustifyContent.SpaceBetween}
    alignItems={AlignItems.Center}
    data-testid={getDataTestId(Boolean(text), Boolean(children))}
  >
    <Text
      size={3}
      fontWeight={FontWeight.Bold}
      color={Color.ColdGrey_800}
      style={{ lineHeight: '27px' }}
    >
      {text}
    </Text>

    {children}
  </Box>
)
