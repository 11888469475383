import React from 'react'

import {
  Box,
  Icon,
  Text,
  Color,
  FontFamily,
  FontWeight,
  Display,
  FlexDirection,
  JustifyContent,
  AlignItems,
} from '@gousto-internal/citrus-react'

import { useBasket } from 'routes/Menu/domains/basket'
import { usePricing } from 'routes/Menu/domains/pricing'

import { SectionHeading } from '../SharedComponents'
import { ExpandContainerWrapper, ChevronIconWrapper } from './styled'

type ExpandContainerProps = {
  expandAction: () => void
  expanded: boolean
  isMobile?: boolean
}

export const ExpandContainer = ({
  expandAction,
  expanded,
  isMobile = false,
}: ExpandContainerProps) => {
  const { pricing } = usePricing()
  const { numPortions } = useBasket()

  return (
    <ExpandContainerWrapper onClick={() => expandAction()} data-testid="ExpandContainer">
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.SpaceBetween}
        flexGrow={1}
      >
        <SectionHeading text={`Your box (${numPortions} people)`} noBottomOffset />
        {pricing && pricing.promoCodeValid && isMobile && (
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.SpaceBetween}
            alignItems={AlignItems.Center}
            gap={2}
          >
            <Text
              size={2}
              color={Color.ColdGrey_800}
              fontFamily={FontFamily.Book}
              style={{ textDecoration: 'line-through' }}
            >
              £{pricing.recipeTotal}
            </Text>
            <Text size={3} color={Color.Success_800} fontWeight={FontWeight.Bold}>
              £{pricing.total}
            </Text>
          </Box>
        )}
      </Box>
      <ChevronIconWrapper expanded={expanded}>
        <Icon name="chevron_down" />
      </ChevronIconWrapper>
    </ExpandContainerWrapper>
  )
}
