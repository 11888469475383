import type { UserCookBookResponse } from './interfaces'
import { ErrorResponse } from '@library/http/src/parsers/errors'

export async function extractUserCookbookResponse(
  response: Response,
): Promise<UserCookBookResponse> {
  if (!response.ok) {
    throw new ErrorResponse(response)
  }

  const parsedJSON = JSON.parse(await response.text())

  const result = parsedJSON as Record<string, unknown>

  return result as UserCookBookResponse
}
