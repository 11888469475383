import config from 'config/subscription'
import moment from 'moment'
import * as trackingKeys from 'actions/trackingKeys'
import { getUserId } from 'selectors/user'
import { deactivateSubscription } from '../routes/Account/apis/subscription'
import statusActions from './status'
import { actionTypes } from './actionTypes'

const pauseModalTrackingPrefix = config.tracking.pauseModalPrefix

const subPauseActions = {
  subscriptionDeactivate,
  subscriptionPauseTrack,
  subscriptionPauseTrackRecover,
}

function subscriptionPauseTrack(key, data = {}) {
  return {
    type: actionTypes.TRACKING,
    trackingData: {
      actionType: `${pauseModalTrackingPrefix}${key}`,
      ...data,
    },
  }
}

function subscriptionPauseTrackRecover() {
  return {
    type: actionTypes.TRACKING,
    trackingData: {
      actionType: trackingKeys.recoverSubscription,
    },
  }
}

function subscriptionDeactivate(pauseReason = '') {
  return async (dispatch, getState) => {
    dispatch(statusActions.pending(actionTypes.SUBSCRIPTION_DEACTIVATE, true))
    dispatch(statusActions.error(actionTypes.SUBSCRIPTION_DEACTIVATE, false))
    const accessToken = getState().auth.get('accessToken')

    try {
      const state = getState()
      const pauseDate = moment().format('YYYY-MM-DD')
      const userId = getUserId(state)

      await deactivateSubscription(accessToken, pauseDate, userId, pauseReason)
    } catch (err) {
      dispatch(statusActions.error(actionTypes.SUBSCRIPTION_DEACTIVATE, 'deactivate-fail'))
    } finally {
      dispatch(statusActions.pending(actionTypes.SUBSCRIPTION_DEACTIVATE, false))
    }
  }
}

export default subPauseActions
