import React from 'react'

import { AlignItems, Box, Display } from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { MenuRecipe } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { RecipeAttributePill } from '../../RecipeAttributePill/RecipeAttributePill'
import { formatRatingText, formatKcalText, formatCookingTimeText } from './formatters'

import css from './AttributeGrid.css'

type AttributeGridProps = {
  recipe: MenuRecipe
  loading?: boolean
}

const LoadingSkeleton = ({ style }: { style?: React.CSSProperties }) => (
  <span className={css.loadingSkeleton} style={style} data-testid="LoadingSkeleton" />
)

export const RecipeSummaryGrid = ({ recipe, loading }: AttributeGridProps) => {
  const {
    prep_time: cookingTime,
    rating,
    nutritional_information: { per_portion: { energy_kcal: kcalPerPortion } = {} } = {},
  } = recipe

  if (loading) {
    return (
      <div className={classnames(css.attributes)}>
        {[...Array(4)].map(() => (
          <LoadingSkeleton key={Math.random()} style={{ height: '10px', marginBottom: '10px' }} />
        ))}
      </div>
    )
  }

  if (!kcalPerPortion && !cookingTime && !rating) {
    return null
  }

  return (
    <Box
      alignItems={AlignItems.FlexStart}
      wrap
      data-testid="RecipeAttribute"
      display={Display.Flex}
    >
      {rating && rating.count > 0 && (
        <RecipeAttributePill icon="rate_full" text={formatRatingText(rating)} />
      )}

      {kcalPerPortion && (
        <RecipeAttributePill icon="calories" text={formatKcalText(kcalPerPortion)} />
      )}

      {cookingTime && <RecipeAttributePill icon="time" text={formatCookingTimeText(cookingTime)} />}
    </Box>
  )
}
