import React, { useCallback } from 'react'

import {
  AlignItems,
  Box,
  Button,
  Display,
  FlexDirection,
  Heading1,
  Join,
  Space,
  Body1,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import fetchData from 'routes/Menu/fetchData/fetchData'

export const MenuError = () => {
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(fetchData({ query: {}, params: {} }, true, false, { addRecipe: () => {} }))
  }, [dispatch])

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      paddingV={4}
    >
      <Join with={<Space size={3} />}>
        <Heading1>Something went wrong</Heading1>
        <Body1>Try to reload the data to resolve the issue.</Body1>
        <Button onClick={onClick} data-testid="refreshMenu">
          Refresh
        </Button>
      </Join>
    </Box>
  )
}
