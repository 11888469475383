import React, { useCallback } from 'react'

import {
  Body2,
  Heading3,
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalButton,
  ButtonColorVariant,
  ButtonSize,
  useModal,
} from '@gousto-internal/citrus-react'

interface Props {
  onClose: () => void
  modalName: string
  isModalOpen?: boolean
}

export const MultiSkipEmptyScreen = ({ onClose, modalName, isModalOpen }: Props) => {
  const { closeCurrentModal } = useModal()

  const handleClose = useCallback(() => {
    onClose()
    closeCurrentModal()
  }, [onClose, closeCurrentModal])

  return (
    <Modal isOpen={isModalOpen} name={modalName}>
      <ModalClose onClose={closeCurrentModal} />
      <ModalHeader>
        <Heading3>No boxes left to skip</Heading3>
      </ModalHeader>

      <ModalBody>
        <Body2>Unfortunately, you’ve skipped all boxes you can right now.</Body2>
      </ModalBody>

      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Large}
          onClick={handleClose}
        >
          Okay
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

MultiSkipEmptyScreen.defaultProps = {
  isModalOpen: false,
}
