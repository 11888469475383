import React from 'react'

import { onEnter } from 'utils/accessibility'

import { StyledButton } from './DiscountBarContainer.styled'

export type DiscountBarProps = {
  isHidden: boolean
  isSticky: boolean
  promoText: string
  applyDiscount: () => void
}

export type DiscountBarContainerProps = Omit<DiscountBarProps, 'promoText'> & {
  children: React.ReactNode
}

export const DiscountBarContainer = ({
  isHidden,
  isSticky,
  children,
  applyDiscount,
}: DiscountBarContainerProps) => (
  <StyledButton
    role="button"
    tabIndex={0}
    onClick={applyDiscount}
    onKeyDown={onEnter(applyDiscount)}
    isHidden={isHidden}
    isSticky={isSticky}
  >
    {children}
  </StyledButton>
)
