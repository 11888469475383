import {connect} from 'react-redux'
import {TestUserBanner} from './TestUserBanner'

const mapStateToProps = (state) => ({
  isTestUser: state.user.get('isTestUser')
})

const TestUserBannerContainer = connect(mapStateToProps)(TestUserBanner)

export { TestUserBannerContainer }
