import isomorphicFetch from 'isomorphic-fetch'
import { timeout as fetchWithTimeout } from 'promise-timeout'
import qs from 'qs'

import { isServer } from '@library/environment'
import { getServerEnvConfig } from '@library/environment/server'

import { JSONParse, processJSON } from 'utils/jsonHelper'

import { isProd } from './isomorphicEnvironment'

const DEFAULT_TIME_OUT = 50000
const STATUS_CODES_WITH_NO_CONTENT = [204]

export const dataDefault = {}
export const methodDefault = 'GET'
export const cacheDefault = 'default'
export const headerDefault = {}
export const timeoutDefault = null
export const includeCookiesDefault = false
export const useMenuServiceDefault = false
export const useOverwriteRequestMethodDefault = true

export function fetch(
  accessToken,
  url,
  data = dataDefault,
  method = methodDefault,
  cache = cacheDefault,
  headers = headerDefault,
  timeout = timeoutDefault,
  includeCookies = includeCookiesDefault,
  useMenuService = useMenuServiceDefault,
  useOverwriteRequestMethod = useOverwriteRequestMethodDefault,
) {
  const requestData = {
    ...data,
  }
  let httpMethod = method.toUpperCase()
  if (useOverwriteRequestMethod && (method === 'PUT' || method === 'PATCH')) {
    requestData._method = httpMethod // eslint-disable-line no-underscore-dangle
    httpMethod = 'POST'
  }

  let body = ''
  let requestUrl = url
  if (requestUrl[requestUrl.length - 1] === '/') {
    requestUrl = requestUrl.substr(0, requestUrl.length - 1)
  }
  let requestHeaders = headers
  let queryString
  if (httpMethod === 'GET') {
    queryString = qs.stringify(requestData)
    if (queryString) {
      requestUrl += `?${queryString}`
    }
  } else {
    const contentType = requestHeaders['Content-Type']
    const isContentTypeJSON = contentType === 'application/json'

    body = isContentTypeJSON ? JSON.stringify(requestData) : qs.stringify(requestData)

    if (!contentType) {
      requestHeaders = {
        ...requestHeaders,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }
  }

  if (accessToken) {
    requestHeaders = { ...requestHeaders, Authorization: `Bearer ${accessToken}` }
  }

  if (isServer() && isProd()) {
    requestHeaders['API-Token'] = getServerEnvConfig().API_TOKEN
  }

  const requestDetails = {
    method: httpMethod,
    headers: requestHeaders,
    cache,
  }

  if (includeCookies) {
    requestDetails.credentials = 'same-origin'
  }

  if (cache === 'no-store' || cache === 'reload' || cache === 'no-cache') {
    requestUrl += queryString ? '&' : '?'
    requestUrl += `_=${Date.now()}`
  }

  if (body) {
    requestDetails.body = body
  }

  if (timeout) {
    requestDetails.timeout = timeout
  }

  let responseStatus
  let responseRedirected
  let responseUrl

  const fetchPromise = isomorphicFetch(requestUrl, requestDetails)

  return fetchWithTimeout(fetchPromise, timeout || DEFAULT_TIME_OUT)
    .then((response) => {
      const { status, redirected, url: endpointOrRedirectedUrl } = response
      responseStatus = status
      responseRedirected = redirected
      responseUrl = endpointOrRedirectedUrl

      return response
    })
    .then((response) => response.text())
    .then((response) => {
      if (!response && STATUS_CODES_WITH_NO_CONTENT.includes(responseStatus)) {
        return [{}, responseStatus]
      }

      return [JSONParse(response, useMenuService), responseStatus]
    }) // eslint-disable-line new-cap
    .then(processJSON) /* TODO - try refresh auth token and repeat request if successful */
    .then(({ response, meta }) => {
      if (useMenuService) {
        return { data: response.data, included: response.included, meta: response.meta }
      }

      return { data: response, meta }
    })
    .catch((e) => {
      const message = e instanceof Error || e.message ? e.message : e
      const error = new Error(message)
      error.code = e.code || 500
      error.errors = e.errors || []
      error.message = message
      error.status = responseStatus || 500
      error.redirected = responseRedirected
      error.errorDetails = e.errorDetails
      error.url = responseUrl
      throw error
    })
}

export function fetchRaw(url, data = {}, options) {
  return fetch(
    options.accessToken,
    url,
    data,
    options.method,
    options.cache,
    options.headers,
    options.timeout,
    options.includeCookies,
    options.useMenuService,
    options.useOverwriteRequestMethod,
  )
}

export default fetch
