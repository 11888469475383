import { actionTypes } from './actionTypes'

export const saveDeliveryDetailsInstruction = (deliveryInstruction: string) => ({
  type: actionTypes.SAVE_DELIVERY_DETAILS_INSTRUCTION,
  deliveryInstruction,
})

export const saveDeliveryDetailsInstructionCustom = (deliveryInstructionCustom: string) => ({
  type: actionTypes.SAVE_DELIVERY_DETAILS_INSTRUCTION_CUSTOM,
  deliveryInstructionCustom,
})
