import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  DisplayPosition,
  JustifyContent,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { Recipe } from '../../model/recipe'

type SoldOutOverlayProps = {
  backgroundColor?: Color | string
  isRecipeOutOfStock: (recipeId: string) => boolean
  recipe: Recipe
}

export function SoldOutOverlay({
  backgroundColor = Color.White,
  isRecipeOutOfStock,
  recipe,
}: SoldOutOverlayProps) {
  const { id: recipeId } = recipe

  if (!recipeId) {
    return null
  }

  const isOutOfStock = isRecipeOutOfStock(recipeId)

  if (!isOutOfStock) {
    return null
  }

  const OverlayDiv = withCitrusTheme(Box, (theme) => ({
    position: DisplayPosition.Absolute,
    top: 0,
    left: '0',
    height: '100%',
    width: '100%',
    opacity: 0.7,
    zIndex: '11',
    display: Display.Flex,
    justifyContent: JustifyContent.Center,
    alignItems: AlignItems.Center,
    paddingV: 0,
    paddingH: 3,
    backgroundColor:
      backgroundColor in Color ? theme.color(backgroundColor as Color) : backgroundColor,
  }))

  return <OverlayDiv id="overlayDiv" />
}
