type Action = 'link_click' | 'sticky_cta_state_change'

type EventName =
  | 'main_cta_get_started'
  | 'main_cta_build_your_box'
  | 'secondary_cta_get_started_without_discount'
  | 'secondary_cta_build_your_box_without_discount'
  | 'secondary_cta_get_started_with_discount'
  | 'tertiary_cta_get_started'
  | 'tertiary_cta_build_your_box'
  | 'sticky_footer_cta_with_discount'

export type RafTrackingEventType = {
  action: Action
  properties: {
    component_name: EventName
    in_view?: string
  }
}

export const heroGetStartedTrackingEvent = (): RafTrackingEventType => {
  return {
    action: 'link_click',
    properties: {
      component_name: `main_cta_${'get_started'}`,
    },
  }
}

export const priceComparisonGetStartedTrackingEvent: RafTrackingEventType = {
  action: 'link_click',
  properties: {
    component_name: 'secondary_cta_get_started_with_discount',
  },
}

export const peekInsideBoxGetStartedTrackingEvent = (): RafTrackingEventType => {
  return {
    action: 'link_click',
    properties: {
      component_name: `tertiary_cta_${'get_started'}`,
    },
  }
}

export const valueForMoneyTrackingEvent = (): RafTrackingEventType => {
  return {
    action: 'link_click',
    properties: {
      component_name: `secondary_cta_${'get_started'}_without_discount`,
    },
  }
}

export const FooterStickyGetStartedTrackingEvent: RafTrackingEventType = {
  action: 'link_click',
  properties: {
    component_name: 'sticky_footer_cta_with_discount',
  },
}

export const StickyTrackingEvent = ({
  isVisible,
  name,
}: {
  isVisible: boolean
  name: EventName
}): RafTrackingEventType => ({
  action: 'sticky_cta_state_change',
  properties: {
    component_name: name,
    in_view: String(isVisible),
  },
})
