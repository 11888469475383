import menuFetchData from './fetchData'

export const loadMenuServiceDataIfDeepLinked =
  (isSignUpPage, addRecipe) => async (dispatch, getState) => {
    const state = getState()
    const menuServiceData = state.menuService

    const hasNoMenuDataAlready =
      !menuServiceData || !menuServiceData.data || !menuServiceData.data.length

    if (hasNoMenuDataAlready) {
      await dispatch(menuFetchData({ query: {}, params: {} }, false, true, { addRecipe }))
    }
  }
