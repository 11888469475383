import React from 'react'

import {
  Color,
  Display,
  FontFamily,
  FontSize,
  ResponsiveValue,
  Text,
} from '@gousto-internal/citrus-react'

export const PercentageWithOff = ({
  percentageSize,
  offSize,
}: {
  percentageSize: ResponsiveValue<FontSize>
  offSize: ResponsiveValue<FontSize>
}) => (
  <>
    <Text
      color={Color.White}
      display={Display.Inline}
      fontFamily={FontFamily.Bold}
      size={percentageSize}
    >
      %
    </Text>
    <Text color={Color.White} display={Display.Inline} fontFamily={FontFamily.Bold} size={offSize}>
      OFF
    </Text>
  </>
)
