import { RequestConfig, composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setHeader,
  auth,
} from '@library/http/requests'
import { CreateRecipeFeedbackPostRequest, UserFeedbackResponse } from './types'

// throws error or returns nothing
const recipeFeedbackParsePostRequest = async (response: Response): Promise<void> => {
  if (!response.ok) {
    let errorData
    try {
      errorData = await response.json()
    } catch (e) {
      errorData = { error: 'Error submitting preferences. Failed to parse error response' }
    }
    throw new Error(errorData.error)
  }
}

const recipeFeedbackPostRequest = composeRequest<CreateRecipeFeedbackPostRequest>(
  setServiceUrl('userfeedback', 2),
  addPath((input) => `order/${input.orderId}`),
  setMethod('POST'),
  setHeader('Content-Type', 'application/json'),
  (req: RequestConfig, input: CreateRecipeFeedbackPostRequest) => {
    req.body = JSON.stringify(input.body)
    return req
  },
  auth,
)

// POST Request
export const postRecipeFeedback = composeFetch(
  recipeFeedbackPostRequest,
  recipeFeedbackParsePostRequest,
)

const recipeFeedbackParseGetRequest = async (response: Response): Promise<UserFeedbackResponse> => {
  if (!response.ok) {
    let errorData
    try {
      errorData = await response.json()
    } catch (e) {
      errorData = { error: 'Error while fetching recipe feedback' }
    }
    throw new Error(errorData.error)
  }
  return response.json()
}

const recipeFeedbackGetRequest = composeRequest(
  setServiceUrl('userfeedback', 1),
  addPath('feedback'),
  setMethod('GET'),
  auth,
)

export const fetchRecipeFeedback = composeFetch(
  recipeFeedbackGetRequest,
  recipeFeedbackParseGetRequest,
)
