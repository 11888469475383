import React from 'react'
import { AlignItems, Box, Image, JustifyContent } from '@gousto-internal/citrus-react'

const getImage = (fileName: string) => require(`../../../media/images/${fileName}`) // eslint-disable-line global-require

export const Loader = () => {
  return (
    <Box
      display="flex"
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      width="100%"
      paddingV={20}
    >
      <Image src={getImage('Loading-Icon.gif')} width="100" alt="loader" />
    </Box>
  )
}
