import React, { useCallback } from 'react'

import {
  Box,
  Text,
  Display,
  JustifyContent,
  FontWeight,
  Space,
  Icon,
  IconVariant,
  Color,
  AlignItems,
} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'

import routes from 'config/routes'

interface Props {
  portionsCount: string
  orderId: string
}

export const OrderBoxSize = ({ orderId, portionsCount }: Props) => {
  const onCTAClick = useCallback(() => {
    browserHistory.push(`${routes.client.menu}/${orderId}`)
  }, [orderId])

  return (
    <div data-testing="orderBoxSize">
      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Text fontWeight={FontWeight.Bold}>{`${portionsCount} person box`}</Text>
        <Box
          display={Display.Flex}
          alignItems={AlignItems.Center}
          role="button"
          onClick={onCTAClick}
          data-testid="order-box-size-button"
        >
          <Text size={1} color={Color.Secondary_400}>
            Edit
          </Text>
          <Space size={1} direction="horizontal" />
          <Icon name="edit" variant={IconVariant.Actionable} size={5} />
        </Box>
      </Box>
    </div>
  )
}
