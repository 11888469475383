import { composeFetch } from '@library/http'
import { auth, composeRequest, addPath, setServiceUrl, setQueryParam } from '@library/http/requests'

import { RecipeAvailability, RecipeAvailabilityByDateRangeParams } from './types'
import { extractCoreData } from '@library/http/src/parsers'

const getRecipeAvailabilityByDeliveryDateRangeRequest = composeRequest(
  setServiceUrl('core'),
  addPath('recipes/availability'),
  setQueryParam(
    'filters[delivery_date_from]',
    (params: RecipeAvailabilityByDateRangeParams) => params.deliveryDateFrom,
  ),
  setQueryParam(
    'filters[delivery_date_until]',
    (params: RecipeAvailabilityByDateRangeParams) => params.deliveryDateUntil,
  ),
  auth,
)

export const getRecipeAvailabilityByDeliveryDateRange = composeFetch(
  getRecipeAvailabilityByDeliveryDateRangeRequest,
  extractCoreData<RecipeAvailability[]>(),
)
