import React from 'react'

import {
  Box,
  Display,
  Text,
  Space,
  Color,
  Image,
  FlexDirection,
  Heading3,
  TextAlign,
  FontWeight,
  JustifyContent,
  AlignItems,
  Overflow,
} from '@gousto-internal/citrus-react'

import coupleSharingfoodImage from '../../assets/couple-sharing-food.jpg'

export const FreeDeliveryCard = () => (
  <Box
    data-testid="freeDeliveryCard"
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    width="100%"
    bg={Color.White}
    paddingH={3}
    paddingV={3}
    gap={3}
  >
    <Box
      height="112px"
      display={Display.Flex}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
    >
      <Image
        src={coupleSharingfoodImage}
        title="Sharing a Cozy Meal Together on the Couch"
        alt="A couple enjoying healthy bowl meals together"
        style={{
          objectFit: 'cover',
        }}
      />
    </Box>
    <Box>
      <Heading3 color={Color.Success_800} size={8} textAlign={TextAlign.Center}>
        <Text
          color={Color.ColdGrey_800}
          display={Display.Inline}
          size={8}
          fontWeight={FontWeight.Bold}
        >
          Enjoy
        </Text>{' '}
        free delivery for a year!
      </Heading3>
      <Space size={1} />
      <Text size={[2, 4]} textAlign={TextAlign.Center}>
        As a thank you for trying Gousto, we’ll cover your delivery costs for the first year.
      </Text>
    </Box>
  </Box>
)
