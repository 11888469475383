import React, { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Box, FlexDirection, Join, Space, Link } from '@gousto-internal/citrus-react'
import type { DeliveryPoint } from '@library/api-postcode-lookup'
import type { ShippingAddress } from '@library/api-user'
import { AddressLookupButton } from './AddressLookupButton'
import { AddressLookupDropdown } from './AddressLookupDropdown'
import {
  AddressCountyInput,
  AddressLineOne,
  AddressLineThree,
  AddressLineTwo,
  AddressTownInput,
  AddressPostcodeInput,
} from '../../../Form/Inputs'

const emptyAddress = {
  postcode: '',
  line1: '',
  line2: '',
  line3: '',
  town: '',
  county: '',
  udprn: '',
}

const AddressLookup = ({ deliveryPoint }: { deliveryPoint?: Omit<DeliveryPoint, 'udprn'> }) => {
  const { setValues, values, errors, setFieldError } = useFormikContext<Partial<ShippingAddress>>()
  const [deliveryPoints, setDeliveryPoints] = useState<DeliveryPoint[]>([])
  const [selectedAddress, setSelectedAddress] = useState<Omit<DeliveryPoint, 'udprn'> | undefined>(
    deliveryPoint,
  )
  const onDeliveryPointsFetched = useCallback(
    (deliveryPoints) => {
      setValues({ ...values, ...emptyAddress, postcode: values.postcode })
      setSelectedAddress(undefined)
      setDeliveryPoints(deliveryPoints)
    },
    [values, setValues, setSelectedAddress, setDeliveryPoints],
  )
  const onAddressSelected = useCallback(
    (addressEntry) => {
      setValues({ ...values, ...addressEntry })
      setFieldError('line1', undefined)
      setFieldError('town', undefined)
      setSelectedAddress(addressEntry)
    },
    [values, setValues, setFieldError, setSelectedAddress],
  )
  const onEnterAddressManuallyClick = useCallback(() => {
    setValues({ ...values, ...emptyAddress, postcode: values.postcode })
    setFieldError('line1', undefined)
    setFieldError('town', undefined)
    setSelectedAddress(emptyAddress)
  }, [values, setValues, setFieldError, setSelectedAddress])

  useEffect(() => {
    if (selectedAddress || deliveryPoints.length > 0) {
      return
    }

    // this expands "Edit address manually" content in case if submit button is pressed when postcode lookup is not finished yet
    if ('town' in errors || 'line1' in errors) {
      setSelectedAddress(emptyAddress)
    }
  }, [errors, deliveryPoints, selectedAddress, setSelectedAddress])

  return (
    <Join with={<Space size={2} />}>
      <Box display="flex" flexDirection={FlexDirection.Row}>
        <Box paddingRight={2} flexGrow={2}>
          <AddressPostcodeInput />
        </Box>
        <Box>
          {/* Citrus Issue: https://github.com/Gousto/citrus/issues/416 */}
          <Space size={8} direction="vertical" />
          <AddressLookupButton onDeliveryPointsFetched={onDeliveryPointsFetched} />
        </Box>
      </Box>
      {selectedAddress ? (
        <Join with={<Space size={2} />}>
          <AddressLineOne />
          <AddressLineTwo />
          <AddressLineThree />
          <AddressTownInput />
          <AddressCountyInput />
        </Join>
      ) : (
        <Join with={<Space size={2} />}>
          <AddressLookupDropdown addresses={deliveryPoints} onAddressSelected={onAddressSelected} />
          <Link size={2} onClick={onEnterAddressManuallyClick}>
            Enter address manually
          </Link>
        </Join>
      )}
    </Join>
  )
}

export { AddressLookup }
