import React, { FC } from 'react'
import { SubmitFeedback } from '../SubmitFeedback'

interface Props {
  isSubmitting: boolean
  submitted: boolean
  comment: string
  onCommentUpdate: (comment: string) => void
  onSubmit: () => void
}

export const PerfectFlow: FC<Props> = (props) => {
  const { isSubmitting, onCommentUpdate, submitted, comment, onSubmit } = props

  return (
    <SubmitFeedback
      onSubmit={onSubmit}
      title="What was your favourite bit?"
      placeholder="E.g. so tasty and hardly any washing up"
      submitting={isSubmitting}
      submitted={submitted}
      comment={comment}
      setComment={onCommentUpdate}
    />
  )
}
