import React, { useEffect, useMemo } from 'react'
import { Field, FieldProps, useField } from 'formik'
import {
  Input,
  FormFieldStatus,
  FieldValidationMessage,
  Space,
  Join,
} from '@gousto-internal/citrus-react'
import { isCustomDeliveryInstruction } from '@library/api-user'
import { AddressFields } from '../../../Form/Inputs'

const DeliveryInstructionsInput = () => {
  const [deliveryInstructionsField] = useField(AddressFields.DeliveryInstructions)
  const [
    deliveryInstructionsCustomField,
    deliveryInstructionsCustomFieldMeta,
    deliveryInstructionsCustomFieldHelpers,
  ] = useField(AddressFields.DeliveryInstructionsCustom)
  const isCustom = isCustomDeliveryInstruction(deliveryInstructionsField.value)
  const customInstructionText = deliveryInstructionsCustomField.value
  const placeholder = useMemo(
    () => (deliveryInstructionsField.value === 'Other' ? 'E.g. With flat C' : 'Door number'),
    [deliveryInstructionsField.value],
  )

  useEffect(() => {
    if (isCustom || customInstructionText === '') {
      return
    }

    // reset state if non-custom delivery instruction selected
    deliveryInstructionsCustomFieldHelpers.setValue('')
    deliveryInstructionsCustomFieldHelpers.setError(undefined)
  }, [isCustom, customInstructionText, deliveryInstructionsCustomFieldHelpers])

  if (!isCustom) {
    return null
  }

  return (
    <Field name="deliveryInstructionsCustom">
      {({ field }: FieldProps) => (
        <Join with={<Space size={2} />}>
          <Input
            id="deliveryInstructionsCustom"
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            data-testid="deliveryInstructionsCustom"
            status={deliveryInstructionsCustomFieldMeta.error ? FormFieldStatus.Error : undefined}
            {...field}
          />
          {deliveryInstructionsCustomFieldMeta.error && (
            <FieldValidationMessage>
              {deliveryInstructionsCustomFieldMeta.error}
            </FieldValidationMessage>
          )}
        </Join>
      )}
    </Field>
  )
}

export { DeliveryInstructionsInput }
