import React from 'react'

import Immutable from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import { RecipePreferences } from '@features/recipe-preferences'

import { signupSetIsRecipeConfirmed, signupSetRecipePreferences } from 'routes/Signup/signupActions'
import { getSignupRecipePreferences } from 'routes/Signup/signupSelectors'

import { useRecipesList } from '../../hooks/useRecipeList'

type RecipePreferenceStepProps = {
  next: () => void
}

export const RecipePreferenceStep = ({ next: _next }: RecipePreferenceStepProps) => {
  const dispatch = useDispatch()
  const recipePreferences: Immutable.List<string> = useSelector(getSignupRecipePreferences)
  const recipesList = useRecipesList()

  const handleSkipCTAClick = (recipePreferencesChange: Immutable.List<string>) => {
    dispatch(signupSetIsRecipeConfirmed(false))
    dispatch(signupSetRecipePreferences(recipePreferencesChange))
    _next()
  }

  const handleSeeMenuCTAClick = (recipePreferencesChange: Immutable.List<string>) => {
    // Filter out any recipe preferences that are not in the current recipes list
    const recipePreferencesChangeCleared = Immutable.List(
      recipePreferencesChange.filter((pref) => recipesList.some((recipe) => recipe.uuid === pref)),
    ) as Immutable.List<string>
    dispatch(signupSetIsRecipeConfirmed(true))
    dispatch(signupSetRecipePreferences(recipePreferencesChangeCleared))
    _next()
  }

  return (
    <RecipePreferences
      defaultRecipePreferences={recipePreferences}
      recipes={recipesList}
      onClickSkip={handleSkipCTAClick}
      onClickSeeMenu={handleSeeMenuCTAClick}
    />
  )
}
