export type ChoiceRelationship = {
  id: string
  type: string
}

type QuestionRelationship = {
  id: string
  type: string
}

type ItemRelationship = {
  id: string
  type: string
}

export type Answer = {
  type: string
  attributes: AttributesPayload
  relationships: {
    choices: {
      data: ChoiceRelationship[]
    }
    question: {
      data: QuestionRelationship
    }
    item: {
      data: ItemRelationship
    }
  }
}

type AttributesPayload = {
  comment?: string
  skipped?: boolean
}

export type FeedbackRatingRequestData = {
  data: Answer[]
}

export type FeedbackRatingRequestDataProps = {
  questionId: string
  otifs?: string[]
  recipeId: string
  rating: number | string
  comment?: string
  orderId: string
}

function formatStarRating(choice: string | number): string {
  switch (choice) {
    case 5:
      return 'five-star-id'
    case 4:
      return 'four-star-id'
    case 3:
      return 'three-star-id'
    case 2:
      return 'two-star-id'
    case 1:
      return 'one-star-id'
    default:
      throw new Error(`Unexpected choice: ${choice}`)
  }
}

const formatRatingReasons = (otifs: string[], recipeId: string) => {
  return {
    type: 'multiple-choice-answer',
    attributes: {
      skipped: false,
    },
    relationships: {
      choices: {
        data: otifs.map((otif) => ({ id: otif, type: 'choice' })),
      },
      question: {
        data: {
          id: 'ac41410a-0524-43f6-9e5a-042090a2944e',
          type: 'multiple-choice-question',
        },
      },
      item: {
        data: {
          id: recipeId,
          type: 'recipe',
        },
      },
    },
  }
}

export const formatFeedbackRatingRequest = (
  questionId: string,
  recipeId: string,
  rating: string | number,
  comment?: string,
  otifs?: string[],
): FeedbackRatingRequestData => {
  const scoreId = formatStarRating(rating)

  const answers = []

  answers.push({
    type: 'five-point-star-answer',
    attributes: {
      skipped: false,
      comment,
    },
    relationships: {
      choices: {
        data: [{ id: scoreId, type: 'choice' }],
      },
      question: {
        data: {
          id: questionId,
          type: 'five-point-star-question',
        },
      },
      item: {
        data: {
          id: recipeId,
          type: 'recipe',
        },
      },
    },
  })

  if (otifs) {
    answers.push(formatRatingReasons(otifs, recipeId))
  }

  return { data: answers }
}
