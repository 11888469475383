import React from 'react'

import { Route } from 'react-router'

import config from 'config/routes'
import { CheckoutLayout } from 'routes/Checkout/CheckoutLayout'
import { checkGuest } from 'utils/routes'

import { Checkout as CheckoutComponent } from './Checkout'

const path = `${config.client['check-out']}(/:stepName)`

export const Checkout = (store) => (
  <Route component={CheckoutLayout} onEnter={checkGuest(store, '/menu')}>
    <Route path={path} component={CheckoutComponent} />
  </Route>
)
