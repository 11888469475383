const pound = String.fromCharCode(163)

export function formatPrice(amount) {
  return `${pound}${parseFloat(amount).toFixed(2)}`
}

export function getFirstPartPostcode(postcode) {
  return postcode.slice(0, -3).trim().toLowerCase()
}

export const formatLabelPlural = (string, numItem) => {
  if (numItem > 1) {
    return `${string}s (${numItem})`
  } else if (numItem === 1) {
    return `${string} (${numItem})`
  }

  return string
}

export const formatRecipeDiscount = (recipeDiscountPercent) => {
  if (recipeDiscountPercent) {
    return `${Math.ceil(recipeDiscountPercent)}% discount`
  }

  return 'Discount'
}

export const formatSurcharge = (value) => {
  const formattedValue = Math.abs(value).toFixed(2)

  if (value === 0) return `£${formattedValue}`

  return value < 0 ? `-£${formattedValue}` : `+£${formattedValue}`
}
