import React from 'react'
import { Box, Body2, Space, JustifyContent } from '@gousto-internal/citrus-react'

export const Label: React.FC<LabelProps> = ({ text, backgroundColor, textColor }) => {
  return (
    <Box
      borderRadius={1.5}
      height="24px"
      display="flex"
      justifyContent={JustifyContent.Center}
      style={{ backgroundColor: backgroundColor, alignContent: 'center' }}
    >
      <Space size={2} direction="horizontal" />
      <Body2 style={{ color: textColor, lineHeight: '24px', fontSize: '14px' }}>{text}</Body2>
      <Space size={2} direction="horizontal" />
    </Box>
  )
}

type LabelProps = {
  text: string
  backgroundColor: string
  textColor: string
}
