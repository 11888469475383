import React, { ChangeEventHandler, FocusEventHandler } from 'react'

import {
  AlignItems,
  Box,
  Display,
  FontFamily,
  FormFieldStatus,
  InputField,
  JustifyContent,
  Link,
  LinkVariant,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { FieldContainer } from 'routes/Checkout/Components/AboutYou/FieldContainer'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'

import { EXISTING_EMAIL_LOGIN_MODAL_NAME } from './components/config'

type EmailFieldProps = {
  id: string
  isTouched?: boolean
  value: string
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleBlur: FocusEventHandler<HTMLInputElement>
  error?: string
}

const EmailAddressLabel = () => {
  const isGoustoOnDemandEnabled = useSelector(getIsGoustoOnDemandEnabled)
  const { openModal } = useModal()

  return (
    <Box
      display={Display.Flex}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Text fontFamily={FontFamily.Bold}>Email address</Text>
      <Box>
        <Text size={1}>
          {!isGoustoOnDemandEnabled && (
            <>
              Have an account?&nbsp;
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                href="#"
                size={1}
                variant={LinkVariant.Text}
                onClick={() => openModal(EXISTING_EMAIL_LOGIN_MODAL_NAME)}
              >
                Log in
              </Link>
            </>
          )}
        </Text>
      </Box>
    </Box>
  )
}

export const EmailField = ({
  id,
  isTouched,
  value,
  handleChange,
  handleBlur,
  error,
}: EmailFieldProps) => (
  <FieldContainer>
    <InputField
      id={id}
      data-testing="checkoutEmailInput"
      type="email"
      label={<EmailAddressLabel />}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      status={isTouched && error ? FormFieldStatus.Error : undefined}
      validationMessage={isTouched ? error : undefined}
    />
  </FieldContainer>
)
