import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Icon,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

export const payPalBannerText =
  'Get £5 Gousto credit for your next box by using the PayPal payment method. T&Cs apply*'

export const PayPalBanner = () => (
  <Box
    style={{ marginTop: '-4px' }}
    paddingH={4}
    paddingV={4}
    borderStyle={BorderStyle.Solid}
    borderColor={Color.Informative_200}
    bg={Color.Informative_50}
    display={Display.Flex}
    borderRadius={1.5}
    borderWidth={0.5}
    alignItems={AlignItems.Center}
    flexDirection={FlexDirection.Row}
  >
    <Icon variant={IconVariant.Informative} name="offer_pound" />
    <Space direction="horizontal" size={2} />
    <Text fontWeight={FontWeight.Bold} size={2}>
      {payPalBannerText}
    </Text>
  </Box>
)
