import { IconNames } from '@gousto-internal/citrus-react'

export function replaceDashWithUnderscore(inputString: string): IconNames {
  return inputString.replace(/-/g, '_') as IconNames
}

export function formatDietaryText(inputString: string): IconNames {
  return inputString.replace(/ /g, '-') as IconNames
}

export function formatShelfLifeText(days: number): string {
  return `Use within ${days} days`
}

export function formatKcalText(kcal: number): string {
  return `${kcal} Kcal`
}

export function formatCookingTimeText(time: number): string {
  return `${time} min`
}

export function formatRatingText(rating: { [key: string]: number }): string {
  return `${rating.average.toFixed(1)} (${rating.count})`
}
