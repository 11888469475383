import {
  UserConsentPreferences,
  CookieConsentCustomSettings,
  ConsentCookie,
} from '@gousto-internal/storage-consent-web-package'

import { CLIENT_ID_COOKIE } from '@library/experimentation'
import { setDatadogConsent } from '@library/observability/src/browser/consent'
import { queueTrackingEvent } from '@library/tracking-integration'

import Cookies from 'utils/GoustoCookies'

type TrackingFirstBannerInteraction = {
  action: 'cookie_banner_first_interaction'
  category: 'OFX'
  properties: {
    allocationId?: string
    acceptType: string
    acceptedCategories: string[]
  }
}

type TrackingConsentChanged = {
  action: 'cookie_banner_changed_consent'
  category: 'OFX'
  properties: {
    allocationId?: string
    acceptedCategories: string[]
  }
}

type TrackingShowBanner = {
  action: 'cookie_banner_shown'
  category: 'OFX'
  properties: {
    allocationId?: string
  }
}

export const cookieBannerActions: CookieConsentCustomSettings = {
  onFirstConsent(userPreferences: UserConsentPreferences) {
    const acceptType = userPreferences.accept_type
    const acceptedCategories = userPreferences.accepted_categories

    setDatadogConsent()

    queueTrackingEvent<TrackingFirstBannerInteraction>({
      action: 'cookie_banner_first_interaction',
      category: 'OFX',
      properties: {
        allocationId: Cookies.get(CLIENT_ID_COOKIE),
        acceptType,
        acceptedCategories,
      },
    })

    /*  FIX for a bug where homepage tracking events are not sent to snowplow when the user sees the cookie banner for the first time
    https://gousto.atlassian.net/browse/TG-8887
    */
    window.location.reload()
  },

  onConsentChange(cookie: ConsentCookie) {
    const { categories: acceptedCategories } = cookie

    setDatadogConsent()

    queueTrackingEvent<TrackingConsentChanged>({
      action: 'cookie_banner_changed_consent',
      category: 'OFX',
      properties: {
        allocationId: Cookies.get(CLIENT_ID_COOKIE),
        acceptedCategories,
      },
    })
  },
}

export function trackShowBanner() {
  queueTrackingEvent<TrackingShowBanner>({
    action: 'cookie_banner_shown',
    category: 'OFX',
    properties: {
      allocationId: Cookies.get(CLIENT_ID_COOKIE),
    },
  })
}
