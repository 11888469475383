import React, { useCallback } from 'react'

import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  Heading3,
  Body2,
  ButtonColorVariant,
  ButtonSize,
  useModal,
} from '@gousto-internal/citrus-react'

interface Props {
  onPauseSubmit: () => void
  onBackButtonClick: () => void
  isOpen?: boolean
}

export const GET_HELP_MODAL = 'GET_HELP_MODAL'

export const GetHelpModal = ({ onPauseSubmit, onBackButtonClick, isOpen }: Props) => {
  const { closeCurrentModal } = useModal()

  const handleKeep = useCallback(() => {
    onBackButtonClick()
    closeCurrentModal()
  }, [onBackButtonClick, closeCurrentModal])

  const handlePause = useCallback(() => {
    onPauseSubmit()
    closeCurrentModal()
  }, [onPauseSubmit, closeCurrentModal])

  return (
    <Modal name={GET_HELP_MODAL} isOpen={isOpen}>
      <ModalHeader>
        <Heading3>Did we solve the problem?</Heading3>
      </ModalHeader>

      <ModalBody>
        <Body2>
          If our customer care agent managed to help you and you wish to keep your subscription,
          click yes below.
        </Body2>
      </ModalBody>

      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Secondary}
          size={ButtonSize.Medium}
          onClick={handlePause}
          width="100%"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          No, pause subscription
        </ModalButton>

        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Medium}
          onClick={handleKeep}
        >
          Yes, keep subscription
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
