import React from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import ModalPanel from 'Modal/ModalPanel'
import Svg from 'Svg'
import { UserRAFLink } from 'components/UserRAFLink'
import { LinkRow } from './LinkRow'
import css from './SocialShareSheet.css'
import { getFacebookReferralLink, getWhatsappReferralLink, getTextMessageReferralLink, SOCIAL_TYPES } from '../socialReferralHelper'

const SocialShareSheet = (props) => {
  const {
    onClose,
    referralCode,
    trackingReferFriendSocialSharing,
    trackingReferFriend,
    userFirstName,
    rafOffer,
    trackUserFreeFoodLinkShare,
  } = props

  return (
    <ModalPanel
      closePortal={onClose}
      className={css.modal}
      disableOverlay
    >
      <div className={`${css.title} ${css.row}`}>
        <span>Share Via</span>
      </div>

      <LinkRow onClick={() => { getTextMessageReferralLink(referralCode, userFirstName, rafOffer, trackingReferFriendSocialSharing, trackUserFreeFoodLinkShare) }} svgName="icon-text-message-colour" rowName={SOCIAL_TYPES.text} />
      <LinkRow onClick={() => { getWhatsappReferralLink(referralCode, userFirstName, rafOffer, trackingReferFriendSocialSharing, trackUserFreeFoodLinkShare) }} svgName="icon-whatsapp-colour" rowName={SOCIAL_TYPES.whatsapp} />
      <LinkRow onClick={() => { getFacebookReferralLink(referralCode, userFirstName, trackingReferFriendSocialSharing, trackUserFreeFoodLinkShare) }} svgName="icon-facebook-colour" rowName={SOCIAL_TYPES.facebook} />

      <UserRAFLink
        classContainer={css.row}
        classLinkContainer={css.rafLinkWrapper}
        referralCode={referralCode}
        trackingReferFriend={trackingReferFriend}
        trackUserFreeFoodLinkShare={trackUserFreeFoodLinkShare}
        isModal
      >
        <div>
          <div className={css.iconWrapper}>
            <Svg fileName="icon-link-colour" className={css.icon} />
          </div>
          <span>Copy link</span>
        </div>
      </UserRAFLink>
    </ModalPanel>
  )
}

SocialShareSheet.propTypes = {
  onClose: PropTypes.func.isRequired,
  referralCode: PropTypes.string.isRequired,
  trackingReferFriendSocialSharing: PropTypes.func.isRequired,
  trackingReferFriend: PropTypes.func.isRequired,
  userFirstName: PropTypes.string.isRequired,
  rafOffer: PropTypes.instanceOf(Immutable.Map),
  trackUserFreeFoodLinkShare: PropTypes.func.isRequired,
}

SocialShareSheet.defaultProps = {
  rafOffer: null,
}

export { SocialShareSheet }
