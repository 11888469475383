import qs from 'qs'
import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
} from '@library/http/requests'
import { MenuRecipe } from './types'

type MenuRecipeParams = {
  menuId: string
  recipeId: string
  numPortions: number
  deliveryDate: string
}

const menuApiMenuRecipeParse = async (response: Response): Promise<MenuRecipe> => {
  const body = await response.text()
  const parsedBody = JSON.parse(body)

  if (!response.ok) {
    throw new Error(parsedBody.message)
  }

  return parsedBody as MenuRecipe
}

const menuApiMenuRecipeRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath(({ menuId, recipeId }: MenuRecipeParams) => `menus/${menuId}/recipes/${recipeId}`),
  setQueryParams(({ numPortions, deliveryDate }: MenuRecipeParams) => ({
    num_portions: numPortions.toString(),
    delivery_date: deliveryDate,
  })),
  setMethod('GET'),
)

export const menuRecipeFetcher = composeFetch(menuApiMenuRecipeRequest, menuApiMenuRecipeParse)
