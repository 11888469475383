import { datadogLogs } from '@datadog/browser-logs'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import {
  checkoutPostSignup,
  clearGoustoRef,
  handleCheckoutError,
} from 'routes/Checkout/checkoutActions'
import { getPaymentData, getPaymentProvider } from 'routes/Checkout/checkoutPaymentSelectors'
import { signupPayment } from 'routes/Checkout/checkoutPaymentsApis'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { Pricing } from 'routes/Menu/domains/pricing'
import { getSessionId } from 'selectors/cookies'

export const checkoutSignupPayment: CheckoutActionCreator =
  (sourceId: string, { pricing }: { pricing: Pricing }) =>
  async (dispatch, getState) => {
    try {
      const state = getState()
      const sessionId = getSessionId()
      const provider = getPaymentProvider(state)
      const reqData = getPaymentData(state)

      if (sourceId) {
        reqData.card_token = sourceId
      }

      await signupPayment(reqData, provider, sessionId)
      await dispatch(checkoutPostSignup({ pricing }))
      datadogLogs.logger.info('Request to payments/v1/payments/signup-payments successful')
    } catch (err) {
      datadogLogs.logger.error(`Request to payments failed ${JSON.stringify(err)}`)
      await dispatch(handleCheckoutError(err, 'checkoutSignupPayment'))
    } finally {
      dispatch(clearGoustoRef())
      dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, false))
    }
  }
