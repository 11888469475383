import React from 'react'

import classnames from 'classnames'
import { Layout2Cells, LayoutContentWrapper, ProgressBar } from 'goustouicomponents'
import PropTypes from 'prop-types'

import { useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'
import { useMenuBox } from 'routes/Menu/domains/basket/internal/useMenuBox'

import { BoxProgressMessage } from '../../BoxProgressMessage'
import { TooManyRecipesWarning } from '../../TooManyRecipesWarning'

import css from './RecipesInBasketProgressContent.css'

const propTypes = {
  selectedRecipesCount: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
}

const RecipesInBasketProgressContent = ({ selectedRecipesCount, percentage }) => {
  const menuBox = useMenuBox()
  const numRecipesOverLimit = useNumRecipesOverBasketLimit()
  const isBasketFull = percentage === 100

  const cardClasses = classnames(css.cardContentWrapper, {
    [css.greenBorder]: isBasketFull,
    [css.cardContentWrapperFull]: isBasketFull,
  })

  /* It doesn't make sense to show basket progress if we don't know what the maximum allowed number of recipe
  for the chosen portion size is, so we return null until we get that information from the menu-service. */
  if (!menuBox) {
    return null
  }

  if (numRecipesOverLimit) {
    return <TooManyRecipesWarning numRecipesOverLimit={numRecipesOverLimit} />
  }

  return (
    <div className={cardClasses}>
      <LayoutContentWrapper>
        {isBasketFull ? (
          <Layout2Cells>
            <span className={css.iconProgressCompleted} />
            <BoxProgressMessage className={css.message} numRecipes={selectedRecipesCount} />
          </Layout2Cells>
        ) : (
          <div className={css.cardContentWrapperPartial}>
            <Layout2Cells>
              <p className={css.percentageNumber}>{percentage}%</p>
              <BoxProgressMessage className={css.message} numRecipes={selectedRecipesCount} />
            </Layout2Cells>
            <div className={css.progressBarWrapper}>
              <ProgressBar percentage={percentage} theme="transition-1" />
            </div>
          </div>
        )}
      </LayoutContentWrapper>
    </div>
  )
}

RecipesInBasketProgressContent.propTypes = propTypes

export { RecipesInBasketProgressContent }
