import { canUseWindow } from '@library/environment'
import { AccountDetailsState, ActionType } from '../interfaces'

interface AccountDetailsAction {
  type: ActionType
}

const accountDetailsReducer = (
  initialState: AccountDetailsState,
  action: AccountDetailsAction,
): AccountDetailsState => {
  switch (action.type) {
    case ActionType.EXPAND_PAYMENT_INFO:
      return { ...initialState, paymentInfoSectionExpanded: true }
    case ActionType.RESET_CLICKED_STATE:
      return { ...initialState, paymentInfoSectionExpanded: false }
    default:
      throw new Error(`accountDetailsReducer: ${action.type} NOT IMPLEMENTED!`)
  }
}

const getInitialReducerArguments = () => {
  if (!canUseWindow()) {
    return { paymentInfoSectionExpanded: false }
  }

  const searchParams = new URLSearchParams(window.location.search)

  return {
    paymentInfoSectionExpanded: searchParams.get('expand_payment_section') === '1',
  }
}

export { AccountDetailsAction, accountDetailsReducer, getInitialReducerArguments }
