import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import Helmet from 'react-helmet'

import { RafLandingPage } from '@features/raf-landing-page'

import { useGetCtaProps } from 'routes/Home/Cta'
import { onScrollHandler } from 'routes/Home/Hero/heroUtils'
import { useCanShowPromoBanner } from 'routes/Home/PromoBanner/promoBannerHooks'
import { RAF_LANDING_PAGE_EXPERIMENT_PROMO_CODE_DATA } from 'routes/Home/homeConfig'

import { useIsRafLandingPageExperimentEnabled } from './hooks/useIsRafLandingPageExperimentEnabled'
import { seo } from './seoConfig'
import { useApplyDiscount, useGetAllRecipes, useHandleSignupCTAClick } from './utils'

export const RafPage = () => {
  const handleSignupCTAClick = useHandleSignupCTAClick()
  const recipes = useGetAllRecipes()
  const { ctaText } = useGetCtaProps()

  const { defaultBannerText } = RAF_LANDING_PAGE_EXPERIMENT_PROMO_CODE_DATA
  const canShowPromoBanner = useCanShowPromoBanner()
  const applyDiscount = useApplyDiscount()

  return (
    <Box>
      <Helmet title={seo.title} meta={seo.meta} />
      <RafLandingPage
        onScrollHandler={onScrollHandler}
        useIsRafLandingPageExperimentEnabled={useIsRafLandingPageExperimentEnabled}
        onSignupCTAClick={handleSignupCTAClick}
        recipes={recipes}
        signupCTA={ctaText}
        applyDiscount={applyDiscount}
        isPromoBannerVisible={canShowPromoBanner}
        promoBannerText={defaultBannerText}
      />
    </Box>
  )
}
