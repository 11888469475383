import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { TrackingKey } from 'routes/Checkout/models/TrackingKey'
import { getUTMAndPromoCode } from 'selectors/tracking'

export const trackCheckoutUrgencyAction: CheckoutActionCreator =
  (type: TrackingKey, additionalData: any = {}) =>
  (dispatch, getState) => {
    const { promoCode, UTM } = getUTMAndPromoCode(getState())

    dispatch({
      type,
      trackingData: {
        actionType: type,
        ...UTM,
        promoCode,
        ...additionalData,
      },
    })
  }
