export const seRecoveryAttemptTypesByModalType = {
  promo: 'promo',
  contact: 'contact_cc',
  copy: 'generic',
  changeDeliveryDate: 'change_delivery_date',
  quoteSkipNext: 'skip_next',
  skipBox: 'skip_boxes',
  quote: 'promo_reminder',
  changed_mind: 'changed_mind',
}
