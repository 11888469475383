import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const chevronExpandAnim = keyframes`
  from { transform: rotateZ(180deg); }
  90% { transform: rotateZ(-20deg); }
  to { transform: rotateZ(0); }
`
const chevronCollapseAnim = keyframes`
  from { transform: rotateZ(0); }
  90% { transform: rotateZ(200deg); }
  to { transform: rotateZ(180deg); }
`

type ChevronIconWrapperProps = { expanded: boolean }

export const ChevronIconWrapper = styled.div(({ expanded }: ChevronIconWrapperProps) => ({
  transform: `rotateZ(${expanded ? '180deg' : '0deg'})`,
  width: '1.5rem',
  height: '1.5rem',
  animation: `${expanded ? chevronCollapseAnim : chevronExpandAnim} ${
    expanded ? '600ms' : '300ms'
  } ease-out`,
}))
