import React from 'react'

import {
  Box,
  Display,
  DisplayPosition,
  FlexDirection,
  JustifyContent,
  Space,
  Text,
  Color,
  FontFamily,
  FontWeight,
  TextAlign,
} from '@gousto-internal/citrus-react'

type QuotationBlockProps = {
  quote: {
    source: string
    text: string
  }
}

export const BoxPricesQuotationBlock = ({ quote }: QuotationBlockProps) => (
  <Box position={DisplayPosition.Relative} maxWidth="550px" data-testid="boxPricesQuotationBlock">
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Space size={6} direction="vertical" />
      <Text
        color={Color.ColdGrey_100}
        size={9}
        fontWeight={FontWeight.Bold}
        textAlign={TextAlign.Left}
      >
        “
      </Text>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.SpaceEvenly}
      >
        <Space size={2} direction="vertical" />
        <Text
          size={2}
          fontFamily={FontFamily.Book}
          textAlign={TextAlign.Center}
          data-testid="quoteBlockText"
        >
          {quote.text}
        </Text>
        <Space size={2} direction="vertical" />
        <Text size={2} textAlign={TextAlign.Center} fontWeight={FontWeight.Bold}>
          {quote.source}
        </Text>
      </Box>
      <Text
        color={Color.ColdGrey_100}
        size={9}
        fontWeight={FontWeight.Bold}
        textAlign={TextAlign.Left}
      >
        ”
      </Text>
    </Box>
    <Space size={6} direction="vertical" />
  </Box>
)
