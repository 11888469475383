import React from 'react'

import { AlignItems, Body3, Box, Display, Space } from '@gousto-internal/citrus-react'

import { MenuRecipeIngredient } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { IngredientImage } from './IngredientImage'

import css from './Ingredient.css'

type IngredientProps = {
  ingredient: MenuRecipeIngredient
  loading: boolean
}

const LoadingSkeleton = ({ style }: { style?: React.CSSProperties }) => (
  <span className={css.loadingSkeleton} style={style} data-testid="LoadingSkeleton" />
)

const Ingredient = ({ ingredient, loading }: IngredientProps) => {
  const quantity = ingredient.quantities?.in_meal ?? 0
  const label = `${ingredient.name}${quantity !== 1 ? ` x ${quantity}` : ''}`

  if (loading) {
    return (
      <Box display={Display.Flex} alignItems={AlignItems.Center}>
        <LoadingSkeleton style={{ height: '30px', width: '30px' }} />
        <Space size={3} direction="horizontal" />
        <LoadingSkeleton style={{ height: '10px', width: '200px' }} />
      </Box>
    )
  }

  return (
    <Box display={Display.Flex} alignItems={AlignItems.Center}>
      <IngredientImage ingredient={ingredient} />
      <Space size={2} direction="horizontal" />
      <Body3>{label}</Body3>
    </Box>
  )
}

export { Ingredient }
