import React from 'react'

import {
  Body1,
  Body2,
  Box,
  Color,
  FontFamily,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import {
  getSignupIsDietaryConfirmed,
  getSignupIsRecipeConfirmed,
} from 'routes/Signup/signupSelectors'

import { SDPHeaderTexts } from './config'

const Container = withCitrusTheme(Box, () => ({
  '@media (min-width: 1200px)': {
    paddingLeft: '6px',
  },
}))

export function SignupDietaryPreferencesHeader() {
  const { header, subHeader } = SDPHeaderTexts
  const signupIsDietaryConfirmed = useSelector(getSignupIsDietaryConfirmed)
  const signupIsRecipeConfirmed = useSelector(getSignupIsRecipeConfirmed)
  let subHeaderText = subHeader.dietaryAndRecipePreferences

  if (!signupIsDietaryConfirmed && !signupIsRecipeConfirmed) {
    return null
  }

  if (signupIsRecipeConfirmed && !signupIsDietaryConfirmed) {
    subHeaderText = subHeader.recipeOnlyPreferences
  }

  if (signupIsDietaryConfirmed && !signupIsRecipeConfirmed) {
    subHeaderText = subHeader.dietaryOnlyPreferences
  }

  return (
    <Container
      paddingH={[3, null, 0]}
      paddingV={[0, null, 2]}
      paddingBottom={[2, 2, 0]}
      data-testid="signup-dietary-preferences-header"
    >
      <Body1 color={Color.ColdGrey_800} fontFamily={FontFamily.Bold}>
        {header}
      </Body1>
      <Body2 color={Color.ColdGrey_800}>{subHeaderText}</Body2>
    </Container>
  )
}
