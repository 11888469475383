import React from 'react'

import { Text, FontFamily, FontWeight, Color, Display } from '@gousto-internal/citrus-react'
import { Recipe } from '../../model/recipe'

export const CookingTimeText = ({
  numPortions,
  recipe,
}: {
  numPortions: number
  recipe: Recipe
}) => {
  const { isFineDineIn, prepTimes } = recipe

  const cookingTime = numPortions && prepTimes ? prepTimes[`for${numPortions}`] : undefined

  if (!cookingTime) {
    return null
  }

  return (
    <Text
      size={2}
      fontFamily={FontFamily.Book}
      fontWeight={FontWeight.Normal}
      color={isFineDineIn ? Color.White : Color.ColdGrey_600}
      display={Display.Inline}
    >
      {cookingTime + ' mins'}
    </Text>
  )
}
