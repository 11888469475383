import PropTypes from 'prop-types'
import React from 'react'
import Immutable from 'immutable'

import { Button } from 'goustouicomponents'
import DropdownInput from 'Form/Dropdown'
import { getRecipeAvailabilityByDeliveryDateRange } from '@library/api-recipeavailability'
import util from './util'
import css from './EditDate.css'

class EditDate extends React.PureComponent {
  constructor(props) {
    super(props)
    const { coreDeliveryDayId, deliverySlotId } = this.props

    this.state = {
      selectedDeliveryDayId: coreDeliveryDayId,
      selectedDeliverySlotId: deliverySlotId,
      deliveryDaysOptions: [],
      slotsOptions: {},
      selectedDeliveryDate: ''
    }
  }

  componentDidMount() {
    const { deliveryDays } = this.props
    const sortedDates = this.sortDeliveryDays(deliveryDays.toJS())
    const deliveryDateFrom = sortedDates[0]
    const deliveryDateUntil = sortedDates[sortedDates.length - 1]

    if (sortedDates.length > 0) {
      this.getRecipeAvailabilityData(deliveryDateFrom, deliveryDateUntil, deliveryDays)
    }
  }

  componentWillReceiveProps(nextProps) {
    const sortedDates = this.sortDeliveryDays(nextProps.deliveryDays.toJS())
    const deliveryDateFrom = sortedDates[0]
    const deliveryDateUntil = sortedDates[sortedDates.length - 1]
    this.getRecipeAvailabilityData(deliveryDateFrom, deliveryDateUntil, nextProps.deliveryDays)
  }

  onSubmitFunction() {
    const { selectedDeliverySlotId, selectedDeliveryDayId, slotsOptions, selectedDeliveryDate } = this.state
    const { orderId, deliverySlotId, deliveryDays, orderUpdateDayAndSlot } = this.props

    if (selectedDeliverySlotId !== deliverySlotId) {
      const { uuid } = slotsOptions[selectedDeliveryDayId].find(slot => slot.value === selectedDeliverySlotId)

      orderUpdateDayAndSlot(orderId, selectedDeliveryDayId, selectedDeliverySlotId, uuid, selectedDeliveryDate, deliveryDays)
    }
  }

  onDayChange(dayId, slotsOptions) {
    const {
      clearUpdateDateErrorAndPending,
      coreDeliveryDayId,
      deliverySlotId,
      orderId,
      userTrackDateSelected,
    } = this.props
    const { deliveryDaysOptions } = this.state

    let slotId
    let selectedDeliveryDate
    if (dayId === coreDeliveryDayId) {
      slotId = deliverySlotId
    } else {
      const defaultSlotOption = slotsOptions[dayId].find(slotOption => slotOption.isDefaultSlot)
      selectedDeliveryDate = deliveryDaysOptions.find(day => day.value === dayId).date
      if (defaultSlotOption) {
        slotId = defaultSlotOption.value
      } else {
        slotId = slotsOptions[dayId][0].value
      }
    }

    this.setState({ selectedDeliveryDayId: dayId, selectedDeliverySlotId: slotId, selectedDeliveryDate })
    clearUpdateDateErrorAndPending()
    userTrackDateSelected(orderId, deliverySlotId, slotId)
  }

  onSlotChange(slotId) {
    const { deliverySlotId, orderId, userTrackSlotSelected } = this.props
    this.setState({ selectedDeliverySlotId: slotId })
    userTrackSlotSelected(orderId, deliverySlotId, slotId)
  }

  getRecipeAvailabilityData(deliveryDateFrom, deliveryDateUntil, deliveryDays) {
    getRecipeAvailabilityByDeliveryDateRange({
      deliveryDateFrom,
      deliveryDateUntil,
    }).then((availabilityForDateRange) => {
      const { coreDeliveryDayId, deliverySlotId, recipes, portionsCount, orders } = this.props
      this.setState(util.getDayAndSlotOptionsAndSelected(deliveryDays, availabilityForDateRange, coreDeliveryDayId, deliverySlotId, recipes, portionsCount, orders))
    })
  }

  sortDeliveryDays(deliveryDays) {
    return Object
      .values(deliveryDays)
      .map((date) => date.date)
      .sort((a, b) => new Date(a) - new Date(b))
  }

  render() {
    const { deliverySlotId, isPendingUpdateDayAndSlot } = this.props
    const { deliveryDaysOptions, slotsOptions } = this.state
    const { selectedDeliveryDayId, selectedDeliverySlotId } = this.state
    const canSubmit = selectedDeliverySlotId !== deliverySlotId

    return (
      <div className={css.editDeliveryOuterContainer}>
        <div className={css.row}>
          <div className={css.dropDownContainer}>
            <DropdownInput
              options={deliveryDaysOptions}
              onChange={(dayId) => this.onDayChange(dayId, slotsOptions)}
              value={selectedDeliveryDayId}
            />
          </div>
          <div className={css.dropDownContainer}>
            <DropdownInput
              options={slotsOptions[selectedDeliveryDayId]}
              onChange={(slotId) => this.onSlotChange(slotId)}
              value={selectedDeliverySlotId}
            />
          </div>
        </div>
        <Button
          onClick={() => this.onSubmitFunction()}
          color="secondary"
          width="full"
          noDecoration
          pending={isPendingUpdateDayAndSlot}
          disabled={!canSubmit}
        >
          Set delivery slot
        </Button>
      </div>
    )
  }
}

EditDate.propTypes = {
  clearUpdateDateErrorAndPending: PropTypes.func,
  coreDeliveryDayId: PropTypes.string.isRequired,
  deliveryDays: PropTypes.instanceOf(Immutable.Map),
  deliverySlotId: PropTypes.string.isRequired,
  isPendingUpdateDayAndSlot: PropTypes.bool,
  orderId: PropTypes.string,
  orderUpdateDayAndSlot: PropTypes.func.isRequired,
  orders: PropTypes.instanceOf(Immutable.Map),
  portionsCount: PropTypes.string,
  recipes: PropTypes.instanceOf(Immutable.List),
  userTrackDateSelected: PropTypes.func.isRequired,
  userTrackSlotSelected: PropTypes.func.isRequired,
}

EditDate.defaultProps = {
  orderId: '',
  deliveryDays: Immutable.Map(),
  isPendingUpdateDayAndSlot: false,
  clearUpdateDateErrorAndPending: () => null,
  recipes: Immutable.List(),
  orders: Immutable.Map(),
  portionsCount: '2'
}

export { EditDate }
