export const expiredCheckoutUrgencyModalTexts = {
  headingText: 'Your session has expired',
  bodyText: 'Go back to the menu to check your recipes are still in stock',
  ctaText: 'Back to the menu',
}

export const almostExpiredCheckoutUrgencyModalTexts = {
  headingText: 'Your session is about to expire',
  bodyText: 'Continue with checkout to avoid losing your recipes',
  ctaText: 'Continue with checkout',
}
