import { ReactNode } from 'react'

import { Box } from '@gousto-internal/citrus-react'

import { CreateAccount } from './Steps/CreateAccount/CreateAccount'
import { Delivery } from './Steps/Delivery'

/**
 * Breadcrumbs to arrange and display in Checkout.
 */
export enum Breadcrumbs {
  ACCOUNT = 'Account',
  DELIVERY = 'Delivery',
  PAYMENT = 'Payment',
}

export enum CARD_DETAILS {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
}

export const CHECKOUT_BREADCRUMBS = [Breadcrumbs.ACCOUNT, Breadcrumbs.DELIVERY, Breadcrumbs.PAYMENT]

/**
 * Unordered list of all possible steps in checkout.
 * Enum serves as IDs for steps in application.
 * Route to step is equal to its ID.
 */
export enum CheckoutStepIds {
  ACCOUNT = 'account',
  DELIVERY = 'delivery',
  /**
   * Step is always rendered. Check description in CHECKOUT_STEPS for more info.
   */
  PAYMENT = 'payment',
}

/**
 * Checkout step as information container on everything about step in checkout flow.
 */
type CheckoutStep = {
  id: CheckoutStepIds
  /**
   * Component to display for given step.
   * Note: while ReactNode allows not just components, but null, strings, etc., using something more meaningful
   * is not possible due to use of "prop-types" lib.
   */
  component: ReactNode
  /**
   * Breadcrumb to display as active for given step.
   */
  breadcrumb: Breadcrumbs
  /**
   * If true, then instead of going to next step, checkout order should be submitted.
   * Note: check formContainer.js for more info.
   */
  isLastStep: boolean
}

/**
 * List of all possible steps (control & variations) in Checkout flow.
 */
export const CHECKOUT_STEPS: Array<CheckoutStep> = [
  {
    id: CheckoutStepIds.ACCOUNT,
    component: CreateAccount,
    breadcrumb: Breadcrumbs.ACCOUNT,
    isLastStep: false,
  },
  {
    id: CheckoutStepIds.DELIVERY,
    component: Delivery,
    breadcrumb: Breadcrumbs.DELIVERY,
    isLastStep: false,
  },
  /**
   * Payment Step is always rendered on the `Account` Step, but shown only when navigated to `/payment` URL.
   * Other than the unusual rendering condition, it behaves the same as other steps.
   * The prop `prerender` is responsible for the hide/show behaviour using the { display: block | none }.
   * Therefore the component rendered below is dummy Box
   */
  {
    id: CheckoutStepIds.PAYMENT,
    component: Box,
    breadcrumb: Breadcrumbs.PAYMENT,
    isLastStep: true,
  },
]
