import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { RadioGroup, Box, Space } from '@gousto-internal/citrus-react'
import { getSubscriptionRecipeOptions } from '../../../../context/selectors/subscriptionOptions'
import css from './RecipesPerBox.css'
import {
  SubscriptionContext,
} from '../../../../context'
import { actionTypes } from '../../../../context/reducers'
import {
  getRecipesPerBox,
  getBoxPricesNumPortion,
  getIsBoxAndPricesLoaded,
  getDietaryPreference,
  getTotalBoxPriceDiscounted,
  getNumPortions,
  getNumRecipes
} from '../../../../context/selectors/box'

import { SettingSection } from '../../../../components/SettingSection'

import { trackSubscriptionSettingsChange } from '../../../../tracking'

import { useUpdateSubscription } from '../../../../hooks/useUpdateSubscription'
import { RecipesPerBoxLabel } from './RecipesPerBoxLabel/RecipesPerBoxLabel'
import { RecipesPerBoxConfirmationModal } from './RecipesPerBoxConfirmationModal'
import { getOpenOrders } from '../../../../context/selectors/orders'

export const RecipesPerBox = ({ accessToken, isMobile }) => {
  const context = useContext(SubscriptionContext)
  const { state, dispatch } = context
  const isBoxAndPricesLoaded = getIsBoxAndPricesLoaded(state)
  const currentBoxSize = getNumPortions(state)
  const currentRecipesPerBox = getNumRecipes(state)
  const selectedRecipesPerBox = getRecipesPerBox(state)
  const boxPrices = getBoxPricesNumPortion(state)
  const totalBoxPrice = getTotalBoxPriceDiscounted(state)
  const dietaryPreference = getDietaryPreference(state)
  const recipesPerBoxOptions = getSubscriptionRecipeOptions(state)
  const [shouldSubmit, setShouldSubmit] = useState(false)

  const settingName = 'recipes_per_box'

  const recipesPerBoxMap = recipesPerBoxOptions[currentBoxSize] || []

  const currentRecipesPerBoxIndex = recipesPerBoxMap.indexOf(selectedRecipesPerBox || currentRecipesPerBox)

  const [, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    trigger: {
      shouldRequest: shouldSubmit,
      setShouldRequest: setShouldSubmit
    },
    data: {
      numRecipes: selectedRecipesPerBox
    },
    settingName,
  })

  const didUpdateComplete = typeof updateResponse?.status === 'string'

  const onSubmit = () => {
    trackSubscriptionSettingsChange({ settingName, action: 'update' })()
    setShouldSubmit(true)
  }

  const handleRecipesPerBoxChange = useCallback(
    (event) => {
      const numRecipes = event.target.value
      dispatch({
        type: actionTypes.UPDATE_SELECTED_RECIPES_PER_BOX,
        data: { numRecipes }
      })
    },
    [dispatch]
  )

  const isCtaDisabled = selectedRecipesPerBox === currentRecipesPerBox
  || !selectedRecipesPerBox
  const options = recipesPerBoxMap.map(recipesPerBox => (
    {
      name: recipesPerBox,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: () => (
        <RecipesPerBoxLabel
          recipesPerBox={recipesPerBox}
          pricePerPortionDiscounted={boxPrices[recipesPerBox][dietaryPreference].pricePerPortionDiscounted}
          dietaryPreference={dietaryPreference}
        />
      ),
      value: recipesPerBox,
    }
  ))

  return (
    <Box padding={0}>
      <SettingSection
        icon="mealPerBox"
        title="Recipes Per Box"
        instruction="How many recipes would you like in a default recipe box?"
        ctaText="Save"
        isCtaDisabled={isCtaDisabled}
        renderCurrentValue={(
          <>
            {isBoxAndPricesLoaded && (
              <>
                <p className={css.currentSetting} data-testing="current-recipes-per-box">
                  {`${recipesPerBoxMap[currentRecipesPerBoxIndex]} recipes per box (£${boxPrices[selectedRecipesPerBox || currentRecipesPerBox][dietaryPreference].pricePerPortionDiscounted} per serving)`}
                </p>
                <p>
                  {`Recipe box price £${totalBoxPrice}`}
                </p>
              </>
            )}
          </>
        )}
        onSubmit={onSubmit}
        onEditClick={trackSubscriptionSettingsChange({ settingName, action: 'edit' })}
        isMobile={isMobile}
        testingSelector="recipes-per-box"
      >
        {
          isBoxAndPricesLoaded
            && (
              <RadioGroup
                outline
                defaultValue={selectedRecipesPerBox || currentRecipesPerBox}
                onChange={handleRecipesPerBoxChange}
                options={options}
              />
            )
        }
        <Space size={2} direction="vertical" />
      </SettingSection>
      <RecipesPerBoxConfirmationModal
        openOrders={getOpenOrders(state)}
        numPortions={currentBoxSize}
        numRecipes={selectedRecipesPerBox}
        didUpdateComplete={didUpdateComplete}
        didUpdateFail={updateError}
        updateResponse={updateResponse}
      />
    </Box>
  )
}

RecipesPerBox.propTypes = {
  accessToken: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}
