import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import userActions, { userFetchReferralOffer } from 'actions/user'
import { getBasketOrderDetails } from 'selectors/basket'
import { isOrderConfirmationPageLoading } from 'selectors/orderConfirmation'
import { locationQuery } from 'selectors/routing'
import { getAgeVerified } from 'selectors/user'

import { OrderConfirmation } from './OrderConfirmation'
import { getHeaderDetails, isFiveForFour } from './helper'

const mapStateToProps = (state) => {
  const locationQueryParam = locationQuery(state)
  const order = getBasketOrderDetails(state)
  const headerDetails = !!order && getHeaderDetails(order)
  const showHeader = !!(locationQueryParam && locationQueryParam.order_action) && !!headerDetails
  const showMultipleBoxesDeliveryMessage = !!order && isFiveForFour(order)
  const isLoading = !order || isOrderConfirmationPageLoading(state)

  return {
    ageVerified: getAgeVerified(state),
    headerDetails,
    isLoading,
    showHeader,
    showMultipleBoxesDeliveryMessage,
  }
}

const mapDispatchToProps = {
  userFetchReferralOffer,
  userVerifyAge: userActions.userVerifyAge,
  userLoadData: userActions.userLoadData,
}

const OrderConfirmationContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation),
)

export { OrderConfirmationContainer }
