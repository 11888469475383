import React from 'react'

import {
  Box,
  Display,
  AlignItems,
  FlexDirection,
  JustifyContent,
  Color,
  Icon,
  Space,
  TextAlign,
  Text,
  FontSize,
  FontFamily,
} from '@gousto-internal/citrus-react'

import { IconWrapperStyled, LargeResponsiveHeader, MediumResponsiveHeader } from '../Hero.styled'
import { PercentageWithOff } from './PercentageWithOff'
import { RafCampaignDetails } from '../../../hooks/useFetchRafCampaignDetails'

export const DiscountBoxes = ({
  firstBoxDiscount,
  secondBoxDiscount,
}: Omit<RafCampaignDetails, 'giftYourFriendCredit'>) => (
  <Box
    display={Display.Flex}
    flexDirection={[FlexDirection.Row, null, FlexDirection.Column]}
    alignItems={[AlignItems.Center, null, AlignItems.FlexEnd]}
    gap={0}
  >
    <Box
      bg={Color.Secondary_400}
      paddingH={2}
      paddingV={2}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      width={['142px', '180px']}
      height={['142px', '180px']}
    >
      <Space size={[4, 0, 0]} direction="vertical" />
      <Box display={Display.Flex} flexDirection={FlexDirection.Row} alignItems={AlignItems.Center}>
        <LargeResponsiveHeader
          color={Color.White}
          display={Display.Inline}
          fontFamily={FontFamily.Bold}
        >
          {firstBoxDiscount}
        </LargeResponsiveHeader>
        <Space size={1} direction="horizontal" />
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          alignItems={AlignItems.FlexStart}
          justifyContent={JustifyContent.Center}
          paddingV={2}
        >
          <PercentageWithOff percentageSize={[7, 9, 10]} offSize={[3, 5, 8]} />
        </Box>
      </Box>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} width="80%">
        <Text color={Color.White} size={[3, 8]} textAlign={TextAlign.Center}>
          your first box
        </Text>
      </Box>
      <Space size={[5, 0]} direction="vertical" />
    </Box>
    <IconWrapperStyled>
      <Icon name="add" size={10} />
    </IconWrapperStyled>
    <Box
      bg={Color.ColdGrey_800}
      paddingH={2}
      paddingV={[0, 2]}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      width={['120px', '160px']}
      height={['120px', '160px']}
    >
      <Box
        paddingV={2}
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        alignItems={AlignItems.Center}
      >
        <MediumResponsiveHeader
          color={Color.White}
          display={Display.Inline}
          fontFamily={FontFamily.Bold}
        >
          {secondBoxDiscount}
        </MediumResponsiveHeader>
        <Space size={1} direction="horizontal" />

        <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
          <PercentageWithOff percentageSize={[6, 7]} offSize={[2, 4]} />
        </Box>
      </Box>

      <Text color={Color.White} size={[0 as FontSize, 3, 6]} textAlign={TextAlign.Center}>
        all boxes in your first two months
      </Text>
    </Box>
  </Box>
)
