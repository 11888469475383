import React from 'react'

import {
  Box,
  Icon,
  Text,
  Display,
  AlignItems,
  JustifyContent,
  Space,
  Color,
  IconVariant,
} from '@gousto-internal/citrus-react'

import { signupConfig } from 'routes/Signup/signupConfig'

export const PostcodeStepMessage = ({ deliveryDaysError }: { deliveryDaysError: string }) => {
  const footNote = signupConfig.postCodeStep.footNoteMessage
  let text = signupConfig.postCodeStep.reminder
  let isFootnoteVisible = true
  if (deliveryDaysError === 'do-not-deliver') {
    text = signupConfig.postCodeStep.doNotDeliverErrorMessage
    isFootnoteVisible = false
  } else if (deliveryDaysError) {
    text = signupConfig.postCodeStep.genericErrorMessage
    isFootnoteVisible = false
  }

  return (
    <>
      <Box
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        color={deliveryDaysError ? Color.Error_900 : Color.Success_600}
        data-testid="PostcodeStepMessage"
      >
        {!deliveryDaysError && (
          <>
            <Icon name="tick" variant={IconVariant.Inherit} />
            <Space direction="horizontal" size={1} />
          </>
        )}
        <Text size={3}>{text}</Text>
      </Box>
      {isFootnoteVisible && (
        <>
          <Space direction="vertical" size={6} />
          <Box
            display={Display.Flex}
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.Center}
            color={Color.ColdGrey_500}
            data-testid="PostcodeStepFootnote"
          >
            <Text size={1}>{footNote}</Text>
          </Box>
        </>
      )}
    </>
  )
}
