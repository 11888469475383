import React, { useState } from 'react'

import styled from '@emotion/styled'
import {
  AlignItems,
  BorderStyle,
  Box,
  Button,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  Heading2,
  Icon,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { PortionSizeSelector } from 'PortionSizeSelector/PortionSizeSelector'
import { useDispatch, useSelector } from 'react-redux'

import { basketIsPortionSizeViewVisibleChange, basketNumPortionChange } from 'actions/basket'
import { trackBoxSizeSelectionChange } from 'actions/boxSummary'
import { PortionSizes } from 'config/portionSizes'
import { useBasket, useSupportedBoxTypes } from 'routes/Menu/domains/basket'
import { getAvailableNumPortions, getBasketTotalRecipes, getNumPortions } from 'selectors/basket'

import { useGetUnavailableRecipesForPortionSize } from './hooks'

const UnorderedList = styled.ul({
  margin: '0',
  padding: '0',
})

const UnavailableRecipeListItem = ({ recipe }: any) => (
  <li key={recipe.recipeId} style={{ listStyleType: 'disc', marginLeft: '24px' }}>
    <Text size={2} fontFamily={FontFamily.Bold} display={Display.Inline} style={{ left: '-10px' }}>
      {recipe.recipeTitle}
    </Text>
  </li>
)

const WarningAlertWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box
    bg={Color.Warning_50}
    display={Display.Flex}
    paddingH={4}
    paddingV={4}
    alignItems={AlignItems.FlexStart}
    borderColor={Color.Warning_100}
    borderWidth={0.5}
    borderStyle={BorderStyle.Solid}
    borderRadius={1.5}
    gap={2}
    style={{ marginBottom: '12px' }}
  >
    <Icon name="warning" variant={IconVariant.Error} />
    {children}
  </Box>
)

export const PortionSize = () => {
  const { removeRecipe } = useBasket()
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const dispatch = useDispatch()
  const availablePortionSize = useSelector(getAvailableNumPortions)

  const basketPortionSize = useSelector(getNumPortions)
  const numRecipesInBasket = useSelector(getBasketTotalRecipes)

  const [selectedPortionSize, setSelectedPortionSize] = useState<PortionSizes>(basketPortionSize)
  const unavailableRecipesForPortionSize =
    useGetUnavailableRecipesForPortionSize(selectedPortionSize)

  const maxRecipesNumForPortion = maxRecipesForPortion(selectedPortionSize)
  const numRecipeToBeRemoved = numRecipesInBasket - maxRecipesNumForPortion

  const hasTooManyRecipesInBasketForSelectedPortionSize =
    numRecipesInBasket > maxRecipesNumForPortion

  const hasUnavailableRecipes = Object.values(unavailableRecipesForPortionSize).length > 0

  const shouldRenderWarning =
    hasUnavailableRecipes || hasTooManyRecipesInBasketForSelectedPortionSize

  const onConfirmBoxSize = () => {
    if (hasUnavailableRecipes) {
      unavailableRecipesForPortionSize.forEach((recipe) => {
        removeRecipe(recipe.recipeId)
      })
    }
    dispatch(basketNumPortionChange(selectedPortionSize, 'box_summary'))
    dispatch(basketIsPortionSizeViewVisibleChange(false))
  }

  const handlePortionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPortionSize(Number(event.target.value) as PortionSizes)
    dispatch(trackBoxSizeSelectionChange(Number(event.target.value), 'box_summary'))
  }

  const getWarningContent = () => {
    if (hasUnavailableRecipes) {
      return (
        <WarningAlertWrapper>
          <Box
            data-testing="unavailableRecipesWarning"
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
          >
            <Text size={2}>
              The following recipes will be removed as they aren’t available in a{' '}
              {selectedPortionSize}
              &nbsp;person box yet
            </Text>
            <Space size={1} />
            <UnorderedList>
              {unavailableRecipesForPortionSize.map((recipe) => (
                <UnavailableRecipeListItem key={recipe.recipeId} recipe={recipe} />
              ))}
            </UnorderedList>
          </Box>
        </WarningAlertWrapper>
      )
    }

    if (hasTooManyRecipesInBasketForSelectedPortionSize) {
      return (
        <WarningAlertWrapper>
          <Box
            data-testing="recipesOverLimitWarning"
            data-testid="recipesOverLimitWarning"
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
          >
            <Text fontFamily={FontFamily.Bold} size={2}>
              {selectedPortionSize} person boxes fit up to {maxRecipesNumForPortion} recipes right
              now.
            </Text>
            <Text size={2}>
              Remove{' '}
              <Text size={2} fontFamily={FontFamily.Bold} display={Display.Inline}>
                {numRecipeToBeRemoved}{' '}
              </Text>
              <Text size={2} display={Display.Inline}>
                {numRecipeToBeRemoved === 1 ? 'recipe' : 'recipes'} before you check out.
              </Text>
            </Text>
            <Space size={1} />
          </Box>
        </WarningAlertWrapper>
      )
    }

    return null
  }

  return (
    <Box
      paddingV={2}
      paddingH={2}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      data-testing="portionSizeView"
    >
      <Heading2> Edit box size</Heading2>
      <Box paddingV={3}>
        {availablePortionSize && (
          <PortionSizeSelector
            portionSize={selectedPortionSize}
            availablePortionSizes={availablePortionSize}
            onChange={handlePortionChange}
          />
        )}
      </Box>
      {shouldRenderWarning && getWarningContent()}
      <Button data-testing="portionSizeConfirmBoxSize" width="100%" onClick={onConfirmBoxSize}>
        Confirm box size
      </Button>
    </Box>
  )
}
