import React, { useCallback } from 'react'
import {
  Button,
  ButtonColorVariant,
  ButtonSize,
  Join,
  Space,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import { DeleteAccountModal } from './DeleteAccountModal'

const DeleteAccountSection = () => {
  const { openModal } = useModal()
  const onAccountDeleteClick = useCallback(() => {
    openModal('DeleteAccountModal')
  }, [openModal])

  return (
    <Join with={<Space size={6} />}>
      <DeleteAccountModal />
      <Text>{`We'd be sorry to see you go, but you can delete your account at any time.`}</Text>
      <Button
        onClick={onAccountDeleteClick}
        colorVariant={ButtonColorVariant.Warning}
        size={ButtonSize.Medium}
        width="100%"
      >
        {`Delete account`}
      </Button>
    </Join>
  )
}

export { DeleteAccountSection }
