import React from 'react'
import { Heading, LayoutPageWrapper } from 'goustouicomponents'
import Helmet from 'react-helmet'
import modernSlavery from 'config/modernSlavery'
import css from './ModernSlaveryStatement.css'

const ModernSlaveryStatement = () => (
  <LayoutPageWrapper>
    <Helmet
      title={modernSlavery.head.title}
      meta={modernSlavery.head.meta}
    />
    <section className={css.mainSection}>
      <Heading type="h1" size="fontStyleL" isCenter>Modern Slavery Statement</Heading>
      <p>This statement has been published in accordance with the Modern Slavery Act 2015. The statement sets out the steps taken by SCA Investments Limited trading as Gousto (“Gousto”) to prevent modern slavery and human trafficking in its business and supply chain.</p>
      <p>Gousto is committed to acting ethically and with integrity in all of its business dealings and relationships.  Gousto is committed to implementing and enforcing effective systems and controls to ensure modern slavery and human trafficking is not taking place anywhere in its business or wider supply chain.</p>
      <Heading type="h2" size="fontStyleM">Who we are</Heading>
      <p>Gousto is a recipe box food delivery company that’s on a mission to change the way its customers eat.  We deliver pre-measured fresh ingredients with easy to follow recipes directly to the doors of our customers.  Our menu features more than 60 recipes every week providing customers with incredible choice.</p>
      <p>Gousto was founded in London in June 2012.  Since then, the business has gone on to expand across the UK with fulfilment centres in Spalding, Warrington and Thurrock and new sites earmarked to open in the near future.</p>
      <p>Gousto’s goal is to become the UK’s most loved way to eat dinner by creating an amazing product that has a positive impact on both people and the planet.  Gousto strives to ensure that every meal leaves the world better off.  Gousto’s staff are working hard across the whole supply chain and operations in areas such as food waste reduction and removing plastic packaging.</p>
      <p>Gousto’s workforce is made up of permanent employees, temporary agency workers and a small number of specialist contractors. Gousto engages with a number of employment businesses to provide temporary agency workers to its fulfilment sites across the UK.  Our fulfilment sites therefore comprise a mixture of permanent employees and a number of agency workers engaged on a flexible basis, particularly during peak times to ensure the business is able to meet increased customer demand.  Gousto carries out a series of due diligence and compliance checks on all of its suppliers, including (without limitation) such employment agency businesses, before entering into contractual relationships with them to ensure it is monitoring and addressing risk of modern slavery and human trafficking in its supply chain (see section <i>“Staff training, policies on modern slavery & human trafficking and wider due diligence processes”</i> for more information). </p>
      <p>Gousto’s board of directors (the <strong>“Board”</strong>) has overall responsibility for ensuring the business complies with its legal and ethical obligations. The General Counsel together with the Director of Buying has primary and day-to-day responsibility for implementing the Company’s Anti Slavery and Human Trafficking policy, monitoring its use and effectiveness, dealing with any queries relating to it, and auditing internal control systems and procedures to ensure they are effective in countering modern slavery.</p>
      <Heading type="h2" size="fontStyleM">Gousto Supply Chains</Heading>
      <p>Gousto sources food ingredients from over 40 countries through UK importers and suppliers. Gousto does not currently have direct relationships with overseas suppliers at this time, however the UK based suppliers may have subsidiaries and joint ventures in other territories. All purchases and contracts are completed in the UK and approved by the relevant Gousto business-owner.</p>
      <p>The indirect supply chains include services and products that are not for resale, instead they support the fulfilment centre and the London headquarters. These services may include (but are not limited to) operational infrastructure, courier and logistics services, warehousing, maintenance, and IT and professional services such as marketing, human resources and consultant fees.</p>
      <p>Gousto is a member of Sedex, the world’s leading ethical trade membership organisation which works to improve the working conditions in global supply chains.  Sedex allows Gousto to better manage its social and environmental performance, ensure compliance with modern slavery and human rights legislation, and ultimately improve working conditions throughout its supply chain.  Gousto uses Sedex as a supplier management tool and remains committed to its use throughout the business.</p>
      <Heading type="h2" size="fontStyleM" name="test">Staff training, policies on modern slavery & human trafficking and wider due diligence processes</Heading>
      <p>Gousto has rolled out updated compulsory training for all employees, workers and contractors in the business. New joiners are also required to complete the training as part of their induction process. The purpose of the training is to explain what modern slavery and human trafficking is and to raise awareness of both issues to help our workforce recognise their role in identifying and reporting any concerns.  The training also includes a compulsory test at the end of the module which assesses individual’s on their ability to identify modern slavery and human trafficking using real world examples.</p>
      <p>Gousto recognises that its suppliers enable it to provide its customers with high-quality and ethically sourced and produced products while ensuring customers are provided with maximum value. Gousto has in place robust systems to onboard and manage supplier relationships to ensure it can identify and manage modern slavery and human trafficking risk in its supply chain.  Our supply chain is characterised by formal contracts ranging from the supply of ingredients and packaging to labour and logistics. We have dedicated teams who continuously engage with suppliers to ensure we manage modern slavery risk both at the outset of the relationship and throughout the entire duration. We are committed to maintaining the highest possible standards of integrity in our business relationship with suppliers, and in turn, look for suppliers and contractors who align themselves to our values and standards.  We have a supplier onboarding management process, which is a standardised process that every supplier is required to follow in order to enter into contractual relationships with Gousto.  The management process requires all new suppliers to answer questions around their own supply chain practices on ethical trading, modern slavery, anti-bribery and corruption.  Responses are then reviewed and approved by relevant stakeholders and Gousto’s legal team before the supplier can be approved for onboarding.  This ensure the business can ask questions and assess the level of risk around modern slavery before a supplier is onboarded.</p>
      <Heading type="h2" size="fontStyleM">Risk assessment & management and actions taken to prevent modern slavery</Heading>
      <p>As outlined above, Gousto has a supplier onboarding management policy pursuant to which suppliers are asked a series of questions in relation to their businesses practises and supply chain to establish the level of risk of modern slavery.   As part of the policy, we request that suppliers provide details of any steps they take to identify and mitigate the risk of modern slavery, bribery and corruption affecting their supply chains, including any labour related due diligence the supplier carries out on its own supply chain and suppliers.  We also request that suppliers provide details around (without limitation) how they monitor compliance, who is responsible for monitoring such compliance and how frequently it monitors or reviews its supply chain.  Gousto will then only contract with suppliers on the basis we receive sufficient and satisfactory responses to the questions raised around modern slavery and human trafficking.  If further information is required, we will ask for such information.  If responses are not sufficient or raise concerns, we may decide not to contract with the supplier. We continuously review the supplier management policy and update both the policy and our standard purchase terms and conditions as necessary.  We will also continue to evaluate risks in relation to modern slavery and ensure the strategy is updated accordingly.</p>
      <p>The mandatory training rolled out to Gousto’s entire workforce to explain what modern slavery and human trafficking is, how to recognise it and report concerns is also updated as necessary.  Gousto’s workforce is required to complete the training on an annual basis to ensure the business is well equipped to prevent modern slavery and human trafficking.</p>
    </section>
  </LayoutPageWrapper>
)

export {
  ModernSlaveryStatement,
}
