import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import { BorderStyle, Box, Color, Display, FlexDirection } from '@gousto-internal/citrus-react'
import Svg from 'Svg'

export enum AlertSeverity {
  Error,
  Success,
}

type AlertProps = {
  severity?: AlertSeverity
  children: ReactNode
}

const SvgStyled = styled(Svg)({
  width: '1.5rem',
  height: '1.5rem',
})

export const Alert = ({ severity = AlertSeverity.Error, children }: AlertProps) => {
  const isSuccess = severity === AlertSeverity.Success
  const iconFileName = isSuccess ? 'icon-success' : 'icon-danger'

  return (
    <Box
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      display={Display.Flex}
      paddingV={4}
      paddingH={4}
      bg={isSuccess ? Color.Success_50 : Color.Error_50}
      borderColor={isSuccess ? Color.Success_200 : Color.Error_900}
    >
      <Box display={Display.Flex} flexGrow={0} flexShrink={0} flexBasis="1.5rem" paddingRight={2}>
        <SvgStyled fileName={iconFileName} label={isSuccess ? 'success' : 'error'} />
      </Box>
      <Box display={Display.Flex} flexGrow={1} flexDirection={FlexDirection.Column}>
        {children}
      </Box>
    </Box>
  )
}
