import React from 'react'
import { Body2 } from '@gousto-internal/citrus-react'

import styled from '@emotion/styled'
import {
  cssExtraInformation,
  cssLabelContainer,
  cssPerServingText,
  cssSoldOutText,
  cssSurchargeAmountText,
  cssTitleContainer,
  cssTitleText,
  cssTitleActive,
} from './styles'

import { formatSurchargeValue } from '../../../utils/formatSurcharge'

type ItemContentProps = {
  recipeName: string
  isOutOfStock: boolean
  surcharge: number | null
  isChecked?: boolean
}

const LabelContainer = styled.div(cssLabelContainer)
const TitleContainer = styled.div(cssTitleContainer)
const Title = styled(Body2)((props: any) =>
  props.isChecked ? { ...cssTitleText, ...cssTitleActive } : cssTitleText,
)
const ExtraInformation = styled.div(cssExtraInformation)
const SurchargeAmountText = styled(Body2)(cssSurchargeAmountText)
const PerServingText = styled.span(cssPerServingText)
const SoldOutText = styled.span(cssSoldOutText)

export function ItemContent({
  recipeName,
  isOutOfStock,
  surcharge = null,
  isChecked,
}: ItemContentProps) {
  return (
    <LabelContainer>
      <TitleContainer>
        <Title isChecked={isChecked}>{recipeName}</Title>
      </TitleContainer>
      {surcharge && !isOutOfStock && (
        <ExtraInformation>
          <SurchargeAmountText>{formatSurchargeValue(surcharge)}</SurchargeAmountText>
          <PerServingText>per serving</PerServingText>
        </ExtraInformation>
      )}
      {isOutOfStock && <SoldOutText>Sold out</SoldOutText>}
    </LabelContainer>
  )
}
