import React, { useEffect } from 'react'

import { Box, Color, DisplayPosition, FontWeight, Space, Text } from '@gousto-internal/citrus-react'

import { HostedFieldsUI, InputFrame, SvgStyled } from '../../CheckoutFrame.styled'

type FrameFieldProps = {
  header: string
  hasLockIcon?: boolean
  dataFrames: string
  errorDataTesting: string
  errorMessage: string
  showError: boolean
  setHostedFieldsFrameLoaded?: (value: boolean) => void | undefined
}

export const FrameField = ({
  header,
  hasLockIcon = false,
  dataFrames,
  errorDataTesting,
  errorMessage,
  showError,
  setHostedFieldsFrameLoaded,
}: FrameFieldProps) => {
  const isMigrateToBraintreeEnabled = true

  useEffect(() => {
    if (setHostedFieldsFrameLoaded) {
      setHostedFieldsFrameLoaded(true)
    }

    return () => {
      if (setHostedFieldsFrameLoaded) {
        setHostedFieldsFrameLoaded(false)
      }
    }
  }, [setHostedFieldsFrameLoaded])

  return (
    <Box paddingV={1}>
      <Text fontWeight={FontWeight.Bold}>{header}</Text>
      <Space direction="vertical" size={2} />
      <Box position={hasLockIcon ? DisplayPosition.Relative : undefined}>
        {isMigrateToBraintreeEnabled ? (
          <HostedFieldsUI
            id={dataFrames}
            className={showError ? 'braintree-hosted-fields-invalid' : ''}
          />
        ) : (
          <InputFrame data-frames={dataFrames} />
        )}
        {hasLockIcon && (
          <Box position={DisplayPosition.Absolute} top="0.7rem" right="0.5rem" height="100%">
            <SvgStyled fileName="icon-checkout-lock" />
          </Box>
        )}
      </Box>
      <Space direction="vertical" size={2} />
      <Box data-testid={errorDataTesting} data-testing>
        {showError && (
          <Text size={1} color={Color.Error_900}>
            {errorMessage}
          </Text>
        )}
      </Box>
    </Box>
  )
}
