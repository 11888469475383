import {
  BorderStyle,
  Box,
  Color,
  Display,
  DisplayPosition,
  Icon,
  IconVariant,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import React from 'react'

type CheckboxProps = {
  isSelected: boolean
}

export function Checkbox({ isSelected }: CheckboxProps) {
  return (
    <Box
      data-testid="checkbox"
      borderWidth={0.5}
      borderRadius={1.5}
      borderColor={Color.White}
      borderStyle={BorderStyle.Solid}
      bg={Color.White}
      width="28px"
      height="28px"
      display={Display.Flex}
      position={DisplayPosition.Relative}
    >
      <Box
        display={Display.Flex}
        position={DisplayPosition.Absolute}
        justifyContent={JustifyContent.Center}
        top="1px"
        paddingH={0.25}
      >
        {isSelected ? (
          <Icon name="checkbox_selected" variant={IconVariant.Actionable} />
        ) : (
          <Icon name="checkbox_default" />
        )}
      </Box>
    </Box>
  )
}
