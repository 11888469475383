import { datadogLogs } from '@datadog/browser-logs'
import { canUseWindow } from '@library/environment'

export function httpWarning(message: string) {
  const warning = '@library/http: ' + message
  if (canUseWindow()) {
    datadogLogs.logger.warn(warning)
  } else {
    console.warn(warning)
  }
}
