import { Button, ResponsiveValue } from '@gousto-internal/citrus-react'
import React from 'react'
import { RafTrackingEventType } from '../../tracking/trackingConfig'
import { redirectToWizard } from '../../util/redirectToWizard'
export const CTAButton = ({
  text,
  trackingEvent,
  maxWidth = ['280px', '340px'],
}: {
  text: string
  trackingEvent: RafTrackingEventType
  maxWidth?: ResponsiveValue<string | number>
}) => {
  const redirectToWebclient = redirectToWizard
  return (
    <Button
      onClick={() => redirectToWebclient(trackingEvent)}
      width="100%"
      maxWidth={maxWidth}
      height="52px"
      data-testing="raf-landing-page-cta-button"
    >
      {text}
    </Button>
  )
}
