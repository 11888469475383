import React from 'react'

import { FormFieldStatus, InputField } from '@gousto-internal/citrus-react'
import { Field, FieldProps } from 'formik'

export const TownInput = () => (
  <Field name="town">
    {({ field, meta }: FieldProps) => (
      <InputField
        id={field.name}
        data-testid="town"
        label="City"
        status={meta.touched && meta.error ? FormFieldStatus.Error : undefined}
        validationMessage={meta.touched ? meta.error : undefined}
        maxLength={32}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
    )}
  </Field>
)
