import { CamelCasedValue, SnakeToCamelCase, Dict } from '@library/type-utils'

function isObject(obj: unknown): obj is Dict {
  return typeof obj === 'object' && obj !== null
}

function isArray(arr: unknown): arr is unknown[] {
  return Array.isArray(arr)
}

/**
 * Transform a single snake_case string to camelCase
 */
function snakeToCamelCase<S extends string>(str: S) {
  return str.replace(/([_][a-zA-Z\d])/g, (group) =>
    group.toUpperCase().replace('_', ''),
  ) as SnakeToCamelCase<S>
}

/**
 * Parses an argument of object type with unknown structure
 * with snake_case keys to camelCase
 *
 * If a non-object type is passed, argument is returned
 *
 * @example
 * ```
 * const result = parseObjectKeysToCamelCase({ foo_bar: 'baz' })
 * // result is { fooBar: 'baz' }
 * ```
 *
 */
export function parseObjectKeysToCamelCase<T>(obj: T): CamelCasedValue<T>
export function parseObjectKeysToCamelCase<T>(obj: unknown): T {
  if (isArray(obj)) {
    return obj.map(parseObjectKeysToCamelCase) as unknown as T
  }

  if (isObject(obj)) {
    return Object.entries(obj).reduce(
      (camelCasedObject, [key, value]) => ({
        ...camelCasedObject,
        [snakeToCamelCase(key)]: parseObjectKeysToCamelCase(value),
      }),
      {},
    ) as T
  }

  return obj as T
}
