import { endpoint } from '@library/endpoint'

import routes from 'config/routes'
import { fetch } from 'utils/fetch'

export interface BoxPriceDetails {
  pricePerPortion: string
  pricePerPortionDiscounted: string
  productTotal: string
  recipeTotal: string
  surchargeTotal: string
  recipeTotalDiscounted: string
  recipeDiscount: string
  flatDiscountApplied: boolean
  amountOff: string | null
  percentageOff: string | null
  promoCode: string | false
  promoCodeValid: boolean
  deliveryTotal: string
  grossTotal: string
  vatCharged: string
  total: string
  totalDiscount: string
  isDeliveryFree: boolean
}

export type BoxType = 'gourmet' | 'vegetarian'

export interface BoxPrices {
  [key: string]: {
    [key: string]: {
      [key in BoxType]: BoxPriceDetails
    }
  }
}

export interface BoxPricesResponse {
  data: BoxPrices
  meta: any[]
}

export function fetchBoxPrices(accessToken: string, reqData: any): Promise<BoxPricesResponse> {
  return fetch(accessToken, `${endpoint('core')}${routes.core.boxPrices}`, reqData, 'GET')
}
