import React, { useCallback, useEffect } from 'react'
import { browserHistory } from 'react-router'
import useSWR from 'swr'
import {
  ButtonColorVariant,
  Modal,
  ModalBody,
  ModalButton,
  ModalHeader,
  TextAlign,
  ModalFooter,
  Heading6,
  ButtonSize,
  Body1,
  Text,
  FontWeight,
  Space,
  Join,
  useModal,
} from '@gousto-internal/citrus-react'
import { ShippingAddress, fetchUserPendingOrders } from '@library/api-user'
import { ApiKeys } from '../../../enums'
import { BannerMessage } from './BannerMessage'
import { PendingOrdersList } from './PendingOrdersList'
import { formatShippingAddress } from './formatters'

const DefaultDeliveryAddressUpdatedModal = ({
  address,
  isOpen = false,
}: {
  address?: ShippingAddress
  isOpen?: boolean
}) => {
  const { data: orders } = useSWR(ApiKeys.UserCurrentPendingOrders, fetchUserPendingOrders)
  const { closeCurrentModal, getCurrentModal } = useModal()
  const onManageDeliveriesClick = useCallback(() => {
    browserHistory.push('/my-deliveries')
  }, [])

  // do not show if zero pending orders
  useEffect(() => {
    if (!orders || orders.length || getCurrentModal() !== 'DefaultDeliveryAddressUpdatedModal') {
      return
    }

    closeCurrentModal()
  }, [orders, closeCurrentModal, getCurrentModal])

  if (!orders) {
    return null
  }

  return (
    <Modal name="DefaultDeliveryAddressUpdatedModal" isOpen={isOpen} preventScroll>
      <ModalHeader>
        <Heading6 textAlign={TextAlign.Left}>
          Your delivery address is updated for new orders
        </Heading6>
      </ModalHeader>
      <ModalBody>
        <Join with={<Space size={6} direction="vertical" />}>
          <Body1>
            Any orders you place from now will be sent to:
            <Text size={1} fontWeight={FontWeight.Bold}>
              {formatShippingAddress(address)}
            </Text>
          </Body1>
          <BannerMessage />
          <PendingOrdersList orders={orders} />
        </Join>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={onManageDeliveriesClick}
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Medium}
          width="100%"
        >
          Manage deliveries
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export { DefaultDeliveryAddressUpdatedModal }
