import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import recipesActions from 'actions/recipes'

import { RecipeTile } from './RecipeTile'
import { RecipesContainer } from './styled'
import { useGetYourBoxData } from './yourBoxHooks'

type Props = { expanded: boolean }

export const RecipeList = ({ expanded }: Props) => {
  const [delayedExpanded, setDelayedExpanded] = useState(false)
  const dispatch = useDispatch()
  const { maxRecipesNum, recipesIdsList, menuRecipesStore } = useGetYourBoxData()

  useEffect(() => {
    const fetchRecipes = () => {
      if (menuRecipesStore && menuRecipesStore.size === 0) {
        dispatch(recipesActions.recipesLoadRecipesById(recipesIdsList))
      }
    }

    fetchRecipes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(
      () => {
        setDelayedExpanded(expanded)
      },
      expanded ? 300 : 100,
    )
  }, [expanded])

  return (
    <RecipesContainer
      expanded={expanded}
      delayedExpanded={delayedExpanded}
      maxRecipesNum={maxRecipesNum}
      recipesNum={recipesIdsList.filter(Boolean).length}
      data-testid={`RecipesContainer-${recipesIdsList.length}`}
    >
      {recipesIdsList.map((recipeId, index) => (
        <RecipeTile
          key={`summary-recipe-${recipeId || index}`}
          recipeId={recipeId}
          expanded={delayedExpanded}
        />
      ))}
    </RecipesContainer>
  )
}
