import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import { datadogRum, DefaultPrivacyLevel, RumInitConfiguration } from '@datadog/browser-rum'
import Cookies from 'cookies-js'

import {
  getClientDatadogRumSdkAppID,
  getClientDatadogRumSdkClientToken,
  getClientDatadogBrowserLogsClientToken,
} from '@library/environment/browser'
import { getEnvironment } from '@library/environment/isomorphic'

import {
  DATADOG_ENABLED_ENVS,
  SERVICE_NAME,
  E2E_DD_APP_ID,
  E2E_DD_CLIENT_TOKEN,
  E2E_DD_CTX_COOKIE,
  E2E_SERVICE_NAME,
} from '../constants'
import { getDatadogTrackingConsent } from './consent'

function isDatadogEnabledEnvironment() {
  const environment = getEnvironment()
  return DATADOG_ENABLED_ENVS.includes(environment)
}

function shouldEnableForE2E() {
  const cypressDDCookie = Cookies.get('v1_' + E2E_DD_CTX_COOKIE)
  return Boolean(cypressDDCookie)
}

function getCommonConfig(): Partial<LogsInitConfiguration & RumInitConfiguration> {
  return {
    env: getEnvironment(),
    sessionSampleRate: 50,
    service: SERVICE_NAME,
    site: 'datadoghq.eu',
    version: __APP_VERSION__,
    storeContextsAcrossPages: true,
    trackingConsent: getDatadogTrackingConsent(),
  }
}

function getE2EConfig(): RumInitConfiguration {
  return {
    ...getCommonConfig(),
    trackingConsent: 'granted', // Granted by default to enable instrumentation for e2e suite
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    service: E2E_SERVICE_NAME,
    applicationId: E2E_DD_APP_ID,
    clientToken: E2E_DD_CLIENT_TOKEN,
    defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      (url) => /http(s)?:\/\/.+\.gousto\.(local|info|co\.uk)(\/(.)*)?/g.test(url),
    ],
  }
}

function initialiseLoggingSDK() {
  datadogLogs.init({
    ...getCommonConfig(),
    clientToken: getClientDatadogBrowserLogsClientToken(),
    forwardErrorsToLogs: true,
  })
}

function initialiseRUMSDK() {
  datadogRum.init({
    ...getCommonConfig(),
    applicationId: getClientDatadogRumSdkAppID(),
    clientToken: getClientDatadogRumSdkClientToken(),
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: false,
  })
}

function initializeRUMSDKForE2E() {
  datadogRum.init(getE2EConfig())
}

function configureE2ERUMCtx() {
  try {
    const testCtx = JSON.parse(Cookies.get('v1_' + E2E_DD_CTX_COOKIE))
    datadogRum.setGlobalContext(testCtx)
  } catch (_) {}
}

export function initialiseDatadog() {
  if (shouldEnableForE2E()) {
    initializeRUMSDKForE2E()
    configureE2ERUMCtx()
    datadogRum.startSessionReplayRecording()
    return
  }

  if (isDatadogEnabledEnvironment()) {
    initialiseLoggingSDK()
    initialiseRUMSDK()
  }
}
