import { datadogLogs } from '@datadog/browser-logs'
import * as Yup from 'yup'
import React, { useCallback, useState } from 'react'
import { browserHistory } from 'react-router'
import { Field, Form, Formik, FieldProps } from 'formik'
import { deleteUserAccount, fetchCurrentUser, logoutUser, forgetUser } from '@library/api-user'
import { getAuthToken } from '@library/auth'
import useSWR from 'swr'
import {
  ButtonColorVariant,
  Modal,
  Text,
  ModalBody,
  ModalButton,
  ModalHeader,
  TextAlign,
  ModalFooter,
  Heading6,
  ButtonSize,
  FontWeight,
  Checkbox,
  Join,
  Space,
  Body1,
  Color,
} from '@gousto-internal/citrus-react'
import { ApiKeys } from '../../enums'

const subscriptionSettings = '/subscription-settings'
const root = '/'

const validationSchema = Yup.object().shape({
  confirmAccountDeletion: Yup.boolean().required().equals([true]),
})

const DeleteAccountModal = ({ isOpen = false }: { isOpen?: boolean } = {}) => {
  const { data } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const [isAccountDeleted, setAccountDeletedState] = useState(false)
  const [errorMessage, setError] = useState<string>('')
  const onClose = useCallback(async () => {
    if (!isAccountDeleted) {
      return
    }

    const authToken = getAuthToken()
    const accessToken = authToken?.__brand ? authToken.__brand : (authToken as string)

    try {
      await logoutUser()
      await forgetUser({ accessToken })
    } catch (error: unknown) {
      datadogLogs.logger.error('Logout error', { error })
      // do not re-throw: the Auth service sometimes fails "/forget" requests this leads to bad UX
    }

    // perform hard page refresh to re-render navigation panel and flush refreshToken stored in the Redux store
    window.location.assign(root)
  }, [isAccountDeleted])
  const onPauseSubscription = useCallback(() => {
    browserHistory.push(subscriptionSettings)
  }, [])
  const onSubmit = useCallback(async () => {
    setError('')
    try {
      await deleteUserAccount()
      setAccountDeletedState(true)
    } catch (e: unknown) {
      setError(`Sorry, we couldn’t process your request right now. Please try again.`)
    }
  }, [setAccountDeletedState])
  const isActiveSubscription = data?.user?.subscription?.state === 'active'

  return (
    <Modal isOpen={isOpen} preventScroll name="DeleteAccountModal" onRequestClose={onClose}>
      {isAccountDeleted ? (
        <>
          <ModalHeader>
            <Heading6 textAlign={TextAlign.Left}>{`Your account has been deleted`}</Heading6>
          </ModalHeader>
          <ModalBody>
            <Join with={<Space size={6} />}>
              <Body1>{`We can confirm that your account has been successfully deleted.`}</Body1>
              <Body1>{`If you’d like to return to Gousto in the future, please sign up for a new account on the home page.`}</Body1>
            </Join>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={onClose}
              colorVariant={ButtonColorVariant.Secondary}
              size={ButtonSize.Medium}
              width="100%"
            >
              {`Return to home page`}
            </ModalButton>
          </ModalFooter>
        </>
      ) : (
        <Formik
          initialValues={{ confirmAccountDeletion: false }}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              <ModalHeader>
                <Heading6 textAlign={TextAlign.Left}>{`Before you leave us`}</Heading6>
              </ModalHeader>
              <ModalBody>
                <Join with={<Space size={6} />}>
                  <Text fontWeight={FontWeight.Bold}>
                    {`Deleting your account means your cookbook history will be lost.`}
                  </Text>
                  <Body1>
                    {isActiveSubscription
                      ? `If you'd like to stop receiving recipes from us, we suggest pausing your subscription. You can reactivate this at any time.`
                      : `Your subscription has already been paused.`}
                  </Body1>
                  <Field type="checkbox" name="confirmAccountDeletion">
                    {({ field }: FieldProps) => (
                      <Checkbox id={field.name} {...field}>
                        {`Tick here to confirm that you wish to delete all your account details, Gousto cooking history and data.`}
                      </Checkbox>
                    )}
                  </Field>
                  {errorMessage && (
                    <Text color={Color.Error_700} size={2}>
                      {errorMessage}
                    </Text>
                  )}
                </Join>
              </ModalBody>
              <ModalFooter>
                <ModalButton
                  disabled={!isActiveSubscription}
                  colorVariant={ButtonColorVariant.Secondary}
                  onClick={onPauseSubscription}
                  size={ButtonSize.Medium}
                  width="100%"
                >
                  {`Pause my subscription`}
                </ModalButton>
                <ModalButton
                  type="submit"
                  disabled={!dirty || isSubmitting}
                  colorVariant={ButtonColorVariant.Warning}
                  size={ButtonSize.Medium}
                  width="100%"
                >
                  {`Delete account instead`}
                </ModalButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export { DeleteAccountModal }
