import React, { useCallback } from 'react'
import { useField } from 'formik'
import { Button } from '@gousto-internal/citrus-react'
import { DeliveryPoint, fetchPostcodeAddresses } from '@library/api-postcode-lookup'
import { AddressFields } from '../../../Form/Inputs'

interface AddressLookupButtonProps {
  onDeliveryPointsFetched: (entries: DeliveryPoint[]) => void
}

const AddressLookupButton = ({ onDeliveryPointsFetched }: AddressLookupButtonProps) => {
  const [{ value }, _, { setError }] = useField(AddressFields.PostCode)
  const onAddressLookup = useCallback(async () => {
    const postcode = value || ''

    if (postcode === '') {
      return
    }

    setError(undefined)

    try {
      const response = await fetchPostcodeAddresses(postcode)

      onDeliveryPointsFetched(response)
    } catch (e) {
      setError('No addresses found for specified postcode')
    }
  }, [value, onDeliveryPointsFetched, setError])

  return (
    <Button type="button" onClick={onAddressLookup} height="48px">
      Search
    </Button>
  )
}

export { AddressLookupButton }
