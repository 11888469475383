import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Color,
  Display,
  FlexDirection,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import { MenuSettingsRequestPayload } from '@library/menu-service'

import { useMenuPreferences } from 'routes/Menu/domains/menu'
import { onEnter } from 'utils/accessibility'

type MenuPreferencesOptionsProps = {
  onClose: (event: SyntheticEvent) => void
  variant: 'desktop' | 'mobile'
}

export const MenuPreferencesOptions = ({ onClose, variant }: MenuPreferencesOptionsProps) => {
  const { updateMenuPreferences, data } = useMenuPreferences()

  const [formPreferences, setFormPreferences] = useState<MenuSettingsRequestPayload>({
    dietary_preferences: [],
    equipment: [],
    display_calories: true,
  })

  const handleSave = (event: SyntheticEvent) => {
    onClose(event)
    updateMenuPreferences(formPreferences)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newPreferences = { ...formPreferences, display_calories: event.target.checked }

    setFormPreferences(newPreferences)

    if (variant === 'desktop') {
      updateMenuPreferences(newPreferences)
    }
  }

  // set the initial state based on the data from the API once it's loaded
  useEffect(() => {
    if (data) {
      setFormPreferences(data)
    }
  }, [data])

  return (
    <Box>
      {data ? (
        <>
          <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
            <Text color={Color.ColdGrey_800} fontWeight={700} size={3}>
              Recipe Information
            </Text>
            {variant === 'desktop' && (
              <Box
                data-testid="close-button"
                display={Display.Flex}
                role="button"
                tabIndex={0}
                onClick={onClose}
                onKeyDown={onEnter(onClose)}
              >
                <Icon name="close" size={5} variant={IconVariant.Actionable}>
                  Close menu settings
                </Icon>
              </Box>
            )}
          </Box>
          <Text color={Color.ColdGrey_800} size={2}>
            Customise information you see on recipes
          </Text>
          <Checkbox
            id={`show-calories-${variant}`}
            checked={formPreferences.display_calories ?? false}
            onChange={handleChange}
            paddingV={2}
            data-testid={`show-calories-${variant}`}
          >
            <Box display={Display.Flex} flexGrow={1} flexDirection={FlexDirection.Column}>
              <Text
                color={formPreferences.display_calories ? Color.Secondary_400 : Color.ColdGrey_800}
                size={3}
              >
                Calories (kcal)
              </Text>
              <Text color={Color.ColdGrey_600} size={2}>
                Calories are always visible when you view more details.
              </Text>
            </Box>
          </Checkbox>
          {variant === 'mobile' && (
            <>
              <Space size={5} />
              <Button data-testid="save-button" onClick={handleSave} width="100%">
                Save
              </Button>
            </>
          )}
        </>
      ) : null}
    </Box>
  )
}
