export const seActions = {
  SUBSCRIPTION_PAUSE_ATTEMPT: 'pause_subscription_attempt',
  START_MODAL_VIEWED: 'StartModalViewed',
  REASON_CATEGORY_MODAL_VIEWED: 'ReasonCategoryModalViewed',
  REASON_CATEGORY_SELECTED: 'ReasonCategorySelected',
  REASON_LIST_MODAL_VIEWED: 'ReasonListModalViewed',
  REASON_SELECTED: 'ReasonSelected',
  RECOVERY_ATTEMPT_MODAL_VIEWED: 'RecoveryAttemptModalViewed',
  SUBSCRIPTION_KEPT_ACTIVE: 'recover_subscription',
  SUBSCRIPTION_PAUSED: 'pause_subscription_complete',
  END_MODAL_VIEWED: 'EndModalViewed',
  VIEW_PAUSE_DISCOUNT_REMINDER_OFFER_SCREEN: 'view_pause_discount_reminder_offer_screen',
}
