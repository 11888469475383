/**
 * This code was migrated from webclient. For the original file's history, see
 * https://github.com/Gousto/gousto-webclient/blob/1544f0f8/src/apps/webclient/src/config/service-environment/find-service-environment.ts
 */

import type { ServiceManifest, ServiceName, ServiceVersion } from './types'

function throwIfServiceManifestIsUndefined(serviceManifest?: ServiceManifest) {
  if (!serviceManifest) {
    throw new Error(
      'Service manifest is undefined. A service manifest is required to find a service majorVersion.',
    )
  }
}

function throwIfServiceNotFoundInManifest(
  serviceName: string,
  serviceVersions: ServiceVersion[] | undefined,
): void {
  if (!serviceVersions || serviceVersions.length === 0) {
    throw new Error(`Service '${serviceName}' not found in manifest.`)
  }
}

function findServiceVersionInManifest(
  serviceName: ServiceName,
  majorVersion: number,
  serviceManifest: ServiceManifest,
) {
  return serviceManifest[serviceName].find((sv) => sv.majorVersion === majorVersion)
}

function throwIfVersionNotFoundForService(
  serviceName: ServiceName,
  majorVersion: number,
  serviceManifest: ServiceManifest,
) {
  if (findServiceVersionInManifest(serviceName, majorVersion, serviceManifest) === undefined) {
    throw new Error(
      `Service version ${majorVersion} for service '${serviceName}' not found in manifest`,
    )
  }
}

export function findServiceVersion(
  serviceName: ServiceName,
  majorVersion: number,
  serviceManifest: ServiceManifest,
): ServiceVersion {
  throwIfServiceManifestIsUndefined(serviceManifest)
  throwIfServiceNotFoundInManifest(serviceName, serviceManifest[serviceName])
  throwIfVersionNotFoundForService(serviceName, majorVersion, serviceManifest)

  const serviceVersion = findServiceVersionInManifest(serviceName, majorVersion, serviceManifest)

  return serviceVersion as ServiceVersion
}
