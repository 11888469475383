import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { MenuHeadersIncludesData } from 'routes/Menu/domains/brand/types'

import { merchandisingBannerClick } from '../../../actions/merchandisingBannerClick'
import { WaveLinkHeader } from '../WaveLinkHeader'

type OwnProps = {
  link: MenuHeadersIncludesData['attributes']['link']
}

const WaveLinkHeaderContainer = connect(null, (dispatch: Dispatch<any>, ownProps: OwnProps) => ({
  onClick: () => {
    const collectionId = ownProps.link?.collectionId
    if (collectionId) {
      dispatch(merchandisingBannerClick(collectionId))
    }
  },
}))(WaveLinkHeader)

export { WaveLinkHeaderContainer }
