import { useEffect, useState } from 'react'

import moment from 'moment'

import { Nullable } from '@library/type-utils'

const TICK_INTERVAL = 1000

function durationToFormat(duration: Nullable<number>, format: string) {
  if (duration === null) {
    return ' '
  }

  return moment.utc(moment.duration(duration, 'seconds').asMilliseconds()).format(format)
}

export function useTimeLeftUntilCutoff(unsafeCutoffISOString: string) {
  const [timeLeft, setTimeLeft] = useState<Nullable<number>>(null)

  const cutoffDateTime = moment(unsafeCutoffISOString)
  // Get timezone offsets for cutoff and user time
  const cutoffTZOffsetHrs = cutoffDateTime.toDate().getTimezoneOffset() / 60
  const userTimeOffsetHrs = moment().toDate().getTimezoneOffset() / 60

  const timezoneDifference = userTimeOffsetHrs - cutoffTZOffsetHrs

  useEffect(() => {
    function runCountdownTimer() {
      const userDateTime = moment().add(timezoneDifference * -1, 'hours')
      const timeLeftInSeconds = cutoffDateTime.diff(userDateTime, 'seconds')
      if (timeLeftInSeconds < 0) {
        setTimeLeft(0)

        return
      }

      setTimeLeft(timeLeftInSeconds)
    }

    runCountdownTimer()
    const interval = setInterval(runCountdownTimer, TICK_INTERVAL)

    return () => clearInterval(interval)
  }, [timezoneDifference, cutoffDateTime])

  return {
    hours: durationToFormat(timeLeft, 'H'),
    minutes: durationToFormat(timeLeft, 'mm'),
    seconds: durationToFormat(timeLeft, 'ss'),
  }
}
