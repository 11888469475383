import {
  Box,
  Heading5,
  Modal,
  ModalBody,
  ModalHeader,
  Body1,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { usePaymentCard3DSModal } from '../PaymentInfo/hooks/usePaymentCard3DSModal'

const ModalCardConfirmationFrame = withCitrusTheme('iframe', () => {
  return {
    maxWidth: '100%',
    width: '390px',
    height: '450px',
    border: 0,
    boxSizing: 'border-box',
    margin: '0 auto',
  }
})

const PaymentCard3DSModal = () => {
  const { cardConfirmationRedirectURL, onCardConfirmationLoaded, setCardConfirmationError } =
    usePaymentCard3DSModal()

  return (
    <Modal name="PaymentCard3DSModal">
      <ModalHeader>
        <Heading5>Card Verification</Heading5>
      </ModalHeader>
      <ModalBody>
        <Body1>
          {`You're almost there, Your bank will now verify your card details. If you're experiencing difficulties, please contact your bank`}
        </Body1>
        <Box display="flex">
          {cardConfirmationRedirectURL && (
            <ModalCardConfirmationFrame
              id="3dsFrame"
              title="3ds frame"
              onLoad={onCardConfirmationLoaded}
              onError={(event) => {
                console.warn(event)

                setCardConfirmationError('Card verification failed. Please try again.')
              }}
              src={cardConfirmationRedirectURL}
            />
          )}
        </Box>
      </ModalBody>
    </Modal>
  )
}

export { PaymentCard3DSModal }
