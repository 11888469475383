import React, { useEffect, useRef } from 'react'

import {
  Heading1,
  Display,
  Box,
  FlexDirection,
  JustifyContent,
  AlignItems,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { boxSummaryDeliverySlotChosen } from 'actions/boxSummary'
import { trackDeliveryDayEdited } from 'actions/deliveries'
import tempActions from 'actions/temp'
import { completeWizardDeliveryDay } from 'actions/trackingKeys'
import { useGet2DD10PMCutOff } from 'hooks/useGet2DD10PMCutOff'
import { DeliveryDay } from 'routes/Signup/Components/Calendar/models/DeliveryDay'
import {
  trackSignupWizardAction,
  trackDeliveryInstructionSelection,
} from 'routes/Signup/signupActions'
import { signupConfig } from 'routes/Signup/signupConfig'
import { getUserHasAvailableSlots, getTempDeliveryOptions } from 'routes/Signup/signupSelectors'
import { getNextDayDeliveryPaintedDoorFeatureEnabled } from 'selectors/features'
import {
  createNextDayDeliveryDays,
  generateNextDayDeliverySlots,
  getDateOffset,
} from 'utils/deliverySlot'

import { Image } from '../../Image'
import { DeliveryStepWithCalendar } from './DeliveryStepWithCalendar'
import { DeliveryStepWithoutCalendar } from './DeliveryStepWithoutCalendar'
import { trackDisplayEarliestDeliveryDate } from './utils/tracking'
import { getDeliveryDaysAndSlots } from './utils/utils'

export const DeliveryStep = ({ next }: { next: () => void }) => {
  const dispatch = useDispatch()
  const nextDayDeliveryPaintedDoorFeature = useSelector(getNextDayDeliveryPaintedDoorFeatureEnabled)
  const userHasAvailableSlots = useSelector(getUserHasAvailableSlots)
  const { deliveryDaysWithDisabled, tempDate, disabledSlots, tempSlotId } =
    useSelector(getTempDeliveryOptions)
  const [_isTwoDaysDelivery10PMCutOffEnabled, twoDaysDelivery10PMCutOffVariationValue] =
    useGet2DD10PMCutOff()
  const hasDisplayEarliestDeliveryDateEffectRun = useRef(false)
  useEffect(() => {
    if (
      !hasDisplayEarliestDeliveryDateEffectRun.current &&
      twoDaysDelivery10PMCutOffVariationValue &&
      ['cohort_a', 'cohort_c'].includes(twoDaysDelivery10PMCutOffVariationValue) &&
      tempDate
    ) {
      trackDisplayEarliestDeliveryDate(twoDaysDelivery10PMCutOffVariationValue, tempDate)
      hasDisplayEarliestDeliveryDateEffectRun.current = true
    }
  }, [twoDaysDelivery10PMCutOffVariationValue, tempDate])

  const { title } = signupConfig.deliveryOptionsStep

  let { slots, deliveryDays } = getDeliveryDaysAndSlots(
    deliveryDaysWithDisabled,
    tempDate,
    disabledSlots,
    userHasAvailableSlots,
  )

  if (nextDayDeliveryPaintedDoorFeature) {
    const nextDayDeliveryDays = createNextDayDeliveryDays()
    deliveryDays = [...nextDayDeliveryDays, ...deliveryDays] as DeliveryDay[]
    slots = { ...generateNextDayDeliverySlots(nextDayDeliveryDays), ...slots }
  }

  const dateLabel = deliveryDays.find((day) => day.date === tempDate)?.label || ''

  const onTempDateChange = (date: string) => {
    // If the date value has changed
    if (date !== tempDate) {
      // Track the edit
      const slotId = slots[date] ? slots[date][0].value : null
      dispatch(trackDeliveryDayEdited(date, getDateOffset(date), slotId))
    }

    dispatch(tempActions.temp('date', date))
    if (slots[date]) {
      const slotId = slots[date][0].value
      dispatch(tempActions.temp('slotId', slotId))
    }
  }

  const onShowRecipe = async () => {
    dispatch(trackDeliveryInstructionSelection())
    dispatch(trackSignupWizardAction(completeWizardDeliveryDay))
    await dispatch(
      boxSummaryDeliverySlotChosen({
        date: tempDate,
        slotId: tempSlotId,
      }),
    )
    next()
  }

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.SpaceBetween}
      alignItems={AlignItems.Center}
      data-testing="signupDeliveryStep"
    >
      <Box width="100%">
        <Box paddingBottom={3}>
          <>
            <Heading1>{title}</Heading1>
            {!userHasAvailableSlots && <Image name="delivery-day" />}
          </>
        </Box>
        {userHasAvailableSlots ? (
          <DeliveryStepWithCalendar
            deliveryDays={deliveryDays}
            tempDate={tempDate}
            onTempDateChange={onTempDateChange}
            dateLabel={dateLabel}
            onShowRecipe={onShowRecipe}
          />
        ) : (
          <DeliveryStepWithoutCalendar />
        )}
      </Box>
    </Box>
  )
}
