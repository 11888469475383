import React from 'react'

import { useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'

import { Receipt } from './Receipt'

export const ReceiptWrapper = (ownProps: any) => {
  const numRecipesOverLimit = useNumRecipesOverBasketLimit()
  const numRecipesOverBasketLimit = !!numRecipesOverLimit

  return (
    <Receipt
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      numRecipesOverBasketLimit={numRecipesOverBasketLimit}
    />
  )
}
