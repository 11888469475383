import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'cookies-js'

/**
 * Get datadog consent directly from consent cookie
 * Prevents accidentally adding any fetch imports to the module graph
 * which would break fetch instrumentation.
 */
export function getDatadogTrackingConsent() {
  const storageConsentCookie = Cookies.get('v1_gousto_cookie_consent')
  let consented = false

  if (storageConsentCookie) {
    try {
      const consentedCategories = JSON.parse(storageConsentCookie).level
      consented = consentedCategories.includes('ANALYTICS')
    } catch (_) {}
  }

  return consented ? 'granted' : 'not-granted'
}

export function setDatadogConsent() {
  const trackingConsent = getDatadogTrackingConsent()

  datadogLogs.setTrackingConsent(trackingConsent)
  datadogRum.setTrackingConsent(trackingConsent)
}
