import React from 'react'
import { browserHistory } from 'react-router'
import { Button, ButtonColorVariant } from '@gousto-internal/citrus-react'
import { trackSubscriptionSettingsChange } from '../../../tracking'

export const SkipABox = () => {
  const settingName = 'skip_a_box'
  const handleClick = () => {
    trackSubscriptionSettingsChange({ settingName, action: 'cta_clicked' })()
    browserHistory.push('/my-deliveries')
  }

  return (
    <Button
      colorVariant={ButtonColorVariant.Secondary}
      onClick={handleClick}
      width="100%"
      data-testid="skip-a-box-cta"
    >Skip box or boxes
    </Button>
  )
}
