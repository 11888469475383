import { CamelCasedValue, SnakeToCamelCase } from '@library/type-utils'

/**
 * Internals
 * ============================================================================
 */

/**
 * Transform a single snake_case string to camelCase
 */
function snakeToCamelCase<S extends string>(str: S) {
  return str.replace(/([_][a-zA-Z\d])/g, (group) =>
    group.toUpperCase().replace('_', ''),
  ) as SnakeToCamelCase<S>
}

/**
 * Module
 * ============================================================================
 */

export function parseObjectKeysToCamelCase<T>(obj: T): CamelCasedValue<T> {
  /**
   * This function uses quite a few type assertions. Ideally we would only rarely
   * do that in most of our application code. At a future point we should try
   * refactor this function to reduce the number of `as` clauses.
   */

  // Bail out when we reach leaf nodes
  if (typeof obj !== 'object' || obj === null) return obj as CamelCasedValue<T>

  return Object.keys(obj).reduce((camelCaseObject, currentKey) => {
    const currentValue = (obj as Record<string, unknown>)[currentKey]
    let parsedValue

    switch (true) {
      case Array.isArray(currentValue):
        parsedValue = (currentValue as unknown[]).map(parseObjectKeysToCamelCase)
        break
      case typeof currentValue === 'object' && currentValue !== null:
        parsedValue = { ...parseObjectKeysToCamelCase(currentValue as Record<string, unknown>) }
        break
      default:
        parsedValue = currentValue
    }

    return {
      ...camelCaseObject,
      [snakeToCamelCase(currentKey)]: parsedValue,
    }
  }, {}) as CamelCasedValue<T>
}
