import React, { useEffect, useState } from 'react'

import {
  AlignItems,
  Col,
  Display,
  Grid,
  JustifyContent,
  ScreenReaderLiveRegion,
  Space,
  Visible,
} from '@gousto-internal/citrus-react'
import { DiscountAppliedBar } from 'DiscountAppliedBar/DiscountAppliedBar'
import { useDiscountAppliedData } from 'DiscountAppliedBar/discountAppliedBarHooks'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'

import { redirect } from 'actions/redirect'
import { trackUTMAndPromoCode } from 'actions/tracking'
import { closeDiscountAppliedBar } from 'actions/trackingKeys'
import routesConfig from 'config/routes'
import { BoxSummaryDrawer } from 'routes/Checkout/Components/BoxSummaryDrawer/BoxSummaryDrawer'
import { CheckoutStepContainer } from 'routes/Checkout/Components/CheckoutStepContainer/CheckoutStepContainer'
import { CheckoutUrgency } from 'routes/Checkout/Components/CheckoutUrgency/CheckoutUrgency'
import { LoginModal } from 'routes/Checkout/Components/LoginModal'
import {
  clearPayPalClientToken,
  fetchGoustoRef,
  trackApplePayEligibility,
  trackSignupPageChange,
} from 'routes/Checkout/checkoutActions'
import {
  Breadcrumbs as BreadcrumbsEnum,
  CHECKOUT_STEPS,
  CheckoutStepIds,
} from 'routes/Checkout/checkoutConfig'
import { useIsCheckoutBoxTangibilityV3Enabled } from 'routes/Checkout/hooks'
import { useIsApplePayEnabled } from 'routes/Checkout/hooks/useIsApplePayEnabled'
import {
  BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA,
  GIFTING_EXPERIMENT_PROMO_CODE_DATA,
} from 'routes/Home/homeConfig'
import {
  signupDismissDiscountAppliedBar,
  signupShowDiscountAppliedBar,
} from 'routes/Signup/signupActions'
import { getPromoCode } from 'selectors/basket'

import { Breadcrumbs } from './Components/Breadcrumbs'
import { OrderSummaryAndBoxDetails as OrderSummaryAndBoxDetailsVariant } from './Components/CheckoutBoxTangibility'
import { OrderSummaryAndBoxDetails } from './Components/OrderSummaryAndBoxDetails'
import { useRedirectOnError } from './checkoutHooks'
import { getIsDiscountAppliedBannerDismissed } from './checkoutSelectors'

type CheckoutProps = {
  /**
   * URL params.
   */
  params: {
    /**
     * Step to display in Checkout flow right now.
     */
    stepName?: CheckoutStepIds
  }
}

/**
 * Checkout flow mechanism component.
 * Serves as logic wrapper for all steps in checkout flow.
 * Displays proper checkout step depending on URL.
 */
export const Checkout = ({ params: { stepName: urlStepId } }: CheckoutProps) => {
  const previousUrlStepId = usePrevious(urlStepId)
  const currentStepInfo = CHECKOUT_STEPS.find((checkoutStep) => checkoutStep.id === urlStepId)

  const dispatch = useDispatch()
  const isApplePayEligible = useIsApplePayEnabled()
  const basketPromo = useSelector(getPromoCode)
  const isGiftDiscountApplied =
    basketPromo === GIFTING_EXPERIMENT_PROMO_CODE_DATA.defaultPromoCode ||
    basketPromo === BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA.defaultPromoCode
  const { isDiscountEnabled, discountTip } = useDiscountAppliedData()
  const isDiscountAppliedBannerDismissed = useSelector(getIsDiscountAppliedBannerDismissed)

  useRedirectOnError()
  /**
   * Map of Breadcrumb to user-chosen Step.
   * For different breadcrumbs there can be different steps depending on what user clicked, this mapping "freezes" it.
   */
  const [breadcrumbsToStepsMapping, setBreadcrumbsToStepsMapping] = useState<{
    [key in BreadcrumbsEnum]?: CheckoutStepIds
  }>({
    [BreadcrumbsEnum.ACCOUNT]: CheckoutStepIds.ACCOUNT,
  })

  const redirectToStep = (stepId?: CheckoutStepIds): void => {
    if (!stepId) {
      return
    }
    const stepInfo = CHECKOUT_STEPS.find((checkoutStep) => checkoutStep.id === stepId)
    if (!stepInfo) {
      // should not happen, but theoretically can
      return
    }
    const nextStepId = stepInfo.id
    dispatch(trackSignupPageChange(nextStepId))
    dispatch(redirect(`${routesConfig.client['check-out']}/${nextStepId}`)) // ID serves as route
  }

  /**
   * First render. Load everything and redirect to "Account" step.
   */
  useEffect(() => {
    // make sure on reload we get to 1st step (Account)
    if (urlStepId !== CheckoutStepIds.ACCOUNT) {
      redirectToStep(CheckoutStepIds.ACCOUNT)
    }

    dispatch(fetchGoustoRef())
    dispatch(trackApplePayEligibility(isApplePayEligible))

    // show the DiscountAppliedBar component which may have been dismissed in the signup funnel
    if (isDiscountAppliedBannerDismissed) {
      dispatch(signupShowDiscountAppliedBar())
    }

    return () => {
      dispatch(clearPayPalClientToken())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Update "breadcrumbs to steps" mapping if stepId has changed.
   */
  useEffect(() => {
    if (!currentStepInfo) return
    setBreadcrumbsToStepsMapping({
      ...breadcrumbsToStepsMapping,
      [currentStepInfo.breadcrumb]: currentStepInfo.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlStepId])

  /**
   * Redirect to menu if there's no "stepName" that comes from URL, meaning it's just "/check-out" URL and previous step was "Account".
   * Why:
   * - initially user lands on "/check-out" URL, then user is getting redirected to "Account", meaning there would
   * always be "/check-out" entry in browser history.
   * Happens when:
   * - User is on "Account" step and clicks "Back" browser button, meaning user tries to go back to Menu (if previous
   * history entry is "/menu" from user perspective).
   */
  useEffect(() => {
    if (!urlStepId && previousUrlStepId === CheckoutStepIds.ACCOUNT) {
      dispatch(redirect(routesConfig.client.menu))
    }
  })

  const isDiscountAppliedBarVisible =
    isGiftDiscountApplied && isDiscountEnabled && !isDiscountAppliedBannerDismissed

  const handleDiscountAppliedBannerDismissal = () => {
    dispatch(trackUTMAndPromoCode(closeDiscountAppliedBar, { checkoutStep: urlStepId }))
    dispatch(signupDismissDiscountAppliedBar())
  }

  const isCheckoutBoxTangibilityV3Enabled = useIsCheckoutBoxTangibilityV3Enabled()

  return (
    <>
      <ScreenReaderLiveRegion urgency="polite">
        {isDiscountAppliedBarVisible && (
          <DiscountAppliedBar
            onDismiss={handleDiscountAppliedBannerDismissal}
            boxDiscount={discountTip || ''}
          />
        )}
      </ScreenReaderLiveRegion>

      <Grid
        gap={[0, 0, 4]}
        paddingH={[0, null, 8]}
        paddingBottom={[0, 12]}
        data-testing="checkoutContainer"
        data-testid="checkoutContainer"
      >
        <Col size={12}>
          <Visible display={[Display.Block, Display.None]}>
            <BoxSummaryDrawer isCreatingPreviewOrder={false} />
          </Visible>
        </Col>
        {isCheckoutBoxTangibilityV3Enabled ? (
          <>
            <Visible display={[Display.Block, Display.None]}>
              <Col
                data-testid="box-tangibility-variant-mobile"
                size={12}
                display={Display.Flex}
                paddingH={[0, 14, 20, 0]}
                paddingV={[4, 3]}
                alignItems={AlignItems.Center}
                justifyContent={JustifyContent.SpaceBetween}
              >
                <Breadcrumbs
                  currentBreadcrumb={currentStepInfo?.breadcrumb}
                  breadcrumbsToStepsMapping={breadcrumbsToStepsMapping}
                />
              </Col>
            </Visible>

            <Col size={12} data-testid="box-tangibility-variant-tablet-desktop">
              <Visible display={[Display.None, Display.Block]}>
                <CheckoutUrgency />
                <Breadcrumbs
                  currentBreadcrumb={currentStepInfo?.breadcrumb}
                  breadcrumbsToStepsMapping={breadcrumbsToStepsMapping}
                />
              </Visible>
              <Space size={[0, 4, 0]} />
            </Col>
          </>
        ) : (
          <Col size={12} data-testid="box-tangibility-control">
            <CheckoutUrgency />
            <Breadcrumbs
              currentBreadcrumb={currentStepInfo?.breadcrumb}
              breadcrumbsToStepsMapping={breadcrumbsToStepsMapping}
            />
            <Space size={[4, null, 0]} />
          </Col>
        )}
        <Col paddingH={[0, 14, 20, 0]} size={[12, null, null, 7]}>
          <CheckoutStepContainer
            currentStepId={urlStepId}
            onStepChange={(stepId) => redirectToStep(stepId)}
          />
          <Space size={[4, null, 0]} />
        </Col>
        <Col paddingH={[0, 14, 20, 0]} size={[12, null, null, 5]}>
          <Visible display={[Display.None, Display.Block]}>
            {isCheckoutBoxTangibilityV3Enabled ? (
              <OrderSummaryAndBoxDetailsVariant isCreatingPreviewOrder={false} />
            ) : (
              <OrderSummaryAndBoxDetails isCreatingPreviewOrder={false} />
            )}
          </Visible>
        </Col>
        <LoginModal />
      </Grid>
    </>
  )
}
