import React from 'react'

import { CSSObject } from '@emotion/styled'
import {
  FontWeight,
  Icon,
  IconVariant,
  Text,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  Space,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { formatOptionalPrice } from '../actionBarUtils'

type Props = {
  recipeCount: number
  maxRecipesNum: number
  nextTierPricePerPortion: string | null
}

const Info = withCitrusTheme(Text, (theme) => ({
  ...(theme.responsiveStyle(['13px', null, '12px'], ['fontSize']) as CSSObject),
}))

const Wrapper = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(['64px', '0px', '0px'], ['paddingRight']) as CSSObject),
}))

export const ActionBarText = ({ recipeCount, maxRecipesNum, nextTierPricePerPortion }: Props) => {
  const renderContent = () => {
    if (recipeCount === 0) {
      return (
        <Info>
          <Text fontWeight={FontWeight.Bold} display={Display.Inline}>
            Add first recipe
          </Text>
          {` to get started`}
        </Info>
      )
    } else if (recipeCount === 1) {
      return (
        <Info>
          <Text fontWeight={FontWeight.Bold} display={Display.Inline}>
            Add at least 2 recipes
          </Text>
          {` to checkout`}
        </Info>
      )
    } else if (recipeCount < maxRecipesNum) {
      const recipeCountDiff = maxRecipesNum - recipeCount

      return (
        <Info>
          <Text fontWeight={FontWeight.Bold} display={Display.Inline}>
            Add {recipeCountDiff} more recipe{recipeCountDiff > 1 ? 's' : ''}
          </Text>
          {` for the best price per portion of `}
          {formatOptionalPrice(nextTierPricePerPortion)}
        </Info>
      )
    } else {
      return (
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.FlexStart}
        >
          <Icon variant={IconVariant.Confirmation} name="tick" size={4} />
          <Space size={1} direction="horizontal" />
          <Info>
            {`Nice! You've got the `}
            <Text fontWeight={FontWeight.Bold} display={Display.Inline}>
              best price per portion of {formatOptionalPrice(nextTierPricePerPortion)}
            </Text>
          </Info>
        </Box>
      )
    }
  }

  return <Wrapper>{renderContent()}</Wrapper>
}
