import React from 'react'

import { formatSurcharge } from '../../utils/formatSurcharge'

import {
  Box,
  Text,
  FontFamily,
  FontWeight,
  JustifyContent,
  FlexDirection,
  Display,
  DisplayPosition,
} from '@gousto-internal/citrus-react'
import { SoldOutOverlay } from '../SoldOutOverlay'
import { getOverlayColor } from '../../utils/getOverlayColor'
import { Recipe } from '../../model/recipe'

type RecipeTilePurchaseInfoProps = {
  isRecipeOutOfStock: (recipeId: string) => boolean
  numPortions: number
  recipe: Recipe
}

export const RecipeTilePurchaseInfo: React.FC<RecipeTilePurchaseInfoProps> = ({
  isRecipeOutOfStock,
  numPortions,
  recipe,
}) => {
  const formattedSurcharge = formatSurcharge(recipe, numPortions)

  const justifyContent = !!formattedSurcharge ? JustifyContent.SpaceBetween : JustifyContent.FlexEnd

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Row} justifyContent={justifyContent}>
      {Boolean(formattedSurcharge) && (
        <Box position={DisplayPosition.Relative} style={{ paddingTop: '8px' }}>
          <SoldOutOverlay
            backgroundColor={getOverlayColor(recipe)}
            isRecipeOutOfStock={isRecipeOutOfStock}
            recipe={recipe}
          />
          <Text size={2} fontFamily={FontFamily.Book} fontWeight={FontWeight.Normal}>
            {formattedSurcharge} a serving
          </Text>
        </Box>
      )}
    </Box>
  )
}
