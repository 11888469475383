import { List } from 'immutable'

import { AVAILABLE_STEP_COMPONENTS } from 'routes/Signup/constants/AvailableStepComponents'
import { SignupSteps } from 'routes/Signup/constants/SignupSteps'
import { signupConfig } from 'routes/Signup/signupConfig'

/**
 * Returns steps for signup. Steps without a component are filtered out.
 * @param isMenuPersonalisationEnabled - Whether or not in the variant for the menu personalization of the OFX experience
 */
export const getSignupSteps = (isMenuPersonalisationEnabled = false): List<SignupSteps> => {
  const resultSteps: List<SignupSteps> = List(
    isMenuPersonalisationEnabled
      ? signupConfig.menuPersonalisationSteps
      : signupConfig.defaultSteps,
  )

  /**
   * Filter out steps that do not have a component.
   */
  return resultSteps.filter(
    (step) => !!AVAILABLE_STEP_COMPONENTS[step as SignupSteps],
  ) as List<SignupSteps>
}
