import React, { SyntheticEvent } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonColorVariant,
  ButtonVariant,
  Color,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

const ButtonStyled = withCitrusTheme(Button, (theme) => ({
  '&:disabled, &:disabled:hover, &:disabled:focus': {
    color: theme.color(Color.White),
  },
}))

export const AddRecipeButton: React.FC<{
  recipeId: string
  setBrowserCTAVisible: () => void
  canAddRecipes: boolean
  addRecipe: (recipeId: string) => void
  removeRecipe: (recipeId: string) => void
  reachedLimit: boolean
  isRecipeInBasket: (recipeId: string) => boolean
  isRecipeOutOfStock: (recipeId: string) => boolean
}> = ({
  recipeId,
  setBrowserCTAVisible,
  canAddRecipes,
  addRecipe,
  removeRecipe,
  reachedLimit,
  isRecipeInBasket,
  isRecipeOutOfStock,
}) => {
  const isInBasket = isRecipeInBasket(recipeId)
  const isOutOfStock = isRecipeOutOfStock(recipeId)
  const disabled = isOutOfStock || (reachedLimit && !isInBasket)

  const buttonAction: React.EventHandler<SyntheticEvent<unknown>> = (e) => {
    e.stopPropagation()

    if (!canAddRecipes) {
      setBrowserCTAVisible()
      return
    }

    if (isInBasket) {
      removeRecipe(recipeId)
    } else {
      addRecipe(recipeId)
    }
  }

  const buttonKeyPressAction: React.KeyboardEventHandler = (e) => {
    e.stopPropagation()

    if (e.keyCode === 13) {
      buttonAction(e)
    }
  }

  return (
    <ButtonStyled
      variant={ButtonVariant.Icon}
      icon={isInBasket ? 'remove' : 'add'}
      colorVariant={isInBasket ? ButtonColorVariant.Secondary : ButtonColorVariant.Primary}
      disabled={disabled}
      onClick={buttonAction}
      onKeyPress={buttonKeyPressAction}
      data-testing={disabled ? 'menuRecipeAddDisabled' : 'menuRecipeAdd'}
      aria-label={isInBasket ? 'Remove recipe' : 'Add recipe'}
    />
  )
}

AddRecipeButton.propTypes = {
  recipeId: PropTypes.string.isRequired,
}
