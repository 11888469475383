import React from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Button,
  ButtonSize,
  Display,
  FlexDirection,
  Heading5,
  Icon,
  IconVariant,
  Space,
  TextAlign,
} from '@gousto-internal/citrus-react'

import { queueTrackingEvent } from '@library/tracking-integration'

type SearchErrorProps = {
  onRetry: () => void
}

export const SearchError = ({ onRetry }: SearchErrorProps) => {
  const handleRetry = () => {
    queueTrackingEvent<{ action: 'search_retry' }>({ action: 'search_retry' })
    onRetry()
  }

  return (
    <Box alignItems={AlignItems.Center} display={Display.Flex} flexDirection={FlexDirection.Column}>
      <Icon name="error" size={[12, 12, 16]} variant={IconVariant.Error} />
      <Space size={4} />
      <Heading5 textAlign={TextAlign.Center}>We can&apos;t load results right now</Heading5>
      <Body2 textAlign={TextAlign.Center}>
        There&apos;s either a technical issue or poor connectivity
      </Body2>
      <Space size={4} />
      <Button onClick={handleRetry} size={ButtonSize.Large}>
        Try again
      </Button>
    </Box>
  )
}
