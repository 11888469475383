import React from 'react'
import { Box, Image, Overflow } from '@gousto-internal/citrus-react'
import peekImageJpg from '../../../assets/peekInBoxImage.jpg'
import peekImageWebp from '../../../assets/peekInBoxImage.webp'

export const PeekInTheBoxImage = () => (
  <Box borderRadius="round" overflowY={Overflow.Hidden}>
    <Image
      width={['300px', '425px']}
      srcSet={peekImageWebp}
      src={peekImageJpg}
      alt="Peek in the box"
    />
  </Box>
)
