import React, { useCallback } from 'react'

import {
  Body1,
  Box,
  Button,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  JustifyContent,
  StepperButton,
  TextAlign,
} from '@gousto-internal/citrus-react'
import actions from 'actions'
import { useDispatch } from 'react-redux'

import { useBasket, useSupportedBoxTypes } from 'routes/Menu/domains/basket'

import { menuRecipeDetailVisibilityChange } from '../../../actions/menuRecipeDetails'
import { Surcharge } from './Surcharge'

type ButtonsProps = {
  buttonText?: string
  isOutOfStock?: boolean
  position?: string
  recipeId: string
  surcharge?: number
}

export const RecipeDetailsButtons = ({
  buttonText = 'Add recipe',
  isOutOfStock = false,
  position = '',
  recipeId,
  surcharge,
}: ButtonsProps) => {
  const dispatch = useDispatch()
  const {
    numPortions,
    reachedLimit,
    getQuantitiesForRecipeId,
    canAddRecipes,
    addRecipe,
    removeRecipe,
  } = useBasket()
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const maxRecipesNum = maxRecipesForPortion(numPortions)
  const qty = getQuantitiesForRecipeId(recipeId)
  const disabled = isOutOfStock || reachedLimit
  const dataTesting = qty < 1 ? 'menuRecipeAdd' : 'menuAddServings'

  const handleAdd = useCallback(
    (isFirstInBatchOfSameRecipes: boolean) => {
      if (!isOutOfStock && canAddRecipes) {
        addRecipe(recipeId, 'detail', { position }, maxRecipesNum)

        if (isFirstInBatchOfSameRecipes) {
          dispatch(menuRecipeDetailVisibilityChange())
        }
      } else {
        dispatch(menuRecipeDetailVisibilityChange())
        setTimeout(() => {
          dispatch(actions.menuBrowseCTAVisibilityChange(true))
        }, 500)
      }
    },
    [isOutOfStock, canAddRecipes, addRecipe, recipeId, position, maxRecipesNum, dispatch],
  )

  const handleRemove = useCallback(() => {
    removeRecipe(recipeId, 'detail', position)
  }, [recipeId, position, removeRecipe])

  const renderAddButton = () => (
    <Button onClick={() => handleAdd(true)} disabled={disabled} width="100%">
      <Box display={Display.Flex} flexDirection={[FlexDirection.Column, FlexDirection.Row]}>
        {buttonText}
        {surcharge && (
          <Box paddingV={[1, 0]}>
            <Surcharge surcharge={surcharge} />
          </Box>
        )}
      </Box>
    </Button>
  )

  const renderStepperButton = () => {
    const totalQty = qty * numPortions

    return (
      <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
        <StepperButton
          fullWidth
          increment={() => handleAdd(false)}
          decrement={handleRemove}
          count={1}
          disableIncrement={reachedLimit || isOutOfStock}
          disableDecrement={isOutOfStock}
        >
          <Box
            display={Display.Flex}
            flexDirection={[FlexDirection.Column, FlexDirection.Row]}
            paddingH={surcharge ? [2, 6] : [4, 18]}
          >
            <Body1
              color={Color.Secondary_400}
              fontFamily={FontFamily.Bold}
              textAlign={TextAlign.Center}
            >{`${totalQty} Servings Added`}</Body1>
            {surcharge && <Surcharge surcharge={surcharge} />}
          </Box>
        </StepperButton>
      </Box>
    )
  }

  return (
    <Box
      data-testing={disabled ? 'menuRecipeAddDisabled' : dataTesting}
      width="full"
      style={{ flex: 1, backgroundColor: Color.White }}
    >
      {qty > 0 ? renderStepperButton() : renderAddButton()}
    </Box>
  )
}
