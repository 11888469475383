import React from 'react'

import Svg from 'Svg'
import classNames from 'classnames'

import config from 'config/company'

import css from './ErrorPage.css'

type Error = {
  title: string
  subTitle: string
  svg: string
}

const errors: Record<string, Error> = {
  403: {
    title: "Something's Fishy",
    subTitle: "You're not able to see the page you have requested",
    svg: 'icon-gousto-iso',
  },
  404: {
    title: 'Oh crumbs!',
    subTitle: "We can't find the page you're looking for",
    svg: 'icon-gousto-iso',
  },
  500: {
    title: 'Oh crumbs!',
    subTitle: "That wasn't meant to happen",
    svg: 'icon-gousto-iso',
  },
}

type ErrorPageProps = {
  status: string
}

const ErrorPage = ({ status = '404' }: ErrorPageProps) => {
  const httpStatus = errors[status] ? status : '404'
  const page = errors[httpStatus]

  return (
    <div className={css.container}>
      <div className={css.row}>
        <div className={css.errorWrapSmall}>
          <div className={css.row}>
            <h1 className={css.textHeading}>{page.title}</h1>
            <h2 className={css.description}>{page.subTitle}</h2>
            <h3 className={css.descriptionSM}>
              Please try again or get in touch with our Customer Care team.
            </h3>
            <h2 className={css.contact}>
              <a
                href={`tel:${config.telephone.link}`}
                className={classNames(css.contactLink, css.phoneNumber)}
              >
                <span className={css.iconEarphone} />
                {config.telephone.number}
              </a>
              <a href={`mailto:${config.email}`} className={css.contactLink}>
                <Svg fileName="icon-email" className={css.email} />
                {config.email}
              </a>
            </h2>
          </div>
          <div className={css.row}>
            <Svg fileName={page.svg} className={css.goustoIsotype} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { ErrorPage }
