import React from 'react'

import { Text, FontFamily, FontWeight } from '@gousto-internal/citrus-react'

import { useGetDeliveryData } from './deliveryDateHooks'

export const DeliveryDate = () => {
  const { formattedDate, formattedSlots } = useGetDeliveryData()

  return (
    <>
      <Text fontWeight={FontWeight.Bold} size={1}>
        {formattedDate}
      </Text>
      <Text fontFamily={FontFamily.Book} size={2}>
        {formattedSlots.toUpperCase()}
      </Text>
    </>
  )
}
