import React, { useEffect } from 'react'

import {
  Box,
  Icon,
  Space,
  Text,
  Display,
  IconVariant,
  Color,
  BorderStyle,
  JustifyContent,
  FontWeight,
} from '@gousto-internal/citrus-react'

import { onEnter } from 'utils/accessibility'

type DiscountAppliedBarProps = {
  boxDiscount: string
  onShow?: () => void
  onDismiss: () => void
}

export const DiscountAppliedBar = ({ onShow, onDismiss, boxDiscount }: DiscountAppliedBarProps) => {
  useEffect(() => {
    if (onShow) {
      onShow()
    }
  }, [onShow])

  return (
    <Box
      data-testid="discountAppliedBanner"
      display={Display.Flex}
      paddingV={4}
      paddingH={[3, 8]}
      paddingRight={[null, 3]}
      bg={Color.Success_50}
      borderStyle={BorderStyle.Solid}
      borderColor={Color.Success_600}
      borderBottomWidth={0.5}
    >
      <Space direction="horizontal" size={[0, 1]} />
      <Box display={Display.Flex} justifyContent={JustifyContent.Center} flexGrow={1}>
        <Icon name="success" variant={IconVariant.Confirmation} />
        <Space size={3} direction="horizontal" />
        <Box>
          <Text display={Display.Inline} size={2}>
            Discount applied:&nbsp;
          </Text>
          <Text display={Display.Inline} size={2} fontWeight={FontWeight.Bold}>
            {boxDiscount}
          </Text>
        </Box>
      </Box>
      <Box
        display={Display.Flex}
        role="button"
        tabIndex={0}
        onClick={onDismiss}
        onKeyDown={onEnter(onDismiss)}
      >
        <Icon name="close" variant={IconVariant.Default}>
          Close discount applied banner
        </Icon>
      </Box>
    </Box>
  )
}
