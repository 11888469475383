import useSWR from 'swr'

import { fetchPostcodeAddresses } from '@library/api-postcode-lookup'

export const usePostcodeAddresses = (postcode: string) => {
  const { data, error } = useSWR(postcode || null, fetchPostcodeAddresses, {
    revalidateIfStale: false, // Prevents re-fetching when the component remounts and the cached data might be stale
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onErrorRetry: (errorObj, _, __, revalidate, { retryCount }) => {
      if (errorObj.name === 'PostcodeError') {
        // the api-postcode-look up lib throws a custom error when the postcode is invalid and we don't want to retry when this happens
        return
      }
      setTimeout(() => revalidate({ retryCount }), 5000)
    },
  })

  return {
    addresses: data,
    isPostcodeError: error,
  }
}
