import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setHeaders,
} from '@library/http/requests'

type MenuActivePeriodRequestParams = {
  headers: Record<string, string>
}

const parseMenuActivePeriodResponse = async (response: Response) => {
  const responseClone = await response.text()
  return JSON.parse(responseClone)
}

const menuActivePeriodRequest = composeRequest(
  setServiceUrl('menu', 1),
  addPath('active-periods'),
  setMethod('GET'),
  setHeaders((params: MenuActivePeriodRequestParams) => {
    return params.headers
  }),
)

export const menuActivePeriodsFetcher = composeFetch(
  menuActivePeriodRequest,
  parseMenuActivePeriodResponse,
)
