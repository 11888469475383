import { CheckoutStore } from 'routes/Checkout/models/CheckoutStore'

interface OrderConflictResponse {
  code: string
  oldOrderId: number
  newOrderId: number
}

export const hasOrderConflict: (state: CheckoutStore) => null | OrderConflictResponse = (state) => {
  const errors = state.checkout.get('errors')
  if (!errors) {
    return null
  }
  const orderConflictErrors = errors.filter((error) => {
    const err: unknown = error

    return typeof err === 'object' && (err as OrderConflictResponse)?.code === 'save-order-conflict'
  })

  return orderConflictErrors.size > 0
    ? (orderConflictErrors.first() as unknown as OrderConflictResponse)
    : null
}
