import React from 'react'

import {
  Box,
  Text,
  Space,
  FontWeight,
  FontFamily,
  FontSize,
  Color,
  Image,
  Display,
} from '@gousto-internal/citrus-react'
import Immutable from 'immutable'

type OrderedRecipeProps = {
  title: string
  media: Immutable.List<string>
  isFineDineIn: boolean
}

export const OrderedRecipe = ({ title, media, isFineDineIn }: OrderedRecipeProps) => (
  <>
    <Box display={Display.Flex} paddingBottom={2}>
      <Box>
        <Image
          width={['60px', '80px']}
          height={['60px', '80px']}
          src={media.getIn([1, 'src'])}
          alt={`Image of ${title}`}
        />
      </Box>
      <Space size={4} direction="horizontal" />
      <Box>
        <Text fontWeight={FontWeight.Bold} fontFamily={FontFamily.Bold} size={2}>
          {title}
        </Text>
        {isFineDineIn ? (
          <Box
            bg={Color.Informative_50}
            borderRadius={1.5}
            display={Display.InlineBlock}
            paddingV={0.5}
            paddingH={1}
          >
            <Text size={0 as FontSize} fontWeight={FontWeight.Normal}>
              Fine Dine In
            </Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  </>
)
