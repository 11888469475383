import React from 'react'
import {
  Box,
  Color,
  FlexDirection,
  Space,
  Text,
  FontWeight,
  AlignItems,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import type { ShippingAddress } from '@library/api-user'

const AddressRadioLabel = ({
  address,
  focused,
  children,
}: {
  address: ShippingAddress
  focused: boolean
  children: React.ReactNode
}) => {
  return (
    <Box
      display="flex"
      flexDirection={FlexDirection.Row}
      flexGrow={1}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Space size={2} direction="horizontal" />
      <Box display="flex" flexGrow={1} flexDirection={FlexDirection.Column}>
        <Text
          size={2}
          color={focused ? Color.Secondary_400 : Color.ColdGrey_800}
          fontWeight={focused ? FontWeight.Bold : FontWeight.Normal}
        >
          {address.shippingDefault ? `${address.name} (default)` : address.name}
        </Text>
        <Text size={1} color={Color.ColdGrey_600}>
          {`${address.line1}, ${address.postcode}`}
        </Text>
      </Box>
      {children}
    </Box>
  )
}

export { AddressRadioLabel }
