import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useModal } from '@gousto-internal/citrus-react'
import { trackSubscriptionSettingsChange } from 'routes/Account/Subscription/tracking'
import { MultiSkipEmptyScreen } from './MultiSkipEmptyScreen/index'
import { MultiSkipScreen } from './MultiSkipScreen'
import { MultiSkipResultScreen } from './MultiSkipResultScreen'

export const MULTI_SKIP_MODAL_NAME = 'MULTI-SKIP-MODAL'

export const MultiSkipScreenLogic = ({
  handleSkipBoxes,
  newOrders,
  closeModal,
  alreadySkippedBoxesCount,
  nextDeliveryDate,
  isMultiSkipSuccess,
  isMultiSkipError,
  multiSkippedBoxesCount,
  trackViewMultiSkip,
  subscriptionPauseTrackRecover,
  userId,
  userLoadNewOrders,
  isModalOpen,
  isLoadingOrders,
  onUpdateComplete,
}) => {
  const [selectedOrderIds, setSelectedOrderIds] = useState([])
  const [isMultiskipComplete, setMultiskipComplete] = useState(false)
  const { closeCurrentModal } = useModal()

  useEffect(trackViewMultiSkip, [trackViewMultiSkip])

  const hasOrdersToSkip = useMemo(() => newOrders.filter(x => x.canSkip).length > 0, [newOrders])

  const toggleSkipBox = (id, shouldSkip) => {
    setSelectedOrderIds(shouldSkip
      ? [...selectedOrderIds, id]
      : selectedOrderIds.filter(orderId => id !== orderId))
  }

  const handleOnUpdateComplete = useCallback(() => {
    closeModal()
    onUpdateComplete()
  }, [closeModal, onUpdateComplete])

  const handleCloseModal = useCallback(() => {
    subscriptionPauseTrackRecover()
    closeModal()
  }, [subscriptionPauseTrackRecover, closeModal])

  const handleNoOrdersScreenCloseModal = useCallback(() => {
    trackSubscriptionSettingsChange({ action: 'click', settingName: 'pause_recovery_no_boxes' })
  }, [])

  const selectedOrders = newOrders.filter(({ id }) => selectedOrderIds.includes(id))

  const skipBoxes = useCallback(() => {
    handleSkipBoxes({ selectedOrders }, userId)
  }, [handleSkipBoxes, selectedOrders, userId])

  useEffect(() => {
    if (isMultiSkipSuccess || isMultiSkipError) {
      closeCurrentModal()
      setMultiskipComplete(true)
    }
  }, [isMultiSkipSuccess, isMultiSkipError, closeCurrentModal])

  useEffect(() => {
    if (userId) {
      userLoadNewOrders(true)
    }
  }, [userLoadNewOrders, userId])

  if (isMultiskipComplete) {
    return (
      <MultiSkipResultScreen
        onClose={handleOnUpdateComplete}
        nextDeliveryDate={nextDeliveryDate}
        isSuccess={isMultiSkipSuccess}
        skippedBoxesCount={multiSkippedBoxesCount}
      />
    )
  } else if (hasOrdersToSkip) {
    return (
      <MultiSkipScreen
        modalName={MULTI_SKIP_MODAL_NAME}
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        alreadySkippedBoxesCount={alreadySkippedBoxesCount}
        handleSkipBoxes={skipBoxes}
        newOrders={newOrders}
        toggleSkipBox={toggleSkipBox}
        selectedOrderIds={selectedOrderIds}
        isLoadingOrders={isLoadingOrders}
      />
    )
  } else {
    return (
      <MultiSkipEmptyScreen
        modalName={MULTI_SKIP_MODAL_NAME}
        isModalOpen={isModalOpen}
        onClose={handleNoOrdersScreenCloseModal}
      />
    )
  }
}

const newOrderShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isProjected: PropTypes.bool.isRequired,
  canSkip: PropTypes.bool.isRequired,
  deliveryDay: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  deliveryDayId: PropTypes.string.isRequired
})

MultiSkipScreenLogic.propTypes = {
  newOrders: PropTypes.arrayOf(newOrderShape).isRequired,
  handleSkipBoxes: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onUpdateComplete: PropTypes.func.isRequired,
  alreadySkippedBoxesCount: PropTypes.number.isRequired,
  nextDeliveryDate: PropTypes.string,
  isMultiSkipSuccess: PropTypes.bool.isRequired,
  isMultiSkipError: PropTypes.bool.isRequired,
  multiSkippedBoxesCount: PropTypes.number,
  trackViewMultiSkip: PropTypes.func.isRequired,
  subscriptionPauseTrackRecover: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userLoadNewOrders: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  isLoadingOrders: PropTypes.bool,
}

MultiSkipScreenLogic.defaultProps = {
  multiSkippedBoxesCount: null,
  nextDeliveryDate: null,
  isModalOpen: false,
  isLoadingOrders: false,
}
