import React from 'react'

import {
  Body1,
  Box,
  Color,
  CSSKeywords,
  FontFamily,
  Icon,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { useDecision } from '@optimizely/react-sdk'

import { DiscountBarContainer, DiscountBarProps } from './DiscountBarContainer'

export const DiscountBar = ({ isHidden, isSticky, applyDiscount, promoText }: DiscountBarProps) => {
  const [immersiveHeroEnabledDecison] = useDecision('beetroots_ofx_homepage_immersive_hero_test')
  const isImmersiveHeroEnabled = immersiveHeroEnabledDecison.variationKey === 't1'

  return (
    <DiscountBarContainer applyDiscount={applyDiscount} isSticky={isSticky} isHidden={isHidden}>
      {isImmersiveHeroEnabled ? (
        <Box width={['100%', CSSKeywords.Unset]} data-testid="immersiveHeroBannerText">
          <Box maxWidth={['260px', CSSKeywords.Unset]}>
            <Body1 color={Color.White} fontFamily={FontFamily.Bold}>
              {promoText}
            </Body1>
          </Box>
        </Box>
      ) : (
        <Text
          data-testid="discountBarBannerText"
          color={Color.White}
          size={4}
          fontFamily={FontFamily.Bold}
        >
          {promoText}
        </Text>
      )}
      <Space direction="horizontal" size={2} />
      <Icon name="arrow_right" style={{ color: Color.White }} />
    </DiscountBarContainer>
  )
}
