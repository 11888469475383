import React from 'react'

import { telephone } from 'config/company'

import css from './PhoneContent.css'

const PhoneContent = () => (
  <div>
    Call us on&nbsp;
    <a className={css.visibleOnlyOnMobile} href={`tel:${telephone.link}`}>{telephone.number}</a>
    <span className={css.hiddenOnMobile}>{telephone.number}</span>
    . We’re available Monday - Friday from 9am&nbsp;-&nbsp;8.45pm. Saturday & Sunday from 9am - 7.45pm.
  </div>
)

export {
  PhoneContent
}
