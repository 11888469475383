import React from 'react'

import {
  Modal,
  ModalClose,
  ModalHeader,
  ModalBody,
  ModalButton,
  Heading5,
  ButtonColorVariant,
  Text,
  ButtonSize,
  Display,
  JustifyContent,
  Box,
  Icon,
  Color,
  IconVariant,
  Space,
  FontFamily,
  BorderStyle,
} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'

import { Order, useSubscriptionModal } from '../../../../../hooks/useSubscriptionModal'

interface Props {
  didUpdateComplete: boolean
  didUpdateFail: boolean
  numPortions: string
  numRecipes: string
  openOrders: Order[]
  shouldShow?: boolean
}

export const recipesPerBoxConfirmationModal = 'recipes-per-box-confirmation-modal'

type NotificationIcon = 'error' | 'info'

const getNotificationStyleByState = (state: string) => {
  if (state === 'error') {
    return {
      bg: Color.Error_50,
      borderColour: Color.Error_900,
      iconVariant: IconVariant.Error,
      iconName: 'error' as NotificationIcon,
    }
  }

  return {
    bg: Color.Informative_50,
    borderColour: Color.Informative_200,
    iconVariant: IconVariant.Informative,
    iconName: 'info' as NotificationIcon,
  }
}

export const RecipesPerBoxConfirmationModal = ({
  didUpdateComplete,
  didUpdateFail,
  openOrders,
  numPortions,
  numRecipes,
  shouldShow = false,
}: Props) => {
  const { content, handleClose } = useSubscriptionModal({
    didUpdateFail,
    didUpdateComplete,
    numPortions,
    numRecipes,
    openOrders,
    modalName: recipesPerBoxConfirmationModal,
  })

  const handleClick = () => {
    handleClose()
    browserHistory.push('/my-deliveries')
  }

  const notificationStyle = getNotificationStyleByState(content.variant)

  return (
    <Modal preventScroll isOpen={shouldShow} name={recipesPerBoxConfirmationModal}>
      <ModalHeader>
        <Heading5>{content.title}</Heading5>
        <ModalClose onClose={handleClose} />
      </ModalHeader>
      <ModalBody>
        <Box
          display={Display.Flex}
          bg={notificationStyle.bg}
          borderColor={notificationStyle.borderColour}
          width="100%"
          paddingV={4}
          paddingH={3}
          borderStyle={BorderStyle.Solid}
          borderWidth={0.5}
          borderRadius={1.5}
          data-testid="recipesPerBoxModalInfoPanel"
        >
          <Box display={Display.Flex} width="100%">
            <Icon
              name={notificationStyle.iconName}
              size={5}
              variant={notificationStyle.iconVariant}
            />
            <Space direction="horizontal" size={2} />
            <Box>
              <Text size={1} fontFamily={FontFamily.Bold}>
                {content.notificationTitle}
              </Text>
              <Text size={1}>{content.notificationBody}</Text>
            </Box>
          </Box>
        </Box>
        <Space size={4} direction="vertical" />
        <Box justifyContent={JustifyContent.Center} width="100%">
          <ModalButton
            colorVariant={ButtonColorVariant.Primary}
            onClick={handleClick}
            size={ButtonSize.Medium}
            width="100%"
          >
            Review upcoming deliveries
          </ModalButton>
          <Space size={2} />
          <ModalButton
            colorVariant={ButtonColorVariant.Tertiary}
            onClick={handleClose}
            size={ButtonSize.Medium}
            width="100%"
          >
            Got it
          </ModalButton>
        </Box>
      </ModalBody>
    </Modal>
  )
}
