import React from 'react'

import {
  FlexDirection,
  Icon,
  Display,
  Color,
  Box,
  BorderStyle,
  Text,
  Link,
  FontWeight,
  LinkVariant,
  Space,
  IconVariant,
  TextAlign,
  JustifyContent,
} from '@gousto-internal/citrus-react'

import { unbounce as unbounceRoutes } from 'config/routes'

export const DeliveryStepWithoutCalendar = () => (
  <Box
    display={Display.Flex}
    justifyContent={JustifyContent.SpaceBetween}
    width="100%"
    paddingBottom={4}
  >
    <Box
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      display={Display.Flex}
      paddingV={2}
      paddingH={2}
      bg={Color.Informative_50}
      borderColor={Color.Informative_50}
    >
      <Box display={Display.Flex} flexGrow={0} flexShrink={0} flexBasis="1.5rem" paddingRight={2}>
        <Icon name="info" size={5} variant={IconVariant.Actionable} />
      </Box>
      <Box display={Display.Flex} flexGrow={1} flexDirection={FlexDirection.Column}>
        <Text size={2} color={Color.ColdGrey_800} fontWeight={FontWeight.Bold}>
          Due to extremely high demand, we don’t have any available slots right now.
        </Text>
        <Space size={1} direction="vertical" />
        <Text display={Display.Inline} textAlign={TextAlign.Center} size={1}>
          <Link href={unbounceRoutes.covid} target="_blank" size={1} variant={LinkVariant.Text}>
            Register your interest
          </Link>
          <Space size={1} direction="horizontal" />
          to be notified when more slots open up or check back soon!
        </Text>
      </Box>
    </Box>
  </Box>
)
