import { actionTypes } from 'actions/actionTypes'

export const trackNextBoxTrackingClick = orderId => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: 'TrackMyBox Clicked',
    orderId,
  }
})

export const trackOrderConflictModalSeen = orderId => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: 'order_conflict_modal_seen',
    orderId,
  }
})

export const trackOrderConflictReplaceRecipesClick = orderId => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: 'order_conflict_replace_recipes_clicked',
    orderId,
  }
})

export const trackOrderConflictKeepRecipesClick = orderId => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: 'order_conflict_keep_recipes_clicked',
    orderId,
  }
})
