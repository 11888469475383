import React from 'react'

import {
  Box,
  Text,
  Icon,
  Display,
  FontWeight,
  Space,
  FlexDirection,
  JustifyContent,
  AlignItems,
  IconVariant,
} from '@gousto-internal/citrus-react'

import { Benefit } from './data'

export const BenefitItem = ({
  iconName,
  ariaLabel,
  strongText,
  text,
}: Pick<Benefit, 'iconName' | 'ariaLabel' | 'strongText' | 'text'>) => (
  <Box
    role="img"
    aria-label={ariaLabel}
    data-testid="BenefitItem"
    display={Display.Flex}
    flexDirection={FlexDirection.Row}
    justifyContent={JustifyContent.FlexEnd}
    alignItems={AlignItems.Center}
  >
    <Icon name={iconName} size={6} variant={IconVariant.Inherit} />
    <Space direction="horizontal" size={2} />
    <>
      {/* FYI: can't use font size here, because according to design we need to use 12px/20px size/line-height and
      in citrus 12/20 is sizescale 0 in typography, bu 0 can't be applied here because of citrus bug. Issue already created
      https://github.com/Gousto/citrus/pull/356#pullrequestreview-1137074915  */}
      <Text
        display={Display.Inline}
        fontWeight={FontWeight.Bold}
        style={{
          fontSize: '12px',
          lineHeight: '20px',
        }}
      >
        {strongText}{' '}
      </Text>
      <Space direction="horizontal" size={1} />
      {/* FYI: can't use font size here, because according to design we need to use 12px/20px size/line-height and
      in citrus 12/20 is sizescale 0 in typography, bu 0 can't be applied here because of citrus bug. Issue already created
      https://github.com/Gousto/citrus/pull/356#pullrequestreview-1137074915  */}
      <Text
        display={Display.Inline}
        style={{
          fontSize: '12px',
          lineHeight: '20px',
        }}
      >
        {text}
      </Text>
    </>
  </Box>
)
