import { collectionsTransformer } from 'apis/transformers/collections'
import { recipesTransformer } from 'apis/transformers/recipes'

import { actionTypes } from './actionTypes'

export function menuCollectionsReceive(collections) {
  return {
    type: actionTypes.MENU_COLLECTIONS_RECEIVE,
    collections,
  }
}

export function menuReceiveMenu(recipes) {
  return {
    type: actionTypes.RECIPES_RECEIVE,
    recipes,
  }
}

const basketCurrentMenuIdChange = ({ id, attributes }) => ({
  type: actionTypes.BASKET_CURRENT_MENU_ID_CHANGE,
  menuId: id,
  menuUuid: attributes.uuid,
})

const loadMenuCollectionsWithMenuService = (dispatch, getState) => {
  const state = getState()
  const menuServiceData = state.menuService

  if (!menuServiceData || !menuServiceData.data || !menuServiceData.data.length) {
    return
  }

  const activeMenu = menuServiceData.data[0]
  const transformedCollections = collectionsTransformer(activeMenu, menuServiceData)
  const transformedRecipes = recipesTransformer(activeMenu, menuServiceData)

  dispatch(menuCollectionsReceive(transformedCollections))
  dispatch(menuReceiveMenu(transformedRecipes))
  dispatch(basketCurrentMenuIdChange(activeMenu))
}

export { loadMenuCollectionsWithMenuService }
