import { actionTypes } from 'actions/actionTypes'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const trackCheckoutButtonPressed: CheckoutActionCreator = (type, property) => {
  const tracking = {
    actionType: type,
    seCategory: 'Checkout',
    ...property,
  }

  return {
    type: actionTypes.TRACKING,
    trackingData: tracking,
    gtmEvent: tracking,
  }
}
