import React, { useState } from 'react'
import styled from '@emotion/styled'
import Immutable from 'immutable'
import {
  Box,
  Button,
  ButtonColorVariant,
  Col,
  Color,
  Display,
  FlexDirection,
  Grid,
  JustifyContent,
  Space,
  useTheme,
  Theme,
} from '@gousto-internal/citrus-react'
import { RecipePreferenceTile } from './components/RecipePreferenceTile/RecipePreferenceTile'
import { HeaderSection } from './components/HeaderSection/HeaderSection'
import { CTAs } from './config'
import { Recipe } from './types/recipe'
import { trackCTAClick, TrackingActions } from './tracking'

export type RecipePreferencesProps = {
  defaultRecipePreferences?: Immutable.List<string>
  recipes: Recipe[]
  onClickSkip: (recipePreferences: Immutable.List<string>) => void
  onClickSeeMenu: (recipePreferences: Immutable.List<string>) => void
}

export function RecipePreferences({
  defaultRecipePreferences = Immutable.List(),
  recipes,
  onClickSkip,
  onClickSeeMenu,
}: RecipePreferencesProps) {
  const { seeMenuCTA, skipCTA } = CTAs
  const { theme } = useTheme()

  const [recipePreferences, setRecipePreferences] =
    useState<Immutable.List<string>>(defaultRecipePreferences)

  const handleTileSelectionChange = (pref: string) => {
    const updatedRecipePreferences = recipePreferences.includes(pref)
      ? (recipePreferences.filter((item) => item !== pref) as Immutable.List<string>)
      : recipePreferences.push(pref)
    setRecipePreferences(updatedRecipePreferences)
  }

  const handleSkipCTAClick = () => {
    trackCTAClick(TrackingActions.CLICK_SKIP)
    onClickSkip(recipePreferences)
  }

  const handleSeeMenuCTAClick = () => {
    trackCTAClick(TrackingActions.CLICK_SEE_YOUR_MENU, recipePreferences.toJS())
    onClickSeeMenu(recipePreferences)
  }

  return (
    <Box>
      <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
        <HeaderSection />
      </Box>
      <Space size={[4, 6]} />
      <Grid maxWidth={['350px', '528px', '528px', '724px']} columnGap={2} rowGap={2}>
        {recipes.map((recipe) => (
          <Col key={recipe.id} size={[6, 4, 4, 3]}>
            <RecipePreferenceTile
              recipe={recipe}
              onSelectionChange={handleTileSelectionChange}
              defaultIsSelected={recipePreferences.includes(recipe.uuid)}
            />
          </Col>
        ))}
      </Grid>
      <Space size={6} />
      <FloatingContainerWithBackgroundStyled citrusTheme={theme}>
        <Box
          display={Display.Flex}
          flexDirection={[FlexDirection.Column, 'row-reverse' as FlexDirection]}
          justifyContent={JustifyContent.Center}
          gap={2}
        >
          <Button
            width={['100%', '265px', '358px']}
            height="48px"
            data-testid="recipe-preferences-see-menu-cta"
            disabled={recipePreferences.size === 0}
            onClick={handleSeeMenuCTAClick}
            aria-label={seeMenuCTA}
            aria-disabled={recipePreferences.size === 0}
          >
            {seeMenuCTA}
          </Button>
          <Button
            colorVariant={ButtonColorVariant.Secondary}
            width={['100%', '265px', '358px']}
            data-testid="recipe-preferences-skip-cta"
            height="48px"
            onClick={handleSkipCTAClick}
            aria-label={skipCTA}
            aria-disabled={false}
          >
            {skipCTA}
          </Button>
        </Box>
      </FloatingContainerWithBackgroundStyled>
    </Box>
  )
}

const FloatingContainerWithBackgroundStyled = styled.div<{ citrusTheme: Theme }>((props) => ({
  background: props.citrusTheme.color(Color.White),
  borderTop: `1px solid ${props.citrusTheme.color(Color.NeutralGrey_200)}`,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  paddingBlock: props.citrusTheme.spacing(4),
  paddingInline: props.citrusTheme.spacing(3),

  [`@media (min-width: ${props.citrusTheme.tokens.breakpoints[0]})`]: {
    background: props.citrusTheme.color(Color.Transparent),
    borderTopStyle: 'none',
    position: 'static',
    padding: 0,
  },
}))
