import { withCitrusTheme, Text } from '@gousto-internal/citrus-react'

export const DietaryIcons = withCitrusTheme(Text, (theme) => ({
  zIndex: 1,
  lineHeight: '14px !important',
  width: '28px',
  textAlign: 'center',
  color: theme.tokens.colors.White,
  position: 'relative',
  marginRight: theme.spacing(1),
  transform: 'translateY(8px)',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translateY(-8px)',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    backgroundColor: theme.tokens.colors.ColdGrey_700,
    zIndex: -1,
  },
}))
