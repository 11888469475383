import { createSelector } from '@reduxjs/toolkit'

import { PartialStore } from './types'

const getBasketRecipes = ({ basket }: PartialStore) => basket.get('recipes')

const getRecipesFromStore = ({ recipes }: PartialStore) => ({
  recipes,
})

const getDeliveryDaysFromStore = ({ boxSummaryDeliveryDays }: PartialStore) =>
  boxSummaryDeliveryDays

export const getBasketRecipesIDs = createSelector(getBasketRecipes, (recipesIDs) =>
  recipesIDs.keySeq().toArray(),
)

export const getStoreRecipes = createSelector(getRecipesFromStore, ({ recipes }) => recipes)

export const getDeliveryDays = createSelector(
  getDeliveryDaysFromStore,
  (deliveryDays) => deliveryDays,
)
