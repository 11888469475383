import { Heading6, Modal, ModalBody, ModalHeader, useModal } from '@gousto-internal/citrus-react'
import { useSWRConfig } from 'swr'
import React, { useCallback } from 'react'
import { updateShippingAddress } from '@library/api-user'
import { AddressForm } from '../../AddressForm'

import type { ShippingAddress } from '@library/api-user'
import { ApiKeys } from '../../../../enums'

type Props = {
  address?: ShippingAddress
  isOpen?: boolean
}

export const EditAddressModal = ({ address, isOpen = false }: Props) => {
  const { mutate } = useSWRConfig()
  const { closeCurrentModal } = useModal()
  const handleSubmit = useCallback(
    async (values: Record<string, unknown>) => {
      if (!address) {
        return
      }

      await updateShippingAddress(values)
      await mutate(ApiKeys.UserCurrentShippingAddress)

      closeCurrentModal()
    },
    [address, closeCurrentModal, mutate],
  )
  const handleClose = useCallback(() => {
    closeCurrentModal()
  }, [closeCurrentModal])

  return (
    <Modal name="EditAddressModal" preventScroll isOpen={isOpen}>
      {address && (
        <>
          <ModalHeader>
            <Heading6>Edit address</Heading6>
          </ModalHeader>
          <ModalBody>
            <AddressForm address={address} onSubmit={handleSubmit} onCancel={handleClose} />
          </ModalBody>
        </>
      )}
    </Modal>
  )
}
