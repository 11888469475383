import { Box, Body2, Divider, Space, Button } from '@gousto-internal/citrus-react'
import * as React from 'react'
import { ForcedDecision } from '../../../types'
import { DetailColumn } from './DetailColumn'
import { DetailGrid } from './DetailGrid'

export function Details({
  decisions,
  resetCookieAndClose,
}: {
  decisions: ForcedDecision[]
  resetCookieAndClose: () => void
}) {
  return (
    <Box width="100%">
      <DetailGrid>
        <DetailColumn heading="Flag Key">
          {decisions.map(({ flagKey }) => (
            <Body2 key={flagKey}>{flagKey}</Body2>
          ))}
        </DetailColumn>

        <DetailColumn heading="Variation Key">
          {decisions.map(({ flagKey, variationKey }) => (
            <Body2 key={`${flagKey}-${variationKey}`}>{variationKey}</Body2>
          ))}
        </DetailColumn>
        <Divider />
      </DetailGrid>

      <Space size={3} />

      <Button width="100%" onClick={resetCookieAndClose}>
        Clear forced decisions
      </Button>
    </Box>
  )
}
