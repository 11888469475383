import React from 'react'

import {
  Body1,
  Box,
  Button,
  ButtonSize,
  TextArea,
  Spinner,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

interface Props {
  submitting: boolean
  submitted: boolean
  comment: string
  setComment: (comment: string) => void
  onSubmit: () => void
  title: string
  placeholder?: string
}

const Title = withCitrusTheme(Body1, (theme) => ({
  marginBottom: theme.spacing(2),
}))
const Comment = withCitrusTheme(TextArea, () => ({
  height: '8.25rem',
  resize: 'none',
}))
const SubmitButton = withCitrusTheme(Button, (theme) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  height: theme.spacing(14),
}))

export const SubmitFeedback: React.FC<Props> = ({
  submitting,
  submitted,
  comment,
  setComment,
  onSubmit,
  title,
  placeholder = '',
}) => {
  return (
    <Box>
      <Title>{title}</Title>
      <Comment
        placeholder={placeholder}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
      <SubmitButton
        size={ButtonSize.Large}
        disabled={submitted || submitting}
        onClick={onSubmit}
        icon="cool"
      >
        {submitting ? <Spinner /> : 'Submit'}
      </SubmitButton>
    </Box>
  )
}
