import Immutable from 'immutable'
import { useSelector } from 'react-redux'

import { getBasketRecipesIDs, getStoreRecipes } from './selectors'
import { PartialRecipeData, RecipeImageURLs } from './types'

export function useGetBasketRecipes() {
  const basketRecipesIDs = useSelector(getBasketRecipesIDs)
  const recipes = useSelector(getStoreRecipes)

  const recipeData: PartialRecipeData[] = basketRecipesIDs.map((recipeId) => {
    const URLs: RecipeImageURLs = recipes.getIn(
      [recipeId, 'media', 'images', 0, 'urls'],
      Immutable.List([]),
    )

    const url: string | undefined = URLs.getIn([1, 'src'], undefined)

    const title: string = recipes.getIn([recipeId, 'title'], '')

    return { url, title }
  })

  return recipeData
}
