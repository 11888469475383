import React from 'react'
import { PROBLEMATIC_RENDERING_ATTRIBUTE } from './constants'

type FlagProblematicRenderingProps = { label: string }

export const FlagProblematicRendering = ({ label }: FlagProblematicRenderingProps) => {
  const opts = {
    [PROBLEMATIC_RENDERING_ATTRIBUTE]: Date.now(),
    ['data-testing']: label,
  }
  return <div {...opts} />
}
