export const CTAs = {
  ctaBoldText: 'No lock in: ',
  ctaText: 'pause or cancel for free anytime',
}

export const HeroSlides = {
  titlePart1: 'The',
  titlePart2: 'recipe box with the greatest choice',
  subTitlePart1: 'Over 250 recipes a month',
  subTitlePart2: 'from just £2.99 a meal*',
}

export const VeganSlides = {
  titlePart: 'Veggie & vegan',
  subTitlePart1: 'Over 250 recipes',
  subTitlePart2: 'every month including:',
}
