import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import {
  checkoutCardAuthorisation,
  checkoutSignupPayment,
  clearGoustoRef,
  fetchGoustoRef,
  handleCheckoutError,
  resetDuplicateCheck,
  trackSignupPageChange,
  userSubscribe,
} from 'routes/Checkout/checkoutActions'
import { getProvider, isCardPayment } from 'routes/Checkout/checkoutPaymentSelectors'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { Pricing } from 'routes/Menu/domains/pricing'

import { CardProvider } from '../models/CheckoutTypes'

const errorCodes = {
  duplicateDetails: '409-duplicate-details',
  promoCodeHasBeenUsed: '409-offer-has-been-used',
  challengeFailed: '3ds-challenge-failed',
  signupLoginFailed: 'signupLoginFailed',
}

export const checkoutSignup: CheckoutActionCreator =
  ({ pricing }: { pricing: Pricing }) =>
  async (dispatch, getState) => {
    dispatch(trackSignupPageChange('Submit'))

    dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP, null))
    dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, true))

    try {
      await dispatch(fetchGoustoRef())
      dispatch(resetDuplicateCheck({ pricing }))
      await dispatch(userSubscribe({ pricing }))
    } catch (err) {
      await dispatch(handleCheckoutError(err, 'checkoutSignup'))
      if ((err as any).code !== errorCodes.duplicateDetails) {
        dispatch(clearGoustoRef())
      }
      dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, false))

      return
    }

    if (isCardPayment(getState()) && getProvider(getState()) === CardProvider.CHECKOUT) {
      await dispatch(checkoutCardAuthorisation({ pricing }))
    } else {
      const sourceId = null
      await dispatch(checkoutSignupPayment(sourceId, { pricing }))
    }
  }
