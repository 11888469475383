import React, { ChangeEvent } from 'react'

import styled from '@emotion/styled'
import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  Space,
  RadioGroup,
  Heading5,
  Body2,
  Bold,
  Icon,
  IconVariant,
  Color,
  Col,
  Grid,
  BorderStyle,
  Text,
} from '@gousto-internal/citrus-react'
import Svg from 'Svg'

import {
  portionSizesConfig,
  PortionSizes,
  PortionSizesConfig,
  PortionSizeDescriptor,
} from 'config/portionSizes'

const StyledRadioContainer = styled.div`
  label {
    align-items: flex-start;
  }
`

const SvgStyled = styled(Svg)({
  width: '44px',
  height: '26px',
  display: 'block',
})

type PortionSizeSelectorProps = {
  portionSize: PortionSizes
  availablePortionSizes: PortionSizes[]
  config?: PortionSizesConfig
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const recipeCardInfoTip = {
  title: 'Heads up:',
  body: `You'll find measurements and instructions for this box size on your Gousto app, not on your recipe cards.`,
}

const RecipeCardInfoTip = () => (
  <>
    <Box
      data-testid="recipeCardInfoTip"
      bg={Color.Informative_50}
      display={Display.Flex}
      paddingH={4}
      paddingV={4}
      alignItems={AlignItems.FlexStart}
      borderColor={Color.Informative_50}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      gap={2}
    >
      <Icon name="info" variant={IconVariant.Informative} />
      <Box>
        <Text size={2}>
          <Bold>{recipeCardInfoTip.title}</Bold>
          &nbsp;{recipeCardInfoTip.body}
        </Text>
      </Box>
    </Box>
  </>
)

export const PortionSizeSelector = ({
  portionSize,
  availablePortionSizes,
  onChange,
  config = portionSizesConfig,
}: PortionSizeSelectorProps) => {
  const getRadioLabel = ({
    heading,
    suitableFor,
    recipeInfoStrongText,
    value,
  }: PortionSizeDescriptor) => {
    let packagingInfo = ' recipes per box.'
    let numberOfRecipes = recipeInfoStrongText

    if (value === 4) {
      packagingInfo = ' recipes per order.'
      numberOfRecipes = '2 to 5'
    }

    return (
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        paddingH={3}
        paddingRight={0}
        data-testid={`${heading}`}
        data-testing="signupPortionSizeView"
      >
        <Grid alignItems={AlignItems.Center}>
          <Col size={11}>
            <Heading5>{heading}</Heading5>
          </Col>
          <Col size={1}>
            <Box display={Display.Flex}>
              <SvgStyled fileName={`${value}-person`} />
            </Box>
          </Col>
        </Grid>
        <Box display={value === portionSize ? Display.Block : Display.None}>
          <Space size={1} direction="vertical" />
          <Body2 color={Color.ColdGrey_800}>
            {`Choose `}
            <Bold>{numberOfRecipes}</Bold>
            {packagingInfo}
          </Body2>
          <Space size={1} direction="vertical" />
          <Box>
            <Body2 color={Color.ColdGrey_800}>Suitable for:</Body2>
            <Space size={1} direction="vertical" />
            {suitableFor.map((label: string) => (
              <Box
                key={`radio-suitable-for-${label}`}
                display={Display.Flex}
                alignItems={AlignItems.Center}
              >
                <Icon name="tick" variant={IconVariant.Confirmation} size={4} />
                <Space direction="horizontal" size={2} />
                <Body2 color={Color.ColdGrey_800}>{label}</Body2>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    )
  }

  const getRadioOptions = () =>
    availablePortionSizes.map((portion) => ({
      name: 'portionSize',
      value: config[portion].value,
      label: () => getRadioLabel(config[portion]),
    }))

  return (
    <>
      <StyledRadioContainer>
        <RadioGroup
          value={portionSize}
          outline
          options={getRadioOptions()}
          onChange={(e) => onChange(e)}
        />
      </StyledRadioContainer>
      <Space direction="vertical" size={2} />
      {[1, 5].includes(portionSize) && <RecipeCardInfoTip />}
    </>
  )
}
