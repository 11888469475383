import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  Heading2,
  JustifyContent,
  Space,
  TextAlign,
} from '@gousto-internal/citrus-react'

import { LovedByBrandLogoContainer } from './components/LovedByBrandLogoContainer'

export const LovedBy = () => {
  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      justifyContent={[JustifyContent.Center, JustifyContent.SpaceEvenly]}
      bg={Color.ColdGrey_800}
    >
      <Space size={5} direction="vertical" />
      <Heading2 textAlign={TextAlign.Center} color={Color.White} size={[4, null, 6]}>
        Tested and loved by
      </Heading2>
      <Space size={5} direction="vertical" />
      <LovedByBrandLogoContainer />
      <Space size={8} direction="vertical" />
    </Box>
  )
}
