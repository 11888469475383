import { trackUTMAndPromoCode } from 'actions/tracking'

const checkoutClickHideOrderSummary = 'checkout_click_hide_order_summary'
const checkoutClickShowOrderSummary = 'checkout_click_show_order_summary'
const clickRecipeImageOrderSummary = 'click_recipe_image_order_summary'
const clickEditOrderLink = 'click_edit_order_link'

export const trackToggleExpandOrderSummary = (toExpand: boolean) =>
  toExpand
    ? trackUTMAndPromoCode(checkoutClickShowOrderSummary)
    : trackUTMAndPromoCode(checkoutClickHideOrderSummary)

export const trackClickRecipeImageOrderSummary = (expanded: boolean, recipeId: string) =>
  trackUTMAndPromoCode(clickRecipeImageOrderSummary, { expanded, recipeId })

export const trackClickEditOrderLink = (recipeIds: string[]) =>
  trackUTMAndPromoCode(clickEditOrderLink, { recipeIds })
