import React from 'react'

import css from './ContentMask.css'

type ContentMaskProps = {
  children: React.ReactNode
  className?: string
}

export const ContentMask = ({ className = '', children }: ContentMaskProps) => (
  <div className={className}>
    <div className={css.container}>
      <div className={css.content}>{children}</div>
    </div>
  </div>
)
