import React, { useCallback, useState } from 'react'

import styled from '@emotion/styled'
import {
  Body2,
  Bold,
  Box,
  Color,
  Display,
  FlexDirection,
  Link,
  RadioGroup,
  Space,
} from '@gousto-internal/citrus-react'

import { usePauseOSRData } from 'routes/Account/Subscription/hooks/usePauseOSRData'

const StyledRadioContainer = styled.div`
  label {
    align-items: flex-start;
  }
`

interface AlternateActionTrackingData {
  pause_reason: string
  recovery_action: string
}

interface RadioLabelProps {
  label: string
  isSelected: boolean
  alternateOption?: {
    hintText: string
    linkText: string
    handler: (value: string, tracking: AlternateActionTrackingData) => void
  }
}

const RadioLabel = ({ label, isSelected, alternateOption }: RadioLabelProps) => {
  const handler = useCallback(() => {
    if (alternateOption) {
      const tracking = {
        pause_reason: label,
        recovery_action: alternateOption?.linkText,
      }

      alternateOption.handler(label, tracking)
    }
  }, [label, alternateOption])

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
      {!isSelected && label}
      {isSelected && <Bold color={Color.Secondary_400}>{label}</Bold>}
      {isSelected && !!alternateOption && (
        <>
          <Space size={1} direction="vertical" />
          <Body2 color={Color.ColdGrey_600}>{alternateOption.hintText}</Body2>
          <Space size={1} direction="vertical" />
          <Body2>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link role="button" onClick={handler}>
              {alternateOption.linkText}
            </Link>
          </Body2>
        </>
      )}
    </Box>
  )
}

interface PauseRadioGroupProps {
  onChangeDeliveryFrequencyClick: (data: { tracking: AlternateActionTrackingData }) => void
  onSkipDeliveriesClick: (data: { tracking: AlternateActionTrackingData }) => void
  onOpenLiveChatClick: (data: { tracking: AlternateActionTrackingData }) => void
  onReasonChange: (selected: string) => void
}

const PauseRadioGroup = ({
  onChangeDeliveryFrequencyClick,
  onSkipDeliveriesClick,
  onOpenLiveChatClick,
  onReasonChange,
}: PauseRadioGroupProps) => {
  const reasons = usePauseOSRData()
  const [selected, setSelected] = useState('')

  const isEqualTo = useCallback(
    (value: string | number, equalsTo: string | number) => value === equalsTo,
    [],
  )

  const handleAlternateOption = useCallback(
    (value: string, tracking: { pause_reason: string; recovery_action: string }) => {
      if (isEqualTo(value, "I can't afford a weekly box")) {
        onChangeDeliveryFrequencyClick({ tracking })
      }

      if (isEqualTo(value, "I'm going away")) {
        onSkipDeliveriesClick({ tracking })
      }

      if (isEqualTo(value, 'I had a problem')) {
        onOpenLiveChatClick({ tracking })
      }
    },
    [isEqualTo, onChangeDeliveryFrequencyClick, onSkipDeliveriesClick, onOpenLiveChatClick],
  )

  const buildOptions = useCallback(
    () =>
      reasons.map(({ label, value, alternateOption }) => {
        const isSelected = isEqualTo(selected, value)
        const alternateOptionProp = alternateOption
          ? {
              hintText: alternateOption.hintText,
              linkText: alternateOption.linkText,
              handler: handleAlternateOption,
            }
          : undefined

        return {
          name: 'subscriptionPauseReason',
          label: (
            <RadioLabel
              label={label}
              isSelected={isSelected}
              alternateOption={alternateOptionProp}
            />
          ),
          value,
        }
      }),
    [reasons, selected, isEqualTo, handleAlternateOption],
  )

  const handleChange = useCallback(
    (event) => {
      setSelected(event.target.value)
      onReasonChange(event.target.value)
    },
    [onReasonChange],
  )

  return (
    <StyledRadioContainer>
      <RadioGroup outline value={selected} onChange={handleChange} options={buildOptions()} />
    </StyledRadioContainer>
  )
}

export { PauseRadioGroup }
