import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormFieldStatus, Input } from '@gousto-internal/citrus-react'

const AddressTownInput = () => (
  <Field name="town">
    {({ field, meta }: FieldProps) => (
      <Input
        id="town"
        type="text"
        placeholder="Town"
        autoComplete="address-level2"
        status={meta.error ? FormFieldStatus.Error : undefined}
        maxLength={32}
        {...field}
      />
    )}
  </Field>
)

export { AddressTownInput }
