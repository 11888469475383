import React from 'react'

import { Text, FontFamily, FontWeight, Box } from '@gousto-internal/citrus-react'

export function Title({ title }: { title: string }) {
  return (
    <Box maxWidth={'80%'}>
      <Text size={3} fontFamily={FontFamily.Book} fontWeight={FontWeight.SemiBold}>
        {title}
      </Text>
    </Box>
  )
}
