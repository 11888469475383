import React from 'react'
import { Radio } from '@gousto-internal/citrus-react'
import type { ShippingAddress } from '@library/api-user'

function AddressRadioButton({
  address,
  isChecked,
  onChange,
  children,
}: {
  address: ShippingAddress
  isChecked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
}) {
  return (
    <Radio
      id={address.id}
      name={`radio-${address.id}`}
      checked={isChecked}
      onChange={onChange}
      outline
    >
      {() => children}
    </Radio>
  )
}

export { AddressRadioButton }
