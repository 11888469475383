/**
 * GetFeedback Digital: Virtual pageviews in Single Page Applications
 * https://support.usabilla.com/hc/en-us/articles/115004155665-Virtual-pageviews-in-Single-Page-Applications-SPA-
 */
import { canUseWindow } from 'utils/browserEnvironment'

export const executeVirtualView = () => {
  if (canUseWindow() && window.usabilla_live) {
    window.usabilla_live('virtualPageView')
  }
}
