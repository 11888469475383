import React, { MouseEvent, useCallback } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { HotjarTrigger } from 'HotjarTrigger'
import { Form, FormikProvider } from 'formik'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { CheckoutButton } from 'routes/Checkout/Components/CheckoutButton'
import { DeliveryEducationBanner } from 'routes/Checkout/Components/Delivery/DeliveryEducationBanner'
import { DeliveryInfoCard } from 'routes/Checkout/Components/DeliveryDetailsForm/DeliveryInfoCard'
import {
  FirstNameInput,
  LastNameInput,
  PhoneInput,
} from 'routes/Checkout/Components/DeliveryDetailsForm/Fields'
import { useDeliveryDetailsForm } from 'routes/Checkout/Components/DeliveryDetailsForm/useDeliveryDetailsForm'
import { PostcodeAddressFields } from 'routes/Checkout/Components/PostcodeAddressFields'
import { SectionHeader } from 'routes/Checkout/Components/SectionHeader'
import {
  setCurrentPaymentMethod,
  trackCheckoutButtonPressed,
} from 'routes/Checkout/checkoutActions'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'
import { getIsMobile } from 'routes/Checkout/checkoutSelectors'
import { useIsApplePayEnabled } from 'routes/Checkout/hooks/useIsApplePayEnabled'
import { usePricing } from 'routes/Menu/domains/pricing'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import { ExpressCheckoutPayWithApplePay } from '../ExpressCheckoutPayWithApplePay'
import { useBraintreeApplePayExpressCheckout } from './ApplePay/useBraintreeApplePayExpressCheckout'

type DeliveryDetailsFormProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
  braintreeClientInstance: braintree.Client | null
}

const ExpressCheckoutFallBack = () => (
  <>
    <Box
      bg={Color.Warning_50}
      display={Display.Flex}
      paddingH={4}
      paddingV={4}
      alignItems={AlignItems.FlexStart}
      borderColor={Color.Warning_100}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      gap={2}
    >
      <Icon variant={IconVariant.Error} name="report_problem" />
      <Box>
        <Text fontFamily={FontFamily.Bold} size={2}>
          There’s an issue with connecting to Apple Pay
        </Text>
        <Text size={2}>
          Please continue with checkout below and choose an alternative payment method on the next
          step
        </Text>
      </Box>
    </Box>
    <Space size={2} />
  </>
)

export const DeliveryDetailsForm = ({
  onStepChange,
  braintreeClientInstance,
}: DeliveryDetailsFormProps) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)
  const { pricing } = usePricing()

  const { handleSubmit, canMakePaymentsWithActiveCard } = useBraintreeApplePayExpressCheckout({
    braintreeClientInstance,
  })

  const errorBoundaryLogger = () => {
    datadogLogs.logger.error('ExpressCheckout: Unexpected error thrown by Error Boundary')
  }

  const isApplePayEnabled = useIsApplePayEnabled()

  const formik = useDeliveryDetailsForm(onStepChange)

  const onCheckoutButtonClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      dispatch({
        type: actionTypes.CHECKOUT_CONTINUE_TO_PAYMENT,
        trackingData: { totalPrice: pricing?.total },
      }) // for data layer tracking

      dispatch(setCurrentPaymentMethod(PaymentMethod.Card))

      if (isMobile) {
        dispatch(
          trackCheckoutButtonPressed('DeliveryAddress Confirmed', {
            succeeded: formik.isValid,
            missing_field: formik.isValid
              ? null
              : Object.keys(formik.errors).filter((key) => Boolean((formik.errors as any)[key])),
          }),
        )
      }
      formik.submitForm()
    },
    [dispatch, formik, isMobile, pricing?.total],
  )

  return (
    <Box
      data-testid="checkoutDeliverySection"
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      bg={Color.White}
      paddingH={[3, 6]}
      paddingV={[6, 8]}
    >
      <ErrorBoundary onError={errorBoundaryLogger} FallbackComponent={ExpressCheckoutFallBack}>
        {isApplePayEnabled && canMakePaymentsWithActiveCard && (
          <ExpressCheckoutPayWithApplePay initializeApplePay={handleSubmit} />
        )}
      </ErrorBoundary>
      <SectionHeader title="Delivery details" />
      <DeliveryInfoCard />

      <FormikProvider value={formik}>
        <Form autoComplete="off">
          <Box display={Display.Flex}>
            <Box width="100%">
              <FirstNameInput />
            </Box>

            <Space size={4} direction="horizontal" />

            <Box width="100%">
              <LastNameInput />
            </Box>
          </Box>

          <Space size={6} direction="vertical" />

          <PostcodeAddressFields />

          <Box paddingBottom={[4, 6]}>
            <PhoneInput />
          </Box>

          <DeliveryEducationBanner />

          <CheckoutButton isDisabled={!formik.isValid} onClick={onCheckoutButtonClick}>
            Continue to Payment
          </CheckoutButton>
        </Form>
      </FormikProvider>

      <HotjarTrigger name="pbc_delivery_and_payment" shouldInvoke={false} />
    </Box>
  )
}
