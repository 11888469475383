import React, { useEffect } from 'react'

import { Display, Visible, useModal } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { redirect } from 'actions/redirect'
import {
  checkoutUrgencyExpiredModalDismissed,
  checkoutUrgencyExpiredModalDisplayed,
  checkoutUrgencyExpiredModalPrimaryButtonClicked,
  checkoutUrgencyInitialModalDismissed,
  checkoutUrgencyInitialModalDisplayed,
  checkoutUrgencyInitialModalPrimaryButtonClicked,
} from 'actions/trackingKeys'
import routes from 'config/routes'
import {
  CheckoutUrgencyModal,
  checkoutUrgencyModalName,
} from 'routes/Checkout/Components/CheckoutUrgency/CheckoutUrgencyModal'
import {
  UrgencyBannerVariant,
  UrgencyBannerControl,
} from 'routes/Checkout/Components/CheckoutUrgency/UrgencyBanner'
import {
  useIsCheckoutSessionAlmostExpired,
  useIsCheckoutSessionExpired,
} from 'routes/Checkout/Components/CheckoutUrgency/checkoutUrgencyUtils'
import { setCheckoutStartedTime, trackCheckoutUrgencyAction } from 'routes/Checkout/checkoutActions'
import { useIsCheckoutBoxTangibilityV3Enabled } from 'routes/Checkout/hooks'

/**
 * Logic wrapper for check out urgency banner and modals.
 * Its task is to render urgency banner and display modals when needed.
 */
export const CheckoutUrgency = () => {
  const dispatch = useDispatch()
  const isCheckoutSessionAlmostExpired = useIsCheckoutSessionAlmostExpired()
  const isCheckoutSessionExpired = useIsCheckoutSessionExpired()
  const { openModal, closeCurrentModal } = useModal()

  /**
   * On 1st render save time when check out was started.
   */
  useEffect(() => {
    dispatch(setCheckoutStartedTime(new Date().getTime()))
  }, [dispatch])

  /**
   * On modal open tracking action should be dispatched
   */
  useEffect(() => {
    if (isCheckoutSessionAlmostExpired) {
      dispatch(trackCheckoutUrgencyAction(checkoutUrgencyInitialModalDisplayed))
      openModal(checkoutUrgencyModalName)
    } else if (isCheckoutSessionExpired) {
      dispatch(trackCheckoutUrgencyAction(checkoutUrgencyExpiredModalDisplayed))
      openModal(checkoutUrgencyModalName)
    }
  }, [isCheckoutSessionAlmostExpired, isCheckoutSessionExpired, dispatch, openModal])

  useEffect(
    () => () => {
      dispatch(setCheckoutStartedTime(null))
      closeCurrentModal()
    },

    [closeCurrentModal, dispatch],
  )

  /**
   * On modal closing tracking action should be dispatched;
   * If session is expired, user should be redirected back to menu.
   */
  const onDismissModal = (): void => {
    if (isCheckoutSessionExpired) {
      dispatch(trackCheckoutUrgencyAction(checkoutUrgencyExpiredModalDismissed))
      dispatch(redirect(routes.client.menu))
    } else {
      dispatch(trackCheckoutUrgencyAction(checkoutUrgencyInitialModalDismissed))
      dispatch(setCheckoutStartedTime(new Date().getTime()))
    }
    closeCurrentModal()
  }

  const onConfirmModal = (): void => {
    if (isCheckoutSessionExpired) {
      dispatch(trackCheckoutUrgencyAction(checkoutUrgencyExpiredModalPrimaryButtonClicked))
      dispatch(redirect(routes.client.menu))
    } else {
      dispatch(trackCheckoutUrgencyAction(checkoutUrgencyInitialModalPrimaryButtonClicked))
      dispatch(setCheckoutStartedTime(new Date().getTime()))
    }
    closeCurrentModal()
  }

  const isCheckoutBoxTangibilityV3Enabled = useIsCheckoutBoxTangibilityV3Enabled()

  return (
    <>
      {isCheckoutBoxTangibilityV3Enabled ? (
        <>
          <Visible display={[Display.Block, Display.None]}>
            <UrgencyBannerVariant />
          </Visible>
          <Visible display={[Display.None, Display.Block]}>
            <UrgencyBannerControl />
          </Visible>
        </>
      ) : (
        <UrgencyBannerControl />
      )}
      <CheckoutUrgencyModal
        onConfirmModal={onConfirmModal}
        onDismissModal={onDismissModal}
        isExpired={isCheckoutSessionExpired}
      />
    </>
  )
}
