import React from 'react'

import { Modal } from '@gousto-internal/citrus-react'

import { MenuRecipe } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { DetailContent } from './DetailContent'

import css from './Detail.css'

type DetailProps = {
  id: string
  title: string
  showOverlay: boolean
  error: boolean
  loading: boolean
  recipe: MenuRecipe
  reload: () => void
  onClose: () => void
  position?: string
}

export const Detail = ({
  id,
  title,
  showOverlay,
  error,
  loading,
  recipe,
  reload,
  onClose,
  position = '0',
}: DetailProps) => (
  <Modal
    name="recipe-detail-modal"
    isOpen={showOverlay}
    onRequestClose={onClose}
    overlayClassName={css.overlay}
  >
    <DetailContent
      id={id}
      title={title}
      error={error}
      loading={loading}
      recipe={recipe}
      reload={reload}
      onClose={onClose}
      position={position}
    />
  </Modal>
)
