import { Color } from '@gousto-internal/citrus-react'
import { Recipe } from '../model/recipe'

export const getOverlayColor = (recipe: Recipe) => {
  const { isFineDineIn } = recipe

  if (isFineDineIn) return Color.ColdGrey_800

  return Color.White
}
