import useSWR from 'swr'
import logger from 'utils/logger'
import { useEffect } from 'react'
import { fetchSubscriptionOptions } from '@library/api-subscription'
import { actionTypes } from '../context/reducers'
import { getCurrentUserId } from '../context/selectors/currentUser'

const hasDataInResponse = (response) => {
  if (response && response.data) {
    return true
  }

  logger.error(`No subscription option available: ${JSON.stringify(response)}`)

  return false
}

const hasValidData = (data) => {
  if (data.boxSize.length && data.boxType.length && data.interval.length) {
    return true
  }

  logger.error(`Malformed subscription options data: ${JSON.stringify(data)}`)

  return false
}

export const useSubscriptionOptionsData = (dispatch, state) => {
  const userId = getCurrentUserId(state)
  const { data: subscriptionOptionsResponse, error: subscriptionOptionsError } = useSWR(
    userId ? [{ userId: String(userId) }] : null,
    fetchSubscriptionOptions,
    {
      shouldRetryOnError: false
    }
  )

  useEffect(() => {
    if (!subscriptionOptionsError && hasDataInResponse(subscriptionOptionsResponse) && hasValidData(subscriptionOptionsResponse.data)) {
      dispatch({
        type: actionTypes.SUBSCRIPTION_OPTIONS_RECEIVED,
        data: subscriptionOptionsResponse.data,
      })
    }
  }, [dispatch, subscriptionOptionsResponse, subscriptionOptionsError])
}
