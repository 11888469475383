import React from 'react'

import { Radio } from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { ItemContent } from './RecipeDetailsPageItemContent'

import css from './RecipeDetailsPageOptionItem.css'

type RecipeDetailsPageOptionItemProps = {
  recipeId: string
  recipeName: string
  changeCheckedRecipe: () => void
  isChecked: boolean
  isOutOfStock: boolean
  surcharge?: number | null
}

export const RecipeDetailsPageOptionItem = ({
  recipeId,
  recipeName,
  changeCheckedRecipe,
  isChecked,
  isOutOfStock,
  surcharge = null,
}: RecipeDetailsPageOptionItemProps) => (
  <li className={classnames(css.listItem, { [css.listItemChecked]: isChecked })}>
    <Radio
      id={recipeId}
      value={recipeId}
      name="variantList"
      onChange={changeCheckedRecipe}
      checked={isChecked}
      style={{ width: '100%' }}
    >
      {() => (
        <ItemContent
          recipeName={recipeName}
          isOutOfStock={isOutOfStock}
          surcharge={surcharge}
          isChecked={isChecked}
        />
      )}
    </Radio>
  </li>
)
