import React from 'react'

import { BorderStyle, Box, Color, Divider, Space } from '@gousto-internal/citrus-react'

import { DeliveryDate } from './DeliveryDate'
import { Summary } from './Summary'
import { YourGoustoBox } from './YourGoustoBox'

type OrderSummaryAndBoxDetailsProps = {
  isCreatingPreviewOrder: boolean
}

export function OrderSummaryAndBoxDetails({
  isCreatingPreviewOrder,
}: OrderSummaryAndBoxDetailsProps) {
  return (
    <Box
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderColor={Color.NeutralGrey_200}
      bg={Color.White}
    >
      <YourGoustoBox />
      <Space size={6} />
      <Box paddingH={6}>
        <Summary isLoading={isCreatingPreviewOrder} />
        <Space size={6} />
        <Divider />
        <Space size={[0, 6]} />
        <DeliveryDate />
        <Space size={6} />
      </Box>
    </Box>
  )
}
