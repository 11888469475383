import React, { Fragment } from 'react'

import {
  Box,
  Button,
  ButtonColorVariant,
  Color,
  Display,
  Space,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { Slots } from '../types'

import css from './SlotPicker.css'

type SlotPickerProps = {
  slots: Slots
  date: string
  slotId: string
  onClick: (slotId: string) => void
}

const SmallButton = withCitrusTheme(Button, (theme) => ({
  flex: 1,
  padding: theme.spacing(1),
  '&:disabled, &:disabled:hover, &:disabled:focus': {
    color: theme.color(Color.NeutralGrey_200),
  },
}))

const SlotPicker = ({ slots, date, slotId, onClick }: SlotPickerProps) => {
  const hasMoreThanTwoSlots = slots[date] && slots[date].length > 2

  return (
    <Box data-testid="slotPicker" display={Display.Flex}>
      {slots[date] &&
        slots[date].map((slot, index) => (
          <Fragment key={slot.value}>
            <SmallButton
              colorVariant={
                slot.value === slotId && !slot.disabled
                  ? ButtonColorVariant.Primary
                  : ButtonColorVariant.Secondary
              }
              onClick={() => {
                if (!slot.disabled) {
                  onClick(slot.value)
                }
              }}
              className={classnames(
                { [css.disabled]: slot.disabled },
                { [css.enabled]: !slot.disabled },
                { [css.selected]: slot.value === slotId },
                { [css.compact]: hasMoreThanTwoSlots },
              )}
              disabled={slot.disabled}
            >
              <span className={css.fullWidth}>
                <span
                  className={classnames(
                    hasMoreThanTwoSlots ? css.blockLabel : css.label,
                    slot.disabled && css.disableLabel,
                  )}
                >
                  {slot.label}
                  <span className={hasMoreThanTwoSlots ? css.blockLabel : css.inlineLabel}>
                    {slot.subLabel}
                  </span>
                </span>
              </span>
            </SmallButton>
            {slots[date].length > 1 && index < slots[date].length - 1 && (
              <Space direction="horizontal" size={1} />
            )}
          </Fragment>
        ))}
    </Box>
  )
}

export { SlotPicker }
