import React from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  Icon,
  Space,
  useModal,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { queueTrackingEvent } from '@library/tracking-integration'

import { menuSearchModalOpen } from 'actions/trackingKeys'
import { getBasketMenuUuid } from 'selectors/basket'

import { recipeSearchModalName } from '../RecipeSearchModal/RecipeSearchModal'

type TrackSearchButtonClick = {
  action: typeof menuSearchModalOpen
  properties: {
    menu_id: string
  }
}
const onSpaceOrEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }

export const SearchButton = () => {
  const menuUuid = useSelector(getBasketMenuUuid)
  const { openModal } = useModal()

  const onClick = () => {
    openModal(recipeSearchModalName)

    queueTrackingEvent<TrackSearchButtonClick>({
      action: menuSearchModalOpen,
      properties: {
        menu_id: menuUuid,
      },
    })
  }

  return (
    <>
      <Box
        alignItems={AlignItems.Center}
        aria-label="Open menu search"
        bg={Color.NeutralGrey_50}
        borderRadius={1.5}
        data-testid="search-button"
        display={Display.Flex}
        height="40px"
        onClick={onClick}
        onKeyDown={onSpaceOrEnter(() => onClick())}
        paddingH={[2, 4]}
        role="button"
        tabIndex={0}
      >
        <Icon name="search" size={6} />
        <Space size={[1, 2]} direction="horizontal" />
        <Body2>Search the menu</Body2>
      </Box>
    </>
  )
}
