import React from 'react'
import { FontFamily, TextAlign } from '@gousto-internal/citrus-react'
import { getDietaryClaims } from '../../utils/getRecipeDietaryClaims'
import { DietaryIcons } from './DietaryIcons.styled'
import { Recipe } from '../../model/recipe'

const dietaryAcronyms = {
  vegan: 'PB',
  vegetarian: 'V',
  dairy_free: 'DF',
  gluten_free: 'GF',
}

export const DietaryLabels = ({ recipe }: { recipe: Recipe }) => {
  const dietaryClaims = getDietaryClaims(recipe)

  return (
    <>
      {dietaryClaims.map((claim) => (
        <DietaryIcons
          key={claim}
          fontFamily={FontFamily.Bold}
          size={2}
          textAlign={TextAlign.Center}
        >
          {dietaryAcronyms[claim]}
        </DietaryIcons>
      ))}
    </>
  )
}
