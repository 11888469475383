import { RequestMiddleware, composeFetch, composeParser } from '@library/http'
import { parseGoustoBasicResponse } from '@library/http/parsers'
import { addPath, auth, composeRequest, sendJSON, setHeader, setMethod, setQueryParam, setQueryParams, setServiceUrl } from '@library/http/requests'
import { Branded } from '@library/type-utils'

export type UserID = Branded<string, 'UserId'>

type CardCheckoutBody = {
  payment_provider: 'checkout'
  card_token: string
  active: number
}

type CustomerPaymentUpdateBody = {
  user_id: UserID
  is_default: 1
  type: string
  card: CardCheckoutBody
  '3ds': boolean
  success_url: string
  failure_url: string
}

type CustomerPaymentUpdateResponseData = {
  transactionId: string
  reference: string
  redirectLink: string
  message: string
  paymentStatus: string
  cardToken: string
}

type CustomerPaymentUpdateResponse = {
  status: string
  data: CustomerPaymentUpdateResponseData
}

type BraintreeCardDetails = {
  last4: string;
  cardHolderName: string | null;
  brand: string;
  bin: string;
  expiryDate: string;
}

type BraintreeResponsePayload = {
  transactionId: string;
  cardDetails: BraintreeCardDetails;
  responseCode: number;
  message: string;
  paymentStatus: string;
  cardToken: string;
}

type BraintreeCustomerPaymentUpdateResponseData = {
  id: string;
  cardToken: string;
  reference: string;
  status: string;
  userId: string;
  value: number;
  message: string;
  responsePayload: BraintreeResponsePayload;
  createdAt: string;
  updatedAt: string;
}

type BraintreeCustomerPaymentUpdateResponse = {
  status: string
  data: BraintreeCustomerPaymentUpdateResponseData
}

type BraintreeClientTokenResponseData = {
  clientToken: string
}

type BraintreeClientTokenResponse = {
  status: string
  data: BraintreeClientTokenResponseData
}

type BraintreeCustomerPaymentUpdateReq = {
  body: {
    user_id: string
    gousto_ref: string
    user_flow: string
    card_token: string
    device_data: string,
  },
  query: {
    session_id: string
  }
}

type CardDetails = {
  holder: string,
  number: string,
  type: string,
  cardToken: string,
  paymentProvider: string,
  expiryMonth: number,
  expiryYear: number,
}

type PayPalDetails = {
  cardToken: string,
  paymentProvider: 'paypal'
}

type ApplePayDetails =  {
  cardToken: string,
  paymentProvider: 'applepay'
}

type PaymentMethod = {
  method: 'paypal' | 'applepay',
  name: string,
  isDefault: boolean,
  details: PayPalDetails | ApplePayDetails ,
}

export type CardPaymentMethod = {
  method: 'card',
  name: string,
  isDefault: boolean,
  details: CardDetails,
}

type PaymentMethodData = {
  status: string,
  data: {
    type: 'payment-method',
    attributes: CardPaymentMethod | PaymentMethod,
  },
}

const paymentRequest = (userId: UserID) => composeRequest(
  setServiceUrl('paymentmethodquery'),
  addPath('payment-methods'),
  setQueryParam('userId', userId),
  auth
)

export const fetchPaymentDetails = (userId: UserID) => composeFetch(
  paymentRequest(userId),
  composeParser(
    parseGoustoBasicResponse,
    (basicResponse) => basicResponse as PaymentMethodData,
    (response) => response.data.attributes,
  )
)()

const customerPaymentUpdateRequest = composeRequest(
  setServiceUrl('customers', 2),
  addPath((params) => `customers/${params.user_id}/payment_methods`),
  setMethod('POST'),
  sendJSON<CustomerPaymentUpdateBody>(),
  auth,
)

export const updateCustomerPaymentDetails = composeFetch(
  customerPaymentUpdateRequest,
  composeParser(
    parseGoustoBasicResponse,
    (basicResponse: unknown) => basicResponse as CustomerPaymentUpdateResponse,
    (response: CustomerPaymentUpdateResponse) => response.data,
  ),
)

const braintreeClientTokenRequest = composeRequest(
  setServiceUrl('payments', 1),
  addPath('payments/token'),
  setQueryParam('provider', 'paypal'),
)

export const fetchBrainTreeClientToken = composeFetch(
  braintreeClientTokenRequest,
  composeParser(
    parseGoustoBasicResponse,
    (basicResponse: unknown) => basicResponse as BraintreeClientTokenResponse,
    (response: BraintreeClientTokenResponse ) => response.data
  )
)

const setPayload: RequestMiddleware<BraintreeCustomerPaymentUpdateReq> = (req, input) => {
  req.body = JSON.stringify(input.body)
  return req
}

const braintreeCustomerPaymentUpdateRequest = composeRequest(
  setHeader('Content-Type', 'application/json'),
  setServiceUrl('payments', 1),
  addPath('payments/payments'),
  setMethod('POST'),
  setPayload,
  setQueryParams((input: BraintreeCustomerPaymentUpdateReq) => ({
    provider: 'bento',
    session_id: input.query.session_id
  })),
)

export const updateBraintreeCustomerPaymentDetails = composeFetch(
  braintreeCustomerPaymentUpdateRequest,
  composeParser(
    parseGoustoBasicResponse,
    (basicResponse: unknown) => basicResponse as BraintreeCustomerPaymentUpdateResponse,
    (response: BraintreeCustomerPaymentUpdateResponse) => response.data,
  ),
)
