import React, { useState } from 'react'
import * as Yup from 'yup'
import useSWR from 'swr'
import { Field, FieldProps, Form, FormikProvider, useFormik } from 'formik'
import {
  Accordion,
  Join,
  Space,
  Text,
  Button,
  InputField,
  FormFieldStatus,
} from '@gousto-internal/citrus-react'
import { fetchCurrentUser, updateCurrentUser } from '@library/api-user'

import { RenderedTitle } from '../RenderedTitle'
import { ApiKeys } from '../../enums'

const errorMessage = 'Phone number must be a valid number'
const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^[0-9]{9,11}$/, { message: errorMessage })
    .required(errorMessage),
})

function PhoneNumberSection() {
  const { data, mutate } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const [timestamp, forceRender] = useState(Date.now())
  const formik = useFormik({
    initialValues: {
      phone: data?.user.phone || '',
    },
    onSubmit: async (values, { setFieldError }) => {
      try {
        await mutate(updateCurrentUser({ phone: values.phone }))
        forceRender(Date.now())
      } catch (e) {
        // display generic error underneath input field for now
        setFieldError(
          'phone',
          'Sorry, we couldn’t process your request right now. Please try again.',
        )
      }
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
  })
  const { errors, isSubmitting, resetForm } = formik

  return (
    <Accordion
      id="phone-number-accordion"
      key={`phone-number-accordion-${timestamp}`}
      title={(isExpanded) => (
        <RenderedTitle isExpanded={isExpanded} title="Phone number" iconType="phone">
          <Text size={2}>{data ? `+44 (0) ${data.user.phone}` : undefined}</Text>
        </RenderedTitle>
      )}
      onChange={(isExpanded) => {
        if (isExpanded) {
          return
        }
        resetForm({ values: { phone: data?.user.phone || '' } })
      }}
    >
      <FormikProvider value={formik}>
        <Form>
          <Join with={<Space size={4} />}>
            <Field name="phone">
              {({ field }: FieldProps) => (
                <InputField
                  id={field.name}
                  type="tel"
                  label="Phone number"
                  placeholder="Ex. 07783123456"
                  autoComplete="tel"
                  validationMessage={errors.phone}
                  {...('phone' in errors && { status: FormFieldStatus.Error })}
                  {...field}
                />
              )}
            </Field>
            <Button width="100%" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Join>
        </Form>
      </FormikProvider>
    </Accordion>
  )
}

export { PhoneNumberSection }
