import React from 'react'

import {
  Text,
  Color,
  FontWeight,
  Box,
  Icon,
  Space,
  IconVariant,
  AlignItems,
  IconNames,
} from '@gousto-internal/citrus-react'

import { minRule, maxRule, passwordCriteria } from './errors'

type PasswordCriteriaProps = {
  password: string
  passwordErrors: string[]
  showFailedCriteria: boolean
}

type CriteriaStyle = {
  textColor: Color
  iconName: IconNames
  iconVariant: IconVariant
}

export const PasswordCriteria = ({
  password,
  passwordErrors,
  showFailedCriteria,
}: PasswordCriteriaProps) => {
  const maxValue = passwordErrors.length > 0 && passwordErrors.includes(maxRule)
  const requirements = passwordCriteria.filter((item) =>
    maxValue ? item.rule !== minRule : item.rule !== maxRule,
  )

  const getCriteriaStyle = (rule: string): CriteriaStyle => {
    if (!passwordErrors.includes(rule) && password) {
      return {
        textColor: Color.Success_800,
        iconName: 'tick',
        iconVariant: IconVariant.Confirmation,
      }
    } else if (showFailedCriteria) {
      return { textColor: Color.Error_800, iconName: 'close', iconVariant: IconVariant.Error }
    }

    return { textColor: Color.Inherit, iconName: 'bullet_point', iconVariant: IconVariant.Inherit }
  }

  return (
    <Box data-testing="criteria-container">
      <Text size={1} fontWeight={FontWeight.Bold}>
        Password requirements:
      </Text>
      <Space size={2} />
      <Box data-testing="errors-list" paddingBottom={4}>
        {requirements.map(({ message, rule }) => {
          const { textColor, iconName, iconVariant } = getCriteriaStyle(rule)

          return (
            <Box key={rule} display="flex" alignItems={AlignItems.Center}>
              <Icon name={iconName} variant={iconVariant} />
              <Space size={2} direction="horizontal" />
              <Text size={1} color={textColor}>
                {message}
              </Text>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
