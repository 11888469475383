import React from 'react'

export const StarComplete = function () {
  return (
    <svg
      width="182"
      height="182"
      viewBox="0 0 182 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="91" cy="91" r="91" fill="#F4D13D" />
      <circle cx="90.9996" cy="91" r="76.2222" fill="#F4D13D" />
      <path
        d="M89.0616 117.089C90.015 116.513 91.2087 116.513 92.162 117.089L113.565 130.007C115.837 131.378 118.64 129.341 118.037 126.757L112.357 102.409C112.104 101.325 112.473 100.19 113.314 99.4605L132.228 83.0751C134.234 81.3375 133.162 78.0427 130.518 77.8184L105.619 75.706C104.51 75.6119 103.545 74.9119 103.11 73.8874L93.374 50.9153C92.3402 48.4762 88.8834 48.4762 87.8497 50.9153L78.1134 73.8874C77.6792 74.9119 76.7136 75.6119 75.6048 75.706L50.706 77.8184C48.0617 78.0427 46.9895 81.3375 48.9953 83.0751L67.9094 99.4604C68.7511 100.19 69.1197 101.325 68.8667 102.409L63.1868 126.757C62.5839 129.341 65.3866 131.378 67.6586 130.007L89.0616 117.089Z"
        fill="white"
      />
    </svg>
  )
}
