import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Text,
} from '@gousto-internal/citrus-react'

export const TooManyRecipesWarning = ({ numRecipesOverLimit }: { numRecipesOverLimit: number }) => {
  const recipeText = numRecipesOverLimit === 1 ? 'recipe' : 'recipes'

  return (
    <Box
      bg={Color.Error_50}
      display={Display.Flex}
      paddingH={4}
      paddingV={4}
      alignItems={AlignItems.FlexStart}
      borderColor={Color.Error_200}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      data-testing="tooManyRecipesWarning"
    >
      <Icon name="error" variant={IconVariant.Error} />
      <Box paddingH={2} paddingRight={0}>
        <Text size={1} fontFamily={FontFamily.ExtraBold}>
          You’ve chosen too many recipes
        </Text>
        <Text size={2}>
          Remove
          <Text size={2} display={Display.Inline} fontFamily={FontFamily.ExtraBold}>
            &nbsp;{numRecipesOverLimit}&nbsp;
          </Text>
          {recipeText} to check out
        </Text>
      </Box>
    </Box>
  )
}
