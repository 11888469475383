export enum DietaryClaimIconNames {
  DairyFree = 'dairy_free',
  GlutenFree = 'gluten_free',
  NutFree = 'nut_free',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
}

export const convertDashToUnderscore = (string: string): string => {
  return string.replace(/-/g, '_')
}
