const menuConfig = {
  recommendationInStockRecipeThreshold: 4,
  legal:
    'In addition to the recipe specific allergen information provided, due to production and packing methods Gousto boxes may also contain low levels of the following allergens: cereals containing gluten, crustaceans, eggs, fish, peanuts, soya, milk, nuts (almonds, hazelnuts, walnuts, cashews, pecan nuts, Brazil nuts, pistachio nuts, macadamia nuts), celery, sesame, sulphur dioxide and sulphites, lupin, molluscs, mustard',
  helmet: {
    title: "This Week's Menu | Choose From Over 75 Recipes | Gousto",
    meta: [
      {
        name: 'description',
        content:
          "Explore this week's menu and learn how to cook restaurant grade recipes. Browse through over 60 recipes each week and order your food box today!",
      },
      {
        name: 'keywords',
        content:
          'Gousto, recipe delivery, organic ingredients, fresh, healthy food, cooking, recipe box',
      },
    ],
    style: [
      {
        cssText: `
          body .zopim {
            bottom: 75px !important;
            -webkit-transition: -webkit-filter 0.3s;
            -webkit-filter: blur(0px);
            @media (max-width: 767px) {
              display: none !important;
            }
          }
        `,
      },
    ],
  },
  collections: [
    { id: 'quick-easy', label: 'Quick & Easy' },
    { id: 'family-friendly', label: 'Family Friendly' },
    { id: 'vegetarian', label: 'Vegetarian' },
    { id: 'lighter', label: 'Lighter' },
    { id: 'dairy-free', label: 'Dairy Free' },
    { id: 'gluten-free', label: 'Gluten Free' },
  ],
  communicationPanel: {
    title: 'We’re sorry there aren’t more options available',
    body: `We’ve had a rush on orders this week so a lot of recipes have now sold out. If you change your delivery day, more options should become available.`,
    showIcon: true,
    level: 'info',
  },
}

export const getMenuConfig = () => menuConfig
export default menuConfig
