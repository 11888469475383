import React from 'react'

import { Display, Box } from '@gousto-internal/citrus-react'
import { Form, FormikProvider } from 'formik'
import { useSelector } from 'react-redux'

import { PaymentProps } from 'routes/Checkout/Steps/Payment/Payment'
import { getDeliveryAddress } from 'routes/Checkout/checkoutSelectors'

import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { SubmitButton } from '../../SubmitButton'
import { CheckoutBillingAddress } from '../CheckoutBillingAddress'
import { CheckoutFrame } from '../CheckoutFrame/CheckoutFrame'
import { useAddressDetailsForm } from '../useAddressDetailsForm'
import { useHostedFields } from './useHostedFields'

type CheckoutPaymentDetailsProps = Pick<
  PaymentProps,
  'submitOrder' | 'onLoginClick' | 'braintreeClientInstance' | 'setBraintreeClientInstance'
> & {
  hide: boolean
}

export const HostedFields = ({
  submitOrder,
  onLoginClick,
  hide,
  braintreeClientInstance,
  setBraintreeClientInstance,
}: CheckoutPaymentDetailsProps) => {
  const formik = useAddressDetailsForm()
  const deliveryAddress = useSelector(getDeliveryAddress)

  const {
    formValidationError,
    isFormValid,
    handleCardDetailsValidation,
    handleSubmit,
    isFormSubmitting,
    setHostedFieldsFrameLoaded,
  } = useHostedFields({
    braintreeClientInstance,
    setBraintreeClientInstance,
    submitOrder,
  })

  const processSubmit = () => {
    if (!isFormValid || !formik.values.cardHolderName) {
      handleCardDetailsValidation()
      formik.setFieldTouched('cardHolderName')
    }
    if (isFormValid && formik.isValid) {
      formik.submitForm()
      handleSubmit({ deliveryAddress, values: formik.values })
    }
  }

  return (
    <Box display={hide ? Display.None : Display.Block}>
      <FormikProvider value={formik}>
        <Form>
          <CheckoutFrame
            setHostedFieldsFrameLoaded={setHostedFieldsFrameLoaded}
            formValidationError={formValidationError}
          />
          <CheckoutBillingAddress />
          <ErrorMessage onLoginClick={onLoginClick} />
          <SubmitButton onClick={processSubmit} isDisabled={isFormSubmitting} />
        </Form>
      </FormikProvider>
    </Box>
  )
}
