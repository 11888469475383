import React from 'react'

import {
  FontWeight,
  Icon,
  Box,
  Text,
  Space,
  Display,
  FlexDirection,
  AlignItems,
  Color,
  FontFamily,
  JustifyContent,
  BorderStyle,
} from '@gousto-internal/citrus-react'

import { formatOrderPrice } from 'utils/pricing'

import { titleConfig, orderSummaryTestId, expandedIcon, collapsedIcon } from './data'

type HeaderContentProps = {
  isExpanded: boolean
  totalWithoutDiscount: string
  totalToPay?: string
  promoCodeValid: boolean
}

export const HeaderContent = ({
  isExpanded,
  totalWithoutDiscount,
  totalToPay,
  promoCodeValid,
}: HeaderContentProps) => {
  const { expandedTitle, collapsedTitle } = titleConfig

  return (
    <Box
      data-testid={orderSummaryTestId}
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      justifyContent={JustifyContent.SpaceBetween}
      alignItems={AlignItems.Center}
      paddingH={3}
      paddingV={4}
      borderStyle={BorderStyle.Solid}
      borderColor={Color.NeutralGrey_200}
      borderBottomWidth={isExpanded ? 0 : 0.5}
      data-testing="checkoutOrderSummary"
    >
      <Box paddingRight={1}>
        <Text size={2} fontFamily={FontFamily.Bold}>
          {isExpanded ? expandedTitle : collapsedTitle}
        </Text>
      </Box>
      <Box display={Display.Flex} flexDirection={FlexDirection.Row} alignItems={AlignItems.Center}>
        {promoCodeValid && (
          <Text>
            <s>{`£${totalWithoutDiscount}`}</s>
          </Text>
        )}
        <Space size={2} direction="horizontal" />
        <Text
          size={3}
          fontFamily={FontFamily.Bold}
          fontWeight={FontWeight.Bold}
          color={Color.Success_800}
        >
          {formatOrderPrice(totalToPay)}
        </Text>
        <Space size={2} direction="horizontal" />
        <Icon name={isExpanded ? expandedIcon : collapsedIcon} />
      </Box>
    </Box>
  )
}
