import { actionTypes } from 'actions/actionTypes'
import { basketPromoCodeAppliedChange, basketPromoCodeChange } from 'actions/basket'
import statusActions from 'actions/status'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { Pricing } from 'routes/Menu/domains/pricing'
import { getPromoCode } from 'selectors/basket'
import { isValidPromoCode } from 'utils/order'

export const resetDuplicateCheck: CheckoutActionCreator =
  ({ pricing }: { pricing: Pricing }) =>
  (dispatch, getState) => {
    const state = getState()
    dispatch(statusActions.error(actionTypes.CHECKOUT_ERROR_DUPLICATE, null))
    if (getPromoCode(state)) {
      if (!isValidPromoCode(pricing)) {
        dispatch(basketPromoCodeChange(''))
        dispatch(basketPromoCodeAppliedChange(false))
        dispatch(statusActions.error(actionTypes.CHECKOUT_ERROR_DUPLICATE, true))
      }
    }
  }
