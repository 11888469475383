import { actionTypes } from 'actions/actionTypes'

function getError(state, action, orderId) {
  const error = state.error.get(action, null)
  const result = error && error.orderId === orderId ? error.error : undefined

  return result
}

const getCancelledOrderError = (state, orderId) => {
  const projectedOrderError = getError(state, actionTypes.PROJECTED_ORDER_CANCEL, orderId)
  const cancelledOrderError = getError(state, actionTypes.CANCELLED_ORDER_RESTORED, orderId)

  return projectedOrderError || cancelledOrderError
}

const isCancelledOrderPending = (state) => {
  const isProjectedOrderCancelPending = state.pending.get(actionTypes.PROJECTED_ORDER_CANCEL, false)
  const isProjectedOrderRestorePending = state.pending.get(actionTypes.PROJECTED_ORDER_RESTORE, false)
  const isCancelledOrderRestoredPending = state.pending.get(actionTypes.CANCELLED_ORDER_RESTORED, false)

  return isProjectedOrderCancelPending || isCancelledOrderRestoredPending || isProjectedOrderRestorePending
}

export { getCancelledOrderError, isCancelledOrderPending }
