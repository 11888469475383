import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { findNewestOrder, isOrderBeingDeliveredToday } from 'utils/order'
import { DeliverySimple } from './DeliverySimple'

const propTypes = {
  loadOrderTrackingInfo: PropTypes.func.isRequired,
  orders: PropTypes.instanceOf(Immutable.Map),
  nextOrderTracking: PropTypes.string,
  params: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }).isRequired,
  trackDeliveryOther: PropTypes.func.isRequired,
  trackDeliveryStatus: PropTypes.func.isRequired,
  trackNextBoxTrackingClick: PropTypes.func.isRequired,
  userLoadOrders: PropTypes.func.isRequired,
}
const defaultProps = {
  nextOrderTracking: null,
  orders: Immutable.Map({})
}

class Delivery extends PureComponent {
  async componentDidMount() {
    const { userLoadOrders } = this.props

    userLoadOrders()
  }

  componentDidUpdate(prevProps) {
    const { orders, loadOrderTrackingInfo } = this.props
    if (prevProps.orders.size !== orders.size) {
      const upcomingOrder = findNewestOrder(orders, true)
      const orderDeliveryDate = upcomingOrder && upcomingOrder.get('deliveryDate')

      if (upcomingOrder && isOrderBeingDeliveredToday(orderDeliveryDate)) {
        loadOrderTrackingInfo(upcomingOrder.get('id'))
      }
    }
  }

  render() {
    const {
      loadOrderTrackingInfo,
      nextOrderTracking,
      orders,
      trackDeliveryOther,
      trackDeliveryStatus,
      trackNextBoxTrackingClick,
      userLoadOrders,
    } = this.props

    return (
      <DeliverySimple
        loadOrderTrackingInfo={loadOrderTrackingInfo}
        nextOrderTracking={nextOrderTracking}
        orders={orders}
        trackDeliveryOther={trackDeliveryOther}
        trackDeliveryStatus={trackDeliveryStatus}
        trackNextBoxTrackingClick={trackNextBoxTrackingClick}
        userLoadOrders={userLoadOrders}
      />
    )
  }
}

Delivery.defaultProps = defaultProps
Delivery.propTypes = propTypes

export {
  Delivery
}
