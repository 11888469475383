import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  Heading3,
  Image,
  JustifyContent,
  Space,
  TextAlign,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { BRAND_ENDORSEMENTS } from '../../config'

const LogoImage = withCitrusTheme(Image, () => ({
  opacity: 0.7,
  mixBlendMode: 'multiply',
}))

export const BrandEndorsementsSection = () => {
  const { images, header } = BRAND_ENDORSEMENTS

  return (
    <section data-testid="brandEndorsementsSection">
      <Box paddingV={[14, 14, 16]} paddingH={6} paddingBottom={12} bg={Color.White}>
        <Heading3 textAlign={TextAlign.Center}>{header}</Heading3>
        <Space size={6} />
        <Box
          alignItems={AlignItems.Center}
          display={Display.Flex}
          flexDirection={[FlexDirection.Column, null, null, FlexDirection.Row]}
          justifyContent={JustifyContent.Center}
          gap={4}
        >
          <Box
            alignItems={AlignItems.Center}
            display={Display.Flex}
            flexDirection={[FlexDirection.Column, FlexDirection.Row]}
            gap={4}
          >
            <Box
              alignItems={AlignItems.Center}
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              gap={4}
            >
              <LogoImage
                height="32px"
                width="auto"
                alt="bbclogo"
                srcSet={images.britishBroadcastingCorporation.webp}
                src={images.britishBroadcastingCorporation.png}
              />

              <LogoImage
                height="40px"
                width="auto"
                alt="thetelegraphlogo"
                srcSet={images.theTelegraph.webp}
                src={images.theTelegraph.png}
              />
            </Box>
            <LogoImage
              height="32px"
              width="auto"
              alt="marieclairelogo"
              srcSet={images.marieClaire.webp}
              src={images.marieClaire.png}
            />
          </Box>
          <Box
            alignItems={AlignItems.Center}
            display={Display.Flex}
            flexDirection={[FlexDirection.Column, FlexDirection.Row]}
            gap={4}
          >
            <LogoImage
              height="40px"
              width="auto"
              alt="independentlogo"
              srcSet={images.independent.webp}
              src={images.independent.png}
            />
            <LogoImage
              height="32px"
              width="auto"
              alt="eveningstandardlogo"
              srcSet={images.eveningStandard.webp}
              src={images.eveningStandard.png}
            />
          </Box>
        </Box>
      </Box>
    </section>
  )
}
