import {
  Body1,
  Body2,
  Box,
  Col,
  Color,
  FontWeight,
  Grid,
  Join,
  Space,
} from '@gousto-internal/citrus-react'
import React from 'react'

type Props = {
  humanDeliveryDayTime: string
  address: {
    lineOne: string
    town: string
    postcode: string
  }
}

export const OrderExpanded = ({ humanDeliveryDayTime, address }: Props) => {
  return (
    <Grid width="100%">
      <Col size={12}>
        <Join with={<Space size={3} direction="vertical" />}>
          <Body1 fontWeight={FontWeight.Bold} color={Color.ColdGrey_900}>
            Delivery Details
          </Body1>
          <Box>
            <Body2 fontWeight={FontWeight.Bold} color={Color.ColdGrey_900}>
              Date and time
            </Body2>
            <Body2 color={Color.ColdGrey_900}>{humanDeliveryDayTime}</Body2>
          </Box>
        </Join>
      </Col>
      <Col size={12}>
        <Space size={3} direction="vertical" />
        <Body2 fontWeight={FontWeight.Bold} color={Color.ColdGrey_900}>
          Delivery Address
        </Body2>
        <Body2 color={Color.ColdGrey_900}>
          {address.lineOne}, {address.town}, {address.postcode}
        </Body2>
      </Col>
    </Grid>
  )
}
