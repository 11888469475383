import React, { useEffect, useRef, useContext } from 'react'
import useSWR from 'swr'
import { fetchCurrentUser, UserID } from '@library/api-user'
import { Join, Space } from '@gousto-internal/citrus-react'
import { ApiKeys } from '../../enums'
import { PaymentCardSection } from './PaymentCardSection/PaymentCardSection'
import { AccountDetailsContext } from '../../AccountDetailsContext'

export const PaymentInfo = () => {
  const { data } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const { state } = useContext(AccountDetailsContext)
  const rootRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!state.paymentInfoSectionExpanded) {
      return
    }

    const options: ScrollIntoViewOptions = {
      block: 'center',
      behavior: 'smooth',
    }

    rootRef.current?.scrollIntoView(options)
  }, [state])

  const userId = data?.user.id as UserID
  return (
    <div ref={rootRef}>
      <Space size={6} direction="vertical" />
      <Join with={<Space size={6} />}>
        <PaymentCardSection userId={userId} />
      </Join>
    </div>
  )
}
