export const LOGIN_MODAL_COPY = {
  loginCopy: 'Login',
  emailLabel: 'Email',
  passwordLabel: 'Password',
  forgotPassword: 'Forgot Password',
  rememberMe: 'Remember Me',
}

export const EMAIL_VALIDATION_RULES = {
  required: 'This field is required',
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Please enter a valid email address',
  },
}

export const PASSWORD_VALIDATION_RULES = {
  required: 'This field is required',
}

export const EXISTING_EMAIL_LOGIN_MODAL_NAME = 'existingEmailLoginModal'

export const EXISTING_EMAIL_WARNING_NOTIFICATION_COPY = {
  loginCTA: 'Login',
  loginTitle: `Welcome back!`,
  loginMessage: `Looks like you've already got an account with us. Please log in instead.`,
}

export const VALIDATE_EMAIL_ERROR_DISPLAY = 'Uh oh! Something went wrong, please try again'
