import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { trackUTMAndPromoCode } from 'actions/tracking'
import * as trackingKeys from 'actions/trackingKeys'
import { clearGoustoRef, handleCheckoutError } from 'routes/Checkout/checkoutActions'
import { getPaymentAuthData } from 'routes/Checkout/checkoutPaymentSelectors'
import { authPayment } from 'routes/Checkout/checkoutPaymentsApis'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { Pricing } from 'routes/Menu/domains/pricing'
import { getSessionId } from 'selectors/cookies'

export const checkoutCardAuthorisation: CheckoutActionCreator =
  ({ pricing }: { pricing: Pricing }) =>
  async (dispatch, getState) => {
    try {
      const reqData = getPaymentAuthData(getState(), { pricing })
      if (reqData.order_id) {
        const { data } = await authPayment(reqData, getSessionId())
        dispatch({
          type: actionTypes.PAYMENT_SHOW_MODAL,
          challengeUrl: data.responsePayload.redirectLink,
        })
        dispatch(trackUTMAndPromoCode(trackingKeys.signupChallengeModalDisplay))
      }
    } catch (err) {
      await dispatch(handleCheckoutError(err, 'checkoutCardAuthorisation'))
      dispatch(clearGoustoRef())
      dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, false))
    }
  }
