import { Box, Display, FlexDirection, AlignItems, Body2, Bold } from '@gousto-internal/citrus-react'
import * as React from 'react'

export function DetailColumn(props: { children: React.ReactNode; heading: string }) {
  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      flexGrow={2}
      alignItems={AlignItems.FlexStart}
      gap={1}
    >
      <Body2>
        <Bold>{props.heading}</Bold>
      </Body2>
      {props.children}
    </Box>
  )
}
