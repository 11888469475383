// import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Breakpoint } from '@gousto-internal/citrus-react'

type RecipeContainerProps = {
  maxRecipesNum: number
  recipesNum: number
  expanded: boolean
  delayedExpanded: boolean
}

export const RecipesContainer = styled.div(
  ({ expanded, maxRecipesNum, recipesNum, delayedExpanded }: RecipeContainerProps) => {
    const fromSmallMediaKey = `@media (min-width: ${Breakpoint.Small})`
    // eslint-disable-next-line no-nested-ternary
    const gapSize = expanded ? '0.5rem' : maxRecipesNum === 4 ? '0.5rem' : '0.25rem'

    return {
      display: 'grid',
      gridTemplateRows: delayedExpanded ? `repeat(${recipesNum}, 64px)` : '1fr',
      gridTemplateColumns: delayedExpanded ? '100%' : `repeat(${maxRecipesNum}, 1fr)`,
      width: '100%',
      gap: gapSize,

      [fromSmallMediaKey]: {
        gridTemplateRows: delayedExpanded ? `repeat(${recipesNum}, 80px)` : '1fr',
      },

      '> div:not(:first-child)': {
        transition: `${expanded ? '300ms' : '100ms'} ease-out`,
        ...(expanded && { opacity: !delayedExpanded ? 0 : 1 }),
        ...(!expanded && { opacity: delayedExpanded ? 0 : 1 }),
      },
    }
  },
)

export const RecipeTileImage = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
})
