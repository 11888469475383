import React from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  FlexDirection,
  Icon,
  IconVariant,
  Space,
} from '@gousto-internal/citrus-react'

export const BoxPriceSuitableForSection = ({
  suitableForLabel,
}: {
  suitableForLabel: string[]
}) => (
  <>
    {suitableForLabel.map((label, index) => (
      <li key={`for-${label}`}>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          alignItems={AlignItems.Center}
        >
          <Icon name="tick" variant={IconVariant.Confirmation} size={5} />
          <Space size={2} direction="horizontal" />
          <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
            <Body2 color={Color.ColdGrey_800}>{label}</Body2>
            <Space size={0.25} direction="vertical" />
          </Box>
        </Box>
        {index === 0 && <Space size={1} direction="vertical" />}
      </li>
    ))}
  </>
)
