import {
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  AlignItems,
  DisplayPosition,
  ZIndex,
  Color,
  BorderStyle,
} from '@gousto-internal/citrus-react'
import * as React from 'react'

export function OverlayContainer(props: { children: React.ReactNode }) {
  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.FlexStart}
      gap={[1, 3]}
      position={DisplayPosition.Fixed}
      zIndex={ZIndex.Overlay}
      paddingH={3}
      paddingV={3}
      bg={Color.Warning_50}
      borderRadius={1.5}
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderColor={Color.Warning_100}
      bottom={['0px', '12px']}
      left={['0px', '12px']}
    >
      {props.children}
    </Box>
  )
}
