import React from 'react'

import {
  Box,
  Display,
  Text,
  Heading1,
  Space,
  FontFamily,
  AlignItems,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import TextInput from 'Form/Input'
import { useDispatch, useSelector } from 'react-redux'

import { RibbonTriggerContainer } from 'components/RibbonTrigger'
import { useGetDiscountSofteningFreeDeliveryFromPromoCode } from 'hooks/useGetDiscountSofteningFreeDeliveryFromPromoCode'
import {
  signupChangePostcode,
  signupChangeTempPostcode,
  signupGetCountByPostcode,
} from 'routes/Signup/signupActions'
import { signupConfig } from 'routes/Signup/signupConfig'
import {
  getDeliveryDaysError,
  getPostcodePending,
  getTempPostcode,
} from 'routes/Signup/signupSelectors'

import { Button } from '../../Button'
import { Image } from '../../Image'
import { FreeDeliveryCard } from './FreeDeliveryCard'
import { PostcodeStepMessage } from './PostcodeStepMessage'

export function PostcodeStep({ nextStepName = '' }: { nextStepName: string | number }) {
  const dispatch = useDispatch()

  const tempPostcode = useSelector(getTempPostcode)
  const deliveryDaysError = useSelector(getDeliveryDaysError)
  const postcodePending = useSelector(getPostcodePending)
  const value = useGetDiscountSofteningFreeDeliveryFromPromoCode()

  const isFreeDeliveryEnabled = value && ['t2', 't3'].includes(value)

  const handleClick = () => {
    dispatch(signupGetCountByPostcode(tempPostcode))
    dispatch(signupChangePostcode(tempPostcode, nextStepName))
  }
  const inputChangeHandler = (postcode: string) => dispatch(signupChangeTempPostcode(postcode))

  return (
    <Box data-testing="signupPostcodeStep">
      <Box>
        <>
          <Heading1>{signupConfig.postCodeStep.title}</Heading1>
          <Image name="where-to-deliver" />
          <Space size={3} direction="vertical" />
        </>
      </Box>

      <Box>
        <form
          onSubmit={(e) => {
            if (e) {
              e.preventDefault()
            }
            handleClick()
          }}
          action="#"
        >
          <Box
            display={Display.Flex}
            alignItems={AlignItems.FlexEnd}
            justifyContent={JustifyContent.Center}
          >
            <Box flexBasis={['50%', 'auto']} width={[null, '100%']} maxWidth={[null, '13.5rem']}>
              <Text size={2} fontFamily={FontFamily.Bold}>
                Postcode
              </Text>
              <Space size={2} direction="vertical" />
              <TextInput
                isFixed
                placeholder="e.g. W3 7UP"
                onChange={inputChangeHandler}
                minLength={5}
                maxLength={8}
                value={tempPostcode}
                mask
                dataTesting="signupPostcodeInput"
                isInCheckout
                error={deliveryDaysError}
                autoComplete="postal-code"
              />
            </Box>
            <Space size={2} direction="horizontal" />
            <Box maxWidth="8.25rem" flexGrow={1}>
              <Button
                disabled={tempPostcode.trim().length < 5}
                data-testing="signupPostcodeCTA"
                onClick={handleClick}
                pending={postcodePending}
                width="full"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      <Space size={[4, 6]} direction="vertical" />
      <PostcodeStepMessage deliveryDaysError={deliveryDaysError} />
      {isFreeDeliveryEnabled && (
        <>
          <Space size={[4, 6]} direction="vertical" />
          <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
            <FreeDeliveryCard />
          </Box>
        </>
      )}
      <RibbonTriggerContainer name="wizard-postcode-step" />
    </Box>
  )
}
