module.exports = {
  imageSize: {
    grid: 700,
    featured: 1000,
    list: 200,
    detail: 1000,
  },
  imageScale: {
    50: '100',
    200: '200',
    400: '400',
    700: '767',
    1000: '1199',
    1500: '1599',
    1600: '1600',
  },
}
