export const seo = {
  title: 'Refer a friend | Gousto',
  meta: [
    {
      name: 'og:description',
      content: 'Get 65% off your first box plus 25% off other boxes in your first two months',
    },
    {
      name: 'og:image',
      content: 'https://production-media.gousto.co.uk/cms/mood-image/IMG2002_Box.jpg',
    },
    {
      name: 'og:image:width',
      content: '1200',
    },
    {
      name: 'og:image:height',
      content: '800',
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:url',
      content: 'https://www.gousto.co.uk/raf/',
    },
  ],
}
