import React, { useCallback, useContext, useMemo, useState } from 'react'

import {
  Body2,
  Heading3,
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalButton,
  ButtonColorVariant,
  ButtonSize,
  useModal,
  RadioGroup,
  Space,
  Bold,
} from '@gousto-internal/citrus-react'
import { Loader } from 'goustouicomponents'

import { SubscriptionContext } from 'routes/Account/Subscription/context'
import { getDeliveryFrequency } from 'routes/Account/Subscription/context/selectors/deliveries'
import { frequencyMapping } from 'routes/Account/Subscription/enum/frequency'
import { useUpdateSubscription } from 'routes/Account/Subscription/hooks/useUpdateSubscription'
import { trackSubscriptionSettingsChange } from 'routes/Account/Subscription/tracking'

export const CHANGE_FREQUENCY_MODAL_NAME = 'CHANGE-FREQUENCY-MODAL'

type DeliveryFrequency = 1 | 2 | 4

interface Props {
  accessToken: string
  isModalOpen?: boolean
  onUpdateCompleted: () => void
}

export const ChangeFrequencyModal = ({ accessToken, isModalOpen, onUpdateCompleted }: Props) => {
  const context = useContext<any>(SubscriptionContext)
  const currentDeliveryFrequency = getDeliveryFrequency(context.state) as DeliveryFrequency
  const [selected, setSelected] = useState(currentDeliveryFrequency)
  const [shouldSubmit, setShouldSubmit] = useState(false)

  const options = useMemo(
    () =>
      Object.entries(frequencyMapping).map(([value, label]) => ({
        name: 'subscriptionPauseReason',
        label,
        value: parseInt(value, 10),
      })),
    [],
  )

  const { closeCurrentModal } = useModal()

  const [isLoading, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    trigger: {
      shouldRequest: shouldSubmit,
      setShouldRequest: setShouldSubmit,
    },
    data: {
      interval: selected,
    },
    settingName: 'box_frequency',
  })

  const handleClose = useCallback(() => {
    setSelected(currentDeliveryFrequency)
    setShouldSubmit(false)
    closeCurrentModal()
  }, [closeCurrentModal, setSelected, setShouldSubmit, currentDeliveryFrequency])

  const handleFrequencyChange = useCallback(
    (event) => {
      setSelected(parseInt(event.target.value, 10) as DeliveryFrequency)
    },
    [setSelected],
  )

  const handleSaveClick = useCallback(() => {
    trackSubscriptionSettingsChange({ settingName: 'recover_subscription', action: 'click' })({
      pause_reason: "I can't afford a weekly box",
      recovery_action: 'Change my delivery frequency',
      recovery_action_selected: frequencyMapping[selected],
    })
    setShouldSubmit(true)
  }, [selected, setShouldSubmit])

  if (updateError) {
    return (
      <Modal
        isOpen={isModalOpen}
        name={CHANGE_FREQUENCY_MODAL_NAME}
        onAfterClose={onUpdateCompleted}
      >
        <ModalClose onClose={closeCurrentModal} />
        <ModalHeader>
          <Heading3>Oops! Something went wrong...</Heading3>
        </ModalHeader>

        <ModalBody>
          <Body2>
            We&apos;re really sorry, but it seems there&apos;s an unexpected issue on our end:
            we&apos;re currently unable to update the frequency of your subscription.
          </Body2>
        </ModalBody>

        <ModalFooter>
          <ModalButton
            colorVariant={ButtonColorVariant.Primary}
            size={ButtonSize.Large}
            onClick={closeCurrentModal}
          >
            Okay
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  if (updateResponse) {
    const intervalMapping = {
      1: 'once a week',
      2: 'fortnightly',
      4: 'once a month',
    }
    const intervalText = intervalMapping[selected]

    return (
      <Modal
        isOpen={isModalOpen}
        name={CHANGE_FREQUENCY_MODAL_NAME}
        onAfterClose={onUpdateCompleted}
      >
        <ModalClose onClose={closeCurrentModal} />
        <ModalHeader>
          <Heading3>Frequency updated</Heading3>
        </ModalHeader>

        <ModalBody>
          <Body2>
            We&apos;ll send you a Gousto box <Bold>{intervalText}</Bold>. You can change this in
            your <Bold>Subscription details</Bold>.
          </Body2>
        </ModalBody>

        <ModalFooter>
          <ModalButton
            colorVariant={ButtonColorVariant.Primary}
            size={ButtonSize.Large}
            onClick={closeCurrentModal}
          >
            Done
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const isCtaDisabled =
    selected === currentDeliveryFrequency || !selected || isLoading || shouldSubmit

  return (
    <Modal isOpen={isModalOpen} name={CHANGE_FREQUENCY_MODAL_NAME}>
      <ModalClose onClose={handleClose} />
      <ModalHeader>
        <Heading3>Change your frequency</Heading3>
      </ModalHeader>

      <ModalBody>
        <Body2>Please select the how often you’d like to receive your box</Body2>
        <Space size={4} direction="vertical" />
        <RadioGroup outline value={selected} onChange={handleFrequencyChange} options={options} />
      </ModalBody>

      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Large}
          disabled={isCtaDisabled}
          onClick={handleSaveClick}
          style={{
            paddingBottom: shouldSubmit ? '1.5rem' : '1rem',
            paddingTop: shouldSubmit ? '1.5rem' : '1rem',
          }}
          data-testid="save-subscription-cta"
        >
          {shouldSubmit ? <Loader /> : 'Save and keep subscription'}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

ChangeFrequencyModal.defaultProps = {
  isModalOpen: false,
}
