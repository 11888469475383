import { endpoint } from '@library/endpoint'

import { useUserIdForOptimizely } from 'containers/OptimizelyRollouts/useOptimizely.hook'
import { fetch } from 'utils/fetch'

import { getRequestHeaders } from './_utils'

export const sendClientMetric = async (name, value, unit = 'None', userId) => {
  const reqData = {
    client: 'web',
    name,
    value,
    unit,
  }

  const headers = getRequestHeaders(userId)

  try {
    await fetch(null, `${endpoint('clientmetrics')}/metric`, reqData, 'POST', 'default', headers)
  } catch (e) {
    //
  }
}

export const useSendClientMetric = () => {
  const userId = useUserIdForOptimizely()

  return (name, value, unit) => sendClientMetric(name, value, unit, userId)
}
