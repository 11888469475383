import { useCallback, useEffect } from 'react'

import { useModal } from '@gousto-internal/citrus-react'

import { recipesPerBoxConfirmationModal } from '../ActiveSubscription/sections/DefaultRecipeSettings/RecipesPerBox/RecipesPerBoxConfirmationModal'
import { boxSizeConfirmationModal } from '../ActiveSubscription/sections/YourSubscriptionDetails/BoxSize/BoxSizeConfirmationModal'

export type Order = {
  phase: string
  deliveryDate: string
}

type Input = {
  openOrders: Order[]
  didUpdateComplete: boolean
  didUpdateFail: boolean | null
  numPortions: string
  numRecipes?: string
  modalName: string
}

const getDefaultFailureContent = () => ({
  title: 'Oops, something went wrong',
  notificationTitle: `Sorry, we couldn't process your request right now. Please try again.`,
  notificationBody: null,
  variant: 'error',
  subTitle: null,
})

const getDefaultSuccessfulContent = () => ({
  title: 'Default box recipes updated',
  notificationTitle: null,
  notificationBody: `This change applies to deliveries you haven't confirmed yet`,
  variant: 'success',
  subTitle: null,
})

const getDefaultSuccessfulContentForPortions = (numPortions: string) => ({
  ...getDefaultSuccessfulContent(),
  subTitle: `You've changed to a ${numPortions}-person box`,
  title: 'Edit box size',
})

const getDefaultSuccessfulContentForRecipes = (numRecipes: string) => ({
  ...getDefaultSuccessfulContent(),
  notificationTitle: `You've changed to a ${
    Number(numRecipes) > 1 ? `${numRecipes} recipe` : 'one recipe'
  } box`,
})

const getOpenOrdersContentForPortions = (numPortions: string) => ({
  ...getDefaultSuccessfulContent(),
  title: 'Edit box size',
  subTitle: `You've changed to a ${numPortions}-person box`,
  notificationTitle: `Any confirmed deliveries won't change automatically to a ${
    Number(numPortions) > 1 ? `${numPortions} person` : 'one person'
  } box`,
  notificationBody: `You'll need to edit upcoming deliveries individually to change the size of your box`,
})

const getOpenOrdersContentForRecipes = (numRecipes: string) => ({
  ...getDefaultSuccessfulContent(),
  notificationTitle: `Any confirmed deliveries won't change automatically to a ${
    Number(numRecipes) > 1 ? `${numRecipes} recipe` : 'one recipe'
  } box`,
  notificationBody: null,
})

const getSubscriptionModalContent = (
  showDeliveriesReminder: boolean,
  didUpdateFail: boolean | null,
  numPortions: string,
  modalName: string,
  numRecipes?: string,
) => {
  if (didUpdateFail) {
    return getDefaultFailureContent()
  }

  if (showDeliveriesReminder) {
    if (modalName === recipesPerBoxConfirmationModal && numRecipes) {
      return getOpenOrdersContentForRecipes(numRecipes)
    }
    if (modalName === boxSizeConfirmationModal) {
      return getOpenOrdersContentForPortions(numPortions)
    }
  }

  if (modalName === recipesPerBoxConfirmationModal && numRecipes) {
    return getDefaultSuccessfulContentForRecipes(numRecipes)
  }
  if (modalName === boxSizeConfirmationModal) {
    return getDefaultSuccessfulContentForPortions(numPortions)
  }

  throw new Error('Unexpected modal name')
}

export const useSubscriptionModal = ({
  openOrders,
  didUpdateComplete,
  didUpdateFail,
  numPortions,
  numRecipes,
  modalName,
}: Input) => {
  const { openModal, closeCurrentModal } = useModal()
  const showDeliveriesReminder = openOrders.length > 0

  useEffect(() => {
    if (didUpdateComplete || didUpdateFail) {
      openModal(modalName)
    }
  }, [didUpdateComplete, didUpdateFail, modalName, openModal])

  const handleClose = useCallback(() => {
    closeCurrentModal()
  }, [closeCurrentModal])

  return {
    content: getSubscriptionModalContent(
      showDeliveriesReminder,
      didUpdateFail,
      numPortions,
      modalName,
      numRecipes,
    ),
    handleClose,
  }
}
