import { Order, ProductItem, RecipeItem } from '@library/api-user'
import { LOCALE } from '../configs'
import { OrderDiscountPrice, orderModel } from '../models/orderModel'
import { useMemo, useState } from 'react'

const orderPhaseToHumanReadableMap = {
  cancelled: 'Cancelled',
  choose: 'Menu open',
  edit: 'Recipes chosen',
  packing: "We're preparing your order",
  confirmed: "We're preparing your order",
  picking: "We're preparing your order",
  dispatched: 'Dispatched',
  delivered: 'Delivered',
  preMenu: 'Scheduled',
}

const formatOrderPhaseToHumanReadable = (
  phaseKey: keyof typeof orderPhaseToHumanReadableMap,
): string => {
  return orderPhaseToHumanReadableMap[phaseKey]
}

const formatTimestampToTwelveHourTime = (dateString: string): string => {
  return new Date(dateString).toLocaleString(LOCALE, {
    hour12: true,
    timeStyle: 'short',
  })
}

const formatTimestampToOrdinalDate = (dateString: string): string => {
  const rules = new Intl.PluralRules(LOCALE, { type: 'ordinal' })
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  const day = new Date(dateString).getDate()
  const month = new Date(dateString).toLocaleString(LOCALE, { month: 'long' })
  const rule = rules.select(day)
  const suffix = suffixes.get(rule)

  return `${day}${suffix} ${month}`
}

export interface UseOrderTileResult {
  data: {
    shouldCutOffTime: string
    shouldCutOffDate: string
    orderDiscount: string | null
    humanDeliveryDate: string
    isAwaitingChoices: boolean
    isOrderEditable: boolean
    isOrderDelivered: boolean
    orderPhaseContent: string
    price: OrderDiscountPrice
    recipeItems: RecipeItem[]
    productItems: ProductItem[]
    shippingAddress: Partial<Order['shippingAddress']>
    box: {
      numPortions: string
      numRecipes: string
    }
    humanDeliveryDayTime: string
    orderId: string
  }
  isOrderExpanded: boolean
  setIsOrderExpanded: (isExpanded: boolean) => void
}

const useOrderTile = (order: Order): UseOrderTileResult => {
  const model = orderModel()
  const [isOrderExpanded, setIsOrderExpanded] = useState(false)

  const data = useMemo(() => {
    return {
      shouldCutOffTime: formatTimestampToTwelveHourTime(model.getShouldCutOffAt(order)),
      shouldCutOffDate: formatTimestampToOrdinalDate(model.getShouldCutOffAt(order)),
      orderDiscount: model.getDiscountForOrder(order),
      humanDeliveryDate: model.getHumanDeliveryDate(order),
      humanDeliveryDayTime: model.getHumanDeliveryDayTime(order),
      isAwaitingChoices: model.isOrderAwaitingChoices(order),
      isOrderEditable: model.isOrderEditable(order),
      isOrderDelivered: model.isOrderDelivered(order),
      orderPhaseContent: formatOrderPhaseToHumanReadable(
        model.getOrderPhase(order) as keyof typeof orderPhaseToHumanReadableMap,
      ),
      price: model.getPriceForOrder(order),
      recipeItems: model.getRecipes(order),
      productItems: model.getProducts(order),
      shippingAddress: model.getOrderShippingAddress(order),
      box: {
        numPortions: model.getOrderNumberOfPortions(order),
        numRecipes: model.getOrderNumberOfRecipes(order),
      },
      orderId: model.getOrderId(order),
    }
  }, [order, model])

  return {
    data,
    isOrderExpanded,
    setIsOrderExpanded,
  }
}

export { useOrderTile }
