import { Box, Color } from '@gousto-internal/citrus-react'
import React, { RefObject } from 'react'
import { Hero, LovedBy, PeekInTheBox, TrustPilot } from '../components'
import { ValueForMoney } from '../components/ValueForMoney/ValueForMoney'
import { useFetchRafCampaignDetails } from '../hooks/useFetchRafCampaignDetails'
import { LandingPageContent } from '../../../landing-page-content/src/LandingPageContent'
import type { Recipe } from '../../../landing-page-content/src/types/endlessVarietyCarousel'
import { PromoBanner, PromoBannerText } from '../components/PromoBanner'

export type RafLandingPageType = {
  useIsRafLandingPageExperimentEnabled: () => boolean
  onScrollHandler: ({
    heroRef,
    stickyCTARef,
    setIsSticky,
    setMaxHeight,
  }: {
    heroRef: RefObject<HTMLDivElement>
    stickyCTARef: RefObject<HTMLDivElement>
    setIsSticky: (isSticky: boolean) => void
    setMaxHeight: (isSticky: number) => void
  }) => void
  onSignupCTAClick: () => void
  recipes: Record<string, Recipe>
  signupCTA: string
  applyDiscount: () => void
  isPromoBannerVisible: boolean
  promoBannerText: string
}

export const RafLandingPage = ({
  onScrollHandler,
  useIsRafLandingPageExperimentEnabled,
  onSignupCTAClick,
  recipes,
  signupCTA,
  applyDiscount,
  isPromoBannerVisible,
  promoBannerText,
}: RafLandingPageType) => {
  const { firstBoxDiscount, secondBoxDiscount, giftYourFriendCredit } = useFetchRafCampaignDetails()

  const isRafLandingPageExperimentEnabled = useIsRafLandingPageExperimentEnabled()

  return (
    <Box paddingBottom={[10, 6]} bg={Color.White}>
      {isRafLandingPageExperimentEnabled && isPromoBannerVisible && (
        <PromoBanner applyDiscount={applyDiscount}>
          <PromoBannerText>{promoBannerText}</PromoBannerText>
        </PromoBanner>
      )}
      <Hero
        firstBoxDiscount={firstBoxDiscount}
        secondBoxDiscount={secondBoxDiscount}
        giftYourFriendCredit={giftYourFriendCredit}
        onScrollHandler={onScrollHandler}
        onCTAClick={onSignupCTAClick}
        signupCTA={signupCTA}
        isRafLandingPageExperimentEnabled={isRafLandingPageExperimentEnabled}
      />
      {isRafLandingPageExperimentEnabled ? (
        <LandingPageContent
          onSignupCTAClick={onSignupCTAClick}
          signupCTA={signupCTA}
          recipes={recipes}
        />
      ) : (
        <>
          <LovedBy />
          <ValueForMoney />
          <TrustPilot />
          <PeekInTheBox />
        </>
      )}
    </Box>
  )
}
