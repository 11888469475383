import React, { ReactNode } from 'react'

import { FooterContainer } from 'Footer'
import { Header } from 'Header'

type FullPageProps = {
  children: ReactNode
  route: {
    props: {
      footerType?: string
      footerIsOnLandingPage: boolean
      showHeaderWithoutNavLink?: boolean
    }
  }
}
export const FullPage = ({
  children,
  route: {
    props: { footerIsOnLandingPage, footerType, showHeaderWithoutNavLink = false },
  },
}: FullPageProps) => (
  <>
    <Header simple={showHeaderWithoutNavLink} />
    {children}
    <FooterContainer type={footerType} copyright isOnLandingPage={footerIsOnLandingPage} />
  </>
)
