import Immutable from 'immutable'
import { createSelector } from 'reselect'

export const getDiscountDuration = (offer) => offer.get('discountDuration', Immutable.fromJS({}))

export const getCreditFormatted = (offer) => offer.get('creditFormatted')
export const getOfferExpiry = (offer) => (offer.get('expiry') ? 'gold' : 'blue')
export const getYourFriendFirstBoxOffer = (offer) => offer.get('firstBoxDiscountFormatted')
export const getYourFriendFirstMonthOffer = (offer) => offer.get('firstMonthDiscountFormatted')

export const getDiscountDurationFormatted = createSelector(
  getDiscountDuration,
  (discountDuration) => discountDuration.get('formatted'),
)
