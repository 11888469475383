import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { useDoubleDeckerNav } from 'hooks/useDoubleDeckerNav'
import { useMenu } from 'routes/Menu/domains/menu'

import css from './CollectionItem.css'

type CollectionItemProps = {
  children: React.ReactNode
  className: string
  collectionId: string
  dataId: string
  element: React.RefObject<HTMLDivElement>
  identifier: string
  onClick: () => void
  slug: string
}

const onSpaceOrEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }

const CollectionItem = ({
  children,
  className,
  collectionId,
  dataId,
  element,
  identifier,
  onClick,
  slug,
}: CollectionItemProps) => {
  const { getRecipesForCollectionId } = useMenu()
  const count = getRecipesForCollectionId(collectionId).recipes.size
  const doubleDeckerExperimentEnabled = useDoubleDeckerNav()

  const countText = doubleDeckerExperimentEnabled ? `(${count})` : `${count}`

  return (
    <Box
      data-id={dataId}
      className={className}
      onClick={onClick}
      key={identifier}
      ref={element}
      data-slug={slug}
      tabIndex={0}
      role="button"
      onKeyDown={onSpaceOrEnter(onClick)}
    >
      {children}
      <span
        className={classnames({
          [css.count]: !doubleDeckerExperimentEnabled,
          [css.doubleDeckerCount]: doubleDeckerExperimentEnabled,
        })}
      >
        {countText}
      </span>
    </Box>
  )
}

export { CollectionItem }
