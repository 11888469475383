import { FormikErrors, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { regularExpressions, restrictedRegularExpressions } from 'validations/regularExpressions'

import { actionTypes } from 'actions/actionTypes'
import { basketPostcodeChange } from 'actions/basket'
import { trackUTMAndPromoCode } from 'actions/tracking'
import { checkoutClickContinueToPayment } from 'actions/trackingKeys'
import { userProspect } from 'actions/user'
import { AddressFields } from 'routes/Checkout/Components/PostcodeAddressFields'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'
import { phoneValidator } from 'routes/Checkout/checkoutFormUtils'
import { getDeliveryAddress, getName, getPhone } from 'routes/Checkout/checkoutSelectors'
import { getBasketPostcode } from 'selectors/basket'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'

type FormFields = {
  firstName: string
  lastName: string
  phone: string
  deliveryInstruction: string
  deliveryInstructionCustom: string
} & AddressFields

const NAME_MAX_LENGTH = 50
const POSTCODE_MIN_LENGTH = 5
const POSTCODE_MAX_LENGTH = 8

/**
 * https://gousto.atlassian.net/browse/TG-8602
 * Changed the max length for houseNo field from 35 to 70
 * This will allow some houseNo values coming from the postcode-lookup API
 * to be valid while the user input is still limited to max 35 characters.
 */
const HOUSE_NUMBER_MAX_LENGTH = 70
const STREET_MAX_LENGTH = 35
const TOWN_MAX_LENGTH = 32

const validate = (formValues: FormFields): FormikErrors<FormFields> => {
  const errors: FormikErrors<FormFields> = {}

  if (!formValues.firstName) {
    errors.firstName = 'First name is required'
  } else if (!regularExpressions.name.test(formValues.firstName)) {
    errors.firstName =
      "Please use only letters (a-z), hyphens (-), apostrophes (' and ‘) and European special characters."
  } else if (formValues.firstName.length > NAME_MAX_LENGTH) {
    errors.firstName = 'First name must be under 50 characters'
  }

  if (!formValues.lastName) {
    errors.lastName = 'Last name is required'
  } else if (!regularExpressions.name.test(formValues.lastName)) {
    errors.lastName =
      "Please use only letters (a-z), hyphens (-), apostrophes (' and ‘) and European special characters."
  } else if (formValues.lastName.length > NAME_MAX_LENGTH) {
    errors.lastName = 'Last name must be under 50 characters'
  }

  if (formValues.searchPostcode) {
    const invalidPostcode = formValues.searchPostcode.match(restrictedRegularExpressions.postcode)
    if (invalidPostcode) {
      errors.searchPostcode = `Postcode can't contain ${invalidPostcode}`
    } else if (formValues.searchPostcode.length < POSTCODE_MIN_LENGTH) {
      errors.searchPostcode = 'Postcode must be at least 5 characters'
    } else if (formValues.searchPostcode.length > POSTCODE_MAX_LENGTH) {
      errors.searchPostcode = 'Postcode must be under 8 characters'
    }
  } else {
    errors.searchPostcode = 'Postcode is required'
  }

  if (formValues.houseNo) {
    const invalidHouseNo = formValues.houseNo.match(restrictedRegularExpressions.houseNo)
    if (invalidHouseNo) {
      errors.houseNo = `House number can't contain ${invalidHouseNo}`
    } else if (formValues.houseNo.length > HOUSE_NUMBER_MAX_LENGTH) {
      errors.houseNo = `House number must be under ${HOUSE_NUMBER_MAX_LENGTH} characters`
    }
  } else {
    errors.houseNo = 'House number is required'
  }

  if (formValues.street) {
    const invalidStreet = formValues.street.match(restrictedRegularExpressions.houseNo)
    if (invalidStreet) {
      errors.street = `Street can't contain ${invalidStreet}`
    } else if (formValues.street.length > STREET_MAX_LENGTH) {
      errors.street = `Street must be under ${STREET_MAX_LENGTH} characters`
    }
  }

  if (formValues.town) {
    const invalidTown = formValues.town.match(restrictedRegularExpressions.town)
    if (invalidTown) {
      errors.town = `City can't contain ${invalidTown}`
    } else if (formValues.town.length > TOWN_MAX_LENGTH) {
      errors.town = `City must be under ${TOWN_MAX_LENGTH} characters`
    }
  } else {
    errors.town = 'City is required'
  }

  const phoneError = phoneValidator(formValues.phone)
  if (phoneError) errors.phone = phoneError

  return errors
}

export const useDeliveryDetailsForm = (onStepChange: (stepId: CheckoutStepIds) => void) => {
  const dispatch = useDispatch()

  const { firstName, lastName } = useSelector(getName)
  const postcode = useSelector(getBasketPostcode)
  const deliveryAddress = useSelector(getDeliveryAddress)
  const phone = useSelector(getPhone)

  const deliveryInstruction = useSelector(getDeliveryDetailsInstructions)
  const deliveryInstructionCustom = useSelector(getDeliveryDetailsInstructionsCustom)

  const saveFormFieldsToStore = (formValues: FormFields) => {
    dispatch({
      type: actionTypes.CHECKOUT_SET_NAME,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    })
    dispatch({
      type: actionTypes.CHECKOUT_SET_DELIVERY_ADDRESS,
      postcode: formValues.postcode,
      houseNo: formValues.houseNo,
      street: formValues.street,
      town: formValues.town,
      county: formValues.county,
      udprn: formValues.udprn,
    })
    dispatch({
      type: actionTypes.CHECKOUT_SET_PHONE,
      phone: formValues.phone,
    })
    dispatch({
      type: actionTypes.CHECKOUT_SET_DELIVERY_INSTRUCTION,
      deliveryInstruction: formValues.deliveryInstruction,
      deliveryInstructionCustom: formValues.deliveryInstructionCustom,
    })
  }

  return useFormik<FormFields>({
    initialValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      searchPostcode: postcode ?? '',
      postcode: postcode ?? '',
      houseNo: deliveryAddress?.get('houseNo') ?? '',
      street: deliveryAddress?.get('street') ?? '',
      town: deliveryAddress?.get('town') ?? '',
      county: deliveryAddress?.get('county') ?? '',
      udprn: deliveryAddress?.get('udprn') ?? '',
      phone: phone ?? '',
      deliveryInstruction,
      deliveryInstructionCustom,
    },
    validateOnBlur: true,
    validateOnMount: true,
    validate,
    onSubmit: (formValues) => {
      saveFormFieldsToStore(formValues)
      dispatch(basketPostcodeChange(formValues.searchPostcode))
      dispatch(trackUTMAndPromoCode(checkoutClickContinueToPayment))
      dispatch(userProspect())
      onStepChange(CheckoutStepIds.PAYMENT)
    },
  })
}
