import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  JustifyContent,
  ResponsiveValue,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { CSSObject } from '@emotion/react'

import mobilePatternSVG from '../../assets/background/mobile-pattern.svg'
import desktopPatternSVG from '../../assets/background/desktop-pattern.svg'

export const Background = () => {
  return (
    <Container
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      width={'100%'}
      data-testid="background"
    />
  )
}

const Container = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(responsiveBackground(theme.color(Color.ColdGrey_800) as string), [
    'background',
  ]) as CSSObject),
}))

const responsiveBackground = (backgroundColor: string) =>
  [
    `${backgroundColor} no-repeat calc(50% - 16px) -32px url(${mobilePatternSVG})`,
    `${backgroundColor} no-repeat calc(50% - 32px) -32px url(${mobilePatternSVG})`,
    `${backgroundColor} no-repeat center -64px url(${mobilePatternSVG})`,
    `${backgroundColor} no-repeat calc(50% - 64px) -120px url(${desktopPatternSVG})`,
  ] as ResponsiveValue<string>
