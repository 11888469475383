import React from 'react'
import { browserHistory } from 'react-router'
import { Button, ButtonColorVariant } from '@gousto-internal/citrus-react'

import { trackSubscriptionSettingsChange } from '../../../tracking'

export const OrderABox = () => {
  const settingName = 'order_a_box'
  const handleClick = () => {
    trackSubscriptionSettingsChange({ settingName, action: 'cta_clicked' })()
    browserHistory.push('/menu')
  }

  return (
    <Button
      colorVariant={ButtonColorVariant.Secondary}
      onClick={handleClick}
      data-testid="order-a-box-cta"
      width="100%"
    >Order a one-off box
    </Button>
  )
}
