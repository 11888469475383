import React, { useEffect, useState } from 'react'

import { useModal } from '@gousto-internal/citrus-react'
import { Notification } from 'Notification'
import { useDispatch, useSelector } from 'react-redux'

import { trackOrderConflictModalSeen } from 'actions/myGousto'
import { getUserOrders } from 'selectors/user'
import { getUserOrderById } from 'utils/user'

import {
  OrderConflictConfirmationModal,
  ORDER_CONFLICT_MODAL_NAME,
} from './OrderConflictConfirmationModal'
import { hasOrderConflict } from './orderConflictSelector'

export interface NotificationParameters {
  state: 'error' | 'success'
  message: string
  body?: string
}

export const OrderConflictConfirmationModalContainer: React.FC = () => {
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const orderConflict = useSelector(hasOrderConflict)
  const [notificationParams, setNotificationParams] = useState<NotificationParameters | null>(null)
  const orders = useSelector(getUserOrders)
  const [existingOrder, setExistingOrder] = React.useState<null | any>(null)

  useEffect(() => {
    if (orderConflict && orders.size > 0) {
      const order = getUserOrderById(orderConflict.oldOrderId.toString(), orders)
      setExistingOrder(order)
    }
  }, [orderConflict, orders])

  useEffect(() => {
    if (existingOrder) {
      dispatch(trackOrderConflictModalSeen(existingOrder.get('id')))
      openModal(ORDER_CONFLICT_MODAL_NAME)
    }
  }, [dispatch, openModal, existingOrder])

  return (
    <>
      {existingOrder !== null ? (
        <OrderConflictConfirmationModal
          existingOrder={existingOrder}
          shouldShow={false}
          setNotificationParameters={setNotificationParams}
        />
      ) : null}
      {notificationParams !== null ? (
        <Notification
          state={notificationParams.state}
          message={notificationParams.message}
          body={notificationParams.body}
        />
      ) : null}
    </>
  )
}
