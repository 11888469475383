import React from 'react'

import { Color, Text, DisplayPosition, withCitrusTheme } from '@gousto-internal/citrus-react'

const StyledBox = withCitrusTheme('div', () => ({
  position: DisplayPosition.Relative,
  top: '-48px',
  '@media (max-width: 410px)': {
    top: '-8px',
  },
}))

export const DiscountTagAsteriskNote = () => (
  <StyledBox data-testid="discount_tag_asterisk_text">
    <Text size={[0, 2]} color={Color.ColdGrey_800}>
      *Offer expires 2 months after sign up
    </Text>
  </StyledBox>
)
