import { Theme, useTheme } from '@gousto-internal/citrus-react'
import { canUseWindow } from '@library/environment'
import { useEffect, useMemo, useState } from 'react'

/**
 * We strongly advise using the <Visible /> component from Citrus over the below hook.
 * In the majority of cases <Visible /> should be sufficient to conditionally render based on screensize.
 * https://staging-citrus-storybook.gousto.info/storybook/preview/main/index.html?path=/story/components-visible-docs--page
 */

export const DeviceType = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
}

const getDeviceType = (breakpointsNums: number[]): string => {
  if (!canUseWindow()) {
    return DeviceType.DESKTOP
  }
  const width = window.innerWidth

  if (width < breakpointsNums[0]) {
    return DeviceType.MOBILE
  } else if (width < breakpointsNums[1]) {
    return DeviceType.TABLET
  } else {
    return DeviceType.DESKTOP
  }
}

const transformBreakpoints = (theme: Theme) =>
  theme.tokens.breakpoints.map((breakpoint: string) => {
    return parseInt(breakpoint)
  })

export const useDeviceType = (): string => {
  const { theme } = useTheme()

  const breakpointsNums = useMemo(() => transformBreakpoints(theme), [theme])

  const [deviceType, setDeviceType] = useState(getDeviceType(breakpointsNums))

  useEffect(() => {
    if (canUseWindow()) {
      const onResize = () => {
        setDeviceType(getDeviceType(breakpointsNums))
      }
      window.addEventListener('resize', onResize)

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [breakpointsNums])

  return deviceType
}
