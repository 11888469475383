import React, { memo } from 'react'

import {
  AlignItems,
  Body1,
  Body2,
  Box,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  Icon,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'

import { formatOrderPrice } from 'utils/pricing'

import { boxDetailsAccordionTexts } from '../data'
import { YourGoustoBox } from './YourGoustoBox'

type HeaderContentProps = {
  isExpanded: boolean
  totalWithoutDiscount: string
  totalToPay?: string
  promoCodeValid: boolean
}

export const HeaderContent = memo(function HeaderContent({
  isExpanded,
  totalWithoutDiscount,
  totalToPay,
  promoCodeValid,
}: HeaderContentProps) {
  const { title } = boxDetailsAccordionTexts

  return (
    <Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
        paddingH={3}
        paddingBottom={2}
      >
        <Box paddingRight={1}>
          <Body2 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
            {title}
          </Body2>
        </Box>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          alignItems={AlignItems.Center}
        >
          {promoCodeValid && (
            <Body1 color={Color.ColdGrey_800}>
              <s>{`£${totalWithoutDiscount}`}</s>
            </Body1>
          )}
          <Space size={2} direction="horizontal" />
          <Body1 fontFamily={FontFamily.Bold} color={Color.Success_800}>
            {formatOrderPrice(totalToPay)}
          </Body1>
          <Space size={2} direction="horizontal" />
          <Icon name={isExpanded ? 'chevron_up' : 'chevron_down'} />
        </Box>
      </Box>
      <YourGoustoBox />
    </Box>
  )
})
