import { useEffect, useReducer, useRef, useState } from 'react'

import { menuSearchFetcher } from '@library/menu-service'
import { MenuSearch } from '@library/menu-service/queries/menuApiMenuSearch/types'
import { queueTrackingEvent } from '@library/tracking-integration'

type MenuSearchRequest = {
  deliveryDate: string
  menuId: string
  searchText: string
}

type MenuSearchResponse = {
  data: MenuSearch | null
  error: unknown
  isLoading: boolean
}

const initialResponse = {
  data: null,
  error: null,
  isLoading: false,
}

export const useMenuSearch = ({ deliveryDate, menuId, searchText }: MenuSearchRequest) => {
  const [response, setResponse] = useState<MenuSearchResponse>(initialResponse)
  const previousResponseData = useRef<MenuSearch | null>(null)
  const [retryCount, retry] = useReducer((count: number) => count + 1, 0)

  useEffect(() => {
    const fetchMenuSearch = async () => {
      if (!searchText) {
        previousResponseData.current = null
        setResponse(initialResponse)

        return
      }

      try {
        setResponse({ data: previousResponseData.current, error: null, isLoading: true })

        const data = await menuSearchFetcher({
          deliveryDate,
          menuId,
          numPortions: 2,
          search: searchText,
        })

        queueTrackingEvent<{
          action: 'search_query'
          properties: {
            menu_id: string
            query: string
            search_invocation_id: string
          }
        }>({
          action: 'search_query',
          properties: {
            menu_id: menuId,
            query: searchText,
            search_invocation_id: data.invocation_id,
          },
        })

        previousResponseData.current = data
        setResponse({ data, error: null, isLoading: false })
      } catch (error) {
        previousResponseData.current = null
        setResponse({ data: null, error, isLoading: false })
      }
    }
    fetchMenuSearch()
  }, [deliveryDate, menuId, retryCount, searchText])

  return { ...response, retry }
}
