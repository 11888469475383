import { Body1, Color, FontWeight, Icon, Space, Box, AlignItems, Display } from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'
import React from 'react'
import { ORDER_STATE_TO_COLOR_MAPPING } from 'routes/Account/AccountComponents/enum/enum'
import css from './OrderState.css'

const initcap = (word) => word.charAt(0).toUpperCase() + word.slice(1)
const toCamelCase = (str) => str.replace(/\b\w/g, chr => chr.toUpperCase()).replace(' ', '')
const mapResults = (state, numberOfTrackingUrls) => {
  switch (state) {
  case 'Dispatched':
    return numberOfTrackingUrls > 1 ? `Dispatched as ${numberOfTrackingUrls} boxes` : state
  case 'Confirmed':
    return "We're preparing your order"
  default:
    return state
  }
}

const OrderState = ({ orderState, numberOfTrackingUrls }) => {
  const state = initcap(orderState)
  const iconClass = `icon${toCamelCase(state)}`

  return (
    <div className={css.orderStateWrap} data-testing="orderState">
      { state === 'Confirmed' ?
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Box
          bg={Color.ColdGrey_600}
          display={Display.Flex}
          alignItems={AlignItems.Center}
          borderRadius="round"
          paddingH={0.5}
          width={16}
          height={16}
        >
          <Icon
            size={3}
            name="arrow_right"
            style={{ color: 'white' }}
          />
        </Box>
        : <span className={css[iconClass]} />}
      <Space size={2} direction="horizontal" />
      <Body1 fontWeight={FontWeight.Bold} color={ORDER_STATE_TO_COLOR_MAPPING[orderState]}>
        {mapResults(state, numberOfTrackingUrls)}
      </Body1>
    </div>
  )
}

OrderState.propTypes = {
  orderState: PropTypes.string,
  numberOfTrackingUrls: PropTypes.number,
}

OrderState.defaultProps = {
  orderState: '',
  numberOfTrackingUrls: 0,
}

export default OrderState
