import { connect } from 'react-redux'

import { getUserId } from '../../../selectors/user'
import { getAccessToken } from '../../../selectors/auth'
import { loadRefundAmount } from '../actions/loadRefundAmount'
import {
  getIsRefundOrComplaintPending,
  getIsRefundOrComplaintError,
  getSelectedIngredients,
} from '../selectors/selectors'
import { getCompensation, getIsMultiComplaints } from '../selectors/compensationSelectors'
import { trackIngredientsGetInTouchClick } from '../actions/getHelp'
import { createComplaint } from '../actions/createComplaint'

import { Refund } from './Refund'

const mapStateToProps = (state) => ({
  compensation: getCompensation(state),
  user: {
    id: getUserId(state),
    accessToken: getAccessToken(state),
  },
  isAnyPending: getIsRefundOrComplaintPending(state),
  isAnyError: getIsRefundOrComplaintError(state),
  isMultiComplaints: getIsMultiComplaints(state),
  numberOfIngredients: Object.keys(getSelectedIngredients(state)).length,
})

const RefundContainer = connect(mapStateToProps, {
  createComplaint,
  loadRefundAmount,
  trackIngredientsGetInTouchClick,
})(Refund)

export {
  RefundContainer
}
