import React from 'react'

import { Heading2, Text, Space } from '@gousto-internal/citrus-react'

type SectionHeaderProps = {
  title: string
  subtitle?: string
}

export const SectionHeader = ({ title, subtitle }: SectionHeaderProps) => (
  <>
    <Heading2 size={7}>{title}</Heading2>
    {subtitle && <Text size={1}>{subtitle}</Text>}
    <Space size={[3, 6]} />
  </>
)
