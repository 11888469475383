import React from 'react'

import { Box, Color, withCitrusTheme } from '@gousto-internal/citrus-react'

import { MenuRecipe } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { Basic } from 'routes/Menu/components/Detail/DetailIngredients/Ingredients/Ingredients'

import { Ingredients } from './Ingredients'

type DetailIngredientsProps = {
  recipe: MenuRecipe
  loading: boolean
  basics: Basic[]
}

const SectionPanel = withCitrusTheme(Box, (theme) => ({
  marginBottom: theme.spacing(4),
  backgroundColor: theme.color(Color.White),
}))

export const DetailIngredients = ({ recipe, loading, basics }: DetailIngredientsProps) => {
  const { ingredients } = recipe

  if (ingredients.length === 0) {
    return null
  }

  return (
    <SectionPanel data-testing="recipeDetailsIngredients">
      <Ingredients loading={loading} ingredients={ingredients} basics={basics} />
    </SectionPanel>
  )
}
