import { CSSObject } from '@emotion/styled'
import { Box, Color, withCitrusTheme } from '@gousto-internal/citrus-react'

import bgImageLarge1xJpg from '../../assets/VFM-large@1x.jpg'
import bgImageLarge2xJpg from '../../assets/VFM-large@2x.jpg'
import bgImageLarge1xWebp from '../../assets/VFM-large@1x.webp'
import bgImageLarge2xWebp from '../../assets/VFM-large@2x.webp'
import bgImageSmall1xJpg from '../../assets/VFM-small@1x.jpg'
import bgImageSmall2xJpg from '../../assets/VFM-small@2x.jpg'
import bgImageSmall1xWebp from '../../assets/VFM-small@1x.webp'
import bgImageSmall2xWebp from '../../assets/VFM-small@2x.webp'

export const ValueForMoneyContainer = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(
    [
      `65% bottom/639px no-repeat image-set(url(${bgImageSmall1xWebp}) 1x, url(${bgImageSmall2xWebp}) 2x, url(${bgImageSmall1xJpg}) 1x,
    url(${bgImageSmall2xJpg}) 2x)`,
      `bottom/contain no-repeat image-set(url(${bgImageSmall1xWebp}) 1x, url(${bgImageSmall2xWebp}) 2x, url(${bgImageSmall1xJpg}) 1x,
    url(${bgImageSmall2xJpg}) 2x)`,
      `30% 20%/cover no-repeat image-set(url(${bgImageLarge1xWebp}) 1x, url(${bgImageLarge2xWebp}) 2x, url(${bgImageLarge1xJpg}) 1x,
    url(${bgImageLarge2xJpg}) 2x)`,
    ],
    ['background'],
  ) as CSSObject),
}))

export const ConveniencesContainer = withCitrusTheme(Box, (theme) => ({
  borderRadius: '1rem',
  ...(theme.responsiveStyle(
    [theme.color(Color.Transparent), '#ecedefb3'],
    ['backgroundColor'],
  ) as CSSObject),
}))
