import React from 'react'

import { Heading4, Text, Box } from '@gousto-internal/citrus-react'

import css from './RecipeGridDescription.css'

export const RecipeGridDescription = ({ title, text }: { title: string; text: string }) => (
  <Box className={css.recipeGridDescription}>
    <Heading4>{title}</Heading4>
    <Text className={css.recipeGridDescriptionText}>{text}</Text>
  </Box>
)
