import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  JustifyContent,
  Text,
  TextAlign,
  Space,
  Display,
} from '@gousto-internal/citrus-react'

export const DiscountTag = () => (
  <Box display={Display.Flex} data-testid="discount_tag">
    <Box
      display={Display.Flex}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      width="56px"
      height="20px"
      bg={Color.Success_800}
      borderRadius={1}
    >
      <Text size={0} fontWeight={FontWeight.Bold} textAlign={TextAlign.Center} color={Color.White}>
        20% off
      </Text>
    </Box>
    <Space size={1} direction="horizontal" />
    <Box>
      <Text size={2} fontWeight={FontWeight.Bold} color={Color.Success_800}>
        on this box*
      </Text>
    </Box>
  </Box>
)
