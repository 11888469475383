import React, { KeyboardEvent } from 'react'

import styled from '@emotion/styled'
import { useTheme } from '@gousto-internal/citrus-react'

import { DiscountBarContainerProps } from './DiscountBarContainer'

export type StyledButtonProps = Omit<DiscountBarContainerProps, 'applyDiscount'> & {
  role: string
  tabIndex: number
  onClick: () => void
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
}

export const StyledButton = (props: StyledButtonProps) => {
  const { theme } = useTheme()
  const { isHidden, isSticky } = props
  const breakpointFromMedium = `@media (min-width: ${theme.tokens.breakpoints[0]})`
  const { Secondary_400: secondary400 } = theme.tokens.colors

  const Component = styled.div({
    display: isHidden ? 'none' : 'flex',
    backgroundColor: secondary400,
    width: '100%',
    minHeight: '3.75rem',
    padding: '1rem',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpointFromMedium]: {
      minHeight: '3rem',
      padding: '0',
      ...(isSticky
        ? {
            position: 'fixed',
            top: 0,
            zIndex: theme.tokens.zIndices.Sticky,
          }
        : {}),
    },
  })

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />
}
