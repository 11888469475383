export type BoxSummaryProps = {
  iconName: string
  itemTitle: string
}

type BoxTypes = {
  [key: number]: {
    title: string
  }
}

export const boxTypes: BoxTypes = {
  1: {
    title: '1 person box',
  },
  2: {
    title: '2 person box',
  },
  3: {
    title: '3 person box',
  },
  4: {
    title: '4 person box',
  },
  5: {
    title: '5 person box',
  },
}
