import React from 'react'
import { Route } from 'react-router'
import config from 'config'
import { RecipeFeedback } from '@features/recipe-feedback'

const route = <Route path={config.routes.client.rateMyRecipes} component={RecipeFeedback} />

export {
  route,
}
