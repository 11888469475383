import { useContext } from 'react'
import { PaymentCard3DSModalContext } from '../../PaymentCard3DSModal'

const usePaymentCard3DSModal = () => {
  const {
    cardConfirmationCompleted,
    cardConfirmationError,
    cardConfirmationRedirectURL,
    onCardConfirmationLoaded,
    setCardConfirmationRedirectURL,
    setCardConfirmationError,
    reset,
  } = useContext(PaymentCard3DSModalContext)

  return {
    cardConfirmationCompleted,
    cardConfirmationError,
    cardConfirmationRedirectURL,
    onCardConfirmationLoaded,
    setCardConfirmationError,
    setCardConfirmationRedirectURL,
    reset,
  }
}

export { usePaymentCard3DSModal }
