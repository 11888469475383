import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'

import routes from 'config/routes'
import {Box, Text, FontWeight, Display, Space, JustifyContent, Bold, Color, Icon, IconVariant, AlignItems } from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'
import OrderRecipeBox from './OrderRecipeBox'

const OrderRecipes = ({
  recipes,
  orderId,
  orderState,
  whenCutoff,
  portionsCount,
  maxNumRecipes,
}) => {
  const onCTAClick = useCallback(() => {
    browserHistory.push(`${routes.client.menu}/${orderId}`)
  }, [orderId])

  return (
    <div>
      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Text fontWeight={FontWeight.Bold}>Chosen recipes</Text>
        {['menu open', 'recipes chosen'].indexOf(orderState) > -1 ? (
          <Box
            display={Display.Flex}
            role="button"
            alignItems={AlignItems.Center}
            onClick={onCTAClick}
            data-testid="order-recipes-button"
          >
            <Text size={1} color={Color.Secondary_400}>{orderState === 'recipes chosen' ? 'Edit recipes' : 'Choose recipes'}</Text>
            <Space size={1} direction="horizontal" />
            <Icon size={5} name="edit" variant={IconVariant.Actionable} />
          </Box>
        ) : null}
      </Box>
      <Space size={4} direction="vertical" />
      <OrderRecipeBox
        recipes={recipes}
        orderState={orderState}
        portionsCount={portionsCount}
        maxNumRecipes={maxNumRecipes}
      />
      {orderState === 'menu open' ? (
        <Box paddingV={5} data-testid="order-recipes-information">
          <Text fontWeight={FontWeight.Bold}>
            You haven&apos;t chosen any recipes yet.
          </Text>
          <Space size={3} direction="vertical" />
          <Text>
            If you do not choose by
            {' '}
            <Bold>{whenCutoff}</Bold>
            , Gousto will send you a selection of recipes based on your subscription settings.
          </Text>
        </Box>
      ) : null}
    </div>
  )
}

OrderRecipes.propTypes = {
  recipes: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      recipeImage: PropTypes.string,
      recipeTitle: PropTypes.string,
    })
  ),
  orderId: PropTypes.string,
  orderState: PropTypes.string,
  whenCutoff: PropTypes.string,
  portionsCount: PropTypes.string,
  maxNumRecipes: PropTypes.number,
}

OrderRecipes.defaultProps = {
  recipes: Immutable.List([]),
  orderId: '',
  orderState: '',
  whenCutoff: '',
  portionsCount: '2',
  maxNumRecipes: 4,
}

export default OrderRecipes
