import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  Icon,
  JustifyContent,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

const CustomInput = withCitrusTheme('input', (theme) => ({
  backgroundColor: theme.color(Color.NeutralGrey_50),
  border: 'none',
  height: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',

  '&:disabled, &:disabled:hover, &:disabled:focus, &:focus': {
    outline: 'none',
  },

  '&::placeholder': {
    color: theme.color(Color.ColdGrey_600),
    opacity: 1,
  },
}))

type SearchInputProps = {
  onChange: (value: string) => void
  value: string
}

export const SearchInput = ({ onChange, value }: SearchInputProps) => (
  <Box
    alignItems={AlignItems.Center}
    bg={Color.NeutralGrey_50}
    borderRadius={1.5}
    data-testid="search-input"
    display={Display.Flex}
    height="40px"
    justifyContent={JustifyContent.SpaceBetween}
    paddingH={4}
  >
    <Icon name="search" size={6} />
    <CustomInput
      aria-label="Search the menu"
      autoFocus
      onChange={(e) => onChange(e.target.value)}
      placeholder="Search the menu"
      type="text"
      value={value}
    />
  </Box>
)
