import React from 'react'

import {
  AlignItems,
  AspectRatio,
  Bold,
  BorderStyle,
  Box,
  Color,
  Display,
  FontWeight,
  GridDisplay,
  Icon,
  IconVariant,
  Image,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import type { BoxPrice } from 'routes/Account/MyGousto/Header/NextOrder/utils/calculateBestBoxPrice'
import { formatPrice } from 'utils/format'
import timeFormat from 'utils/timeFormat'

import type { OrderState } from '../../../types'
import { ORDER_STATE_TO_COLOR_MAPPING } from '../enum/enum'

interface ImageSource {
  // eslint-disable-next-line react/no-unused-prop-types
  src?: string
  // eslint-disable-next-line react/no-unused-prop-types
  alt: string
}

interface OrderDetailsProps {
  deliveryDate: Date
  recipeImages?: ImageSource[]
  price?: number
  bestAvailablePrice?: BoxPrice
  orderState?: OrderState
  maxRecipesCount?: number
  deliveryStartTime?: string
  deliveryEndTime?: string
}

/**
 * Default number of recipe slots rendered in a single row
 */
const DEFAULT_MAX_RECIPE_NUMBER = 4

const RecipeImageBox = ({ src, alt }: ImageSource) => (
  <AspectRatio ratio={1}>
    {src ? (
      <Image src={src} alt={alt} />
    ) : (
      <Box borderStyle={BorderStyle.Dashed} borderColor={Color.ColdGrey_400} borderWidth={0.5}>
        {' '}
      </Box>
    )}
  </AspectRatio>
)

const OrderDetails: React.FC<OrderDetailsProps> = (props: OrderDetailsProps) => {
  const {
    deliveryDate,
    deliveryStartTime,
    deliveryEndTime,
    orderState,
    price,
    bestAvailablePrice,
    recipeImages = [],
    maxRecipesCount = DEFAULT_MAX_RECIPE_NUMBER,
  } = props
  const formattedPrice = formatPrice(price)
  const formattedDeliveryDate = timeFormat(deliveryDate, 'day')
  const formattedStartTime = timeFormat(deliveryStartTime, 'hour')
  const formattedEndTime = timeFormat(deliveryEndTime, 'hour')
  const limitedRecipeImages = recipeImages.slice(0, maxRecipesCount)

  return (
    <>
      {/* Delivery date & Price */}
      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Text fontWeight={FontWeight.Bold} data-testid="delivery-date">
          {formattedDeliveryDate}
        </Text>
        {price ? (
          <Text fontWeight={FontWeight.Bold} data-testid="price">
            {formattedPrice}
          </Text>
        ) : null}
      </Box>

      {/* Order state */}
      {orderState ? (
        <Text
          capitalise
          size={1}
          fontWeight={FontWeight.Bold}
          color={ORDER_STATE_TO_COLOR_MAPPING[orderState]}
          data-testid="order-state"
        >
          {orderState === 'confirmed' ? "We're preparing your order" : orderState}
        </Text>
      ) : null}

      {/* Delivery Time */}
      {deliveryStartTime && deliveryEndTime ? (
        <Text size={1} data-testid="delivery-time">
          {`${formattedStartTime} - ${formattedEndTime}`}
        </Text>
      ) : null}

      {/* Best price per serving messaging */}
      {orderState === 'menu open' && bestAvailablePrice ? (
        <>
          <Space size={3} />
          <Box
            bg={Color.Success_50}
            display={Display.Flex}
            justifyContent={JustifyContent.Center}
            alignItems={AlignItems.Center}
            paddingV={1}
            paddingH={2}
            style={{
              borderRadius: '60px',
              width: 'fit-content',
            }}
          >
            <Icon name="offer_pound" variant={IconVariant.Confirmation} size={6} />
            <Space size={2} direction="horizontal" />
            <Text size={2}>
              Add{' '}
              <Bold color={Color.Success_800}>{bestAvailablePrice.numberOfRecipes} recipes</Bold>{' '}
              for <Bold>£{bestAvailablePrice.pricePerPortion} a portion</Bold>
            </Text>
          </Box>
        </>
      ) : null}

      <Space size={3} />

      {/* Recipes */}
      <Box
        gridTemplateColumns={`repeat(${maxRecipesCount}, 1fr)`}
        display={GridDisplay.Grid}
        gap={2}
        data-testid="recipe-list"
      >
        {limitedRecipeImages.map(({ src, alt }: ImageSource, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <RecipeImageBox key={`${src}-${i}`} src={src} alt={alt} />
        ))}
        {Array.from(new Array(maxRecipesCount - limitedRecipeImages.length), (_, i: number) => (
          <RecipeImageBox key={i} alt="Recipe image" />
        ))}
      </Box>
      <Space size={3} />
    </>
  )
}

export { OrderState, OrderDetails }
