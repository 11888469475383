import React from 'react'

import { Box, Text, Space, FontFamily, Checkbox, Color } from '@gousto-internal/citrus-react'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'

import { PostcodeAddressFields } from '../../PostcodeAddressFields'
import { checkoutPaymentConfig } from '../config'
import { FormFields } from '../useAddressDetailsForm'

export const CheckoutBillingAddress = () => {
  const { values, setFieldValue } = useFormikContext<FormFields>()
  const {
    checkoutBillingAddress: { header, sameBillingAddressCheckbox },
  } = checkoutPaymentConfig

  const dispatch = useDispatch()

  const handleBillingAddressChange = () => {
    setFieldValue('isBillingAddressDifferent', !values.isBillingAddressDifferent)

    dispatch({
      type: actionTypes.PAYMENT_BILLING_ADDRESS_DIFFERENT,
      isBillingAddressDifferent: !values.isBillingAddressDifferent,
    })
  }

  return (
    <>
      <Box>
        <Box paddingV={2}>
          <Text fontFamily={FontFamily.Bold} size={2}>
            {header}
          </Text>
        </Box>
        <Checkbox
          id="checkoutBillingAddress"
          color={Color.Secondary_400}
          checked={!values.isBillingAddressDifferent}
          data-testid="checkoutBillingAddressToggle"
          onChange={handleBillingAddressChange}
        >
          {sameBillingAddressCheckbox}
        </Checkbox>
        {values.isBillingAddressDifferent && (
          <>
            <Space size={3} direction="vertical" />
            <PostcodeAddressFields />
          </>
        )}
      </Box>
      <Space size={4} direction="vertical" />
    </>
  )
}
