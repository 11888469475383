import { composeFetch } from '@library/http'
import { addPath, auth, composeRequest, setMethod, setServiceUrl } from '@library/http/requests'
import { extractOrderResponseV2 } from './deserialisers'

const restoreCancelledOrder = composeFetch(
  composeRequest<string | number>(
    setServiceUrl('order', 2),
    addPath((orderId) => `orders/${orderId}/restore`),
    setMethod('POST'),
    auth,
  ),
  extractOrderResponseV2,
)

export { restoreCancelledOrder }
