export const ResourceType = {
  Box: 'box',
  DeliverySlot: 'delivery-slot',
  DeliveryDay: 'delivery-day',
  DaySlotLeadTime: 'day-slot-lead-time',
  DeliveryTariff: 'delivery-tariff',
  Ingredient: 'ingredient',
  Order: 'order',
  Period: 'period',
  Product: 'product',
  Recipe: 'recipe',
  ShippingAddress: 'shipping-address',
}
