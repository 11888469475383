import React, { PropsWithChildren } from 'react'

import { Box, Accordion, Color } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { checkoutClickHideOrderSummary, checkoutClickShowOrderSummary } from 'actions/trackingKeys'
import { useIsCheckoutBoxTangibilityV3Enabled } from 'routes/Checkout/hooks'

import { HeaderContent as VariantHeaderContent } from '../CheckoutBoxTangibility'
import { HeaderContent } from './HeaderContent/HeaderContent'
import { titleConfig } from './HeaderContent/data'

type ExpandableBoxSummaryProps = PropsWithChildren<{
  totalToPay: string
  totalWithoutDiscount: string
  promoCodeValid?: boolean
}>

const renderAccordionHeader = (
  totalToPay: string,
  totalWithoutDiscount: string,
  promoCodeValid: boolean,
) => {
  const AccordionHeader = (isExpanded: boolean) => {
    const isCheckoutBoxTangibilityV3Enabled = useIsCheckoutBoxTangibilityV3Enabled()

    return isCheckoutBoxTangibilityV3Enabled ? (
      <VariantHeaderContent
        isExpanded={isExpanded}
        totalToPay={totalToPay}
        totalWithoutDiscount={totalWithoutDiscount}
        promoCodeValid={promoCodeValid}
      />
    ) : (
      <HeaderContent
        isExpanded={isExpanded}
        totalToPay={totalToPay}
        totalWithoutDiscount={totalWithoutDiscount}
        promoCodeValid={promoCodeValid}
      />
    )
  }

  return AccordionHeader
}
export const ExpandableBoxSummary = ({
  children,
  totalToPay,
  totalWithoutDiscount,
  promoCodeValid = false,
}: ExpandableBoxSummaryProps) => {
  const dispatch = useDispatch()
  const sendSnowplowEventHandler = (isExpanded: boolean) => {
    dispatch(
      trackUTMAndPromoCode(
        isExpanded ? checkoutClickShowOrderSummary : checkoutClickHideOrderSummary,
      ),
    )
  }
  const titleTotalToPay = totalToPay || titleConfig.pricePlaceholder

  return (
    <Box bg={Color.White}>
      <Accordion
        id="expandable-box-summary"
        onChange={(isExpanded) => sendSnowplowEventHandler(isExpanded)}
        title={renderAccordionHeader(titleTotalToPay, totalWithoutDiscount, promoCodeValid)}
      >
        {children}
      </Accordion>
    </Box>
  )
}
