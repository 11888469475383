import React from 'react'

import {
  AlignItems,
  Box,
  Body2,
  BorderStyle,
  Col,
  Color,
  Display,
  Grid,
} from '@gousto-internal/citrus-react'

import { cookieCategories, cookieUsageDetails } from './tableData'

function TableRow({ children, last }: { children: React.ReactNode; last?: boolean }) {
  return (
    <Grid
      role="row"
      borderColor={Color.ColdGrey_800}
      borderBottomWidth={last ? 0.5 : 0}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
    >
      {children}
    </Grid>
  )
}

function RowHeader({ children }: { children: string }) {
  return (
    <Col
      alignItems={AlignItems.Center}
      borderColor={Color.ColdGrey_800}
      borderRightWidth={0.5}
      borderStyle={BorderStyle.Solid}
      display={Display.Flex}
      bg={Color.Secondary_300}
      paddingH={2}
      paddingV={2}
      role="rowheader"
      size={4}
    >
      <Body2 color={Color.White} style={{ wordBreak: 'break-word' }}>
        {children}
      </Body2>
    </Col>
  )
}

function ColumnHeader({ children, last }: { children: string; last?: boolean }) {
  return (
    <Col
      alignItems={AlignItems.Center}
      borderColor={Color.ColdGrey_800}
      borderRightWidth={last ? 0 : 0.5}
      borderStyle={BorderStyle.Solid}
      display={Display.Flex}
      bg={Color.Secondary_300}
      paddingH={2}
      paddingV={2}
      role="columnheader"
      size={4}
    >
      <Body2 color={Color.White}>{children}</Body2>
    </Col>
  )
}

function TableCell({
  children,
  size,
  borderRight,
}: {
  children: string
  size: 4 | 8
  borderRight?: boolean
}) {
  return (
    <Col
      alignItems={AlignItems.Center}
      display={Display.Flex}
      paddingH={2}
      paddingV={2}
      role="cell"
      size={size}
      borderColor={Color.ColdGrey_800}
      borderRightWidth={borderRight ? 0.5 : 0}
      borderStyle={BorderStyle.Solid}
    >
      <Body2 style={{ wordBreak: 'break-word' }}>{children}</Body2>
    </Col>
  )
}

export function CookieCategoriesTable() {
  return (
    <Box role="table">
      {cookieCategories.map(({ category, description }, index) => {
        const last = index === cookieCategories.length - 1

        return (
          <TableRow key={category} last={last}>
            <RowHeader>{category}</RowHeader>
            <TableCell size={8}>{description}</TableCell>
          </TableRow>
        )
      })}
    </Box>
  )
}

export function CookiesUsageTable() {
  const { colHeaders, rows } = cookieUsageDetails

  return (
    <Box role="table">
      <TableRow>
        {colHeaders.map((header, index) => {
          const last = index === colHeaders.length - 1

          return (
            <ColumnHeader key={header} last={last}>
              {header}
            </ColumnHeader>
          )
        })}
      </TableRow>
      {rows.map(([purpose, category, examples], index) => {
        const last = index === rows.length - 1

        return (
          <TableRow key={purpose} last={last}>
            <TableCell size={4} borderRight>
              {purpose}
            </TableCell>
            <TableCell size={4} borderRight>
              {category}
            </TableCell>
            <TableCell size={4}>{examples}</TableCell>
          </TableRow>
        )
      })}
    </Box>
  )
}
