import styled from '@emotion/styled'

// FYI: can't use just block because can not find the way to pass pointer cursor
export const ExpandContainerWrapper = styled.div(() => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '0.5rem',
  gap: '0.5rem',
}))
