import React, { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import {
  FieldValidationMessage,
  FormFieldStatus,
  Select,
  Space,
} from '@gousto-internal/citrus-react'
import type { DeliveryPoint } from '@library/api-postcode-lookup'

interface DeliveryPointsDropdownProps {
  addresses: DeliveryPoint[]
  onAddressSelected: (value: DeliveryPoint) => void
}

const formatDeliveryPoint = (point: DeliveryPoint): string =>
  [point.line1, point.line2, point.line3]
    .filter((x) => typeof x !== 'undefined')
    .map((x) => x.trim().toUpperCase())
    .join(' ')
    .trim()

const keyIn = (object: Record<string, string>) => (key: string) => key in object

const AddressLookupDropdown = ({ addresses, onAddressSelected }: DeliveryPointsDropdownProps) => {
  const { errors } = useFormikContext()
  const onAddressChanged = useCallback(
    async (e: React.SyntheticEvent<HTMLSelectElement>) => {
      const foundDeliveryPoint = addresses.find(
        (deliveryPoint: DeliveryPoint) => deliveryPoint.udprn === e.currentTarget.value,
      )

      if (!foundDeliveryPoint) {
        throw new Error('Address line is not found')
      }

      const { line1, line2, line3, town, county, postcode, udprn } = foundDeliveryPoint
      const addressEntry = {
        line1,
        line2,
        line3,
        town,
        postcode,
        county,
        udprn,
      }

      onAddressSelected(addressEntry)
    },
    [addresses, onAddressSelected],
  )
  const options = useMemo(
    () =>
      addresses.map((point: DeliveryPoint) => (
        <option key={point.udprn} value={point.udprn}>
          {formatDeliveryPoint(point)}
        </option>
      )),
    [addresses],
  )
  const hasRelevantErrors = ['line1', 'line2', 'line3', 'town', 'postcode', 'county'].some(
    keyIn(errors),
  )

  if (!addresses.length) {
    return null
  }

  return (
    <>
      <Select
        id="delivery-points-select"
        data-testid="address-lookup-dropdown"
        onChange={onAddressChanged}
        status={hasRelevantErrors ? FormFieldStatus.Error : undefined}
      >
        <option>Please select your address</option>
        {options}
      </Select>
      {hasRelevantErrors && (
        <>
          <Space size={2} />
          <FieldValidationMessage>Please select an address</FieldValidationMessage>
        </>
      )}
    </>
  )
}

export { AddressLookupDropdown }
