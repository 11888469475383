import Immutable from 'immutable'
import { push } from 'react-router-redux'
import { AnyAction, Dispatch } from 'redux'

import { actionTypes } from 'actions/actionTypes'
import { basketPostcodeChange } from 'actions/basket'
import loginActions from 'actions/login'
import { menuLoadBoxPrices } from 'actions/menu'
import { redirect } from 'actions/redirect'
import tempActions from 'actions/temp'
import { trackUTMAndPromoCode } from 'actions/tracking'
import {
  completeWizardPostcode,
  clickSeeThisWeeksMenu,
  signupSocialBelongingBanner,
  signupCheckAccountNewCustomer,
  signupCheckAccountExistingCustomer,
  signupApplyVoucher,
  deliveryInstructionDetailsClick,
  promoCodeInvalid,
} from 'actions/trackingKeys'
import { fetchCountByPostcode } from 'apis/signup'
import routes, { client } from 'config/routes'
import { signupConfig } from 'routes/Signup/signupConfig'
import { stepByName } from 'routes/Signup/signupUtils'
import { getAccessToken } from 'selectors/auth'
import { getDeliveryDetailsInstructions } from 'selectors/deliveryDetails'
import { getUTMAndPromoCode } from 'selectors/tracking'
import logger from 'utils/logger'

import { GetState } from './models/SignupStore'

export function signupStepsReceive(stepNames: string) {
  return {
    type: actionTypes.SIGNUP_STEPS_RECEIVE,
    stepNames,
  }
}

export function signupSetStep(step: any) {
  return (dispatch: Dispatch, getState: GetState) => {
    const signupState = getState().signup
    const stepNames = signupState.getIn(['wizard', 'stepNames'])

    if (step) {
      const newStepNumber = stepNames.findIndex((stepName: string) => stepName === step.get('name'))
      const isLastStep = newStepNumber === stepNames.size - 1

      dispatch({
        type: actionTypes.SIGNUP_STEP_SET,
        currentStepName: step.get('name'),
        currentStepNumber: newStepNumber,
        isLastStep,
        trackingData: {
          type: actionTypes.SIGNUP_STEP_SET,
          step: step.get('slug'),
          stepName: step.get('name'),
        },
      })
    }
  }
}

export function signupTracking() {
  return (dispatch: Dispatch, getState: GetState) => {
    const { basket } = getState()
    const postcode = basket.get('postcode')
    const numAdults = basket.get('numAdults')
    const numPortions = basket.get('numPortions')
    const date = basket.get('date')
    const slotId = basket.get('slotId')

    dispatch({
      type: actionTypes.SIGNUP_TRACKING,
      trackingData: {
        actionType: actionTypes.SIGNUP_TRACKING,
        postcode,
        numAdults,
        numPortions,
        date,
        slotId,
      },
    })
  }
}

/**
 * Redirects application to Signup exact step.
 */
export function signupNextStep(stepName: string | number, isMenuPersonalisationEnabled = false) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const step = stepByName(stepName)
    if (step) {
      const state = getState()
      const signupState = state.signup
      const isCurrentlyTheLastStep = signupState.getIn(['wizard', 'isLastStep'])
      const lastWizardStep = signupState.getIn(['wizard', 'stepNames']).last()
      const slug = step.get('slug')
      if (isCurrentlyTheLastStep && (step.get('name') === lastWizardStep || !slug)) {
        dispatch(signupTracking() as unknown as AnyAction)

        const path = isMenuPersonalisationEnabled
          ? routes.client.menu
          : `${routes.client.signup}/${signupConfig.sellThePropositionPageSlug}`

        return dispatch(redirect(path))
      }

      try {
        const { search } = getState().routing.locationBeforeTransitions
        dispatch(push(`${client.signup}/${slug}${search}`))
      } catch (e) {
        dispatch(push(`${client.signup}/${slug}`))
      }
    }

    return null
  }
}

export function signupGoToMenu() {
  return (dispatch: Dispatch) => {
    dispatch(trackUTMAndPromoCode(clickSeeThisWeeksMenu) as unknown as AnyAction)
    dispatch(redirect(routes.client.menu))
    dispatch({ type: actionTypes.WIZARD_SEEN })
  }
}

export const trackSignupWizardAction =
  (type: any, additionalData = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { promoCode, UTM } = getUTMAndPromoCode(getState())

    dispatch({
      type,
      trackingData: {
        actionType: type,
        ...UTM,
        promoCode,
        ...additionalData,
      },
    })
  }

export const signupSetSocialBelongingOptions = (socialBelongingOptions: any) => ({
  type: actionTypes.SIGNUP_SET_SOCIAL_BELONGING_OPTIONS,
  ...socialBelongingOptions,
})

export const signupGetCountByPostcode =
  (postcode: string) => async (dispatch: Dispatch, getState: GetState) => {
    let socialBelongingOptions = {}
    try {
      const accessToken = getAccessToken(getState())
      const { data } = await fetchCountByPostcode(accessToken, { postcode })
      socialBelongingOptions = data
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error('No users found in current area')
    } finally {
      dispatch(signupSetSocialBelongingOptions(socialBelongingOptions))
    }
  }

export function signupChangePostcode(postcode: string, nextStepName: string | number) {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(basketPostcodeChange(postcode) as unknown as AnyAction)

    if (!getState().error.get(actionTypes.BOXSUMMARY_DELIVERY_DAYS_RECEIVE, false)) {
      dispatch(
        trackSignupWizardAction(completeWizardPostcode, { postcode }) as unknown as AnyAction,
      )
      signupNextStep(nextStepName)(dispatch, getState)
    }
  }
}

export const signupChangeTempPostcode = (postcode: string) =>
  tempActions.temp('postcode', postcode.toUpperCase())

export const signupDismissDiscountAppliedBar = () => ({
  type: actionTypes.SIGNUP_DISMISS_DISCOUNT_APPLIED_BAR,
})

export const signupShowDiscountAppliedBar = () => ({
  type: actionTypes.SIGNUP_SHOW_DISCOUNT_APPLIED_BAR,
})

export const trackSocialBelongingBannerAppearance =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState()
    const { promoCode, UTM } = getUTMAndPromoCode(state)
    const district = state.signup.getIn(['wizard', 'district'])
    const amountOfCustomers = state.signup.getIn(['wizard', 'amountOfCustomers'])

    dispatch({
      type: signupSocialBelongingBanner,
      trackingData: {
        actionType: signupSocialBelongingBanner,
        ...UTM,
        promo_code: promoCode,
        district,
        number_of_customers: amountOfCustomers,
      },
    })
  }

export const signupCheckAccountGoToBoxPrices = () => (dispatch: Dispatch) => {
  dispatch(menuLoadBoxPrices() as unknown as AnyAction)
  dispatch(trackUTMAndPromoCode(signupCheckAccountNewCustomer) as unknown as AnyAction)
  dispatch(redirect(`${routes.client.signup}/box-size`))
}

export const signupCheckAccountLogin = () => (dispatch: Dispatch) => {
  dispatch(trackUTMAndPromoCode(signupCheckAccountExistingCustomer) as unknown as AnyAction)

  const { loginVisibilityChange } = loginActions
  dispatch(loginVisibilityChange(true))
}

export const signupApplyVoucherGoToDeliveries = () => (dispatch: Dispatch) => {
  dispatch(trackUTMAndPromoCode(signupApplyVoucher) as unknown as AnyAction)
  dispatch(redirect(`${routes.client.myDeliveries}`))
}

export const trackDeliveryInstructionSelection = () => (dispatch: Dispatch, getState: GetState) => {
  const deliveryInstruction = getDeliveryDetailsInstructions(getState())

  dispatch({
    type: actionTypes.TRACKING,
    trackingData: {
      actionType: deliveryInstructionDetailsClick,
      selection: deliveryInstruction,
    },
  })
}

export const trackingPromoCodeInvalid = (error: any) => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: promoCodeInvalid,
    error,
  },
})

export const signupSetIsDietaryConfirmed =
  (isDietaryConfirmed: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.SIGNUP_SET_IS_DIETARY_CONFIRMED,
      isDietaryConfirmed,
    })
  }

export const signupSetDietaryPreferences =
  (dietaryPreferences: Immutable.List<string>) => (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.SIGNUP_SET_DIETARY_PREFERENCES,
      dietaryPreferences,
    })
  }

export const signupSetIsRecipeConfirmed = (isRecipeConfirmed: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SIGNUP_SET_IS_RECIPE_CONFIRMED,
    isRecipeConfirmed,
  })
}

export const signupSetRecipePreferences =
  (recipePreferences: Immutable.List<string>) => (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.SIGNUP_SET_RECIPE_PREFERENCES,
      recipePreferences,
    })
  }
