import React from 'react'

import Image from 'Image'
import { Col } from 'Page/Grid'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import placeholderSrc from 'media/images/product-placeholder.png'

import css from './RecipeCard.css'

const propTypes = {
  link: PropTypes.string,
  images: PropTypes.oneOfType([PropTypes.instanceOf(Immutable.List), PropTypes.string]).isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.bool.isRequired,
}

const defaultProps = {
  link: null,
  title: null,
}

const maxMediaSize = 400
const placeholderImage = <img className={css.image} src={placeholderSrc} alt="" />

const RecipeCard = ({ link, images, title, placeholder }) => (
  <Col col-xs-6 col-lg-2>
    <a href={link} className={css.cardContainer}>
      <div data-testId={placeholder ? 'cookbook-recipe-placeholder' : 'cookbook-recipe'} className={css.cardContent}>
        <Image
          media={images}
          title={title}
          maxMediaSize={maxMediaSize}
          className={css.image}
          placeholder={placeholderImage}
          lazy
        />
        {title && (
          <p className={css.title}>
            {title}
            &nbsp;
            <span className={css.arrowRight} />
          </p>
        )}
      </div>
    </a>
  </Col>
)

RecipeCard.propTypes = propTypes
RecipeCard.defaultProps = defaultProps

export { RecipeCard }
