import React from 'react'

import actions from 'actions'
import { connect } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { basketIsPortionSizeViewVisibleChange } from 'actions/basket'
import { boxSummaryVisibilityChange, boxSummaryNext } from 'actions/boxSummary'
import { useBasket } from 'routes/Menu/domains/basket'
import { usePricing } from 'routes/Menu/domains/pricing'
import { getUnavailableRecipeIds } from 'routes/Menu/selectors/basket'
import { getBrowserType } from 'selectors/browser'
import { isMobile } from 'utils/view'

import {
  getNumPortions,
  getBasketOrderId,
  getBasketRecipes,
  shouldShowBoxSummary,
} from '../../../../selectors/basket'
import { getMenuBrowseCTAShow } from '../../../../selectors/root'
import { BoxSummary } from './BoxSummary'

const mapStateToProps = (state) => ({
  isMobile: isMobile(getBrowserType(state)),
  numPortions: getNumPortions(state),
  recipes: getBasketRecipes(state),
  showDetails: state.boxSummaryShow.get('show'),
  menuRecipes: state.menuRecipes,
  menuService: state.menuService,
  orderId: getBasketOrderId(state),
  basketCheckedOut: state.pending.get(actionTypes.BASKET_CHECKOUT),
  basketCheckoutClicked: state.pending.get(actionTypes.BASKET_CHECKOUT_CLICKED),
  menuFetchPending: state.pending.get(actionTypes.MENU_FETCH_DATA),
  hasUnavailableRecipes: Boolean(getUnavailableRecipeIds(state).size),
  orderSaveError: state.error.get(actionTypes.ORDER_SAVE),
  shouldShowBoxSummary: shouldShowBoxSummary(state),
  shouldMenuBrowseCTAShow: getMenuBrowseCTAShow(state),
})

const BoxSummaryPure = (props) => {
  const { isPending, pricing } = usePricing()
  const { removeRecipe } = useBasket()

  return (
    <BoxSummary
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      pricingPending={isPending}
      removeRecipe={removeRecipe}
      totalPrice={pricing?.total} // used for data layer tracking
    />
  )
}

export const BoxSummaryContainer = connect(mapStateToProps, {
  basketIsPortionSizeViewVisibleChange,
  boxDetailsVisibilityChange: boxSummaryVisibilityChange,
  basketRestorePreviousValues: actions.basketRestorePreviousValues,
  boxSummaryNext,
})(BoxSummaryPure)
