import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import { fetchPayPalToken } from 'routes/Checkout/checkoutPaymentsApis'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import logger from 'utils/logger'

export const fetchPayPalClientToken: CheckoutActionCreator = () => async (dispatch) => {
  try {
    const { data } = await fetchPayPalToken()

    dispatch({
      type: actionTypes.PAYMENT_SET_PAYPAL_CLIENT_TOKEN,
      token: data.clientToken,
    })
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logger.error({
      message: `${actionTypes.PAYPAL_TOKEN_FETCH_FAILED} - ${(err as Error).message}`,
      errors: [err],
    })
    dispatch(
      trackCheckoutError(actionTypes.PAYPAL_TOKEN_FETCH_FAILED, true, 'fetchPayPalClientToken'),
    )
    dispatch(statusActions.error(actionTypes.PAYPAL_TOKEN_FETCH_FAILED, true))
  }
}
