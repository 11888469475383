import React from 'react'

import styled from '@emotion/styled'

import { AlternativeOptionItem } from '../AlternativeOptionItem'

import { cssRecipeList, cssRecipeListText, cssVariantsTitle } from './styles'
import { AlternativeOptions } from '../../../model/alternativeOptions/alternativeOptions'
import { queueTrackingEvent } from '@library/tracking-integration'

export type TrackVariantDisplay = {
  action: 'recipe_variant_action_sheet'
  view: string
}

type RecipeAlternativeOptionsProps = {
  recipeId: string
  /**
   * Optional Function to be called upon switching recipes.
   */
  onChangeCheckedRecipe: (_: { previousRecipeId: string; nextRecipeId: string }) => void
  selectVariant: ({
    variantId,
    variantOutOfStock,
  }: {
    variantId: string
    variantOutOfStock: boolean
  }) => void
  alternativeOptions: AlternativeOptions
  isMobile?: boolean
}

const RecipeListDiv = styled.div(cssRecipeList)
const VariantsTitle = styled.h2(cssVariantsTitle)
const RecipeListText = styled.ul(cssRecipeListText)

export function RecipeAlternativeOptions({
  recipeId: currentRecipeId,
  onChangeCheckedRecipe,
  selectVariant,
  alternativeOptions,
  isMobile = false,
}: RecipeAlternativeOptionsProps) {
  // alternative options include the recipe itself
  const ALTERNATIVE_OPTIONS_STARTING_LENGTH = 1

  const hasAlternativeOptions = alternativeOptions.length > ALTERNATIVE_OPTIONS_STARTING_LENGTH

  if (hasAlternativeOptions) {
    queueTrackingEvent<TrackVariantDisplay>({
      action: 'recipe_variant_action_sheet',
      view: 'grid',
    })
  }

  const preventPropagation = (e: React.SyntheticEvent) => e.stopPropagation()

  if (!hasAlternativeOptions) {
    return null
  }

  return (
    <RecipeListDiv
      role="button"
      tabIndex={-1}
      onClick={preventPropagation}
      onKeyPress={preventPropagation}
    >
      {!isMobile && <VariantsTitle>Choose an option</VariantsTitle>}

      <RecipeListText>
        {alternativeOptions.map(({ recipeId, recipeName, isChecked, isOutOfStock, surcharge }) => (
          <AlternativeOptionItem
            key={recipeId}
            recipeId={recipeId}
            recipeName={recipeName}
            onChange={() => {
              selectVariant({ variantId: recipeId, variantOutOfStock: isOutOfStock })
              onChangeCheckedRecipe({ previousRecipeId: currentRecipeId, nextRecipeId: recipeId })
            }}
            isChecked={isChecked}
            isOutOfStock={isOutOfStock}
            surcharge={surcharge === undefined ? null : surcharge}
          />
        ))}
      </RecipeListText>
    </RecipeListDiv>
  )
}
