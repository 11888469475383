import { fetch } from 'utils/fetch'
import { endpoint } from '@library/endpoint'
import routes from 'config/routes'

const customersApi = {
  customerSignup,
}

export function customerSignup(accessToken, reqData) {
  const TIMEOUT = 50000

  return fetch(accessToken, `${endpoint('customers', 2)}${routes.customers.signup}`, reqData, 'POST', 'default', {}, TIMEOUT)
}

export const fetchReference = () => (
  fetch(null, `${endpoint('customers')}${routes.customers.reference}`, {}, 'GET')
)

export default customersApi
