import React from 'react'

import { BrandHeaderImage } from '../../../domains/brand/types'

import css from './WaveLinkHeader.css'

export type WaveLinkHeaderProps = {
  backgroundColor?: string
  color?: string
  headerImage?: BrandHeaderImage[]
  description: string
  link?: {
    collectionId?: string
    collectionSlug: string
  }
  onClick: () => void
}

const WaveLinkHeader = ({
  backgroundColor,
  color,
  headerImage,
  description,
  link,
  onClick,
}: WaveLinkHeaderProps) => (
  <div className={css.waveLinkHeaderContanier} data-testid="wave-link-header-container">
    <div
      className={css.waveLinkHeader}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
      data-testid="wave-link-header"
      style={{
        backgroundColor: `${backgroundColor}`,
      }}
    >
      <div className={css.arrowRightWrapper} data-testid="arrow-right-wrapper">
        <span className={css.arrowRight} style={{ color }} data-testid="arrow-right" />
      </div>
      <div style={{ color: `${color}` }} className={css.waveLinkInfo} data-testid="wave-link-info">
        {headerImage && headerImage[0] && (
          <img
            className={css.waveLinkTitleImage}
            src={headerImage[0].url}
            alt={link?.collectionSlug}
            style={{
              width: 200,
              height: 'auto',
              zIndex: 1,
            }}
            data-testid="wave-link-header-image"
          />
        )}
        <p className={css.waveLinkDescription} data-testid="wave-link-description">
          {description}
        </p>
      </div>
    </div>
  </div>
)

export { WaveLinkHeader }
