import { MenuRecipeImage } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { recipesConfig } from 'config/recipes'
import { DetailViewType } from 'routes/Menu/components/Detail/types'

type Crop = {
  url: string
  width: number
}

export function getMenuRecipeImage(imageUrls: Crop[], imageSize: number) {
  const imageSrc = imageUrls.reduce(
    (reducedImage, newImage) => {
      const image = reducedImage
      const newUrlWidth = parseInt(newImage.width.toString(), 10)

      if (newUrlWidth >= imageSize) {
        if (reducedImage.upper) {
          if (reducedImage.upper.width > newUrlWidth) {
            image.upper = newImage
          }
        } else {
          image.upper = newImage
        }
      } else if (reducedImage.lower) {
        if (reducedImage.lower.width < newUrlWidth) {
          image.lower = newImage
        }
      } else {
        image.lower = newImage
      }

      return image
    },
    {
      upper: null as Crop | null,
      lower: null as Crop | null,
    },
  )
  const outputImage = imageSrc.upper ? imageSrc.upper : imageSrc.lower

  return outputImage?.url
}

export function getFeaturedImage(
  images: MenuRecipeImage[] = [],
  view: DetailViewType,
  browserType: string,
) {
  const homepageImage = images.find((image) => image.type === 'homepage-image')
  const shouldUseHomepageImage = view === 'detail' && browserType === 'desktop'

  if (shouldUseHomepageImage && homepageImage !== undefined) {
    const homepageImageUrls = homepageImage.crops ?? []

    if (homepageImageUrls.length > 0) {
      return homepageImageUrls
    }
  }

  const moodImage = images.find((image) => image.type === 'mood-image')
  const moodImageUrls = moodImage ? moodImage.crops : null
  const firstImageUrls = images[0]?.crops ?? []
  const recipeImage = moodImageUrls || firstImageUrls

  return recipeImage
}

export function getRangeImages(images: MenuRecipeImage[] = []) {
  return images
    .filter((image) => recipesConfig.range.imageTypes.includes(image.type))
    .sort(
      (a, b) =>
        recipesConfig.range.imageTypes.indexOf(a.type) -
        recipesConfig.range.imageTypes.indexOf(b.type),
    )
}
