import React from 'react'

import { RootStateOrAny, useSelector } from 'react-redux'

import { useBrandHeadersInfo } from '../../../domains/brand'
import { MenuHeadersIncludesData } from '../../../domains/brand/types'
import { getCollectionsHeaders } from '../../../selectors/collections'
import { GradientInfoHeader } from '../GradientInfoHeader'
import { WaveLinkHeaderContainer } from '../LinkHeaderContainer'
import { useShouldRemoveMerchandisingBanner } from '../useShouldRemoveMerchandisingBanner'

const CollectionHeaderWrapper = () => {
  const { headers, collectionsPerMenu } = useBrandHeadersInfo()
  const collectionsHeaders = useSelector<RootStateOrAny, MenuHeadersIncludesData>((state) =>
    getCollectionsHeaders(headers, collectionsPerMenu)(state),
  )
  const removeMerchandisingBanner = useShouldRemoveMerchandisingBanner()

  if (!collectionsHeaders || removeMerchandisingBanner) {
    return null
  }

  const { gradientColor, backgroundColor, color, headerImage, image, description, link } =
    collectionsHeaders.attributes

  switch (collectionsHeaders.type) {
    case 'gradient-info-header':
      return (
        <GradientInfoHeader
          gradientColor={gradientColor}
          color={color}
          headerImage={headerImage}
          image={image}
          description={description}
        />
      )
    case 'wave-link-header':
      return (
        <WaveLinkHeaderContainer
          backgroundColor={backgroundColor}
          color={color}
          headerImage={headerImage}
          description={description}
          link={link}
        />
      )
    default:
      return null
  }
}

export { CollectionHeaderWrapper }
