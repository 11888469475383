import React from 'react'

import Immutable from 'immutable'
import PropTypes from 'prop-types'

import {
  useGetCurrentBoxSummaryView,
  BoxSummaryViews,
} from 'routes/Menu/hooks/useGetCurrentBoxSummaryView'

import { DeliverySlotContainer } from '../DeliverySlot'
import { DetailsContainer } from '../Details'
import { PortionSize } from '../PortionSize'
import { PostcodeContainer } from '../Postcode'

const BoxSummaryContent = (props) => {
  const {
    view = 'desktop',
    date,
    orderId,
    displayOptions,
    numPortions,
    recipes,
    portionChangeErrorModalHandler,
  } = props

  const boxSummaryCurrentView = useGetCurrentBoxSummaryView()

  let boxSummaryView

  switch (boxSummaryCurrentView) {
    case BoxSummaryViews.POSTCODE:
      boxSummaryView = <PostcodeContainer view={view} />
      break
    case BoxSummaryViews.DELIVERY_SLOT:
      boxSummaryView = <DeliverySlotContainer view={view} displayOptions={displayOptions} />
      break
    case BoxSummaryViews.PORTION_SIZE:
      boxSummaryView = <PortionSize />
      break
    case BoxSummaryViews.DETAILS:
      boxSummaryView = (
        <DetailsContainer
          view={view}
          displayOptions={displayOptions}
          date={date}
          orderId={orderId}
          numPortions={numPortions}
          basketRecipes={recipes}
          portionChangeErrorModalHandler={portionChangeErrorModalHandler}
        />
      )
      break
    default:
      boxSummaryView = null
      break
  }

  return <div>{boxSummaryView}</div>
}

BoxSummaryContent.propTypes = {
  displayOptions: PropTypes.instanceOf(Immutable.List).isRequired,
  view: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  numPortions: PropTypes.number.isRequired,
  recipes: PropTypes.instanceOf(Immutable.Map).isRequired,
  portionChangeErrorModalHandler: PropTypes.func.isRequired,
}

export { BoxSummaryContent }
