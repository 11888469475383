import React from 'react'

import { CSSObject } from '@emotion/react'
import {
  Body1,
  Heading2,
  Link,
  LinkProps,
  Space,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { cookieBanner } from '@gousto-internal/storage-consent-web-package'

import { CookieCategoriesTable, CookiesUsageTable } from './Table'

const OrderedList = withCitrusTheme('ol', () => ({
  counterReset: 'a',
  listStyleType: 'none',
}))

const ListItem = withCitrusTheme('li', (theme) => ({
  position: 'relative',
  '::before': {
    counterIncrement: 'a',
    content: 'counters(a, ".") "."',
    position: 'absolute',
    marginRight: '100%',
    right: '8px',
    color: theme.tokens.colors.ColdGrey_600,
    ...(theme.responsiveFont([4, null, 6]) as CSSObject),
  },
  ol: {
    counterReset: 'a',
  },
}))

function TextLink({ children, href, onClick, id }: React.PropsWithChildren<LinkProps>) {
  return (
    <Link href={href} target="_blank" onClick={onClick} id={id}>
      {children}
    </Link>
  )
}

export const CookiePolicyDetails = () => (
  <OrderedList>
    <ListItem>
      <Heading2 size={[5, null, 7]}>What are cookies?</Heading2>
      <Space size={4} />
      <OrderedList>
        <ListItem>
          <Body1>
            Cookies are small text files stored on your device when you visit certain web pages and
            are used to store and receive identifiers and other information on computers, phones and
            other devices.
          </Body1>
          <Space size={4} />
        </ListItem>
      </OrderedList>
    </ListItem>

    <ListItem>
      <Heading2 size={[5, null, 7]}>Cookies used by us</Heading2>
      <Space size={4} />
      <OrderedList>
        <ListItem>
          <Body1>We differentiate our cookies into four different categories:</Body1>
          <Space size={4} />
          <CookieCategoriesTable />
          <Space size={8} />
        </ListItem>

        <ListItem>
          <Body1>
            Why do we use cookies? The cookies that we use may change over time as we improve our
            products and services, we typically use them for the following purposes:
          </Body1>
          <Space size={4} />
          <CookiesUsageTable />
          <Space size={8} />
        </ListItem>
      </OrderedList>
    </ListItem>

    <ListItem>
      <Heading2 size={[5, null, 7]}>How to control and delete cookies</Heading2>
      <Space size={4} />
      <OrderedList>
        <ListItem>
          <Body1>
            In terms of how long cookies last, some are erased when you close the browser on our
            website, these are known as “Session Cookies”. Others stay longer and are saved onto
            your device so that they&apos;re there when you revisit our website, these are known as
            “Persistent Cookies”.
          </Body1>
        </ListItem>

        <ListItem>
          <Body1>
            Strictly necessary cookies are always on when you visit our website. On your first
            visit, we will ask you for your preferences. You can always change your mind and update
            your cookie preferences by clicking{' '}
            <TextLink onClick={() => cookieBanner.showSettings()} id="settings-link">
              here
            </TextLink>
            .
          </Body1>
        </ListItem>

        <ListItem>
          <Body1>
            In addition, when you use services via a browser, you can change your web browser&apos;s
            settings to update your cookies preferences. Each browser is different but usually these
            settings fall under the “options” or “preferences” menu. The links below provide
            information about cookie settings for the browsers supported by Gousto:
          </Body1>
          <ul>
            <li>
              <TextLink href="https://www.microsoft.com/en-us/edge/learning-center/how-to-manage-and-clear-your-cache-and-cookies">
                Microsoft Edge
              </TextLink>
            </li>
            <li>
              <TextLink href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
                Firefox
              </TextLink>
            </li>
            <li>
              <TextLink href="https://support.google.com/chrome/answer/95647?hl=en-GB">
                Chrome
              </TextLink>
            </li>
            <li>
              <TextLink href="https://support.apple.com/en-lb/guide/safari/sfri11471/16.0/mac/12.0">
                Safari
              </TextLink>
            </li>
          </ul>
          <Space size={4} />
        </ListItem>

        <ListItem>
          <Body1>
            For Google Analytics Advertising Features, you can also{' '}
            <TextLink href="https://support.google.com/ads/answer/2662922?hl=en">opt-out</TextLink>{' '}
            through{' '}
            <TextLink href="https://support.google.com/ads/answer/2662922?hl=en">
              Google Ads Settings
            </TextLink>
            . Google also provides a Google Analytics{' '}
            <TextLink href="https://tools.google.com/dlpage/gaoptout/">opt-out plug-in</TextLink>{' '}
            for the web.
          </Body1>
        </ListItem>

        <ListItem>
          <Body1>
            For more information including how to see what cookies have been set on your device,
            please visit{' '}
            <TextLink href="http://www.aboutcookies.org">www.aboutcookies.org</TextLink> or{' '}
            <TextLink href="http://www.allaboutcookies.org">www.allaboutcookies.org</TextLink>.
          </Body1>
        </ListItem>
      </OrderedList>
      <Space size={4} />
    </ListItem>

    <ListItem>
      <Heading2 size={[5, null, 7]}>General</Heading2>
      <Space size={4} />
      <OrderedList>
        <ListItem>
          <Body1>
            We may update this Cookies Policy from time to time. When we make changes, we will
            update the “Effective Date” at the top of this page. Any changes to this Notice will
            become effective when posted.
          </Body1>
        </ListItem>

        <ListItem>
          <Body1>
            The quickest and easiest way to talk to us about your personal data is through our
            friendly{' '}
            <TextLink href="https://gousto.zendesk.com/hc/en-gb/requests/new">
              Customer Care
            </TextLink>{' '}
            team. You may also choose to refer to our{' '}
            <TextLink href="#privacy-policy">Privacy Policy</TextLink>.
          </Body1>
        </ListItem>

        <ListItem>
          <Body1>
            If you have any further questions about this Cookies Policy or the use of cookies on our
            website, you can also contact our Data Protection Officer at{' '}
            <TextLink href="mailto:dpo@gousto.co.uk">dpo@gousto.co.uk</TextLink>.
          </Body1>
        </ListItem>

        <ListItem>
          <Body1>
            While we’d love to be your first port of call, you have the right to contact the{' '}
            <TextLink href="https://ico.org.uk/concerns/">
              Information Commissioner&apos;s Office
            </TextLink>{' '}
            (ICO) directly. We’re registered with the Information Commissioner&apos;s Register of
            Data Controllers under number ZA029698.
          </Body1>
        </ListItem>
      </OrderedList>
    </ListItem>
  </OrderedList>
)
