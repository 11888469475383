import { connect } from 'react-redux'
import { getUserOrderById } from 'utils/user'
import { getUserFirstName } from 'selectors/user'
import { OrderSchedule } from './OrderSchedule'

function mapStateToProps(state) {
  const orderId = state.basket.get('orderId')
  const nameFirst = getUserFirstName(state)
  const order = getUserOrderById(orderId, state.user.get('orders'))
  const subscription = state.user.get('subscription')
  const numRecipes = parseInt(order.getIn(['box', 'numRecipes']), 10)
  const numPortions = parseInt(order.getIn(['box', 'numPortions']), 10)

  return {
    deliveryDate: order.get('deliveryDate'),
    whenCutoff: order.get('whenCutoff'),
    interval: subscription ? subscription.get('interval').title : '',
    nameFirst,
    numRecipes,
    numPortions,
  }
}

const OrderScheduleContainer = connect(mapStateToProps)(OrderSchedule)

export { OrderScheduleContainer }
