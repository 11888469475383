import { jsonParse } from '@library/http'
import type { OrderResponseV2, OrderResponseV2Error } from './interfaces'

const extractOrderResponseV2 = async (response: Response): Promise<OrderResponseV2> => {
  const parsedJSON = await jsonParse(response)

  if (parsedJSON.type === 'failure') {
    throw parsedJSON.error
  }

  const result = parsedJSON.value as Record<string, unknown>

  if ('errors' in result && Array.isArray(result.errors)) {
    const error = result as OrderResponseV2Error
    const message = error.errors[0]

    if (typeof message === 'string') {
      throw new Error(message)
    }

    throw new Error(message.detail)
  }

  return result as OrderResponseV2
}

export { extractOrderResponseV2 }
