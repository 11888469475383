import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { canUseWindow } from '@library/environment'
import { getFacebookReferralLink, getMessengerReferralLink, SOCIAL_TYPES } from '../socialReferralHelper'
import { SocialButton } from '../SocialButton'

import css from './SocialShareButtons.css'

const propTypes = {
  referralCode: PropTypes.string.isRequired,
  userFirstName: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  elementType: PropTypes.string,
  trackingReferFriendSocialSharing: PropTypes.func.isRequired,
  trackUserFreeFoodLinkShare: PropTypes.func.isRequired,
}

const defaultProps = {
  elementType: 'page'
}
class SocialShareButtons extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isGoustoFacebookInitiated: false,
    }
  }

  componentDidMount() {
    this.checkGoustoFacebookInitiated()
    window.addEventListener('GoustoFacebookInit', this.checkGoustoFacebookInitiated)
  }

  componentWillUnmount() {
    window.removeEventListener('GoustoFacebookInit', this.checkGoustoFacebookInitiated)
  }

  handleGetMessengerReferralLink = () => {
    const {
      referralCode,
      userFirstName,
      trackingReferFriendSocialSharing,
      device,
      trackUserFreeFoodLinkShare,
    } = this.props

    getMessengerReferralLink(
      referralCode,
      userFirstName,
      trackingReferFriendSocialSharing,
      device,
      trackUserFreeFoodLinkShare,
    )
  }

  handleGetFacebookReferralLink = () => {
    const {
      referralCode,
      userFirstName,
      trackingReferFriendSocialSharing,
      trackUserFreeFoodLinkShare,
    } = this.props

    getFacebookReferralLink(
      referralCode,
      userFirstName,
      trackingReferFriendSocialSharing,
      trackUserFreeFoodLinkShare,
    )
  }

  checkGoustoFacebookInitiated = () => {
    if (canUseWindow && window.FB) {
      this.setState({ isGoustoFacebookInitiated: true })
    }
  }

  render() {
    const { elementType } = this.props
    const socialShareButtonsClasses = classnames(
      css.mobileHide,
      css.socialButtons,
      {
        [css.pageSocialButtons]: elementType === 'page',
        [css.componentSocialButtons]: elementType === 'component'
      })

    const { isGoustoFacebookInitiated } = this.state

    if (!isGoustoFacebookInitiated) {
      return null
    }

    return (
      <section className={socialShareButtonsClasses}>
        <SocialButton
          data-testing="freeFoodMessengerCTA"
          text={SOCIAL_TYPES.messenger}
          type="facebook-messenger"
          elementType={elementType}
          onClick={this.handleGetMessengerReferralLink}
        />
        <SocialButton
          data-testing="freeFoodFacebookCTA"
          text={SOCIAL_TYPES.facebook}
          type="facebook"
          elementType={elementType}
          onClick={this.handleGetFacebookReferralLink}
        />
      </section>
    )
  }
}

SocialShareButtons.propTypes = propTypes
SocialShareButtons.defaultProps = defaultProps

export { SocialShareButtons, SOCIAL_TYPES }
