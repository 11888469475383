import React, { HTMLAttributes } from 'react'

import styled, { CSSObject } from '@emotion/styled'
import { Text, useTheme, withCitrusTheme } from '@gousto-internal/citrus-react'

export const IconWrapperStyled = (props: HTMLAttributes<HTMLDivElement>) => {
  const { theme } = useTheme()

  const largeBreakpoint = theme.tokens.breakpoints[1]
  const breakpointToLarge = `@media (min-width: ${largeBreakpoint})`

  const Component = styled.div({
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    margin: '-40px -15px 0px',

    [breakpointToLarge]: {
      margin: '-15px 10px -15px 0px',
    },
  })

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />
}

export const LargeResponsiveHeader = withCitrusTheme(Text, (theme) => ({
  ...(theme.responsiveStyle(['72px', null, '80px'], ['fontSize']) as CSSObject),
}))
export const MediumResponsiveHeader = withCitrusTheme(Text, (theme) => ({
  ...(theme.responsiveStyle(['56px', null, '60px'], ['fontSize']) as CSSObject),
}))
