import { makeRequest } from '../fetch/makeRequest'
import { Fetcher, RequestMeta, RequestMiddleware, ResponseMiddleware } from '../types'

const pipeResponse = (response: Response) => response

/**
 * The order of these overrides is important; if they're incorrect the type tests in fetch.test.ts will break
 */

export function composeFetch(reduceRequest: RequestMiddleware<unknown>): Fetcher<void, Response>

export function composeFetch<Input>(
  reduceRequest: RequestMiddleware<Input>,
): Fetcher<Input, Response>

export function composeFetch<_, Output>(
  reduceRequest: RequestMiddleware<unknown>,
  reduceResponse: ResponseMiddleware<Response, Output>,
): Fetcher<void, Output>

export function composeFetch<Input, Output>(
  reduceRequest: RequestMiddleware<Input>,
  reduceResponse: ResponseMiddleware<Response, Output>,
): Fetcher<Input, Output>

export function composeFetch<Input, Output>(
  reduceRequest: RequestMiddleware<Input>,
  reduceResponse?: ResponseMiddleware<Response, Output>,
) {
  const identifier = Symbol('composeFetch')
  if (reduceResponse) {
    return withMetaIdentity(identifier, (input: Input) => {
      return makeRequest({ reduceRequest, reduceResponse, input, identifier })
    })
  } else {
    return withMetaIdentity(identifier, (input: Input) => {
      return makeRequest({ reduceRequest, reduceResponse: pipeResponse, input, identifier })
    })
  }
}

function withMetaIdentity<I, O>(identifier: symbol, fn: Fetcher<I, O>): Fetcher<I, O> {
  ;(fn as unknown as RequestMeta).__meta__ = {
    identifier,
  }
  return fn
}
