import React from 'react'

import {
  Box,
  Display,
  JustifyContent,
  Text,
  TextAlign,
  Heading6,
  Modal,
  ModalHeader,
  ModalButton,
  ModalBody,
  ModalFooter,
  ButtonSize,
  ModalClose,
} from '@gousto-internal/citrus-react'

import { Clock } from 'routes/Checkout/Components/CheckoutUrgency/Clock/Clock'

import { expiredCheckoutUrgencyModalTexts, almostExpiredCheckoutUrgencyModalTexts } from './data'

interface CheckoutUrgencyModalProps {
  onConfirmModal: () => void
  onDismissModal: () => void
  /**
   * if true, modal will show expired text
   * if false, modal will show almost expired text
   */
  isExpired: boolean
}
export const checkoutUrgencyModalName = 'checkoutUrgencyModal'
export const CheckoutUrgencyModal = ({
  onConfirmModal,
  onDismissModal,
  isExpired,
}: CheckoutUrgencyModalProps) => {
  const getCheckoutUrgencyModalText = (isExpiredModal: boolean) =>
    isExpiredModal ? expiredCheckoutUrgencyModalTexts : almostExpiredCheckoutUrgencyModalTexts

  const urgencyModalTexts = getCheckoutUrgencyModalText(isExpired)

  return (
    <Modal name={checkoutUrgencyModalName} preventScroll onRequestClose={onDismissModal}>
      <ModalHeader>
        <Heading6>{urgencyModalTexts.headingText}</Heading6>
        <ModalClose onClose={onDismissModal} />
      </ModalHeader>
      <ModalBody>
        <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingBottom={6}>
          <Clock />
        </Box>
        <Text size={[2, 3]} textAlign={TextAlign.Center}>
          {urgencyModalTexts.bodyText}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Box display={Display.Flex} justifyContent={JustifyContent.Center} width="100%">
          <ModalButton onClick={onConfirmModal} size={ButtonSize.Medium} width="70%">
            {urgencyModalTexts.ctaText}
          </ModalButton>
        </Box>
      </ModalFooter>
    </Modal>
  )
}
