import React, { useEffect } from 'react'

import {
  Display,
  Box,
  FlexDirection,
  JustifyContent,
  AlignItems,
  Color,
  Button,
  Divider,
  BorderStyle,
  Heading3,
  ButtonVariant,
  ButtonColorVariant,
  Space,
  Join,
  withCitrusTheme,
  useTheme,
  Visible,
  DisplayPosition,
  ZIndex,
} from '@gousto-internal/citrus-react'
import actions from 'actions'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { browserHistory } from 'react-router'

import { queueTrackingEvent } from '@library/tracking-integration'

import {
  basketAddressChange,
  basketChosenAddressChange,
  basketDateChange,
  basketPostcodeChangePure,
  basketSlotChange,
  getMenuCutoffUntil,
} from 'actions/basket'
import { boxSummaryDeliveryDaysLoad } from 'actions/boxSummary'
import redirectActions from 'actions/redirect'
import { client as clientRoutes } from 'config/routes'
import { DeliveryAddress } from 'routes/DeliveryDetails/DeliveryAddress'
import { DeliveryAddressModal } from 'routes/DeliveryDetails/DeliveryAddressModal'
import { DeliveryBoxSize } from 'routes/DeliveryDetails/DeliveryBoxSize'
import { DeliveryBoxSizeModal } from 'routes/DeliveryDetails/DeliveryBoxSizeModal'
import Loading from 'routes/Menu/Loading'
import { DatePickerContainer } from 'routes/Menu/components/BoxSummary/DeliverySlot/DatePicker'
import { DeliverySupportingText } from 'routes/Menu/components/BoxSummary/DeliverySlot/DeliverySupportingText'
import { Slots } from 'routes/Menu/components/BoxSummary/DeliverySlot/types'
import { useBasket } from 'routes/Menu/domains/basket'
import { getDisabledSlotDates } from 'routes/Menu/selectors/boxSummary'
import { getIsAuthenticated } from 'selectors/auth'
import { getSubscriptionState } from 'selectors/subscription'
import { getLoadingStateForOrder, getUserOrders } from 'selectors/user'
import { getDeliveryDaysAndSlots, getTempDeliveryOptions } from 'utils/deliverySlotHelper'

import { BoxSizeAlert } from './BoxSizeAlert'
import { DeliveryDateSlot } from './DeliveryDateSlot'

import css from './DeliveryDetails.css'

export const DeliveryDetails = () => {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const store = useStore()
  const { address: basketAddress, numPortions } = useBasket()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const disabledSlots = useSelector(getDisabledSlotDates)
  const userOrders = useSelector(getUserOrders)
  const subscriptionState = useSelector(getSubscriptionState)
  const isSubscriptionInactive = subscriptionState === 'inactive'
  const { deliveryDays, tempDate, tempSlotId, tempOrderId } = useSelector(getTempDeliveryOptions)
  const doesDateHaveDisabledSlots = disabledSlots
    .map((disabledSlotId: string) => disabledSlotId.slice(0, 10))
    .includes(tempDate)

  const isUserOrdersLoading = useSelector(getLoadingStateForOrder)
  const shippingAddresses = useSelector((state) => (state as any).user.get('shippingAddresses'))

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(redirectActions.replace(clientRoutes.menu))
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (shippingAddresses && !basketAddress) {
      const addressToSelect =
        shippingAddresses.find((address: any) => address.get('shippingDefault') === true) ||
        shippingAddresses.first()
      dispatch(basketPostcodeChangePure(addressToSelect.get('postcode')))
      dispatch(basketAddressChange(addressToSelect))
      dispatch(basketChosenAddressChange(addressToSelect))
    }
  }, [basketAddress, dispatch, shippingAddresses])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actions.userLoadData())
      dispatch(actions.userFetchShippingAddresses())
      const menuCutoffUntil = await getMenuCutoffUntil(store.getState)
      dispatch(boxSummaryDeliveryDaysLoad(undefined, menuCutoffUntil))
    }

    fetchData()
  }, [dispatch, store.getState])

  const handleContinueClick = async () => {
    dispatch(basketDateChange(tempDate))
    dispatch(basketSlotChange(tempSlotId))

    const orderId = store.getState().basket.get('orderId')

    queueTrackingEvent<{
      action: 'confirm_delivery_date'
      properties: {
        delivery_date: string
        num_portions: number
        editing_existing_order: boolean
      }
    }>({
      action: 'confirm_delivery_date',
      properties: {
        delivery_date: tempDate,
        num_portions: numPortions,
        editing_existing_order: Boolean(orderId),
      },
    })

    if (orderId) {
      browserHistory.push(`/menu/${orderId}`)
    } else {
      browserHistory.push('/menu')
    }
  }

  const helperProps = {
    disabledSlots,
    isAuthenticated,
    tempDate,
    userOrders,
    tempSlotId,
    deliveryDaysProps: deliveryDays,
  }

  const {
    slots,
    deliveryDays: deliveryDaysForDatePicker,
    hasEmptyOrders,
    hasFullOrders,
    hasActiveSlotsForSelectedDate,
  } = getDeliveryDaysAndSlots(tempDate, helperProps)

  const slotOrder = userOrders.find((order: { get: (arg0: string) => string | any[] }) =>
    order.get('deliveryDate').includes(tempDate),
  )
  const slotOrderNumPortions = slotOrder?.getIn(['box', 'numPortions'])
  const slotOrderShippingAddress = slotOrder?.get('shippingAddress')?.toJS()

  const isLoading = !isAuthenticated || isUserOrdersLoading || !deliveryDays || !slots

  const breakpointFromSmall = `@media (max-width: ${theme.tokens.breakpoints[0]})`

  const DeliverDetailsBox = withCitrusTheme(Box, () => ({
    [breakpointFromSmall]: {
      flexDirection: 'column',
    },
  }))

  const dateSlots = (slots as Slots)[tempDate]
  const tempSlot = dateSlots?.find((slot) => slot.value === tempSlotId) || dateSlots?.[0]

  return isLoading ? (
    <div className={css.loadingContainer} data-testid="delivery-details-loading">
      <Loading loading={isLoading} />
    </div>
  ) : (
    <Box alignItems={AlignItems.Center} display={Display.Flex} flexDirection={FlexDirection.Column}>
      <Box
        bg={Color.White}
        borderColor={Color.NeutralGrey_100}
        borderStyle={BorderStyle.Solid}
        borderTopWidth={0}
        borderWidth={0.5}
        width={['100%', '100%', '80%', '60%']}
      >
        <Box
          alignItems={AlignItems.Center}
          display={Display.Flex}
          paddingH={[2, 6, 6, 6]}
          paddingV={4}
        >
          <Join with={<Space direction="horizontal" size={1} />}>
            <Button
              aria-label="Back"
              colorVariant={ButtonColorVariant.Tertiary}
              data-testid="back-button"
              icon="arrow_back"
              onClick={() => browserHistory.goBack()}
              variant={ButtonVariant.Icon}
            />
            <Heading3 fontWeight={700}>Delivery details</Heading3>
          </Join>
        </Box>
        <Box minHeight="70vh" paddingH={[4, 8, 8, 8]} paddingV={[0, 4]}>
          <DeliverDetailsBox display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
            <Box flexGrow={1} width={['100%', '50%']}>
              <DeliveryDateSlot slotLabel={tempSlot?.label} tempDate={tempDate} />
              <Visible display={['none', 'block']}>
                <DeliveryBoxSize numPortions={slotOrderNumPortions} />
                <DeliveryAddress address={slotOrderShippingAddress} />
              </Visible>
            </Box>
            <Space direction="horizontal" size={10} />
            <Box width={['100%', '50%']}>
              <DatePickerContainer
                slots={slots}
                slotId={tempSlotId}
                deliveryDays={deliveryDaysForDatePicker}
                tempOrderId={tempOrderId}
                tempSlotId={tempSlotId}
                tempDate={tempDate}
                subLabelClassName=""
                hideSlotPicker
              />
              <Box paddingBottom={[5, 0]}>
                <DeliverySupportingText
                  hasEmptyOrders={hasEmptyOrders}
                  hasFullOrders={hasFullOrders}
                  doesDateHaveDisabledSlots={
                    doesDateHaveDisabledSlots && isAuthenticated && isSubscriptionInactive
                  }
                  showWarning={false}
                  tempDate={tempDate}
                />
              </Box>
              <Visible display={['none', 'block']}>{slotOrder && <BoxSizeAlert />}</Visible>
            </Box>
            <Visible display={['block', 'none']}>
              <DeliveryBoxSize numPortions={slotOrderNumPortions} />
              {slotOrder && (
                <Box paddingBottom={5}>
                  <BoxSizeAlert />
                </Box>
              )}
              <DeliveryAddress address={slotOrderShippingAddress} />
            </Visible>
          </DeliverDetailsBox>
        </Box>
        <Divider />
        <Box
          bg={Color.White}
          bottom={['0', null]}
          boxShadow="0 -4px 4px rgba(0, 0, 0, 0.06)"
          display={Display.Flex}
          justifyContent={JustifyContent.Center}
          left={['0', null]}
          paddingH={[4, 8, 8, 8]}
          paddingV={3}
          position={[DisplayPosition.Fixed, DisplayPosition.Unset]}
          right={['0', null]}
          zIndex={ZIndex.Sticky}
        >
          <Button
            disabled={!hasActiveSlotsForSelectedDate}
            height="48px"
            onClick={handleContinueClick}
            maxWidth={[null, '400px']}
            width={['100%', '60%']}
          >
            Choose recipes
          </Button>
        </Box>
      </Box>
      <DeliveryBoxSizeModal />
      <DeliveryAddressModal />
    </Box>
  )
}
