import React from 'react'

import { Color, Button, withCitrusTheme } from '@gousto-internal/citrus-react'

type BoxPriceButtonType = {
  isActive: boolean
  text: number
  handleOnClick: () => void
  dataTestId?: string
}

const ButtonWrapper = (props: any) => {
  const { isActive } = props

  const Component = withCitrusTheme(Button, (theme) => ({
    color: isActive ? theme.color(Color.White) : theme.color(Color.ColdGrey_800),
    backgroundColor: isActive ? theme.color(Color.Secondary_400) : theme.color(Color.White),
    height: isActive ? '48px' : '40px',
    borderColor: theme.color(Color.Secondary_400),
    fontSize: '24px',
    width: '100%',
    ':focus': {
      zIndex: 1,
    },
    ...(isActive
      ? {
          borderRadius: '3px',
        }
      : {
          ':first-of-type': {
            borderRadius: '3px 0 0 3px',
          },
          ':last-of-type': {
            borderRightWidth: '1px',
            borderRadius: '0 3px 3px 0',
          },
          borderRadius: 0,
          borderWidth: '1px',
          borderRightWidth: 0,
        }),
  }))

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />
}

export const BoxPriceButton = ({
  isActive,
  text,
  handleOnClick,
  dataTestId,
}: BoxPriceButtonType) => (
  <ButtonWrapper isActive={isActive} onClick={handleOnClick} data-testid={dataTestId}>
    {text}
  </ButtonWrapper>
)
