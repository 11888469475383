import actual from 'actual'
import type { Store } from 'redux'

import { browserTypeChange } from 'actions/request'

export function browserType(store: Store) {
  if (actual('width', 'px') < 768) {
    store.dispatch(browserTypeChange('mobile'))
  } else if (actual('width', 'px') < 1025) {
    store.dispatch(browserTypeChange('tablet'))
  } else {
    store.dispatch(browserTypeChange('desktop'))
  }
}
