import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import css from './TestUserBanner.css'

const TestUserBanner = ({isTestUser}) => {
  if (!isTestUser) {
    return null
  }

  return (
    <div className={classnames(css.container)} role="banner">
      <div>
        <p className={css.description}>You are logged in as a test user</p>
      </div>
    </div>
  )
}

TestUserBanner.propTypes = {
  isTestUser: PropTypes.bool.isRequired
}

export {TestUserBanner}
