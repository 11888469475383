import useSWR from 'swr'

import { menuRecipeFetcherV3 } from '@library/menu-service'
import {
  MenuRecipe,
  MenuRecipeIngredient,
} from '@library/menu-service/queries/menuApiMenuRecipe/types'

const placeholderRecipe = {
  id: 'f2b3dbc6-db36-4990-aa9e-805c07e62bdb',
  nutritional_information: {
    per_100g: {},
    per_portion: {},
  },
  ingredients: [...Array(10)].map((_, index) => ({
    id: index.toString(),
  })) as unknown as MenuRecipeIngredient,
} as unknown as MenuRecipe

export const useFetchRecipe = (
  menuId: string,
  // there is no recipe when the details modal is closed
  recipeUuid: string | null,
  numPortions: number,
  deliveryDate: string,
): {
  error: boolean
  loading: boolean
  recipe: MenuRecipe | null
  reload: () => void
} => {
  const { data, mutate, isValidating, error } = useSWR(
    () =>
      recipeUuid
        ? {
            menuId,
            recipeId: recipeUuid,
            numPortions,
            deliveryDate,
          }
        : null,
    menuRecipeFetcherV3,
  )

  const reload = () => {
    mutate()
  }

  return {
    error: !!error,
    loading: !data && isValidating,
    recipe: recipeUuid ? data || { ...placeholderRecipe, id: recipeUuid } : null,
    reload,
  }
}
