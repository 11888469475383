import React from 'react'

import {
  Box,
  Text,
  Icon,
  Display,
  FlexDirection,
  AlignItems,
  Space,
  Image,
} from '@gousto-internal/citrus-react'

import paymentLogos from './assets/payment-logos.svg'

export const SecurePaymentBanner = () => (
  <Box
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    alignItems={AlignItems.Center}
    paddingV={[4, 6]}
    paddingBottom={0}
    data-testid="securePaymentBanner"
  >
    <Box display={Display.Flex} paddingBottom={2} alignItems={AlignItems.Center}>
      <Icon name="secure" />
      <Space direction="horizontal" size={2} />
      <Text size={2}>Secure payments with</Text>
    </Box>
    <Image width="248" height="24" alt="" src={paymentLogos} />
  </Box>
)
