import { LogFormat } from './types'

const ERROR_PREFIX = `@library/experimentation: `

export class OFXError extends Error {
  constructor(message: string) {
    super(`${ERROR_PREFIX}${message}`)
  }
}

export function logOFXError(err: Error) {
  const formatted: LogFormat = {
    log_level: 'warning',
    service: 'webclient',
    message: ERROR_PREFIX + err.message,
  }
  console.warn(JSON.stringify(formatted))
}
