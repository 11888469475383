import { actionTypes } from 'actions/actionTypes'
import { trackOrder } from 'actions/order'
import { orderConfirmationRedirect } from 'actions/orderConfirmation'
import statusActions from 'actions/status'
import { sendClientMetric } from 'routes/Menu/apis/clientMetrics'
import * as orderV2 from 'routes/Menu/apis/orderV2'
import { getAccessToken, getAuthUserId } from 'selectors/auth'
import logger from 'utils/logger'

import { getBasketOrderId } from '../../../selectors/basket'
import * as coreApi from '../apis/core'
import { getOrderDetails, getOrderAction } from '../selectors/order'

const handleErrorForOrder = (message) => (dispatch) => {
  dispatch(statusActions.error(actionTypes.ORDER_SAVE, message))
  dispatch(statusActions.pending(actionTypes.BASKET_CHECKOUT, false))

  dispatch(statusActions.pending(actionTypes.ORDER_SAVE, false))
}

/**
 *
 * @param { oldOrderId: number, newOrderId: number } data
 * @returns
 */
const handleErrorForOrderConflict = (data) => (dispatch) => {
  dispatch(statusActions.error(actionTypes.ORDER_SAVE, { code: 'save-order-conflict', ...data }))
  dispatch(statusActions.pending(actionTypes.BASKET_CHECKOUT, false))

  dispatch(statusActions.pending(actionTypes.ORDER_SAVE, false))
}

export const orderAssignToUser = (orderAction, existingOrderId) => async (dispatch, getState) => {
  dispatch(statusActions.error(actionTypes.ORDER_SAVE, null))
  dispatch(statusActions.pending(actionTypes.ORDER_SAVE, true))

  const accessToken = getAccessToken(getState())
  const userId = getAuthUserId(getState())
  const orderDetails = getOrderDetails(getState())

  // We omit the additional keys we don't need
  delete orderDetails.delivery_tariff_id
  delete orderDetails.order_id
  delete orderDetails.promo_code

  let savedOrder

  try {
    const { data } = await coreApi.saveUserOrder(accessToken, orderDetails)
    savedOrder = data
  } catch (err) {
    logger.error({
      message: 'saveUserOrder in orderAssignToUser failed, logging error below...',
      actor: userId,
      extra: {
        orderDetails,
      },
    })

    logger.error(err)

    if (!existingOrderId) {
      dispatch(handleErrorForOrder('save-order-fail'))

      return
    }

    if (err.message.indexOf('having the same delivery date') > -1) {
      dispatch(handleErrorForOrderConflict(err.errorDetails))

      return
    }

    const updateUserOrderPayload = {
      ...orderDetails,
      order_id: existingOrderId,
    }

    try {
      const { data } = await coreApi.updateUserOrder(accessToken, updateUserOrderPayload)
      savedOrder = data
    } catch (error) {
      logger.error({
        message: 'updateUserOrder in orderAssignToUser failed, logging error below...',
        actor: userId,
        extra: {
          updateUserOrderPayload,
        },
      })
      logger.error(error)

      dispatch(handleErrorForOrder('update-order-fail'))

      return
    }
  }

  if (!savedOrder || !savedOrder.id) {
    logger.error({ message: 'orderAssignToUser failed, logging error below...', actor: userId })
    logger.error('assign-order-fail')

    dispatch(handleErrorForOrder('assign-order-fail'))

    return
  }

  dispatch(trackOrder(orderAction, savedOrder))
  dispatch(orderConfirmationRedirect(savedOrder.id, orderAction))
  dispatch(statusActions.pending(actionTypes.ORDER_SAVE, false))
}

export const sendUpdateOrder = () => async (dispatch, getState) => {
  dispatch(statusActions.error(actionTypes.ORDER_SAVE, null))
  dispatch(statusActions.pending(actionTypes.ORDER_SAVE, true))

  const state = getState()
  const orderId = getBasketOrderId(state)
  const orderAction = getOrderAction(state)

  try {
    const { data: order } = await orderV2.updateOrder(dispatch, getState, orderId)

    if (order) {
      dispatch(trackOrder(orderAction, order))

      sendClientMetric('menu-edit-complete-order-api-v2', 1, 'Count')

      dispatch(orderConfirmationRedirect(orderId, orderAction))
    }
  } catch (err) {
    logger.error({ message: 'saveOrder api call failed, logging error below...' })
    logger.error(err)
    dispatch(statusActions.error(actionTypes.ORDER_SAVE, err.message))
    dispatch(statusActions.pending(actionTypes.BASKET_CHECKOUT, false))
  }

  dispatch(statusActions.pending(actionTypes.ORDER_SAVE, false))
}
