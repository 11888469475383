import React from 'react'
import { Box, AlignItems, Heading1, Color, Body1, Display } from '@gousto-internal/citrus-react'

function AccountDetailsHeader() {
  return (
    <div data-testid="account-details-header">
      <Box
        width={'100%'}
        display={Display.Flex}
        alignItems={AlignItems.Baseline}
        paddingBottom={4}
        paddingH={[3, 0]}
      >
        <Box paddingRight={2}>
          <Heading1 size={[5, 7]}>Account Details</Heading1>
        </Box>
        <Box height={'1px'} flexGrow={1} bg={Color.NeutralGrey_100} />
      </Box>
      <Box paddingH={[3, 0]}>
        <Body1>
          Here you can update all relevant information about you as well as your contact and payment
          details.
        </Body1>
      </Box>
    </div>
  )
}

export { AccountDetailsHeader }
