import React from 'react'

import { Text, FontFamily, FontWeight, Color, Display } from '@gousto-internal/citrus-react'
import { Recipe } from '../../model/recipe'

export const CaloriesText = ({ numPortions, recipe }: { numPortions: number; recipe: Recipe }) => {
  const { isFineDineIn, calories } = recipe

  const caloriesToDisplay = calories?.[`for${numPortions}`]

  if (!caloriesToDisplay) {
    return null
  }

  return (
    <Text
      size={2}
      fontFamily={FontFamily.Book}
      fontWeight={FontWeight.Normal}
      color={isFineDineIn ? Color.White : Color.ColdGrey_600}
      display={Display.Inline}
    >
      &nbsp;&nbsp;|&nbsp;&nbsp;{`${caloriesToDisplay}kcal`}
    </Text>
  )
}
