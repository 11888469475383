import React from 'react'

import { useSelector } from 'react-redux'

import { useBasket } from 'routes/Menu/domains/basket'
import {
  getDeliveryDetailsInstructions,
  getIsValidDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'

import { DeliverySlot } from './DeliverySlot'

const DeliverySlotWrapper = (ownProps: any) => {
  const { removeRecipe } = useBasket()

  const deliveryInstruction = useSelector(getDeliveryDetailsInstructions)
  const isValidDeliveryDetailsInstructionCustom = useSelector(
    getIsValidDeliveryDetailsInstructionsCustom,
  )

  return (
    <DeliverySlot
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      removeRecipeFromBasket={removeRecipe}
      deliveryInstruction={deliveryInstruction}
      isValidDeliveryDetailsInstructionCustom={isValidDeliveryDetailsInstructionCustom}
    />
  )
}

export { DeliverySlotWrapper }
