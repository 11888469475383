import { invokeHotjarEvent } from 'utils/hotjarUtils'

import { actionTypes } from '../../../actions/actionTypes'
import * as trackingKeys from '../../../actions/trackingKeys'
import { doesRecipeHaveSurcharges } from '../selectors/menuService'

export const menuRecipeDetailVisibilityChange = (recipeUuid) => (dispatch, getState) => {
  const { recipes } = getState()

  if (recipeUuid && !recipes.find((r) => r.get('uuid') === recipeUuid)) {
    return
  }

  const showRecipeDetails = Boolean(recipeUuid)

  if (showRecipeDetails) {
    invokeHotjarEvent(trackingKeys.menuRecipeDetailsShown)
  }

  dispatch({
    type: actionTypes.MENU_RECIPE_DETAIL_VISIBILITY_CHANGE,
    recipeUuid,
    trackingData: {
      actionType: trackingKeys.changeMenuRecipeDetailVisibility,
      recipeUuid,
      show: showRecipeDetails,
    },
  })
}

export const showDetailRecipe = (recipeUuid) => (dispatch, getState) => {
  const { boxSummaryShow } = getState()

  if (!boxSummaryShow.get('show')) {
    menuRecipeDetailVisibilityChange(recipeUuid)(dispatch, getState)
  }
}

export const initSelectedRecipeVariantAction = (selectedRecipeVariants) => ({
  type: actionTypes.MENU_RECIPE_VARIANT_INIT,
  payload: { selectedRecipeVariants },
})

export const selectRecipeVariantAction = (
  originalRecipeId,
  variantId,
  collectionId,
  variantOutOfStock,
  view = 'grid',
  close = true,
  hasSurcharge,
  recipeReference,
) => ({
  type: actionTypes.MENU_RECIPE_VARIANT_SELECTED,
  payload: {
    collectionId,
    originalRecipeId,
    variantId,
    close,
    recipeReference,
  },
  trackingData: {
    actionType: trackingKeys.selectRecipeVariant,
    recipe_id: originalRecipeId,
    recipe_variant_id: variantId,
    collection_id: collectionId,
    variant_out_of_stock: variantOutOfStock,
    view,
    has_surcharge: hasSurcharge,
  },
})

export const menuClosePropositionTileAction = () => ({
  type: actionTypes.MENU_SELL_THE_PROPOSITION_TILE_CLOSED,
})

export const trackShowThePropositionTile = () => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.showPropositionTile,
  },
})

export const trackCloseThePropositionTile = () => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.closePropositionTile,
  },
})

export const selectRecipeVariant =
  ({
    originalRecipeId,
    variantId,
    collectionId,
    variantOutOfStock,
    view = 'grid',
    close = true,
    recipeReference,
  }) =>
  async (dispatch, getState) => {
    const hasSurcharges = doesRecipeHaveSurcharges(getState(), variantId)

    dispatch(
      selectRecipeVariantAction(
        originalRecipeId,
        variantId,
        collectionId,
        variantOutOfStock,
        view,
        close,
        hasSurcharges,
        recipeReference,
      ),
    )
  }
