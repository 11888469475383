import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  FlexDirection,
  JustifyContent,
  Link,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'

import configRoutes from 'config/routes'
import { useIsOptimizelyFeatureEnabled } from 'containers/OptimizelyRollouts'
import { checkoutConstants } from 'routes/Checkout/checkoutConstants'

export const TermsAndConditions = () => {
  const isPayPalOfferEnabled = useIsOptimizelyFeatureEnabled(
    'beetroots_checkout_paypal_offer_enabled',
  )

  return (
    <Box
      display="flex"
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
    >
      <Space size={[4, 6]} direction="vertical" />
      <Text size={1}>{checkoutConstants.terms}</Text>
      <Box paddingV={1}>
        <Link
          href={configRoutes.client.termsAndConditions}
          color={Color.Secondary_400}
          target="_blank"
          size={2}
        >
          {checkoutConstants.termsLinkText}
        </Link>
      </Box>
      <Box paddingV={1}>
        <Text textAlign={TextAlign.Center} size={1}>
          {checkoutConstants.termsAge}
        </Text>
      </Box>
      {isPayPalOfferEnabled && (
        <>
          <Space size={[4, 6]} direction="vertical" />
          <Text textAlign={TextAlign.Center} size={1}>
            {checkoutConstants.termsPayPal}
          </Text>
        </>
      )}
    </Box>
  )
}
