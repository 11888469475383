import React from 'react'

import { CSSObject } from '@emotion/styled'
import {
  AlignItems,
  Body1,
  Body2,
  Box,
  Button,
  Color,
  CSSKeywords,
  Display,
  FlexDirection,
  FontFamily,
  Image,
  Space,
  TextAlign,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { Link } from 'react-router'

import { queueTrackingEvent } from '@library/tracking-integration'

import { signupConfig } from 'routes/Signup/signupConfig'

import buttonIcon from './assets/preferences.svg'
import { texts } from './config'

const DIETARY_PREFERENCES_STEP_INDEX = 3

const StyledLink = withCitrusTheme(Link, (theme) => ({
  ...(theme.responsiveStyle(['100%', CSSKeywords.Unset], ['width']) as CSSObject),
}))

export function UpdateSignupDietaryPreferences() {
  const { title, message, buttonText } = texts
  const { slug: dietaryPreferencesSlug } = signupConfig.steps[DIETARY_PREFERENCES_STEP_INDEX]

  return (
    <Box
      paddingH={[4, null, 8]}
      paddingV={3}
      paddingBottom={0}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={[AlignItems.FlexStart, AlignItems.Center]}
    >
      <Body1 color={Color.ColdGrey_800} fontFamily={FontFamily.Bold}>
        {title}
      </Body1>
      <Space size={1} />
      <Box maxWidth={[null, '400px', '472px']}>
        <Body2 color={Color.ColdGrey_800} textAlign={[TextAlign.Left, TextAlign.Center]}>
          {message}
        </Body2>
      </Box>
      <Space size={3} />
      <StyledLink
        to={`/signup/${dietaryPreferencesSlug}`}
        onClick={() => {
          queueTrackingEvent<TrackingEditDietaryPreferences>({
            action: 'edit_dietary_preferences',
            properties: {
              section: 'menu',
            },
          })
        }}
        data-testid="link-to-dietary-preferences"
      >
        <Button width={['100%', '345px']}>
          {buttonText}
          <Space size={4} direction="horizontal" />
          <Image alt="" width="18px" src={buttonIcon} />
        </Button>
      </StyledLink>
    </Box>
  )
}

type TrackingEditDietaryPreferences = {
  action: 'edit_dietary_preferences'
  properties: {
    section: 'menu'
  }
}
