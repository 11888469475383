import React from 'react'

import { Heading4, Body3, Strong, Space } from '@gousto-internal/citrus-react'

import { MenuRecipeIngredient } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { SubIngredients } from '../SubIngredients/SubIngredients'

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const IngredientsList = ({
  ingredients,
  allergens,
}: {
  ingredients: MenuRecipeIngredient[]
  allergens: string[]
}) => (
  <div>
    {ingredients.length > 0 ? (
      <>
        <Heading4>Ingredients contain</Heading4>
        <Space size={4} />
        {ingredients.map((ingredient) => {
          const { name, quantities, sub_ingredients: subIngredients } = ingredient
          const quantity = quantities?.in_meal ?? 0

          if (!subIngredients || quantity <= 0) {
            return <span key={name} />
          }

          return (
            <dl key={name} data-testid="ingredient">
              <Body3>
                <Strong>{capitalizeFirstLetter(name)}: </Strong>
                <SubIngredients subIngredients={subIngredients} allergens={allergens} />
              </Body3>
            </dl>
          )
        })}
      </>
    ) : (
      <span />
    )}
  </div>
)
