import { Color } from '@gousto-internal/citrus-react'
import GoustoPNG from '../../assets/Gousto.png'
import GoustoWEBP from '../../assets/Gousto.webp'
import HelloFreshPNG from '../../assets/HelloFresh.png'
import HelloFreshWEBP from '../../assets/HelloFresh.webp'
import MindfulChefPNG from '../../assets/MindfulChef.png'
import MindfulChefWEBP from '../../assets/MindfulChef.webp'

export enum TableItemType {
  Gousto,
  HelloFresh,
  MindfulChef,
}

export type ColorData = {
  priceTextColor: Color
  badgeBackgroundColor: Color
  badgeTextColor: Color
}

export type ItemImageProps = {
  type: TableItemType
}

export type TableItemData = {
  id: string
  type: TableItemType
  price: string
  priceDescription: string
  priceDiff: string
}

export const TABLE_DATA: TableItemData[] = [
  {
    id: 'Gousto',
    type: TableItemType.Gousto,
    price: '£40.46',
    priceDescription: 'Per box',
    priceDiff: '0',
  },
  {
    id: 'HelloFresh',
    type: TableItemType.HelloFresh,
    price: '£43.82',
    priceDescription: 'Per box',
    priceDiff: '£3.36',
  },
  {
    id: 'MindfulChef',
    type: TableItemType.MindfulChef,
    price: '£66.39',
    priceDescription: 'Per box',
    priceDiff: '£25.93',
  },
]

export const IMAGES = {
  gousto: {
    png: GoustoPNG,
    webp: GoustoWEBP,
  },
  mindfulChef: {
    png: MindfulChefPNG,
    webp: MindfulChefWEBP,
  },
  helloFresh: {
    png: HelloFreshPNG,
    webp: HelloFreshWEBP,
  },
}
