import React from 'react'

import {
  Body2,
  BorderStyle,
  Box,
  CSSKeywords,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { DeliveryDay } from 'routes/Signup/Components/Calendar/models/DeliveryDay'
import { getTempDeliveryOptions } from 'routes/Signup/signupSelectors'

import { useTimeLeftUntilCutoff } from './hooks/useTimeLeftUntilCutoff'
import { getDaysUntilDate } from './utils/utils'

type DeliveryInfoBannerProps = {
  deliveryDays: DeliveryDay[]
}

export function DeliveryInfoBanner({ deliveryDays }: DeliveryInfoBannerProps) {
  const availableDeliveryDays = deliveryDays.filter((dd) => dd.disabled === false)

  const earliestDeliveryDate = availableDeliveryDays[0].date

  const days = getDaysUntilDate(earliestDeliveryDate)

  const { deliveryDaysWithDisabled } = useSelector(getTempDeliveryOptions)
  const cutoffDate = deliveryDaysWithDisabled.getIn([
    earliestDeliveryDate,
    'daySlots',
    0,
    'shouldCutoffAt',
  ])

  const timeLeft = useTimeLeftUntilCutoff(cutoffDate)

  const timeLeftString =
    +timeLeft.hours >= 1
      ? `${timeLeft.hours} hrs ${timeLeft.minutes} mins`
      : `${timeLeft.minutes} mins ${timeLeft.seconds} secs`

  if (!Number.isInteger(days) || Number.isNaN(Date.parse(cutoffDate))) {
    return null
  }

  return (
    <Box
      bg={Color.Informative_50}
      borderColor={Color.Informative_200}
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderRadius={1.5}
      data-testid="delivery-info-banner"
      display={Display.Flex}
      paddingV={4}
      paddingH={4}
    >
      <Icon name="delivery" variant={IconVariant.Informative} />
      <Space direction="horizontal" size={2} />
      <Body2 color={Color.ColdGrey_800}>
        Order within {timeLeftString} to get{' '}
        <Text display={Display.Inline} fontFamily={FontFamily.Bold} size={CSSKeywords.Inherit}>
          your meals in as little as {days} days
        </Text>
      </Body2>
    </Box>
  )
}
