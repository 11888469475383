import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import { Alert } from 'goustouicomponents'
import PropTypes from 'prop-types'

import { PricePerServingMessage } from 'components/PricePerServingMessage'
import { TooManyRecipesWarning } from 'routes/Menu/components/TooManyRecipesWarning/TooManyRecipesWarning'
import { useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'
import { useMenuBox } from 'routes/Menu/domains/basket/internal/useMenuBox'
import { useNumPortions } from 'routes/Menu/domains/basket/internal/useNumPortions'
import { useSupportedBoxTypes } from 'routes/Menu/domains/basket/internal/useSupportedBoxTypes'

import { BoxProgressMessage } from '../../../BoxProgressMessage'

const BoxProgressAlert = ({ numRecipes }) => {
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const { numPortions } = useNumPortions()
  const maxRecipesNum = maxRecipesForPortion(numPortions)
  const hasMaxRecipes = numRecipes >= maxRecipesNum
  const numRecipesOverLimit = useNumRecipesOverBasketLimit()
  const menuBox = useMenuBox()

  /* It doesn't make sense to show basket progress if we don't know what the maximum allowed number of recipe
  for the chosen portion size is, so we return null until we get that information from the menu-service. */
  if (!menuBox) {
    return null
  }

  if (numRecipesOverLimit) {
    return (
      <Box paddingBottom={4}>
        <TooManyRecipesWarning numRecipesOverLimit={numRecipesOverLimit} />
      </Box>
    )
  }

  return (
    <div data-testing="boxProgressAlert">
      <Alert type={hasMaxRecipes ? 'success' : 'info'}>
        <PricePerServingMessage />
        <BoxProgressMessage numRecipes={numRecipes} />
      </Alert>
    </div>
  )
}

BoxProgressAlert.propTypes = {
  numRecipes: PropTypes.number.isRequired,
}

export { BoxProgressAlert }
