import {
  AlignItems,
  Body3,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { HERO } from '../../../config'

const Pill = withCitrusTheme(Box, () => ({
  borderRadius: '48px',
}))

export const HighlightChoice = () => (
  <Box display={Display.Flex} justifyContent={[JustifyContent.Center, JustifyContent.FlexStart]}>
    <Pill
      display={Display.Flex}
      paddingH={2}
      paddingV={2}
      bg={Color.Success_50}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      width="340px"
    >
      <Icon name="restaurant" variant={IconVariant.Confirmation} />
      <Space direction="horizontal" size={2} />
      <Body3>{HERO.benefits}</Body3>
    </Pill>
  </Box>
)
