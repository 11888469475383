import React, { ReactNode } from 'react'

import RCTooltip from 'rc-tooltip'

import css from './CheckoutTooltip.css'

type Placement =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'rightTop'
  | 'rightBottom'
  | 'leftTop'
  | 'leftBottom'

type Trigger = 'hover' | 'click' | 'focus'

type CheckoutTooltipProps = {
  version: 'Desktop' | 'Mobile'
  placement?: Placement
  trigger?: Trigger | Trigger[]
  children: ReactNode
}

export const CheckoutTooltip = ({
  version,
  placement = 'right',
  trigger = ['click'],
  children,
}: CheckoutTooltipProps) => (
  <div className={css[`on${version}`]}>
    <RCTooltip
      placement={placement}
      trigger={trigger}
      overlay={<div className={css.tooltipContent}>{children}</div>}
      overlayClassName={`checkoutTooltip-${placement}`}
    >
      <span data-testid="CheckoutTooltipContent" className={css.tooltipTrigger} />
    </RCTooltip>
  </div>
)
