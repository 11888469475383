import React from 'react'

import {
  AlignItems,
  Box,
  Display,
  Text,
  FontFamily,
  FlexDirection,
  JustifyContent,
  Space,
  Color,
  Body2,
  Heading5,
  Grid,
  Col,
} from '@gousto-internal/citrus-react'

import { portionSizesConfig } from 'config/portionSizes'

import { BoxPriceSuitableForSection } from '../BoxPriceBlock/BoxPriceSuitableForSection'
import { BoxPriceButton } from '../BoxPriceButton/BoxPriceButton'
import { BoxPricesWithAllPortionsType } from '../boxPricesModels'

export const BoxPricesNumPortionsButton = ({
  portionSizes,
  activeNumPortion,
  onClickPortionSize,
}: Pick<
  BoxPricesWithAllPortionsType,
  'portionSizes' | 'activeNumPortion' | 'onClickPortionSize'
>) => (
  <>
    <Box
      display={Display.Flex}
      flexDirection={[FlexDirection.Column, FlexDirection.Row]}
      justifyContent={JustifyContent.Center}
      alignItems={[AlignItems.FlexStart, AlignItems.Center]}
      wrap={false}
      width="100%"
    >
      <Box width={['100%', '50%']} paddingBottom={1}>
        <Text fontFamily={FontFamily.Bold} size={2}>
          Select number of people
        </Text>
      </Box>
      <Box width={['100%', '50%']} display={Display.Flex} alignItems={AlignItems.Center}>
        {portionSizes.map((portionSize: string) => {
          const numPortion = Number(portionSize)

          return (
            <BoxPriceButton
              key={numPortion}
              text={numPortion}
              isActive={activeNumPortion === numPortion}
              handleOnClick={() => onClickPortionSize(numPortion)}
              dataTestId="numPortionsButton"
            />
          )
        })}
      </Box>
    </Box>
    <Space size={2} direction="vertical" />
    <Grid paddingBottom={4}>
      <Col size={6} gridColumn={['span 12', null, '7/span 6']}>
        <Box bg={Color.NeutralGrey_50} paddingV={3} paddingH={3} borderRadius={1.5}>
          <Heading5>{`${activeNumPortion} person box`}</Heading5>
          <Space size={2} direction="vertical" />
          <Body2 color={Color.ColdGrey_800}>Suitable for:</Body2>
          <Space size={1} direction="vertical" />
          <ul
            style={{
              padding: 0,
              listStyle: 'none',
              margin: 0,
            }}
          >
            <BoxPriceSuitableForSection
              suitableForLabel={(portionSizesConfig as any)[activeNumPortion].suitableFor}
            />
          </ul>
        </Box>
      </Col>
    </Grid>
  </>
)
