import React, { useCallback, useMemo } from 'react'

import { Box, Display, Heading5, Space, Visible } from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { RecipeTile, RecipeTileDependencies } from '@features/recipe-tile'
import { MenuApiPreferencesResponse } from '@library/menu-service'
import { MenuRecipe } from '@library/menu-service/queries/common/types'
import { queueTrackingEvent } from '@library/tracking-integration'

import { SearchRecipeTile } from 'routes/Menu/components/Search/SearchRecipeTile/SearchRecipeTile'
import { transformRecipe } from 'routes/Menu/components/Search/utils'
import { useTag } from 'routes/Menu/context/recipeContext'
import { useCollections } from 'routes/Menu/domains/collections'
import { useMenu } from 'routes/Menu/domains/menu'
import { getSignupDietaryPreferences } from 'routes/Signup/signupSelectors'
import { getIsAuthenticated } from 'selectors/auth'
import { getBasketMenuUuid } from 'selectors/basket'
import { getUserFirstName } from 'selectors/user'

import { TrackCTAClick } from '../types'

type SuggestedRecipeProps = {
  addRecipe: (
    recipeId: string,
    view?: string,
    recipeInfo?: { position: string },
    maxRecipesNum?: number,
    orderId?: string,
    isOrderRecipe?: boolean,
  ) => void
  canAddRecipes: boolean
  isRecipeInBasket: (recipeId: string) => boolean
  menuPreferences?: MenuApiPreferencesResponse
  numPortions: 1 | 2 | 3 | 4 | 5
  onClick: (recipeId: string) => void
  reachedLimit: boolean
  removeRecipe: (recipeId: string, view?: string, position?: string) => void
}

export const SuggestedRecipe = ({
  addRecipe,
  canAddRecipes,
  isRecipeInBasket,
  menuPreferences,
  numPortions,
  onClick,
  reachedLimit,
  removeRecipe,
}: SuggestedRecipeProps) => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const { currentCollectionId, collections } = useCollections()
  const { getRecipesForCollectionId } = useMenu()

  const userFirstName = useSelector(getUserFirstName)
  const dietaryPreferences = useSelector(getSignupDietaryPreferences)
  const menuId = useSelector(getBasketMenuUuid)
  const useRecipeBrand = useCallback(
    () => ({
      useTag,
    }),
    [],
  )

  const firstRecommendedRecipe = useMemo(() => {
    const recommendations = collections.find(
      (collection) => collection?.get('slug') === 'recommendations',
    )
    if (!recommendations) return null
    const { recipes } = getRecipesForCollectionId(recommendations.get('id'))
    const { recipe } = recipes.first()

    return recipe.toJS()
  }, [collections, getRecipesForCollectionId])

  if (!isAuthenticated || !firstRecommendedRecipe) return null

  return (
    <Box>
      <Heading5>Suggested For {userFirstName}</Heading5>
      <Space size={4} />
      <Visible display={[Display.None, Display.Block]}>
        <SearchRecipeTile
          addRecipe={addRecipe}
          isRecipeInBasket={isRecipeInBasket}
          menuPreferences={menuPreferences}
          onClick={() => onClick(firstRecommendedRecipe.uuid)}
          reachedLimit={reachedLimit}
          recipe={transformRecipe(firstRecommendedRecipe, numPortions) as MenuRecipe}
          removeRecipe={removeRecipe}
          trackingData={{ menuId }}
        />
      </Visible>
      <Visible display={[Display.Block, Display.None]}>
        <RecipeTileDependencies useRecipeBrand={useRecipeBrand}>
          <RecipeTile
            recipe={firstRecommendedRecipe}
            numPortions={numPortions}
            addRecipe={(recipeId) => {
              queueTrackingEvent<TrackCTAClick>({
                action: 'add_recipe',
                properties: {
                  menu_id: menuId,
                  add_type: 'search',
                  recipe_id: firstRecommendedRecipe.uuid,
                },
              })
              addRecipe(recipeId)
            }}
            alternativeOptions={[]}
            canAddRecipes={canAddRecipes}
            currentCollectionId={currentCollectionId as string}
            dietaryPreferences={dietaryPreferences}
            isMenuPersonalisationEnabled={false}
            isRecipeInBasket={isRecipeInBasket}
            isRecipeOutOfStock={() => false}
            onClick={() => onClick(firstRecommendedRecipe.uuid)}
            originalId={firstRecommendedRecipe.id}
            reachedLimit={reachedLimit}
            recipeReference=""
            removeRecipe={(recipeId) => {
              queueTrackingEvent<TrackCTAClick>({
                action: 'remove_recipe',
                properties: {
                  menu_id: menuId,
                  add_type: 'search',
                  recipe_id: firstRecommendedRecipe.uuid,
                },
              })
              removeRecipe(recipeId)
            }}
            selectVariant={() => {}}
            setBrowserCTAVisible={() => {}}
            menuPreferences={menuPreferences}
            insideOverflow
          />
        </RecipeTileDependencies>
      </Visible>
    </Box>
  )
}
