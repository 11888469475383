import React, { useRef, useEffect, useState } from 'react'
import { Box, Heading4, Overflow, withCitrusTheme } from '@gousto-internal/citrus-react'
import { OnSubmit, QuestionRating, RecipeRating } from '../types'
import { RecipeTile } from '../RecipeTile'
import styled from '@emotion/styled'
import { dateFormatter } from '../formatters'

const TITLE_ANIMATION_DURATION = 200 //millisecond
type FeedbackCarouselProps = {
  recipes: RecipeRating[]
  questions: QuestionRating[]
  onSubmit: OnSubmit
}
const Title = withCitrusTheme(Heading4, (theme) => ({
  paddingBottom: theme.spacing(4),
  transition: `opacity ${TITLE_ANIMATION_DURATION}ms ease`,
}))
const FlexBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  marginLeft: '-1rem',
  '& > *': {
    flexShrink: 0,
    marginLeft: '1rem',
  },
}))

export const FeedbackCarousel: React.FC<FeedbackCarouselProps> = ({
  recipes,
  questions,
  onSubmit,
}) => {
  const flexBoxRef = useRef<HTMLDivElement>(null)
  const [controlPoint, setControlPoint] = useState([0, 0])
  const [changingOrderDate, setChangingOrderDate] = useState(false)
  const [orderDate, setOrderDate] = useState(recipes[0].orderDate)

  const findControlPoint = () => {
    const { top, left, right } = flexBoxRef.current?.getBoundingClientRect() as DOMRect
    setControlPoint([left + right / 2, top + 40])
  }

  const findCurrentTile = () => {
    const elements = document.elementsFromPoint(controlPoint[0], controlPoint[1])
    const tileElement = elements.find(
      (element) => element.getAttribute('data-type') === 'recipe-tile',
    )
    if (tileElement) {
      const date = tileElement.getAttribute('data-orderdate') as string
      if (orderDate !== date) {
        setChangingOrderDate(true)
        setTimeout(() => {
          setOrderDate(date)
          setChangingOrderDate(false)
        }, TITLE_ANIMATION_DURATION)
      }
    }
  }

  useEffect(() => {
    findControlPoint()
    document.addEventListener('scroll', findControlPoint)
    return () => {
      document.removeEventListener('scroll', findControlPoint)
    }
  }, [])
  return (
    <Box
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
      width="100%"
      paddingV={14}
      data-testid="feedback-carousel"
    >
      <Title style={{ opacity: changingOrderDate ? 0 : 1 }}>
        Box from {dateFormatter.format(new Date(orderDate))}
      </Title>
      <FlexBox ref={flexBoxRef} onScroll={findCurrentTile}>
        {recipes.map((recipe) => (
          <RecipeTile
            key={recipe.id}
            recipe={recipe}
            onSubmit={(orderId, recipeId) => {
              findCurrentTile()
              onSubmit(orderId, recipeId)
            }}
            questionId={
              questions.find((question) => question.orderId === recipe.orderId)?.id as string
            }
          />
        ))}
      </FlexBox>
    </Box>
  )
}
