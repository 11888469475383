import React from 'react'

import classnames from 'classnames'

import { BrandHeaderImage } from '../../../domains/brand/types'

import css from './GradientInfoHeader.css'

export type GradientInfoHeaderProps = {
  gradientColor?: string
  color?: string
  headerImage?: BrandHeaderImage[]
  image?: BrandHeaderImage[]
  description: string
  altText?: string
}

const GradientInfoHeader = ({
  gradientColor,
  color,
  headerImage,
  image,
  description,
  altText = 'Campaign banner',
}: GradientInfoHeaderProps) => (
  <div className={css.gradientInfoHeader} data-testid="gradient-info-header">
    <div className={css.gradientImageBackground} data-testid="gradient-image-background">
      {image && image[0] && (
        <img
          className={css.gradientImage}
          src={image[0].url}
          alt={altText}
          data-testid="gradient-info-header-main-image"
        />
      )}
      <div
        className={classnames(css.gradientBackground, css.hideMobile)}
        style={{
          backgroundImage: `linear-gradient(270deg, rgba(0, 83, 199, 0) 20%, ${gradientColor} 30%)`,
        }}
        data-testid="gradient-background-mobile"
      />
      <div
        className={classnames(css.gradientBackground, css.hideDesktop)}
        style={{
          backgroundImage: `linear-gradient(270deg, rgba(0, 83, 199, 0) 26.58%, ${gradientColor} 55%)`,
        }}
        data-testid="gradient-background-desktop"
      />
      <div
        className={css.gradientText}
        style={{
          color: `${color}`,
        }}
        data-testid="gradient-info-text"
      >
        {headerImage && headerImage[0] && (
          <img
            data-testid="gradient-info-header-image"
            src={headerImage[0].url}
            alt={altText}
            style={{ width: 200, height: 'auto' }}
            className={css.gradientTitleImage}
          />
        )}
        <p className={css.gradientDescription} data-testid="gradient-description">
          {description}
        </p>
      </div>
    </div>
  </div>
)

export { GradientInfoHeader }
