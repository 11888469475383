import React, { useEffect, useState } from 'react'

import { CTA, Modal, ModalHeader, ModalBody } from 'goustouicomponents'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import configRoutes from 'config/routes'

import css from './CheckoutErrorModal.css'

interface Props {
  shouldShow: boolean
  customMessage?: string
}

export const CheckoutErrorModal = ({ shouldShow, customMessage }: Props) => {
  const [openModal, setOpenModal] = useState(shouldShow)

  useEffect(() => {
    setOpenModal(shouldShow)
  }, [shouldShow])

  const onClick = () => {
    setOpenModal(false)
  }

  const errorMessage =
    customMessage ||
    'There was an issue when confirming your order. Head to your account to check your upcoming deliveries.'

  return (
    <Modal
      isOpen={openModal}
      variant="floating"
      name="error-modal"
      description="checkout error modal"
      handleClose={onClick}
      withOverlay
    >
      <ModalHeader withSeparator align="left">
        Uh oh, something went wrong
      </ModalHeader>
      <ModalBody>
        <p className={css.modalText}>{errorMessage}</p>
        <Link to={configRoutes.client.myDeliveries}>
          <CTA size="medium" variant="primary" isFullWidth onClick={() => {}}>
            View deliveries
          </CTA>
        </Link>
      </ModalBody>
    </Modal>
  )
}

CheckoutErrorModal.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
}
