import React, { useEffect, useState } from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  Heading2,
  Visible,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { fetchRecipeFeedback } from '@library/user-feedback'
import { Loader } from '../Loader'
import { RatingsComplete } from '../RatingsComplete'
import { Feedback, OnSubmit, TrackEvent } from '../types'
import { formatFeedbackResponse, getAllQuestions, getAllRecipes } from '../formatters'
import { FeedbackGrid } from '../FeedbackGrid'
import { FeedbackCarousel } from '../FeedbackCarousel'
import { queueTrackingEvent } from '@library/tracking-integration'

const Container = withCitrusTheme(Box, (theme) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))
const Divider = withCitrusTheme(Box, (theme) => ({
  flex: 1,
  marginLeft: theme.spacing(2),
  height: '1px',
  width: 'auto',
  backgroundColor: theme.color(Color.NeutralGrey_100),
}))

export const RecipeFeedback = () => {
  const [loading, setLoading] = useState(false)
  const [hasFeedback, setHasFeedback] = useState(false)
  const [feedback, setFeedback] = useState<Feedback[]>([])

  useEffect(() => {
    setLoading(true)
    queueTrackingEvent<TrackEvent>({
      action: 'view_rate_screen',
    })
    fetchRecipeFeedback().then((response) => {
      setHasFeedback(response.meta.totalRecipeCount > 0)
      setFeedback(formatFeedbackResponse(response))
      setLoading(false)
    })
  }, [])

  const onSubmit: OnSubmit = (orderId, recipeId) => {
    const { feedback: updatedFeedback, totalRecipeCount } = feedback.reduce(
      (result, feedback) => {
        if (feedback.id === orderId) {
          feedback.recipes = feedback.recipes.filter((recipe) => recipe.id !== recipeId)
        }
        result.totalRecipeCount += feedback.recipes.length
        result.feedback.push(feedback)
        return result
      },
      { feedback: [] as Feedback[], totalRecipeCount: 0 },
    )
    setFeedback(updatedFeedback)
    setHasFeedback(totalRecipeCount > 0)
  }

  return (
    <Container>
      <Box display="flex" alignItems={AlignItems.Center} width="100%">
        <Heading2>Rate my recipes</Heading2>
        <Divider />
      </Box>
      <Body2>
        We want to make our menu the best it can be, and knowing what you think really helps.
      </Body2>
      {loading && <Loader />}
      {!loading &&
        (hasFeedback ? (
          <>
            <Visible display={[Display.None, Display.Block]}>
              <FeedbackGrid feedback={feedback} onSubmit={onSubmit} />
            </Visible>
            <Visible display={[Display.Block, Display.None]}>
              <FeedbackCarousel
                recipes={getAllRecipes(feedback)}
                questions={getAllQuestions(feedback)}
                onSubmit={onSubmit}
              />
            </Visible>
          </>
        ) : (
          <RatingsComplete />
        ))}
    </Container>
  )
}
