import { Box, Color, FontFamily, Space, Text } from '@gousto-internal/citrus-react'
import React from 'react'
import { HostedFieldsUI } from './Form'

export const HostedFieldsInputFields = ({
  label,
  id,
  showError,
  errorMessage,
}: {
  label: string
  id: string
  showError: boolean
  errorMessage: string
}) => (
  <Box paddingV={1}>
    <Text fontFamily={FontFamily.Bold}>{label}</Text>
    <Space direction="vertical" size={2} />
    <HostedFieldsUI id={id} />
    {showError && (
      <>
        <Space size={1} />
        <Text size={1} color={Color.Error_900}>
          {errorMessage}
        </Text>
      </>
    )}
  </Box>
)
