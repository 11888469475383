export const transformIngredientsWithPortionSize = ({ ingredientsData, recipeIngredients, portionSize }) => recipeIngredients.map(
  ({ id: ingredientId, quantities }) => {
    const ingredient = ingredientsData.find(item => item.id === ingredientId)
    const quantity = quantities[`for${portionSize}`].in_box
    const urls = ingredient.attributes?.images?.[0]?.crops || []
    const ingredientName = ingredient.attributes?.name
    const urlsAndUuid = { urls, uuid: ingredientId }

    if (!quantity) {
      return null
    }

    return ({ label: `${quantity} ${ingredientName}`, ...urlsAndUuid })
  }
).filter(recipes => recipes)

export const transformRecipesWithIngredients = (recipes, ingredientsData, portionSize) => (
  recipes.map((recipe) => {
    const { id: uuid, attributes, relationships } = recipe
    const { name: title, core_recipe_id: id, gousto_reference: goustoReference, images } = attributes
    const { ingredients: { data: recipeIngredients } } = relationships

    const imageUrl = images?.[0]?.crops?.[1]?.url || ''

    const ingredients = transformIngredientsWithPortionSize({ ingredientsData, recipeIngredients, portionSize })

    return { id, uuid, title, goustoReference: goustoReference.toString(), ingredients, imageUrl }
  })
)
