import React from 'react'

import { Display, Space, Visible } from '@gousto-internal/citrus-react'
import { Header } from 'Header'
import { SimpleHeader } from 'Header/SimpleHeader'
import Helmet from 'react-helmet'

import { client as clientRoutes } from 'config/routes'

import { CheckoutUrgency } from '../Components/CheckoutUrgency/CheckoutUrgency'
import { useIsCheckoutBoxTangibilityV3Enabled } from '../hooks'

import css from './CheckoutLayout.css'

export const HeaderCheckoutUrgency = () => (
  <Visible display={[Display.Block, Display.None]}>
    <Space size={2} />
    <CheckoutUrgency />
  </Visible>
)

export function CheckoutLayout({ children }: React.PropsWithChildren<Record<string, never>>) {
  const isCheckoutBoxTangibilityV3Enabled = useIsCheckoutBoxTangibilityV3Enabled()

  return (
    <span>
      <Helmet
        title="Food Boxes | Get Fresh Food &amp; Ingredients Delivered | Gousto"
        style={[
          {
            cssText: `
              #react-root {
                height: 100%;
              }
            `,
          },
        ]}
      />

      <div className={css.layoutContainer}>
        <div className={css.pageContainer}>
          {isCheckoutBoxTangibilityV3Enabled ? (
            <SimpleHeader
              serverError={false}
              className=""
              homeUrl={clientRoutes.home}
              showWhiteHeader
              innerComponent={<HeaderCheckoutUrgency />}
            />
          ) : (
            <Header simple />
          )}
          {children}
        </div>
      </div>
    </span>
  )
}
