import type { RequestConfig } from '@library/http'
import { composeRequest, setHeader } from '@library/http/requests'
import type { DeliveryInstruction, ShippingAddress, UserChangeDefaultAddressBody } from './interfaces'

export const useChangeDefaultAddressSerialiser = composeRequest(
  (req: RequestConfig, input: UserChangeDefaultAddressBody) => {
  return {
    ...req,
    body: JSON.stringify({ address_id: input.addressId })
  }
})

export const useAddressSerialiser = composeRequest(
  setHeader('Content-Type', 'application/json'),
  (req: RequestConfig, input: Partial<ShippingAddress>) => {
    const {
      name,
      companyName,
      billingDefault,
      shippingDefault,
      premiumDelivery,
      deliveryInstructions,
      deliveryInstructionsCustom = '',
      ...rest
    } = input

    let updatedDeliveryInstructions = deliveryInstructions

    if (deliveryInstructions === 'Neighbour') {
      const neighbourInstructions = `Neighbour: ${deliveryInstructionsCustom}`
      updatedDeliveryInstructions = neighbourInstructions as DeliveryInstruction
    }

    if (deliveryInstructions === 'Other') {
      updatedDeliveryInstructions = deliveryInstructionsCustom as DeliveryInstruction
    }

    req.body = JSON.stringify({
      ...rest,
      name: name || companyName,
      companyname: companyName,
      billing_address: billingDefault,
      shipping_default: shippingDefault,
      premium_delivery: premiumDelivery,
      delivery_instructions: updatedDeliveryInstructions,
    })

    return req
  },
)
