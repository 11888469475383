module.exports = {
  boxTypes: {
    2: '2-Person',
    4: 'Family',
  },

  maxProductsNum: 10,

  maxRecipesNum: 4,
  minRecipesNum: 2,

  portions: {
    default: 2,
    allowed: [1, 2, 3, 4, 5, 8],
  },

  minRecipes: 2,

  offsetDays: 3,
}
