export const BRAINTREE_CDN = 'https://js.braintreegateway.com'
export const BRAINTREE_VERSION = '3.96.0'
export const BRAINTREE_CLIENT = 'client'
export const BRAINTREE_DATA_COLLECTOR = 'data-collector'
export const BRAINTREE_HOSTED_FIELDS = 'hosted-fields'
export const BRAINTREE_3D_SECURE = 'three-d-secure'

export const loadScript = (name: string, document: Document | undefined) =>
  new Promise((resolve, reject) => {
    if (!document) {
      return reject(new Error('No document provided'))
    }
    const scriptId = `braintree-${name}-script`
    const existingScript = document.getElementById(scriptId)

    if (existingScript && existingScript.parentElement) {
      existingScript.parentElement.removeChild(existingScript)
    }

    const script = document.createElement('script')
    script.src = `${BRAINTREE_CDN}/web/${BRAINTREE_VERSION}/js/${name}.min.js`
    script.id = scriptId
    document.body.appendChild(script)
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      reject(new Error(`Failed to load Braintree ${name} script`))
    }
  })

export const loadBraintreeScript = (document = window.document) =>
  Promise.all([
    loadScript(BRAINTREE_CLIENT, document),
    loadScript(BRAINTREE_DATA_COLLECTOR, document),
    loadScript(BRAINTREE_HOSTED_FIELDS, document),
    loadScript(BRAINTREE_3D_SECURE, document),
  ])
