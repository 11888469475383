import { connect } from 'react-redux'

import { getMenuRecipeForDetails } from '../../selectors/menuRecipeDetails'
import { RecipeMeta } from './RecipeMeta'

const mapStateToProps = ({ recipes, menuRecipeDetails }) => ({
  recipe: getMenuRecipeForDetails({ recipes, menuRecipeDetails }),
})

export const DetailRecipeMetaContainer = connect(mapStateToProps)(RecipeMeta)
