import qs from 'qs'
import { composeFetch } from '@library/http'
import { composeRequest, addPath, setServiceUrl, setMethod, auth } from '@library/http/requests'
import { MenuApiRecipeResponse, MenuApiRecipeResponseError } from './types'

type CoreParams = {
  coreRecipeIds: string[]
  include?: string[]
}

type Params = {
  recipeIds: string[]
  include?: string[]
}

// create new parser for menu api
type Result<T, U> = { type: 'success'; value: T } | { type: 'failure'; error: U }

export async function jsonParseMenuApi(resp: string): Promise<Result<unknown, Error>> {
  try {
    const value = await JSON.parse(resp)
    return { type: 'success', value }
  } catch (parseErr) {
    return { type: 'failure', error: parseErr as Error }
  }
}

// should return recipe and included ingredients
const menuApiRecipesParse = async (response: Response): Promise<MenuApiRecipeResponse> => {
  const responseClone = await response.text()
  const parsedJSON = await jsonParseMenuApi(responseClone)

  if (parsedJSON.type === 'failure') {
    throw parsedJSON.error
  }

  const result = parsedJSON.value as Record<string, unknown>

  if ('errors' in result && Array.isArray(result.errors)) {
    const error = result as MenuApiRecipeResponseError
    const message = error.errors[0]

    if (typeof message === 'string') {
      throw new Error(message)
    }

    throw new Error(message.detail)
  }

  return result as MenuApiRecipeResponse
}

// Composes a request that: uses the input argument to call a REST path
const menuApiRecipeRequest = composeRequest(
  setServiceUrl('menu', 2),
  addPath(
    (params: CoreParams | Params) =>
      `recipes?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`,
  ),
  setMethod('GET'),
  auth,
)

export const menuRecipeFetcher = composeFetch(menuApiRecipeRequest, menuApiRecipesParse)
