import React from 'react'
import { Route, Redirect } from 'react-router'

import config from 'config/routes'
import MyDeliveries from './MyDeliveries'
import { AccountDetails } from './AccountDetails'
import { MyGousto } from './MyGousto'
import SubscriptionSettings from './Subscription'
import { RecipeFeedback } from './RecipeFeedback'
import { Referral } from './Referral'
import Account from './Account'

import { checkValidSession } from '../../utils/routes'

export const accountRoutes = store => (
  <Route component={Account} onEnter={checkValidSession(store, config.client.home, true)}>
    {AccountDetails}
    {MyGousto}
    {MyDeliveries}
    {SubscriptionSettings}
    {Referral}
    {RecipeFeedback}
    <Redirect
      from={`${config.client.deprecated__mySubscription}*`}
      to={`${config.client.mySubscription}*`}
    />
    <Redirect
      from={`${config.client.deprecated__myDetails}*`}
      to={`${config.client.myDetails}*`}
    />
  </Route>
)
