import { connect } from 'react-redux'
import { actionTypes } from 'actions/actionTypes'
import actions from 'actions/user'
import {
  didErrorFetchingPendingOrdersWithRestorable,
  didErrorFetchingProjectedOrders,
  getUserId
} from 'selectors/user'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'
import MyDeliveries from './MyDeliveries'

const mapStateToProps = (state) => ({
  isFetchingOrders: state.pending.get(actionTypes.USER_LOAD_ORDERS_WITH_RESTORABLE, true) || state.pending.get(actionTypes.USER_LOAD_PROJECTED_DELIVERIES, true),
  didErrorFetchingPendingOrders: didErrorFetchingPendingOrdersWithRestorable(state),
  didErrorFetchingProjectedOrders: didErrorFetchingProjectedOrders(state),
  userId: getUserId(state),
  isGoustoOnDemandEnabled: getIsGoustoOnDemandEnabled(state),
})

const MyDeliveriesContainer = connect(mapStateToProps, {
  userLoadAddresses: actions.userLoadAddresses,
  userLoadNewOrders: actions.userLoadNewOrders,
  userLoadData: actions.userLoadData,
})(MyDeliveries)

export default MyDeliveriesContainer
