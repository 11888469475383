import React from 'react'

import { Box, Color, Overflow, RadiusScale } from '@gousto-internal/citrus-react'

// Below code is a duplication of <ProgressBar /> from Citrus,
// this is because the component has flag not-production ready

type ProgressBarProps = {
  percentage: number
  color: Color
  ariaDescription: string
}

export const ProgressBar = ({ percentage, color, ariaDescription }: ProgressBarProps) => (
  <Box
    role="progressbar"
    aria-valuenow={percentage}
    aria-valuemin={0}
    aria-valuemax={100}
    aria-valuetext={ariaDescription}
    height="8px"
    aria-label="progressbar"
    width="100%"
    overflowX={Overflow.Hidden}
    overflowY={Overflow.Hidden}
    borderRadius={2 as RadiusScale}
    bg={Color.ColdGrey_100}
  >
    <Box
      style={{
        transition: 'all 500ms ease',
        transitionProperty: 'width, background-color',
      }}
      bg={color}
      height="100%"
      width={`${percentage}%`}
      borderRadius={2 as RadiusScale}
    />
  </Box>
)
