import { useIsOptimizelyFeatureEnabled } from 'containers/OptimizelyRollouts'

import { getPauseReasons } from '../../apis/subscription'

interface Reason {
  value: string
  label: string
  reasons?: {
    value: string
    label: string
  }[]
  alternateOption?: {
    hintText: string
    linkText: string
  }
}

export const usePauseOSRData = (): Reason[] => {
  const phaseOneReasons = [
    "I can't afford a weekly box",
    "I'm going away",
    'I had a problem',
    'Something else',
  ]
  const isPhaseOneEnabled = useIsOptimizelyFeatureEnabled(
    'iswebsubscriptionpausereasonosrenabledphase1',
  )
  const isPhaseTwoEnabled = useIsOptimizelyFeatureEnabled(
    'iswebsubscriptionpausereasonosrenabledphase2',
  )
  const { reasons } = getPauseReasons()

  if (isPhaseTwoEnabled) {
    return reasons
  }

  if (isPhaseOneEnabled) {
    return reasons.filter((reason) => phaseOneReasons.includes(reason.value))
  }

  return reasons
}
