import React from 'react'

import {
  AlignItems,
  Icon,
  Box,
  Bold,
  Color,
  Display,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

const InfoPanel = () => (
  <Box
    display={Display.Flex}
    bg={Color.Informative_50}
    borderColor={Color.Informative_200}
    width="100%"
    paddingV={3}
    paddingH={3}
    borderRadius={1.5}
    data-testid="info-panel"
    alignItems={AlignItems.Center}
  >
    <Icon name="info" size={5} variant={IconVariant.Informative} />
    <Space direction="horizontal" size={2} />
    <Text size={1}>
      <Bold>
        {`We'll create a box for you if you don't choose your recipes before the cut-off
        point, `}
      </Bold>
      which is midday, 3 days before delivery.
    </Text>
  </Box>
)

export { InfoPanel }
