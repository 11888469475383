export class BadJSON extends Error {
  // This is inbuilt as of newer browsers and Node16, so may be able to remove later
  public cause: Error

  constructor(cause: Error) {
    super(`@library/http BadJSON: service responded with malformed JSON, error="${cause.message}"`)
    this.cause = cause
  }
}

export class ErrorResponse extends Error {
  constructor(resp: Response) {
    super(`@library/http ErrorResponse: service responded with error, status=${resp.status}`)
  }
}

export class ErrorJSON extends Error {
  constructor(resp: Response, json: unknown) {
    super(
      `@library/http ErrorJSON: service responded with error, status=${
        resp.status
      } json=${JSON.stringify(json)}`,
    )
  }
}

export class MalformedType extends Error {
  constructor(reason: string) {
    super(`@library/http MalformedType: ${reason}`)
  }
}
