enum ActionType {
  EXPAND_PAYMENT_INFO,
  RESET_CLICKED_STATE,
}

interface AccountDetailsState {
  paymentInfoSectionExpanded: boolean
}

export { ActionType, AccountDetailsState }
