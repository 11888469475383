import { browserHistory } from 'react-router'
import { RafTrackingEventType } from '../tracking/trackingConfig'
import { queueTrackingEvent } from '@library/tracking-integration'

export const redirectToWizard = (trackingEvent: RafTrackingEventType) => {
  const params = new URLSearchParams(window.location.search)
  const discountCode = params.get('promo_code')
  const hasPromocode = params.has('promo_code')

  queueTrackingEvent<RafTrackingEventType>(trackingEvent)

  browserHistory.push(
    hasPromocode ? `/signup/box-size?promo_code=${discountCode}` : `/signup/box-size`,
  )
}

export const redirectToMenu = (trackingEvent: RafTrackingEventType) => {
  const params = new URLSearchParams(window.location.search)
  const discountCode = params.get('promo_code')
  const hasPromocode = params.has('promo_code')

  queueTrackingEvent<RafTrackingEventType>(trackingEvent)

  browserHistory.push(hasPromocode ? `/menu?promo_code=${discountCode}` : `/menu`)
}
