import { datadogLogs } from '@datadog/browser-logs'
import Immutable from 'immutable'
import type { AnyAction, Store } from 'redux'

/**
 * This class exists to support legacy access to window.__store__ without exposing security details
 * (like auth tokens). The __store__ data is still used by Optimizely Web X configuration
 * and possibly some other places like GTM tags.
 *
 * We use a proxy to log any access to datadog - this helps us understand if restricting access will
 * break anything.
 */
export class PublicStore {
  // eslint-disable-next-line no-empty-function, no-useless-constructor
  constructor(private store: Store) {}

  private emptyMap = Immutable.fromJS({})

  private proxyStateReads(_target: unknown, prop: string | symbol) {
    switch (prop) {
      case 'basket': {
        // Sometimes used by legacy Optimizely Web X event callbacks
        return this.store.getState().basket
      }
      case 'tracking': {
        // May be used by GTM variable tags
        return this.store.getState().tracking
      }
      case 'user': {
        return Immutable.fromJS({
          // May be used by Optimizely Web X event callbacks to check if user signed in.
          // Mask for privacy.
          id: this.store.getState().user.get('id') ? '*' : '',
        })
      }
      default: {
        datadogLogs.logger.warn(
          `FEF-1426: Unexpected read from window.__store__.getState().${String(prop)}`,
        )

        return this.emptyMap
      }
    }
  }

  getState(): Record<string, any> {
    const state = this.store.getState()

    return new Proxy(state, {
      get: this.proxyStateReads.bind(this),
    })
  }

  dispatch(event: AnyAction) {
    datadogLogs.logger.warn(
      `FEF-1426: Unexpected call to window.__store__.dispatch(); type ${event?.type}`,
    )

    this.store.dispatch(event)
  }
}
