module.exports = {
  head: {
    title: 'Modern Slavery Statement - Gousto',
    meta: [
      {
        name: 'description',
        content: 'Read the Modern Slavery Statement for Gousto',
      },
      {
        name: 'keywords',
        content: 'Gousto, recipe delivery, ingredients, fresh, healthy food, cooking',
      },
    ],
  },
}
