import Immutable from 'immutable'

import { actionTypes } from 'actions/actionTypes'

export const getErrorType = (errorCode: string) => {
  if (errorCode === '82031') {
    return actionTypes.CARD_TOKENIZATION_FAILED
  }

  return actionTypes.NETWORK_FAILURE
}

export const formatAddress = (address: Immutable.Map<string, any>) => ({
  houseNo: address.get('houseNo'),
  town: address.get('town'),
  postcode: address.get('postcode'),
})
