import { actionTypes } from 'actions/actionTypes'
import { trackUTMAndPromoCode } from 'actions/tracking'
import * as trackingKeys from 'actions/trackingKeys'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { PaymentMethod } from 'routes/Signup/signupConfig'

export const setCurrentPaymentMethod: CheckoutActionCreator =
  (paymentMethod, options = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.PAYMENT_SET_PAYMENT_METHOD,
      paymentMethod,
    })

    const state = getState()

    if (
      paymentMethod === PaymentMethod.PayPal &&
      state.checkout.get('paypalErrors').size &&
      !state.checkout.get('paypalErrorsReported')
    ) {
      dispatch({ type: actionTypes.CHECKOUT_PAYPAL_ERRORS_REPORTED })
    }

    if (!options.disableTracking) {
      const trackingKey =
        paymentMethod === PaymentMethod.Card
          ? trackingKeys.selectCardPayment
          : trackingKeys.selectPayPalPayment
      dispatch(trackUTMAndPromoCode(trackingKey))
    }
  }
