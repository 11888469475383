import React from 'react'

import {
  AlignItems,
  Body1,
  Box,
  Color,
  Display,
  FlexDirection,
  Heading5,
  Icon,
  IconVariant,
  Space,
  TextAlign,
} from '@gousto-internal/citrus-react'

export const NoResults = () => (
  <Box alignItems={AlignItems.Center} display={Display.Flex} flexDirection={FlexDirection.Column}>
    <Box
      bg={Color.ColdGrey_50}
      borderRadius="round"
      display={Display.Flex}
      paddingH={8}
      paddingV={8}
    >
      <Icon name="search" size={20} variant={IconVariant.Default} />
    </Box>
    <Space size={4} />
    <Heading5 textAlign={TextAlign.Center}>That&apos;s not on the menu this week</Heading5>
    <Body1 textAlign={TextAlign.Center}>Try searching for something different</Body1>
    <Space size={4} />
  </Box>
)
