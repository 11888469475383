import React, { useCallback } from 'react'
import { useModal, Link } from '@gousto-internal/citrus-react'
import type { ShippingAddress } from '@library/api-user'

type Props = {
  onClick: () => void
  address: ShippingAddress
}

const DeleteAddressButton = ({ address, onClick }: Props) => {
  const { openModal } = useModal()

  const deleteThisAddress = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      onClick()

      openModal('DeleteAddressModal')
    },
    [openModal, onClick],
  )

  return (
    <Link size={1} disabled={address.shippingDefault} onClick={deleteThisAddress}>
      Delete
    </Link>
  )
}

export { DeleteAddressButton }
