import React from 'react'
import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  Heading1,
  JustifyContent,
  Link,
  Body1,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'

export const AccessibilityStatement = () => {
  return (
    <Box
      paddingH={[3, 8]}
      paddingV={[10, 14]}
      display={Display.Flex}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
    >
      <Box
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        flexDirection={FlexDirection.Column}
        width="100%"
        maxWidth="1200px"
        gap={[6, 8]}
      >
        <Text size={2}>Version 1.0</Text>
        <Text size={2}>Updated April 2023</Text>
        <Heading1 textAlign={TextAlign.Center}>Accessibility Statement</Heading1>
        <Space direction="vertical" size={4} />
        <Body1>
          Our aim is to create a digital experience that is as inclusive as possible so everyone can
          get the most out of the Gousto website and app.
        </Body1>
        <Space direction="vertical" size={4} />
        <Body1>
          {`We're committed to improving accessibility with a clear plan of action that addresses issues
        identified by our team and raised by our customers. This includes making our website and app
        more compatible with assistive technologies, and easier to navigate and view for those who
        need extra support. You'll see better screen-reader functioning and support for different
        text sizes and improved contrasts, as well as other planned upgrades coming soon.`}
        </Body1>
        <Space direction="vertical" size={4} />
        <Body1>
          {`Of course, that's not the end of the journey, and we know some customers may have needs
        we've not yet met. If you'd like to give any feedback around creating a more accessible
        digital experience, head over to `}
          <Link href="https://www.gousto.co.uk/get-help/contact">our Help Centre</Link> where you
          can get in touch, or call us on 020 3011 1002.
        </Body1>
      </Box>
    </Box>
  )
}
