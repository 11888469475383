import { Image, useTheme } from '@gousto-internal/citrus-react'
import React from 'react'

type ResponsiveImageProps = {
  mobileWebp: string
  mobile1xPng: string
  mobile2xPng: string
  desktopWebp?: string // Optional for desktop images
  desktop1xPng?: string // Optional for desktop images
  desktop2xPng?: string // Optional for desktop images
  alt: string
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  mobileWebp,
  mobile1xPng,
  mobile2xPng,
  desktopWebp,
  desktop1xPng,
  desktop2xPng,
  alt,
}) => {
  const { theme } = useTheme()

  return (
    <picture
      style={{
        width: '100%',
      }}
    >
      {/* We will enable wepb format in an upcoming PR as current webp assets aren't good enough*/}
      {/* Desktop WebP */}
      {/* {desktopWebp && (
        <source
          srcSet={`${desktopWebp} 1x`}
          media={`(min-width: ${theme.tokens.breakpoints[2]}))`}
          type="image/webp"
        />
      )} */}

      {/* Desktop PNG fallback */}
      {desktop1xPng && desktop2xPng && (
        <source
          data-testid="desktop-source"
          srcSet={`${desktop1xPng} 1x, ${desktop2xPng} 2x`}
          media={`(min-width: ${theme.tokens.breakpoints[2]})`}
        />
      )}

      {/* Mobile WebP */}
      {/* <source srcSet={`${mobileWebp} 1x`} type="image/webp" /> */}

      {/* Mobile PNG fallback */}
      <source data-testid="mobile-source" srcSet={`${mobile1xPng} 1x, ${mobile2xPng} 2x`} />

      {/* Fallback image in <img> tag */}
      <Image
        data-testid="responsive-image"
        width={'100%'}
        height={'auto'}
        src={mobile1xPng}
        alt={alt}
      />
    </picture>
  )
}

export default ResponsiveImage
