import { actionTypes } from 'actions/actionTypes'
import { basketPromoCodeAppliedChange, basketPromoCodeChange } from 'actions/basket'
import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import { checkoutCreatePreviewOrder } from 'routes/Checkout/checkoutActions'
import { checkoutConstants } from 'routes/Checkout/checkoutConstants'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import logger from 'utils/logger'

const errorCodes = {
  duplicateDetails: '409-duplicate-details',
  promoCodeHasBeenUsed: '409-offer-has-been-used',
  challengeFailed: '3ds-challenge-failed',
  signupLoginFailed: 'signupLoginFailed',
}

// Note: this is a helper method, not an action.  It should be called directly
// instead of dispatched.
const handlePromoCodeRemoved = async (dispatch: any) => {
  dispatch(basketPromoCodeChange(''))
  dispatch(basketPromoCodeAppliedChange(false))
  dispatch(statusActions.error(actionTypes.CHECKOUT_ERROR_DUPLICATE, true))
}

export const handleCheckoutError: CheckoutActionCreator = (err, initiator) => async (dispatch) => {
  const { code } = err
  const errorName = checkoutConstants.errorsToHandleForSignupPayment.includes(code)
    ? actionTypes.CHECKOUT_PAYMENT
    : actionTypes.CHECKOUT_SIGNUP

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  logger.error({ message: `${errorName} - ${err.message}`, errors: [err] })

  dispatch(trackCheckoutError(errorName, code, initiator))
  dispatch(statusActions.error(errorName, code))
  if (code === errorCodes.duplicateDetails || code === errorCodes.promoCodeHasBeenUsed) {
    await handlePromoCodeRemoved(dispatch)
  } else if (checkoutConstants.errorsThatClearOrderPreview.includes(code)) {
    // Certain error scenarios trigger the rollback logic which removes the
    // order preview, hence the preview order id stored at the client becomes
    // invalid. Regenerate the order preview when such an error is detected.
    await dispatch(checkoutCreatePreviewOrder())
  }
}
