import { connect } from 'react-redux'
import subPauseActions from 'actions/subscriptionPause'
import userActions from 'actions/user'
import {
  multiSkipCloseModal,
  skipMultipleBoxes,
  trackViewMultiSkip
} from 'routes/Account/actions/multiSkip'
import {
  getUserId,
  getUserNewOrdersForMultiSkip,
  getNextDeliveryDate,
  getIsMultiSkipSuccess,
  getIsMultiSkipError,
  getSkippedBoxesCount
} from 'selectors/user'
import { MultiSkipScreenLogic } from './MultiSkipScreen.logic'

const mapStateToProps = (state) => {
  const newOrders = getUserNewOrdersForMultiSkip(state)
  const alreadySkippedBoxesCount = newOrders.filter(({ canSkip }) => !canSkip).length

  return {
    newOrders,
    alreadySkippedBoxesCount,
    nextDeliveryDate: getNextDeliveryDate(state),
    isMultiSkipSuccess: getIsMultiSkipSuccess(state),
    isMultiSkipError: getIsMultiSkipError(state),
    multiSkippedBoxesCount: getSkippedBoxesCount(state),
    userId: getUserId(state),
    isLoadingOrders: state.pending.get('USER_LOAD_ORDERS') || state.pending.get('USER_LOAD_PROJECTED_DELIVERIES'),
  }
}

const mapDispatchToProps = {
  handleSkipBoxes: skipMultipleBoxes,
  closeModal: multiSkipCloseModal,
  trackViewMultiSkip,
  subscriptionPauseTrackRecover: subPauseActions.subscriptionPauseTrackRecover,
  userLoadNewOrders: userActions.userLoadNewOrders,
}

export const MultiSkipScreenContainer = connect(mapStateToProps, mapDispatchToProps)(MultiSkipScreenLogic)
