import { connect } from 'react-redux'

import {
  getDiscountDurationFormatted,
  getCreditFormatted,
  getOfferExpiry,
  getYourFriendFirstBoxOffer,
  getYourFriendFirstMonthOffer,
} from 'selectors/rafOffer'

import { RAFOffer } from './RAFOffer'

const mapStateToProps = (state, ownProps) => {
  const { offer } = ownProps

  return {
    youGetOffer: getCreditFormatted(offer),
    yourFriendFirstBoxOffer: getYourFriendFirstBoxOffer(offer),
    yourFriendFirstMonthOffer: getYourFriendFirstMonthOffer(offer),
    offerColour: getOfferExpiry(offer),
    discountDuration: getDiscountDurationFormatted(offer),
  }
}

const RAFOfferContainer = connect(mapStateToProps)(RAFOffer)

export { RAFOfferContainer }
