import React from 'react'

import { Alert } from 'goustouicomponents'

import css from './MultipleBoxesDeliveryMessage.css'

export const MultipleBoxesDeliveryMessage = () => (
  <div className={css.multipleDeliveriesAlert}>
    <Alert type="info">
      <p>
        Heads up, your order could arrive in 1 or 2 boxes. We&apos;ll let you know when it&apos;s
        packed.
      </p>
    </Alert>
  </div>
)
