export const getMenuRecipeUuidForDetails = ({ menuRecipeDetails }) =>
  menuRecipeDetails.get('recipeUuid', null)

export const getMenuRecipeForDetails = ({ recipes, menuRecipeDetails }) => {
  const recipeUuid = getMenuRecipeUuidForDetails({ menuRecipeDetails })

  if (!recipeUuid) {
    return null
  }

  return recipes.find((recipe) => recipe.get('uuid') === recipeUuid) || null
}
