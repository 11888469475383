import type { ParameterizedContext } from 'koa'
import type { ReactSDKClient } from '@optimizely/react-sdk'
import type { Event, EventTags, OptimizelyDecision } from '@optimizely/optimizely-sdk'

import type { Branded, Dict } from '@library/type-utils'

export type ClientID = Branded<string, 'ClientID'>

/**
 * Opaque struct type for Optimizely datafile
 */
export type Datafile = Branded<Dict, 'Datafile'>

export type CookiesSource = {
  get: (key: string) => string | undefined
  set: (
    key: string,
    value: string,
    opts?: {
      path?: string
      domain?: string
      expires?: Date
      secure?: boolean
    },
  ) => unknown
}

export type ForcedDecision = {
  flagKey: string
  variationKey: string
}

export type OptimizelySDKOptions = {
  forcedDecisions: ForcedDecision[]
}

export type LogFormat = {
  log_level: 'warning'
  service: 'webclient'
  message: string
}

/**
 * Type for use in Redux thunks
 */
export type ThunkExperiments = {
  experiments: ReactSDKClient
}

/**
 * Types for use with notifications
 */
export type FlagDecision = {
  type: 'flag'
  decisionInfo: OptimizelyDecision
  userId: ClientID
}

export type OFXDecisionSnowplowEvent = {
  action: 'OFX_DECISION'
  category: 'OFX'
  properties: {
    allocationId: ClientID
    enabled: boolean
    flagKey: string
    ruleKey: OptimizelyDecision['ruleKey']
    variationKey: OptimizelyDecision['variationKey']
  }
}

export type EventTrackNotification = {
  eventKey: string
  eventTags: EventTags
  event: Event
  userId: ClientID
}

export type OFXTrackingSnowplowEvent = {
  action: string
  category: 'OFX'
  properties: {
    allocationId: ClientID
  }
}

export enum DECISION_TYPES {
  FLAG = 'flag',
}

/**
 * Client ID cookies
 */

export type ClientIDContext = ParameterizedContext<{
  clientID?: string
}>

/**
 * Client-side datafile
 */

declare global {
  interface Window {
    __datafile__: Datafile
  }
}
