import React from 'react'
import {
  ExpandableSection
} from 'goustouicomponents'
import { Box, ButtonColorVariant } from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'

import { iconNames } from '../Icon'
import { ExpandedContent } from './ExpandedContent'
import { SettingSectionToggle } from './SettingSectionToggle'
import { StyledButton } from './SettingSection.styled'

import css from './SettingSection.css'

export const SettingSection = ({
  children,
  ctaText,
  icon,
  isMobile,
  onEditClick,
  onSubmit,
  renderCurrentValue,
  title,
  instruction,
  testingSelector,
  isCtaDisabled,
  onChildrenRender
}) => (
  <ExpandableSection
    allowOverflow
    disableAnimation
    onExpand={onEditClick}
    className={css.expandableSection}
    renderToggle={({ isExpanded, handleClick }) => (
      <SettingSectionToggle
        isExpanded={isExpanded}
        icon={icon}
        title={title}
        handleClick={handleClick}
        isMobile={isMobile}
        renderCurrentValue={renderCurrentValue}
        testingSelector={testingSelector}
      />
    )}
    contentClassName={css.expandedContent}
  >
    {({ collapseSection, isExpanded }) => {
      const handleCollapseAndSubmit = (e) => {
        collapseSection(e)
        onSubmit()
      }

      if (isExpanded) {
        onChildrenRender(() => {
          collapseSection({ preventDefault: () => { } })
        }, isExpanded)
      }

      return (
        <ExpandedContent
          isExpanded={isExpanded}
          isMobile={isMobile}
          title={instruction}
          collapseSection={collapseSection}
        >
          {children}
          <Box paddingV={2}>
            <StyledButton
              disabled={isCtaDisabled}
              onClick={handleCollapseAndSubmit}
              data-testid={`${testingSelector}-save-cta`}
              colorVariant={ButtonColorVariant.Primary}
              width="100%"
            >
              {ctaText}
            </StyledButton>
          </Box>
        </ExpandedContent>
      )
    }}
  </ExpandableSection>
)

SettingSection.propTypes = {
  icon: PropTypes.oneOf(iconNames).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  renderCurrentValue: PropTypes.node.isRequired,
  ctaText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  instruction: PropTypes.string.isRequired,
  testingSelector: PropTypes.string,
  isCtaDisabled: PropTypes.bool,
  onChildrenRender: PropTypes.func
}

SettingSection.defaultProps = {
  isMobile: false,
  testingSelector: '',
  isCtaDisabled: false,
  onChildrenRender: () => {}
}
