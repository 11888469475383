/**
 * Steps in Wizard. If experiment steps should be added, add them here.
 */
// eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
export enum SignupSteps {
  BOX_SIZE = 'boxSize',
  POSTCODE = 'postcode',
  DELIVERY = 'delivery',
  DIETARY_PREFERENCES = 'dietaryPreferences',
  RECIPE_PREFERENCES = 'recipePreferences',
}
