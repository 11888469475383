import { createInstance, eventDispatcher, enums } from '@optimizely/react-sdk'

import { canUseWindow } from '@library/environment'
import { isProd } from '@library/environment/isomorphic'
import { DDInstrumentOFXDecision } from '@library/observability/src/browser/featureFlags'

import { Datafile, OptimizelySDKOptions } from '../types'
import { sendSnowplowDecisionEvent, sendSnowplowTrackingEvent, noOpDispatcher } from './events'
import { logOFXError } from '../errors'

export function getOptimizelySDK(
  datafile: Datafile,
  userID: string | null,
  opts: OptimizelySDKOptions,
) {
  const { ERROR, INFO } = enums.LOG_LEVEL
  const sdk = createInstance({
    datafile,
    eventDispatcher: canUseWindow() ? eventDispatcher : noOpDispatcher,
    logLevel: isProd() ? ERROR : INFO,
  })

  sdk.setUser({
    id: userID || null,
  })

  // Defending against vanilla JS
  try {
    for (const { flagKey, variationKey } of opts.forcedDecisions) {
      sdk.setForcedDecision({ flagKey }, { variationKey })
    }
  } catch (err) {
    logOFXError(err as Error)
  }

  if (canUseWindow()) {
    sdk.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      sendSnowplowDecisionEvent,
    )
    sdk.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      DDInstrumentOFXDecision,
    )
    sdk.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.TRACK,
      sendSnowplowTrackingEvent,
    )
  }

  return sdk
}
