import Cookies from 'cookies-js'
import { PROTOCOL_PREFIX } from '@library/environment'
import { getProtocol } from 'utils/isomorphicEnvironment'

Cookies.defaults = {
  ...Cookies.defaults,
  path: '/',
  secure: (getProtocol() === PROTOCOL_PREFIX.HTTPS),
}

export default Cookies
