import React from 'react'

import {
  FontFamily,
  FontWeight,
  Space,
  Text,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  AlignItems,
  BorderStyle,
  Color,
} from '@gousto-internal/citrus-react'
import Immutable from 'immutable'
import { useDispatch } from 'react-redux'

import { trackClickRecipeImageOrderSummary } from '../snowplowActions'
import { RecipeTileImage } from './styled'
import { useGetYourBoxData } from './yourBoxHooks'

type RecipeTileProps = {
  recipeId: string
  expanded: boolean
}

export const RecipeTile = ({ recipeId, expanded }: RecipeTileProps) => {
  const dispatch = useDispatch()
  const { menuRecipesStore } = useGetYourBoxData()
  const media = menuRecipesStore.getIn([recipeId, 'media', 'images', 0, 'urls'], Immutable.List([]))
  const imageSource = media.getIn([1, 'src'])
  const title = menuRecipesStore.getIn([recipeId, 'title'], '')

  const onClickRecipe = () => {
    dispatch(trackClickRecipeImageOrderSummary(expanded, recipeId))
  }

  if (expanded && recipeId) {
    return (
      <Box
        width="100%"
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        justifyContent={JustifyContent.FlexStart}
        alignItems={AlignItems.FlexStart}
        data-testid="RecipeTileRow"
        onClick={onClickRecipe}
      >
        <Box minWidth={['64px', '80px']} width={['64px', '80px']} style={{ aspectRatio: '1' }}>
          <RecipeTileImage src={imageSource} alt={title} />
        </Box>
        <Space size={4} direction="horizontal" />
        <Text fontWeight={FontWeight.Bold} fontFamily={FontFamily.Bold} size={1}>
          {title}
        </Text>
      </Box>
    )
  } else if (!expanded && !recipeId) {
    return (
      <Box
        data-testid="RecipeTileEmpty"
        borderStyle={BorderStyle.Dashed}
        borderColor={Color.ColdGrey_800}
        borderWidth={0.5}
        onClick={onClickRecipe}
        style={{ aspectRatio: '1' }}
      />
    )
  } else if (!expanded && recipeId) {
    return (
      <Box data-testid="RecipeTileRegular" onClick={onClickRecipe} style={{ aspectRatio: '1' }}>
        <RecipeTileImage src={imageSource} alt={title} />
      </Box>
    )
  }

  return null
}
