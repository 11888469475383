import {
  composeRequest,
  addPath,
  setQueryParam,
  setHost,
  setServiceUrl,
} from '@library/http/requests'
import { composeFetch, composeParser } from '@library/http'

export type DeliveryPoint = {
  town: string
  county: string
  postcode: string
  line1: string
  line2: string
  line3: string
  udprn: string
}

const isTestingEnvironment = process.env.NODE_ENV === 'test'

const postcodeAddressesRequest = composeRequest(
  isTestingEnvironment ? setServiceUrl('webclient') : setHost(''),
  addPath('address/postcode-lookup'),
  setQueryParam('postcode', (postcode: string) => postcode),
)

export const fetchPostcodeAddresses = composeFetch(
  postcodeAddressesRequest,
  composeParser(async (response: Response) => {
    const parsedResponse = await response.json()

    if (parsedResponse.errorCode) {
      const PostcodeError = new Error(parsedResponse.errorMsg)
      PostcodeError.name = 'PostcodeError'
      throw PostcodeError
    }

    const result = parsedResponse.deliveryPoints.map((point: DeliveryPoint) => ({
      line1: point.line1,
      line2: point.line2,
      line3: point.line3,
      udprn: point.udprn,
      town: parsedResponse.town,
      postcode: parsedResponse.postcode,
      county: parsedResponse.postalCounty,
    }))

    return result
  }),
)
