import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  Divider,
  FlexDirection,
  FontWeight,
  Heading3,
  Heading4,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { client } from 'config/routes'
import { RecipeSummary } from 'routes/Checkout/Components/RecipeSummary'
import { getBasketDate, getBasketSlotId, getNumPortions } from 'selectors/basket'
import { getSlotTimes } from 'utils/deliveries'

import { checkoutClickEditOrderLink } from '../CheckoutBoxTangibility/trackingKeys'
import { StyledLink } from './BoxDetails.styled'

export const BoxDetails = () => {
  const dispatch = useDispatch()

  const numPortions = useSelector(getNumPortions)
  const date = useSelector(getBasketDate)
  const deliveryDays = useSelector((state) => (state as any).boxSummaryDeliveryDays)
  const slotId = useSelector(getBasketSlotId)

  const deliveryDate = moment(date).format('dddd Do MMMM')
  const deliveryTime = getSlotTimes({ date, deliveryDays, slotId })

  const linkClickTrackingHandler = () => {
    dispatch(trackUTMAndPromoCode(checkoutClickEditOrderLink))
  }

  return (
    <Box
      data-testing="checkoutBoxDetailsSection"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      bg={Color.White}
      paddingBottom={[4, 6]}
      paddingH={[3, 6]}
    >
      <Box
        display={Display.Flex}
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.FlexStart}
        paddingBottom={5}
      >
        <Heading3 size={3}>Your order (for {numPortions} people)</Heading3>
        <StyledLink to={client.menu} onClick={linkClickTrackingHandler}>
          <Text size={1} fontWeight={FontWeight.Bold}>
            Edit order
          </Text>
        </StyledLink>
      </Box>

      <RecipeSummary />

      <Divider />

      <Space size={4} />

      <Heading4 size={5}>Delivery date</Heading4>

      <Space size={4} />

      <Text fontWeight={FontWeight.Bold} size={2}>
        {deliveryDate}
      </Text>
      <Text uppercase size={2}>
        {deliveryTime}
      </Text>
    </Box>
  )
}
