import React from 'react'

import { Box, Color, Display, FlexDirection } from '@gousto-internal/citrus-react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { useBasket, useSupportedBoxTypes } from 'routes/Menu/domains/basket'

import { ActionBarText } from './ActionBarText/ActionBarText'
import { ProgressBar } from './ProgressBar/ProgressBar'
import { createGetBestTierPricePerPortion } from './actionBarSelectors'

import css from './ActionBarPresentational.module.css'

type Props = {
  variant: 'separate' | 'embedded'
  recipeCount: number
  shouldAnimate: boolean
  onAnimationEnd: () => void
}

export const ActionBarPresentational = ({
  variant,
  recipeCount,
  shouldAnimate,
  onAnimationEnd,
}: Props) => {
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const { numPortions } = useBasket()
  const maxRecipesNum = maxRecipesForPortion(numPortions)

  const percentage = Math.floor((recipeCount / maxRecipesNum) * 100)
  const singleRecipePercentage = Math.floor(100 / maxRecipesNum)
  const hasLessThanOneRecipePercentage = singleRecipePercentage >= percentage
  const nextTierPricePerPortion = useSelector(createGetBestTierPricePerPortion(maxRecipesNum))

  return (
    <Box
      bg={Color.White}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      data-testid="actionBar"
      className={classNames({
        [css.separate]: variant === 'separate',
        [css.embedded]: variant === 'embedded',
        [css.shouldAnimate]: shouldAnimate,
      })}
      onAnimationEnd={onAnimationEnd}
    >
      <ProgressBar
        percentage={percentage}
        color={hasLessThanOneRecipePercentage ? Color.Informative_700 : Color.Success_800}
        ariaDescription={`${recipeCount} out of ${maxRecipesNum} recipes added`}
      />
      <ActionBarText
        recipeCount={recipeCount}
        maxRecipesNum={maxRecipesNum}
        nextTierPricePerPortion={nextTierPricePerPortion}
      />
    </Box>
  )
}
