import { connect } from 'react-redux'

import { CollectionsNavWrapper } from 'routes/Menu/components/CollectionsNav/CollectionsNavWrapper'

function mapStateToProps(state) {
  return {
    user: {
      nameFirst: state.user.get('nameFirst'),
    },
  }
}

const CollectionsNavContainer = connect(mapStateToProps)(CollectionsNavWrapper)

export { CollectionsNavContainer }
