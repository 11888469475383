import { getDietaryTags } from 'utils/recipe'

export const getRecipeIdFromProps = (state, props) => props.recipeId

export const findTag = (allTags, tag) => {
  const foundTag = allTags && allTags.find((tagData) => tagData.slug === tag)

  if (foundTag) {
    const foundTheme = foundTag.themes.find((theme) => theme.name === 'light')

    return {
      ...foundTag,
      themes: undefined,
      theme: foundTheme,
    }
  }

  return null
}

export const getVariantsForRecipeForCurrentCollection = (
  variants,
  recipeId,
  menuRecipes,
  collectionDietaryClaims,
) => {
  if (!variants) {
    return null
  }

  const recipeVariants = variants.get(recipeId)

  if (!recipeVariants) {
    return null
  }

  const alternatives = recipeVariants.get('alternatives')

  if (!alternatives || !alternatives.size) {
    return null
  }

  if (!collectionDietaryClaims) {
    return { type: 'alternatives', alternatives, variantsList: alternatives }
  }

  const alternativesDietaryClaims = alternatives.filter((variant) => {
    const variantRecipeDietaryAttributes = getDietaryTags(
      menuRecipes.find((recipe) => recipe.get('id') === variant.get('coreRecipeId')),
    )

    if (!variantRecipeDietaryAttributes || !variantRecipeDietaryAttributes.size) {
      return false
    }

    return collectionDietaryClaims.every((claim) => variantRecipeDietaryAttributes.includes(claim))
  })

  return {
    type: 'alternatives',
    alternatives: alternativesDietaryClaims,
    variantsList: alternativesDietaryClaims,
  }
}
