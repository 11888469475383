import type { EventDispatcher } from '@optimizely/optimizely-sdk/lib/shared_types'

import { queueTrackingEvent } from '@library/tracking-integration'

import {
  DECISION_TYPES,
  FlagDecision,
  EventTrackNotification,
  OFXDecisionSnowplowEvent,
  OFXTrackingSnowplowEvent,
} from '../types'
import { ListenerPayload } from '@optimizely/optimizely-sdk'

/**
 * Optimizely FX Events
 * ============================================================================
 * Supports dispatching events from the OFX SDK
 */

/**
 * Dispatchers
 * ============================================================================
 */

export const noOpDispatcher: EventDispatcher = {
  dispatchEvent(_, callback) {
    callback({ statusCode: 204 })
  },
}

/**
 * Handlers
 * ============================================================================
 * These are handlers for OFX SDK notificationListeners. See documentation:
 * https://docs.developers.optimizely.com/feature-experimentation/docs/notification-listeners
 */

function isDecisionEvent(event: FlagDecision | ListenerPayload): event is FlagDecision {
  return 'type' in event && event.type === DECISION_TYPES.FLAG
}

export function sendSnowplowDecisionEvent(event: FlagDecision | ListenerPayload) {
  if (isDecisionEvent(event)) {
    const { decisionInfo, userId } = event
    queueTrackingEvent<OFXDecisionSnowplowEvent>({
      action: 'OFX_DECISION',
      category: 'OFX',
      properties: {
        allocationId: userId,
        enabled: decisionInfo.enabled,
        flagKey: decisionInfo.flagKey,
        ruleKey: decisionInfo.ruleKey,
        variationKey: decisionInfo.variationKey,
      },
    })
  }
}

export function sendSnowplowTrackingEvent(event: EventTrackNotification) {
  queueTrackingEvent<OFXTrackingSnowplowEvent>({
    action: event.eventKey,
    category: 'OFX',
    properties: {
      allocationId: event.userId,
    },
  })
}
