import React, { useEffect } from 'react'

import { RibbonTriggerContainer } from 'RibbonTrigger'
import classnames from 'classnames'
import { LayoutPageWrapper, VerticalStages, VerticalStagesItem } from 'goustouicomponents'
import PropTypes from 'prop-types'

import Overlay from 'components/Overlay'
import { AgeVerificationPopUp } from 'components/Product/AgeVerification'
import { MultipleBoxesDeliveryMessage } from 'routes/OrderConfirmation/components/MultipleBoxesDeliveryMessage/MultipleBoxesDeliveryMessage'
import { PageLoader } from 'routes/OrderConfirmation/components/PageLoader'

import { AwinPixel } from './components/AwinPixel'
import { Market } from './components/Market'
import { OrderConfirmationHeader } from './components/OrderConfirmationHeader'
import { ReferAFriend } from './components/ReferAFriend'

import css from './OrderConfirmation.css'

const propTypes = {
  ageVerified: PropTypes.bool.isRequired,
  headerDetails: PropTypes.oneOfType([
    PropTypes.shape({
      deliveryDate: PropTypes.string,
      deliveryStart: PropTypes.string,
      deliveryEnd: PropTypes.string,
      whenCutoffTime: PropTypes.string,
      whenCutoffDate: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  showHeader: PropTypes.bool.isRequired,
  showMultipleBoxesDeliveryMessage: PropTypes.bool.isRequired,
  userFetchReferralOffer: PropTypes.func.isRequired,
  userVerifyAge: PropTypes.func.isRequired,
  userLoadData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const defaultProps = {
  headerDetails: {},
}

export const OrderConfirmation = ({
  userFetchReferralOffer,
  userVerifyAge,
  userLoadData,
  ageVerified,
  headerDetails,
  isLoading,
  showHeader,
  showMultipleBoxesDeliveryMessage,
}) => {
  const [hasConfirmedAge, setHasConfirmedAge] = React.useState(false)
  const [showAgeVerification, setShowAgeVerification] = React.useState(false)

  useEffect(() => {
    userFetchReferralOffer()
  }, [userFetchReferralOffer])

  useEffect(() => {
    userLoadData()
  }, [userLoadData])

  const toggleAgeVerificationPopUp = () => {
    setShowAgeVerification(!showAgeVerification)
  }

  const onAgeConfirmation = (isOver18) => {
    setHasConfirmedAge(true)
    userVerifyAge(isOver18, true)
  }

  const isUnderAge = hasConfirmedAge && !ageVerified

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <LayoutPageWrapper padding="large-screens-only">
      <div className={css.pageContent} data-testing="orderConfirmationContainer">
        <Overlay open={showAgeVerification} from="top">
          <AgeVerificationPopUp
            onClose={toggleAgeVerificationPopUp}
            isUnderAge={isUnderAge}
            onAgeConfirmation={onAgeConfirmation}
          />
        </Overlay>

        {showHeader ? (
          <VerticalStages hasFullWidth>
            <VerticalStagesItem title="Order created" isCompleted backgroundColor="lightGrey">
              <div>
                <OrderConfirmationHeader
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...headerDetails}
                />
                {showMultipleBoxesDeliveryMessage && (
                  <MultipleBoxesDeliveryMessage data-testid="multipleDeliveriesAlert" />
                )}
                <div className={classnames(css.mobileShow, css.rafMobile)}>
                  <ReferAFriend />
                </div>
              </div>
            </VerticalStagesItem>

            <VerticalStagesItem
              title="Add desserts, drinks, snacks & more from the Gousto Market"
              isCompleted={false}
            >
              <Market
                ageVerified={ageVerified}
                toggleAgeVerificationPopUp={toggleAgeVerificationPopUp}
              />
            </VerticalStagesItem>
          </VerticalStages>
        ) : (
          <div>
            <h3 className={css.marketPlaceTitle}>Gousto Market</h3>
            <Market
              ageVerified={ageVerified}
              toggleAgeVerificationPopUp={toggleAgeVerificationPopUp}
            />
            <div className={classnames(css.mobileShow, css.rafMobile)}>
              <ReferAFriend />
            </div>
          </div>
        )}

        <AwinPixel />
      </div>
      <RibbonTriggerContainer name="order-confirmation-web" />
    </LayoutPageWrapper>
  )
}

OrderConfirmation.propTypes = propTypes
OrderConfirmation.defaultProps = defaultProps
