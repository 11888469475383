import React from 'react'

import {
  AlignItems,
  Body2,
  BorderStyle,
  Box,
  Color,
  CSSKeywords,
  Display,
  Icon,
  IconVariant,
  Space,
  ScreenReaderLiveRegion,
  Text,
} from '@gousto-internal/citrus-react'
import { RadiusScale } from '@gousto-internal/citrus-react/dist/theme/tokens'

import { useTimeLeftToCheckOut } from 'routes/Checkout/Components/CheckoutUrgency/checkoutUrgencyUtils'

/**
 * Displays banner during every check out step saying "Checkout within {timeLeft} to avoid losing your recipes".
 */

/*
  Component for the c variant of the Checkout - Box Tangibility Experiment
  https://gousto.atlassian.net/jira/software/c/projects/TG/boards/220?selectedIssue=TG-8493
*/
export const UrgencyBannerControl = () => {
  const timeLeft = useTimeLeftToCheckOut()

  return (
    <ScreenReaderLiveRegion urgency="polite">
      <Box paddingV={[2, 2, 4, 4]} paddingH={[2, 2, null]}>
        <Box
          display="flex"
          bg={Color.Warning_50}
          borderStyle={BorderStyle.Solid}
          borderColor={Color.Warning_100}
          borderWidth={0.5}
          borderRadius={3 as RadiusScale}
          paddingV={2}
          paddingH={2}
          alignItems={AlignItems.Center}
        >
          <Icon name="time" variant={IconVariant.Error} />
          <Space size={1} direction="horizontal" />
          <Box paddingV={1}>
            <Body2>Checkout within {timeLeft} to avoid losing your recipes</Body2>
          </Box>
        </Box>
      </Box>
    </ScreenReaderLiveRegion>
  )
}

/*
  Component for the t1 variant of the Checkout - Box Tangibility Experiment
  https://gousto.atlassian.net/jira/software/c/projects/TG/boards/220?selectedIssue=TG-8493
*/

export const UrgencyBannerVariant = () => {
  const timeLeft = useTimeLeftToCheckOut()

  return (
    <ScreenReaderLiveRegion urgency="polite">
      <Box
        display="flex"
        bg={Color.Warning_50}
        borderRadius={10 as RadiusScale}
        paddingV={2}
        paddingH={3}
        gap={1}
        alignItems={AlignItems.Center}
        width="180px"
      >
        <Icon name="time" variant={IconVariant.Error} />
        <Body2 size={1} color={Color.ColdGrey_800}>
          Checkout in{' '}
          <Text display={Display.InlineBlock} size={CSSKeywords.Inherit} color={Color.Error_800}>
            {timeLeft}
          </Text>
        </Body2>
      </Box>
    </ScreenReaderLiveRegion>
  )
}
