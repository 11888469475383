import { canUseWindow } from '@library/environment'

export type TrackingIntegrationWindow = typeof window & {
  dataLayer: Record<string, unknown>[]
}

type DefaultEventTrackingData = {
  action: string
  category?: string
  properties?: Record<string, unknown>
}

/**
 * Queues the specified tracking event to be sent. Does not block on the tracking data actually being sent.
 * Does not throw or provide guarantees that the Snowplow HTTP call succeeds.
 * No-ops on the server.
 */
export function queueTrackingEvent<T = never, E extends T = T>(
  event: E & DefaultEventTrackingData,
) {
  const win = window as TrackingIntegrationWindow

  if (canUseWindow() && win.dataLayer) {
    const { action, category, properties } = event

    const trackingObj = {
      event: 'userAction',
      actionType: action,
      // if category is not passed, default 'funnel' category is used
      category,
      actionValue: JSON.stringify(properties),
    }
    win.dataLayer.push(trackingObj)
  }
}
