import { CSSObject } from '@emotion/react'
import { screen } from '../../styles'

export const cssImageWrapper: CSSObject = {
  height: '100%',
  width: '100%',
  border: 'none',
  background: 'none',
  padding: '0',

  '&:hover': {
    cursor: 'pointer',
  },
}

export const cssImageStyle: CSSObject = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: 'scale(1.1)',
  [`@media ${screen.screenSMMin}`]: {
    position: 'relative',
  },
}

export const cssRecipeImageAndCookingTimeWrapper: CSSObject = {
  height: '100%',
  width: '100%',
}
