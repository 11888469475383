import React from 'react'

import { Body3, Color } from '@gousto-internal/citrus-react'

interface Props {
  textLength: number
  lengthLimit: number
}

const TextLengthCounter = ({ textLength, lengthLimit }: Props) => {
  const currentTextAreaLength = textLength > lengthLimit ? lengthLimit : textLength

  return (
    <Body3 color={Color.ColdGrey_900}>
      {currentTextAreaLength}/{lengthLimit}
    </Body3>
  )
}

export { TextLengthCounter }
