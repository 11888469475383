import React, { useReducer, useMemo } from 'react'
import { Grid, Col, Divider, Join, Space, ModalProvider } from '@gousto-internal/citrus-react'
import { SectionWrapper } from './components/SectionWrapper'
import { AccountDetailsHeader } from './components/AccountDetailsHeader'
import { ResetPasswordSection } from './components/ResetPasswordSection'
import { DeleteAccountSection } from './components/DeleteAccountSection'
import { UserNameSection } from './components/UserNameSection'
import { PhoneNumberSection } from './components/PhoneNumberSection'
import { AccountBalanceSection } from './components/AccountBalanceSection'
import { UserEmailSection } from './components/UserEmailSection'
import { CommunicationPreferencesSection } from './components/CommunicationPreferencesSection'
import { DeliveryInfoSection } from './components/DeliveryInfoSection'
import { AccountOnHold } from './components/AccountOnHold'
import { PaymentInfo, PaymentCard3DSModalProvider } from './components/PaymentInfo'
import { accountDetailsReducer, getInitialReducerArguments } from './reducers/accountDetailsReducer'
import { AccountDetailsContext } from './AccountDetailsContext'

const VERTICAL_GAP = 6

function AccountDetails() {
  const [state, dispatch] = useReducer(accountDetailsReducer, getInitialReducerArguments())
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <AccountDetailsContext.Provider value={contextValue}>
      <ModalProvider>
        <Grid gap={[VERTICAL_GAP, 8]} paddingV={[4, 8]} paddingH={[0, 3]}>
          <Col size={12}>
            <Join with={<Space size={VERTICAL_GAP} />}>
              <AccountOnHold />
              <AccountDetailsHeader />
            </Join>
          </Col>
          <Col size={[12, VERTICAL_GAP]}>
            <Join with={<Space size={VERTICAL_GAP} direction="vertical" />}>
              <SectionWrapper id="personalDetails" title="My personal details">
                <Join with={<Space size={VERTICAL_GAP} />}>
                  <UserNameSection />
                  <PhoneNumberSection />
                  <UserEmailSection />
                  <Divider />
                  <ResetPasswordSection />
                </Join>
              </SectionWrapper>
              <SectionWrapper id="deliveryInfo" title="Delivery info">
                <Space size={4} direction="vertical" />
                <DeliveryInfoSection />
              </SectionWrapper>
              <SectionWrapper id="paymentInfo" title="Payment info">
                <PaymentCard3DSModalProvider>
                  <PaymentInfo />
                </PaymentCard3DSModalProvider>
              </SectionWrapper>
              <SectionWrapper id="communicationPreferences" title="Communication preferences">
                <CommunicationPreferencesSection />
              </SectionWrapper>
              <SectionWrapper id="shippingAddress" title="Delete your account">
                <DeleteAccountSection />
              </SectionWrapper>
            </Join>
          </Col>
          <Col size={[12, VERTICAL_GAP]}>
            <SectionWrapper id="accountBalance" title="Account balance">
              <Space size={2} direction="vertical" />
              <AccountBalanceSection />
            </SectionWrapper>
          </Col>
        </Grid>
      </ModalProvider>
    </AccountDetailsContext.Provider>
  )
}

export { AccountDetails }
