import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { fetchApplePayStatus } from 'routes/Checkout/checkoutActions'

/**
 * Component wrapper for useFetchApplePayStatus hook.
 * Hook should be called from root "AppContainer" component, but that component provides Redux store only to rendered
 * content, making impossible to use hook in "AppContainer" function body. Thus, component wrapper is necessary.
 */
export const ApplePayStatusFetch = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchApplePayStatus())
  }, [dispatch])

  return null
}
