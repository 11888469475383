import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

export function useIsMenuPersonalisationEnabled() {
  const [isMenuPersonalisationEnabled] = useDecision('beetroots_ofx_menu_personalisation')
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) return false

  return isMenuPersonalisationEnabled.variationKey === 't1'
}
