import {
  Box,
  Grid,
  Col,
  FlexDirection,
  JustifyContent,
  Color,
  Space,
  BorderStyle,
  Display,
  AlignItems,
  Join,
  Button,
} from '@gousto-internal/citrus-react'
import { Order } from '@library/api-user'
import React, { useCallback } from 'react'
import { RecipeImageTiles } from './RecipeImageTiles'
import { useOrderTile, UseOrderTileResult } from '../hooks/useOrderTile'
import { DiscountPercentage, DiscountPrice } from './Discount'
import {
  OrderDetailExpandButton,
  OrderDetailHeadingsForRecipesChosen,
  OrderDetailStatusForRecipesChosen,
  OrderDetailWithoutRecipesChosen,
} from './OrderDetail'
import { useTracking } from '../hooks/useTracking'
import { browserHistory } from 'react-router'
import { OrderExpanded } from './OrderExpanded'

type Props = {
  order: Order
}

function renderExpanded(order: UseOrderTileResult['data']) {
  return (
    <Box>
      <Space size={8} direction="vertical" />
      <Box
        height="1px"
        width="100%"
        borderStyle={BorderStyle.Solid}
        borderBottomWidth={0.5}
        borderColor={Color.ColdGrey_100}
      />
      <Box>
        <Space size={6} direction="vertical" />
        <OrderExpanded
          humanDeliveryDayTime={order.humanDeliveryDayTime}
          address={{
            lineOne: order.shippingAddress?.line1 || '',
            town: order.shippingAddress?.town || '',
            postcode: order.shippingAddress?.postcode || '',
          }}
        />
      </Box>
    </Box>
  )
}

function renderOrderWithRecipes(
  order: UseOrderTileResult['data'],
  trackPlates: ReturnType<typeof useTracking>['trackPlates'],
  handleGetHelpClick: () => void,
  isOrderExpanded: boolean,
  setIsOrderExpanded: (isExpanded: boolean) => void,
) {
  return (
    <Box
      width={'100%'}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      borderStyle={BorderStyle.Solid}
      paddingV={6}
      paddingH={6}
      bg={Color.White}
    >
      <Grid width="100%" gap={[0, 10]}>
        <Col size={[12, 3]}>
          <Box display={'flex'} flexDirection={FlexDirection.Column} height={'100%'}>
            <Join with={<Space size={6} direction="vertical" />}>
              <Box
                display={'flex'}
                flexDirection={FlexDirection.Row}
                justifyContent={JustifyContent.SpaceBetween}
                height={'100%'}
              >
                <Box
                  height={'100%'}
                  display={'flex'}
                  flexDirection={FlexDirection.Column}
                  justifyContent={JustifyContent.SpaceBetween}
                >
                  <OrderDetailHeadingsForRecipesChosen
                    humanDeliveryDate={order.humanDeliveryDate}
                    orderPhaseContent={order.orderPhaseContent}
                    recipeCount={order.recipeItems.length}
                    productCount={order.productItems.length}
                  />
                  <OrderDetailStatusForRecipesChosen
                    orderId={order.orderId}
                    isOrderDelivered={order.isOrderDelivered}
                    shouldCutOffTime={order.shouldCutOffTime}
                    shouldCutOffDate={order.shouldCutOffDate}
                    handleGetHelpClick={handleGetHelpClick}
                  />
                </Box>
                <Box display={['flex', 'none']} flexDirection={FlexDirection.Row}>
                  <DiscountPrice
                    priceWithDiscount={order.price.priceWithDiscount}
                    priceWithoutDiscount={order.price.priceWithoutDiscount}
                  />
                  <OrderDetailExpandButton
                    isOrderExpanded={isOrderExpanded}
                    setIsOrderExpanded={setIsOrderExpanded}
                  />
                </Box>
              </Box>
            </Join>
          </Box>
        </Col>
        <Col size={[12, 7]}>
          <RecipeImageTiles
            orderId={order.orderId}
            recipes={order.recipeItems}
            box={order.box}
            isOrderEditable={order.isOrderEditable}
            trackPlateClick={trackPlates}
          />
        </Col>
        <Col size={2}>
          <Box
            display={['none', 'flex']}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.FlexEnd}
            paddingRight={5}
            alignItems={AlignItems.Center}
            height={'100%'}
          >
            <Join with={<Space size={6} direction="horizontal" />}>
              <DiscountPrice
                priceWithDiscount={order.price.priceWithDiscount}
                priceWithoutDiscount={order.price.priceWithoutDiscount}
              />
              <OrderDetailExpandButton
                isOrderExpanded={isOrderExpanded}
                setIsOrderExpanded={setIsOrderExpanded}
              />
            </Join>
            <div />
          </Box>
        </Col>
      </Grid>
      {isOrderExpanded && renderExpanded(order)}
    </Box>
  )
}

function renderWithoutRecipes(
  order: UseOrderTileResult['data'],
  trackPlates: () => void,
  handleChooseRecipes: () => void,
  isOrderExpanded: boolean,
  setIsOrderExpanded: (isExpanded: boolean) => void,
) {
  return (
    <Box
      width={'100%'}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      borderStyle={BorderStyle.Solid}
      paddingV={6}
      paddingH={6}
      bg={Color.White}
    >
      <Grid width="100%" gap={[0, 10]}>
        <Col size={[12, 7]}>
          <Box display={'flex'} flexDirection={FlexDirection.Column}>
            <Join with={<Space size={6} direction="vertical" />}>
              <OrderDetailWithoutRecipesChosen
                humanDeliveryDate={order.humanDeliveryDate}
                orderPhaseContent={order.orderPhaseContent}
                shouldCutOffTime={order.shouldCutOffTime}
                shouldCutOffDate={order.shouldCutOffDate}
                orderDiscount={order.orderDiscount}
              />
              <RecipeImageTiles
                orderId={order.orderId}
                recipes={order.recipeItems}
                box={order.box}
                isOrderEditable={order.isOrderEditable}
                trackPlateClick={trackPlates}
              />
              <Button onClick={handleChooseRecipes}>Choose recipes</Button>
            </Join>
          </Box>
        </Col>
        <Col size={[12, 4]}>
          <Box
            borderStyle={BorderStyle.Dashed}
            borderColor={Color.ColdGrey_800}
            borderWidth={0.5}
            height="100%"
          />
        </Col>
        <Col size={1}>
          <Box
            display={['none', 'flex']}
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.SpaceBetween}
            alignItems={AlignItems.Center}
            height={'100%'}
          >
            <DiscountPercentage discount={order.orderDiscount} />
            <OrderDetailExpandButton
              isOrderExpanded={isOrderExpanded}
              setIsOrderExpanded={setIsOrderExpanded}
            />
            <div />
          </Box>
        </Col>
      </Grid>
      {isOrderExpanded && renderExpanded(order)}
      <Space size={6} direction="vertical" />
    </Box>
  )
}

export const OrderTile = ({ order }: Props) => {
  const { data, isOrderExpanded, setIsOrderExpanded } = useOrderTile(order)
  const { trackChooseRecipes, trackPlates, trackGetHelp } = useTracking(order)
  const { orderId } = data

  const handleChooseRecipes = useCallback(() => {
    trackChooseRecipes()
    browserHistory.push(`/menu/${orderId}`)
  }, [trackChooseRecipes, orderId])

  const handleGetHelpClick = useCallback(() => {
    trackGetHelp()
    browserHistory.push(`/get-help?orderId=${orderId}`)
  }, [orderId, trackGetHelp])

  if (!data.isAwaitingChoices) {
    return renderOrderWithRecipes(
      data,
      trackPlates,
      handleGetHelpClick,
      isOrderExpanded,
      setIsOrderExpanded,
    )
  }

  if (data.isOrderDelivered) {
    return renderOrderWithRecipes(
      data,
      trackPlates,
      handleGetHelpClick,
      isOrderExpanded,
      setIsOrderExpanded,
    )
  }

  return renderWithoutRecipes(
    data,
    trackPlates,
    handleChooseRecipes,
    isOrderExpanded,
    setIsOrderExpanded,
  )
}
