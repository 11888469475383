import styled from '@emotion/styled'
import { Link } from 'react-router'

export const StyledLink = styled(Link)`
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #615cff;
  &:hover {
    color: #412dee;
  }
`
