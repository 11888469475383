import React from 'react'
import {
  AlignItems,
  Bold,
  Box,
  Button,
  Color,
  colors,
  Display,
  Icon,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { CTAs } from '../../config'

export type CTAProps = {
  onCTAClick: () => void
  signupCTA: string
}

export function CTA({ onCTAClick, signupCTA }: CTAProps) {
  const { ctaBoldText, ctaText } = CTAs

  return (
    <Box data-testid="cta">
      <Space size={4} />
      <Box
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
      >
        <Button onClick={onCTAClick} width={['380px', '343px']} height="48px">
          {signupCTA}
        </Button>
      </Box>
      <Space size={3} />
      <Box
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
      >
        <Icon name="play_pause" size={6} style={{ color: colors[Color.White] }} />
        <Space direction="horizontal" size={2} />
        <Text size={4} color={Color.White}>
          <Bold>{ctaBoldText}</Bold>
          {ctaText}
        </Text>
      </Box>
      <Space size={4} />
    </Box>
  )
}
