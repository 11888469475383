import { connect } from 'react-redux'
import { projectedOrderRestore, restoreCancelledOrder } from 'actions/order'
import { getCancelledOrderError, isCancelledOrderPending } from 'selectors/order'
import OrderRestoreButton from './OrderRestoreButton'

function mapStateToProps(state, ownProps) {
  return {
    error: getCancelledOrderError(state, ownProps.orderId),
    pending: isCancelledOrderPending(state),
  }
}

const OrderRestoreButtonContainer = connect(mapStateToProps, {
  projectedOrderRestore,
  restoreCancelledOrder,
})(OrderRestoreButton)

export default OrderRestoreButtonContainer
