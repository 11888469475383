import React from 'react'

import classnames from 'classnames'
import Immutable from 'immutable'

import { Crop } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import GoustoImage from 'components/Image'

import { SoldOutOverlay } from '../../Recipe/SoldOutOverlay'
import { DetailViewType } from '../types'

import css from './DetailImage.css'

type DetailImageProps = {
  media: Crop[]
  title?: string
  view?: DetailViewType
  mouseEnter?: () => void
  mouseLeave?: () => void
  maxMediaSize?: number | null
  lazy?: boolean
}

export const DetailImage = ({
  media,
  title = '',
  view = 'grid',
  mouseEnter = () => {},
  mouseLeave = () => {},
  maxMediaSize = null,
  lazy = true,
}: DetailImageProps) => (
  <div
    className={classnames(
      { [css[view]]: ['list', 'fineDineIn'].indexOf(view) !== -1 },
      { [css.grid]: ['list', 'fineDineInDetail'].indexOf(view) === -1 },
      { [css.detail]: view === 'detail' },
      { [css.fineDineInDetail]: view === 'fineDineInDetail' },
      { [css.simple]: view === 'simple' },
      css.placeholder,
    )}
    onMouseEnter={mouseEnter}
    onMouseLeave={mouseLeave}
  >
    {/* Showing SoldOutOverlay when the recipe does not have media is slightly
    misleading. Maybe it is worth bringing special replacer instead? */}
    {media.length > 0 && <SoldOutOverlay />}
    {media.length > 0 && (
      <GoustoImage
        media={Immutable.fromJS(media)}
        title={title}
        maxMediaSize={maxMediaSize}
        className={css.recipeImg}
        lazy={lazy}
      />
    )}
  </div>
)
