import { composeFetch } from '@library/http'
import {
  addPath,
  auth,
  composeRequest,
  setMethod,
  setQueryParam,
  setServiceUrl,
} from '@library/http/requests'
import { parseGoustoBasicResponse } from '@library/http/src/parsers'

type QueryParam = { userId?: string }

const fetchSubscriptionOptions = composeFetch(
  composeRequest(
    setServiceUrl('subscriptionquery', 1),
    setQueryParam<QueryParam>('userId', (payload) => {
      return typeof payload.userId === 'string' && payload.userId.length ? payload.userId : ''
    }),
    addPath('subscription-options'),
    setMethod('GET'),
    auth,
  ),
  parseGoustoBasicResponse,
)

export { fetchSubscriptionOptions }
