import React from 'react'

import Immutable from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import { DietaryPreferences } from '@features/dietary-preferences'

import {
  signupSetDietaryPreferences,
  signupSetIsDietaryConfirmed,
} from 'routes/Signup/signupActions'
import { getSignupDietaryPreferences } from 'routes/Signup/signupSelectors'

type DietaryPreferencesStepProps = {
  next: () => void
}

export const DietaryPreferencesStep = ({ next: _next }: DietaryPreferencesStepProps) => {
  const dispatch = useDispatch()
  const dietaryPreferences: Immutable.List<string> = useSelector(getSignupDietaryPreferences)

  const handleSkipCTAClick = (dietaryPreferencesChange: Immutable.List<string>) => {
    dispatch(signupSetIsDietaryConfirmed(false))
    dispatch(signupSetDietaryPreferences(dietaryPreferencesChange))
    _next()
  }

  const handleContinueCTAClick = (dietaryPreferencesChange: Immutable.List<string>) => {
    dispatch(signupSetIsDietaryConfirmed(true))
    dispatch(signupSetDietaryPreferences(dietaryPreferencesChange))
    _next()
  }

  return (
    <DietaryPreferences
      onContinue={handleContinueCTAClick}
      onSkip={handleSkipCTAClick}
      defaultDietaryPreferences={dietaryPreferences}
    />
  )
}
