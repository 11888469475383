import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import { SignupDietaryPreferencesHeader } from 'routes/Menu/components/SignupDietaryPreferencesHeader'

import { DetailOverlay } from '../../components/Detail'
import { RecipeList } from '../RecipeList'
import { RecipeGridDescription } from './RecipeGridDescription'

import css from '../../Menu.css'

class RecipeGrid extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowOverlay: false,
    }
  }

  componentDidMount() {
    this.setState({ shouldShowOverlay: true })
  }

  render() {
    const { shouldShowOverlay } = this.state
    const { menuCurrentCollectionId, menuPreferences, signupDietaryPreferencesEnabled } = this.props

    return (
      <div className={css.menuContainer} data-testing="menuRecipesList">
        {menuCurrentCollectionId === '97270056-cd65-11e8-a2d2-067a72dd5dba' && (
          <RecipeGridDescription
            title="Based on what you've had before"
            text="We think you'll love these recipes"
          />
        )}
        {signupDietaryPreferencesEnabled && <SignupDietaryPreferencesHeader />}
        <RecipeList menuPreferences={menuPreferences} />
        <DetailOverlay showOverlay={shouldShowOverlay} />
      </div>
    )
  }
}

RecipeGrid.propTypes = {
  query: PropTypes.shape({
    collection: PropTypes.string,
  }),
  menuCurrentCollectionId: PropTypes.string,
  menuPreferences: PropTypes.shape({
    display_preferences: PropTypes.bool,
  }),
  signupDietaryPreferencesEnabled: PropTypes.bool.isRequired,
}

RecipeGrid.defaultProps = {
  query: {},
  menuCurrentCollectionId: '',
  menuPreferences: null,
}

export { RecipeGrid }
