import React from 'react'

import { CSSObject } from '@emotion/react'

import { Box, Color, Display, withCitrusTheme } from '@gousto-internal/citrus-react'

import { Benefits } from './components/Benefits'
import { Video } from './components/Video'

export type ValueYouCanTasteSectionProps = {
  signupCTA: string
  onCTAClick: () => void
}

const Container = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(['column-reverse', 'row'], ['flexDirection']) as CSSObject),
}))

export function ValueYouCanTasteSection({ signupCTA, onCTAClick }: ValueYouCanTasteSectionProps) {
  return (
    <section data-testid="valueYouCanTaste">
      <Container bg={Color.ColdGrey_800} display={Display.Flex} height={[null, '480px']}>
        <Box width={[null, '50%']}>
          <Video />
        </Box>
        <Box width={[null, '50%']}>
          <Benefits ctaText={signupCTA} onCTAClick={onCTAClick} />
        </Box>
      </Container>
    </section>
  )
}
