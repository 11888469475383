import React from 'react'

import {
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  AlignItems,
  Icon,
  IconVariant,
  Color,
  Text,
  FontWeight,
  TextAlign,
} from '@gousto-internal/citrus-react'

import { TransparencyItemTextContainer } from './styled'

type SubscriptionTransparencyItemProps = {
  highlightedText: string
  regularText: string
}

export const SubscriptionTransparencyItem = ({
  highlightedText,
  regularText,
}: SubscriptionTransparencyItemProps) => (
  <Box
    width="100%"
    display={Display.Flex}
    flexDirection={FlexDirection.Row}
    alignItems={AlignItems.FlexStart}
    justifyContent={JustifyContent.SpaceBetween}
  >
    <Icon name="tick" variant={IconVariant.Confirmation} />
    <TransparencyItemTextContainer>
      <Text
        textAlign={TextAlign.Center}
        color={Color.ColdGrey_800}
        size={2}
        display={Display.Inline}
      >
        <Text
          size={4}
          fontWeight={FontWeight.Bold}
          color={Color.Success_800}
          display={Display.Inline}
        >
          {highlightedText}
        </Text>{' '}
        {regularText}
      </Text>
    </TransparencyItemTextContainer>
  </Box>
)
