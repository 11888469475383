import optimizelySdk, { enums } from '@optimizely/optimizely-sdk'

import { DDInstrumentRolloutDecision } from '@library/observability/browser'

import { getEnvironment } from 'utils/isomorphicEnvironment'
import { optimizelyRolloutsConfig } from '../../config/head/optimizelyRollouts'

export const timeout = 5000

export class OptimizelySDK {
  optimizelyRolloutsInstance = null

  validInstance = false

  loading = false

  async getOptimizelyInstance() {
    const currentEnvironment = getEnvironment()

    if (!this.hasInstance()) {
      this.loading = true
      this.optimizelyRolloutsInstance = optimizelySdk.createInstance({
        sdkKey: optimizelyRolloutsConfig[currentEnvironment] || optimizelyRolloutsConfig.staging,
        logLevel: currentEnvironment === 'local' ? enums.LOG_LEVEL.INFO : enums.LOG_LEVEL.ERROR,
        eventBatchSize: 10,
        eventFlushInterval: 1000,
      })
      try {
        if (!this.optimizelyRolloutsInstance) {
          throw new Error('Failed to create Optimizely Rollouts instance')
        }

        this.instrumentDecisionNotification(this.optimizelyRolloutsInstance)

        const { success } = await this.optimizelyRolloutsInstance.onReady({ timeout })
        this.validInstance = success
      } catch (err) {
        this.validInstance = false
      } finally {
        this.loading = false
      }
    }

    return this.optimizelyRolloutsInstance
  }

  hasInstance() {
    return !!this.optimizelyRolloutsInstance
  }

  hasValidInstance() {
    return this.validInstance
  }

  isLoading() {
    return this.loading
  }

  /**
   * Adds DataDog instrumentation to 'decision' notifications
   */
  instrumentDecisionNotification(optimizelyRolloutsInstance) {
    optimizelyRolloutsInstance.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      (payload) => {
        DDInstrumentRolloutDecision(payload.decisionInfo)
      },
    )
  }
}

const instanceSDK = new OptimizelySDK()

export const instance = instanceSDK
export const hasValidInstance = () => instanceSDK.hasValidInstance()
export const hasInstance = () => instanceSDK.hasInstance()
export const getOptimizelyInstance = () => instanceSDK.getOptimizelyInstance()
export const isLoading = () => instanceSDK.isLoading()
