import { datadogLogs } from '@datadog/browser-logs'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

/**
 * Validates Apple Pay session and completes Apple Pay merchant validation.
 */
export const validateApplePaySessionForBraintree: CheckoutActionCreator =
  (
    event: ApplePayJS.ApplePayValidateMerchantEvent,
    session: ApplePaySession,
    applePayInstance: braintree.ApplePay,
  ) =>
  async (dispatch) => {
    try {
      const data = await applePayInstance.performValidation({
        validationURL: event.validationURL,
        displayName: 'Gousto payment',
      })
      session.completeMerchantValidation(data)
    } catch (e) {
      dispatch(statusActions.error(actionTypes.APPLE_PAY_MERCHANT_VALIDATION_ERROR, true))
      datadogLogs.logger.error(`apple pay validate merchant failed with ${JSON.stringify(e)}`)
      dispatch(
        trackCheckoutError(
          actionTypes.APPLE_PAY_MERCHANT_VALIDATION_ERROR,
          true,
          'validateApplePaySession',
        ),
      )
      session.abort()
    }
  }
