import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Text,
} from '@gousto-internal/citrus-react'

export const notPrimaryMenuWeekWarningCopy = {
  title: 'Our recipes change every Tuesday!',
  body: 'You’ve chosen a delivery date for a new menu week. Your recipes will be removed and you will need to choose new recipes',
}

export const NotPrimaryMenuWeekWarning = () => (
  <Box
    bg={Color.Warning_50}
    display={Display.Flex}
    paddingH={4}
    paddingV={4}
    alignItems={AlignItems.FlexStart}
    borderColor={Color.Warning_100}
    borderWidth={0.5}
    borderStyle={BorderStyle.Solid}
    borderRadius={1.5}
    gap={2}
    style={{ marginBottom: '12px' }}
  >
    <Icon name="warning" variant={IconVariant.Error} />
    <Box>
      <Text size={2} fontFamily={FontFamily.Bold}>
        {notPrimaryMenuWeekWarningCopy.title}
      </Text>
      <Text size={2}>{notPrimaryMenuWeekWarningCopy.body}</Text>
    </Box>
  </Box>
)
