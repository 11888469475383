import React, { useRef, useEffect } from 'react'

import { Box, Link, Space } from '@gousto-internal/citrus-react'

export const CheckoutTrustPilot = () => {
  const ref = useRef(null)

  useEffect(() => {
    // widget may not load if the bootstrapper script has been loaded before the component gets rendered
    // we use the TrustPilot loadFromElement function to manually load the widget if this happens
    // see more https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#routing-in-single-page-applications-3

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <Box paddingV={6} paddingH={4} paddingRight={0}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="512cda6e000064000522fb6a"
        data-style-height="120px"
        data-style-width="100%"
        data-theme="light"
      >
        <Space direction="horizontal" size={2} />
        <Link href="https://uk.trustpilot.com/review/gousto.co.uk" target="_blank">
          Trustpilot
        </Link>
      </div>
    </Box>
  )
}
