import { isServer } from '@library/environment'

import { actionTypes } from 'actions/actionTypes'
import { basketPreviewOrderChange } from 'actions/basket'
import statusActions from 'actions/status'
import { createPreviewOrder } from 'apis/orders'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import {
  getCouldBasketBeExpired,
  getOrderDetails,
  getSlotForBoxSummaryDeliveryDays,
} from 'routes/Menu/selectors/order'
import { getAuthUserId } from 'selectors/auth'
import logger from 'utils/logger'

export const checkoutCreatePreviewOrder: CheckoutActionCreator =
  () => async (dispatch, getState) => {
    const userId = getAuthUserId(getState())
    const [slot, slotId] = getSlotForBoxSummaryDeliveryDays(getState())

    dispatch(statusActions.pending(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, true))
    dispatch(statusActions.error(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, null))

    const state = getState()

    if (!slot) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error({ message: `Can't find any slot with id: ${slotId}`, actor: userId })

      dispatch(statusActions.pending(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, false))
      dispatch(
        statusActions.error(
          actionTypes.BASKET_PREVIEW_ORDER_CHANGE,
          `Can't find any slot with id: ${slotId}`,
        ),
      )

      return
    }

    const orderDetails = getOrderDetails(state)

    const couldBasketBeExpired = getCouldBasketBeExpired(getState())

    if (couldBasketBeExpired) {
      const { router: { locationBeforeTransitions: { pathName: path } = {} } = {} } = getState()

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning({
        message: 'Missing data, persistent basket might be expired',
        actor: userId,
        extra: {
          deliveryDayId: orderDetails.delivery_day_id,
          deliverySlotId: orderDetails.delivery_slot_id,
          recipeChoices: orderDetails.recipe_choices,
          path,
          serverSide: isServer(),
        },
      })

      dispatch(
        statusActions.error(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, {
          message: 'Missing data, persistent basket might be expired',
          code: 'basket-expired',
        }),
      )
    }

    try {
      const { data: previewOrder = {} } = await createPreviewOrder(orderDetails)
      dispatch(
        basketPreviewOrderChange(
          String(previewOrder.order.id),
          previewOrder.order.boxId,
          previewOrder.surcharges,
        ),
      )
      dispatch(statusActions.error(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, null))
    } catch (e) {
      const { message, code } = e as any
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning(message)

      const { router: { locationBeforeTransitions: { pathName: path } = {} } = {} } = getState()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error({
        message: 'createPreviewOrder failed, logging error below...',
        actor: userId,
        extra: {
          orderDetails,
          path,
          serverSide: isServer(),
        },
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error(e)

      dispatch(statusActions.error(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, { message, code }))
    }

    dispatch(statusActions.pending(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, false))
  }
