import React from 'react'

import PropTypes from 'prop-types'

import { HeadingWithSeparator } from './HeadingWithSeparator'
import { LoginForm } from './LoginForm'

const defaultProps = {
  title: 'Log in',
}
const propTypes = {
  title: PropTypes.string,
}

const LoginWrapper = ({ title }) => (
  <>
    <HeadingWithSeparator>{title}</HeadingWithSeparator>
    <LoginForm />
  </>
)

LoginWrapper.defaultProps = defaultProps
LoginWrapper.propTypes = propTypes

export { LoginWrapper }
