import { isServer } from '@library/environment'

let token: AuthToken | null = null

export type AuthToken = string & { __brand: 'AuthToken' }

export function setAuthToken(input: string | null) {
  token = input as AuthToken
}

const logWithFormatting = (message: string) => {
  const formattedMessage = {
    log_level: 'WARNING',
    service: 'webclient',
    message,
  }

  console.warn(JSON.stringify(formattedMessage))
}

export function getAuthToken() {
  if (isServer()) {
    logWithFormatting('getAuthToken should not be called server-side!')

    return null
  }

  return token || null
}
