type SignUpPromoCode = string
type HomepageDiscountBannerText = string
type PromocodeDescriptionLines = string[]

export interface PromoCodeData {
  defaultPromoCode: SignUpPromoCode
  defaultBannerText: HomepageDiscountBannerText
  defaultDescriptionLines: PromocodeDescriptionLines
}

// Constants
export const hero = {
  header: 'Mouthwatering home-cooked meals',
  subheader: 'Over 250 recipes a month from just £2.99 a meal*',
  benefits: '85+ veggie (incl vegan) recipes a month',
}

export const PROMO_CODE_DATA: PromoCodeData = {
  // @todo BEET-282 check if we need to amend to DTI-HP-ST20C
  defaultPromoCode: 'DTI-SB-602020',
  defaultBannerText: 'Get 60% off your first order and 20% off your first two months',
  defaultDescriptionLines: [
    `Get 60% off your first order!`,
    `Then 20% off all other boxes you order in your first two months.`,
  ],
}

export const BRAND_PPC_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEA-BR-ST20',
  defaultBannerText: 'Get 60% off your first order and 20% off your first two months',
  defaultDescriptionLines: [
    `Get 60% off your first order!`,
    `Then 20% off all other boxes you order in your first two months.`,
  ],
}

export const DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-HP-ST20N',
  defaultBannerText: 'Get 60% off your first box and more discounts on your boxes for 2 months!',
  defaultDescriptionLines: ['placeholder2', 'placeholder3'],
}

export const BRAND_PPC_DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEA-BR-ST20N',
  defaultBannerText: 'Get 60% off your first box and more discounts on your boxes for 2 months!',
  defaultDescriptionLines: ['placeholder2', 'placeholder3'],
}

export const FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-HP-ST20CFD',
  defaultBannerText:
    'Get 60% off your first order and 20% off your first two months - plus FREE delivery for a year!',
  defaultDescriptionLines: ['placeholder2', 'placeholder3'],
}

export const BRAND_PPC_FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEA-BR-ST20CFD',
  defaultBannerText:
    'Get 60% off your first order and 20% off your first two months - plus FREE delivery for a year!',
  defaultDescriptionLines: ['placeholder2', 'placeholder3'],
}

export const DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-HP-ST20NFD',
  defaultBannerText:
    'Get 60% off your first box and more discounts on your boxes for 2 months - plus FREE delivery for a year!',
  defaultDescriptionLines: ['placeholder2', 'placeholder3'],
}

export const BRAND_PPC_DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'GB-SEA-BR-ST20NFD',
  defaultBannerText:
    'Get 60% off your first box and more discounts on your boxes for 2 months - plus FREE delivery for a year!',
  defaultDescriptionLines: ['placeholder2', 'placeholder3'],
}

export const GIFTING_EXPERIMENT_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-HP-GFT60',
  defaultBannerText:
    'Get 60% off your first order plus 20% off for two months and FREE DESSERT for three months**',
  defaultDescriptionLines: [
    `Get 60% off your first order!`,
    `Then 20% off all other boxes you order in your first two months and FREE DESSERT for three months.`,
  ],
}

export const RAF_LANDING_PAGE_EXPERIMENT_PROMO_CODE_DATA: PromoCodeData = {
  defaultPromoCode: 'DTI-SB-602020',
  defaultBannerText: 'Get 65% off your first order and 25% off your first two months',
  defaultDescriptionLines: [
    `Get 65% off your first order!`,
    `Then 25% off all other boxes you order in your first two months.`,
  ],
}

// This is a Brand PPC version of the discount with a free gift
export const BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA = {
  defaultPromoCode: 'GB-DINNER-SEA-BR-GFT-6020',
}

export const knownVariants = ['default', 'tv']

export const defaultVariant = 'default'

export const seo = {
  title: 'Recipe Boxes | Get Fresh Food & Recipes Delivered | Gousto',
  meta: [
    {
      name: 'description',
      content:
        'Change the way you eat with our easy to follow recipes. We deliver fresh boxes of ingredients and delicious recipes 7 days a week. Get started now!',
    },
    {
      name: 'keywords',
      content: 'Gousto, recipe delivery, ingredients, fresh, healthy food, cooking, recipe box',
    },
  ],
}

export const homeConfig = {
  hero,
  PROMO_CODE_DATA,
  knownVariants,
  defaultVariant,
  seo,
}
