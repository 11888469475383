import { Box, Display, AlignItems, Color, Icon, IconVariant } from '@gousto-internal/citrus-react'
import * as React from 'react'

export function CollapsedView(props: { children: React.ReactNode }) {
  return (
    <Box display={Display.Flex} alignItems={AlignItems.Center} gap={3}>
      <Box display={Display.Flex} flexGrow={0} color={Color.Warning_500}>
        <Icon name="info" variant={IconVariant.Inherit} />
      </Box>
      {props.children}
    </Box>
  )
}
