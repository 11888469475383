import isomorphicFetch from 'isomorphic-fetch'
import { Dispatch } from 'redux'

import { endpoint } from '@library/endpoint'

import * as orderApiV1 from 'apis/orders'
import * as userApiV1 from 'apis/user'
import { isOptimizelyFeatureEnabledFactory } from 'containers/OptimizelyRollouts'
import { getAccessToken } from 'selectors/auth'
import { getUserId } from 'selectors/user'
import { fetch, cacheDefault } from 'utils/fetch'

import { OrderV2 } from '../domains/orders/v2'
import { getOrderV2, getProductsV2, getUpdateOrderProductItemsOrderV1 } from '../selectors/order'
import { getRequestHeaders } from './_utils'
import { post } from './fetch'
import { transformOrderV2ToOrderV1 } from './ordersV2toV1'

/*
  TODO: We need to type the global state - setting to any for now to satisfy TS
*/
type State = Record<string, any>

export const createOrder = async (
  accessToken: string,
  order: Record<string, any>,
  userId: string,
) => {
  const headers = getRequestHeaders(userId)

  const response = await fetch(
    accessToken,
    `${endpoint('order', 2)}/orders`,
    { data: order },
    'POST',
    cacheDefault,
    headers,
  )

  const {
    data: { data: orderResponse },
  } = response

  return orderResponse
}

export const getOrderPrice = async (
  accessToken: string,
  order: Record<string, any>,
  userId: string,
) => {
  const headers = getRequestHeaders(userId)

  return post({ accessToken, userId }, `${endpoint('order', 2)}/prices`, { data: order }, headers)
}

function generateUpdateOrderRequestPayload(
  state: State,
  orderId: string,
  orderUpdates: Record<string, any>,
) {
  /**
   * This is constructing an Order V2-like data structure using data from the basket.
   *
   * This assumes you're updating an order from the menu itself (where order data is
   * loaded into the basket).
   *
   * This needs revisiting as you will not always be editing an order from the menu
   * (i.e. the Upcoming Deliveries page).
   *
   * The only reason this method works is that order updates are passed in as an
   * argument.
   */
  const requestPayload = getOrderV2(state as { basket: any }) as unknown as OrderV2

  requestPayload.id = requestPayload.id ?? orderId

  const {
    delivery_slot_id: deliverySlotId,
    delivery_day_id: deliveryDayId,
    day_slot_lead_time_id: daySlotLeadTimeId,
    shipping_address_id: shippingAddressId,
  } = orderUpdates

  const hasOrderUpdatesToApply = Object.keys(orderUpdates).length

  if (hasOrderUpdatesToApply && !requestPayload.relationships) {
    requestPayload.relationships = {} as OrderV2['relationships']
  }

  if (deliverySlotId) {
    requestPayload.relationships.delivery_slot = {
      data: {
        id: deliverySlotId,
        type: 'delivery-slot',
        meta: {},
      },
    }
  }

  if (deliveryDayId) {
    requestPayload.relationships.delivery_day = {
      data: {
        id: deliveryDayId,
        type: 'delivery-day',
        meta: {},
      },
    }
  }

  if (daySlotLeadTimeId) {
    requestPayload.relationships.day_slot_lead_time = {
      data: {
        id: daySlotLeadTimeId,
        type: 'day-slot-lead-time',
        meta: {},
      },
    }
  }

  if (shippingAddressId) {
    requestPayload.relationships.shipping_address = {
      data: {
        id: shippingAddressId,
        type: 'shipping-address',
        meta: {},
      },
    }
  }

  return requestPayload
}

export async function updateOrder(
  dispatch: Dispatch,
  getState: () => State,
  orderId: string,
  orderUpdates: Record<string, any> = {},
) {
  const state = getState()
  const accessToken = getAccessToken(state)
  const userId = getUserId(state)
  const headers = getRequestHeaders(userId)
  const url = `${endpoint('order', 2)}/orders/${orderId}`
  const updateOrderRequestPayload = generateUpdateOrderRequestPayload(state, orderId, orderUpdates)

  return new Promise((resolve, reject) =>
    isomorphicFetch(url, {
      method: 'PUT',
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({ data: updateOrderRequestPayload }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse?.errors) {
          let error = jsonResponse?.errors
          error = Array.isArray(error) ? error.pop() : error

          return reject(new Error(error.detail || error.message || 'OrderUpdateError'))
        }
        const fromV2 = transformOrderV2ToOrderV1(jsonResponse.data, jsonResponse.included)

        return resolve({ ...jsonResponse, data: fromV2 })
      })
      .catch((error) => {
        reject(error)
      }),
  )
}

export async function patchOrderProducts(
  dispatch: Dispatch,
  getState: () => State,
  orderId: string,
) {
  const state = getState()
  const accessToken = getAccessToken(state)
  const userId = getUserId(state)

  const useOrderApiV2PutforMarketplace = await isOptimizelyFeatureEnabledFactory(
    'radishes_order_api_v2_putmarketplace_web_enabled',
  )(dispatch, getState)

  const useOrderApiV2Get = await isOptimizelyFeatureEnabledFactory(
    'radishes_order_api_v2_getorder_web_enabled',
  )(dispatch, getState)

  if (!useOrderApiV2PutforMarketplace || !useOrderApiV2Get) {
    const productInformation = getUpdateOrderProductItemsOrderV1(state as { basket: any })

    return orderApiV1.updateOrderItems(accessToken, orderId, productInformation)
  }

  const orderProducts = getProductsV2(state as { basket: any }) as unknown as OrderV2
  const headers = getRequestHeaders(userId)
  const url = `${endpoint('order', 2)}/orders/${orderId}/relationships/product`

  return new Promise((resolve, reject) =>
    isomorphicFetch(url, {
      method: 'PATCH',
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({ data: orderProducts }),
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse?.errors) {
          let error = jsonResponse?.errors
          error = Array.isArray(error) ? error.pop() : error

          return reject(new Error(error.detail || error.message || 'OrderPatchError'))
        }

        return resolve({ ...jsonResponse })
      })
      .catch((error) => {
        reject(error)
      }),
  )
}

export async function fetchOrder(getState: () => State, orderId: string): Promise<any> {
  const state = getState()
  const userId = getUserId(state)
  const accessToken = getAccessToken(state)

  const headers = getRequestHeaders(userId)
  const url = `${endpoint('order', 2)}/orders/${orderId}?include[]=shipping_address`

  return new Promise((resolve, reject) =>
    isomorphicFetch(url, {
      method: 'GET',
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        const transformedOrder = transformOrderV2ToOrderV1(jsonResponse.data, jsonResponse.included)
        resolve({ ...jsonResponse, data: transformedOrder })
      })
      .catch((error) => {
        reject(error)
      }),
  )
}

export async function fetchUserOrders(
  dispatch: Dispatch,
  getState: () => State,
  reqData?: Record<string, any>,
) {
  const state = getState()
  const userId = getUserId(state)
  const headers = getRequestHeaders(userId)
  const accessToken = getAccessToken(state)
  const useOrderApiV2 = await isOptimizelyFeatureEnabledFactory(
    'radishes_order_api_v2_userorders_web_enabled',
  )(dispatch, getState)

  // Temporary feature flag until we complete the migration from Order API V1 to Order API V2
  if (!useOrderApiV2) {
    // eslint-disable-next-line import/no-named-as-default-member
    return userApiV1.fetchUserOrders(accessToken, reqData || {})
  }

  if (!userId) {
    return { data: [] }
  }

  let url = `${endpoint('order', 2)}/users/${userId}/orders`
  if (reqData) {
    let urlSearchParams: Record<string, string> = {
      'page[limit]': reqData.limit,
      'filter[state]': reqData.state,
      sort: reqData.sort_order,
      'include[]': reqData.includes,
    }

    if (reqData.extends && reqData.extends.length > 0) {
      urlSearchParams = { ...urlSearchParams, 'extends[]': reqData.extends }
    }

    const params = new URLSearchParams(urlSearchParams).toString()

    url = `${url}?${params}`
  }

  return new Promise((resolve, reject) =>
    isomorphicFetch(url, {
      method: 'GET',
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        const transformedOrder =
          jsonResponse?.data.map((d: any) => transformOrderV2ToOrderV1(d, jsonResponse.included)) ||
          []
        resolve({ ...jsonResponse, data: transformedOrder.reverse() })
      })
      .catch((error) => {
        reject(error)
      }),
  )
}

export async function cancelPendingOrders(
  dispatch: Dispatch,
  getState: () => State,
  accessToken: string,
  userId: string,
): Promise<void> {
  const useOrderApiV2 = await isOptimizelyFeatureEnabledFactory(
    'radishes_order_api_v2_cancel_pending_orders_web_enabled',
  )(dispatch, getState)

  if (!useOrderApiV2) {
    return orderApiV1.cancelExistingOrders(accessToken)
  }

  const url = `${endpoint('order', 2)}/users/${userId}/orders?filter[state]=pending`
  const headers = getRequestHeaders(userId)

  await isomorphicFetch(url, {
    method: 'DELETE',
    headers: { ...headers, Authorization: `Bearer ${accessToken}` },
  })

  return undefined
}
