import actions from 'actions'
import { RootStateOrAny, connect } from 'react-redux'

import { boxSummaryVisibilityChange } from 'actions/boxSummary'

import { BrowseCTAButton } from './BrowseCTAButton'

type BrowseCTAButtonContainerProps = {
  view: string
}

function mapStateToProps(state: RootStateOrAny, ownProps: BrowseCTAButtonContainerProps) {
  return {
    menuBrowseCTAShow: state.menuBrowseCTAShow,
    boxSummaryShow:
      state.boxSummaryShow.get('show') && state.boxSummaryShow.get('view') === ownProps.view,
    disable: state.auth.get('isAdmin'),
  }
}

const BrowseCTAButtonContainer = connect(mapStateToProps, {
  boxDetailsVisibilityChange: boxSummaryVisibilityChange,
  menuBrowseCTAVisibilityChange: actions.menuBrowseCTAVisibilityChange,
})(BrowseCTAButton)

export { BrowseCTAButtonContainer }
