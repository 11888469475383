import React from 'react'

import {
  AlignItems,
  Box,
  Display,
  Body2,
  Icon,
  IconNames,
  Color,
  withCitrusTheme,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'

type RecipeAttributePillProps = {
  icon?: IconNames
  text: string
}

const Pill = withCitrusTheme(Box, (theme) => ({
  borderRadius: theme.spacing(10),
  width: 'fit-content',
  margin: '4px 4px 4px 0px',
}))

const BodyText = withCitrusTheme(Body2, () => ({
  whiteSpace: 'nowrap',
}))

export const RecipeAttributePill: React.FC<RecipeAttributePillProps> = ({ icon, text }) => (
  <Pill
    display={Display.Flex}
    alignItems={AlignItems.Center}
    bg={Color.NeutralGrey_50}
    justifyContent={JustifyContent.SpaceEvenly}
    paddingV={1}
    paddingH={2}
  >
    {icon && <Icon name={icon} />}
    {icon && <Space size={2} direction="horizontal" />}
    <BodyText size={1} color={Color.ColdGrey_900}>
      {text}
    </BodyText>
  </Pill>
)
