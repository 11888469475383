import React from 'react'
import { Field, FieldProps } from 'formik'
import { InputField, FormFieldStatus } from '@gousto-internal/citrus-react'

const AddressNameInput = () => (
  <Field name="name">
    {({ field, meta }: FieldProps) => (
      <InputField
        id="name"
        label="Address name"
        placeholder="Name (e.g. Home, Work)"
        autoComplete="off"
        status={meta.error ? FormFieldStatus.Error : undefined}
        validationMessage={meta.error}
        {...field}
      />
    )}
  </Field>
)

export { AddressNameInput }
