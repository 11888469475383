import { IconNames } from '@gousto-internal/citrus-react'

export enum BenefitType {
  NO_LOCK_IN = 'noLockIn',
  NO_LOCK_IN_IMMERSIVE = 'noLockInImmersive',
  EVERYDAY_DELIVERY = 'everydayDelivery',
  PAID_DELIVERY = 'paidDelivery',
}

// FYI: this is for JS files
// use instead of enum BenefitType
export const BENEFIT_TYPE = {
  NO_LOCK_IN: 'noLockIn',
  EVERYDAY_DELIVERY: 'everydayDelivery',
  PAID_DELIVERY: 'paidDelivery',
}

export type Benefit = {
  type: BenefitType
  iconName: IconNames
  ariaLabel: string
  strongText: string
  text: string
}

export const BENEFITS: Benefit[] = [
  {
    type: BenefitType.NO_LOCK_IN,
    iconName: 'play_pause',
    ariaLabel: 'No lock in icon',
    strongText: 'No lock in: ',
    text: 'skip or cancel for free any time',
  },
  {
    type: BenefitType.NO_LOCK_IN_IMMERSIVE,
    iconName: 'play_pause',
    ariaLabel: 'No lock in icon',
    strongText: 'No lock in: ',
    text: 'pause or cancel anytime',
  },
  {
    type: BenefitType.EVERYDAY_DELIVERY,
    iconName: 'delivery',
    ariaLabel: 'Contactless delivery icon',
    strongText: 'Delivered to your door',
    text: ', 7 days a week',
  },
  {
    type: BenefitType.PAID_DELIVERY,
    iconName: 'delivery',
    ariaLabel: 'Contactless delivery icon',
    strongText: 'Delivered 7 days a week.',
    text: 'Prices exclude delivery. ',
  },
]
