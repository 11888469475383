import React from 'react'

import { Route } from 'react-router'

import config from 'config/routes'
import { FullPage } from 'layouts/FullPage'

import { RafPage } from './RafPage'

export const Raf = () => (
  <Route
    component={FullPage}
    props={{
      footerType: 'large',
      footerIsOnLandingPage: false,
      showHeaderWithoutNavLink: true,
    }}
  >
    <Route path={config.client.raf} component={RafPage} />
  </Route>
)
