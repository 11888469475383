import { createSelector } from 'reselect'

import { getBasketRecipes, getNumPortions } from 'selectors/basket'
import { getMenuRecipeIds, getMenuService } from 'selectors/root'
import { okRecipes } from 'utils/basket'

export const getOkRecipeIds = createSelector(
  getBasketRecipes,
  getMenuRecipeIds,
  getMenuService,
  getNumPortions,
  (basketRecipes, menuRecipeIds, menuService, numPortions) =>
    okRecipes(basketRecipes, menuRecipeIds, menuService, numPortions),
)

export const getUnavailableRecipeIds = createSelector(
  getBasketRecipes,
  getOkRecipeIds,
  (basketRecipes, okRecipeIds) =>
    basketRecipes.filter((obj, recipeId) => !okRecipeIds.has(recipeId)),
)
