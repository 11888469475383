import { Box, Display3, Heading2 } from '@gousto-internal/citrus-react'
import React from 'react'
import { ENVIRONMENTAL_BENEFITS_HEADING } from '../../../config'

export const HeaderSection = () => (
  <Box>
    <Heading2>{ENVIRONMENTAL_BENEFITS_HEADING.eyebrowHeading}</Heading2>
    <Box width={['260px', '100%', '260px', '100%']}>
      <Display3>{ENVIRONMENTAL_BENEFITS_HEADING.headingFirst}</Display3>
    </Box>
  </Box>
)
