import { actionTypes } from 'actions/actionTypes'
import { validateUserPassword } from 'apis/auth'
import { checkoutConstants } from 'routes/Checkout/checkoutConstants'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import logger from 'utils/logger'

export const validatePassword: CheckoutActionCreator = (password: string) => async (dispatch) => {
  if (password) {
    try {
      await validateUserPassword(password, 2)

      dispatch({ type: actionTypes.CHECKOUT_PASSWORD_VALIDATION_RULES_SET, password, errors: [] })
    } catch (err) {
      dispatch({
        type: actionTypes.CHECKOUT_PASSWORD_VALIDATION_RULES_SET,
        errors: (err as any).errors,
        password,
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error({ message: err.message, errors: err })
    }
  } else {
    dispatch({
      type: actionTypes.CHECKOUT_PASSWORD_VALIDATION_RULES_SET,
      errors: checkoutConstants.passwordRules,
      password,
    })
  }
}
