import React from 'react'

import { Box, FlexDirection, Link, Space, Text } from '@gousto-internal/citrus-react'

import { DeliveryCard } from 'routes/Checkout/Components/Delivery/DeliveryCard'

type AddressCardProps = {
  address: string
  onEditAddressManuallyClick: () => void
}

export const AddressCard = ({ address, onEditAddressManuallyClick }: AddressCardProps) => (
  <DeliveryCard iconName="icon-home" dataTesting="deliveryCardAddress">
    <Box display="flex" flexDirection={FlexDirection.Column}>
      <Text>{address}</Text>
      <Space size={2} />
      <Box>
        {/* eslint-disable-next-line */}
        <Link role="button" size={1} onClick={onEditAddressManuallyClick}>
          Edit address
        </Link>
      </Box>
    </Box>
  </DeliveryCard>
)
