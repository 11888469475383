import React from 'react'

import { Body2, Body3, Button, FontWeight, Space, TextAlign } from '@gousto-internal/citrus-react'

import { RemoveRecipeFn, useBasket } from 'routes/Menu/domains/basket'

import css from './BrowseCTA.css'

type VisibilityEvents = {
  boxDetailsVisibilityChange: (show: boolean, removeRecipe: RemoveRecipeFn) => void
  menuBrowseCTAVisibilityChange: (value: boolean) => void
}

type HandleClickProps = VisibilityEvents & { removeRecipe: RemoveRecipeFn }

type BrowseCTAProps = VisibilityEvents & { menuBrowseCTAShow: boolean; view: string }

const handleClick =
  ({ boxDetailsVisibilityChange, menuBrowseCTAVisibilityChange, removeRecipe }: HandleClickProps) =>
  (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    menuBrowseCTAVisibilityChange(false)
    boxDetailsVisibilityChange(true, removeRecipe)
  }

const BrowseCTA = ({
  boxDetailsVisibilityChange,
  menuBrowseCTAVisibilityChange,
  menuBrowseCTAShow = true,
  view = '',
}: BrowseCTAProps) => {
  const { removeRecipe } = useBasket()

  return (
    <div
      className={menuBrowseCTAShow ? css[`container-${view}`] : css[`containerHidden-${view}`]}
      role="button"
      tabIndex={0}
      onClick={handleClick({
        menuBrowseCTAVisibilityChange,
        boxDetailsVisibilityChange,
        removeRecipe,
      })}
      onKeyPress={handleClick({
        menuBrowseCTAVisibilityChange,
        boxDetailsVisibilityChange,
        removeRecipe,
      })}
    >
      <div
        className={menuBrowseCTAShow ? css[`show-${view}`] : css.hidden}
        data-testing="menuBrowseCTAOverlay"
      >
        <div className={css.textContainer}>
          <Body2 fontWeight={FontWeight.Bold} textAlign={TextAlign.Center} size={[4]}>
            Ready to choose some recipes?
          </Body2>
          <Space />
          <Body3 textAlign={TextAlign.Center} size={[2]}>
            Please tell us where and when you&apos;d like your box so we can show you the latest
            recipes
          </Body3>
          <div className={css.button}>
            <Button width="full">Get Started</Button>
          </div>
        </div>
        <span className={css.arrow} />
      </div>
    </div>
  )
}

export { BrowseCTA }
