type Result<T, U> = { type: 'success'; value: T } | { type: 'failure'; error: U }

export async function jsonParse(resp: Response): Promise<Result<unknown, Error>> {
  try {
    const value = await resp.json()
    return { type: 'success', value }
  } catch (parseErr) {
    return { type: 'failure', error: parseErr as Error }
  }
}
