import React from 'react'

import classNames from 'classnames'

import {
  timeLeftToDisplayModal,
  timeLeftToHighlightClock,
} from 'routes/Checkout/Components/CheckoutUrgency/checkoutUrgencyConstants'
import {
  useMillisecondsLeftToCheckout,
  useTimeLeftToCheckOut,
} from 'routes/Checkout/Components/CheckoutUrgency/checkoutUrgencyUtils'

import css from './Clock.css'

export const Clock = () => {
  const svgViewBox = 128
  const center = svgViewBox / 2
  const strokeWidth = 2
  const radius = center - strokeWidth
  const circumference = 2 * Math.PI * radius
  const timeLeft = useMillisecondsLeftToCheckout()
  const formattedTimeLeft = useTimeLeftToCheckOut()
  const highlightedPart = Math.min(
    (1 - timeLeft / timeLeftToDisplayModal) * circumference,
    circumference,
  )
  const isCriticalTimeLeft = timeLeft < timeLeftToHighlightClock

  return (
    <div className={css.clock}>
      <svg viewBox={`0 0 ${svgViewBox} ${svgViewBox}`} className={css.clockSvg} fill="none">
        <circle cx={center} cy={center} r={radius} strokeWidth={1} />
        <circle
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={`${highlightedPart}, ${circumference}`}
          className={classNames(css.highlight, { [css.highlightIsCritical]: isCriticalTimeLeft })}
        />
      </svg>
      <div className={classNames(css.text, { [css.textIsCritical]: isCriticalTimeLeft })}>
        {formattedTimeLeft}
      </div>
    </div>
  )
}
