import React from 'react'

import { MenuRecipe } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { NutritionInfo } from 'routes/Menu/components/Detail/Nutrition'
import { NutritionDisclaimerText } from 'routes/Menu/components/Detail/NutritionDisclaimerText'
import { SectionPanel } from 'routes/Menu/components/Detail/SectionPanel'

type DetailPerPortionProps = {
  numPortions: number
  loading: boolean
  recipe: MenuRecipe
}

export const DetailPerPortion = ({ loading, recipe, numPortions }: DetailPerPortionProps) => (
  <SectionPanel>
    <NutritionInfo nutritionValues={recipe.nutritional_information} loading={loading} />
    <NutritionDisclaimerText numPortions={numPortions} />
  </SectionPanel>
)
