import React from 'react'

import { Route } from 'react-router'

import config from 'config/routes'
import { MainLayout } from 'layouts/MainLayout'

import { BoxPrices as BoxPricesPage } from './BoxPrices'

export const BoxPrices = (
  <Route component={MainLayout}>
    <Route path={config.client.boxPrices} component={BoxPricesPage} />
  </Route>
)
