import React, { useEffect } from 'react'

import { OrderState } from 'FiveRecipesAwareness/OrderListBanner/FiveRecipesAwarenessOrderListBanner'
import { useSubscription } from 'FiveRecipesAwareness/use5RecipesAwareness'
import Immutable from 'immutable'
import { useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'

interface Props {
  orders: Immutable.Map<string, string>
}

export const Fire5RecipeAwarenessTracking: React.FC<Props> = ({ orders }) => {
  const anyOrderIsOpen =
    orders.filter((order: any) => order.get('orderState') === OrderState.MenuOpen).size > 0
  const subscription = useSubscription()
  const dispatch = useDispatch()

  useEffect(() => {
    if (anyOrderIsOpen && subscription?.numPortions === 2) {
      dispatch({
        type: actionTypes.TRACKING,
        trackingData: {
          actionType: '5r_dashboard_experiment',
          portion_size: subscription?.numPortions,
        },
      })
    }
  }, [anyOrderIsOpen, dispatch, subscription])

  return null
}
