import { getEnvironment, getDomain, getProtocol } from '@library/environment/isomorphic'

export const STATIC_ASSET_DIRECTORY = '/webclient-static-assets'

// FEF-1394 TODO - we should be able to configure root domain for static assets
function getAssetsBaseURL(assetDirectory: string) {
  const environment = getEnvironment()
  const domain = getDomain()
  const protocol = getProtocol()

  switch (environment) {
    case 'local':
      return assetDirectory

    case 'production':
    case 'staging':
      return `${protocol}//${environment}-assets.${domain}${assetDirectory}`

    default:
      // Squad environment
      return `${protocol}//s3-gousto-${environment}-assets.s3.amazonaws.com${assetDirectory}`
  }
}

/**
 * Returns URL for where assets in the static-assets (non-bundled) files are served,
 * regardless of environment
 *
 * @example
 * // File exists in src/modules/library/static-assets/assets/fonts
 * getStaticAssetUrl('/fonts/Axiforma-Bold.otf')
 */
export function getStaticAssetURL(assetPath?: `/${string}`) {
  return `${getAssetsBaseURL(STATIC_ASSET_DIRECTORY)}${assetPath ? assetPath : ''}`
}
