import type { Branded } from '@library/type-utils'
import type { MenuServiceIngredient, MenuServiceRecipe } from './menu'

export enum MediaType {
  MoodImage = 'mood-image',
  ProductImage = 'product-image',
}

export enum ResourceType {
  Box = 'box',
  DaySlotLeadTime = 'day-slot-lead-time',
  DeliverySlot = 'delivery-slot',
  DeliveryDay = 'delivery-day',
  DeliveryTariff = 'delivery-tariff',
  Ingredient = 'ingredient',
  Order = 'order',
  Product = 'product',
  Recipe = 'recipe',
  RecipeCard = 'recipecard',
  RecipeRating = 'reciperating',
  ShippingAddress = 'shipping-address',
  Period = 'period',
}

export enum Phase {
  AwaitingChoices = 'awaiting_choices',
  Cutoff = 'cutoff',
  Delivery = 'delivery',
  Open = 'open',
  Packing = 'packing',
  Picking = 'picking',
  PreMenu = 'pre_menu',
  Delivered = 'delivered',
}

type DateTime = string

type Time = string

type OrderAttributesPricingV2 = {
  is_flat_discount_applied: boolean
  amount_off: string | null
  percentage_off?: string | null
  promo_code?: string
  is_promo_code_valid: boolean
  per_portion: string
  per_portion_discounted: string
  product_total: string
  recipe_total_discounted: string
  recipe_total: string
  surcharge_total: string
  surcharge_count: number
  recipe_discount: string
  delivery_total: string
  gross_total: string
  vat_charged: string
  total_discount: string
  total: string
  is_delivery_free: boolean
}

type OrderAttributesV2 = {
  menu_id: string
  state: string
  phase: Phase
  cut_off_date: DateTime
  updated_at: DateTime
  menu_active_from: DateTime
  is_waiting_on_user_choices: boolean
  prices: OrderAttributesPricingV2
  original_delivery_day: { date: string; rescheduled_reason: string } | null
}

type BoxRelation = {
  data: {
    type: ResourceType.Box
    id: string
  }
}

type Box = {
  type: ResourceType.Box
  id: string
  attributes: {
    num_portions: number
    num_recipes: number
    box_type: string
    core_id?: string
  }
}

type PeriodRelation = {
  data: {
    type: ResourceType.Period
    id: string
  }
}

type Period = {
  type: ResourceType.Period
  id: string
  attributes: {
    starts_at: DateTime
    ends_at: DateTime
  }
}

type ShippingAddress = {
  type: ResourceType.ShippingAddress
  id: string
  attributes: {
    deleted: boolean
    name: string
    company_name: string | null
    line1: string | null
    line2: string | null
    line3: string | null
    town: string | null
    county: string | null
    postcode: string
    delivery_instructions: string | null
    shipping_default: boolean
    billing_default: boolean
  }
}

type ShippingAddressRelation = {
  data: {
    type: ResourceType.ShippingAddress
    id: string
  }
}

type DeliveryDay = {
  type: ResourceType.DeliveryDay
  id: string
  attributes: {
    date: string
  }
}

type DeliveryDayRelation = {
  data: {
    type: ResourceType.DeliveryDay
    id: string
  }
}

type DaySlotLeadTime = {
  type: ResourceType.DaySlotLeadTime
  id: string
}

type DaySlotLeadTimeRelation = {
  data: DaySlotLeadTime
}

type DeliveryTariff = {
  type: ResourceType.DeliveryTariff
  id: string
}

type DeliveryTariffRelation = {
  data: DeliveryTariff
}

type DeliverySlot = {
  id: string
  type: ResourceType.DeliverySlot
  attributes: {
    core_id: string
    uuid?: string
    delivery_slot_id: string
    start: Time
    end: Time
  }
}

type DeliverySlotRelation = {
  data: {
    type: ResourceType.DeliverySlot
    id: string
    meta?: {
      core_id?: string
      uuid?: string
    }
  }
}

type MediaSchema = {
  title: string | null
  description: string | null
  type: MediaType
  urls: {
    src: string
    width: string
  }[]
}

type Product = {
  type: ResourceType.Product
  id: string
  attributes: {
    title: string
    media: MediaSchema[]
  }
}

type ProductRelation = {
  type: ResourceType.Product
  id: string
  meta: {
    quantity: number
    list_price: string
  }
}

type RecipeRelation = {
  type: ResourceType.Recipe
  id: string
  meta?: {
    core_recipe_id?: string
    portion_for: number
    amendments: unknown[]
  }
}

type OrderRelationshipV2 = {
  box?: BoxRelation
  period: PeriodRelation
  shipping_address?: ShippingAddressRelation
  delivery_slot?: DeliverySlotRelation
  delivery_day?: DeliveryDayRelation
  delivery_tariff?: DeliveryTariffRelation
  day_slot_lead_time?: DaySlotLeadTimeRelation
  components: {
    data: ProductRelation | RecipeRelation
  }
}

type OrderV2Include =
  | Box
  | Period
  | DaySlotLeadTime
  | DeliverySlot
  | DeliveryDay
  | DeliveryTariff
  | MenuServiceIngredient
  | MenuServiceRecipe
  | Product
  | ShippingAddress

type OrderV2 = {
  id: OrderID
  type: ResourceType.Order
  attributes: OrderAttributesV2
  relationships: OrderRelationshipV2
}

export type OrderResponseV2 = {
  data: OrderV2
  included: OrderV2Include[]
}

export type OrderResponseV2Error = {
  errors: string | { detail: string; status: string }[]
  meta: Record<string, unknown>
}

export type OrderID = Branded<string, 'OrderID'>
