import { getAuthToken } from '@library/auth'
import { endpoint, ServiceName } from '@library/endpoint'

import { RequestConfig, RequestMiddleware } from '../types'
import { setHeader, setHeaders } from './header'
import { httpWarning } from '../logger'
import { composeRequest } from '../compose'

/**
 * Uses @library/auth to add an authentication header
 */
export const auth: RequestMiddleware<unknown> = setHeaders(() => {
  const authToken = getAuthToken()
  return authToken ? { Authorization: `Bearer ${authToken}` } : ({} as Record<string, string>)
})

/**
 * Sends JSON data based on the input
 */
export function sendJSON<Input>(): RequestMiddleware<Input> {
  return composeRequest(
    setHeader('Content-Type', 'application/json'),
    (req: RequestConfig, input: Input) => {
      req.body = JSON.stringify(input)
      return req
    },
  )
}

/**
 * Resets the request host and path to point to a Gousto service URL
 */
export function setServiceUrl(serviceName: ServiceName, version = 1): RequestMiddleware<unknown> {
  return function serviceUrlMiddleware(req) {
    const url = new URL(endpoint(serviceName, version))
    const host = url.origin
    const path = url.pathname.slice(1)

    if (req.host.length || req.paths.length) {
      httpWarning('setServiceUrl will reset existing request host / path config')
    }

    req.host = host
    req.paths = path.length ? [path] : []

    return req
  }
}
