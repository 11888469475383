import { datadogLogs } from '@datadog/browser-logs'
import Immutable from 'immutable'

import { actionTypes } from 'actions/actionTypes'
import { basketPreviewOrderChange } from 'actions/basket'
import statusActions from 'actions/status'
import {
  trackFirstPurchase,
  trackNewOrder,
  trackNewUser,
  trackUnexpectedSignup,
} from 'actions/tracking'
import { placeOrder } from 'actions/trackingKeys'
import { customerSignup } from 'apis/customers'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { buildSignupRequestData } from 'routes/Checkout/utils/buildSignupRequestData'
import { Pricing } from 'routes/Menu/domains/pricing'
import { getPreviewOrderId, getPromoCode } from 'selectors/basket'
import GoustoException from 'utils/GoustoException'
import logger from 'utils/logger'

export const userSubscribe: CheckoutActionCreator =
  ({ pricing }: { pricing: Pricing }) =>
  async (dispatch, getState) => {
    const state = getState()
    const boxId = state.basket.get('boxId')
    if (!boxId) {
      dispatch(trackUnexpectedSignup())

      return
    }
    dispatch(statusActions.error(actionTypes.USER_SUBSCRIBE, null))
    dispatch(statusActions.pending(actionTypes.USER_SUBSCRIBE, true))

    try {
      const reqData = buildSignupRequestData(state)

      const { data } = await customerSignup(null, reqData)

      if (data.customer && data.addresses && data.subscription && data.orderId) {
        const {
          customer,
          addresses,
          subscription,
          orderId,
          customer: { id: customerId },
        } = data
        let user = Immutable.fromJS({
          ...customer,
          ...addresses,
          subscription,
        })
        user = user.set('goustoReference', user.get('goustoReference').toString())

        dispatch(trackNewUser(customerId))

        dispatch({
          type: actionTypes.CHECKOUT_ORDER_PLACED,
          trackingData: {
            actionType: placeOrder,
            order_id: orderId,
            order_total: pricing?.total,
            promo_code: getPromoCode(state),
            signup: true,
            subscription_active: data.subscription.status ? data.subscription.status.slug : true,
            interval_id: reqData.subscription.interval_id,
          },
        })

        dispatch(trackFirstPurchase(orderId, pricing))
        dispatch(trackNewOrder(orderId, customerId))
        dispatch(basketPreviewOrderChange(orderId, state.basket.get('boxId')))
        dispatch({ type: actionTypes.USER_SUBSCRIBE, user })
        datadogLogs.logger.info('Request to customers customers/v2/signup successful')
      } else {
        throw new GoustoException(actionTypes.USER_SUBSCRIBE)
      }
    } catch (err) {
      dispatch(statusActions.error(actionTypes.USER_SUBSCRIBE, (err as GoustoException).message))
      dispatch(trackNewUser())

      const previewOrderId = getPreviewOrderId(state)

      dispatch({
        type: actionTypes.CHECKOUT_ORDER_FAILED,
        trackingData: {
          actionType: 'Order Failed',
          order_id: previewOrderId,
          promo_code: getPromoCode(state),
          signup: true,
          error_reason: (err as GoustoException).message,
        },
      })
      dispatch(trackNewOrder(previewOrderId))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error({ message: (err as GoustoException).message, errors: [err] })
      throw err
    } finally {
      dispatch(statusActions.pending(actionTypes.USER_SUBSCRIBE, false))
    }
  }
