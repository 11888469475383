import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'

import { Box, BoxProps, useTheme, withCitrusTheme } from '@gousto-internal/citrus-react'

export function PromoBannerContainer(props: BoxProps) {
  const { theme } = useTheme()
  const stickyBarRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)

  const breakpointFromSmall = `@media (min-width: ${theme.tokens.breakpoints[0]})`

  const onScroll = () => {
    if (!stickyBarRef.current) {
      return
    }

    const isSticky = stickyBarRef.current.offsetTop < window.pageYOffset
    setIsSticky(isSticky)
  }

  const onScrollDebounced = debounce(onScroll, 100)

  useEffect(() => {
    window.addEventListener('scroll', onScrollDebounced)

    return () => {
      window.removeEventListener('scroll', onScrollDebounced)
    }
  })

  const Container = withCitrusTheme(Box, () => ({
    [breakpointFromSmall]: {
      cursor: 'pointer',
      ...(isSticky
        ? { position: 'fixed', top: 0, left: 0, zIndex: theme.tokens.zIndices.Sticky }
        : {}),
    },
  }))

  return (
    <div ref={stickyBarRef}>
      <Container {...props} />
    </div>
  )
}
