import { queueTrackingEvent } from '@library/tracking-integration'

export enum TrackingActions {
  CLICK_SEE_YOUR_MENU = 'click_see_your_menu',
  CLICK_SKIP = 'click_skip',
}

type CTAClickTrackingData = {
  action: TrackingActions
  properties: {
    recipe_preferences?: string[]
    section: 'recipePreferences'
  }
}

export function trackCTAClick(
  action: TrackingActions,
  recipe_preferences?: CTAClickTrackingData['properties']['recipe_preferences'],
) {
  queueTrackingEvent<CTAClickTrackingData>({
    action,
    properties: {
      recipe_preferences,
      section: 'recipePreferences',
    },
  })
}
