import React from 'react'

import {
  Box,
  Display,
  Image,
  JustifyContent,
  Text,
  RadioGroup,
  AlignItems,
  Space,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { useIsOptimizelyFeatureEnabled } from 'containers/OptimizelyRollouts'
import { setCurrentPaymentMethod } from 'routes/Checkout/checkoutActions'
import {
  getCurrentPaymentMethod,
  isPayPalReady as getIsPayPalReady,
} from 'routes/Checkout/checkoutPaymentSelectors'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import paypalPaymentMethod from '../../../assets/paypal.png'
import { PayPalBanner } from '../../PayPalBanner/PayPalBanner'
import cardsPaymentMethod from './assets/payment_methods.png'

const paymentMethods = [PaymentMethod.Card, PaymentMethod.PayPal]
type PaymentMethods = typeof paymentMethods[number]

const getRadioLabels = (paymentMethod: PaymentMethods) => {
  if (paymentMethod === PaymentMethod.Card) {
    return (
      <Box
        display={Display.Flex}
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
        paddingH={1}
        width="100%"
      >
        <Text>Card payment</Text>
        <Image
          src={cardsPaymentMethod}
          alt="logos of VISA, mastercard, maestro and AMEX"
          width="160px"
        />
      </Box>
    )
  }

  return (
    <>
      <Space direction="horizontal" size={1} />
      <Image src={paypalPaymentMethod} alt="paypal logo" width="100px" />
    </>
  )
}

export const CheckoutPaymentMethodSelector = () => {
  const currentPaymentMethod = useSelector(getCurrentPaymentMethod)
  const isPayPalReady = useSelector(getIsPayPalReady)
  const dispatch = useDispatch()
  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCurrentPaymentMethod(event.target.value))
  }
  const isPayPalOfferEnabled = useIsOptimizelyFeatureEnabled(
    'beetroots_checkout_paypal_offer_enabled',
  )

  const getRadioOptions = () =>
    paymentMethods.map((paymentMethod) => ({
      name: 'paymentMethod',
      value: paymentMethod,
      label: () => getRadioLabels(paymentMethod),
    }))

  return !isPayPalReady ? (
    <>
      <RadioGroup
        value={currentPaymentMethod}
        outline
        options={getRadioOptions()}
        onChange={handlePaymentMethodChange}
      />
      {isPayPalOfferEnabled && (
        <>
          <Space direction="vertical" size={2} />
          <PayPalBanner />
        </>
      )}
    </>
  ) : null
}
