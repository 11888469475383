import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Column } from 'goustouicomponents'
import { Space } from '@gousto-internal/citrus-react'
import { Section } from '../components/Section'
import { Divider } from '../components/Divider'
import { DeliveryDayAndTime } from './sections/YourSubscriptionDetails/DeliveryDayAndTime'
import { Frequency } from './sections/YourSubscriptionDetails/Frequency'
import { BoxSize } from './sections/YourSubscriptionDetails/BoxSize'
import { SkipABox } from './sections/SkipABox'
import { PauseSubscription } from './sections/PauseSubscription'
import { ResubscriptionModal } from './ResubscriptionModal'

import {
  yourSubscriptionDetailsSection,
  defaultRecipeSettingsSection,
  skipABoxSection,
  pauseSubscriptionSection,
} from '../subscriptionsSectionsContent'
import { DietaryPreference } from './sections/DefaultRecipeSettings/DietaryPreference'
import { InfoPanel } from './sections/DefaultRecipeSettings/InfoPanel/InfoPanel'
import { RecipesPerBox } from './sections/DefaultRecipeSettings/RecipesPerBox'

const ActiveSubscription = ({ accessToken, isMobile, handlePause }) => (
  <Fragment>
    <ResubscriptionModal />
    <Grid>
      <Column
        smallScreen={12}
        mediumScreen={6}
        largeScreen={6}
        hasPaddingSmallScreen={false}
      >
        <Section
          title={yourSubscriptionDetailsSection.title}
          subTitle={yourSubscriptionDetailsSection.subTitle}
          testingSelector={yourSubscriptionDetailsSection.testingSelector}
        >
          <DeliveryDayAndTime
            accessToken={accessToken}
            isMobile={isMobile}
          />
          <BoxSize
            accessToken={accessToken}
            isMobile={isMobile}
          />
          <Frequency
            accessToken={accessToken}
            isMobile={isMobile}
          />
        </Section>
      </Column>
      <Column
        smallScreen={12}
        mediumScreen={6}
        largeScreen={6}
        hasPaddingSmallScreen={false}
      >
        <Section
          title={defaultRecipeSettingsSection.title}
          testingSelector={defaultRecipeSettingsSection.testingSelector}
        >
          <InfoPanel />
          <Space direction="horizontal" size={2} />
          <RecipesPerBox accessToken={accessToken} isMobile={isMobile} />
          <DietaryPreference accessToken={accessToken} isMobile={isMobile} />
        </Section>
      </Column>
    </Grid>

    <Grid>
      <Column
        smallScreen={12}
      >
        <Divider hidden={['sm']} />
      </Column>
    </Grid>

    <Grid>
      <Column
        smallScreen={12}
        mediumScreen={6}
        largeScreen={6}
        hasPaddingSmallScreen={false}
      >
        <Section
          title={skipABoxSection.title}
          subTitle={skipABoxSection.subTitle}
          testingSelector={skipABoxSection.testingSelector}
        >
          <SkipABox />
        </Section>
      </Column>
      <Column
        smallScreen={12}
        mediumScreen={6}
        largeScreen={6}
        hasPaddingSmallScreen={false}
      >
        <Section
          title={pauseSubscriptionSection.title}
          subTitle={pauseSubscriptionSection.subTitle}
          testingSelector={pauseSubscriptionSection.testingSelector}
        >
          <PauseSubscription handlePause={handlePause} />
        </Section>
      </Column>
    </Grid>
  </Fragment>

)

ActiveSubscription.propTypes = {
  accessToken: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handlePause: PropTypes.func.isRequired
}

export { ActiveSubscription }
