import { RefObject } from 'react'

import { canUseWindow } from '@library/environment'

export const getStickyContainerHeight = (
  isSticky: boolean,
  bottom: number,
  stickyCTA: HTMLElement,
) => (isSticky && Math.round(bottom) < window?.innerHeight ? `${stickyCTA.clientHeight}px` : false)

export const onScrollHandler = ({
  heroRef,
  stickyCTARef,
  setIsSticky,
  setMaxHeight,
}: {
  heroRef: RefObject<HTMLDivElement>
  stickyCTARef: RefObject<HTMLDivElement>
  setIsSticky: (isSticky: boolean) => void
  setMaxHeight: (isSticky: number) => void
}) => {
  const heroSection = heroRef.current as unknown as HTMLDivElement
  const stickyCTA = stickyCTARef.current as unknown as HTMLDivElement
  let isSticky = false

  if (!canUseWindow()) return

  if (heroSection) {
    if (window.pageYOffset > heroSection.offsetHeight + heroSection.offsetTop) {
      isSticky = true
    }

    const lastSection = document.getElementsByTagName('footer')[0]
    const { bottom }: DOMRect = lastSection.getBoundingClientRect()
    const sectionHeight = getStickyContainerHeight(isSticky, bottom, stickyCTA)

    lastSection.style.marginBottom = sectionHeight || '0px'

    setIsSticky(isSticky)
    setMaxHeight(sectionHeight ? bottom : 0)
  }
}
