import React from 'react'

import { Route } from 'react-router'

import { MainLayout } from 'layouts/MainLayout'

import { DeliveryDetails as DeliveryDetailsComponent } from './DeliveryDetails'

export const DeliveryDetails = (
  <Route component={MainLayout}>
    <Route path="/delivery-details" component={DeliveryDetailsComponent} />
  </Route>
)
