import Immutable from 'immutable'
import { Recipe } from '../model/recipe'

const dietaryPreferencesMap = new Map([
  ['pescatarian', ['vegetarian', 'plant-based']],
  ['no-seafood', ['vegetarian', 'plant-based']],
  ['vegetarian', ['plant-based']],
])
const dietaryClaimsSet = new Set(['gluten-free', 'dairy-free', 'vegan'])

export function formatInvalidDietaryPreferences(
  recipe: Recipe,
  dietaryPreferences: Immutable.List<string>,
): string | null {
  if (!dietaryPreferences) {
    return null
  }

  const filteredPreferences = handleConflictingDietTypes(dietaryPreferences)
  const invalidDietTypes = getInvalidDietTypes(recipe, filteredPreferences)
  const invalidDietaryClaims = getInvalidDietaryClaims(recipe, filteredPreferences)

  return formatInvalidPreferencesMessage(invalidDietTypes, invalidDietaryClaims)
}

function getInvalidDietType(recipeDietType: string, preference: string): string | null {
  switch (recipeDietType) {
    case 'meat':
      if (
        preference === 'vegetarian' ||
        preference === 'plant-based' ||
        preference === 'pescatarian'
      ) {
        return preference
      }
      break
    case 'fish':
      if (
        preference === 'vegetarian' ||
        preference === 'plant-based' ||
        preference === 'no-seafood'
      ) {
        return preference
      }
      break
    case 'vegetarian':
      if (preference === 'plant-based') {
        return preference
      }
      break
  }

  return null
}

// Returns user's diet type which is not met by recipe
function getInvalidDietTypes(recipe: Recipe, dietaryPreferences: string[]): string[] {
  return (dietaryPreferences ?? [])
    .map((dietaryPreference) => getInvalidDietType(recipe.dietType ?? '', dietaryPreference))
    .filter((dietType) => dietType !== null) as string[]
}

// Returns user's dietary claims which is not met by recipe
function getInvalidDietaryClaims(recipe: Recipe, preferences: string[]): string[] {
  const dietaryClaims = (recipe.dietaryClaims ?? []).map((claim) => {
    return claim.slug.replace('vegan', 'plant-based')
  })

  const dietaryClaimsSlugs = new Set(dietaryClaims)

  return (preferences ?? []).filter(
    (claim) => !dietaryClaimsSlugs.has(claim) && dietaryClaimsSet.has(claim),
  )
}

function handleConflictingDietTypes(dietaryPreferences: Immutable.List<string>): string[] {
  const dietaryPreferencesSet = new Set(dietaryPreferences.toArray())

  for (const diet of dietaryPreferences.toArray()) {
    if (dietaryPreferencesSet.has(diet)) {
      // Remove conflicting diet types
      dietaryPreferencesMap.get(diet)?.forEach((conflict) => {
        dietaryPreferencesSet.delete(conflict)
      })
    }
  }

  return Array.from(dietaryPreferencesSet)
}

function formatInvalidPreferencesMessage(
  invalidDietTypes: string[],
  invalidDietaryClaims: string[],
): string | null {
  if (invalidDietTypes.length) {
    const [invalidDietType] = invalidDietTypes
    if (invalidDietType === 'no-seafood') {
      return 'Contains seafood'
    }

    return `Not ${invalidDietType}`
  }

  if (invalidDietaryClaims.length > 0) {
    return `Contains ${invalidDietaryClaims.map((claim) => claim.split('-')[0]).join(' and ')}`
  }

  return null
}
