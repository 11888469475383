import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

export function useShouldShowDeliveryDetailsPage() {
  const { isAuthenticated } = useAuth()
  const [decision] = useDecision('turnips_ofx_delivery_details_before_menu')

  return decision.variationKey === 't1' && isAuthenticated
}
