import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Immutable from 'immutable'
import { compareMoments as ascOrderComparator } from 'utils/deliveries'
import Order from './Order'
import NoOrders from './NoOrders'
import CancelledAllBoxesModal from './CancelledAllBoxesModal'
import { Fire5RecipeAwarenessTracking } from './Fire5RecipeAwarenessTracking/Fire5RecipeAwarenessTracking'

const OrdersList = ({ orders, recipes, boxType }) => {
  // OrdersList component always renders items in the ASCending order
  const orderedList = useMemo(() => orders.toList().sortBy(
    (value) => moment(value.get('deliveryDay')),
    ascOrderComparator
  ), [orders])

  return (
    <div>
      <CancelledAllBoxesModal />
      <Fire5RecipeAwarenessTracking orders={orders} />
      {orders.size < 1 ? <NoOrders recipes={recipes} boxType={boxType} /> : null}
      {orderedList.map((order) => (
        <Order key={order.get('id')} order={order} />
      ))}
    </div>
  )
}

OrdersList.propTypes = {
  orders: PropTypes.instanceOf(Immutable.Map),
  recipes: PropTypes.instanceOf(Immutable.Map),
  boxType: PropTypes.string,
}

OrdersList.defaultProps = {
  orders: Immutable.fromJS({}),
  recipes: Immutable.fromJS({}),
  boxType: '',
}

export default OrdersList
