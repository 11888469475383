import React from 'react'

import { ScreenReaderLiveRegion, Space } from '@gousto-internal/citrus-react'
import { DeliveryInstructions } from 'DeliveryInstructions/DeliveryInstructions'
import { DeliveryTips } from 'DeliveryTips/DeliveryTips'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { RibbonTriggerContainer } from 'components/RibbonTrigger'
import { useIsWizard2DDBannerEnabled } from 'hooks/useIsWizard2DDBannerEnabled'
import { Calendar } from 'routes/Signup/Components/Calendar/Calendar'
import { DeliveryDay } from 'routes/Signup/Components/Calendar/models/DeliveryDay'
import { SocialBelongingBanner } from 'routes/Signup/SocialBelongingBanner'
import { trackSocialBelongingBannerAppearance } from 'routes/Signup/signupActions'
import { getAmountOfCustomers, getDistrict } from 'routes/Signup/signupSelectors'
import {
  getDeliveryDetailsInstructions,
  getIsValidDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'

import { Button } from '../../Button'
import { DeliveryInfoBanner } from './DeliveryInfoBanner'

import signupCss from '../../Signup.css'
import css from './DeliveryStep.css'

type DeliveryStepWithCalendarProps = {
  deliveryDays: DeliveryDay[]
  tempDate: string
  onTempDateChange: (date: string) => void
  dateLabel: string
  onShowRecipe: () => void
}

export const DeliveryStepWithCalendar = ({
  deliveryDays,
  tempDate,
  onTempDateChange,
  dateLabel,
  onShowRecipe,
}: DeliveryStepWithCalendarProps) => {
  const deliveryInstructions = useSelector(getDeliveryDetailsInstructions)
  const isValidDeliveryDetailsInstructionsCustom = useSelector(
    getIsValidDeliveryDetailsInstructionsCustom,
  )
  const district = useSelector(getDistrict)
  const amountOfCustomers = useSelector(getAmountOfCustomers)

  const isWizard2DDBannerEnabled = useIsWizard2DDBannerEnabled()

  const showSocialBelongingBanner = !isWizard2DDBannerEnabled && district && amountOfCustomers > 50

  let ctaDisabled
  if (!deliveryInstructions) {
    ctaDisabled = true
  } else {
    ctaDisabled = !isValidDeliveryDetailsInstructionsCustom
  }

  return (
    <>
      {showSocialBelongingBanner && (
        <SocialBelongingBanner
          amountOfCustomers={amountOfCustomers}
          district={district}
          trackBannerAppearance={trackSocialBelongingBannerAppearance}
        />
      )}
      <ScreenReaderLiveRegion urgency="polite">
        {isWizard2DDBannerEnabled && (
          <>
            <DeliveryInfoBanner deliveryDays={deliveryDays} />
            <Space size={4} />
          </>
        )}
      </ScreenReaderLiveRegion>
      <div className={css.calendarContainer}>
        <div data-testing="signupDeliveryDay" data-testid="signupDeliveryDay">
          <Calendar
            deliveryDays={deliveryDays}
            selectedDay={tempDate}
            onDayChange={onTempDateChange}
          />
        </div>
        <>
          <DeliveryInstructions />
          <DeliveryTips />
        </>
      </div>
      <div className={signupCss.footer}>
        <div className={classNames(signupCss.inputContainer, css.confirmButton)}>
          <Button
            data-testing="signupDeliveryCTA"
            data-testid="signupDeliveryCTA"
            width="full"
            onClick={onShowRecipe}
            disabled={ctaDisabled}
          >
            {`Select ${dateLabel}`}
          </Button>
        </div>
      </div>
      <RibbonTriggerContainer name="wizard-delivery-options" />
    </>
  )
}
