import {
  QuestionItem,
  RecipeItem,
  UserFeedbackResponse,
} from '@library/user-feedback/queries/types'
import { Feedback, QuestionRating, RecipeRating } from './types'

export const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
})

export const formatFeedbackResponse = (response: UserFeedbackResponse): Feedback[] => {
  return response.data.reduce((result, orderReference) => {
    return [
      ...result,
      {
        id: orderReference.id,
        type: orderReference.type,
        deliveryDate: orderReference.attributes.deliveryDate,
        recipes: orderReference.relationships.items.data.map(({ id: recipeId }) => {
          const recipe = response.included.find((item) => item.id === recipeId) as RecipeItem
          return {
            id: recipe.id,
            type: recipe.type,
            name: recipe.attributes.name,
            images: recipe.attributes.images.map((image) => {
              const [largestCropImage] = image.crops.slice(-1)
              return {
                title: image.title,
                description: image.description,
                crops: image.crops,
                defaultCrop: largestCropImage,
              }
            }),
            orderDate: orderReference.attributes.deliveryDate,
            orderId: orderReference.id,
          }
        }),
        questions: orderReference.relationships.feedback.data.map((questionItem) => {
          const question = response.included.find(
            (item) => item.id === questionItem.id,
          ) as QuestionItem
          return {
            id: question.id,
            type: question.type,
            questionType: question.attributes.questionType,
            title: question.attributes.title,
            maxScore: question.attributes.score.maxValue,
            commentAllowed: question.attributes.commentAllowed,
            orderId: orderReference.id,
          }
        }),
      },
    ]
  }, [] as Feedback[])
}

export const getAllRecipes = (feedback: Feedback[]): RecipeRating[] => {
  return feedback.reduce((result, feedbackItem) => {
    return [...result, ...feedbackItem.recipes]
  }, [] as RecipeRating[])
}

export const getAllQuestions = (feedback: Feedback[]): QuestionRating[] => {
  return feedback.reduce((result, feedbackItem) => {
    return [...result, ...feedbackItem.questions]
  }, [] as QuestionRating[])
}
