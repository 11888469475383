import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { incrementTutorialViewed, tutorialTracking } from 'actions/tutorial'
import { Portal } from 'routes/Menu/components/Portal'
import { Tutorial, Step } from 'routes/Menu/components/Tutorial'
import { getRecommendationShortName } from 'routes/Menu/selectors/collections'
import { getUserFirstName } from 'selectors/user'

import { CollectionSlug } from '../../domains/collections'
import { useShowJFYTutorial } from './useShowJFYTutorial'

import css from './JustForYouTutorial.css'

const tutorialName = 'just_for_you'

export const JustForYouTutorial = () => {
  const dispatch = useDispatch()
  const collectionName = useSelector(getRecommendationShortName) || 'Chosen For You'
  const userFirstName = useSelector(getUserFirstName)
  const showTutorial = useShowJFYTutorial()

  const onCloseTutorial = (step: number) => {
    dispatch(incrementTutorialViewed('justforyou'))
    dispatch(tutorialTracking(tutorialName, step, true))
  }

  const trackStepViewed = (step: number) => {
    dispatch(tutorialTracking(tutorialName, step, false))
  }

  if (!showTutorial) {
    return null
  }

  return (
    <Portal>
      <Tutorial onClose={onCloseTutorial} trackStepViewed={trackStepViewed}>
        <Step selector={`[data-slug='${CollectionSlug.Recommendations}']`}>
          <p className={css.main}>{userFirstName ? `For ${userFirstName}` : collectionName}</p>
          <p className={css.text}>
            Here&apos;s a personalised section of recipes we think you&apos;ll like based on what
            you&apos;ve had before
          </p>
        </Step>
        <Step selector="[data-slug='all-recipes']">
          <p className={css.text}>
            You can still browse all available recipes by clicking All Recipes.
          </p>
        </Step>
      </Tutorial>
    </Portal>
  )
}
