import { phoneValidator } from 'routes/Checkout/checkoutFormUtils'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { getBasketPostcode } from 'selectors/basket'

export const validateShippingContact: CheckoutActionCreator<ApplePayError[]> =
  (shippingContact: ApplePayJS.ApplePayPaymentContact) => (_dispatch, getState) => {
    const errors: ApplePayError[] = []

    const { postalCode = '', countryCode, phoneNumber } = shippingContact

    const wizardPostcode = (getBasketPostcode(getState() as any) as string)
      .replace(/\s+/g, '')
      .toUpperCase()
    const applePayPostcode = postalCode.replace(/\s+/g, '').toUpperCase()

    // From developer.apple.com:
    // For Canada and United Kingdom, a redacted shipping address excludes the last three characters of the postal code.
    // The full postal code is provided after the user authorizes the transaction.
    if (!wizardPostcode.startsWith(applePayPostcode)) {
      errors.push(
        new ApplePayError(
          'shippingContactInvalid',
          'postalCode',
          "A postcode in this delivery address doesn't match the postcode entered in a previous step",
        ),
      )
    }

    if (countryCode !== 'GB') {
      errors.push(new ApplePayError('shippingContactInvalid', 'countryCode'))
    }

    if (typeof phoneNumber !== 'undefined') {
      const errorMessage = phoneValidator(phoneNumber)

      if (errorMessage) {
        errors.push(new ApplePayError('shippingContactInvalid', 'phoneNumber', errorMessage))
      }
    }

    return errors
  }
