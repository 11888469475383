import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik, Field, FieldProps } from 'formik'
import useSWR from 'swr'
import { ApiKeys } from '../../enums'
import {
  Accordion,
  Text,
  Join,
  Space,
  InputField,
  FormFieldStatus,
  Button,
} from '@gousto-internal/citrus-react'

import { fetchCurrentUser, updateUserEmail } from '@library/api-user'
import { RenderedTitle } from '../RenderedTitle'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('The email must be a valid email address.')
    .max(255)
    .required('The email address is required'),
  password: Yup.string().min(1).max(255).required('The password is required'),
})

export const UserEmailSection = () => {
  const { data, mutate } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const [timestamp, forceRender] = useState<number>(new Date().getTime())
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      email: data?.user.email || '',
      password: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      try {
        await mutate(updateUserEmail({ email: values.email, password: values.password }))
        forceRender(new Date().getTime())
      } catch (error: any) {
        const fieldName = error.message.includes('password') ? 'password' : 'email'

        setFieldError(
          fieldName,
          'Sorry, we couldn’t process your request right now. Please try again.',
        )
      }
    },
  })
  const { errors, isSubmitting, resetForm } = formik
  const [isPasswordExpanded, setPasswordExpanded] = useState<boolean>(false)

  return (
    <Accordion
      id="email-accordion"
      key={`email-accordion-${timestamp}`}
      title={(isExpanded) => (
        <RenderedTitle isExpanded={isExpanded} title="Email" iconType="email">
          <Text size={2} ellipsis>{`${data?.user.email || ''}`}</Text>
        </RenderedTitle>
      )}
      onChange={(isExpanded) => {
        if (isExpanded) {
          return
        }
        resetForm({ values: { email: data?.user.email || '', password: '' } })
        setPasswordExpanded(false)
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off">
          <Join with={<Space size={4} />}>
            <Field name="email">
              {({ field }: FieldProps) => (
                <InputField
                  required
                  type="email"
                  id={field.name}
                  label="Email address"
                  placeholder="Type email address"
                  validationMessage={errors.email}
                  status={errors.email ? FormFieldStatus.Error : undefined}
                  {...field}
                />
              )}
            </Field>
            {isPasswordExpanded ? (
              <>
                <Field name="password">
                  {({ field }: FieldProps) => (
                    <InputField
                      required
                      id={field.name}
                      type="password"
                      label="Confirm password"
                      placeholder="Type current password"
                      validationMessage={errors.password}
                      status={errors.password ? FormFieldStatus.Error : undefined}
                      {...field}
                    />
                  )}
                </Field>
                <Space size={6} direction="vertical" />
                <Button width="100%" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </>
            ) : (
              <Button
                width="100%"
                type="button"
                onClick={() => setPasswordExpanded(true)}
                disabled={!!errors.email}
              >
                Next
              </Button>
            )}
          </Join>
        </Form>
      </FormikProvider>
    </Accordion>
  )
}
