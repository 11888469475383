import React from 'react'

import { datadogRum } from '@datadog/browser-rum'
import {
  Body1,
  Bold,
  BorderStyle,
  Box,
  Button,
  ButtonColorVariant,
  Color,
  Display,
  Icon,
  IconVariant,
  Space,
  useModal,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { EXISTING_EMAIL_WARNING_NOTIFICATION_COPY, EXISTING_EMAIL_LOGIN_MODAL_NAME } from './config'

const LoginButton = withCitrusTheme(Button, (theme) => ({
  background: theme.color(Color.Transparent),
}))

export const ExistingEmailWarningNotification = () => {
  const { openModal } = useModal()

  const handleClick = () => {
    openModal(EXISTING_EMAIL_LOGIN_MODAL_NAME)
    datadogRum.addAction('click_open_existing_email_modal')
  }

  return (
    <>
      <Box
        paddingH={5}
        paddingV={5}
        borderColor={Color.Error_400}
        borderStyle={BorderStyle.Solid}
        borderWidth={0.5}
        borderRadius={1.5}
        bg={Color.Error_50}
        data-testid="ExistingEmailWarningNotification"
      >
        <Box display={Display.Flex}>
          <Box paddingRight={2}>
            <Icon name="info" variant={IconVariant.Error} />
          </Box>
          <Box>
            <Body1>
              <Bold>{EXISTING_EMAIL_WARNING_NOTIFICATION_COPY.loginTitle}</Bold>{' '}
              {EXISTING_EMAIL_WARNING_NOTIFICATION_COPY.loginMessage}
            </Body1>
          </Box>
        </Box>
        <Space />
        <LoginButton
          onClick={handleClick}
          height="48px"
          width="100%"
          colorVariant={ButtonColorVariant.Secondary}
          data-testid="existingEmailLoginCTA"
        >
          {EXISTING_EMAIL_WARNING_NOTIFICATION_COPY.loginCTA}
        </LoginButton>
      </Box>
      <Space />
    </>
  )
}
