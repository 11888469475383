import { useCallback, useState } from 'react'

import actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'

import { trackPromoCodeChange } from 'routes/Checkout/checkoutActions'
import { usePricing } from 'routes/Menu/domains/pricing'
import { getPromoCode } from 'selectors/basket'

import { PromoCodeProps } from './promoCodeModels'

const DEBOUNCE_MS = 500

export const usePromoCode = (): PromoCodeProps => {
  const dispatch = useDispatch()

  const promoCode = useSelector(getPromoCode) || ''

  const { pricing } = usePricing()
  const promoCodeValid = pricing?.promoCodeValid ?? null

  const [promoCodeValue, setPromoCodeValue] = useState(promoCode)

  const [isFirstRun, setIsFirstRun] = useState(true)

  useDebounce(
    () => {
      const hasPromoCode = !!promoCodeValue

      dispatch(actions.basketPromoCodeAppliedChange(hasPromoCode))

      // debounce callback is run on the component render
      // at the first time we send only the promo code applied event
      // all the other calls should trigger also basketPromoCodeChange
      if (isFirstRun) {
        setIsFirstRun(false)
      } else {
        dispatch(actions.basketPromoCodeChange(promoCodeValue))

        if (hasPromoCode) {
          dispatch(trackPromoCodeChange(promoCodeValue, true))
        } else {
          dispatch(trackPromoCodeChange(promoCode, false))
        }
      }
    },
    DEBOUNCE_MS,
    [promoCodeValue],
  )

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target

      setPromoCodeValue(value.toUpperCase())
    },
    [setPromoCodeValue],
  )

  return {
    promoCode: promoCodeValue,
    isValid: promoCodeValid,
    handleChange,
  }
}
