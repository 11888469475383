import useSWR from 'swr'
import { fetchUserOrders, Order } from '@library/api-user'
import { ApiKeys } from '../configs'
import { orderModel } from '../models/orderModel'

type UseOrderResult = {
  pendingOrders: Order[]
  lastDeliveredOrder: Order | undefined
  error: Error | undefined
  isLoading: boolean
}

const model = orderModel()

const useOrder = (): UseOrderResult => {
  const { data, error } = useSWR(ApiKeys.UserCurrentOrders, fetchUserOrders)

  const lastDeliveredOrder = getLastDeliveredOrder(data ?? [])
  const pendingOrders = getPendingOrders(data ?? [])

  return {
    pendingOrders,
    lastDeliveredOrder,
    error,
    isLoading: !data && !error,
  }
}

const getLastDeliveredOrder = (orders: Order[]) => {
  const deliveredOrders = orders?.filter((order: Order) => model.isOrderDelivered(order))

  return sortOrdersByDeliveryDateDesc(deliveredOrders)[0]
}

const sortOrdersByDeliveryDateDesc = (orders: Order[]) => {
  return orders?.sort(
    (a, b) =>
      new Date(model.getDeliveryDate(b) ?? 0).getTime() -
      new Date(model.getDeliveryDate(a) ?? 0).getTime(),
  )
}

const getPendingOrders = (orders: Order[]) => {
  const pendingOrders = orders?.filter((order: Order) => model.isOrderPending(order))

  return sortOrdersByDeliveryDateAsc(pendingOrders)
}

const sortOrdersByDeliveryDateAsc = (orders: Order[]) => {
  return orders?.sort(
    (a, b) =>
      new Date(model.getDeliveryDate(a) ?? 0).getTime() -
      new Date(model.getDeliveryDate(b) ?? 0).getTime(),
  )
}

export { useOrder }
