import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormFieldStatus, Input } from '@gousto-internal/citrus-react'

interface AddressLineInputProps {
  name: string
  placeholder: string
}

const AddressLineInput = ({ name, placeholder }: AddressLineInputProps) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <Input
        id={name}
        type="text"
        placeholder={placeholder}
        autoComplete={`address-${name}`}
        status={meta.error ? FormFieldStatus.Error : undefined}
        maxLength={35}
        {...field}
      />
    )}
  </Field>
)

const AddressLineOne = () => <AddressLineInput name="line1" placeholder="Address line 1" />

const AddressLineTwo = () => <AddressLineInput name="line2" placeholder="Address line 2" />

const AddressLineThree = () => <AddressLineInput name="line3" placeholder="Address line 3" />

export { AddressLineOne, AddressLineTwo, AddressLineThree }
