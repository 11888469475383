import { useMemo } from 'react'

import Immutable from 'immutable'

import { useLegacyInStockRecipeIds } from './stock'
import { useLegacyCurrentMenuRecipes, useLegacyCurrentMenuVariants } from './recipes'

import { getOutOfStockRecipeReplacer } from './getOutOfStockRecipeReplacer'
import { getRecipeComparatorForOutOfStock } from './getRecipeComparatorForOutOfStock'
import { getRecipeReferenceInjector } from './getRecipeReferenceInjector'
import { getSelectedVariantsReplacer } from './getSelectedVariantsReplacer'
import { SelectedVariants } from './useAlternativeOptions/types'

const getRecipesInCollection = (
  menuCollections: Immutable.Map<string, any>,
  collectionId: string,
): Immutable.List<string> =>
  menuCollections.getIn([collectionId, 'recipesInCollection'], Immutable.List())

const getDietaryClaimsInCollection = (
  menuCollections: Immutable.Map<string, any>,
  collectionId: string,
) => menuCollections.getIn([collectionId, 'requirements', 'dietary_claims'], null)

export function useGetRecipesForCollectionId(
  menuId: string,
  selectedRecipeVariants: SelectedVariants,
  collections: Immutable.Map<string, any>,
  numPortions: number,
) {
  const recipes = useLegacyCurrentMenuRecipes(numPortions)
  const recipesInStockIds = useLegacyInStockRecipeIds({ numPortions })
  const recipesVariants = useLegacyCurrentMenuVariants(menuId)

  const recipeComparatorForOutOfStock = useMemo(
    () => getRecipeComparatorForOutOfStock(recipesInStockIds),
    [recipesInStockIds],
  )

  const getRecipesForCollectionId = (collectionId: string) => {
    const recipeIdsInCollection = getRecipesInCollection(collections, collectionId)
    const dietaryClaims = getDietaryClaimsInCollection(collections, collectionId)

    if (!recipeIdsInCollection || !recipes.size) {
      return {
        recipes: Immutable.List<{
          recipe: Immutable.Map<string, string>
          originalId: string
          reference: string
        }>(),
      }
    }

    const selectedVariantReplacer = getSelectedVariantsReplacer({
      recipes,
      replacementMap: (selectedRecipeVariants || {})[collectionId] || {},
    })

    const outOfStockRecipeReplacer = getOutOfStockRecipeReplacer({
      recipesInStockIds,
      recipes,
      recipesVariants,
      dietaryClaims,
    })

    const recipeReferenceInjector = getRecipeReferenceInjector({ recipesVariants })

    // The order of mappers below matters:
    //  * ensure the recipes are wrapped in an envelop with additional meta data: originalId and reference
    //  * ensure any out of stock recipes are replaced by in stock alternatives
    //  * ensure any remaining out of stock recipes are moved to the end of the list
    //  * ensure we prefer to show variants customer explicitly picked (even when out of stock)

    const sortedRecipes = recipeIdsInCollection
      .map((id?: string) =>
        recipes.find((other?: Immutable.Map<string, string>) => other?.get('id') === id),
      )
      .filter((recipe?: Immutable.Map<string, string>) => Boolean(recipe))
      .map(recipeReferenceInjector)
      .map(outOfStockRecipeReplacer)
      .sort(recipeComparatorForOutOfStock)
      .map(selectedVariantReplacer)

    return { recipes: sortedRecipes }
  }

  return {
    getRecipesForCollectionId,
  }
}
