import React from 'react'

import {
  Box,
  Display,
  FontFamily,
  FontWeight,
  Image,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import css from './styles.css'

interface Props {
  title: string
  image: string
}

export const RecipeListItem: React.FC<Props> = ({ image, title }) => (
  <li className={css.recipeListItem}>
    <Box display={Display.Flex} paddingBottom={2}>
      <Box>
        <Image
          width={['60px', '80px']}
          height={['60px', '80px']}
          src={image}
          alt={`Image of ${title}`}
        />
      </Box>
      <Space size={4} direction="horizontal" />
      <Box>
        <Text fontWeight={FontWeight.Bold} fontFamily={FontFamily.Bold} size={2}>
          {title}
        </Text>
      </Box>
    </Box>
  </li>
)
