import React from 'react'

import { Route, IndexRoute } from 'react-router'

import { client as clientRoutes } from 'config/routes'

import { MenuContainer } from './MenuContainer'
import { MenuRecipesPageWrapper } from './MenuRecipesPage'

export const MenuRoute = (
  <Route path={clientRoutes.menu} component={MenuContainer}>
    <IndexRoute component={MenuRecipesPageWrapper} />
    <Route path=":orderId" component={MenuRecipesPageWrapper} />
  </Route>
)
