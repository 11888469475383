import { useCallback } from 'react'

import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { actionTypes } from 'actions/actionTypes'
import * as trackingKeys from 'actions/trackingKeys'
import { getBasketDate, getBasketMenuId, getBasketOrderId } from 'selectors/basket'
import { getBoxSummaryDeliveryDays, getMenuBrowseCTAShow, getMenuCollections } from 'selectors/root'
import { getTransactionType } from 'selectors/tracking'

export function useTracking() {
  const dispatch = useDispatch()
  const basketDate = useSelector(getBasketDate)
  const boxSummaryDeliveryDays = useSelector(getBoxSummaryDeliveryDays)
  const transactionType = useSelector(getTransactionType)
  const menuCollections = useSelector(getMenuCollections)
  const currentMenuId = useSelector(getBasketMenuId)
  const orderId = useSelector(getBasketOrderId)
  const browseMode = useSelector(getMenuBrowseCTAShow)
  const recommenderVersion = useSelector(
    ({ menuService }: RootStateOrAny) =>
      menuService.meta.recommendations && menuService.meta.recommendations.version,
  )
  const recommendationId = useSelector(
    ({ menuService }: RootStateOrAny) =>
      menuService.meta.recommendations && menuService.meta.recommendations.id,
  )

  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (collectionId, displayedOrder) => {
      const identifier = uuid()

      const collectionName = menuCollections.getIn([collectionId, 'slug'], '')
      const indexOfCollection = menuCollections.keySeq().indexOf(collectionId || '')
      const deliveryDayId = boxSummaryDeliveryDays.getIn([basketDate, 'id'])

      dispatch({
        type: actionTypes.RECIPES_SHOWN,
        trackingData: {
          actionType: trackingKeys.recipesShown,
          identifier,
          displayedOrder: [], // TODO: Pass in displayedOrder when we figure out how to fix character limit on analytics events
        },
      })

      dispatch({
        type: actionTypes.VIEW_RECIPE_LIST,
        trackingData: {
          actionType: trackingKeys.viewRecipeList,
          identifier,
          collectionId,
          collectionName,
          collectionPosition: indexOfCollection + 1,
          deliveryDayId,
          orderId,
          browseMode,
          recommenderVersion,
          recommendationId,
          currentMenuId,
          transactionType,
        },
      })
    },
    [
      dispatch,
      basketDate,
      boxSummaryDeliveryDays,
      transactionType,
      menuCollections,
      currentMenuId,
      orderId,
      browseMode,
      recommenderVersion,
      recommendationId,
    ],
  )
}
