import { actionTypes } from 'actions/actionTypes'
import * as trackingKeys from 'actions/trackingKeys'
import { cancelMultipleBoxes } from 'actions/order'
import { modalVisibilityChange } from 'actions/onScreenRecovery'
import { trackSubscriptionSettingsChange } from '../Subscription/tracking'

export const multiSkipTrackContinueToPause = () => (dispatch) => {
  dispatch({
    type: actionTypes.TRACKING,
    trackingData: {
      actionType: trackingKeys.continueToPause
    }
  })
}

export const trackViewMultiSkip = () => (dispatch) => {
  dispatch({
    type: actionTypes.TRACKING,
    trackingData: {
      actionType: trackingKeys.viewMultiSkipBoxesScreen
    }
  })
}

export const multiSkipCloseModal = () => (dispatch) => {
  modalVisibilityChange({ modalVisibility: false })(dispatch)
  dispatch({
    type: actionTypes.CANCEL_MULTIPLE_BOXES_END
  })
}

export const skipMultipleBoxes = ({selectedOrders}, userId) => (dispatch, getState) => {
  const skippedOrders = selectedOrders.map(({ deliveryDay }) => deliveryDay)

  trackSubscriptionSettingsChange({ settingName: 'recover_subscription', action: 'click' })({
    pause_reason: "I'm going away",
    recovery_action: 'Skip my deliveries',
    recovery_action_selected: skippedOrders,
  })
  cancelMultipleBoxes({selectedOrders}, userId)(dispatch, getState)
}
