import { OrderBox, RecipeItem } from '@library/api-user'
import { orderModel } from '../models/orderModel'

const useRecipeImageTile = (recipes: RecipeItem[], box: OrderBox) => {
  const { getOrderRecipeImageURLs } = orderModel()
  const maxRecipes = Number(box.numPortions) < 5 ? 5 : 3
  const recipeMediaImages = getOrderRecipeImageURLs(recipes, '200')

  const selectedRecipesCount = recipeMediaImages.length

  const numberOfRecipesLeftToSelect = maxRecipes - selectedRecipesCount
  const arrayOfEmptyRecipes = Array.from({ length: numberOfRecipesLeftToSelect }, () => null)

  return {
    recipeImageURLs: [...recipeMediaImages, ...arrayOfEmptyRecipes],
    maxRecipes,
  }
}

export { useRecipeImageTile }
