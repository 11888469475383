import { actionTypes } from 'actions/actionTypes'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const saveApplePayPersonalInfo: CheckoutActionCreator = ({
  givenName,
  familyName,
  phoneNumber,
}: // eslint-disable-next-line no-undef
ApplePayJS.ApplePayPaymentContact) => ({
  type: actionTypes.SAVE_APPLE_PAY_PERSONAL_INFO,
  personalInfo: {
    firstName: givenName,
    lastName: familyName,
    phoneNumber,
  },
})
