import React from 'react'
import { Field, FieldProps } from 'formik'
import { SelectField } from '@gousto-internal/citrus-react'
import { DELIVERY_INSTRUCTIONS } from '@library/api-user'

const DeliveryInstructionsDropdown = () => (
  <Field name="deliveryInstructions">
    {({ field }: FieldProps) => (
      <SelectField
        id="deliveryInstructions"
        label="Where can we leave your box if you're not in?"
        data-testid="deliveryInstructions"
        {...field}
      >
        {DELIVERY_INSTRUCTIONS.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </SelectField>
    )}
  </Field>
)

export { DeliveryInstructionsDropdown }
