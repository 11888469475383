import React, { ChangeEvent, useState } from 'react'

import {
  Box,
  FontFamily,
  FormFieldStatus,
  InputField,
  SelectField,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import {
  saveDeliveryDetailsInstruction,
  saveDeliveryDetailsInstructionCustom,
} from 'actions/deliveryDetails'
import { safePlaceDropDownSelected } from 'actions/trackingKeys'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
  getIsValidDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'

import { deliveryInstructionsFields } from './DeliveryInstructionsConfig'

const trackDeliveryInstructionSelection = (selection: string) => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: safePlaceDropDownSelected,
    selection,
  },
})

const leaveBoxOptions = deliveryInstructionsFields.leaveBoxOptions.map((option) => ({
  value: option,
  label: option,
}))

export const DeliveryInstructions = () => {
  const dispatch = useDispatch()
  const [isCustomInstructionTouched, setIsCustomInstructionTouched] = useState(false)
  const leaveBoxValue = useSelector(getDeliveryDetailsInstructions)

  const customMessage = useSelector(getDeliveryDetailsInstructionsCustom)

  const onLeaveBoxChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    dispatch(saveDeliveryDetailsInstruction(value))
    dispatch(trackDeliveryInstructionSelection(value))

    if (!deliveryInstructionsFields.leaveBoxOptionsWithMessage.includes(value)) {
      dispatch(saveDeliveryDetailsInstructionCustom(''))
    }
  }

  const onCustomMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    dispatch(saveDeliveryDetailsInstructionCustom(value))
    setIsCustomInstructionTouched(true)
  }

  const isCustomMessageCorrect = useSelector(getIsValidDeliveryDetailsInstructionsCustom)

  return (
    <Box paddingBottom={6}>
      <SelectField
        id="leaveBox"
        data-testid="deliveryDetailInstruction"
        label={
          <Text fontFamily={FontFamily.Bold} size={2}>
            {deliveryInstructionsFields.selectFieldLabel}
          </Text>
        }
        onChange={onLeaveBoxChange}
        defaultValue={leaveBoxValue || ''}
      >
        <option disabled hidden value="">
          {deliveryInstructionsFields.placeholderText}
        </option>

        {leaveBoxOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectField>

      {deliveryInstructionsFields.leaveBoxOptionsWithMessage.includes(leaveBoxValue) && (
        <InputField
          id="customLeaveBox"
          data-testid="customLeaveBox"
          label={
            <Text fontFamily={FontFamily.Bold} size={2}>
              Extra details for your delivery driver
            </Text>
          }
          placeholder={
            leaveBoxValue === 'Neighbour'
              ? 'e.g. next door at number 10'
              : 'e.g. lockbox around left side of house'
          }
          maxLength={32}
          value={customMessage}
          onChange={onCustomMessageChange}
          status={
            !isCustomInstructionTouched || isCustomMessageCorrect
              ? undefined
              : FormFieldStatus.Error
          }
          aria-invalid={!isCustomInstructionTouched || !isCustomMessageCorrect}
          validationMessage={
            !isCustomInstructionTouched || isCustomMessageCorrect
              ? undefined
              : 'Delivery instruction must be at least 5 characters'
          }
        />
      )}
    </Box>
  )
}
