import React, { useEffect } from 'react'

import { FontWeight, Text } from '@gousto-internal/citrus-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { DeliveryCard } from 'routes/Checkout/Components/Delivery/DeliveryCard'

import css from './SocialBelongingBanner.css'

export const SocialBelongingBanner = ({ amountOfCustomers, district, trackBannerAppearance }) => {
  useEffect(trackBannerAppearance, [trackBannerAppearance])

  return (
    <div className={classNames(css.socialBelongingContainer)}>
      <DeliveryCard
        iconName="icon-serving-size-blue"
        cardStyle="blue"
        customStyle={{ padding: '12px' }}
      >
        <Text size={1} fontWeight={FontWeight.Bold}>
          Join our community of {amountOfCustomers.toLocaleString()} Gousto customers in {district}{' '}
          today!
        </Text>
      </DeliveryCard>
    </div>
  )
}

SocialBelongingBanner.propTypes = {
  amountOfCustomers: PropTypes.number.isRequired,
  district: PropTypes.string.isRequired,
  trackBannerAppearance: PropTypes.func.isRequired,
}
