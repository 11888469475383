import { withCitrusTheme } from '@gousto-internal/citrus-react'

export const ResetPaymentMethod = withCitrusTheme('span', (theme) => ({
  color: theme.tokens.colors.Secondary_400,
  transition: 'all 200ms ease',
  transitionProperty: 'color, border',
  borderBottom: `1px solid `.concat(theme.tokens.colors.ColdGrey_700),
  '&:active, &:hover, &:focus': {
    color: theme.tokens.colors.Secondary_600,
    borderColor: theme.tokens.colors.Secondary_600,
  },
}))
