import React from 'react'
import { Color, Join, Space, Body1 } from '@gousto-internal/citrus-react'
import { DeliveryInstructionsDropdown } from './DeliveryInstructionsDropdown'
import { DeliveryInstructionsInput } from './DeliveryInstructionsInput'

const DeliveryInstructions = () => (
  <Join with={<Space size={2} />}>
    <DeliveryInstructionsDropdown />
    <DeliveryInstructionsInput />
    <Body1 size={1} color={Color.ColdGrey_600}>
      {`To ensure your box is delivered safely, we cannot accept instructions to leave boxes over fences, through windows, in bins/bin cupboard, wheelie bins or too exposed to the elements.`}
    </Body1>
  </Join>
)

export { DeliveryInstructions }
