import { Map } from 'immutable'

import { useNumPortions } from './useNumPortions'
import { useSupportedBoxTypes } from './useSupportedBoxTypes'

export const sumQuantities = (recipes: Map<string, number>) =>
  recipes.reduce((total = 0, current = 0) => total + current, 0)

export const useRecipeLimitExceeded = (recipes: Map<string, number>): boolean => {
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const { numPortions } = useNumPortions()

  return sumQuantities(recipes) > maxRecipesForPortion(numPortions)
}

export const useRecipeLimitReached = (recipes: Map<string, number>): boolean => {
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const { numPortions } = useNumPortions()

  return sumQuantities(recipes) >= maxRecipesForPortion(numPortions)
}
