import React from 'react'

import {
  AlignItems,
  Box,
  Display,
  Text,
  FontFamily,
  FlexDirection,
  JustifyContent,
} from '@gousto-internal/citrus-react'

import { boxSizes as boxSize } from 'routes/BoxPrices/boxPricesConfig'

import { BoxPriceButton } from '../BoxPriceButton/BoxPriceButton'
import { BoxPricesWithAllPortionsType } from '../boxPricesModels'

type BoxPricesRecipesButtonType = Pick<
  BoxPricesWithAllPortionsType,
  'activeRecipes' | 'activeNumPortion'
> & {
  handleOnClickOfRecipes: (index: number, recipe: number) => void
}
export const BoxPricesRecipesButton = ({
  activeRecipes,
  activeNumPortion,
  handleOnClickOfRecipes,
}: BoxPricesRecipesButtonType) => (
  <Box
    display={Display.Flex}
    flexDirection={[FlexDirection.Column, FlexDirection.Row]}
    justifyContent={JustifyContent.Center}
    alignItems={[AlignItems.FlexStart, AlignItems.Center]}
    wrap={false}
    width="100%"
  >
    <Box width={['100%', '50%']} paddingBottom={1}>
      <Text fontFamily={FontFamily.Bold} size={2}>
        Select number of recipes
      </Text>
    </Box>
    <Box width={['100%', '50%']} alignItems={AlignItems.Center} display={Display.Flex}>
      {boxSize[activeNumPortion].map((recipe, index) => {
        const numRecipes = Number(recipe)

        return (
          <BoxPriceButton
            key={numRecipes}
            text={numRecipes}
            isActive={activeRecipes === index}
            handleOnClick={() => handleOnClickOfRecipes(index, numRecipes)}
            dataTestId="numRecipesButton"
          />
        )
      })}
    </Box>
  </Box>
)
