import React, { SyntheticEvent } from 'react'

import { Box } from '@gousto-internal/citrus-react'

import { MenuPreferencesOptions } from './MenuPreferencesOptions'

import css from './MenuPreferencesDesktop.css'

type MenuPreferencesDesktopProps = {
  onClose: (event?: SyntheticEvent) => void
}

export const MenuPreferencesDesktop = ({ onClose }: MenuPreferencesDesktopProps) => (
  <Box className={css.menuPreferencesDropDown} data-testid="menu-preferences-desktop">
    <MenuPreferencesOptions onClose={onClose} variant="desktop" />
  </Box>
)
