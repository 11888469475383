import React from 'react'

import {
  AlignItems,
  Box,
  Button,
  ButtonColorVariant,
  Body2,
  Color,
  Display,
  FlexDirection,
  Space,
} from '@gousto-internal/citrus-react'

import css from '../Detail.css'

type Props = {
  reload: () => void
}

export const DetailError = ({ reload }: Props) => (
  <div className={css.sectionPanel}>
    <Box alignItems={AlignItems.Center} display={Display.Flex} flexDirection={FlexDirection.Column}>
      <Body2 color={Color.ColdGrey_900}>
        Unable to load ingredients and nutritional information
      </Body2>
      <Space size={3} />
      <Button colorVariant={ButtonColorVariant.Secondary} onClick={() => reload()}>
        Try again?
      </Button>
    </Box>
  </div>
)
