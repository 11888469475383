import React from 'react'

import { Box, Display, Image } from '@gousto-internal/citrus-react'

type LovedByBrandLogoProps = {
  image: string
}

export const LovedByBrandLogo = ({ image }: LovedByBrandLogoProps) => (
  <Box wrap={true} key={image} maxWidth="100%" minWidth="100px" display={Display.Inline}>
    <Image height={['25px', '30px']} fit="contain" src={image} alt="" />
  </Box>
)
