// eslint-disable-next-line import/no-named-as-default
import fetch from 'utils/fetch'
import routes from 'config/routes'
import { endpoint } from '@library/endpoint'

export function buildSubscriptionCommandUrl(userId, path = '') {
  return `${endpoint('subscriptioncommand')}/subscriptions/${userId}${path}`
}

export function buildSubscriptionQueryUrl(userId, path) {
  return `${endpoint(
    'subscriptionquery'
  )}${path}/${userId}`
}

export function skipDates(accessToken, userId, dates) {
  return fetch(
    accessToken,
    `${buildSubscriptionCommandUrl(userId, routes.subscriptionCommand.skip)}`,
    {
      skipDates: dates,
    },
    'POST',
    'default',
    {'Content-Type': 'application/json'}
  )
}

export function unSkipDates(accessToken, userId, dates) {
  return fetch(
    accessToken,
    `${buildSubscriptionCommandUrl(userId, routes.subscriptionCommand.unSkip)}`,
    {
      unskipDates: dates,
    },
    'POST',
    'default',
    {'Content-Type': 'application/json'}
  )
}

export function fetchSubscription(accessToken, userId) {
  return fetch(
    accessToken,
    buildSubscriptionQueryUrl(userId, routes.subscriptionQuery.subscriptions),
    {},
    'GET',
  )
}

export function fetchProjectedDeliveries(accessToken, userId) {
  return fetch(
    accessToken,
    `${buildSubscriptionQueryUrl(userId, routes.subscriptionQuery.projectedDeliveries)}`,
    {},
    'GET',
  )
}

export function deactivateSubscription(accessToken, pauseDate, userId, pauseReason) {
  return fetch(
    accessToken,
    `${buildSubscriptionCommandUrl(userId, routes.subscriptionCommand.deactivate)}`,
    {pauseDate, pauseReason},
    'POST',
    'default',
    {'Content-Type': 'application/json'}
  )
}

export function getPauseReasons() {
  return {
    reasons: [
      {
        label: "I can't afford a weekly box",
        value: "I can't afford a weekly box",
        alternateOption: {
          hintText: 'Did you know you can change your delivery frequency to fortnightly or monthly?',
          linkText: 'Change my delivery frequency',
        }
      },
      {
        label: "I'm going away",
        value: "I'm going away",
        alternateOption: {
          hintText: 'Did you know you can skip your deliveries without pausing your whole subscription?',
          linkText: 'Skip my deliveries',
        }
      },
      {
        label: "I'm using a different recipe box brand instead",
        value: "I'm using a different recipe box brand instead",
      },
      {
        label: "The menu doesn't have enough variety for me",
        value: "The menu doesn't have enough variety for me",
      },
      {
        label: "I don't want to be on a subscription",
        value: "I don't want to be on a subscription",
      },
      {
        label: 'I had a problem',
        value: 'I had a problem',
        alternateOption: {
          hintText:
            "Sorry to hear about that. We'd love to sort out this problem if you have time to chat?",
          linkText: 'Open live chat',
        },
      },
      {
        label: 'Something else',
        value: 'Something else',
        reasons: [
          {
            value: 'I just fancied a break',
            label: 'I just fancied a break',
          },
          {
            value: "Didn't suit my lifestyle",
            label: "Didn't suit my lifestyle",
          },
          {
            value: 'Other',
            label: 'Other',
          },
        ]
      },
    ],
  }
}
