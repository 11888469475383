import React, { useContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@gousto-internal/citrus-react'
import { PortionSizeSelector } from 'PortionSizeSelector/PortionSizeSelector'
import { portionSizesConfig } from 'config/portionSizes'
import { actionTypes } from 'routes/Account/Subscription/context/reducers'
import { getSubscriptionPortionOptions } from '../../../../context/selectors/subscriptionOptions'
import {
  SubscriptionContext,
} from '../../../../context'
import { getNumPortions, getIsBoxLoaded, getSelectedBoxSize, getSubscriptionSettingsUnsupported } from '../../../../context/selectors/box'
import { SettingSection } from '../../../../components/SettingSection'
import { useUpdateSubscription } from '../../../../hooks/useUpdateSubscription'
import { trackSubscriptionSettingsChange } from '../../../../tracking'
import { BoxSizeConfirmationModal } from './BoxSizeConfirmationModal'
import { getOpenOrders } from '../../../../context/selectors/orders'

export const BoxSize = ({ accessToken, isMobile }) => {
  const { state, dispatch } = useContext(SubscriptionContext)

  const isLoaded = getIsBoxLoaded(state)
  const currentBoxSize = getNumPortions(state)
  const chosenBoxSize = getSelectedBoxSize(state)
  const isUnsupported = getSubscriptionSettingsUnsupported(state)
  const boxSizesOptions = getSubscriptionPortionOptions(state)
  const [shouldSubmit, setShouldSubmit] = useState(false)

  const settingName = 'box_size'
  const [, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    trigger: {
      shouldRequest: shouldSubmit,
      setShouldRequest: setShouldSubmit
    },
    data: {
      numPortions: chosenBoxSize
    },
    settingName
  })

  const didUpdateComplete = typeof updateResponse?.status === 'string'

  const onSubmit = () => {
    trackSubscriptionSettingsChange({ settingName, action: 'update' })()
    setShouldSubmit(true)
  }

  const handleChangeBoxSize = useCallback(
    (event) => {
      const newValue = Number(event.target.value)
      dispatch({
        type: actionTypes.UPDATE_SELECTED_BOX_SIZE,
        data: { numPortions: newValue }
      })
    },
    [dispatch],
  )

  const isCtaDisabled = chosenBoxSize === currentBoxSize
  || !chosenBoxSize

  return (
    <Box padding={0}>
      <SettingSection
        icon="servings"
        title="Box size"
        instruction="Edit box size"
        ctaText="Save"
        isCtaDisabled={isCtaDisabled}
        onChildrenRender={(collapseSection) => {
          if (!isUnsupported) {
            return
          }
          // a workaround to close the <ExpandableSection /> when external state changes
          collapseSection()
        }}
        renderCurrentValue={(
          <p data-testing="current-box-size">
            {chosenBoxSize || currentBoxSize}
            {' '}
            people
          </p>
        )}
        onSubmit={onSubmit}
        onEditClick={trackSubscriptionSettingsChange({ settingName, action: 'edit' })}
        isMobile={isMobile}
        testingSelector="box-size"
      >

        {isLoaded && (
        <PortionSizeSelector
          portionSize={Number(chosenBoxSize)}
          availablePortionSizes={boxSizesOptions}
          config={portionSizesConfig}
          onChange={handleChangeBoxSize}
        />
        )}
      </SettingSection>
      <BoxSizeConfirmationModal
        openOrders={getOpenOrders(state)}
        numPortions={currentBoxSize}
        didUpdateComplete={didUpdateComplete}
        didUpdateFail={updateError}
        updateResponse={updateResponse}
      />
    </Box>
  )
}

BoxSize.propTypes = {
  accessToken: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}
