module.exports = {
  head: {
    title: 'Food Delivery | Try Our Recipe Kits | Gousto',
    keywords: 'Gousto,recipe delivery,ingredients,fresh,healthy food,cooking',
    fbAppID: '245584327705619',
    fbAdmins: [
      '100008548455745',
      '537082922',
      '793975323',
    ],
    description: "Food delivery is simple with Gousto's popular recipe kit boxes. Receive fresh and seasonal ingredients straight to your home with FREE delivery",
    domainVerification: '5duc2owjjtp98bf1i3mqr2izosu9lg',
  },
}
