import React from 'react'

import { Route, Redirect } from 'react-router'

import { checkValidSession } from '../../utils/routes'
import { WelcomeContainer } from './WelcomeContainer'

const WelcomeRoute = (store) => (
  <Route>
    <Route path="welcome-to-gousto/" onEnter={checkValidSession(store, '/')}>
      <Route path=":orderId" component={WelcomeContainer} />
      <Redirect from="welcome-to-gousto-2/*" to="welcome-to-gousto/*" />
    </Route>
    <Redirect from="welcome-to-gousto-2/*" to="welcome-to-gousto/*" />
  </Route>
)

WelcomeRoute.displayName = 'WelcomeRoute'

export default WelcomeRoute
