import React, { useState } from 'react'
import Immutable from 'immutable'
import {
  Display,
  FlexDirection,
  Space,
  Button,
  Box,
  JustifyContent,
  AlignItems,
  ButtonColorVariant,
  Heading1,
  Body1,
  Color,
  FontWeight,
} from '@gousto-internal/citrus-react'
import { CTA_TEXT, DIETARY_PREFERENCES_TEXT } from './config'
import { DietaryPreferencesSelector } from './components/DietaryPreferencesSelector/DietaryPreferencesSelector'
import { trackCTAClick, TrackingActions } from './tracking'

type DietaryPreferencesProps = {
  defaultDietaryPreferences?: Immutable.List<string>
  onContinue: (dietaryPreferences: Immutable.List<string>) => void
  onSkip: (dietaryPreferences: Immutable.List<string>) => void
}

export function DietaryPreferences({
  onContinue,
  onSkip,
  defaultDietaryPreferences = Immutable.List(),
}: DietaryPreferencesProps) {
  const [hasDietaryPreferences, setHasDietaryPreferences] = useState<boolean>(
    defaultDietaryPreferences.size > 0,
  )
  const [dietaryPreferences, setDietaryPreferences] =
    useState<Immutable.List<string>>(defaultDietaryPreferences)

  const isContinueDisabled = hasDietaryPreferences && dietaryPreferences.size < 1

  const handleHasDietaryPreferences = (hasDietaryPreference: boolean) => {
    setHasDietaryPreferences(hasDietaryPreference)
    if (!hasDietaryPreference) {
      setDietaryPreferences(Immutable.List())
    }
  }

  const handleDietaryPreferencesChange = (pref: string): void => {
    const updatedDietaryPreferences = dietaryPreferences.includes(pref)
      ? (dietaryPreferences.filter((item) => item !== pref) as Immutable.List<string>)
      : dietaryPreferences.push(pref)
    setDietaryPreferences(updatedDietaryPreferences)
    setHasDietaryPreferences(true)
  }

  const handleSkipCTAClick = () => {
    trackCTAClick(TrackingActions.CLICK_SKIP)
    onSkip(dietaryPreferences)
  }

  const handleContinueCTAClick = () => {
    if (!isContinueDisabled) {
      trackCTAClick(
        TrackingActions.CLICK_CONTINUE,
        dietaryPreferences.toJS(),
        !hasDietaryPreferences,
      )
      onContinue(dietaryPreferences)
    }
  }

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
    >
      <Box width="100%">
        <Heading1 size={[9, 10]}>{DIETARY_PREFERENCES_TEXT.title}</Heading1>
        <Space size={[2, 4]} />
        <Body1 color={Color.ColdGrey_800} size={[2, 4]}>
          {DIETARY_PREFERENCES_TEXT.description}
        </Body1>
        <Space size={3} />
        <Body1 size={[2, 4]} fontWeight={FontWeight.Bold} color={Color.ColdGrey_800}>
          {DIETARY_PREFERENCES_TEXT.notice}
        </Body1>
      </Box>
      <Space size={[4, 6]} />
      <Box width={['100%', '392px']}>
        <DietaryPreferencesSelector
          hasDietaryPreferences={hasDietaryPreferences}
          onHasDietaryPreferencesChange={handleHasDietaryPreferences}
          dietaryPreferences={dietaryPreferences}
          onDietaryPreferencesChange={handleDietaryPreferencesChange}
        />
      </Box>
      <Space size={[4, 6]} />
      <Box
        display={Display.Flex}
        flexDirection={[FlexDirection.Column, 'row-reverse' as FlexDirection]}
        justifyContent={JustifyContent.Center}
        width={'100%'}
        gap={2}
      >
        <Button
          height="48px"
          width={['100%', '265px', '358px']}
          data-testid="dietary-preferences-continue-cta"
          onClick={handleContinueCTAClick}
          disabled={isContinueDisabled}
          aria-disabled={isContinueDisabled}
          aria-label={CTA_TEXT.CONTINUE}
        >
          {CTA_TEXT.CONTINUE}
        </Button>
        <Button
          height="48px"
          width={['100%', '265px', '358px']}
          data-testid="dietary-preferences-skip-cta"
          onClick={handleSkipCTAClick}
          colorVariant={ButtonColorVariant.Secondary}
          aria-label={CTA_TEXT.SKIP}
        >
          {CTA_TEXT.SKIP}
        </Button>
      </Box>
    </Box>
  )
}
