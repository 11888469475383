import React from 'react'
import { Box, Heading4, Overflow, withCitrusTheme } from '@gousto-internal/citrus-react'
import { Feedback, OnSubmit } from '../types'
import { RecipeTile } from '../RecipeTile'
import { dateFormatter } from '../formatters'

type FeedbackGridProps = {
  feedback: Feedback[]
  onSubmit: OnSubmit
}
const Title = withCitrusTheme(Heading4, (theme) => ({
  paddingBottom: theme.spacing(4),
}))
const Grid = withCitrusTheme(Box, (theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > div': {
    width: '325px',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

export const FeedbackGrid: React.FC<FeedbackGridProps> = ({ feedback, onSubmit }) => {
  return (
    <Box paddingV={14} data-testid="feedback-grid">
      {feedback.map((feedback) =>
        feedback.recipes.length ? (
          <Box
            key={feedback.id}
            overflowX={Overflow.Hidden}
            overflowY={Overflow.Hidden}
            width="100%"
          >
            <Title>{dateFormatter.format(new Date(feedback.deliveryDate))}</Title>
            <Grid>
              {feedback.recipes.map((recipe) => (
                <RecipeTile
                  key={recipe.id}
                  recipe={recipe}
                  questionId={
                    feedback.questions.find((question) => question.orderId === recipe.orderId)
                      ?.id as string
                  }
                  onSubmit={onSubmit}
                />
              ))}
            </Grid>
          </Box>
        ) : null,
      )}
    </Box>
  )
}
