import { fetchBrainTreeClientToken } from '@library/api-payment'
import { useCallback, useEffect, useState } from 'react'
import { loadBraintreeScript } from '../utils/loadBraintreeScript'

declare const braintree: typeof import('braintree-web')

const braintreeSetupError = 'An error occurred, please try again or contact customer service.'

export const useBraintreeClientInstance = () => {
  const [hasBraintreeScriptLoaded, setHadBraintreeScriptLoaded] = useState(false)
  const [deviceData, setDeviceData] = useState<string | null>(null)
  const [braintreeClientToken, setBraintreeClientToken] = useState<string | null>(null)
  const [braintreeClientInstance, setBraintreeClientInstance] = useState<braintree.Client | null>()
  const [braintreeError, setBraintreeError] = useState<string>('')

  const getBrainTreeClientToken = async () => {
    try {
      const response = await fetchBrainTreeClientToken()
      setBraintreeClientToken(response.clientToken)
    } catch (error) {
      setBraintreeError(braintreeSetupError)
    }
  }

  const tryLoadBraintreeScript = async () => {
    setHadBraintreeScriptLoaded(false)

    try {
      await loadBraintreeScript(window.document)
      setHadBraintreeScriptLoaded(true)
    } catch (error: Error | unknown) {
      setBraintreeError(braintreeSetupError)
    }
  }

  const initBraintree = useCallback(async () => {
    const createBraintreeClient = async () => {
      if (!braintreeClientToken) return
      const clientInstance = await braintree.client.create({
        authorization: braintreeClientToken,
      })
      setBraintreeClientInstance(clientInstance)
    }

    try {
      await createBraintreeClient()
    } catch (error: Error | unknown) {
      setBraintreeError(braintreeSetupError)
    }
  }, [braintreeClientToken])

  const initDataCollector = useCallback(async () => {
    const fetchDeviceData = async () => {
      if (!braintreeClientInstance) return
      const dataCollectorInstance = await braintree.dataCollector.create({
        client: braintreeClientInstance,
      })
      setDeviceData(dataCollectorInstance.deviceData)
    }

    try {
      await fetchDeviceData()
    } catch (error: Error | unknown) {
      setBraintreeError(braintreeSetupError)
    }
  }, [braintreeClientInstance])

  useEffect(() => {
    tryLoadBraintreeScript()
  }, [])

  useEffect(() => {
    getBrainTreeClientToken()
  }, [])

  useEffect(() => {
    if (braintreeClientToken && hasBraintreeScriptLoaded) {
      initBraintree()
    }
  }, [braintreeClientToken, hasBraintreeScriptLoaded, initBraintree])

  useEffect(() => {
    if (braintreeClientInstance) {
      initDataCollector()
    }
  }, [braintreeClientInstance, initDataCollector])

  useEffect(
    () => () => {
      if (braintreeClientInstance) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore the type here is incorrect, callback is optional
        braintreeClientInstance.teardown()
        setBraintreeClientInstance(null)
      }
    },

    [braintreeClientInstance, setBraintreeClientInstance],
  )

  return {
    braintreeClientInstance,
    braintreeError,
    deviceData,
  }
}
