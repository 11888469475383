import React from 'react'

import classnames from 'classnames'

import { Icon } from 'components/Icon'

import css from './Arrow.css'

type ArrowProps = {
  direction: string
  onClick?: () => void
}

export const Arrow = ({ direction, onClick = () => {} }: ArrowProps) => (
  <button className={classnames(css.arrow, css[direction])} onClick={onClick} type="button">
    <Icon name={`fa-chevron-${direction}`} aria-hidden="true" />
  </button>
)
