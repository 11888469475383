import React from 'react'

import { Card, CTA, Heading } from 'goustouicomponents'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import configRoutes from 'config/routes'
import { OrderDetails } from 'routes/Account/AccountComponents/OrderDetails'
import { TestOrderHeader } from 'routes/Account/MyDeliveries/OrdersList/Order/TestOrderHeader'
import { calculateBestBoxPrice } from 'routes/Account/MyGousto/Header/NextOrder/utils/calculateBestBoxPrice'
import { myGoustoOrderPropType } from 'routes/GetHelp/getHelpPropTypes'
import { getClientOrderState } from 'routes/GetHelp/utils/orders'
import { windowOpen } from 'utils/window'

import css from './NextOrder.css'

const NextOrder = ({
  boxTrackingUrls,
  hasDeliveryToday,
  order,
  trackNextBoxTrackingClick,
  trackClickGetHelpWithThisBox,
  maxNumRecipes,
}) => {
  const deliveryDate = order.get('deliveryDate')
  const orderId = order.get('id')
  const price = order.getIn(['prices', 'total'])
  const boxPrices = order.get('boxPrices')
  const numPortions = order.getIn(['box', 'numPortions'])
  const boxType = order.getIn(['box', 'boxType'])
  const isTestOrder = order.get('isTestOrder')

  const orderClientState = getClientOrderState(
    order.get('state'),
    deliveryDate,
    order.get('recipeItems'),
    order.get('phase'),
    boxTrackingUrls.length,
  )
  const recipeImages = order.get('recipeItems').map((item) => {
    const image = item
      .get('media')
      .find((mediaItem) => mediaItem.get('type') === 'mood-image')
      .getIn(['urls', '1'])

    return {
      alt: item.get('title', 'Recipe image'),
      src: image.get('src'),
    }
  })

  const CTALabel = hasDeliveryToday ? 'Any issues with this order?' : 'View my upcoming deliveries'
  const CTALink = hasDeliveryToday
    ? `${configRoutes.client.getHelp.index}?orderId=${order.get('id')}`
    : configRoutes.client.myDeliveries

  const onClick = () => {
    if (hasDeliveryToday) {
      trackClickGetHelpWithThisBox(orderId)
    }
  }

  const bestAvailableBoxPrice = calculateBestBoxPrice(boxPrices.toJS(), numPortions, boxType)

  const trackingCTAs = boxTrackingUrls.map((url, index) => (
    <div key={url} className={css.cta}>
      <CTA
        isFullWidth
        onClick={() => {
          trackNextBoxTrackingClick(orderId)
          windowOpen(url)
        }}
        size="small"
        variant="primary"
      >
        {boxTrackingUrls.length > 1 ? `Track Box ${index + 1}` : 'Track my order'}
      </CTA>
    </div>
  ))

  return (
    <div>
      <div className={css.headingWrapper} data-testing="viewUpcomingDelivery">
        <Heading size="fontStyleM" type="h2">
          {hasDeliveryToday ? "Today's delivery" : 'Upcoming delivery'}
        </Heading>
        {hasDeliveryToday && (
          <Link
            to={configRoutes.client.myDeliveries}
            className={css.headingLink}
            data-testing="viewUpcomingDeliveriesCTA"
          >
            View deliveries
          </Link>
        )}
      </div>
      <Card>
        {isTestOrder ? <TestOrderHeader /> : null}
        <OrderDetails
          deliveryDate={deliveryDate}
          orderState={orderClientState}
          price={price}
          bestAvailablePrice={bestAvailableBoxPrice}
          recipeImages={recipeImages.toJS()}
          maxRecipesCount={maxNumRecipes}
        />
        <div className={css.ctasWrapper}>
          {boxTrackingUrls.length > 0 && (
            <div className={css.trackingCtasWrapper}>{trackingCTAs}</div>
          )}
          <div className={css.cta}>
            <Link to={CTALink} clientRouted={false}>
              <CTA
                isFullWidth
                onClick={onClick}
                size="small"
                variant={boxTrackingUrls.length > 0 || !hasDeliveryToday ? 'secondary' : 'primary'}
                testingSelector="myGoustoNextBoxHelpCTA"
              >
                {CTALabel}
              </CTA>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  )
}

NextOrder.propTypes = {
  boxTrackingUrls: PropTypes.arrayOf(PropTypes.string),
  hasDeliveryToday: PropTypes.bool.isRequired,
  order: myGoustoOrderPropType.isRequired,
  trackNextBoxTrackingClick: PropTypes.func.isRequired,
  trackClickGetHelpWithThisBox: PropTypes.func.isRequired,
  maxNumRecipes: PropTypes.number,
}

NextOrder.defaultProps = {
  boxTrackingUrls: [],
  maxNumRecipes: 4,
}

export { NextOrder }
