import React from 'react'

const testOrderHeaderStyle = {
  color: '#FFFFFF',
  'align-self': 'center',
  'padding-right': '5px',
  margin: 0,
  'font-size': '30px',
  'font-weight': 'bold',
  display: 'flex',
  'justify-content': 'center',
  'background-color': '#615CFF',
  width: '100%',
  'z-index': 3,
  padding: '10px 30px',
  'flex-direction': 'row',
  'text-align': 'center',
}
export const TestOrderHeader = () => <div style={testOrderHeaderStyle}>Test Order</div>
