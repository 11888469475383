import moment from 'moment'
import { isServer } from '@library/environment'
import logger from './logger'
import { cookieString } from './cookieString'
import { canUseWindow } from './browserEnvironment'

const versionPrefix = 'v1'

function getPrefixedKey(key) {
  return `${versionPrefix}_${key}`
}

export function encode(val) {
  let ret

  if (canUseWindow()) {
    ret = val ? JSON.stringify(val) : ''
  }

  if (isServer()) {
    ret = val ? encodeURIComponent(JSON.stringify(val)) : ''
  }

  return ret
}

export function decode(val) {
  let ret

  if (isServer()) {
    ret = val ? JSON.parse(decodeURIComponent(val)) : undefined
  }

  if (canUseWindow()) {
    ret = val ? JSON.parse(val) : undefined
  }

  return ret
}

export function get(cookies, key, withVersionPrefix = true, shouldDecode = true) {
  const fullKey = withVersionPrefix ? getPrefixedKey(key) : key

  if (cookies && typeof cookies.get === 'function') {
    try {
      if (key === 'oauth_token' || key === 'oauth_refresh') {
        if (cookies.get(fullKey) === 'deleted') {
          return null
        }
      }

      const cookieValue = cookies.get(fullKey)

      return shouldDecode ? decode(cookieValue) : cookieValue
    } catch (err) {
      logger.notice(`un-parsable cookie value for key: ${key}, value: ${cookies.get(key)}`)
      logger.critical({ message: `un-parsable cookie value for key: ${key}`, errors: [err] })
    }
  }

  return undefined
}

export function set(cookies, key, val, days, withVersionPrefix = true, httpOnly = false, overwrite = false, path = '/', sameSite = 'Lax', domain) {
  const prefixedKey = withVersionPrefix ? getPrefixedKey(key) : key

  if (cookies && typeof cookies.set === 'function') {
    if (days) {
      const expires = moment().add(days * 24, 'hours')
      cookies.set(prefixedKey, encode(val), { expires: expires.toDate(), httpOnly, overwrite, path, sameSite, domain })
    } else {
      cookies.set(prefixedKey, encode(val), { httpOnly, overwrite, path, sameSite, domain })
    }
  } else {
    logger.error({ message: 'no cookies to set on' })
  }
}

export function unset(cookies, key, withVersionPrefix = true, path = '/', domain) {
  const prefixedKey = withVersionPrefix ? getPrefixedKey(key) : key

  if (cookies && typeof cookies.set === 'function') {
    cookies.set(prefixedKey, null, { expires: new Date('1970-01-01'), httpOnly: false, path, domain })
  } else {
    logger.error({ message: 'no cookies to delete on' })
  }
}

export function getNamesWithPrefix(reqCookies, prefix) {
  const cookies = cookieString(reqCookies)
  let cookieNames = []
  if (cookies) {
    const match = RegExp('^\\s*' + getPrefixedKey('') + prefix + '(.*?)=\\s*(.*?)\\s*$') //eslint-disable-line

    let index
    const matches = []
    for (index = 0; index < cookies.length; index++) {
      const entry = cookies[index].match(match)
      if (entry !== null) {
        matches.push(entry[1])
      }
    }

    cookieNames = matches.map(cookieName => `${prefix}${cookieName}`)
  }

  return cookieNames
}
