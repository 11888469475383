export const cookieCategories = [
  {
    category: 'Strictly Necessary',
    description:
      "These cookies are essential for the Gousto website to work properly and can't be switched off.",
  },
  {
    category: 'Functionality',
    description:
      "These cookies aren't essential and can be switched off but doing so may impact your experience.",
  },
  {
    category: 'Analytics',
    description:
      "These cookies aren't essential and can be switched off but they do help to improve your experience. We occasionally use trusted third party cookies to achieve this. The data is collected in a way that does not directly identify anyone.",
  },
  {
    category: 'Marketing',
    description:
      "These cookies aren't essential and can be switched off but the marketing content you receive will not be personalised. We occasionally use trusted third party cookies to achieve this.",
  },
]

export const cookieUsageDetails = {
  colHeaders: ['Purpose', 'Cookie Category', 'Examples'],
  rows: [
    [
      'User input',
      'Strictly necessary',
      'Information about your current order in the basket (such as delivery date, address, number of portions, promo code, subscription option etc.)',
    ],
    [
      'Authentication',
      'Strictly necessary',
      'To authenticate the user has logged into the website.',
    ],
    [
      'Security and Payments',
      'Strictly necessary',
      'To detect repeated failed login attempts or prevent fraud.',
    ],
    [
      'Network management',
      'Strictly necessary',
      'To make sure our services remain available and functional.',
    ],
    [
      'Cookie consent settings',
      'Strictly necessary',
      'To store your cookie preferences over a period of time.',
    ],
    ['Social media plugins', 'Marketing', 'To allow you to access social media platforms.'],
    [
      'Personalisation',
      'Functionality',
      "To show you relevant content on the site, for example remembering what tutorials you've already watched",
    ],
    [
      'Show third party content on site',
      'Functionality',
      'For example, to show you up-to-date and relevant information from third parties, such as our trustpilot score and reviews',
    ],
    [
      'Social media tracking',
      'Marketing and Analytics',
      'To allow us to provide you with targeted marketing on social media.',
    ],
    [
      'Online advertising',
      'Marketing',
      'To allow us to provide you with targeted marketing when you are on non-Gousto platforms.',
    ],
    [
      'User behaviour',
      'Analytics',
      'To provide us with information about how visitors of our services engage with us.',
    ],
  ],
}
