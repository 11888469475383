import * as React from 'react'

import { useDispatch } from 'react-redux'

import { trackUserAddRemoveRecipe } from 'actions/loggingmanager'

import { useAddValidRecipeToBasket } from './useAddValidRecipeToBasket'

export type AddRecipeFn = ReturnType<typeof useAddRecipe>
export const useAddRecipe = () => {
  const addValidRecipeToBasket = useAddValidRecipeToBasket()
  const dispatch = useDispatch()

  return React.useCallback(
    (
      recipeId: string,
      view?: string,
      recipeInfo?: {
        position: string
      },
      maxRecipesNum?: number,
      orderId?: string,
      isOrderRecipe?: boolean,
    ) => {
      addValidRecipeToBasket(recipeId, view, recipeInfo, maxRecipesNum, orderId, isOrderRecipe)
      dispatch(trackUserAddRemoveRecipe())
    },
    [dispatch, addValidRecipeToBasket],
  )
}
