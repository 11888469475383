module.exports = {
  head: {
    title: 'Privacy Statement and Cookies Policy - Gousto',
    meta: [
      {
        name: 'description',
        content: 'Read the Privacy Statement and Cookies Policy for Gousto',
      },
      {
        name: 'keywords',
        content: 'Gousto, recipe delivery, ingredients, fresh, healthy food, cooking',
      },
    ],
  },
}
