import { actionTypes } from 'actions/actionTypes'
import { redirect } from 'actions/redirect'
import routes from 'config/routes'
import { checkoutCreatePreviewOrder } from 'routes/Checkout/checkoutActions'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { orderAssignToUser } from 'routes/Menu/actions/order'
import { getIsAuthenticated } from 'selectors/auth'
import { getPreviewOrderId } from 'selectors/basket'
import { getUserStatus } from 'selectors/user'
import logger from 'utils/logger'
import { getPreviewOrderErrorName } from 'utils/order'

export const checkoutTransactionalOrder: CheckoutActionCreator =
  (orderAction: string) => async (dispatch, getState) => {
    await dispatch(checkoutCreatePreviewOrder())

    const state = getState()
    const { error } = state
    const previewOrderError = error.get(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, false)
    const previewOrderErrorName = getPreviewOrderErrorName(error)
    const orderId = getPreviewOrderId(state)
    const userStatus = getUserStatus(state)
    const isAuthenticated = getIsAuthenticated(state)

    if (previewOrderError || !orderId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning(
        `Preview order id failed to create, persistent basket might be expired, error: ${previewOrderErrorName}`,
      )

      dispatch(
        redirect(`${routes.client.menu}?from=newcheckout&error=${previewOrderErrorName}`, true),
      )

      return
    }

    if (!isAuthenticated) {
      return
    }

    if (userStatus === 'onhold') {
      dispatch(redirect(`${routes.client.myGousto}`))
    } else {
      dispatch(orderAssignToUser(orderAction, orderId))
    }
  }
