import React from 'react'
import {
  AlignItems,
  Body2,
  Box,
  FlexDirection,
  Heading5,
  JustifyContent,
  TextAlign,
} from '@gousto-internal/citrus-react'
import { StarComplete } from './StarComplete'

export function RatingsComplete() {
  return (
    <Box
      display="flex"
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      flexDirection={FlexDirection.Column}
      paddingV={14}
      data-testid="ratings-complete"
    >
      <StarComplete />
      <Heading5 style={{ padding: '1.75rem 0 1rem 0' }}>No recipes to rate</Heading5>
      <Body2 textAlign={TextAlign.Center}>
        Once you’ve tucked into your next box, head back here to rate your recipes.
      </Body2>
    </Box>
  )
}
