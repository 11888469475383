import Immutable from 'immutable'
import { createSelector } from 'reselect'

import { StateTypePlaceholder } from './types'

const getBasketRecipes = ({ basket }: StateTypePlaceholder) => basket.get('recipes')
export const getBasketRecipesSelector = createSelector(getBasketRecipes, (recipes) => ({
  recipes,
  recipesIds: (recipes as Immutable.Map<string, number>).keySeq().toArray(),
}))

const getStoreRecipes = ({ menuBoxPrices, recipes }: StateTypePlaceholder) => ({
  menuBoxPrices,
  menuRecipesStore: recipes,
})
export const getStoreRecipesSelector = createSelector(
  getStoreRecipes,
  ({ menuBoxPrices, menuRecipesStore }) => ({
    menuBoxPrices,
    menuRecipesStore,
  }),
)
