import type { Order, RecipeItem } from '@library/api-user'

export type OrderDiscountPrice = {
  priceWithDiscount: string | null
  priceWithoutDiscount: string
}

const orderPhaseToHumanReadable = {
  cancelled: 'cancelled',
  awaiting_choices: 'choose',
  open: 'edit',
  cutoff: 'confirmed',
  packing: 'confirmed',
  picking: 'confirmed',
  delivery: 'dispatched',
  delivered: 'delivered',
  pre_menu: 'preMenu',
}

export function orderModel() {
  return {
    areRecipesChosenForPedingOrder: (order: Order): boolean => {
      return order.state === 'pending' && order.recipeItems.length > 0
    },
    isOrderDelivered: (order: Order): boolean => {
      return order.phase === 'delivered'
    },
    isOrderPending: (order: Order): boolean => {
      return order.state === 'pending'
    },
    isOrderAwaitingChoices: (order: Order): boolean => {
      return order.phase === 'awaiting_choices'
    },
    isOrderEditable: (order: Order): boolean => {
      return order.phase === 'awaiting_choices' || order.phase === 'open'
    },
    getDeliveryDate: (order: Order) => {
      return order.deliveryDate
    },
    getPriceForOrder: (order: Order): OrderDiscountPrice => {
      return parseFloat(order.prices.totalDiscount)
        ? { priceWithDiscount: order.prices.total, priceWithoutDiscount: order.prices.grossTotal }
        : { priceWithDiscount: null, priceWithoutDiscount: order.prices.total }
    },
    getDiscountForOrder: (order: Order) => {
      const percentageOff = parseFloat(order.prices.percentageOff)

      return percentageOff ? `${percentageOff}%` : null
    },
    getRecipes: (order: Order) => {
      return order.recipeItems
    },
    getProducts: (order: Order) => {
      return order.productItems
    },
    getHumanDeliveryDate: (order: Order) => {
      return order.humanDeliveryDate
    },
    getHumanDeliveryDayTime: (order: Order) => {
      return order.deliverySlot.humanDeliveryDayTime
    },
    getShouldCutOffAt: (order: Order) => {
      return order.shouldCutoffAt
    },
    getOrderPhase: (order: Order) => {
      if (order.state === 'cancelled') {
        return 'cancelled'
      }

      return orderPhaseToHumanReadable[order.phase as keyof typeof orderPhaseToHumanReadable]
    },
    getOrderRecipeImageURLs: (recipeItems: RecipeItem[], width: string) => {
      return recipeItems
        .flatMap((recipeItem) => recipeItem.media)
        .filter((media) => media.type === 'mood-image')
        .flatMap((media) => media.urls)
        .filter((url) => url.width === width)
        .map((url) => url.src)
    },
    getOrderShippingAddress: (order: Order) => {
      return {
        line1: order.shippingAddress.line1,
        town: order.shippingAddress.town,
        postcode: order.shippingAddress.postcode,
      }
    },
    getOrderNumberOfPortions: (order: Order) => {
      return order.box.numPortions
    },
    getOrderNumberOfRecipes: (order: Order) => {
      return order.box.numRecipes
    },
    getOrderPeriodId: (order: Order) => {
      return order.period.id
    },
    getOrderId: (order: Order) => {
      return order.id
    },
    getOrderState: (order: Order) => {
      return order.state
    },
  }
}
