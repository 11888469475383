import { useSelector } from 'react-redux'

import { getMaxNumberOfRecipes } from 'routes/Menu/selectors/menuService'
import { getNumPortions } from 'selectors/basket'

export const useMaxNumberOfRecipes = () => {
  const numPortions = useSelector(getNumPortions)
  const maxNumRecipes = useSelector((state) => getMaxNumberOfRecipes(state, numPortions))

  return maxNumRecipes
}
