import React, { memo } from 'react'

import {
  AspectRatio,
  BorderStyle,
  Box,
  Color,
  GridDisplay,
  ImageProps,
  Image,
  Display,
  Visible,
} from '@gousto-internal/citrus-react'

import { useGetBasketRecipes } from '../hooks'

function EmptyRecipeImage() {
  return (
    <AspectRatio ratio={1 / 1} height="80px" data-testid="recipe-image-placeholder">
      <Box borderStyle={BorderStyle.Dashed} borderColor={Color.ColdGrey_800} borderWidth={0.5} />
    </AspectRatio>
  )
}

function RecipeImage({ src, alt }: ImageProps) {
  return (
    <AspectRatio ratio={1 / 1} height="80px">
      <Image src={src} alt={alt} />
    </AspectRatio>
  )
}

export const YourGoustoBox = memo(function YourGoustoBox() {
  const recipeData = useGetBasketRecipes()
  const numRecipes = recipeData?.length

  const recipeImages = recipeData.map(({ url, title }, idx) =>
    url ? (
      <RecipeImage key={url} src={url} alt={title} />
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <EmptyRecipeImage key={`empty-recipe-image-${idx}`} />
    ),
  )

  return (
    <Visible display={[Display.Block, Display.None]}>
      <Box
        width="100%"
        display={GridDisplay.Grid}
        gridTemplateColumns={`repeat(${numRecipes}, 1fr)`}
      >
        {recipeImages}
      </Box>
    </Visible>
  )
})
