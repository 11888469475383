import React, { useEffect, useState } from 'react'

import { Button, Color, Spinner } from '@gousto-internal/citrus-react'
import classnames from 'classnames'
import usePrevious from 'react-use/lib/usePrevious'

import css from './OrderSummary.css'

const useSafePrevious = (value: boolean) => {
  const prevValue = usePrevious(value)

  return prevValue === undefined ? value : prevValue
}

export const useSaving = (saveRequired: boolean, saving: boolean, error: boolean) => {
  const [showButton, setShowButton] = useState(saveRequired)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const prevSaving = useSafePrevious(saving)
  const prevSaveRequired = useSafePrevious(saveRequired)

  useEffect(() => {
    if (!prevSaveRequired && saveRequired) {
      setShowButton(true)
    }
  }, [saveRequired, prevSaveRequired])

  useEffect(() => {
    if (prevSaving === saving) {
      return
    }

    if (saving) {
      return
    }

    setShowButton(false)

    if (error) {
      setShowError(true)
      setTimeout(() => setShowError(false), 5000)
    } else {
      setShowSuccess(true)
      setTimeout(() => setShowSuccess(false), 5000)
    }
  }, [saving, prevSaving, error])

  return { showButton, showSuccess, showError }
}

type SaveButtonProps = {
  saveRequired: boolean
  onClick: () => void
  saving?: boolean
  onOrderConfirmationMobile?: boolean
  error?: boolean
}

export const SaveButton = ({
  saveRequired,
  onClick,
  saving = false,
  onOrderConfirmationMobile = false,
  error = false,
}: SaveButtonProps) => {
  const { showButton, showSuccess, showError } = useSaving(saveRequired, saving, error)

  return (
    <div className={classnames({ [css.updateOrderButton]: onOrderConfirmationMobile })}>
      {showButton && (
        <div className={css.button}>
          <Button data-testing="updateOrder" disabled={saving} onClick={onClick} width="100%">
            {saving ? <Spinner color={Color.White} size={6} /> : 'Update Order'}
          </Button>
        </div>
      )}
      {showSuccess && !showButton ? <div className={css.success}>SAVED</div> : null}
      {showError && !showButton ? <div className={css.error}>ERROR SAVING CHOICES</div> : null}
    </div>
  )
}
