import React from 'react'

import {
  Box,
  Icon,
  IconNames,
  Text,
  FontWeight,
  Display,
  Space,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { RibbonTriggerContainer } from 'components/RibbonTrigger'
import { CheckoutButton } from 'routes/Checkout/Components/CheckoutButton'
import { InformationalPageTemplate } from 'routes/Signup/Components/InformationalPageTemplate'
import { signupGoToMenu } from 'routes/Signup/signupActions'

import css from './SellThePropositionPage.css'

type itemsType = {
  key: string
  iconName: IconNames
  className: string
  strongText: string
  normalText: string
}[]
// remove className when PersonaliseMenuStep component is removed
export const items: itemsType = [
  {
    key: 'itemValue',
    iconName: 'offer_pound',
    className: css.itemValue,
    strongText: 'Greater value',
    normalText: 'the more recipes you add',
  },
  {
    key: 'itemRecipes',
    className: css.itemRecipes,
    iconName: 'meals_per_box',
    strongText: 'Over 75 recipes',
    normalText: 'changing weekly',
  },
  {
    key: 'itemSkip',
    className: css.itemSkip,
    iconName: 'skip',
    strongText: 'Choose 2 to 5 recipes',
    normalText: 'or skip a box',
  },
  {
    key: 'itemDietary',
    className: css.itemDietary,
    iconName: 'dietary_preference',
    strongText: 'Meals for every appetite',
    normalText: 'and dietary need',
  },
]

export const SellThePropositionPage = () => {
  const dispatch = useDispatch()
  const ctaText = "See this week's menu"

  const renderNonStickyCTA = () => (
    <CheckoutButton
      onClick={() => dispatch(signupGoToMenu())}
      testingSelector="sellThePropositionNonStickyCTA"
    >
      {ctaText}
    </CheckoutButton>
  )

  return (
    <InformationalPageTemplate
      testingSelector="sellThePropositionPage"
      headerText="Get your taste buds ready..."
    >
      <Box>
        {items.map(({ key, iconName, strongText, normalText }) => (
          <Box key={key} display={Display.Flex} paddingBottom={6}>
            <Icon name={iconName} />
            <Space size={2} direction="horizontal" />
            <Text size={2}>
              <Text size={2} fontWeight={FontWeight.Bold} display={Display.Inline}>
                {strongText}&nbsp;
              </Text>
              {normalText}
            </Text>
          </Box>
        ))}
      </Box>
      {renderNonStickyCTA()}
      <RibbonTriggerContainer name="wizard-proposition" />
    </InformationalPageTemplate>
  )
}
