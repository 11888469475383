import React from 'react'
import useSWR from 'swr'
import {
  Box,
  Display,
  Heading6,
  Space,
  Icon,
  JustifyContent,
  Color,
  FontWeight,
  Text,
} from '@gousto-internal/citrus-react'
import { fetchUserBalance } from '@library/api-user'

import { ApiKeys } from '../../enums'

const getBalanceTextColor = (balance: number): Color => {
  if (balance > 0) {
    return Color.Success_700
  }

  if (balance < 0) {
    return Color.Error_700
  }

  return Color.ColdGrey_800
}

const formatAmount = (number: number) =>
  new Intl.NumberFormat('en-GB', {
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
  }).format(number)

function AccountBalanceSection() {
  const { data } = useSWR(ApiKeys.UserCurrentBalance, fetchUserBalance)
  const balance = Number(data?.balance) || 0
  const balanceTextColor = getBalanceTextColor(balance)

  return (
    <>
      <Text>Any credit will be taken off your next purchase</Text>
      <Space size={6} direction="vertical" />
      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Box display={Display.Flex}>
          <Icon name="offer_pound" size={6} />
          <Space size={4} direction="horizontal" />
          <Heading6 size={1} color={Color.ColdGrey_800} fontWeight={FontWeight.Bold}>
            Total balance
          </Heading6>
        </Box>
        <Box>
          <Text color={balanceTextColor} fontWeight={FontWeight.Bold}>
            {formatAmount(balance)}
          </Text>
        </Box>
      </Box>
    </>
  )
}

export { AccountBalanceSection }
