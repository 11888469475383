import { RootStateOrAny, connect } from 'react-redux'

import { ErrorPage } from './ErrorPage'

const mapStateToProps = (state: RootStateOrAny) => ({
  status: state.serverError,
})

const ErrorPageContainer = connect(mapStateToProps, {})(ErrorPage)

export { ErrorPageContainer }
