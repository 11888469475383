import { useState, useEffect, useCallback } from 'react'

import { client } from 'config/routes'
import { redirect } from 'utils/window'

export const useZendeskChat = (handleEndChat: () => void) => {
  const [isChatAvailable, setIsChatAvailable] = useState<boolean>(false)

  const handleChat = () => {
    if (!isChatAvailable) {
      redirect(client.helpCentre)

      return
    }

    window.$zopim.livechat.window.show()
  }

  const onChatEnd = useCallback(() => {
    handleEndChat()

    window.$zopim.livechat.clearAll()
    window.$zopim.livechat.hideAll()
  }, [handleEndChat])

  useEffect(() => {
    if (isChatAvailable) {
      window.$zopim.livechat.setOnChatEnd(onChatEnd)
    }

    return () => {
      if (isChatAvailable) {
        window.$zopim.livechat.setOnChatEnd(null)
        window.$zopim.livechat.clearAll()
        window.$zopim.livechat.hideAll()
      }
    }
  }, [isChatAvailable, onChatEnd])

  useEffect(() => {
    if (window && window.$zopim) {
      setIsChatAvailable(true)
    }
  }, [])

  return {
    handleChat,
  }
}
