import { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

export const RECAPTCHA_IFRAME_URL_PART = 'google.com/recaptcha/api2/bframe'

export type NullableReCAPTCHA = ReCAPTCHA | null

const getChallengeIframeContainer = (): HTMLDivElement | null => {
  const docFrames = document.getElementsByTagName('iframe')
  const docFramesArray = Array.from(docFrames)
  const challengeFrame = docFramesArray.find((frame) =>
    frame.src.includes(RECAPTCHA_IFRAME_URL_PART),
  )

  if (
    !challengeFrame ||
    !challengeFrame.parentElement ||
    !challengeFrame.parentElement.parentElement ||
    challengeFrame.parentElement.parentElement.tagName !== 'DIV'
  ) {
    return null
  }

  return challengeFrame.parentElement.parentElement as HTMLDivElement
}

const getObserverForChallengeClosing = (onChallengeClose: () => void): MutationObserver => {
  const container = getChallengeIframeContainer()

  const observer = new MutationObserver(() => {
    if (!container) return

    const opacity = parseInt(container.style.opacity, 10)

    if (opacity === 0) {
      observer.disconnect()
      onChallengeClose()
    }
  })

  if (container) {
    observer.observe(container, {
      attributes: true,
      attributeFilter: ['style'],
    })
  }

  return observer
}

export const useChallengeClosingObserver = (
  ref: ForwardedRef<NullableReCAPTCHA>,
  isScriptLoaded: boolean,
  onChallengeClose?: () => void,
) => {
  const internalRef = useRef<NullableReCAPTCHA>(null)
  const [hasRefModified, setHasRefModified] = useState<boolean>(false)
  const [observer, setObserver] = useState<MutationObserver>()

  useEffect(() => () => observer?.disconnect(), [observer])

  useImperativeHandle<NullableReCAPTCHA, NullableReCAPTCHA>(
    ref,
    () => {
      if (!internalRef.current || !isScriptLoaded || !onChallengeClose || hasRefModified) {
        return internalRef.current
      }

      const originalExecute = internalRef.current.execute.bind(internalRef.current)

      internalRef.current.execute = () => {
        setObserver(getObserverForChallengeClosing(onChallengeClose))
        originalExecute()
      }

      setHasRefModified(true)

      return internalRef.current
    },
    [isScriptLoaded, onChallengeClose, hasRefModified],
  )

  return internalRef
}
