import { createSelector } from 'reselect'

export const getDeliveries = ({ slots }) => (slots || {})

export const getAreDeliveriesLoaded = createSelector(
  getDeliveries,
  (slots) => Boolean(Object.keys(slots).length)
)

export const getCurrentDeliverySlot = createSelector(
  getDeliveries,
  ({ currentDeliverySlot }) => currentDeliverySlot || {}
)

export const getDeliverySlots = createSelector(
  getDeliveries,
  ({ data }) => data || []
)

export const getDeliveryFrequency = createSelector(
  getDeliveries,
  ({ frequency }) => (frequency || {}).currentValue
)
