import actions from 'actions'

import { trackCheckoutButtonPressed } from 'routes/Checkout/checkoutActions'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const openLoginModal: CheckoutActionCreator =
  (e?: MouseEvent, isMobileView?: boolean) => (dispatch) => {
    e?.stopPropagation()

    dispatch(actions.loginVisibilityChange(true))

    if (isMobileView) {
      dispatch(trackCheckoutButtonPressed('LogInCTA Clicked'))
    }
  }
