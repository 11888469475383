module.exports = {
  telephone: {
    number: '020 3011 1002',
    link: '+442030111002',
  },
  addressLine1: 'Unit 2 Issigonis House',
  addressLine2: 'London',
  postcode: 'W3 7UN',
  email: 'info@gousto.co.uk',
}
