import { useCallback } from 'react'

import { RootStateOrAny, useSelector } from 'react-redux'

import { getMenuService } from 'selectors/root'
import { locationQuery } from 'selectors/routing'

type Recipe = {
  core_recipe_id: string
  meta: {
    portion_sizes_available: number[]
  }
}

type MenuService = {
  data: {
    relationships: {
      recipes: {
        data: Recipe[]
      }
    }
  }[]
}

export function useStockAPI({
  numPortions,
  isRecipeInBasket,
}: {
  numPortions: number
  isRecipeInBasket: (recipeId: string) => boolean
}) {
  const query = useSelector(locationQuery)
  const isAdmin = query && query['preview[menu_id]']
  const menuService = useSelector<RootStateOrAny, MenuService>(getMenuService)

  const isRecipeOutOfStock = useCallback(
    (recipeId: string) => {
      if (!menuService?.data?.[0].relationships.recipes.data) {
        // We choose to be optimistic and assume recipe is in stock
        // while the menu data is still loading
        return false
      }

      const menuRecipe = menuService?.data?.[0].relationships.recipes.data.find(
        (recipe) => recipe.core_recipe_id === recipeId,
      )
      const isRecipeAvailable = menuRecipe?.meta.portion_sizes_available?.includes(numPortions)

      return !isRecipeAvailable && !isRecipeInBasket(recipeId) && !isAdmin
    },
    [isAdmin, isRecipeInBasket, menuService?.data, numPortions],
  )

  const getOutOfStockRecipeIds = useCallback(
    (recipeIds: string[]) => recipeIds.filter(isRecipeOutOfStock),
    [isRecipeOutOfStock],
  )

  return {
    getOutOfStockRecipeIds,
    isRecipeOutOfStock,
  }
}
