import { client } from 'config/routes'
import { useAuth } from 'routes/Menu/domains/auth'
import { useShouldShowDeliveryDetailsPage } from 'routes/Menu/hooks/useShouldShowDeliveryDetailsPage'

import { CtaTexts } from './data'

/**
 * Used to get cta text & uri based on condition
 * if user logged in or not
 */

export const useGetCtaProps = ({ withDiscount = false }: { withDiscount?: boolean } = {}) => {
  const { isAuthenticated } = useAuth()
  const { menu, signup, deliveryDetails } = client
  const shouldShowDeliveryDetailsPage = useShouldShowDeliveryDetailsPage()

  const dynamicCtaText = () =>
    withDiscount ? CtaTexts.GET_STARTED_WITH_DISCOUNT : CtaTexts.GET_STARTED

  const ctaText = dynamicCtaText()

  const ctaURI = signup

  if (!isAuthenticated) {
    return {
      ctaUri: ctaURI,
      ctaText,
    }
  }

  return {
    ctaUri: shouldShowDeliveryDetailsPage ? deliveryDetails : menu,
    ctaText: CtaTexts.OPEN_MENU,
  }
}
