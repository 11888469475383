const HIDE_CHOOSE_RECIPES_CTA = 'hideChooseRecipesCta'
const HIDE_RECIPE_LIST = 'hideRecipeList'
const HIDE_PORTIONS = 'hidePortions'
const HIDE_PROMO_CODE_TEXT = 'hidePromoCodeText'
const HIDE_DELIVERY_COPY = 'hideDeliveryCopy'

export {
  HIDE_CHOOSE_RECIPES_CTA,
  HIDE_RECIPE_LIST,
  HIDE_PORTIONS,
  HIDE_PROMO_CODE_TEXT,
  HIDE_DELIVERY_COPY,
}
