import React from 'react'

import { Space } from '@gousto-internal/citrus-react'
import moment from 'moment'
import PropTypes from 'prop-types'

import OrderSummary from 'containers/welcome/OrderSummary'
import { capitalizeFirstLetter } from 'utils/text'

import { DiscountTag } from './DiscountTag/DiscountTag'
import { DiscountTagAsteriskNote } from './DiscountTagAsteriskNote/DiscountTagAsteriskNote'
import { EventDate } from './EventDate'
import { FreeDeliveryCard } from './FreeDeliveryCard/FreeDeliveryCard'

import css from './OrderSchedule.css'

const propTypes = {
  deliveryDate: PropTypes.string.isRequired,
  nameFirst: PropTypes.string,
  whenCutoff: PropTypes.string.isRequired,
  interval: PropTypes.string,
  numRecipes: PropTypes.number.isRequired,
  numPortions: PropTypes.number.isRequired,
  discountSofteningFreeDeliveryValue: PropTypes.string,
}

const defaultProps = {
  nameFirst: '',
  interval: 'weekly',
  discountSofteningFreeDeliveryValue: 'c',
}

const isValidDate = (date) => (date && date.length) || typeof date === 'object'

const OrderSchedule = ({
  deliveryDate,
  nameFirst,
  whenCutoff,
  interval,
  numRecipes,
  numPortions,
  discountSofteningFreeDeliveryValue,
}) => {
  const isValidDeliveryDate = isValidDate(deliveryDate)
  const isValidWhenCutoff = isValidDate(deliveryDate)

  const isFiveForFourOrder = numRecipes === 5 && numPortions === 4

  const isFreeDeliveryEnabled = ['t2', 't3'].includes(discountSofteningFreeDeliveryValue)
  const isNewDiscountUIEnabled = ['t1', 't3'].includes(discountSofteningFreeDeliveryValue)

  return (
    <div data-testing="orderScheduleContainer" className={css.container}>
      <div className={css.header}>
        <h2>{`Congrats on choosing your first recipes, ${capitalizeFirstLetter(nameFirst)}!`}</h2>
        {isFreeDeliveryEnabled ? (
          <>
            <FreeDeliveryCard />
            <Space size={[3, 2]} />
          </>
        ) : (
          <p className={css.headerSpacing}>
            We&apos;ll send you an email with tracking information when your first order is on its
            way.
          </p>
        )}
        <h2>What happens next?</h2>
      </div>
      <div className={css.scheduleItem}>
        <div>
          <EventDate date={isValidWhenCutoff && deliveryDate} />
          <div className={css.verticalLine} />
        </div>
        <div className={css.information}>
          <h3 className="first-order-header">Your 1st order</h3>
          <p className="first-order-text">
            Changes can be made up until{' '}
            <b>
              {isValidWhenCutoff ? moment(whenCutoff, 'YYYY-MM-DD').format('dddd Do MMMM') : ''}
            </b>
            {isFiveForFourOrder
              ? ". Heads up, your order could arrive in 1 or 2 boxes. We'll let you know when it's packed."
              : ''}
          </p>
          <div className={css.mobileShow}>
            <OrderSummary />
          </div>
        </div>
      </div>
      <div className={css.scheduleItem}>
        <div>
          <EventDate
            date={isValidDeliveryDate && moment(deliveryDate, 'YYYY-MM-DD').add(4, 'days')}
          />
          <div className={css.verticalLine} />
        </div>
        <div className={css.information}>
          <h3 className="second-order-header">Your 2nd order cut-off</h3>
          {isNewDiscountUIEnabled && (
            <>
              <DiscountTag />
              <Space size={1} />
            </>
          )}
          <p>
            Pick your recipes
            <b> before 12pm </b>
            on this date (don’t worry we&apos;ll remind you).
            <b> If you miss this we&apos;ll choose for you.</b>
          </p>
        </div>
      </div>
      <div className={css.scheduleItem}>
        <div>
          <EventDate
            date={isValidDeliveryDate && moment(deliveryDate, 'YYYY-MM-DD').add(7, 'days')}
          />
        </div>
        <div className={css.information}>
          <h3 className="second-order-delivery-header">Delivery of 2nd order</h3>
          <p className="second-order-delivery-text">
            Your
            <b>{` ${interval} `}</b>
            Gousto delivery will arrive every
            {` ${
              isValidDeliveryDate ? moment(deliveryDate, 'YYYY-MM-DD').format('dddd') : ''
            }. You'll get tracking information on the day.`}
          </p>
        </div>
      </div>
      {isNewDiscountUIEnabled && <DiscountTagAsteriskNote />}
    </div>
  )
}

OrderSchedule.propTypes = propTypes
OrderSchedule.defaultProps = defaultProps

export { OrderSchedule }
