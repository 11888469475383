import {
  AlignItems,
  Bold,
  Box,
  Display,
  Icon,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { HERO } from '../../../config'

export const Benefits = () => (
  <Box display={Display.Flex} justifyContent={[JustifyContent.Center, JustifyContent.FlexStart]}>
    <Box
      display={Display.Flex}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
    >
      <Icon name="play_pause" />
      <Space direction="horizontal" size={2} />
      <Text size={0}>
        <Bold>{HERO.subscriptionBenefitBold} </Bold>
        {HERO.subscriptionBenefit}
      </Text>
    </Box>
  </Box>
)
