import { menuRecipeFetcher } from '@library/menu-service'

import { menuRecipeMapper } from '../apis/transformers/recipes'
import { actionTypes } from './actionTypes'
import statusActions from './status'

const recipesLoadRecipesById =
  (recipeIds = []) =>
    async (dispatch, getState) => {
      const actionType = actionTypes.RECIPES_RECEIVE
      const filteredRecipeIds = recipeIds
        .filter((recipeId) => !getState().recipes.has(recipeId))
        .sort()

      if (!filteredRecipeIds.length) return

      dispatch(statusActions.pending(actionType, true))
      try {
        const params = {
          coreRecipeIds: filteredRecipeIds,
        }
        const { data: recipes } = await menuRecipeFetcher(params)
        dispatch({ type: actionType, recipes })
      } catch (err) {
        dispatch(statusActions.error(actionType, err.message))
      } finally {
        dispatch(statusActions.pending(actionType, false))
      }
    }

const recipesLoadFromMenuRecipesById =
  (recipeIds = []) =>
    async (dispatch, getState) => {
      const actionType = actionTypes.RECIPES_RECEIVE
      const filteredRecipeIds = recipeIds
        .filter((recipeId) => !getState().recipes.has(recipeId))
        .sort()

      if (!filteredRecipeIds.length) return

      dispatch(statusActions.pending(actionType, true))
      try {
        const params = {
          recipeIds: recipeIds || filteredRecipeIds,
        }
        const { data: recipes } = await menuRecipeFetcher(params)
        dispatch({ type: actionType, recipes: menuRecipeMapper(recipes) })
      } catch (err) {
        dispatch(statusActions.error(actionType, err.message))
      } finally {
        dispatch(statusActions.pending(actionType, false))
      }
    }

const recipesActions = {
  recipesLoadRecipesById,
  recipesLoadFromMenuRecipesById,
}

export default recipesActions
