import React from 'react'

import PropTypes from 'prop-types'
import { NavBar } from './NavBar'

import css from './Account.css'

const propTypes = {
  children: PropTypes.node,
  renderChildren: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  cardExpiryDate: PropTypes.string,
  userLoadData: PropTypes.func.isRequired,
  checkCardExpiry: PropTypes.func.isRequired,
  rateRecipeCount: PropTypes.number,
  userRecipeRatings: PropTypes.func.isRequired,
}

const defaultProps = {
  children: null,
  renderChildren: false,
  cardExpiryDate: '',
  rateRecipeCount: 0,
}

class Account extends React.PureComponent {
  componentDidMount() {
    const { checkCardExpiry, userLoadData, userRecipeRatings } = this.props

    Promise.all([userLoadData(), userRecipeRatings()]).then(() => checkCardExpiry())
  }

  render() {
    const { cardExpiryDate, renderChildren, children, location, rateRecipeCount } = this.props

    return (
      <div className={css.accountWrap}>
        <NavBar
          currentPath={location.pathname}
          cardExpiryDate={cardExpiryDate}
          rateRecipeCount={rateRecipeCount}
        />
        {!renderChildren ? children : <div />}
      </div>
    )
  }
}

Account.propTypes = propTypes
Account.defaultProps = defaultProps

export default Account
