import { actionTypes } from 'actions/actionTypes'
import { applePayStatusFetchError } from 'actions/trackingKeys'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { isApplePayAvailable } from 'routes/Checkout/utils/applePaySessionUtils'

/**
 * Fetches Apple Pay status - if browser supports Apple Pay and application can make payments via Apple Pay services.
 */
export const fetchApplePayStatus: CheckoutActionCreator = () => (dispatch, _getState) => {
  isApplePayAvailable()
    .then((isAvailable) => {
      dispatch({
        type: actionTypes.CHECKOUT_LOAD_APPLE_PAY_ENABLED_STATUS,
        payload: isAvailable,
      })
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.APPLE_PAY_STATUS_FETCH_ERROR,
        trackingData: {
          actionType: applePayStatusFetchError,
          error,
        },
      })
      dispatch({
        type: actionTypes.CHECKOUT_LOAD_APPLE_PAY_ENABLED_STATUS,
        payload: false,
      })
    })
}
