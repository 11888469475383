import React, { useState } from 'react'
import useSWR from 'swr'
import { fetchCurrentUser, resetUserPassword } from '@library/api-user'
import {
  Box,
  Button,
  ButtonColorVariant,
  ButtonSize,
  Color,
  Heading5,
  Icon,
  Body1,
  Text,
  IconVariant,
  Display,
  JustifyContent,
  AlignItems,
  Join,
  Space,
} from '@gousto-internal/citrus-react'

import { ApiKeys } from '../../enums'

interface ResetPasswordState {
  isSubmitted?: boolean
  isLoading?: boolean
  error?: string
}

function ResetPasswordSection() {
  const { data } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const [{ isSubmitted, isLoading, error }, setState] = useState<ResetPasswordState>({
    isSubmitted: false,
    isLoading: false,
  })
  const resetPasswordClickHandler = async () => {
    setState({ isLoading: true, error: undefined })

    try {
      await resetUserPassword()
      setState({ isLoading: false, isSubmitted: true })
    } catch (e: any) {
      setState({ isLoading: false, error: e.message })
    }
  }

  return (
    <Join with={<Space size={4} />}>
      <Heading5 data-testid="change-password-heading">Change password</Heading5>
      <Body1>
        {"Tap below to change your password. We'll send you an email with instructions."}
      </Body1>
      {isSubmitted ? (
        <Box
          display={Display.Flex}
          justifyContent={JustifyContent.FlexStart}
          alignItems={AlignItems.FlexStart}
          paddingBottom={1}
        >
          <Icon name="tick" variant={IconVariant.Confirmation} size={6} />
          <Box paddingH={2} data-testid="password-reset-success-message">
            <Text color={Color.Success_900}>Password reset email sent</Text>
            <Body1>
              An email with instructions on how to reset your password has been sent to{' '}
              {data?.user.email || 'your email address'}
            </Body1>
          </Box>
        </Box>
      ) : (
        <>
          <Button
            data-testid="password-reset-button"
            colorVariant={ButtonColorVariant.Secondary}
            size={ButtonSize.Medium}
            width="100%"
            disabled={isLoading}
            onClick={resetPasswordClickHandler}
          >
            Change password
          </Button>
          {error ? (
            <Box paddingV={1}>
              <Text color={Color.Error_900}>
                {"Sorry, we couldn't process your request right now. Please try again."}
              </Text>
            </Box>
          ) : null}
        </>
      )}
    </Join>
  )
}

export { ResetPasswordSection }
