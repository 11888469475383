import * as Immutable from 'immutable'

import {
  useLegacyCurrentMenuRecipes,
  useLegacyCurrentMenuVariants,
  useLegacyRecipes,
} from '../recipes'
import { formatRecipeTitle } from '../recipes/title'
import { useLegacyInStockRecipeIds } from '../stock'
import { Recipe } from '../types'
import { getFilteredVariants } from './getFilteredVariants'
import { getSurchargeForRecipe } from './surcharge'
import {
  GetAlternativeOptionsForRecipeArgs,
  RecipeAlternative,
  UseAlternativeOptionsArgs,
} from './types'

const compareCoreRecipeIds = (a: RecipeAlternative, b: RecipeAlternative) =>
  parseInt(a.coreRecipeId, 10) - parseInt(b.coreRecipeId, 10)

const filterAvailableRecipeVariants = (
  recipeVariantsArray: RecipeAlternative[],
  allRecipesAsMap: Immutable.Map<string, Recipe>,
  numPortions: number,
): RecipeAlternative[] => {
  return recipeVariantsArray.filter((variant: RecipeAlternative) => {
    const variantRecipe = allRecipesAsMap.getIn([variant.coreRecipeId]).toJS() as Recipe
    return variantRecipe?.portionSizesOffered?.includes(numPortions)
  })
}

export function useAlternativeOptions({
  allCollections,
  numPortions,
  menuId,
}: UseAlternativeOptionsArgs) {
  const recipesVariants = useLegacyCurrentMenuVariants(menuId)
  const recipes = useLegacyCurrentMenuRecipes()
  const allRecipesAsMap = useLegacyRecipes()
  const recipesInStockIds = useLegacyInStockRecipeIds({ numPortions })

  function getAlternativeOptionsForRecipe({
    recipeId,
    categoryId: collectionId,
  }: GetAlternativeOptionsForRecipeArgs) {
    if (!collectionId) {
      throw new Error(
        `Failed to obtain collectionId while determining Alternative Options for ${recipeId} recipe`,
      )
    }

    const dietaryClaims = allCollections
      ? allCollections.getIn([collectionId, 'requirements', 'dietary_claims'], null)
      : null

    const recipeAlternativeOptions = getFilteredVariants(
      recipesVariants,
      recipeId,
      recipes,
      dietaryClaims,
    )

    const recipeVariantsArray = recipeAlternativeOptions
      ? filterAvailableRecipeVariants(
          recipeAlternativeOptions.variantsList.toJS(),
          allRecipesAsMap,
          numPortions,
        )
      : []

    const selectedRecipe = recipeId ? recipes.find((r) => r?.get('id') === recipeId) : null

    const options = [
      ...(selectedRecipe
        ? [
            {
              displayName: formatRecipeTitle(selectedRecipe),
              coreRecipeId: selectedRecipe.get('coreRecipeId'),
            },
          ]
        : []),
      ...recipeVariantsArray,
    ].sort(compareCoreRecipeIds)

    return options.map(({ coreRecipeId, displayName }) => {
      const surcharge = getSurchargeForRecipe(allRecipesAsMap, coreRecipeId, numPortions)

      return {
        recipeId: coreRecipeId,
        recipeName: displayName,
        isChecked: String(recipeId) === String(coreRecipeId),
        isOutOfStock: !recipesInStockIds.has(coreRecipeId),
        surcharge,
      }
    })
  }

  return {
    getAlternativeOptionsForRecipe,
  }
}
