import React, { SyntheticEvent, useContext, useCallback } from 'react'
import {
  Box,
  Text,
  Color,
  FontWeight,
  Space,
  BorderStyle,
  Icon,
  Display,
  IconVariant,
  Link,
} from '@gousto-internal/citrus-react'
import useSWR from 'swr'
import { fetchCurrentUser } from '@library/api-user'
import { ApiKeys } from '../../enums'
import { AccountDetailsContext } from '../../AccountDetailsContext'
import { ActionType } from '../../interfaces'

function AccountOnHold() {
  const { data } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const { dispatch } = useContext(AccountDetailsContext)
  const onAccountOnHoldBannerClicked = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      dispatch({ type: ActionType.EXPAND_PAYMENT_INFO })
    },
    [dispatch],
  )

  return (
    <>
      {data?.user?.status === 'onhold' && (
        <Box /* Citrus: https://github.com/Gousto/citrus/issues/267 */
          bg={Color.Warning_50}
          borderColor={Color.Warning_100}
          borderWidth={1}
          borderStyle={BorderStyle.Solid}
          paddingH={4}
          paddingV={4}
          width="100%"
        >
          <Box display={Display.Flex}>
            <Icon name="warning" variant={IconVariant.Error} />
            <Space size={2} direction="horizontal" />
            <Box>
              <Text fontWeight={FontWeight.Bold}>Account on hold</Text>
              <Space size={2} direction="vertical" />
              <Text>
                Your account is currently on hold and your future orders have been cancelled. Please
                update your card details <Link onClick={onAccountOnHoldBannerClicked}>here</Link> to
                reactivate your account.
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export { AccountOnHold }
