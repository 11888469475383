import React from 'react'

import {
  Box,
  Color,
  Display,
  DisplayPosition,
  FlexDirection,
  Icon,
  IconVariant,
  Image,
  JustifyContent,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { incrementTutorialViewed, tutorialTracking } from 'actions/tutorial'
import { useAuth } from 'routes/Menu/domains/auth'
import { CollectionId, useCurrentCollectionId } from 'routes/Menu/domains/collections'

import caloriesEducationImage from 'media/photos/calories-education.jpg'

import css from './CaloriesTutorial.css'

const supportedCollectionIds = [CollectionId.AllRecipes, CollectionId.Recommendations]

export const CALORIES_TUTORIAL_NAME = 'recipe_tile_calories'

export const CaloriesTutorial = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()
  const currentCollectionId = useCurrentCollectionId()

  const hasDismissedTutorial = useSelector(({ tutorial }: RootStateOrAny) =>
    Boolean(tutorial && tutorial.getIn(['viewed', CALORIES_TUTORIAL_NAME])),
  )

  if (
    !isAuthenticated ||
    !supportedCollectionIds.includes(currentCollectionId ?? '') ||
    hasDismissedTutorial
  ) {
    return null
  }

  const onClose = () => {
    dispatch(incrementTutorialViewed(CALORIES_TUTORIAL_NAME))
    dispatch(tutorialTracking(CALORIES_TUTORIAL_NAME, 0, true))
  }

  return (
    <Box
      bg={Color.White}
      data-testid="calories-tutorial"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
    >
      <Box
        bg={Color.White}
        display={Display.Flex}
        onClick={onClose}
        onKeyDown={onClose}
        paddingH={1}
        paddingV={1}
        position={DisplayPosition.Absolute}
        right="12px"
        role="button"
        top="12px"
      >
        <Icon name="close" size={6} variant={IconVariant.Actionable}>
          Close calories education
        </Icon>
      </Box>
      <Box bg={Color.Secondary_50} display={Display.Flex} justifyContent={JustifyContent.Center}>
        <Box maxWidth="380px" minHeight="230px">
          <Image alt="Calories education" src={caloriesEducationImage} />
        </Box>
      </Box>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} paddingH={4} paddingV={6}>
        <Text fontWeight={700} size={9} textAlign={TextAlign.Center}>
          Not interested in calories?
        </Text>
        <Space size={2} />
        <Box display={Display.Flex} paddingH={[8, 8, 12, 12]}>
          <Text size={4} textAlign={TextAlign.Center}>
            We&apos;re now showing calories on recipes. If you want to hide them visit
            <Space direction="horizontal" size={1} />
            <Text display={Display.Inline} fontWeight={700} size={4}>
              Menu Settings
              <Space direction="horizontal" size={1} />
              <Icon
                className={css.menuPreferencesIcon}
                name="settings"
                size={5}
                variant={IconVariant.Default}
              >
                Menu settings
              </Icon>
            </Text>
            <Space direction="horizontal" size={1} />
            top right.
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
