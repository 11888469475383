import { createContext, useContext } from 'react'

import Immutable from 'immutable'

import { useBrandInfo } from '../domains/brand'
import { findTag } from '../selectors/recipe'

type Recipe = Immutable.Map<string, unknown>

/**
 *
 * A context to hold a single `Recipe` object
 *
 * To be used in e.g. RecipeTile and RecipeDetails
 *
 */
const RecipeContext = createContext<Recipe>(null as unknown as Recipe)
RecipeContext.displayName = 'RecipeContext'

export const RecipeContextProvider = RecipeContext.Provider
export const useRecipe = (): Recipe => useContext(RecipeContext)
export const useRecipeField = <TField = unknown>(
  fieldSelector: string | string[],
  notSetValue?: unknown,
): TField => {
  const recipe = useRecipe()

  if (!recipe) {
    return notSetValue as TField
  }

  if (Array.isArray(fieldSelector)) {
    return recipe.getIn(fieldSelector, notSetValue)
  }

  return recipe.get(fieldSelector, notSetValue) as TField
}

export const useRecipeId = (): string | null => useRecipeField<string | null>('id', null)

type BrandTag = {
  slug: string
  text: string
  theme: {
    name: string
    color: string
    borderColor: string
  }
}

export const useTag = (tagline: string | null): BrandTag | null => {
  const brandInfo = useBrandInfo()
  const brandTags = brandInfo.brand?.tags || []

  if (!tagline || !brandTags.length) {
    return null
  }

  return findTag(brandTags, tagline)
}
