import { datadogLogs } from '@datadog/browser-logs'
import * as braintree from 'braintree-web'

let braintreeClientInstance: braintree.Client | null = null

export async function getBraintreeClientInstance(authorization: string) {
  if (!braintreeClientInstance) {
    try {
      braintreeClientInstance = await braintree.client.create({ authorization })
    } catch (error) {
      datadogLogs.logger.error(`getBraintreeClientInstance ${error}`)
    }
  }

  return braintreeClientInstance
}
