import React from 'react'
import { Box, Color, FontWeight, Heading1, Space, Text } from '@gousto-internal/citrus-react'
import { HEADER_SECTION } from '../../config'

export function HeaderSection() {
  const { title, description, notice } = HEADER_SECTION

  return (
    <Box paddingRight={[0, 0, 0, 20]} maxWidth={['350px', '528px', '528px', '724px']}>
      <Heading1 size={[9, 10]}>{title}</Heading1>
      <Space size={[2, 4]} />
      <Text size={[2, 4]} fontWeight={FontWeight.Normal} color={Color.ColdGrey_800}>
        {description}
      </Text>
      <Space size={[2, 4]} />
      <Text size={[2, 4]} fontWeight={FontWeight.Bold} color={Color.ColdGrey_800}>
        {notice}
      </Text>
    </Box>
  )
}
