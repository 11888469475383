import Immutable from 'immutable'
import { Action } from 'redux'

import { actionTypes } from 'actions/actionTypes'

import { signupConfig } from './signupConfig'

export const initialState = Immutable.fromJS({
  wizard: {
    stepNames: null,
    currentStepName: '',
    currentStepNumber: 0,
    isLastStep: false,
    amountOfCustomers: null,
    district: null,
  },
  dietaryPreferences: Immutable.List([]),
  isDietaryConfirmed: false,
  isDiscountAppliedBarDismissed: false,
  isInWizardFunnel: false,
  isRecipeConfirmed: false,
  recipePreferences: Immutable.List([]),
})

const signupReducers = {
  signup: (state = initialState, action: SignupAction) => {
    switch (action.type) {
      case actionTypes.SIGNUP_STEPS_RECEIVE: {
        return state.setIn(['wizard', 'stepNames'], Immutable.fromJS(action.stepNames))
      }

      case actionTypes.SIGNUP_STEP_SET: {
        return state
          .setIn(['wizard', 'currentStepName'], action.currentStepName)
          .setIn(['wizard', 'currentStepNumber'], action.currentStepNumber)
          .setIn(['wizard', 'isLastStep'], action.isLastStep)
      }

      case actionTypes.SIGNUP_DISMISS_DISCOUNT_APPLIED_BAR: {
        return state.set('isDiscountAppliedBarDismissed', true)
      }

      case actionTypes.SIGNUP_SET_SOCIAL_BELONGING_OPTIONS: {
        return state
          .setIn(['wizard', 'amountOfCustomers'], action.count || null)
          .setIn(['wizard', 'district'], action.district || null)
      }

      case actionTypes.SIGNUP_SHOW_DISCOUNT_APPLIED_BAR: {
        return state.set('isDiscountAppliedBarDismissed', false)
      }

      case actionTypes.WIZARD_SEEN: {
        return state.set('isInWizardFunnel', true)
      }

      case actionTypes.PAGE_CHANGED: {
        const funnelPaths = ['/menu', '/signup', '/check-out']
        const isInFunnelPage = funnelPaths.some((path) => action.newLocation?.includes(path))

        const isWizardSeen = state.get('isInWizardFunnel')

        return state.set('isInWizardFunnel', isInFunnelPage && isWizardSeen)
      }

      case actionTypes.SIGNUP_SET_IS_DIETARY_CONFIRMED: {
        if (typeof action.isDietaryConfirmed === 'boolean') {
          return state.set('isDietaryConfirmed', action.isDietaryConfirmed)
        }

        return state
      }

      case actionTypes.SIGNUP_SET_DIETARY_PREFERENCES: {
        const dietaryPreferences =
          action?.dietaryPreferences?.filter((preference) =>
            signupConfig.dietaryPreferences.includes(preference),
          ) || []

        return state.set('dietaryPreferences', Immutable.List(dietaryPreferences))
      }

      case actionTypes.SIGNUP_SET_IS_RECIPE_CONFIRMED: {
        if (typeof action.isRecipeConfirmed === 'boolean') {
          return state.set('isRecipeConfirmed', action.isRecipeConfirmed)
        }

        return state
      }

      case actionTypes.SIGNUP_SET_RECIPE_PREFERENCES: {
        const recipePreferences =
          action?.recipePreferences
            ?.filter((preference) => typeof preference === 'string')
            .slice(0, signupConfig.maxSelectedRecipePreferences) || []

        return state.set('recipePreferences', Immutable.List(recipePreferences))
      }

      default: {
        return state
      }
    }
  },
}

export { signupReducers }

type SignupAction = Action<string> &
  Partial<{
    currentStepName: string
    currentStepNumber: number
    isLastStep: boolean
    count: number
    district: string
    newLocation: string
    isDietaryConfirmed: boolean
    dietaryPreferences: string[]
    isRecipeConfirmed: boolean
    recipePreferences: string[]
    stepNames: string[]
  }>
