import { Order } from '@library/api-user'
import { queueTrackingEvent } from '@library/tracking-integration'
import { orderModel } from '../models/orderModel'

type CommonDetails = {
  order_id: string
}

type TrackingOrderDetails = {
  period_id: number
  portion_size: string
  delivery_date: string | undefined
  order_state: string
} & CommonDetails

type TrackingEventsForOrderDetails = {
  action: 'choose_recipes_click' | 'plates_click'
  properties: TrackingOrderDetails
}

type TrackingEventsForGetHelp = {
  action: 'click_get_help_with_this_box'
  properties: CommonDetails
}

const getTrackingPayload = (order: Order, model: ReturnType<typeof orderModel>) => {
  return {
    order_id: model.getOrderId(order),
    period_id: model.getOrderPeriodId(order),
    portion_size: model.getOrderNumberOfPortions(order),
    delivery_date: model.getDeliveryDate(order),
    order_state: model.getOrderState(order),
  }
}

export const useTracking = (order: Order) => {
  const model = orderModel()

  return {
    trackChooseRecipes: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'choose_recipes_click',
        properties: payload,
      })
    },
    trackPlates: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'plates_click',
        properties: payload,
      })
    },
    trackGetHelp: () => {
      queueTrackingEvent<TrackingEventsForGetHelp>({
        action: 'click_get_help_with_this_box',
        properties: {
          order_id: order.id,
        },
      })
    },
  }
}
