import React from 'react'

import {
  Modal,
  ModalClose,
  ModalHeader,
  ModalBody,
  ModalButton,
  Heading5,
  ButtonColorVariant,
  ButtonSize,
  JustifyContent,
  Box,
  Space,
  Display,
  BorderStyle,
  Color,
  IconVariant,
  Icon,
  Text,
  FontFamily,
} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'

import { Order, useSubscriptionModal } from 'routes/Account/Subscription/hooks/useSubscriptionModal'

type NotificationIcon = 'error' | 'info'

const getNotificationStyleByState = (state: string) => {
  if (state === 'error') {
    return {
      bg: Color.Error_50,
      borderColour: Color.Error_900,
      iconVariant: IconVariant.Error,
      iconName: 'error' as NotificationIcon,
    }
  }

  return {
    bg: Color.Informative_50,
    borderColour: Color.Informative_200,
    iconVariant: IconVariant.Informative,
    iconName: 'info' as NotificationIcon,
  }
}

interface Props {
  numPortions: string
  openOrders: Order[]
  didUpdateComplete: boolean
  didUpdateFail: boolean | null
  shouldShow?: boolean
}

export const boxSizeConfirmationModal = 'box-size-confirmation-modal'

export const BoxSizeConfirmationModal = ({
  numPortions,
  openOrders,
  didUpdateComplete,
  didUpdateFail,
  shouldShow = false,
}: Props) => {
  const { content, handleClose } = useSubscriptionModal({
    openOrders,
    didUpdateFail,
    didUpdateComplete,
    numPortions,
    modalName: boxSizeConfirmationModal,
  })

  const handleClick = () => {
    handleClose()
    browserHistory.push('/my-deliveries')
  }

  const notificationStyle = getNotificationStyleByState(content.variant)

  return (
    <Modal preventScroll isOpen={shouldShow} name={boxSizeConfirmationModal}>
      <ModalHeader>
        <Heading5>{content.title}</Heading5>
        <ModalClose onClose={handleClose} />
      </ModalHeader>
      <ModalBody>
        {!didUpdateFail && (
          <>
            <Heading5>{content.subTitle}</Heading5>
            <Space size={3} direction="vertical" />
          </>
        )}
        {(openOrders.length > 0 || didUpdateFail) && (
          <>
            <Box
              display={Display.Flex}
              bg={notificationStyle.bg}
              borderColor={notificationStyle.borderColour}
              width="100%"
              paddingV={4}
              paddingH={3}
              borderStyle={BorderStyle.Solid}
              borderWidth={0.5}
              borderRadius={1.5}
              data-testid="boxSizeModalInfoPanel"
            >
              <Box display={Display.Flex} width="100%">
                <Icon
                  name={notificationStyle.iconName}
                  size={5}
                  variant={notificationStyle.iconVariant}
                />
                <Space direction="horizontal" size={2} />
                <Box>
                  <Text size={1} fontFamily={FontFamily.Bold}>
                    {content.notificationTitle}
                  </Text>
                  <Text size={1}>{content.notificationBody}</Text>
                </Box>
              </Box>
            </Box>
            <Space size={4} />
          </>
        )}
        <Box justifyContent={JustifyContent.Center} width="100%">
          <ModalButton
            colorVariant={ButtonColorVariant.Primary}
            onClick={handleClick}
            size={ButtonSize.Medium}
            width="100%"
          >
            Review upcoming deliveries
          </ModalButton>
          <Space size={2} />
          <ModalButton
            colorVariant={ButtonColorVariant.Tertiary}
            onClick={handleClose}
            size={ButtonSize.Medium}
            width="100%"
          >
            Got it
          </ModalButton>
        </Box>
      </ModalBody>
    </Modal>
  )
}
