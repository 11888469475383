import React, { SyntheticEvent, useRef, useState } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'

import {
  Box,
  FontFamily,
  JustifyContent,
  Text,
  withCitrusTheme,
  TextAlign,
  Color,
  Icon,
  FlexDirection,
  Display,
  IconVariant,
  FontWeight,
} from '@gousto-internal/citrus-react'

import { RecipeAlternativeOptions } from './RecipeAlternativeOptions'
import { useClickOutside } from './useClickOutside'
import { queueTrackingEvent } from '@library/tracking-integration'
import type {
  TrackRecipeAlternativeOptionsMenuOpen,
  TrackRecipeAlternativeOptionsMenuSwapRecipes,
} from './SwapAlternativeOptions'

const Drawer = withCitrusTheme(Box, () => ({
  backgroundColor: Color.White,
  position: 'fixed',
  top: 'auto',
  bottom: '65px',
  width: '100vw',
  minHeight: 'auto',
  zIndex: '100',
  left: '0',
  [`@media (max-width: '420px')`]: {
    transform: 'translate(-50%, 0)',
  },
}))

const DrawerBody: React.FC = withCitrusTheme(Box, () => ({
  textAlign: 'left',
  margin: '0',
  padding: '1rem',
  overflowY: 'scroll',
}))

const IconCustom = withCitrusTheme(Icon, () => ({
  fontFamily: FontFamily.Bold,
}))

const DrawerHeader: React.FC = withCitrusTheme(Box, () => ({
  display: Display.Flex,
  flexDirection: FlexDirection.Row,
  justifyContent: JustifyContent.SpaceBetween,
  padding: '1rem 1rem 0rem',
}))

export type SwapAlternativeOptionsMobileProps = {
  recipeId: string
  categoryId: string
  selectVariant: ({
    variantId,
    variantOutOfStock,
  }: {
    variantId: string
    variantOutOfStock: boolean
  }) => void
  alternativeOptions: {
    recipeId: string
    recipeName: string
    isChecked: boolean
    isOutOfStock: boolean
    surcharge: number | null
  }[]
}

const overlay = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 100,
}

const content: React.CSSProperties = {
  backgroundColor: 'transparent',
  border: 0,
  position: 'relative',
}

export const SwapAlternativeOptionsMobile: React.FC<SwapAlternativeOptionsMobileProps> = ({
  recipeId,
  categoryId,
  selectVariant,
  alternativeOptions,
}) => {
  const [showModal, setShowModal] = useState(false)

  const collectionId = categoryId

  const selectRef = useRef(null)
  useClickOutside(selectRef, () => setShowModal(false), [showModal])

  const onCloseModal = (e: SyntheticEvent) => {
    e.stopPropagation()
    setShowModal(false)
  }
  const onClickButton = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    event.stopPropagation()
    setShowModal(!showModal)
    if (!showModal) {
      queueTrackingEvent<TrackRecipeAlternativeOptionsMenuOpen>({
        action: 'recipe-alternative-options-menu-open',
        recipeId,
        collectionId,
      })
    }
  }

  return (
    <Box display="flex" justifyContent={JustifyContent.FlexEnd} style={{ marginLeft: '1rem' }}>
      <Box
        data-testid="SwapAlternativeOptionsMobileButton"
        onClick={onClickButton}
        onKeyDown={onClickButton}
        role="button"
        tabIndex={-1}
      >
        <Text fontFamily={FontFamily.Bold} color={Color.Secondary_400}>
          Options
        </Text>
      </Box>
      {showModal && (
        <ReactModal isOpen={showModal} style={{ overlay, content }}>
          <Drawer>
            <DrawerHeader>
              <Text
                textAlign={TextAlign.Left}
                fontFamily={FontFamily.Book}
                size={5}
                fontWeight={FontWeight.SemiBold}
              >
                Options available
              </Text>
              <IconCustom
                name="close"
                size={6}
                onClick={onCloseModal}
                role="button"
                variant={IconVariant.Default}
              />
            </DrawerHeader>

            <Box ref={selectRef}>
              <DrawerBody>
                <RecipeAlternativeOptions
                  recipeId={recipeId}
                  onChangeCheckedRecipe={({
                    previousRecipeId,
                    nextRecipeId,
                  }: {
                    previousRecipeId: string
                    nextRecipeId: string
                  }) => {
                    queueTrackingEvent<TrackRecipeAlternativeOptionsMenuSwapRecipes>({
                      action: 'recipe-alternative-options-menu-swap-recipes',
                      previousRecipeId,
                      nextRecipeId,
                      collectionId,
                    })
                    setShowModal(false)
                  }}
                  alternativeOptions={alternativeOptions}
                  selectVariant={selectVariant}
                  isMobile={true}
                />
              </DrawerBody>
            </Box>
          </Drawer>
        </ReactModal>
      )}
    </Box>
  )
}
