import React from 'react'
import styled from '@emotion/styled'

type Props = {
  score: number
  onClick: () => void
  onKeyPress: () => void
  activeStar: boolean
  className?: string
}

const Star = ({ score, onClick, onKeyPress, className }: Props) => (
  <svg
    className={className}
    onClick={onClick}
    onKeyPress={onKeyPress}
    tabIndex={0}
    role="button"
    aria-label={`${score} of 5 stars`}
    width="34"
    height="34"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    data-testid="starFilled"
  >
    <title>{`${score} of 5 stars`}</title>
    <desc>Rating star to be clicked on to rate recipe</desc>
    <path
      d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z"
      fill="#F4D13D"
      stroke="#F4D13D"
      strokeWidth="1.5"
    />
  </svg>
)

export const StarFilled = styled(Star)(({ activeStar }) => {
  return activeStar
    ? {
        animation: 'activateStar 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0s',
        ['@keyframes activateStar']: {
          '0%': {
            transform: 'scale(1)',
          },
          '50%': {
            transform: 'scale(1.5)',
          },
          '100%': {
            transform: 'scale(1)',
          },
        },
      }
    : {}
})
