import React from 'react'

import Immutable from 'immutable'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ReactReduxContext } from 'react-redux'
import { queueTrackingEvent } from '@library/tracking-integration'

import { withOptimizely, ReactSDKClient } from '@optimizely/react-sdk'
import { Cookbook } from './Cookbook'
import { HeaderContainer } from './Header'
import { OrderConflictConfirmationModalContainer } from './OrderConflictConfirmationModal/OrderConflictConfirmationModalContainer'
import { ReferAFriend } from './ReferAFriend'
import { Section } from './Section'

const propTypes = {
  userLoadOrders: PropTypes.func.isRequired,
  userGetReferralDetails: PropTypes.func.isRequired,
  nameFirst: PropTypes.string,
  referralDetails: PropTypes.instanceOf(Immutable.Map),
  redirect: PropTypes.func,
  rateRecipeCount: PropTypes.number,
  trackClickRateRecipes: PropTypes.func,
  serverRedirectedFrom: PropTypes.string,
  setServerRedirectedFrom: PropTypes.func,
  optimizely: PropTypes.shape(ReactSDKClient),
}

const defaultProps = {
  nameFirst: '',
  referralDetails: Immutable.Map(),
  redirect: () => {},
  rateRecipeCount: 0,
  trackClickRateRecipes: () => {},
  serverRedirectedFrom: null,
  setServerRedirectedFrom: () => {},
  optimizely: null,
}

class MyGoustoComponent extends React.PureComponent {
  componentDidMount() {
    const {
      userLoadOrders,
      userGetReferralDetails,
      serverRedirectedFrom,
      setServerRedirectedFrom,
      optimizely,
    } = this.props

    userLoadOrders()
    userGetReferralDetails()
    const isRedirectingToMyGoustoExperimentEnabled = optimizely?.isFeatureEnabled('turnips_ofx_redirecting_to_my_gousto_page')
    if (isRedirectingToMyGoustoExperimentEnabled) {
      queueTrackingEvent({
        action: 'web_flow_redirect',
        properties: {
          returning_logged_in_user: serverRedirectedFrom === '/'
        }
      })
      setServerRedirectedFrom({url: serverRedirectedFrom, processed: true})
    }
  }

  render() {
    const {
      nameFirst,
      referralDetails,
      redirect,
      rateRecipeCount,
      trackClickRateRecipes,
    } = this.props
    const headerTitle = `Hello ${nameFirst},`

    return (
      <div>
        <Helmet title="My Gousto Account | View Your Most Recent Deliveries and Recipes" />
        <Section hasPaddingBottom={false}>
          <OrderConflictConfirmationModalContainer />
        </Section>
        <Section title={headerTitle} largeTitle alternateColour hasPaddingBottom={false}>
          <HeaderContainer />
        </Section>
        <Cookbook rateRecipeCount={rateRecipeCount} trackClickRateRecipes={trackClickRateRecipes} />
        <Section title="Your Gousto wins" alternateColour>
          <ReferAFriend referralDetails={referralDetails} redirect={redirect} />
        </Section>
      </div>
    )
  }
}

MyGoustoComponent.propTypes = propTypes
MyGoustoComponent.defaultProps = defaultProps
MyGoustoComponent.contextType = ReactReduxContext

const MyGousto = withOptimizely(MyGoustoComponent)

export { MyGousto }
