import React from 'react'

export type PaymentCard3DSModalContext = {
  cardConfirmationCompleted: boolean
  cardConfirmationError: string | undefined
  cardConfirmationRedirectURL: string | undefined
  setCardConfirmationRedirectURL: (url: string | undefined) => void
  setCardConfirmationError: (url: string | undefined) => void
  reset: () => void
  onCardConfirmationLoaded: (event: React.SyntheticEvent<HTMLIFrameElement>) => void
}

const initialPaymentCard3DSModalContext: PaymentCard3DSModalContext = {
  cardConfirmationCompleted: false,
  cardConfirmationError: '',
  cardConfirmationRedirectURL: '',
  onCardConfirmationLoaded: () => null,
  setCardConfirmationRedirectURL: () => null,
  setCardConfirmationError: () => null,
  reset: () => null,
}

export const PaymentCard3DSModalContext = React.createContext<PaymentCard3DSModalContext>(
  initialPaymentCard3DSModalContext,
)
