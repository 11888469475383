import { FormikErrors, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { restrictedRegularExpressions } from 'validations/regularExpressions'

import { actionTypes } from 'actions/actionTypes'
import { AddressFields } from 'routes/Checkout/Components/PostcodeAddressFields'
import {
  getBillingAddress,
  getIsBillingAddressDifferent,
} from 'routes/Checkout/checkoutPaymentSelectors'
import { getBasketPostcode } from 'selectors/basket'

export type FormFields = {
  isBillingAddressDifferent: boolean
  cardHolderName: string
} & AddressFields

const POSTCODE_MIN_LENGTH = 5
const POSTCODE_MAX_LENGTH = 8
/**
 * https://gousto.atlassian.net/browse/TG-8602
 * Changed the max length for houseNo field from 35 to 70
 * This will allow some houseNo values coming from the postcode-lookup API
 * to be valid while the user input is still limited to max 35 characters.
 */
const HOUSE_NUMBER_MAX_LENGTH = 70
const STREET_MAX_LENGTH = 35
const TOWN_MAX_LENGTH = 32

const validate = (formValues: FormFields): FormikErrors<FormFields> => {
  const errors: FormikErrors<FormFields> = {}

  if (!formValues.cardHolderName) {
    errors.cardHolderName = 'Card name is required'
  }

  if (formValues.isBillingAddressDifferent) {
    if (formValues.searchPostcode) {
      const invalidPostcode = formValues.searchPostcode.match(restrictedRegularExpressions.postcode)
      if (invalidPostcode) {
        errors.searchPostcode = `Postcode can't contain ${invalidPostcode}`
      } else if (formValues.searchPostcode.length < POSTCODE_MIN_LENGTH) {
        errors.searchPostcode = 'Postcode must be at least 5 characters'
      } else if (formValues.searchPostcode.length > POSTCODE_MAX_LENGTH) {
        errors.searchPostcode = 'Postcode must be under 8 characters'
      }
    } else {
      errors.searchPostcode = 'Postcode is required'
    }

    if (formValues.houseNo) {
      const invalidHouseNo = formValues.houseNo.match(restrictedRegularExpressions.houseNo)
      if (invalidHouseNo) {
        errors.houseNo = `House number can't contain ${invalidHouseNo}`
      } else if (formValues.houseNo.length > HOUSE_NUMBER_MAX_LENGTH) {
        errors.houseNo = `House number must be under ${HOUSE_NUMBER_MAX_LENGTH} characters`
      }
    } else {
      errors.houseNo = 'House number is required'
    }

    if (formValues.street) {
      const invalidStreet = formValues.street.match(restrictedRegularExpressions.houseNo)
      if (invalidStreet) {
        errors.street = `Street can't contain ${invalidStreet}`
      } else if (formValues.street.length > STREET_MAX_LENGTH) {
        errors.street = `Street must be under ${STREET_MAX_LENGTH} characters`
      }
    }

    if (formValues.town) {
      const invalidTown = formValues.town.match(restrictedRegularExpressions.town)
      if (invalidTown) {
        errors.town = `City can't contain ${invalidTown}`
      } else if (formValues.town.length > TOWN_MAX_LENGTH) {
        errors.town = `City must be under ${TOWN_MAX_LENGTH} characters`
      }
    } else {
      errors.town = 'City is required'
    }
  }

  return errors
}

export const useAddressDetailsForm = () => {
  const deliveryPostcode = useSelector(getBasketPostcode)
  const billingAddress = useSelector(getBillingAddress)
  const isBillingAddressDifferent = useSelector(getIsBillingAddressDifferent)
  const dispatch = useDispatch()

  return useFormik<FormFields>({
    initialValues: {
      cardHolderName: '',
      searchPostcode: deliveryPostcode ?? '',
      postcode: billingAddress?.get('postcode') ?? '',
      houseNo: billingAddress?.get('houseNo') ?? '',
      street: billingAddress?.get('street') ?? '',
      town: billingAddress?.get('town') ?? '',
      county: billingAddress?.get('county') ?? '',
      udprn: billingAddress?.get('udprn') ?? '',
      isBillingAddressDifferent,
    },
    validateOnBlur: true,
    validate,
    onSubmit: (formValues) => {
      dispatch({
        type: actionTypes.PAYMENT_SET_BILLING_ADDRESS,
        postcode: formValues.postcode,
        houseNo: formValues.houseNo,
        street: formValues.street,
        town: formValues.town,
        county: formValues.county,
        udprn: formValues.udprn,
      })
    },
  })
}
