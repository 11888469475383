import React from 'react'
import {
  AlignItems,
  Box,
  Color,
  Display,
  Display1,
  DisplayPosition,
  FlexDirection,
  FontWeight,
  Heading5,
  Overflow,
  Space,
  Text,
  TextAlign,
  ZIndex,
} from '@gousto-internal/citrus-react'

import { HeroSlides } from '../../../../config'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import topLeftMobileWebp from '../../../../assets/carousel/hero/mobile/prawn-noodles.webp'
import topLeftMobile1xPng from '../../../../assets/carousel/hero/mobile/prawn-noodles.png'
import topLeftMobile2xPng from '../../../../assets/carousel/hero/mobile/prawn-noodles@2x.png'
import topLeftDesktopWebp from '../../../../assets/carousel/hero/desktop/prawn-noodles.webp'
import topLeftDesktop1xPng from '../../../../assets/carousel/hero/desktop/prawn-noodles.png'
import topLeftDesktop2xPng from '../../../../assets/carousel/hero/desktop/prawn-noodles@2x.png'

import topRightMobileWebp from '../../../../assets/carousel/hero/mobile/katsu.webp'
import topRightMobile1xPng from '../../../../assets/carousel/hero/mobile/katsu.png'
import topRightMobile2xPng from '../../../../assets/carousel/hero/mobile/katsu@2x.png'
import topRightDesktopWebp from '../../../../assets/carousel/hero/desktop/katsu.webp'
import topRightDesktop1xPng from '../../../../assets/carousel/hero/desktop/katsu.png'
import topRightDesktop2xPng from '../../../../assets/carousel/hero/desktop/katsu@2x.png'

import bottomRightMobileWebp from '../../../../assets/carousel/hero/mobile/salmon-tacos.webp'
import bottomRightMobile1xPng from '../../../../assets/carousel/hero/mobile/salmon-tacos.png'
import bottomRightMobile2xPng from '../../../../assets/carousel/hero/mobile/salmon-tacos@2x.png'
import bottomRightDesktopWebp from '../../../../assets/carousel/hero/desktop/salmon-tacos.webp'
import bottomRightDesktop1xPng from '../../../../assets/carousel/hero/desktop/salmon-tacos.png'
import bottomRightDesktop2xPng from '../../../../assets/carousel/hero/desktop/salmon-tacos@2x.png'

import bottomLeftMobileWebp from '../../../../assets/carousel/hero/mobile/salad.webp'
import bottomLeftMobile1xPng from '../../../../assets/carousel/hero/mobile/salad.png'
import bottomLeftMobile2xPng from '../../../../assets/carousel/hero/mobile/salad@2x.png'
import bottomLeftDesktopWebp from '../../../../assets/carousel/hero/desktop/salad.webp'
import bottomLeftDesktop1xPng from '../../../../assets/carousel/hero/desktop/salad.png'
import bottomLeftDesktop2xPng from '../../../../assets/carousel/hero/desktop/salad@2x.png'

export const HeroSlide = () => {
  const { titlePart1, titlePart2, subTitlePart1, subTitlePart2 } = HeroSlides
  return (
    <Box
      data-testid="hero-slide"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      minHeight={'610px'}
      position={DisplayPosition.Relative}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
    >
      <Box width={'307px'} className="center" zIndex={ZIndex.Mask}>
        <Space size={16} />
        <Space size={10} />
        <Display1 data-testid="hero-slide-title" color={Color.White} textAlign={TextAlign.Center}>
          <Text
            style={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
              fontFamily: 'inherit',
              textAlign: 'inherit',
            }}
          >
            {titlePart1}
          </Text>
          {titlePart2}
        </Display1>
        <Space size={1} />
        <Heading5
          data-testid="hero-slide-subtitle"
          color={Color.White}
          fontWeight={FontWeight.Bold}
          textAlign={TextAlign.Center}
        >
          <Text
            style={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
              fontFamily: 'inherit',
              textAlign: 'inherit',
            }}
          >
            {subTitlePart1}
          </Text>
          {subTitlePart2}
        </Heading5>
      </Box>
      <Box
        className="top-left"
        position={DisplayPosition.Absolute}
        width={['248px', '272px', '272px', '460px']}
        height={['248px', '272px', '272px', '460px']}
        top={['-40px', '-56px', '-56px', '-84px']}
        left={['18px', 'calc(50% - 132px)', 'calc(50% - 132px)', 'calc(50% - 280px)']}
        style={{
          transform: 'translateX(-50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={topLeftMobileWebp}
          mobile1xPng={topLeftMobile1xPng}
          mobile2xPng={topLeftMobile2xPng}
          desktopWebp={topLeftDesktopWebp}
          desktop1xPng={topLeftDesktop1xPng}
          desktop2xPng={topLeftDesktop2xPng}
          alt="Prawn noodles recipe"
        />
      </Box>
      <Box
        className="top-right"
        position={DisplayPosition.Absolute}
        width={['348px', '400px', '400px', '364px']}
        height={['348px', '400px', '400px', '364px']}
        top={['-84px', '-114px', '-114px', '-90px']}
        right={['0px', 'calc(50% - 214px)', 'calc(50% - 214px)', 'calc(50% - 246px)']}
        style={{
          transform: 'translateX(50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={topRightMobileWebp}
          mobile1xPng={topRightMobile1xPng}
          mobile2xPng={topRightMobile2xPng}
          desktopWebp={topRightDesktopWebp}
          desktop1xPng={topRightDesktop1xPng}
          desktop2xPng={topRightDesktop2xPng}
          alt="Katsu recipe"
        />
      </Box>
      <Box
        className="bottom-right"
        position={DisplayPosition.Absolute}
        width={['340px', '354px', '354px', '660px']}
        height={['340px', '354px', '354px', '660px']}
        bottom={['32px', '18px', '0px', '-234px']}
        right={['0px', 'calc(50% - 128px)', 'calc(50% - 160px)', 'calc(50% - 276px)']}
        style={{
          transform: 'translateX(50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={bottomRightMobileWebp}
          mobile1xPng={bottomRightMobile1xPng}
          mobile2xPng={bottomRightMobile2xPng}
          desktopWebp={bottomRightDesktopWebp}
          desktop1xPng={bottomRightDesktop1xPng}
          desktop2xPng={bottomRightDesktop2xPng}
          alt="Salmon tacos recipe"
        />
      </Box>
      <Box
        className="bottom-left"
        position={DisplayPosition.Absolute}
        width={['300px', '308px', '308px', '452px']}
        height={['300px', '308px', '308px', '452px']}
        bottom={['72px', '70px', '70px', '-100px']}
        left={['-20px', 'calc(50% - 226px)', 'calc(50% - 226px)', 'calc(50% - 452px)']}
        style={{
          transform: 'translateX(-50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={bottomLeftMobileWebp}
          mobile1xPng={bottomLeftMobile1xPng}
          mobile2xPng={bottomLeftMobile2xPng}
          desktopWebp={bottomLeftDesktopWebp}
          desktop1xPng={bottomLeftDesktop1xPng}
          desktop2xPng={bottomLeftDesktop2xPng}
          alt="Salad recipe"
        />
      </Box>
    </Box>
  )
}
