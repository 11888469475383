import * as React from 'react'
import { Route } from 'react-router'
import Helmet from 'react-helmet'

import {
  Box,
  Display1,
  Heading2,
  Join,
  Space,
  Color,
  BoxProps,
  Body1,
  Divider,
} from '@gousto-internal/citrus-react'
import { OptimizelyDecision, useDecision } from '@optimizely/react-sdk'

type VariationKey = 'on' | 'off' | 'null-variation-key'

interface DecisionTextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
}

function DecisionText(props: DecisionTextProps) {
  const { children, ...rest } = props

  return (
    <Body1 {...rest} color={Color.White}>
      {children}
    </Body1>
  )
}

function NotReady() {
  return (
    <Box id="sdk-not-ready-container" width="200px" height="200px">
      <Heading2>OFX not ready</Heading2>
    </Box>
  )
}

function ErrorReasons(props: { reasons: OptimizelyDecision['reasons'] }) {
  const hasErrorReasons = props.reasons.length > 0

  return (
    <Box
      id="error-reasons-container"
      data-has-errors={hasErrorReasons}
      paddingH={5}
      paddingV={5}
      bg={hasErrorReasons ? Color.Error_400 : Color.Informative_400}
    >
      {hasErrorReasons && props.reasons.map((r) => <DecisionText key={r}>{r}</DecisionText>)}

      {!hasErrorReasons && <DecisionText>None</DecisionText>}
    </Box>
  )
}

function Decision(props: { decision: OptimizelyDecision }) {
  const { decision } = props

  const decisionStyles: Record<VariationKey, Partial<BoxProps>> = {
    on: { bg: Color.Success_400 },
    off: { bg: Color.Warning_400 },
    'null-variation-key': { bg: Color.Error_400 },
  }

  return (
    <Box
      paddingH={5}
      paddingV={5}
      {...decisionStyles[(decision.variationKey || 'null-variation-key') as VariationKey]}
    >
      <DecisionText>Decision enabled: {decision.enabled.toString()}</DecisionText>
      <DecisionText data-variation-key={decision.variationKey}>
        Variation key: {decision.variationKey}
      </DecisionText>
      <DecisionText>Allocation ID: {decision.userContext.id}</DecisionText>
    </Box>
  )
}

export function OFXTestComponent() {
  const [decision, ofxReady] = useDecision('gousto-webclient_testing_flag')

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Join with={<Space size={3} />}>
        <Display1>OFX Test Route</Display1>
        <Divider />

        {!ofxReady ? <NotReady /> : null}

        <Heading2>Decision details</Heading2>
        <Decision decision={decision} />

        <Heading2>Decision errors</Heading2>
        <ErrorReasons reasons={decision.reasons} />
      </Join>
    </>
  )
}

export const OFXTestRoute = <Route path="/ofx" component={OFXTestComponent} />
