import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
  sendJSON,
  auth,
} from '@library/http/requests'
import { MenuApiPreferencesResponse, MenuSettingsRequestPayload } from './types'

type Params = {
  userId: string
}

// create new parser for menu api
type Result<T, U> = { type: 'success'; value: T } | { type: 'failure'; error: U }

export async function jsonParseMenuApi(resp: string): Promise<Result<unknown, Error>> {
  try {
    const value = await JSON.parse(resp)
    return { type: 'success', value }
  } catch (parseErr) {
    return { type: 'failure', error: parseErr as Error }
  }
}

const menuApiPreferencesParse = async (response: Response): Promise<MenuApiPreferencesResponse> => {
  if (!response.ok) {
    throw new Error('Error fetching menu preferences')
  }

  const responseClone = await response.text()

  const parsedJSON = await jsonParseMenuApi(responseClone)

  if (parsedJSON.type === 'failure') {
    throw parsedJSON.error
  }

  return parsedJSON.value as MenuApiPreferencesResponse
}

const menuApiPreferencesRequest = composeRequest(
  setServiceUrl('menu', 3),
  setQueryParams((input: Params) => ({
    user_id: input.userId,
  })),
  addPath('preferences'),
  setMethod('GET'),
)

// Get Request
export const menuPreferencesFetcher = composeFetch(
  menuApiPreferencesRequest,
  menuApiPreferencesParse,
)

const menuApiPreferencesUpdateParse = async (response: Response): Promise<null> => {
  if (!response.ok) {
    throw new Error('Error updating menu preferences')
  }

  return null
}

const composeMenuPreferencesUpdateRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath('preferences'),
  setMethod('PUT'),
  sendJSON<MenuSettingsRequestPayload>(),
  auth,
)

export const updateMenuPreferences = composeFetch(
  composeMenuPreferencesUpdateRequest,
  menuApiPreferencesUpdateParse,
)
