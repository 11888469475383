import { useDecision } from '@optimizely/react-sdk'
import { useDispatch } from 'react-redux'

import actions from 'actions/auth'
import {
  DiscountSofteningFreeDeliveryTreatments,
  useGetDiscountSofteningFreeDelivery,
} from 'hooks/useGetDiscountSofteningFreeDelivery'
import { useIsDiscountGiftEnabled } from 'hooks/useIsDiscountGiftEnabled'

import {
  DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
  DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA,
  FREE_DELIVERY_PROMO_CODE_DATA,
  GIFTING_EXPERIMENT_PROMO_CODE_DATA,
  PROMO_CODE_DATA,
  PromoCodeData,
} from './homeConfig'

/**
 * Experiments running on home page that controls order or visibility
 * of homepage sections should be placed here
 */

export const DISCOUNT_SOFTENING_FREE_DELIVERY_BANNER_DATA: Record<
  DiscountSofteningFreeDeliveryTreatments,
  PromoCodeData
> = {
  t1: DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
  t2: FREE_DELIVERY_PROMO_CODE_DATA,
  t3: DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA,
}

export const useGetHomePageExperiments = () => {
  /** Immersive Hero experiment uses Optimizely FX
   */
  const [immersiveHeroEnabledDecision] = useDecision('beetroots_ofx_homepage_immersive_hero_test')
  const isImmersiveHeroEnabled = immersiveHeroEnabledDecision.variationKey === 't1'

  return {
    isImmersiveHeroEnabled,
  }
}

export const useGetMenuData = () => {
  const dispatch = useDispatch()
  const dispatchRedirectLoggedInUser = () => dispatch(actions.redirectLoggedInUser)

  return {
    dispatchRedirectLoggedInUser,
  }
}

export const isValidDiscountSofteningFreeDeliveryVariation = (
  variation: string,
): variation is DiscountSofteningFreeDeliveryTreatments => ['t1', 't2', 't3'].includes(variation)

export const usePromocodeData = (): PromoCodeData => {
  const [isDiscountSofteningFreeDeliveryEnabled, discountSofteningFreeDeliveryVariation] =
    useGetDiscountSofteningFreeDelivery()

  const isValidDiscountSofteningFreeDeliveryTreatment =
    isDiscountSofteningFreeDeliveryEnabled &&
    discountSofteningFreeDeliveryVariation &&
    isValidDiscountSofteningFreeDeliveryVariation(discountSofteningFreeDeliveryVariation)

  const isDiscountGiftingExperimentEnabled = useIsDiscountGiftEnabled()

  const promoBannerData = (() => {
    if (isValidDiscountSofteningFreeDeliveryTreatment) {
      return DISCOUNT_SOFTENING_FREE_DELIVERY_BANNER_DATA[discountSofteningFreeDeliveryVariation]
    } else if (isDiscountGiftingExperimentEnabled) {
      return GIFTING_EXPERIMENT_PROMO_CODE_DATA
    } else {
      return PROMO_CODE_DATA
    }
  })()

  return promoBannerData
}
