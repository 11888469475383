import Immutable from 'immutable'
import moment from 'moment'

type ImmutableDeliveryDays = Immutable.Map<string, any>
type DateTime = string

const formatPointInTime = (tempDate: DateTime, time: DateTime) =>
  moment(`${tempDate} ${time}`).format('ha')

const formatTime = (deliveryStartTime: DateTime, deliveryEndTime: DateTime, tempDate: DateTime) => {
  if (!tempDate) {
    return ''
  }

  return `${formatPointInTime(tempDate, deliveryStartTime)} - ${formatPointInTime(
    tempDate,
    deliveryEndTime,
  )}`
}

const formatDate = (date: DateTime) =>
  `${moment(date).format('dddd')}s (starting ${moment(date).format('D MMM')})`

/**
 * getDeliveryDaysAndSlots returns an object to populate slots timings for each deliveryDay
 * This information is used for the dropdown and to generate the label of delivery date button
 */
export const getDeliveryDaysAndSlots = (
  deliveryDaysWithDisabled: ImmutableDeliveryDays,
  tempDate: DateTime,
  disabledSlots: string[],
  userHasAvailableSlots: boolean,
) => {
  const slots: Record<string, any> = {}
  const deliveryDays = deliveryDaysWithDisabled
    .map((dd) => {
      const date = dd.get('date')
      slots[date] = dd
        .get('slots')
        .sort((a: ImmutableDeliveryDays, b: ImmutableDeliveryDays) =>
          a.get('deliveryStartTime').localeCompare(b.get('deliveryStartTime')),
        )
        .map((slot: Immutable.Map<string, string>) => {
          const isSlotDisabled = !!(
            disabledSlots && disabledSlots.includes(slot.get('disabledSlotId'))
          )

          return {
            label: formatTime(slot.get('deliveryStartTime'), slot.get('deliveryEndTime'), tempDate),
            subLabel: '',
            value: slot.get('id'),
            coreSlotId: slot.get('coreSlotId'),
            disabled: isSlotDisabled,
          }
        })
        .toArray()
      const disabled =
        userHasAvailableSlots === false ||
        (dd && dd.get('alternateDeliveryDay') !== null) ||
        slots[date].every((slot: Record<string, boolean>) => slot.disabled)

      return {
        date,
        value: date,
        disabled,
        label: formatDate(date),
      }
    })
    .toArray()
    .sort((a, b) => moment.utc(a.value).diff(moment.utc(b.value)))

  return { slots, deliveryDays }
}

export function getDaysUntilDate(date: string) {
  const deliveryDate = moment(date).endOf('day')
  const currentDate = moment().utc().startOf('day')

  return deliveryDate.diff(currentDate, 'days')
}
