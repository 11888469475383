import { useSelector } from 'react-redux'

import { getBasketTotalRecipes, getNumPortions } from 'selectors/basket'

import { useSupportedBoxTypes } from './internal/useSupportedBoxTypes'

export const useNumRecipesOverBasketLimit = (): number => {
  const { maxRecipesForPortion } = useSupportedBoxTypes()

  const numPortions = useSelector(getNumPortions)
  const maxRecipesAllowedForPortionSize = maxRecipesForPortion(numPortions)
  const totalRecipesInBasket = useSelector(getBasketTotalRecipes)

  return Math.max(totalRecipesInBasket - maxRecipesAllowedForPortionSize, 0)
}
