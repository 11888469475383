export enum Title {
  CARD = 'Card',
  PAYPAL = 'Paypal',
  APPLE_PAY = 'ApplePay',
}

export enum DataFrame {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
}

export enum CardConfirmationRedirectTo {
  SUCCESS = '/payment/success',
  FAILURE = '/payment/failure',
}

export enum ApiKeys {
  UserCurrent = 'api/user/current',
  UserCurrentShippingAddress = 'api/user/current/address/shipping',
  UserCurrentBalance = 'api/user/current/balance',
  UserCurrentDefaultShippingAddress = 'api/user/current/changeDefaultAddress',
  UserCurrentPendingOrders = 'api/user/current/orders/pending',
}
