export enum RecipeType {
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
}

export interface RecipeData {
  name: string
  imageUrl: string
  type: RecipeType | null
}
