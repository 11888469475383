export const CollectionSlug = {
  Recommendations: 'recommendations',
  AllRecipes: 'all-recipes',
  DairyFree: 'dairy-free',
  GlutenFree: 'gluten-free',
  CalorieControlled: 'calorie-controlled',
  CarbonCuttingCuisine: 'carbon-cutting-cuisine',
  SaveSavour: 'save-savour',
}

export const CollectionId = {
  Recommendations: '97270056-cd65-11e8-a2d2-067a72dd5dba',
  AllRecipes: 'ca8f71be-63ac-11e6-a693-068306404bab',
}
