import React from 'react'

import {
  Box,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Heading6,
  Icon,
  IconVariant,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'

type CommunicationPanelProps = {
  title?: string
  body: string
  showIcon?: boolean
}

export const CommunicationPanel = ({ title, body, showIcon }: CommunicationPanelProps) => (
  <Box width="100%">
    <Box
      borderWidth={0.5}
      display={Display.Flex}
      paddingV={4}
      paddingH={4}
      bg={Color.NeutralGrey_50}
      style={{ borderLeft: '3px solid #615CFF' }}
    >
      {showIcon && (
        <Box display={Display.Flex} flexGrow={0} flexShrink={0} flexBasis="1.5rem" paddingRight={2}>
          <Icon name="info" size={5} variant={IconVariant.Actionable} />
        </Box>
      )}
      <Box display={Display.Flex} flexGrow={1} flexDirection={FlexDirection.Column}>
        {title && (
          <Heading6 color={Color.ColdGrey_800} fontWeight={FontWeight.SemiBold}>
            {title}
          </Heading6>
        )}
        {title && <Space size={1} direction="vertical" />}
        <Text
          display={Display.Inline}
          textAlign={TextAlign.Left}
          size={1}
          style={{ lineHeight: 1.7 }}
        >
          {body}
        </Text>
      </Box>
    </Box>
  </Box>
)

CommunicationPanel.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  showIcon: PropTypes.bool,
}
