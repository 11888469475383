import React from 'react'
import {
  AlignItems,
  Box,
  Color,
  Display,
  Display1,
  DisplayPosition,
  FlexDirection,
  FontWeight,
  Heading5,
  Overflow,
  Space,
  Text,
  TextAlign,
  ZIndex,
} from '@gousto-internal/citrus-react'

import { VeganSlides } from '../../../../config'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import topLeftMobileWebp from '../../../../assets/carousel/veggie/mobile/buddah-bowl.webp'
import topLeftMobile1xPng from '../../../../assets/carousel/veggie/mobile/buddah-bowl.png'
import topLeftMobile2xPng from '../../../../assets/carousel/veggie/mobile/buddah-bowl@2x.png'
import topLeftDesktopWebp from '../../../../assets/carousel/veggie/desktop/buddah-bowl.webp'
import topLeftDesktop1xPng from '../../../../assets/carousel/veggie/desktop/buddah-bowl.png'
import topLeftDesktop2xPng from '../../../../assets/carousel/veggie/desktop/buddah-bowl@2x.png'

import topRightMobileWebp from '../../../../assets/carousel/veggie/mobile/veg-pasta.webp'
import topRightMobile1xPng from '../../../../assets/carousel/veggie/mobile/veg-pasta.png'
import topRightMobile2xPng from '../../../../assets/carousel/veggie/mobile/veg-pasta@2x.png'
import topRightDesktopWebp from '../../../../assets/carousel/veggie/desktop/veg-pasta.webp'
import topRightDesktop1xPng from '../../../../assets/carousel/veggie/desktop/veg-pasta.png'
import topRightDesktop2xPng from '../../../../assets/carousel/veggie/desktop/veg-pasta@2x.png'

import bottomRightMobileWebp from '../../../../assets/carousel/veggie/mobile/avo-taco.webp'
import bottomRightMobile1xPng from '../../../../assets/carousel/veggie/mobile/avo-taco.png'
import bottomRightMobile2xPng from '../../../../assets/carousel/veggie/mobile/avo-taco@2x.png'
import bottomRightDesktopWebp from '../../../../assets/carousel/veggie/desktop/avo-taco.webp'
import bottomRightDesktop1xPng from '../../../../assets/carousel/veggie/desktop/avo-taco.png'
import bottomRightDesktop2xPng from '../../../../assets/carousel/veggie/desktop/avo-taco@2x.png'

import bottomLeftMobileWebp from '../../../../assets/carousel/veggie/mobile/mush-curry.webp'
import bottomLeftMobile1xPng from '../../../../assets/carousel/veggie/mobile/mush-curry.png'
import bottomLeftMobile2xPng from '../../../../assets/carousel/veggie/mobile/mush-curry@2x.png'
import bottomLeftDesktopWebp from '../../../../assets/carousel/veggie/desktop/mush-curry.webp'
import bottomLeftDesktop1xPng from '../../../../assets/carousel/veggie/desktop/mush-curry.png'
import bottomLeftDesktop2xPng from '../../../../assets/carousel/veggie/desktop/mush-curry@2x.png'

export const VeganSlide = () => {
  const { titlePart, subTitlePart1, subTitlePart2 } = VeganSlides
  return (
    <Box
      data-testid="vegan-slide"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      minHeight={'610px'}
      position={DisplayPosition.Relative}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
    >
      <Box width={'307px'} className="center" zIndex={ZIndex.Mask}>
        <Space size={18} />
        <Space size={18} />
        <Space size={18} />
        <Heading5
          data-testid="vegan-slide-subtitle"
          color={Color.White}
          fontWeight={FontWeight.Bold}
          textAlign={TextAlign.Center}
        >
          <Text
            style={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
              fontFamily: 'inherit',
              textAlign: 'inherit',
            }}
          >
            {subTitlePart1}
          </Text>
          {subTitlePart2}
        </Heading5>
        <Space size={1} />
        <Display1 data-testid="vegan-slide-title" color={Color.White} textAlign={TextAlign.Center}>
          {titlePart}
        </Display1>
      </Box>
      <Box
        className="top-left"
        position={DisplayPosition.Absolute}
        width={['212px', '200px', '200px', '366px']}
        height={['212px', '200px', '200px', '366px']}
        top={['8px', '30px', '30px', '-82px']}
        left={['12px', 'calc(50% - 132px)', 'calc(50% - 132px)', 'calc(50% - 386px)']}
        style={{
          transform: 'translateX(-50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={topLeftMobileWebp}
          mobile1xPng={topLeftMobile1xPng}
          mobile2xPng={topLeftMobile2xPng}
          desktopWebp={topLeftDesktopWebp}
          desktop1xPng={topLeftDesktop1xPng}
          desktop2xPng={topLeftDesktop2xPng}
          alt="Buddah bowl recipe"
        />
      </Box>
      <Box
        className="top-right"
        position={DisplayPosition.Absolute}
        width={['340px', '328px', '328px', '464px']}
        height={['340px', '328px', '328px', '464px']}
        top={['-120px', '-112px', '-112px', '-207px']}
        right={['-12px', 'calc(50% - 152px)', 'calc(50% - 152px)', 'calc(50% - 286px)']}
        style={{
          transform: 'translateX(50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={topRightMobileWebp}
          mobile1xPng={topRightMobile1xPng}
          mobile2xPng={topRightMobile2xPng}
          desktopWebp={topRightDesktopWebp}
          desktop1xPng={topRightDesktop1xPng}
          desktop2xPng={topRightDesktop2xPng}
          alt="Veggie pasta recipe"
        />
      </Box>
      <Box
        className="bottom-right"
        position={DisplayPosition.Absolute}
        width={['210px', '210px', '210px', '370px']}
        height={['210px', '210px', '210px', '370px']}
        bottom={['94px', '74px', '60px', '-80px']}
        right={['0px', 'calc(50% - 138px)', 'calc(50% - 138px)', 'calc(50% - 364px)']}
        style={{
          transform: 'translateX(50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={bottomRightMobileWebp}
          mobile1xPng={bottomRightMobile1xPng}
          mobile2xPng={bottomRightMobile2xPng}
          desktopWebp={bottomRightDesktopWebp}
          desktop1xPng={bottomRightDesktop1xPng}
          desktop2xPng={bottomRightDesktop2xPng}
          alt="Avocado taco recipe"
        />
      </Box>
      <Box
        className="bottom-left"
        position={DisplayPosition.Absolute}
        width={['300px', '290px', '308px', '580px']}
        height={['300px', '290px', '308px', '580px']}
        bottom={['38px', '46px', '70px', '-320px']}
        left={['-10px', 'calc(50% - 150px)', 'calc(50% - 226px)', 'calc(50% - 256px)']}
        style={{
          transform: 'translateX(-50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={bottomLeftMobileWebp}
          mobile1xPng={bottomLeftMobile1xPng}
          mobile2xPng={bottomLeftMobile2xPng}
          desktopWebp={bottomLeftDesktopWebp}
          desktop1xPng={bottomLeftDesktop1xPng}
          desktop2xPng={bottomLeftDesktop2xPng}
          alt="Mushroom curry recipe"
        />
      </Box>
    </Box>
  )
}
