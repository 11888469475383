import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const fireCheckoutError: CheckoutActionCreator =
  (errorName, errorValue = true) =>
  (dispatch) => {
    dispatch(trackCheckoutError(errorName, errorValue, 'fireCheckoutError'))
    dispatch(statusActions.error(errorName, errorValue))
  }
