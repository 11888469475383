import React from 'react'

import { MenuRecipe } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { SectionPanel } from 'routes/Menu/components/Detail/SectionPanel'

import { Allergens } from '../Allergens/Allergens'
import { IngredientsList } from '../IngredientsList/IngredientsList'

type DetailAllergenIngredientsProps = {
  recipe: MenuRecipe
  loading: boolean
}

export const DetailAllergenIngredients = ({ recipe, loading }: DetailAllergenIngredientsProps) => {
  const { allergens, ingredients } = recipe

  if (loading || ingredients.length === 0) {
    return null
  }

  const allergenNames = allergens?.map((allergen) => allergen.name) ?? []

  return (
    <SectionPanel data-testing="recipeDetailsAllergenList">
      <IngredientsList ingredients={ingredients} allergens={allergenNames} />
      <Allergens allergens={allergenNames} />
    </SectionPanel>
  )
}
