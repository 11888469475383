import React, { useEffect, useState } from 'react'

import { BorderStyle, Box, Color, Display } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { boxPricesClickRecipeNumber } from 'actions/trackingKeys'
import { boxSizes as boxSize } from 'routes/BoxPrices/boxPricesConfig'

import { BoxPricesComparisonSection } from '../BoxPricesComparisonSection/BoxPricesComparisonSection'
import { BoxPricesNumPortionsButton } from '../BoxPricesNumPortionsButton/BoxPricesNumPortionsButton'
import { BoxPricesRecipesButton } from '../BoxPricesRecipesButton/BoxPricesRecipesButton'
import { BoxPricesWithAllPortionsType } from '../boxPricesModels'

export const BoxPricesWithAllPortions = ({
  boxInfo,
  onClickPortionSize,
  activeNumPortion,
  portionSizes,
  activeRecipes,
  setActiveRecipes,
}: BoxPricesWithAllPortionsType) => {
  const activeRecipesLength = boxSize[activeNumPortion].length - 1

  const [selectedRecipeIndex, setSelectedRecipeIndex] = useState(activeRecipesLength)
  const [isBestPrice, setIsBestPrice] = useState(activeRecipesLength)
  const dispatch = useDispatch()

  const dispatchTrackUTMAndPromoCode = (numRecipes: number) =>
    dispatch(
      trackUTMAndPromoCode(boxPricesClickRecipeNumber, {
        box_size: activeNumPortion,
        number_of_recipes: numRecipes,
      }),
    )
  const handleOnClickOfRecipes = (index: number, recipe: number) => {
    dispatchTrackUTMAndPromoCode(recipe)
    setSelectedRecipeIndex(index)
    setActiveRecipes(index)
  }

  useEffect(() => {
    if (activeNumPortion) {
      setSelectedRecipeIndex(activeRecipesLength)
      setIsBestPrice(activeRecipesLength)
    }
  }, [activeNumPortion, activeRecipesLength])

  return (
    <Box
      borderStyle={BorderStyle.Solid}
      borderColor={Color.ColdGrey_200}
      borderWidth={0.5}
      flexGrow={1}
      maxWidth={['100%', '640px']}
      paddingV={4}
      paddingH={[3, 5]}
      bg={Color.White}
      display={Display.Block}
    >
      <BoxPricesNumPortionsButton
        activeNumPortion={activeNumPortion}
        portionSizes={portionSizes}
        onClickPortionSize={onClickPortionSize}
      />
      <BoxPricesRecipesButton
        activeNumPortion={activeNumPortion}
        activeRecipes={activeRecipes}
        handleOnClickOfRecipes={handleOnClickOfRecipes}
      />
      <BoxPricesComparisonSection
        selectedRecipeIndex={selectedRecipeIndex}
        isBestPrice={isBestPrice}
        activeNumPortion={activeNumPortion}
        activeRecipes={activeRecipes}
        boxInfo={boxInfo}
      />
    </Box>
  )
}
