import { connect } from 'react-redux'
import { getAccessToken } from 'selectors/auth'
import { getBrowserType } from 'selectors/browser'
import { getIsSubscriberPricingEnabled } from 'selectors/features'
import { isMobile } from 'utils/view'
import { startOnScreenRecoverySubscriptionFlow, selectPauseReason } from 'actions/onScreenRecovery'
import { getShouldClosePauseReasonsView } from 'selectors/subscription'

import { Subscription } from './Subscription'

const mapStateToProps = (state) => ({
  accessToken: getAccessToken(state),
  isMobile: isMobile(getBrowserType(state)),
  isSubscriberPricingEnabled: getIsSubscriberPricingEnabled(state),
  shouldClosePauseReasonsView: getShouldClosePauseReasonsView(state),
})

const SubscriptionContainer = connect(mapStateToProps, {
  startOnScreenRecoverySubscriptionFlow,
  selectPauseReason,
})(Subscription)

export { SubscriptionContainer }
