import React from 'react'

import { Box, Display, Body2, Bold } from '@gousto-internal/citrus-react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { DeliveryCard } from 'routes/Checkout/Components/Delivery/DeliveryCard'
import { getBasketDate, getBasketSlotId } from 'selectors/basket'
import { getSlotTimes } from 'utils/deliveries'

export const DeliveryInfoCard = () => {
  const date = useSelector(getBasketDate)
  const deliveryDays = useSelector((state) => (state as any).boxSummaryDeliveryDays)
  const slotId = useSelector(getBasketSlotId)

  const deliveryDate = moment(date).format('dddd Do MMMM')
  const deliveryTime = getSlotTimes({ date, deliveryDays, slotId })

  return (
    <DeliveryCard iconName="icon-calendar" cardStyle="blue">
      <Box display={Display.InlineFlex}>
        <Body2>
          Your selected delivery day is <Bold>{deliveryDate}, </Bold>
          <Body2 display={Display.Inline}>{deliveryTime.toUpperCase()}</Body2>
        </Body2>
      </Box>
    </DeliveryCard>
  )
}
