import Immutable from 'immutable'
import { useSelector } from 'react-redux'
import { useLegacyCurrentMenuRecipes } from '../recipes'

type Recipe = {
  core_recipe_id: string
  meta: {
    portion_sizes_available: number[]
  }
}

type MenuService = {
  data: {
    relationships: {
      recipes: {
        data: Recipe[]
      }
    }
  }[]
}

const isOutOfStock = (recipeId: string, numPortions: number, menuService: MenuService) => {
  const menuRecipe = menuService?.data?.[0].relationships.recipes.data.find(
    (recipe) => recipe.core_recipe_id === recipeId,
  )
  return !menuRecipe?.meta.portion_sizes_available?.includes(numPortions)
}

const getMenuService = (state: any) => state.menuService
const getBasketRecipes = (state: any) => state.basket.get('recipes', Immutable.List([]))
const isRecipeInBasket = (
  recipe: Immutable.Map<string, any>,
  basketRecipes: Immutable.Map<string, number>,
) => basketRecipes.has(recipe.get('id'))

export function useLegacyInStockRecipeIds({ numPortions }: { numPortions: number }): Set<string> {
  const menuService = useSelector(getMenuService)
  const basketRecipes = useSelector(getBasketRecipes)
  const recipes = useLegacyCurrentMenuRecipes()

  const inStock = recipes.filter(
    (recipe) =>
      !!recipe &&
      (!isOutOfStock(recipe.get('id'), numPortions, menuService) ||
        isRecipeInBasket(recipe, basketRecipes)),
  )

  return new Set(inStock.map((r) => r?.get('id')).toArray())
}
