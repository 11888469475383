import React from 'react'
import {
  Box,
  Display,
  Icon,
  IconVariant,
  Body1,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'
import { PEEK_BOX_TEXT } from '../PeekInTheBox.config'

export const PeekInTheBoxText = () => (
  <Body1 textAlign={TextAlign.Center} size={3} style={{ padding: '1rem' }}>
    {PEEK_BOX_TEXT.bullets.map((bullet, index) => (
      <React.Fragment key={index}>
        <Box display={Display.Flex}>
          <Icon name="tick" variant={IconVariant.Confirmation} />
          <Space direction="horizontal" size={3} />
          <Text size={2}>{bullet} </Text>
        </Box>
        <Space direction="vertical" size={5} />
      </React.Fragment>
    ))}
  </Body1>
)
