import { Domain, PROTOCOL_PREFIX, canUseWindow } from '@library/environment'
import { getEnvironment, getDomain, isDev } from '@library/environment/isomorphic'

import type { ServiceEnvironment, ServiceName, ServiceUrlProperties } from './types'

import { getServiceManifest } from './service-manifest'
import { findServiceVersion } from './find-service-version'
import { serviceUrl } from './service-url'

export const buildServiceUrlProperties = (
  serviceName: ServiceName,
  majorVersion: number,
  serviceEnvironment: ServiceEnvironment,
  basePath?: string,
): ServiceUrlProperties => ({
  ...serviceEnvironment,
  serviceName,
  majorVersion,
  basePath,
})

function getServiceUrl(
  serviceName: ServiceName,
  version: number,
  serviceEnvironment: ServiceEnvironment,
) {
  const { basePath } = findServiceVersion(serviceName, version, getServiceManifest())
  const isPublic = canUseWindow() || isDev()

  return serviceUrl(
    buildServiceUrlProperties(
      serviceName,
      version,
      serviceEnvironment,
      isPublic ? basePath : undefined,
    ),
  )
}

function endpointAdapter(getServiceEnvironment: () => ServiceEnvironment) {
  const cache = new Map<string, string>()

  // signature as per previous implementation
  return function endpoint(serviceName: ServiceName, version = 1): string {
    const serviceEnvironment = getServiceEnvironment()
    const serviceKey = `${serviceEnvironment.protocol}//${serviceEnvironment.environmentName}.${serviceEnvironment.serviceDomain}/${serviceName}/${version}`

    if (cache.has(serviceKey)) {
      return cache.get(serviceKey) as string
    }

    cache.set(serviceKey, getServiceUrl(serviceName, version, serviceEnvironment))

    return cache.get(serviceKey) as string
  }
}

const getServiceEnvironment = (): ServiceEnvironment => ({
  environmentName: getEnvironment(),
  protocol: canUseWindow() ? PROTOCOL_PREFIX.HTTPS : PROTOCOL_PREFIX.HTTP,
  serviceDomain: getDomain() as Domain,
})

export const endpoint = endpointAdapter(getServiceEnvironment)
