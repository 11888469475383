import React, { FC, useState } from 'react'
import { StarEmpty } from './StarEmpty'
import { StarFilled } from './StarFilled'

import { AlignItems, Box, useTheme } from '@gousto-internal/citrus-react'

type Star = {
  id: string
  isFilled: boolean
  rating: number
}

type RatingProps = {
  total?: number
  rating?: number
  onChange?: (newRating: number, previousRating: number) => void
}

export const Rating: FC<RatingProps> = ({ rating = 0, onChange }) => {
  const { theme } = useTheme()
  const [currentRating, setCurrentRating] = useState(rating)
  const stars = Array.from({ length: 5 }, (_, index: number) => {
    return {
      id: `star-${index}`,
      isFilled: index + 1 <= currentRating,
      rating: index + 1,
    }
  })

  const handleStarClick = (newRating: number) => {
    setCurrentRating(newRating)

    if (onChange) {
      onChange(newRating, currentRating)
    }
  }
  const isRated = currentRating > 0

  return (
    <Box
      display="flex"
      alignItems={AlignItems.Center}
      role="radiogroup"
      aria-label={
        isRated
          ? `rating stars. selected score is ${currentRating} of 5`
          : 'rating stars. not selected yet'
      }
      style={{
        justifyContent: isRated ? 'left' : 'space-evenly',
        marginTop: isRated ? theme.spacing(0) : theme.spacing(6),
        marginBottom: isRated ? theme.spacing(2) : theme.spacing(12),
        transform: isRated ? 'scale(0.6)' : 'scale(1)',
        transformOrigin: '0 0',
        transition: 'all 0.3s ease-out',
      }}
    >
      {stars.map((star: Star) => {
        return star.isFilled ? (
          <StarFilled
            key={star.id}
            score={star.rating}
            activeStar={star.rating === currentRating}
            onClick={() => {
              handleStarClick(star.rating)
            }}
            onKeyPress={() => {
              handleStarClick(star.rating)
            }}
          />
        ) : (
          <StarEmpty
            key={star.id}
            score={star.rating}
            onClick={() => {
              handleStarClick(star.rating)
            }}
            onKeyPress={() => {
              handleStarClick(star.rating)
            }}
          />
        )
      })}
    </Box>
  )
}
