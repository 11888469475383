import { useSelector } from 'react-redux'

import { Recipe } from '@features/recipe-preferences/src/types/recipe'

import { getMenuRecipes, getSignupDietaryPreferences, getStoreRecipes } from '../signupSelectors'
import { getPreferredRecipes } from '../utils/helpers/recipeFiltering/dietaryBasedRecipes'
import { NUMBER_OF_REQUIRED_RECIPES } from '../utils/helpers/recipeFiltering/dietaryBasedRecipesConfig'

export const useRecipesList = () => {
  const storeRecipesRaw = useSelector(getStoreRecipes)
  const storeRecipes = storeRecipesRaw ? storeRecipesRaw.toJS() : {}

  const menuRecipesRaw = useSelector(getMenuRecipes)
  const recipesIds = menuRecipesRaw ? menuRecipesRaw.toArray() : []
  const recipes = recipesIds.map((recipeId: string) => storeRecipes[recipeId])

  const dietaryPreferencesRaw = useSelector(getSignupDietaryPreferences)
  const dietaryPreferences = dietaryPreferencesRaw ? dietaryPreferencesRaw.toArray() : []

  const numberOfRequiredRecipes = NUMBER_OF_REQUIRED_RECIPES

  const preferredRecipes = getPreferredRecipes(
    recipes,
    dietaryPreferences,
    numberOfRequiredRecipes,
  ) as unknown as Recipe[]

  return preferredRecipes
}
