import React, { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import {
  Modal,
  ModalHeader,
  TextAlign,
  Heading6,
  useModal,
  ModalBody,
} from '@gousto-internal/citrus-react'
import { createShippingAddress } from '@library/api-user'
import { AddressForm } from './AddressForm'
import { ApiKeys } from '../../enums'

const CreateAddressModal = ({ isOpen }: { isOpen?: boolean } = { isOpen: false }) => {
  const { mutate } = useSWRConfig()
  const { closeCurrentModal } = useModal()
  const onFormSubmit = useCallback(
    async (values) => {
      await createShippingAddress(values)
      await mutate(ApiKeys.UserCurrentShippingAddress)
      closeCurrentModal()
    },
    [mutate, closeCurrentModal],
  )

  return (
    <Modal name="CreateAddressModal" preventScroll isOpen={isOpen}>
      <ModalHeader>
        <Heading6 textAlign={TextAlign.Left}>Create new address</Heading6>
      </ModalHeader>
      {/* https://github.com/Gousto/citrus/issues/393 */}
      <ModalBody>
        <AddressForm onSubmit={onFormSubmit} onCancel={closeCurrentModal} />
      </ModalBody>
    </Modal>
  )
}

export { CreateAddressModal }
