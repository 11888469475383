import { useContext } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { useSelector } from 'react-redux'

import { getIsAuthenticated } from 'selectors/auth'
import { getPromoCode } from 'selectors/basket'
import Cookies from 'utils/GoustoCookies'
import logger from 'utils/logger'

export type DiscountSofteningFreeDeliveryTreatments = 't1' | 't2' | 't3'

function isTreatmentKey(key: unknown): key is DiscountSofteningFreeDeliveryTreatments {
  return typeof key === 'string' && ['t1', 't2', 't3'].includes(key)
}

export function hasConsentForAnalytics(): boolean {
  try {
    const consentCookieString = Cookies.get('v1_gousto_cookie_consent')
    const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}

    return consentCookie?.categories?.includes('ANALYTICS') || false
  } catch (error) {
    if (error instanceof Error) {
      ;(logger as any).error(`Error parsing consent cookie: ${error.message}`)
    }

    return false
  }
}

export function useGetDiscountSofteningFreeDelivery():
  | [true, DiscountSofteningFreeDeliveryTreatments]
  | [false, undefined] {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const { optimizely } = useContext(OptimizelyContext)
  const promoCode = useSelector(getPromoCode)

  const hasNoPromoCodeStatus = !promoCode
  const hasConsentForAnalyticsStatus = hasConsentForAnalytics()
  const hasCorrectAuthStatus = !isAuthenticated

  const isEligibleForExperiment =
    optimizely && hasConsentForAnalyticsStatus && hasCorrectAuthStatus && hasNoPromoCodeStatus

  const { enabled, variationKey } = isEligibleForExperiment
    ? (() => {
        datadogLogs.logger.info(`User with promo code ${promoCode} is eligible for the experiment`)

        return optimizely.decide('beetroots_ofx_discount_softening_free_delivery')
      })()
    : { enabled: false, variationKey: undefined }

  if (enabled && isTreatmentKey(variationKey)) {
    return [true, variationKey]
  } else {
    return [false, undefined]
  }
}
