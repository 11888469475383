import { basketCheckedOut, basketCheckoutClicked, basketProceedToCheckout } from 'actions/basket'
import { boxSummaryVisibilityChange } from 'actions/boxSummary'
import { orderUpdate } from 'actions/order'
import { checkoutTransactionalOrder as checkoutTransactionalOrderV1 } from 'routes/Checkout/checkoutActions'
import { sendUpdateOrder } from 'routes/Menu/actions/order'
import { getIsAuthenticated } from 'selectors/auth'
import { isBasketTransactionalOrder } from 'selectors/basket'

import { checkoutTransactionalOrder } from './checkoutTransactionalOrder'

export const isOrderApiCreateEnabled = async () => true
export const isOrderApiUpdateEnabled = async () => false

export const checkoutBasket =
  ({ section, view, pricing, removeRecipe }) =>
  async (dispatch, getState) => {
    const state = getState()
    const isAuthenticated = getIsAuthenticated(state)
    const isTransactionalOrder = isBasketTransactionalOrder(state)
    const transactionalOrderForNonLoggedInUser = isTransactionalOrder && !isAuthenticated

    dispatch(boxSummaryVisibilityChange(false, removeRecipe))
    dispatch(basketCheckedOut({ view, pricing }))
    dispatch(basketCheckoutClicked(section))

    if (transactionalOrderForNonLoggedInUser) {
      dispatch(basketProceedToCheckout())

      return
    }

    if (isTransactionalOrder) {
      const isCreateV2Enabled = await isOrderApiCreateEnabled(dispatch, getState)

      if (isCreateV2Enabled) {
        dispatch(checkoutTransactionalOrder())
      } else {
        dispatch(checkoutTransactionalOrderV1('create'))
      }

      return
    }

    const isUpdateV2Enabled = await isOrderApiUpdateEnabled(dispatch, getState)

    if (isUpdateV2Enabled) {
      dispatch(sendUpdateOrder())
    } else {
      dispatch(orderUpdate())
    }
  }
