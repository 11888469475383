import React from 'react'

import classnames from 'classnames'

import { formatSurcharge } from '../../../utils/formatSurcharge'

import css from './RecipeDetailsPageOptionItem.css'

type ItemContentProps = {
  recipeName: string
  isOutOfStock: boolean
  surcharge?: number | null
  isChecked?: boolean
}

export const ItemContent = ({
  recipeName,
  isOutOfStock,
  surcharge = null,
  isChecked,
}: ItemContentProps) => (
  <div className={css.labelContainer}>
    <div className={css.titleContainer}>
      <span className={isChecked ? css.titleTextActive : css.titleText}>{recipeName}</span>
    </div>
    {surcharge && !isOutOfStock && (
      <div className={classnames(css.extraInformation, css.negativeMargin)}>
        <span className={css.surchargeAmountText}>{formatSurcharge(surcharge)}</span>
        <span className={css.perServingText}>per serving</span>
      </div>
    )}
    {isOutOfStock && <span className={css.soldOutText}>Sold out</span>}
  </div>
)
