import React from 'react'

import { Heading4, Strong, Body3, Space } from '@gousto-internal/citrus-react'

import menu from 'config/menu'

type AllergensProps = {
  allergens: string[]
}

const replaceGluten = (allergens: string[]) =>
  allergens.map((a) => (a === 'gluten' ? 'cereals containing gluten' : a))

export const allergenNamesInParentheses = (allergens: string[]) => {
  const allergenString = replaceGluten(allergens).join(', ')
  const allergenText = `(${allergenString})`

  return allergenText
}

export const Allergens = ({ allergens }: AllergensProps) => (
  <>
    <Heading4>Allergens</Heading4>
    <Space size={4} />
    {allergens.length > 0 ? (
      <Body3 data-testing="allergen">
        For allergens, see ingredients in
        <Strong> BOLD. </Strong>
        <Strong>{allergenNamesInParentheses(allergens)}</Strong>
      </Body3>
    ) : null}
    <Body3>{menu.legal}</Body3>
  </>
)
