import * as React from 'react'
import { Body1, Button, ButtonVariant, Link } from '@gousto-internal/citrus-react'

import { getCookieForcedDecisions, tomorrow, setForcedDecisionCookie } from '../forcedDecisions'
import { CollapsedView, Details, OverlayContainer } from './components'
import { CookiesSource } from '../../types'

const HIDE_OVERLAY_COOKIE = 'v1_hide_force_decisions_overlay'

function getCookieVisibility(cookiesSource: CookiesSource) {
  const cookieValue = cookiesSource.get(HIDE_OVERLAY_COOKIE)

  return cookieValue === undefined
}

function setCookieVisibility(cookiesSource: CookiesSource) {
  cookiesSource.set(HIDE_OVERLAY_COOKIE, JSON.stringify(true), { expires: tomorrow() })
}

type ForcedDecisionsOverlayProps = {
  cookiesSource: CookiesSource
}

export function ForcedDecisionsOverlay(props: ForcedDecisionsOverlayProps) {
  const { cookiesSource } = props

  const [areDetailsExpanded, setDetailsExpanded] = React.useState(false)
  const [shouldRender, setShouldRender] = React.useState(getCookieVisibility(cookiesSource))

  function hideOverlay() {
    setCookieVisibility(cookiesSource)
    setShouldRender(false)
  }

  function handleDetailsVisibility() {
    setDetailsExpanded(!areDetailsExpanded)
  }

  function resetCookieAndClose() {
    setForcedDecisionCookie([], cookiesSource)
    setShouldRender(false)
  }

  const forcedDecisions = getCookieForcedDecisions(cookiesSource)
  if (!forcedDecisions.length) return null

  return shouldRender ? (
    <OverlayContainer>
      <CollapsedView>
        <Body1>OFX - forced decisions enabled</Body1>
        <Link onClick={handleDetailsVisibility}>
          {areDetailsExpanded ? 'Hide details' : 'Details'}
        </Link>
        <Button variant={ButtonVariant.Icon} icon="close" onClick={hideOverlay}>
          Close overlay
        </Button>
      </CollapsedView>

      {areDetailsExpanded ? (
        <Details decisions={forcedDecisions} resetCookieAndClose={resetCookieAndClose} />
      ) : null}
    </OverlayContainer>
  ) : null
}
