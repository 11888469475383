import React, { FunctionComponent } from 'react'
import { SubmitFeedback } from '../SubmitFeedback'
import { OTIFList } from '../OTIFList'

interface Props {
  selectedItems: string[]
  isSubmitting: boolean
  submitted: boolean
  comment: string
  onOptionSelected: (selected: boolean, value: string) => void
  onCommentUpdate: (comment: string) => void
  onSubmit: () => void
}

export const NegativeFlow: FunctionComponent<Props> = (props) => {
  const {
    onOptionSelected,
    selectedItems,
    isSubmitting,
    onCommentUpdate,
    submitted,
    comment,
    onSubmit,
  } = props

  return (
    <>
      <OTIFList
        title="We'd love to improve this recipe, what was wrong?"
        selectedOTIFs={selectedItems}
        onOTIFSelected={onOptionSelected}
      />
      <SubmitFeedback
        onSubmit={onSubmit}
        title="Anything else you'd like to tell us?"
        submitting={isSubmitting}
        submitted={submitted}
        comment={comment}
        setComment={onCommentUpdate}
      />
    </>
  )
}
