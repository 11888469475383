import React, { SyntheticEvent, useRef } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  Divider,
  Icon,
  IconVariant,
  JustifyContent,
  Text,
} from '@gousto-internal/citrus-react'

import ModalComponent, { ModalContent, ModalTitle } from 'components/ModalComponent'

import { useClickOutside } from '../RecipeTile/SwapAlternativeOptions/useClickOutside'
import { MenuPreferencesOptions } from './MenuPreferencesOptions'

import css from './MenuPreferencesMobile.css'

type MenuPreferencesMobileProps = {
  onClose: (event?: SyntheticEvent) => void
}

export const MenuPreferencesMobile = ({ onClose }: MenuPreferencesMobileProps) => {
  const selectRef = useRef(null)
  useClickOutside(selectRef, onClose, [])

  return (
    <ModalComponent
      from="bottom"
      onClose={onClose}
      overlayContentClassName={css.menuPreferencesOverlayContent}
      styleName={css.menuPreferencesModal}
      visible
    >
      <Box data-testid="menu-preferences-mobile" ref={selectRef}>
        <ModalTitle>
          <Box
            alignItems={AlignItems.Center}
            display={Display.Flex}
            justifyContent={JustifyContent.SpaceBetween}
            paddingH={4}
            paddingV={2}
          >
            <Text color={Color.ColdGrey_800} fontWeight={700} size={7}>
              Menu Settings
            </Text>
            <Icon
              name="close"
              size={6}
              onClick={onClose}
              onKeyDown={onClose}
              role="button"
              variant={IconVariant.Actionable}
            >
              Close menu settings
            </Icon>
          </Box>
        </ModalTitle>
        <Divider />
        <Box paddingH={4}>
          <ModalContent>
            <MenuPreferencesOptions onClose={onClose} variant="mobile" />
          </ModalContent>
        </Box>
      </Box>
    </ModalComponent>
  )
}
