import React from 'react'

import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  FontFamily,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import { IconLineItem } from './IconLineItem'

export const DeliveryTips = () => (
  <Box display={Display.Flex} flexDirection={FlexDirection.Column} data-testid="DeliveryTips">
    <IconLineItem iconName="time">
      <Text size={2}>
        Receive a{' '}
        <Text size={2} display={Display.Inline} fontFamily={FontFamily.Bold}>
          2-hour delivery slot on the day of delivery via text and email.{' '}
        </Text>
        Delivery will be between 8am - 7pm.
      </Text>
    </IconLineItem>
    <Space size={2} />
    <IconLineItem iconName="cool">
      <Text size={2}>
        Insulated packaging keeps your ingredients{' '}
        <Text size={2} display={Display.Inline} fontFamily={FontFamily.Bold}>
          fresh for up to 12 hours.
        </Text>
      </Text>
    </IconLineItem>
    <Space size={2} />
    <IconLineItem iconName="play_pause" align={AlignItems.Center}>
      <Text size={2} fontFamily={FontFamily.Bold}>
        No lock in:{' '}
        <Text size={2} display={Display.Inline}>
          skip or cancel for free any time
        </Text>
      </Text>
    </IconLineItem>
  </Box>
)
