import React, { useState, useCallback, useEffect, useMemo } from 'react'
import useSWR from 'swr'
import {
  Accordion,
  AlignItems,
  BorderStyle,
  Box,
  Button,
  ButtonSize,
  Color,
  Display,
  Join,
  Body1,
  RadioGroup,
  Space,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import {
  fetchUserShippingAddresses,
  ShippingAddress,
  updateUserDefaultAddress,
} from '@library/api-user'

import { ApiKeys } from '../../enums'
import { RenderedTitle } from '../RenderedTitle'

import { AddNewAddressButton } from './AddNewAddressButton'
import { AddressRadioLabel } from './AddressRadioLabel'
import { AddressRadioButton } from './AddressRadioButton'
import { DeleteAddressModal } from './DeleteAddressModal'
import { DefaultDeliveryAddressUpdatedModal } from './DefaultDeliveryAddressUpdatedModal'
import { EditAddressModal } from './EditAddress/EditAddressModal/EditAddressModal'
import { EditAddressButton } from './EditAddress/EditAddressButton/EditAddressButton'
import { DeleteAddressButton } from './AddressRadioLabel/DeleteAddressButton'

import type { AddressID } from '@library/api-user'

import './DeliveryInfoSection.css'

const initialState = {
  addresses: [],
  error: '',
  editOrDeleteAddressId: undefined,
  selectedAddress: '' as AddressID,
}

function DeliveryInfoSection() {
  const { mutate, data: addresses = initialState.addresses } = useSWR(
    ApiKeys.UserCurrentShippingAddress,
    fetchUserShippingAddresses,
  )
  const { openModal } = useModal()
  const defaultShippingAddress = addresses.find((address) => address.shippingDefault)
  const hasOnlyOneShippingAddress = addresses.length === 1
  const hasMoreThanOneShippingAddress = addresses.length > 1

  const [editOrDeleteAddressId, setEditOrDeleteId] = useState<AddressID | undefined>(
    initialState.editOrDeleteAddressId,
  )
  const [errorMessage, setErrorMessage] = useState<string>(initialState.error)
  const [selectedAddressId, setSelectedAddressId] = useState<AddressID>(
    initialState.selectedAddress,
  )

  const addressToEditOrDelete = useMemo(() => {
    return addresses.find((address) => address.id === editOrDeleteAddressId)
  }, [editOrDeleteAddressId, addresses])

  const onAccordionChange = useCallback(
    async (isExpanded) => {
      if (isExpanded) {
        return
      }
      if (defaultShippingAddress?.id) {
        setSelectedAddressId(defaultShippingAddress?.id)
      }
    },
    [defaultShippingAddress?.id],
  )

  useEffect(() => {
    defaultShippingAddress?.id && setSelectedAddressId(defaultShippingAddress?.id)
  }, [defaultShippingAddress?.id])

  const handleSetDefault = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault()

      try {
        await updateUserDefaultAddress({ addressId: selectedAddressId })
        await mutate()
        openModal('DefaultDeliveryAddressUpdatedModal')
      } catch (error: Error | unknown) {
        setErrorMessage(`Sorry, we couldn’t process your request right now. Please try again.`)
      }
    },
    [mutate, selectedAddressId, openModal],
  )

  const handleRadio = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    setSelectedAddressId(target.value as AddressID)
  }, [])

  const handleEditOrDeleteAddress = useCallback((addressId: AddressID) => {
    return () => {
      setEditOrDeleteId(addressId)
    }
  }, [])

  const getRadioLabels = useCallback(
    (renderAddress: ShippingAddress, isFocused: boolean) => {
      return (
        <AddressRadioLabel address={renderAddress} focused={isFocused}>
          <Join with={<Space direction="horizontal" size={2} />}>
            <Box display={Display.Flex} alignItems={AlignItems.Center}>
              <EditAddressButton onClick={handleEditOrDeleteAddress(renderAddress.id)} />
            </Box>
            <Box display={Display.Flex} alignItems={AlignItems.Center}>
              <DeleteAddressButton
                address={renderAddress}
                onClick={handleEditOrDeleteAddress(renderAddress.id)}
              />
            </Box>
          </Join>
        </AddressRadioLabel>
      )
    },
    [handleEditOrDeleteAddress],
  )

  const options = useMemo(() => {
    return addresses.map((address) => {
      const isFocused = selectedAddressId === address.id

      return {
        name: 'shipping-address',
        value: address.id,
        label: () => getRadioLabels(address, isFocused),
      }
    })
  }, [addresses, getRadioLabels, selectedAddressId])

  const sortedOptions = useMemo(() => {
    return options.sort((option) => {
      if (option.value === defaultShippingAddress?.id) {
        return -1
      }

      return 0
    })
  }, [defaultShippingAddress, options])

  return (
    <Accordion
      id="delivery-info-accordion"
      title={(isExpanded: boolean) => (
        <div data-testid="accordionDeliveryInfoTitle">
          <RenderedTitle isExpanded={isExpanded} title="Delivery address" iconType="delivery">
            <>
              <Text size={2} color={Color.ColdGrey_600}>
                {defaultShippingAddress?.name}
              </Text>
              <Text size={1} color={Color.ColdGrey_600}>
                {`${defaultShippingAddress?.line1}, ${defaultShippingAddress?.postcode}`}
              </Text>
            </>
          </RenderedTitle>
        </div>
      )}
      onChange={onAccordionChange}
    >
      <Join with={<Space size={2} />}>
        <form onSubmit={handleSetDefault}>
          {/* This will be refactored as part of https://gousto.atlassian.net/browse/ROC-2635 */}
          <Body1>
            <Space size={2} direction="vertical" />
            <Text size={2}>Select your default delivery address:</Text>
            <Space size={2} direction="vertical" />
          </Body1>
          {errorMessage && (
            <Box /* Citrus: https://github.com/Gousto/citrus/issues/267 */
              bg={Color.Warning_50}
              borderColor={Color.Error_900}
              borderWidth={1}
              borderStyle={BorderStyle.Solid}
              paddingH={4}
              paddingV={4}
              width="100%"
            >
              {errorMessage}
            </Box>
          )}
          {hasOnlyOneShippingAddress && defaultShippingAddress && (
            <AddressRadioButton
              address={defaultShippingAddress}
              isChecked={true}
              onChange={handleRadio}
            >
              {getRadioLabels(defaultShippingAddress, true)}
            </AddressRadioButton>
          )}
          {hasMoreThanOneShippingAddress && defaultShippingAddress && (
            <RadioGroup
              value={selectedAddressId}
              defaultValue={defaultShippingAddress.id}
              outline
              options={sortedOptions}
              onChange={handleRadio}
            />
          )}
          <Box paddingV={4}>
            <Button
              disabled={
                selectedAddressId === defaultShippingAddress?.id || hasOnlyOneShippingAddress
              }
              size={ButtonSize.Medium}
              type="submit"
              width="100%"
            >
              Set default address
            </Button>
          </Box>
        </form>
        <Text size={2}>Don’t see an address you want to use?</Text>
        <AddNewAddressButton />
      </Join>
      <EditAddressModal address={addressToEditOrDelete} />
      <DeleteAddressModal address={addressToEditOrDelete} />
      <DefaultDeliveryAddressUpdatedModal address={defaultShippingAddress} />
    </Accordion>
  )
}

export { DeliveryInfoSection }
