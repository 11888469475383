import React from 'react'

import { Route, IndexRoute, Redirect } from 'react-router'

import { FullPage } from 'layouts/FullPage'

import { HomePageContent } from './HomePageContent'

export const Home = (
  <Route component={FullPage} props={{ footerType: 'large', footerIsOnLandingPage: true }}>
    <IndexRoute component={HomePageContent} />
    <Redirect from="/home" to="/" />
  </Route>
)
