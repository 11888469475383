import humanTimeFormat from 'utils/timeFormat'
import moment from 'moment'
import { createSelector } from 'reselect'
import { getUserNewOrders } from 'selectors/user'
// eslint-disable-next-line import/no-unresolved
import { formatDeliveryPrice } from 'utils/deliveryPrice'

const markDeliveryDaysAndSlotsWithoutStock = (orderDeliveryDays, orderRecipes, indexedRecipeAvailability, portionsPerRecipe) => {
  const recipeUuids = orderRecipes.toJS().map((recipe) => recipe.recipeUuid)
  const days = orderDeliveryDays.toJS()

  Object.entries(days).forEach(([dayIndex, day]) => {
    day.slots.forEach((_, slotIndex) => {
      if (!recipeUuids.every((recipeUuid) => indexedRecipeAvailability[day.date]?.[recipeUuid]?.portions[portionsPerRecipe]?.available)) {
        days[dayIndex].slots[slotIndex].noStock = true
      }
    })
    if (day.slots.every(slot => slot.noStock)) {
      days[dayIndex].noStock = true
    }
  })

  return days
}

const getTakenDatesIds = (orderDeliveryDays, orders, orderCoreDeliveryDayId) => {
  const takenDatesIds = []
  orders
    .filter(_order =>
      _order.get('coreDeliveryDayId') !== orderCoreDeliveryDayId
      && orderDeliveryDays.some(orderDay => orderDay.get('coreDayId') === _order.get('coreDeliveryDayId'))
    )
    .valueSeq().forEach(_order => {
      takenDatesIds.push(_order.get('coreDeliveryDayId'))
    })

  return takenDatesIds
}

const getOrderId = (state, editDateProps) => editDateProps.orderId
export const filterOutNDDOptions = createSelector(
  [getOrderId, getUserNewOrders],
  (orderId, orders) => {
    const order = orders.get(orderId.toString())
    const deliveryDays = order.get('availableDeliveryDays')

    if (!deliveryDays) {
      return deliveryDays
    }

    // Remove NDD day options
    return deliveryDays.map(day => {
      const filteredSlots = day.get('slots').filter(slot => !slot.get('daySlotLeadTimeIsExpress'))

      return day.set('slots', filteredSlots)
    })
      .filter(day => day.get('slots').count() > 0)
  }
)

const getDeliveryDaysAndSlotsOptions = (orderDeliveryDays, orderRecipes, indexedRecipeAvailability, portionsPerRecipe, orderCoreDeliveryDayId, orderDeliverySlotId, orders) => {
  const takenDatesIds = getTakenDatesIds(orderDeliveryDays, orders, orderCoreDeliveryDayId)
  const days = markDeliveryDaysAndSlotsWithoutStock(orderDeliveryDays, orderRecipes, indexedRecipeAvailability, portionsPerRecipe)
  const daysWithStock = Object.entries(days).filter(([, day]) =>
    (!day.noStock && day.alternateDeliveryDay === null) || day.coreDayId === orderCoreDeliveryDayId
  )
  const deliveryDaysOptions = []
  deliveryDaysOptions.push(...daysWithStock.map(([, day]) => {
    const isDateTaken = takenDatesIds.some(takenDate => takenDate === day.coreDayId)

    return {
      value: day.coreDayId,
      label: moment(day.date).format('ddd D MMM'),
      date: day.date,
      disabled: isDateTaken,
      icon: isDateTaken ? 'full-box' : '',
    }
  }))
  const slotsOptions = {}

  daysWithStock.forEach(([, day]) => {
    slotsOptions[day.coreDayId] = []
    slotsOptions[day.coreDayId].push(...day.slots
      .filter(slot => !slot.noStock || (slot.id === orderDeliverySlotId && day.coreDayId === orderCoreDeliveryDayId))
      .map(slot => (
        {
          value: slot.coreSlotId,
          uuid: slot.id,
          label: `${humanTimeFormat(slot.deliveryStartTime, 'hour')} - ${humanTimeFormat(slot.deliveryEndTime, 'hour')}`,
          subLabel: formatDeliveryPrice(slot.deliveryPrice),
          isDefaultSlot: slot.isDefault,
        }
      ))
    )
  })

  return { deliveryDaysOptions, slotsOptions }
}

/**
 * Due to the size and complexity of the EditDate component, this class method was lifted and placed here instead.
 */
function getDayAndSlotOptionsAndSelected(deliveryDays, availabilityForDateRange, coreDeliveryDayId, deliverySlotId, recipes, portionsCount, orders) {
  const recipeAvailabilityIndexedByDate = Object.values(availabilityForDateRange).reduce((acc, recipeAvailability) => ({
    ...acc,
    [recipeAvailability.deliveryDate]: {
      ...acc[recipeAvailability.deliveryDate],
      [recipeAvailability.recipeId]: recipeAvailability,
    }
  }), {})
  const { deliveryDaysOptions, slotsOptions } = getDeliveryDaysAndSlotsOptions(deliveryDays, recipes, recipeAvailabilityIndexedByDate, portionsCount, coreDeliveryDayId, deliverySlotId, orders)
  let selectedDeliveryDayId = coreDeliveryDayId
  let selectedDeliverySlotId = deliverySlotId

  if (!deliveryDaysOptions.some(option => option.value === selectedDeliveryDayId)) {
    selectedDeliveryDayId = deliveryDaysOptions[0] ? deliveryDaysOptions[0].value : ''
  }
  if (!slotsOptions[selectedDeliveryDayId]
    || !slotsOptions[selectedDeliveryDayId].some(option => option.value === selectedDeliverySlotId)) {
    selectedDeliverySlotId = slotsOptions[selectedDeliveryDayId] && slotsOptions[selectedDeliveryDayId][0] ? slotsOptions[selectedDeliveryDayId][0].value : ''
  }

  const selectedDeliveryDate = deliveryDaysOptions.find(day => day.value === selectedDeliveryDayId).date

  return { deliveryDaysOptions, slotsOptions, selectedDeliveryDayId, selectedDeliverySlotId, selectedDeliveryDate }
}

export default { getDayAndSlotOptionsAndSelected, getDeliveryDaysAndSlotsOptions }
