import React from 'react'

import { Card, CTA, Heading } from 'goustouicomponents'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import { client } from 'config/routes'
import { OrderDetails } from 'routes/Account/AccountComponents/OrderDetails'

import { myGoustoOrderPropType } from '../../../../GetHelp/getHelpPropTypes'

import css from './PreviousOrder.css'

const PreviousOrder = ({
  hasDeliveryToday,
  order,
  trackClickGetHelpWithThisBox,
  maxNumRecipes,
}) => {
  const deliveryDate = order.get('deliveryDate')
  const orderId = order.get('id')
  const orderState = order.get('phase')
  const price = order.getIn(['prices', 'total'])
  const recipeImages = order.get('recipeItems').map((item) => {
    const recipeItemMedia = item
      .get('media')
      .find((mediaItem) => mediaItem.get('type') === 'mood-image')
    const image = recipeItemMedia.getIn(['urls', '1'])

    return {
      alt: item.get('title') || 'Recipe image',
      src: image.get('src'),
    }
  })

  return (
    <div data-testing="getHelpPreviousOrder">
      <Heading size="fontStyleM" type="h2">
        Last delivery
      </Heading>
      <Card>
        <OrderDetails
          deliveryDate={deliveryDate}
          orderState={orderState}
          price={price}
          recipeImages={recipeImages.toJS()}
          maxRecipesCount={maxNumRecipes}
        />
        <div className={css.linkWrapper}>
          <Link to={`${client.getHelp.index}?orderId=${orderId}`}>
            <CTA
              isFullWidth
              onClick={() => trackClickGetHelpWithThisBox(orderId)}
              size="small"
              testingSelector="PreviousOrderGetHelpCTA"
              variant={hasDeliveryToday ? 'secondary' : 'primary'}
            >
              Any issues with this order?
            </CTA>
          </Link>
        </div>
      </Card>
    </div>
  )
}

PreviousOrder.propTypes = {
  hasDeliveryToday: PropTypes.bool.isRequired,
  order: myGoustoOrderPropType.isRequired,
  trackClickGetHelpWithThisBox: PropTypes.func.isRequired,
  maxNumRecipes: PropTypes.number,
}

PreviousOrder.defaultProps = {
  maxNumRecipes: 4,
}

export { PreviousOrder }
