import Immutable from 'immutable'
import { connect } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import actions, {userLoadOrderTrackingsInfo} from 'actions/user'
import { getMaxNumRecipes } from '../../selectors/index'
import Order from './Order'

function mapStateToProps(state, ownProps) {
  const { order } = ownProps
  const orderId = order.get('id')
  const products = order.get('products', Immutable.Map({}))

  return {
    userId: state.user.get('id'),
    orderId,
    deliveryDayId: order.get('coreDeliveryDayId') || order.get('deliveryDayId'),
    deliveryDay: order.get('deliveryDay'),
    humanDeliveryDay: order.get('humanDeliveryDay'),
    originalDeliveryDay: order.get('originalDeliveryDay'),
    deliveryDayRescheduledReason: order.get('deliveryDayRescheduledReason'),
    orderDeliveryTimeStart: order.get('deliverySlotStart'),
    orderDeliveryTimeEnd: order.get('deliverySlotEnd'),
    orderState: order.get('orderState'),
    orderShouldCutoffAt: order.get('shouldCutoffAt'),
    orderWhenCommitted: order.get('whenCommited'),
    orderWhenMenuOpen: order.get('whenMenuOpen'),
    recipes: order.get('recipes', Immutable.List([])),
    products,
    collapsed: state.user.getIn(['orderCardsCollapsedStatus', orderId]),
    editDeliveryMode: state.user.getIn(['orderCardsEditStatus', orderId]),
    priceBreakdown: order.get('priceBreakdown', Immutable.Map({})),
    cancellable: order.get('cancellable'),
    restorable: order.get('restorable'),
    isProjected: order.get('isProjected'),
    orderDeliveryDaysFetchError: state.error.get(actionTypes.ORDER_DELIVERY_DAYS_RECEIVE),
    recipesPeriodStockFetchError: state.error.get(actionTypes.RECIPES_PERIOD_STOCK_RECEIVE),
    portionsCount: order.get('portionsCount'),
    phase: order.get('phase'),
    maxNumRecipes: getMaxNumRecipes(state),
    isTestOrder: order.get('isTestOrder')
  }
}

const OrderContainer = connect(mapStateToProps, {
  loadOrderTrackingInfo: userLoadOrderTrackingsInfo,
  userOpenCloseOrderCard: actions.userOpenCloseOrderCard,
  userToggleEditDateSection: actions.userToggleEditDateSection,
})(Order)

export default OrderContainer
