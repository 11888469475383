import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  `

export const Spinner = styled.div`
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #333d47; /* Blue */
  border-radius: 50%; /* Circular effect */
  animation: ${spin} 1s linear infinite; /* Apply the animation */
`
