import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import { Theme, useTheme } from '@gousto-internal/citrus-react'

const FieldContainerStyled = styled.div<{ citrusTheme: Theme }>((props) => ({
  maxWidth: '100%',
  flex: '0 0 100%',
  margin: '0 0 1rem 0',
  padding: 0,

  [`@media (min-width: ${props.citrusTheme.tokens.breakpoints[0]})`]: {
    margin: '0 0 1.5rem 0',
  },
}))

export const FieldContainer = ({ children }: { children: ReactNode }) => {
  const { theme } = useTheme()

  return <FieldContainerStyled citrusTheme={theme}>{children}</FieldContainerStyled>
}
