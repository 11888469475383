import React from 'react'

import styled from '@emotion/styled'
import { Button, ButtonColorVariant, FontWeight, Text } from '@gousto-internal/citrus-react'
import Immutable from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, AlertSeverity } from 'routes/Checkout/Components/Alert/Alert'
import { checkoutConstants } from 'routes/Checkout/checkoutConstants'
import {
  getCurrentPaymentMethod,
  isApplePayPayment,
} from 'routes/Checkout/checkoutPaymentSelectors'
import {
  getApplePayErrors,
  getGeneralErrors,
  getPayPalErrors,
} from 'routes/Checkout/checkoutSelectors'
import { translateCheckoutErrorToMessageCode } from 'routes/Checkout/checkoutUtils'
import { PaymentMethod } from 'routes/Signup/signupConfig'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'

import { isSubmitting as isSubmittingSelector } from '../../utils/state'

type ErrorMessageProps = {
  onLoginClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ErrorMessageContainer = styled.div({
  marginBottom: '1.5rem',
})

const TextMessageContainer = styled.div({
  marginBottom: '1rem',
})

const LoginButtonContainer = styled.div({
  marginLeft: '-2rem',
})

/**
 * This component is used in Checkout to display various errors.
 * @property {() => void} onLoginClick - some errors are related to logging in issues, in this case Log in button is shown which fires this callback
 */
export const ErrorMessage = ({ onLoginClick }: ErrorMessageProps) => {
  const dispatch = useDispatch()

  const handleLoginClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    dispatch(onLoginClick?.(e))
  }

  const isSubmitting = useSelector(isSubmittingSelector)
  const isGoustoOnDemandEnabled = useSelector(getIsGoustoOnDemandEnabled)
  const currentPaymentMethod = useSelector(getCurrentPaymentMethod)
  const isApplePay = useSelector(isApplePayPayment)

  const applePayErrors = useSelector(getApplePayErrors) ?? Immutable.Map<string, boolean>()
  const payPalErrors = useSelector(getPayPalErrors) ?? Immutable.Map<string, boolean>()
  const generalErrors = useSelector(getGeneralErrors)

  if (isSubmitting) return null

  let entry: any[]

  switch (currentPaymentMethod) {
    case PaymentMethod.ApplePay: {
      entry = applePayErrors.merge(generalErrors).findEntry((value) => !!value)
      break
    }
    case PaymentMethod.PayPal: {
      entry = payPalErrors.merge(generalErrors).findEntry((value) => !!value)
      break
    }
    default: {
      entry = generalErrors.findEntry((value) => !!value)
    }
  }

  if (!entry) return null

  const [errorName, errorValue] = entry
  const errorType = translateCheckoutErrorToMessageCode(
    errorName,
    errorValue,
    isGoustoOnDemandEnabled,
    isApplePay,
  )

  const { errorMessage } = checkoutConstants
  const messageObject = (errorMessage as any)[errorType] || errorMessage.generic
  const { header, message, showLoginCTA, showAsSuccess } = messageObject

  const severity = showAsSuccess ? AlertSeverity.Success : AlertSeverity.Error

  return (
    <ErrorMessageContainer data-testing={`${errorType}`}>
      <Alert severity={severity}>
        {header && (
          <Text fontWeight={FontWeight.Bold} size={2}>
            {header}
          </Text>
        )}
        <TextMessageContainer>
          <Text size={2}>{message}</Text>
        </TextMessageContainer>
        {showLoginCTA && (
          <LoginButtonContainer>
            <Button
              width="100%"
              colorVariant={ButtonColorVariant.Secondary}
              onClick={handleLoginClick}
            >
              Log in
            </Button>
          </LoginButtonContainer>
        )}
      </Alert>
    </ErrorMessageContainer>
  )
}
