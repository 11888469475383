import React from 'react'

import {
  Box,
  Heading3,
  Display,
  AlignItems,
  Color,
  Space,
  FontWeight,
  FlexDirection,
  JustifyContent,
  AlignSelf,
} from '@gousto-internal/citrus-react'

import { ConveniencesList } from './ConveniencesList'
import { ConveniencesContainer, ValueForMoneyContainer } from './containers.styled'
import { title, valueForMoneyTrackingSectionName } from './data'
import { CTA_TEXT } from '../config'
import { CTAButton } from '../CTAButton/CTAButton'
import { valueForMoneyTrackingEvent } from '../../tracking/trackingConfig'

export const ValueForMoney = () => {
  return (
    <ValueForMoneyContainer
      height={[null, '711px']}
      paddingH={[null, 6, 8]}
      paddingV={[null, 10, 0]}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={[null, null, JustifyContent.Center]}
      alignItems={[null, null, AlignItems.Center]}
      data-testid={valueForMoneyTrackingSectionName}
    >
      <Box width="100%" maxWidth="1071px">
        <ConveniencesContainer
          width={[null, '449px']}
          paddingH={8}
          paddingV={8}
          paddingRight={[8, 16]}
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
        >
          <Box>
            <Heading3 color={Color.ColdGrey_900} fontWeight={FontWeight.Bold}>
              {title}
            </Heading3>
            <Space size={6} direction="vertical" />
            <ConveniencesList />
            <Space size={2} direction="vertical" />
          </Box>
          <Box maxWidth="17.5rem" width="100%" alignSelf={AlignSelf.Center} display={Display.Block}>
            <CTAButton trackingEvent={valueForMoneyTrackingEvent()} text={CTA_TEXT.GET_STARTED} />
          </Box>
        </ConveniencesContainer>
      </Box>
      {/* This box pads the bottom of the container on small screens so that the background is visible */}
      <Box height={['224px', '0']} />
    </ValueForMoneyContainer>
  )
}
