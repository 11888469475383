import React, { useEffect } from 'react'

import {
  Box,
  ButtonColorVariant,
  ButtonSize,
  Display,
  FlexDirection,
  FontWeight,
  Heading5,
  Join,
  JustifyContent,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  Body1,
  Space,
  Text,
  TextAlign,
  useModal,
} from '@gousto-internal/citrus-react'
import { useDispatch, useStore } from 'react-redux'

import {
  trackOrderConflictKeepRecipesClick,
  trackOrderConflictReplaceRecipesClick,
} from 'actions/myGousto'
import { updateOrder } from 'routes/Menu/apis/orderV2'
import { useIsRecipeInBasket } from 'routes/Menu/domains/basket'

import { NotificationParameters } from './OrderConflictConfirmationModalContainer'
import { RecipeListItem } from './RecipeListItem'

import css from './styles.css'

interface Props {
  shouldShow: boolean
  existingOrder: any
  setNotificationParameters: (notificationParameters: NotificationParameters) => void
}

interface RecipeListItemData {
  recipeId: string
  image: string
  title: string
}

export const ORDER_CONFLICT_MODAL_NAME = 'ORDER-CONFLICT-CONFIRMATION-MODAL'

function getConflictingRecipes(
  orderRecipes: RecipeListItemData[],
  isRecipeInBasket: (recipeId: string) => boolean,
): RecipeListItemData[] {
  return orderRecipes.filter((orderRecipe) => isRecipeInBasket(orderRecipe.recipeId))
}

export const OrderConflictConfirmationModal: React.FC<Props> = ({
  shouldShow,
  existingOrder,
  setNotificationParameters,
}) => {
  const store = useStore()
  const dispatch = useDispatch()
  const { closeCurrentModal } = useModal()

  const isRecipeInBasket = useIsRecipeInBasket()
  const [conflictingRecipes, setConflictingRecipes] = React.useState<Array<RecipeListItemData>>([])
  const [processingSubmit, setProcessingSubmit] = React.useState<boolean>(false)

  useEffect(() => {
    const existingOrderRecipesIds = existingOrder
      .get('recipeItems')
      .map((recipe: any) => {
        const image = recipe
          .get('media')
          .find((mediaItem: any) => mediaItem.get('type') === 'mood-image')
          .getIn(['urls', '1'])

        return {
          image: image.get('src'),
          recipeId: recipe.get('recipeId'),
          title: recipe.get('title'),
        }
      })
      .toArray()
    setConflictingRecipes(getConflictingRecipes(existingOrderRecipesIds, isRecipeInBasket))
  }, [existingOrder, isRecipeInBasket])

  const onConfirm = () => {
    setProcessingSubmit(true)
    dispatch(trackOrderConflictReplaceRecipesClick(existingOrder.get('id')))
    updateOrder(dispatch, store.getState, existingOrder.get('id').toString(), {
      delivery_slot_id: existingOrder.getIn(['deliverySlotId']),
      delivery_day_id: existingOrder.getIn(['deliveryDayId']),
    })
      .then(() => {
        setNotificationParameters({
          state: 'success',
          message: "We've successfully updated your recipe choices",
          body: `You've chosen recipes for your next box arriving on ${existingOrder.get(
            'humanDeliveryDate',
          )}`,
        })
      })
      .catch(() => {
        setNotificationParameters({
          state: 'error',
          message: "We weren't able to update your recipe choices",
        })
      })
      .finally(() => {
        setProcessingSubmit(false)
        closeCurrentModal()
      })
  }

  const onCancel = () => {
    dispatch(trackOrderConflictKeepRecipesClick(existingOrder.get('id')))
    setNotificationParameters({
      state: 'success',
      message: "We've kept the previous recipes you selected",
      body: `There are no changes to the recipes you've already selected for ${existingOrder.get(
        'humanDeliveryDate',
      )}`,
    })
    closeCurrentModal()
  }

  return (
    <Modal preventScroll name={ORDER_CONFLICT_MODAL_NAME} isOpen={shouldShow}>
      <ModalHeader>
        <Box
          display="flex"
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.SpaceBetween}
        >
          <Heading5 textAlign={TextAlign.Left}>Are you sure?</Heading5>
          <button type="button" className={css.modalCloseButton} onClick={closeCurrentModal}>
            <span role="img" aria-label="Close Icon" className={css.modalCloseIcon} />
          </button>
        </Box>
      </ModalHeader>
      <div className={css.modalBody}>
        <ModalBody>
          <Join with={<Space size={4} direction="vertical" />}>
            <Body1>
              You have an existing order for{' '}
              <Text display={Display.Inline} fontWeight={FontWeight.Bold}>
                {existingOrder.get('humanDeliveryDate')}
              </Text>
              . {conflictingRecipes.length > 0 ? 'These recipes are already in your box.' : null}
            </Body1>
            {conflictingRecipes.length > 0 ? (
              <ul className={css.recipeList}>
                {conflictingRecipes.map((recipe: any) => (
                  <RecipeListItem key={recipe.id} title={recipe.title} image={recipe.image} />
                ))}
              </ul>
            ) : null}
            <Body1>
              {conflictingRecipes.length > 0
                ? 'Would you like to replace these recipes with the ones you just selected?'
                : 'Would you like to replace the recipes in your existing order with the ones you just selected?'}
            </Body1>
          </Join>
        </ModalBody>
      </div>
      <div className={css.line} />
      <ModalFooter>
        <Box display="flex" flexDirection={FlexDirection.Column} width="100%">
          <Join with={<Space size={2} direction="vertical" />}>
            <ModalButton
              width="100%"
              colorVariant={ButtonColorVariant.Primary}
              size={ButtonSize.Medium}
              onClick={onConfirm}
              disabled={processingSubmit}
            >
              Yes, update box with new selection
            </ModalButton>
            <ModalButton
              width="100%"
              colorVariant={ButtonColorVariant.Secondary}
              size={ButtonSize.Medium}
              onClick={onCancel}
            >
              No, keep previous choices
            </ModalButton>
          </Join>
        </Box>
      </ModalFooter>
    </Modal>
  )
}
