import React from 'react'
import {
  Box,
  Text,
  Color,
  Icon,
  FontWeight,
  Display,
  JustifyContent,
  Space,
  Join,
  Divider,
} from '@gousto-internal/citrus-react'
import type { Order } from '@library/api-user'
import { formatDeliveryDate, formatShippingAddress } from './formatters'

const PendingOrdersList = ({ orders = [] }: { orders?: Order[] }) => (
  <Box paddingH={4}>
    <Join with={<Space size={4} direction="vertical" />}>
      {orders.map((order: Order, index: number) => (
        <Join with={<Space size={4} direction="vertical" />} key={order.id}>
          <Box display={Display.Flex} justifyContent={JustifyContent.FlexStart}>
            <Icon name="calendar" size={6} />
            <Space size={4} direction="horizontal" />
            <Box>
              <Text size={2} color={Color.ColdGrey_800} fontWeight={FontWeight.Bold}>
                {formatDeliveryDate(order.deliveryDate)}
              </Text>
              <Space size={2} direction="vertical" />
              <Text size={1} color={Color.ColdGrey_600}>
                {formatShippingAddress(order.shippingAddress)}
              </Text>
            </Box>
          </Box>
          {orders.length !== index + 1 && <Divider />}
        </Join>
      ))}
    </Join>
  </Box>
)

export { PendingOrdersList }
