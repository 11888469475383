import React, { useCallback, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import type { EmblaOptionsType } from 'embla-carousel'
import {
  Box,
  Button,
  ButtonVariant,
  ButtonColorVariant,
  ButtonSize,
  Display,
  DisplayPosition,
  JustifyContent,
  Overflow,
  SpaceScale,
  Space,
} from '@gousto-internal/citrus-react'
import { trackCarouselSlideChange } from '../../tracking'

export type CarouselProps = {
  carouselAreaLabel: string
  children: React.ReactNode
  options?: EmblaOptionsType
  showNavButtons?: boolean
  slidePaddingH?: SpaceScale
  slidePaddingV?: SpaceScale
  slideWidth?: string
  slideHeight?: string
  snowplowTrackingProperties?: Record<string, unknown>
}

export const Carousel = ({
  carouselAreaLabel,
  children,
  options,
  slidePaddingH,
  slidePaddingV,
  slideWidth,
  slideHeight,
  showNavButtons = true,
  snowplowTrackingProperties,
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const onSettle = useCallback(
    (emblaApi) => {
      const newIndex = emblaApi.selectedScrollSnap()
      const prevIndex = emblaApi.previousScrollSnap()
      trackCarouselSlideChange(prevIndex, newIndex, snowplowTrackingProperties)
    },
    [snowplowTrackingProperties],
  )

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev()
    }
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext()
    }
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('settle', onSettle)
    }
  }, [emblaApi, onSettle])

  return (
    <Box
      className="embla"
      position={DisplayPosition.Relative}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
    >
      <Box className="embla__viewport" ref={emblaRef}>
        <Box
          aria-label={carouselAreaLabel}
          aria-live="polite"
          aria-atomic="true"
          aria-roledescription="carousel"
          className="embla__container"
          display={Display.Flex}
          role="region"
          tabIndex={0}
        >
          {React.Children.map(children, (child, index) => {
            return (
              <Box
                aria-label={`${index + 1} of ${React.Children.count(children)}`}
                aria-roledescription={`Slide ${index + 1} of ${React.Children.count(children)}`}
                className="embla__slide"
                data-testid="carousel-slide"
                flexBasis={slideWidth}
                flexGrow={0}
                flexShrink={0}
                height={slideHeight}
                key={index}
                paddingH={slidePaddingH}
                paddingV={slidePaddingV}
                role="group"
                tabIndex={-1}
              >
                {child}
              </Box>
            )
          })}
        </Box>
        {showNavButtons && (
          <Box
            aria-label="Carousel controls"
            display={Display.Flex}
            position={DisplayPosition.Absolute}
            role="group"
            top="45%"
            width="100%"
          >
            <Space direction="horizontal" size={4} />
            <Box justifyContent={JustifyContent.SpaceBetween} display={Display.Flex} width="100%">
              <Button
                aria-label="Previous slide"
                className="embla__prev"
                colorVariant={ButtonColorVariant.Secondary}
                icon="arrow_back"
                onClick={scrollPrev}
                size={ButtonSize.Large}
                style={{ borderRadius: '50%' }}
                variant={ButtonVariant.Icon}
              />
              <Button
                aria-label="Next slide"
                className="embla__next"
                colorVariant={ButtonColorVariant.Secondary}
                icon="arrow_right"
                onClick={scrollNext}
                size={ButtonSize.Large}
                style={{ borderRadius: '50%' }}
                variant={ButtonVariant.Icon}
              />
            </Box>
            <Space direction="horizontal" size={4} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
