module.exports = {
  ACCESS_TOKEN: 'oauth_token',
  REFRESH_TOKEN: 'oauth_refresh',
  REMEMBER_ME_DAYS: 60,
  REMEMBER_ME_DEFAULT: false,

  FAILED_LOGIN_TEXT: 'Incorrect email/password.',
  DEFAULT_ERROR: 'Sorry, we were unable to log you in. Please contact customer care.',

  expiresThreshold: 5,

  PASSWORD_MIN_LENGTH: 8,
}
