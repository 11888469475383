import React, { useState } from 'react'
import {
  BorderStyle,
  Box,
  Color,
  Display,
  DisplayPosition,
  FontWeight,
  Heading2,
  Icon,
  IconNames,
  Image,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'
import { Recipe } from '../../types/recipe'
import { convertDashToUnderscore, DietaryClaimIconNames } from './utils'
import { ErrorBoundary } from 'react-error-boundary'
import { Checkbox } from './Checkbox/Checkbox'

export type RecipePreferenceTileProps = {
  recipe: Recipe
  onSelectionChange: (pref: string) => void
  defaultIsSelected?: boolean
}

export const RecipePreferenceTile = ({
  recipe,
  onSelectionChange,
  defaultIsSelected = false,
}: RecipePreferenceTileProps) => {
  const [isSelected, setIsSelected] = useState(defaultIsSelected)

  const handleClick = () => {
    const newSelectedState = !isSelected
    setIsSelected(newSelectedState)
    onSelectionChange(recipe.uuid)
  }

  return (
    <Box
      data-testid="recipe-preference-tile"
      borderWidth={1}
      borderRadius={1.5}
      borderColor={isSelected ? Color.Secondary_400 : Color.NeutralGrey_200}
      borderStyle={BorderStyle.Solid}
      height="278px"
      position={DisplayPosition.Relative}
      onClick={handleClick}
      style={{ overflow: 'hidden' }}
    >
      <Box height="180px" display={Display.Flex} justifyContent={JustifyContent.Center}>
        <Image
          alt={recipe.title}
          src={recipe?.media?.images?.[0]?.urls?.[2]?.src}
          style={{
            transform: 'scale(1.15)',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box display={Display.Flex} position={DisplayPosition.Absolute} paddingH={3} top="-16px">
        {recipe.dietaryClaims.reduce((acc: JSX.Element[], claim, index) => {
          const isDietaryClaimIconAvailable = Object.values(DietaryClaimIconNames).includes(
            convertDashToUnderscore(claim.slug) as DietaryClaimIconNames,
          )
          if (isDietaryClaimIconAvailable) {
            acc.push(
              <ErrorBoundary key={`${claim.slug}-${index}`} fallback={<></>}>
                <Box height="28px" width="28px" paddingV={6} data-testid="dietary-claim">
                  <Icon name={convertDashToUnderscore(claim.slug) as IconNames} size={8} />
                </Box>
                <Space direction="horizontal" size={1} />
              </ErrorBoundary>,
            )
          }
          return acc
        }, [])}
      </Box>

      <Box
        display={Display.Flex}
        position={DisplayPosition.Relative}
        justifyContent={JustifyContent.FlexEnd}
        paddingRight={1}
      >
        <Checkbox isSelected={isSelected} />
      </Box>

      <Box paddingH={4}>
        <Heading2 size={1} color={Color.ColdGrey_800} fontWeight={FontWeight.Bold}>
          {recipe.title}
        </Heading2>
      </Box>
    </Box>
  )
}
