import { parseObjectKeysToCamelCase } from 'utils/jsonHelper'

const isSubscriptionSetupUnsupported = (numPortions, numRecipes, validBoxSizes) => {
  // If box sizes hasn't loaded yet
  if (validBoxSizes.length === 0) {
    return false
  }
  const matchingBoxSize = validBoxSizes.find(boxSize => boxSize.numPortions === Number(numPortions))

  if (matchingBoxSize) {
    return !matchingBoxSize.numRecipes.includes(Number(numRecipes))
  }

  return true
}

export const reduceBoxData = (state, data) => {
  if (!data || !data.box) {
    return state
  }

  const { boxType, numRecipes, numPortions, ...box } = data.box
  const { subscriptionOptions } = state

  return {
    ...state,
    box: {
      ...box,
      numPortions: `${numPortions}`,
      numRecipes: `${numRecipes}`,
      dietaryPreference: {
        currentValue: boxType
      },
      recipesPerBox: {
        currentValue: `${numRecipes}`
      },
      boxSize: {
        currentValue: `${numPortions}`
      },
      requestState: {
        isLoaded: true,
        isLoading: false
      },
      subscriptionSettingsUnsupported: isSubscriptionSetupUnsupported(numPortions, numRecipes, subscriptionOptions.boxSize),
    }
  }
}

export const reduceSelectedBoxSize = (state, data) => ({
  ...state,
  box: {
    ...state.box,
    boxSize: { currentValue: String(data.numPortions) },
    subscriptionSettingsUnsupported: isSubscriptionSetupUnsupported(data.numPortions, state.box.recipesPerBox.currentValue, state.subscriptionOptions.boxSize)
  }
})

export const reduceSelectedRecipesPerBox = (state, data) => ({
  ...state,
  box: {
    ...state.box,
    recipesPerBox: { currentValue: String(data.numRecipes) },
    subscriptionSettingsUnsupported: isSubscriptionSetupUnsupported(state.box.numPortions, data.numRecipes, state.subscriptionOptions.boxSize)
  }
})

export const reduceSwitchToMaxRecipesPerPortionSize = (state) => {
  const numPortions = state.box.boxSize.currentValue
  const { boxSize } = state.subscriptionOptions
  const matchingBoxSize = boxSize.find(size => size.numPortions === Number(numPortions))
  const maxRecipes = matchingBoxSize.numRecipes[matchingBoxSize.numRecipes.length - 1]

  return {
    ...state,
    box: {
      ...state.box,
      recipesPerBox: { currentValue: String(maxRecipes) },
      boxSize: { currentValue: numPortions },
      subscriptionSettingsUnsupported: false,
    }
  }
}

export const reduceBoxPricesData = (state, data) => {
  if (!data) {
    return state
  }

  const camelCaseBoxPrices = parseObjectKeysToCamelCase(data)

  return {
    ...state,
    boxPrices: {
      ...camelCaseBoxPrices,
      requestState: {
        isLoaded: true,
        isLoading: false
      }
    }
  }
}
