import React, { useCallback } from 'react'

import { Space } from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { trackSubmitOrderEvent } from 'actions/tracking'
import { CheckoutButton } from 'routes/Checkout/Components/CheckoutButton'
import { isSubmitting as getIsSubmitting } from 'routes/Checkout/utils/state'
import { usePricing } from 'routes/Menu/domains/pricing'

type SubmitButtonProps = {
  isDisabled: boolean
  onClick: () => void
}

export const SubmitButton = ({ isDisabled, onClick }: SubmitButtonProps) => {
  const dispatch = useDispatch()
  const { pricing } = usePricing()

  const isSubmitting = useSelector(getIsSubmitting)

  const handleClick = useCallback(() => {
    dispatch(trackSubmitOrderEvent(pricing?.total))
    onClick()
  }, [dispatch, onClick, pricing?.total])

  return (
    <>
      <CheckoutButton onClick={handleClick} isLoading={isSubmitting} isDisabled={isDisabled}>
        Start your subscription
      </CheckoutButton>
      <Space direction="vertical" size={[4, null, 5]} />
    </>
  )
}
