import React from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { withOptimizely, ReactSDKClient } from '@optimizely/react-sdk'
import Loading from 'Loading'
import { Alert, CTA, Button } from 'goustouicomponents'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { browserHistory } from 'react-router'

import routes from 'config/routes'
import { DeliveryCard } from 'routes/Checkout/Components/Delivery/DeliveryCard'
import logger from 'utils/logger'

import OrdersList from './OrdersList'

import accountCss from '../Account/Account.css'
import css from './MyDeliveries.css'

class MyDeliveriesComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    const { optimizely } = this.props
    const decision = optimizely.decide('turnips_ofx_delivery_details_before_menu')
    this.state = {
      // no need to check isAuthenticated because this page is only accessible to authenticated users
      isDeliveryDetailsExperimentEnabled: decision.variationKey === 't1',
    }
  }

  componentDidMount() {
    this.fetchOrdersAndAddresses()
  }

  getErrorMessage() {
    const { didErrorFetchingPendingOrders, didErrorFetchingProjectedOrders } = this.props

    const errorMessages = {
      pendingOrderError:
        "We're not able to display your upcoming orders right now. Please try again later.",
      projectedOrderError:
        "We're not able to display your future deliveries right now. Please try again later.",
      genericError: "We're not able to display your deliveries right now. Please try again later.",
    }

    if (didErrorFetchingProjectedOrders !== null) {
      return errorMessages.projectedOrderError
    } else if (didErrorFetchingPendingOrders !== null) {
      return errorMessages.pendingOrderError
    } else {
      return errorMessages.genericError
    }
  }

  fetchOrdersAndAddresses = async () => {
    const { userId, userLoadAddresses, userLoadData, userLoadNewOrders } = this.props

    try {
      if (!userId) {
        await userLoadData()
      }

      userLoadNewOrders()
      userLoadAddresses()
    } catch (error) {
      logger.error(error)
    }
  }

  retryFetch = () => {
    this.fetchOrdersAndAddresses()
  }

  static renderFetchError = (retryFetch, errorMessage) => (
    <div>
      <Alert type="danger">
        <p>{errorMessage}</p>
      </Alert>
      <div className={css.button}>
        <Button onClick={retryFetch}>Retry</Button>
      </div>
    </div>
  )

  handleOrderAnotherBoxClick = () => {
    const { isDeliveryDetailsExperimentEnabled } = this.state

    if (isDeliveryDetailsExperimentEnabled) {
      browserHistory.push(routes.client.deliveryDetails)
    } else {
      browserHistory.push(routes.client.menu)
    }
  }

  static renderLoading = (
    <div className={css.spinner}>
      <Loading />
    </div>
  )

  renderOrders() {
    const {
      didErrorFetchingPendingOrders,
      didErrorFetchingProjectedOrders,
      isFetchingOrders,
      userId,
    } = this.props
    if (isFetchingOrders || !userId) {
      return MyDeliveries.renderLoading
    }

    if (didErrorFetchingPendingOrders !== null || didErrorFetchingProjectedOrders !== null) {
      const errorMessage = this.getErrorMessage()
      const error = didErrorFetchingPendingOrders

      datadogLogs.logger.error('MyDeliveries: orders fetching failed', {
        userId,
        error,
        renderedErrorMessage: errorMessage,
      })

      return MyDeliveries.renderFetchError(this.retryFetch, errorMessage)
    }

    return <OrdersList />
  }

  renderGoustoOnDemandBanner = () => {
    const { isGoustoOnDemandEnabled } = this.props

    if (isGoustoOnDemandEnabled) {
      return (
        <div className={css.bannerContainer}>
          <DeliveryCard iconName="icon-gousto-on-demand-offer" cardStyle="blue">
            Voucher will be applied to your next order
          </DeliveryCard>
        </div>
      )
    }

    return null
  }

  render() {
    const { isDeliveryDetailsExperimentEnabled } = this.state

    return (
      <div className={accountCss.accountContainer} data-testing="myDeliveries">
        <Helmet title="Gousto Deliveries | Manage All Upcoming Deliveries" />
        <div className={css.titleContainer}>
          <h1 className={css.title}>Deliveries</h1>
          <div className={css.subTitleContainer}>
            <p className={css.subTitle}>
              Here you can manage all of your upcoming deliveries, select recipes, skip or cancel
              orders as you need.
            </p>
            <CTA
              onClick={this.handleOrderAnotherBoxClick}
              variant="secondary"
              size="small"
              testingSelector="addBoxButton"
            >
              {isDeliveryDetailsExperimentEnabled ? 'Order another box' : 'Order again'}
            </CTA>
          </div>
        </div>
        {this.renderGoustoOnDemandBanner()}
        {this.renderOrders()}
      </div>
    )
  }
}

MyDeliveriesComponent.propTypes = {
  isFetchingOrders: PropTypes.bool,
  didErrorFetchingPendingOrders: PropTypes.shape({ message: PropTypes.string }),
  didErrorFetchingProjectedOrders: PropTypes.shape({ message: PropTypes.string }),
  userLoadAddresses: PropTypes.func.isRequired,
  userLoadData: PropTypes.func.isRequired,
  userLoadNewOrders: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isGoustoOnDemandEnabled: PropTypes.bool,
  optimizely: PropTypes.shape(ReactSDKClient),
}

MyDeliveriesComponent.defaultProps = {
  isFetchingOrders: false,
  didErrorFetchingPendingOrders: null,
  didErrorFetchingProjectedOrders: null,
  isGoustoOnDemandEnabled: false,
  optimizely: null,
}

const MyDeliveries = withOptimizely(MyDeliveriesComponent)

export default MyDeliveries
