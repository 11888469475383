import { createSelector } from 'reselect'

export const getSubscriptionOptions = ({ subscriptionOptions }) => (subscriptionOptions || {})

export const getSubscriptionBoxSizeOptions = createSelector(
  getSubscriptionOptions,
  (subscriptionOptions) => (
    subscriptionOptions.boxSize
  )
)

export const getSubscriptionPortionOptions = createSelector(
  getSubscriptionBoxSizeOptions,
  (boxSize) => (boxSize.map(box => box.numPortions.toString())))

export const getSubscriptionRecipeOptions = createSelector(
  getSubscriptionBoxSizeOptions,
  (boxSize) => (boxSize.reduce((current, prev) => ({
    ...current,
    [prev.numPortions]: prev.numRecipes.map((recipe) => String(recipe))
  }), {})))
