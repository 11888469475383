import React from 'react'
import {
  Box,
  JustifyContent,
  FontWeight,
  Heading6,
  Color,
  Icon,
  Link,
  Space,
  Display,
} from '@gousto-internal/citrus-react'

export type IconType = 'profile' | 'phone' | 'email' | 'home' | 'credit_card' | 'delivery'

interface Config {
  isExpanded: boolean
  iconType: IconType
  title: string
  children?: React.ReactNode
}

const RenderedTitle = ({ isExpanded, iconType, title, children }: Config): JSX.Element => (
  <>
    <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween} paddingBottom={3}>
      <Box display={Display.Flex}>
        <Icon name={iconType} size={6} />
        <Space size={4} direction="horizontal" />
        <Heading6 size={1} color={Color.ColdGrey_800} fontWeight={FontWeight.Bold}>
          {title}
        </Heading6>
      </Box>
      <Box color={Color.Secondary_400}>
        <Link>{isExpanded ? 'Cancel' : 'Edit'}</Link>
      </Box>
    </Box>
    {children && <Box paddingH={10}>{!isExpanded && children}</Box>}
  </>
)

export { RenderedTitle }
