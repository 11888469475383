import React from 'react'

import { Body3, Join, Space } from '@gousto-internal/citrus-react'

type NutritionDisclaimerTextProps = {
  numPortions: number
}

export const NutritionDisclaimerText = ({ numPortions }: NutritionDisclaimerTextProps) => (
  <Join with={<Space size={2} />}>
    <Body3>Nutritional information only applies to ingredients supplied by Gousto.</Body3>
    <Body3>
      <span>Portion sizes are based on the quantities used in a {numPortions} person box.</span>
    </Body3>
  </Join>
)
