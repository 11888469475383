const seActions = {
  GET_HELP_INGREDIENTS_ACCEPT_REFUND: 'ssr_ingredients_accept_refund',
  GET_HELP_CONTACT_CHANNEL_SELECT: 'click_agent_contact_method',
  GET_HELP_INGREDIENT_ISSUES_SELECT: 'ssr_ingredients_issues_confirmed',
  GET_HELP_ORDER_ISSUE_SELECT: 'ssr_order_issue_selected',
  GET_HELP_STORE_SELECTED_INGREDIENTS: 'ssr_ingredients_selection_confirmed',
}

export {
  seActions
}
