import { useCallback } from 'react'

import useSWR from 'swr'

import {
  MenuApiPreferencesResponse,
  MenuSettingsRequestPayload,
  menuPreferencesFetcher,
  updateMenuPreferences,
} from '@library/menu-service'

import { useAuth } from '../auth'

// this key is used to cache the menu preferences in the SWR cache and to deduplicate the requests
const swrKey = '/menu/v3/preferences'

export function useMenuPreferences(): {
  data?: MenuApiPreferencesResponse
  isLoading: boolean
  updateMenuPreferences: (newMenuPreferences: MenuSettingsRequestPayload) => Promise<void>
} {
  const { authUserId } = useAuth()

  const response = useSWR(
    () =>
      authUserId
        ? {
            key: swrKey,
            userId: authUserId,
          }
        : null,
    menuPreferencesFetcher,
  )

  const updateMenuPreferencesCallback = useCallback(
    async (newMenuPreferences: MenuSettingsRequestPayload) => {
      await response.mutate(async () => {
        await updateMenuPreferences(newMenuPreferences)

        return newMenuPreferences as MenuApiPreferencesResponse
      })
    },
    [response],
  )

  return {
    data: response.data,
    isLoading: !response.data && response.isValidating,
    updateMenuPreferences: updateMenuPreferencesCallback,
  }
}
