import { Box, Display, FlexDirection } from '@gousto-internal/citrus-react'
import * as React from 'react'

export function DetailGrid(props: { children: React.ReactNode }) {
  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Row} width="100%">
      {props.children}
    </Box>
  )
}
