import { useCallback, useMemo } from 'react'

import { OrderedMap } from 'immutable'
import { useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import * as trackingKeys from 'actions/trackingKeys'
import { MenuCollection } from 'routes/Menu/types'

export function useTracking({
  collections,
  menuId,
}: {
  collections: OrderedMap<string, MenuCollection>
  menuId: string
}) {
  const dispatch = useDispatch()

  const collectionSlugArr: (string | undefined)[] = useMemo(
    () =>
      collections
        .map((collection) => collection?.get('slug'))
        .toIndexedSeq()
        .toArray(),
    [collections],
  )

  const collectionPositionArr: number[] = useMemo(
    () => collectionSlugArr.map((collection: any, index: number) => index + 1),
    [collectionSlugArr],
  )

  return useCallback(() => {
    dispatch({
      type: actionTypes.COLLECTION_NAV_SHOWN,
      trackingData: {
        actionType: trackingKeys.collectionNavShown,
        collectionNamesArray: collectionSlugArr,
        collectionPositionsArray: collectionPositionArr,
        menuId,
      },
    })
  }, [dispatch, collectionPositionArr, collectionSlugArr, menuId])
}
