import { useDecision } from '@optimizely/react-sdk'
import { useSelector } from 'react-redux'

import { getIsAuthenticated } from 'selectors/auth'

export function useIsDiscountGiftEnabled() {
  const [discountGiftDecision] = useDecision('beetroots_ofx_discount_gift')
  const isAuthenticated = useSelector(getIsAuthenticated)

  return discountGiftDecision.variationKey === 't1' && !isAuthenticated
}
