import { datadogLogs } from '@datadog/browser-logs'
import { useCallback, useState } from 'react'
import { CardConfirmationRedirectTo } from '../../../enums'

const initialState = {
  cardConfirmationRedirectURL: undefined,
  cardConfirmationError: undefined,
  cardConfirmationCompleted: false,
}

const usePaymentCard3DSModalProvider = () => {
  const [cardConfirmationRedirectURL, setCardConfirmationRedirectURL] = useState<
    string | undefined
  >()
  const [cardConfirmationError, setCardConfirmationError] = useState<string | undefined>()
  const [cardConfirmationCompleted, setCardConfirmationCompleted] = useState<boolean>(
    initialState.cardConfirmationCompleted,
  )

  const reset = () => {
    setCardConfirmationRedirectURL(initialState.cardConfirmationRedirectURL)
    setCardConfirmationError(initialState.cardConfirmationError)
    setCardConfirmationCompleted(initialState.cardConfirmationCompleted)
  }

  const onCardConfirmationLoaded = useCallback((event: React.SyntheticEvent<HTMLIFrameElement>) => {
    const target = event.target as HTMLIFrameElement

    setCardConfirmationError(undefined)
    setCardConfirmationCompleted(false)

    try {
      const href = target.contentWindow?.location.href

      if (href?.includes(CardConfirmationRedirectTo.FAILURE)) {
        setCardConfirmationError('Card verification failed. Please try again.')
      }

      setCardConfirmationCompleted(true)
    } catch (error: Error | DOMException | unknown) {
      if (error instanceof DOMException) {
        datadogLogs.logger.warn(error.message)
      } else {
        setCardConfirmationError('Card verification failed. Please try again.')
      }
    }
  }, [])

  return {
    cardConfirmationError,
    cardConfirmationCompleted,
    cardConfirmationRedirectURL,
    onCardConfirmationLoaded,
    setCardConfirmationRedirectURL,
    setCardConfirmationError,
    reset,
  }
}

export { usePaymentCard3DSModalProvider }
