import * as React from 'react'

import { Global } from '@emotion/react'

const createFontFaces = (fontsPath: string) => [
  {
    '@font-face': {
      fontFamily: 'AxiformaBook',
      src: `url(${fontsPath}/Axiforma-Book.otf) format('opentype')`,
      fontWeight: '1 400',
      fontStyle: 'normal',
    },
  },
  // Applies Axiforma-Bold to Book fonts that have bolder (400+) font-weights applied
  // This is for improved font-rendering and compliance with The Pantry
  {
    '@font-face': {
      fontFamily: 'AxiformaBook',
      src: `url(${fontsPath}/Axiforma-Bold.otf) format('opentype')`,
      fontWeight: '401 1000',
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'AxiformaBold',
      src: `url(${fontsPath}/Axiforma-Bold.otf) format('opentype')`,
      fontWeight: '1 1000',
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'AxiformaExtraBold',
      src: `url(${fontsPath}/Axiforma-ExtraBold.otf) format('opentype')`,
      fontWeight: '1 1000',
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'AxiformaExtraBoldItalic',
      src: `url(${fontsPath}/Axiforma-ExtraBoldItalic.otf) format('opentype')`,
      fontWeight: '1 1000',
      fontStyle: 'normal',
    },
  },
]

export type FontConfigProps = {
  fontsPath: string
}

export const FontConfig = (props: FontConfigProps) => (
  <Global styles={createFontFaces(props.fontsPath)} />
)
