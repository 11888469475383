import React from 'react'

import { Space } from '@gousto-internal/citrus-react'
import classNames from 'classnames'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import humanTimeFormat from 'utils/timeFormat'

import { FiveRecipesAwarenessOrderListBanner } from '../../../../../components/FiveRecipesAwareness/OrderListBanner/FiveRecipesAwarenessOrderListBanner'
import OrderCollage from './OrderCollage'
import OrderCollapseExpand from './OrderCollapseExpand'
import OrderDate from './OrderDate'
import OrderDetail from './OrderDetail'
import OrderItemSummary from './OrderItemSummary'
import OrderPricing from './OrderPricing'
import OrderRescheduledNotification from './OrderRescheduledNotification'
import OrderRestoreButton from './OrderRestoreButton'
import OrderState from './OrderState'
import OrderStatus from './OrderStatus'
import OrderTime from './OrderTime'
import { OrderMultipleBoxesNotification } from './OrderMultipleBoxesNotification'

import css from './Order.css'
import { TestOrderHeader } from './TestOrderHeader'

class Order extends React.PureComponent {
  componentDidMount() {
    const { orderId, userOpenCloseOrderCard, userToggleEditDateSection } = this.props

    userOpenCloseOrderCard(orderId, true)
    userToggleEditDateSection(orderId, false)
  }

  open = () => {
    const { orderId, userOpenCloseOrderCard } = this.props

    userOpenCloseOrderCard(orderId, false)
  }

  close = () => {
    const { orderId, userOpenCloseOrderCard } = this.props

    userOpenCloseOrderCard(orderId, true)
  }

  render() {
    const {
      loadOrderTrackingInfo,
      collapsed,
      orderId,
      userId,
      deliveryDayId,
      orderState,
      restorable,
      recipes,
      products,
      humanDeliveryDay,
      originalDeliveryDay,
      orderDeliveryTimeStart,
      orderDeliveryTimeEnd,
      deliveryDayRescheduledReason,
      orderShouldCutoffAt,
      orderWhenMenuOpen,
      priceBreakdown,
      editDeliveryMode,
      portionsCount,
      isProjected,
      cancellable,
      deliveryDay,
      orderDeliveryDaysFetchError,
      recipesPeriodStockFetchError,
      phase,
      maxNumRecipes,
      isTestOrder,
    } = this.props
    const isOrderInteractive = orderState !== 'cancelled' && phase !== 'pre_menu'

    let onClickFunction = () => {}
    if (isOrderInteractive) {
      onClickFunction = collapsed ? this.open : this.close
    }
    const humanDeliveryTimeStart = humanTimeFormat(orderDeliveryTimeStart, 'hour')
    const humanDeliveryTimeEnd = humanTimeFormat(orderDeliveryTimeEnd, 'hour')
    const numberOfTrackingUrls = loadOrderTrackingInfo ? loadOrderTrackingInfo.length : 0

    return (
      <div
        className={css.orderWrap}
        id={`order-${orderId}`}
        data-testing={isProjected ? 'projectedDelivery' : 'pendingOrder'}
      >
        {isTestOrder ? <TestOrderHeader /> : null}
        <div className={css.order}>
          <span
            onClick={onClickFunction}
            data-testing={orderState === 'recipes chosen' ? 'recipesChosenCard' : ''}
            className={classNames(css.orderRow, { [css.link]: isOrderInteractive })}
          >
            <div>
              <OrderCollage
                recipes={recipes}
                orderState={orderState}
                maxNumRecipes={maxNumRecipes}
              />
            </div>
            <div className={css.orderMain}>
              <FiveRecipesAwarenessOrderListBanner orderState={orderState} />
              {originalDeliveryDay !== null ? (
                <OrderRescheduledNotification
                  oldDeliveryDay={originalDeliveryDay}
                  reason={deliveryDayRescheduledReason}
                />
              ) : null}
              <div className={css.topRow}>
                <div className={css.dateAndTime}>
                  <OrderDate date={humanDeliveryDay} />
                  {isProjected ? null : (
                    <OrderTime start={humanDeliveryTimeStart} end={humanDeliveryTimeEnd} />
                  )}
                </div>
                <div className={css.hideAboveLarge}> {isOrderInteractive && (
                <OrderPricing pricing={priceBreakdown} orderState={orderState} />
                )}
                </div>
              </div>

              <OrderState orderState={orderState} numberOfTrackingUrls={numberOfTrackingUrls} />
              {restorable && (
              <OrderRestoreButton
                userId={userId}
                orderId={orderId}
                deliveryDayId={deliveryDayId}
                deliveryDay={deliveryDay}
                orderState={orderState}
                isProjected={isProjected}
              />
              )}
              <div className={css.orderColLeft}>
                <OrderStatus
                  orderState={orderState}
                  whenCutoff={orderShouldCutoffAt}
                  whenMenuOpen={orderWhenMenuOpen}
                  isProjected={isProjected}
                  restorable={restorable}
                  boxSize={portionsCount}
                />
              </div>

              <div className={css.bottomRow}>
                <div>
                  {recipes.size > 0 && ['cancelled', 'scheduled'].indexOf(orderState) < 0 && (
                  <OrderItemSummary recipes={recipes} numberOfProducts={products.get('total')} />
                  )}
                </div>

                <div className={css.hideAboveLarge}> {isOrderInteractive && (
                <OrderCollapseExpand collapsed={collapsed} onClick={onClickFunction} />
                )}
                </div>
              </div>
              <Space size={2} />
              { orderState === 'recipes chosen' && recipes.size > 4 && portionsCount >= 4 &&
                 (
                 <div className={css.orderColLeft}>
                   <OrderMultipleBoxesNotification />
                   <Space size={2} />
                 </div>
                 )}
            </div>
            <div className={css.hideBelowMedium}> {isOrderInteractive ? (
              <div className={css.orderColRight}>
                <OrderPricing
                  pricing={priceBreakdown}
                  orderState={orderState}
                />
                <div className={css.bottom}>
                  <OrderCollapseExpand
                    collapsed={collapsed}
                    onClick={onClickFunction}
                  />
                </div>
              </div>
            ) : null}
            </div>
          </span>

          {!collapsed ? (
            <div className={classNames(css.orderRow, css.orderDetail)}>
              <OrderDetail
                open={!collapsed}
                orderId={orderId}
                deliveryDayId={deliveryDayId}
                orderState={orderState}
                close={this.close}
                paymentDate={humanTimeFormat(orderShouldCutoffAt, 'dayAndMonth')}
                recipes={recipes}
                products={products.get('elements')}
                priceBreakdown={priceBreakdown}
                deliveryDate={humanDeliveryDay}
                deliveryTimeStart={humanDeliveryTimeStart}
                deliveryTimeEnd={humanDeliveryTimeEnd}
                editDeliveryMode={editDeliveryMode}
                whenCutoff={humanTimeFormat(orderShouldCutoffAt, 'dayAndMonth')}
                cancellable={cancellable}
                restorable={restorable}
                orderDeliveryDaysFetchError={orderDeliveryDaysFetchError}
                recipesPeriodStockFetchError={recipesPeriodStockFetchError}
                deliveryDay={deliveryDay}
                portionsCount={portionsCount}
                maxNumRecipes={maxNumRecipes}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

Order.propTypes = {
  loadOrderTrackingInfo: PropTypes.func.isRequired,
  userId: PropTypes.string,
  collapsed: PropTypes.bool,
  humanDeliveryDay: PropTypes.string,
  originalDeliveryDay: PropTypes.string,
  deliveryDayRescheduledReason: PropTypes.string,
  orderDeliveryTimeEnd: PropTypes.string,
  orderDeliveryTimeStart: PropTypes.string,
  orderId: PropTypes.string,
  deliveryDayId: PropTypes.string,
  orderState: PropTypes.string,
  orderShouldCutoffAt: PropTypes.string,
  orderWhenMenuOpen: PropTypes.string,
  products: ImmutablePropTypes.mapContains({
    total: PropTypes.number,
    elements: PropTypes.instanceOf(Immutable.List),
  }),
  recipes: PropTypes.instanceOf(Immutable.List),
  priceBreakdown: PropTypes.instanceOf(Immutable.Map),
  editDeliveryMode: PropTypes.bool,
  cancellable: PropTypes.bool,
  restorable: PropTypes.bool,
  isProjected: PropTypes.bool,
  orderDeliveryDaysFetchError: PropTypes.string,
  recipesPeriodStockFetchError: PropTypes.string,
  deliveryDay: PropTypes.string,
  portionsCount: PropTypes.string,
  userOpenCloseOrderCard: PropTypes.func.isRequired,
  userToggleEditDateSection: PropTypes.func.isRequired,
  phase: PropTypes.string,
  maxNumRecipes: PropTypes.number,
  isTestOrder: PropTypes.bool,
}

Order.defaultProps = {
  userId: '',
  humanDeliveryDay: '',
  originalDeliveryDay: null,
  deliveryDayRescheduledReason: null,
  orderDeliveryTimeEnd: '',
  orderDeliveryTimeStart: '',
  orderId: '',
  deliveryDayId: '',
  orderState: '',
  orderShouldCutoffAt: '',
  orderWhenMenuOpen: '',
  products: Immutable.Map({
    total: 0,
    elements: Immutable.List([]),
  }),
  recipes: Immutable.List([]),
  priceBreakdown: Immutable.Map({}),
  editDeliveryMode: false,
  cancellable: false,
  restorable: false,
  isProjected: false,
  collapsed: true,
  orderDeliveryDaysFetchError: null,
  recipesPeriodStockFetchError: null,
  deliveryDay: '',
  portionsCount: '2',
  phase: '',
  maxNumRecipes: 4,
  isTestOrder: false,
}

export default Order
