import React from 'react'
import type { EmblaOptionsType } from 'embla-carousel'

import {
  AlignItems,
  Box,
  Display,
  DisplayPosition,
  Icon,
  Image,
  JustifyContent,
} from '@gousto-internal/citrus-react'

import { HERO_CAROUSEL_RECIPE_DATA } from '../../config'
import { Carousel } from '../Carousel'
import { RecipeData } from '../../types/recipeData'
import { HomepageSections } from '../../tracking'

const carouselOptions: EmblaOptionsType = {
  loop: true,
}

const propertiesForTracking = {
  carouselSection: HomepageSections.HERO_CAROUSEL,
}

function RecipeSlide({ recipe }: { recipe: RecipeData }) {
  return (
    <Box paddingH={[1, 2]} position={DisplayPosition.Relative}>
      <Image alt={recipe.name} src={recipe.imageUrl} width={['80px', null, '120px']} />
      {recipe.type && (
        <Box
          alignItems={AlignItems.Center}
          bottom="0"
          display={Display.Flex}
          height="28px"
          justifyContent={JustifyContent.Center}
          position={DisplayPosition.Absolute}
          width="28px"
        >
          <Icon name={recipe.type} size={6} />
        </Box>
      )}
    </Box>
  )
}

export function HeroCarouselSection() {
  return (
    <section data-testid="heroCarousel">
      <Carousel
        carouselAreaLabel="Hero recipes"
        showNavButtons={false}
        options={carouselOptions}
        snowplowTrackingProperties={propertiesForTracking}
      >
        {HERO_CAROUSEL_RECIPE_DATA.map((recipe) => (
          <RecipeSlide key={recipe.name.replace(/(\s|,)/gm, '-')} recipe={recipe} />
        ))}
      </Carousel>
    </section>
  )
}
