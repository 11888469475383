import moment from 'moment'
import type { ShippingAddress } from '@library/api-user'

const formatDeliveryDate = (date?: Date | string | null): string => {
  const now = new Date()

  let newDate = date || now

  if (typeof newDate === 'string' && ['null', 'undefined'].includes(newDate)) {
    newDate = now
  }

  try {
    // backend returns date in non ISO compliant format i.e. "2023-01-23 12:35:20"
    const parsedDate = moment(newDate, 'YYYY-MM-DD hh:mm:ss')

    if (!parsedDate.isValid()) {
      throw new Error(`Invalid time value`)
    }

    const result = parsedDate.format('dddd, D MMMM YYYY')

    return result
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw new Error(`Date formatting error "${date}": ${e.message}`)
    }

    throw e
  }
}

const formatShippingAddress = (address?: ShippingAddress): string => {
  if (!address) {
    return ''
  }

  const { line1, line2, line3, postcode } = address
  const orderedAddressItems = [line1, line2, line3, postcode].filter((line: string) => !!line)
  const result = orderedAddressItems.join(', ')

  return result
}

export { formatDeliveryDate, formatShippingAddress }
