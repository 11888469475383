import React from 'react'

import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, useTheme } from '@gousto-internal/citrus-react'
import seedBannerImage from '../../../assets/seedBanner.png'

type SeedBarProps = { side: 'left' | 'right' }

export const SeedBar = ({ side }: SeedBarProps) => {
  const { theme } = useTheme()

  const Component = styled(Box)(() => ({
    transform: side === 'left' ? 'rotate(180deg)' : '',
    backgroundPosition: 'bottom left',
    ...(theme.responsiveStyle(
      [`url(${seedBannerImage})`, `url(${seedBannerImage})`],
      ['backgroundImage'],
    ) as CSSObject),
  }))

  return (
    <Component
      height={['64px', '100%']}
      width={['100%', '128px']}
      minHeight={[null, '340px']}
      data-testid={side === 'left' ? 'seedsBarLeft' : 'seedsBarRight'}
    />
  )
}
