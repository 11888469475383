import { useSelector } from 'react-redux'

import {
  BRAND_PPC_DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
  BRAND_PPC_DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA,
  BRAND_PPC_FREE_DELIVERY_PROMO_CODE_DATA,
  BRAND_PPC_PROMO_CODE_DATA,
  DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA,
  DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA,
  FREE_DELIVERY_PROMO_CODE_DATA,
  PROMO_CODE_DATA,
} from 'routes/Home/homeConfig'
import { getPromoCode, getBasketOrderId } from 'selectors/basket'
import { getUserOrders } from 'selectors/user'

export type DiscountSofteningFreeDeliveryVariances = 't1' | 't2' | 't3' | 'c'

export function useGetDiscountSofteningFreeDeliveryFromPromoCode(): DiscountSofteningFreeDeliveryVariances {
  const basketOrderId = useSelector(getBasketOrderId)
  const userOrders = useSelector(getUserOrders)
  const promoCode = useSelector(getPromoCode)

  let currentPromoCode = promoCode

  if (!currentPromoCode) {
    const currentOrder = userOrders?.get(basketOrderId)
    const currentPrice = currentOrder?.get('prices')
    currentPromoCode = currentPrice?.get('promoCode') || ''
  }

  const promoCodeTreatmentMap = new Map<string, DiscountSofteningFreeDeliveryVariances>([
    [PROMO_CODE_DATA.defaultPromoCode, 'c'],
    [BRAND_PPC_PROMO_CODE_DATA.defaultPromoCode, 'c'],
    [DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA.defaultPromoCode, 't1'],
    [BRAND_PPC_DISCOUNT_SOFTENING_UI_PROMO_CODE_DATA.defaultPromoCode, 't1'],
    [FREE_DELIVERY_PROMO_CODE_DATA.defaultPromoCode, 't2'],
    [BRAND_PPC_FREE_DELIVERY_PROMO_CODE_DATA.defaultPromoCode, 't2'],
    [DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA.defaultPromoCode, 't3'],
    [BRAND_PPC_DISCOUNT_SOFTENING_UI_WITH_FREE_DELIVERY_PROMO_CODE_DATA.defaultPromoCode, 't3'],
  ])

  return promoCodeTreatmentMap.get(currentPromoCode) || 'c'
}
