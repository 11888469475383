import React from 'react'

import { Heading4, Space } from '@gousto-internal/citrus-react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { MenuRecipeNutritionalInformation } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import css from './Nutrition.css'

type NutritionalValueProps = {
  loading: boolean
  value: string
}

type NutritionInfoProps = {
  nutritionValues: MenuRecipeNutritionalInformation
  loading: boolean
  inset?: boolean
}

const NutritionalValue = ({ loading, value }: NutritionalValueProps) =>
  loading ? (
    <span className={css.loadingSkeleton} data-testid="nutritionInfoLoadingSkeleton" />
  ) : (
    <>{value}</>
  )

export const NutritionInfo = ({ nutritionValues, loading, inset = true }: NutritionInfoProps) => {
  const { per_100g: per100g, per_portion: perPortion } = nutritionValues

  return (
    <div>
      <Heading4>Nutrition</Heading4>
      <Space size={4} />
      <table className={classnames(css.table, inset && css.tableMargins)}>
        <thead>
          <tr className={css.tableBorder}>
            <th className={css.tablePadding}>Typical Values</th>
            <th className={css.tablePadding}>per 100g</th>
            <th className={css.tablePadding}>per portion</th>
          </tr>
        </thead>
        <tbody>
          <tr className={css.tableBorder}>
            <td className={css.tablePadding}>Energy</td>
            <td className={css.tablePadding} data-testing="energyPer100g">
              <NutritionalValue loading={loading} value={`${Math.round(per100g.energy_kj)} kJ`} />
              <br />
              <NutritionalValue
                loading={loading}
                value={`${Math.round(per100g.energy_kcal)} kcal`}
              />
            </td>
            <td className={css.tablePadding} data-testing="energyPerPortion">
              <NutritionalValue
                loading={loading}
                value={`${Math.round(perPortion.energy_kj)} kJ`}
              />
              <br />
              <NutritionalValue
                loading={loading}
                value={`${Math.round(perPortion.energy_kcal)} kcal`}
              />
            </td>
          </tr>
          <tr className={css.tableBorder}>
            <td className={css.tablePadding}>
              Fat
              <br />
              of which saturates
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(per100g.fat_mg / 1000).toFixed(1)} g`}
              />
              <br />
              <NutritionalValue
                loading={loading}
                value={`${(per100g.fat_saturates_mg / 1000).toFixed(1)} g`}
              />
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.fat_mg / 1000).toFixed(1)} g`}
              />
              <br />
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.fat_saturates_mg / 1000).toFixed(1)} g`}
              />
            </td>
          </tr>
          <tr className={css.tableBorder}>
            <td className={css.tablePadding}>
              Carbohydrate
              <br />
              of which sugars
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(per100g.carbs_mg / 1000).toFixed(1)} g`}
              />
              <br />
              <NutritionalValue
                loading={loading}
                value={`${(per100g.carbs_sugars_mg / 1000).toFixed(1)} g`}
              />
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.carbs_mg / 1000).toFixed(1)} g`}
              />
              <br />
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.carbs_sugars_mg / 1000).toFixed(1)} g`}
              />
            </td>
          </tr>
          <tr className={css.tableBorder}>
            <td className={css.tablePadding}>Fibre</td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(per100g.fibre_mg / 1000).toFixed(1)} g`}
              />
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.fibre_mg / 1000).toFixed(1)} g`}
              />
            </td>
          </tr>
          <tr className={css.tableBorder}>
            <td className={css.tablePadding}>Protein</td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(per100g.protein_mg / 1000).toFixed(1)} g`}
              />
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.protein_mg / 1000).toFixed(1)} g`}
              />
            </td>
          </tr>
          <tr>
            <td className={css.tablePadding}>Salt</td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(per100g.salt_mg / 1000).toFixed(2)} g`}
              />
            </td>
            <td className={css.tablePadding}>
              <NutritionalValue
                loading={loading}
                value={`${(perPortion.salt_mg / 1000).toFixed(2)} g`}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

NutritionInfo.propTypes = {
  inset: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  nutritionValues: PropTypes.shape({
    per_100g: PropTypes.shape({
      energy_kj: PropTypes.number,
      energy_kcal: PropTypes.number,
      fat_mg: PropTypes.number,
      fat_saturates_mg: PropTypes.number,
      carbs_mg: PropTypes.number,
      carbs_sugars_mg: PropTypes.number,
      fibre_mg: PropTypes.number,
      protein_mg: PropTypes.number,
      salt_mg: PropTypes.number,
    }).isRequired,
    per_portion: PropTypes.shape({
      energy_kj: PropTypes.number,
      energy_kcal: PropTypes.number,
      fat_mg: PropTypes.number,
      fat_saturates_mg: PropTypes.number,
      carbs_mg: PropTypes.number,
      carbs_sugars_mg: PropTypes.number,
      fibre_mg: PropTypes.number,
      protein_mg: PropTypes.number,
      salt_mg: PropTypes.number,
    }).isRequired,
  }).isRequired,
}
