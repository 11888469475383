import React, { useMemo, useState, useEffect } from 'react'

import actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getAvailableBoxSizes } from 'actions/basket'
import { boxSummaryDeliverySlotChosen } from 'actions/boxSummary'
import { loadOptimizelySDK } from 'actions/optimizely'
import userActions from 'actions/user'
import { useIsMenuPersonalisationEnabled } from 'hooks/useIsMenuPersonalisationEnabled'
import { getIsAuthenticated } from 'selectors/auth'
import { getBasketOrderId, getAvailableNumPortions } from 'selectors/basket'
import { getUserId } from 'selectors/user'
import { getTempDeliveryOptions } from 'utils/deliverySlotHelper'

import { useDoubleDeckerNav } from '../../../hooks/useDoubleDeckerNav'
import { useBasket } from '../domains/basket'
import { useCurrentCollectionId } from '../domains/collections'
import { useMenu } from '../domains/menu'
import fetchData from '../fetchData'
import {
  isMenuLoading,
  getMenuLoadingErrorMessage,
  getRecipeCount,
  getHasProspectNotSeenWizard,
} from '../selectors/menu'
import { shouldShowCommunicationPanel, shouldShowCapacityInfo } from '../selectors/signupReduction'
import { MenuRecipesPage } from './MenuRecipesPage'
import { useHotjarIdentify } from './useHotjarIdentify'

const { useMenuPreferences } = require('../domains/menu')

const MenuRecipesPageWrapper = (ownProps) => {
  const {
    params,
    location: { query = {} },
  } = ownProps
  const [hasUpdated, setHasUpdated] = useState(false)
  const dispatch = useDispatch()
  const currentCollectionId = useCurrentCollectionId()
  const { addRecipe, numPortions } = useBasket()
  const { isPending } = useMenu()
  const isDoubleDeckerFeatureOn = useDoubleDeckerNav()
  const stateRecipeCount = useSelector(getRecipeCount)
  const showLoading = useSelector(isMenuLoading)
  const storeOrderId = useSelector(getBasketOrderId)
  const showCommunicationPanel = useSelector(shouldShowCommunicationPanel)
  const userId = useSelector(getUserId)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const showCapacityInfo = useSelector(shouldShowCapacityInfo)
  const menuLoadingErrorMessage = useSelector(getMenuLoadingErrorMessage)

  const hasProspectNotSeenWizard = useSelector(getHasProspectNotSeenWizard)
  const { tempDate, tempSlotId } = useSelector(getTempDeliveryOptions)
  const availableBoxSize = useSelector(getAvailableNumPortions)
  const availableBoxSizeLength = availableBoxSize?.length

  const { data: menuPreferences, isLoading: menuPreferencesIsLoading } = useMenuPreferences()
  const signupDietaryPreferencesEnabled = useIsMenuPersonalisationEnabled()

  useEffect(() => {
    if (hasProspectNotSeenWizard && tempSlotId && tempDate && !hasUpdated) {
      dispatch(boxSummaryDeliverySlotChosen({ slotId: tempSlotId, date: tempDate }))
      setHasUpdated(true)
    }
    if (!availableBoxSizeLength) {
      dispatch(getAvailableBoxSizes())
    }
    if (isAuthenticated && !userId) {
      dispatch(userActions.userLoadData())
    }
  }, [
    dispatch,
    hasProspectNotSeenWizard,
    tempDate,
    tempSlotId,
    availableBoxSizeLength,
    hasUpdated,
    userId,
    isAuthenticated,
  ])

  const actionDispatchers = useMemo(
    () =>
      bindActionCreators(
        {
          basketOrderLoaded: actions.basketOrderLoaded,
          portionSizeSelectedTracking: actions.portionSizeSelectedTracking,
          loadOptimizelySDK,
          fetchMenuData: fetchData,
        },
        dispatch,
      ),
    [dispatch],
  )

  useHotjarIdentify()

  return (
    <MenuRecipesPage
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      stateRecipeCount={stateRecipeCount}
      menuCurrentCollectionId={currentCollectionId}
      showLoading={showLoading || isPending || menuPreferencesIsLoading}
      orderId={params.orderId}
      storeOrderId={storeOrderId}
      numPortions={numPortions}
      showCommunicationPanel={showCommunicationPanel}
      userId={userId}
      shouldShowCapacityInfo={showCapacityInfo}
      menuLoadingErrorMessage={menuLoadingErrorMessage}
      query={query}
      addRecipe={addRecipe}
      // dispatches below
      basketOrderLoaded={actionDispatchers.basketOrderLoaded}
      portionSizeSelectedTracking={actionDispatchers.portionSizeSelectedTracking}
      loadOptimizelySDK={actionDispatchers.loadOptimizelySDK}
      fetchMenuData={actionDispatchers.fetchMenuData}
      isDoubleDeckerFeatureOn={isDoubleDeckerFeatureOn}
      menuPreferences={menuPreferences}
      signupDietaryPreferencesEnabled={signupDietaryPreferencesEnabled}
    />
  )
}

export { MenuRecipesPageWrapper }
