import React, { useState, useCallback } from 'react'
import {
  useModal,
  Box,
  ButtonColorVariant,
  ButtonSize,
  Heading5,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  Body1,
  FlexDirection,
  Space,
  Text,
  FontWeight,
} from '@gousto-internal/citrus-react'
import { useSWRConfig } from 'swr'
import { deleteShippingAddress } from '@library/api-user'
import { ApiKeys } from '../../../enums'
import { Notification } from '../../Notification'

import type { ShippingAddress } from '@library/api-user'

type Props = {
  isOpen?: boolean
  address?: ShippingAddress
}

const initialState = {
  error: false,
}

export const DeleteAddressModal = ({ isOpen = false, address }: Props) => {
  const { mutate } = useSWRConfig()
  const { closeCurrentModal } = useModal()
  const [error, setError] = useState<boolean>(initialState.error)

  const handleCancel = useCallback(() => {
    closeCurrentModal()
  }, [closeCurrentModal])

  const handleDelete = useCallback(async () => {
    setError(initialState.error)

    if (!address) {
      return
    }

    try {
      await deleteShippingAddress(address.id)
      await mutate(ApiKeys.UserCurrentShippingAddress)

      closeCurrentModal()
    } catch (error: unknown) {
      setError(true)
    }
  }, [address, closeCurrentModal, mutate])

  // <Modal> has to be registered regardless it has content or not
  return (
    <Modal name="DeleteAddressModal" isOpen={isOpen}>
      {address && (
        <>
          <ModalHeader>
            <Heading5>Delete address?</Heading5>
          </ModalHeader>
          <ModalBody>
            {error && (
              <>
                <Notification
                  state="error"
                  message="Sorry, we couldn't process your request right now. Please try again."
                />
              </>
            )}
            <Body1>Are you sure you want to delete this address from your account?</Body1>
            <Space size={4} direction="vertical" />
            <Box display="flex" flexDirection={FlexDirection.Column}>
              <Text size={2} fontWeight={FontWeight.Bold}>
                {address.shippingDefault ? `${address.name} (default)` : address.name}
              </Text>
              <Text size={1}>{`${address.line1}, ${address.postcode}`}</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              width="100%"
              colorVariant={ButtonColorVariant.Secondary}
              size={ButtonSize.Medium}
              onClick={handleCancel}
            >
              Cancel
            </ModalButton>
            <ModalButton
              width="100%"
              colorVariant={ButtonColorVariant.Primary}
              size={ButtonSize.Medium}
              onClick={handleDelete}
              disabled={address.shippingDefault}
            >
              Yes, delete address
            </ModalButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
