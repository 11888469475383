import React from 'react'

import { InputField, Space, FormFieldStatus } from '@gousto-internal/citrus-react'

import { usePromoCode } from 'routes/Checkout/Components/PromoCode/promoCodeHooks'
import { checkoutConstants } from 'routes/Checkout/checkoutConstants'

const getInputState = (value: string, isValid: boolean | null): FormFieldStatus | undefined => {
  if (value && isValid !== null) {
    if (isValid) {
      return FormFieldStatus.Success
    } else {
      return FormFieldStatus.Error
    }
  }

  return undefined
}

const getValidationMessage = (promoCode: string, isValid: boolean | null): string =>
  !!promoCode && isValid === false ? checkoutConstants.errorMessage.invalidPromocode : ''

export const PromoCode = () => {
  const { promoCode, isValid, handleChange } = usePromoCode()

  return (
    <>
      <InputField
        id="promoCodeInput"
        label="Discount code"
        type="text"
        name="promoCode"
        data-testid="promoCodeInput"
        value={promoCode}
        onChange={handleChange}
        status={getInputState(promoCode, isValid)}
        validationMessage={getValidationMessage(promoCode, isValid)}
      />
      <Space size={2} />
    </>
  )
}
