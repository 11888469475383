import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import Helmet from 'react-helmet'

import { BoxPricesComponent } from './BoxPricesComponent'
import { seo } from './boxPricesConfig'

const BoxPrices = () => (
  <Box>
    <Helmet title={seo.title} meta={seo.meta} />
    <BoxPricesComponent />
  </Box>
)

export { BoxPrices }
