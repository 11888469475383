import styled from '@emotion/styled'

type MobileContainerProps = { borderColor: string }

export const MobileContainer = styled.div(({ borderColor }: MobileContainerProps) => ({
  width: '100%',
  marginBottom: '0.25rem',
  backgroundColor: 'white',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: borderColor,
  padding: '1rem 0.75rem',
}))

type AnimatedContentProps = { expanded: boolean }

export const AnimatedContent = styled.div(({ expanded }: AnimatedContentProps) => ({
  transition: `max-height ${expanded ? '300ms' : '150ms'} ease-in`,
  maxHeight: expanded ? '1000px' : 0,
  visibility: expanded ? 'visible' : 'hidden',
  opacity: expanded ? 1 : 0,
}))
