import React, { useState, useCallback } from 'react'
import { postRecipeFeedback } from '@library/user-feedback'

import { Box, Color, Heading5, withCitrusTheme } from '@gousto-internal/citrus-react'
import { OnSubmit, RecipeRating, TrackEvent } from '../types'
import { RecipeImage } from './RecipeImage'
import { Rating } from './Rating'
import { PerfectFlow } from './PerfectFlow'
import { PositiveFlow } from './PositiveFlow'
import { NegativeFlow } from './NegativeFlow'
import styled from '@emotion/styled'
import { formatFeedbackRatingRequest } from './Formatters/formatRatings'
import { queueTrackingEvent } from '@library/tracking-integration'

const RATING_ANIMATION_DURATION = 800 //millisecond
const SUBMIT_ANIMATION_DURATION = 400 //millisecond
type FlowComponent = typeof PerfectFlow | typeof PositiveFlow | typeof NegativeFlow
interface FlowDictionary {
  [key: number]: FlowComponent
}
const flowDictionary: FlowDictionary = {
  1: NegativeFlow,
  2: NegativeFlow,
  3: NegativeFlow,
  4: PositiveFlow,
  5: PerfectFlow,
}

const RecipeTileContainer = styled(Box)(() => ({
  '&.submitted': {
    animation: `${SUBMIT_ANIMATION_DURATION}ms hide-animation ease`,
    display: 'none',
  },
  '@keyframes hide-animation': {
    '0%': {
      opacity: 1,
      display: 'block',
    },
    '99%': {
      opacity: 0,
      display: 'block',
    },
    '100%': {
      opacity: 0,
      display: 'none',
    },
  },
}))
const Container = withCitrusTheme(Box, () => ({
  padding: '1rem',
  overflow: 'hidden',
}))
const FlowContainer = styled(Box)(() => ({
  '&.rating div': {
    animation: `${RATING_ANIMATION_DURATION}ms height-up-animation ease`,
    maxHeight: '1000px',
  },
  '&.rating.change-rating div': {
    animation: `${RATING_ANIMATION_DURATION}ms height-down-animation ease`,
  },
  '@keyframes height-up-animation': {
    '0%': {
      opacity: 0,
      maxHeight: '0px',
    },
    '100%': {
      opacity: 1,
      maxHeight: '1000px',
    },
  },
  '@keyframes height-down-animation': {
    '100%': {
      opacity: 0,
      maxHeight: '0px',
    },
  },
}))

export const RecipeTile = ({
  recipe,
  questionId,
  onSubmit,
}: {
  recipe: RecipeRating
  questionId: string
  onSubmit: OnSubmit
}) => {
  const [rating, onRatingUpdate] = useState(0)
  const [changeRating, setChangeRating] = useState(false)
  const [comment, onCommentUpdate] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [selectedOTIFs, setSelectedOTIFs] = useState<string[]>([])
  const ratingType = '5-star'

  const resetFlow = useCallback(() => {
    setChangeRating(true)
    onCommentUpdate('')
    setSelectedOTIFs([])
  }, [onCommentUpdate, setSelectedOTIFs, setChangeRating])

  const handleRatingChange = useCallback(
    (recipeRating: number) => {
      if (rating) {
        resetFlow()
      }
      queueTrackingEvent<TrackEvent>({
        action: 'click_star',
        properties: { recipeId: recipe.id, recipeRating, ratingType },
      })
      setTimeout(
        () => {
          onRatingUpdate(recipeRating)
          setChangeRating(false)
        },
        rating === 0 ? 0 : RATING_ANIMATION_DURATION,
      )
    },
    [rating, resetFlow],
  )

  const handleOTIFSelectionChange = useCallback(
    (selected: boolean, value: string) => {
      if (selected) {
        setSelectedOTIFs([...selectedOTIFs, value])
      } else {
        setSelectedOTIFs([...selectedOTIFs.filter((x) => x !== value)])
      }
    },
    [setSelectedOTIFs, selectedOTIFs],
  )

  const Flow = flowDictionary[rating]

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
    const orderId = recipe.orderId

    if (rating) {
      const formattedRating = formatFeedbackRatingRequest(
        questionId,
        recipe.id,
        rating,
        comment,
        selectedOTIFs,
      )
      postRecipeFeedback({ orderId, body: formattedRating })
        .then(() => {
          setSubmitted(true)
          setTimeout(
            () => onSubmit(recipe.orderId, recipe.id),
            // we need a little gap to capture correct date
            SUBMIT_ANIMATION_DURATION + 50,
          )
        })
        .catch(() => setSubmitting(false))
      queueTrackingEvent<TrackEvent>({
        action: 'rate_recipe',
        properties: {
          recipeId: recipe.id,
          recipeRating: rating,
          commentAdded: !!comment,
          ratingType,
          ratingReason: selectedOTIFs,
        },
      })
    }
  }, [onSubmit, questionId, comment, rating, recipe, selectedOTIFs])

  return (
    <RecipeTileContainer
      width="325px"
      style={{ backgroundColor: Color.White, height: 'fit-content' }}
      className={submitted ? 'submitted' : ''}
      data-type="recipe-tile"
      data-orderdate={recipe.orderDate}
    >
      <RecipeImage recipe={recipe} />
      <Container>
        <Heading5 size={4} style={{ minHeight: '48px' }}>
          {recipe.name}
        </Heading5>
        <Rating rating={rating} onChange={handleRatingChange} />
        <FlowContainer className={rating ? (changeRating ? 'rating change-rating' : 'rating') : ''}>
          {Flow && (
            <Flow
              onSubmit={handleSubmit}
              isSubmitting={submitting}
              submitted={false}
              onCommentUpdate={onCommentUpdate}
              comment={comment}
              selectedItems={selectedOTIFs}
              onOptionSelected={(selected, value) => {
                handleOTIFSelectionChange(selected, value)
                queueTrackingEvent<TrackEvent>({
                  action: 'click_reason',
                  properties: {
                    recipeId: recipe.id,
                    ratingReason: value,
                    recipeRating: rating,
                    ratingType,
                  },
                })
              }}
            />
          )}
        </FlowContainer>
      </Container>
    </RecipeTileContainer>
  )
}
