import React, { useCallback } from 'react'
import {
  Button,
  ButtonColorVariant,
  ButtonSize,
  Icon,
  IconVariant,
  useModal,
} from '@gousto-internal/citrus-react'
import { CreateAddressModal } from './CreateAddressModal'

function AddNewAddressButton() {
  const { openModal } = useModal()
  const onButtonClick = useCallback(() => {
    openModal('CreateAddressModal')
  }, [openModal])

  return (
    <>
      <CreateAddressModal />
      <Button
        size={ButtonSize.Medium}
        colorVariant={ButtonColorVariant.Secondary}
        onClick={onButtonClick}
        width="100%"
      >
        <Icon name="add" variant={IconVariant.Actionable} /> {`Add new address`}
      </Button>
    </>
  )
}

export { AddNewAddressButton }
