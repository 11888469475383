import React from 'react'
import LazyLoad from 'react-lazyload'
import { screen } from '../../../styles'
import { CSSObject } from '@emotion/react'

import { getRecipeImage } from './getRecipeImage'
import { withCitrusTheme, DisplayPosition } from '@gousto-internal/citrus-react'
import { Recipe } from '../../../model/recipe'

const StyledImage = withCitrusTheme('img', () => ({
  position: DisplayPosition.Absolute,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: 'scale(1.1)',
  [`@media ${screen.screenSMMin}`]: {
    position: DisplayPosition.Relative,
  },
}))

export const Image = ({
  lazy,
  recipe,
  insideOverflow = false,
}: {
  lazy?: boolean
  styles?: CSSObject
  recipe: Recipe
  insideOverflow?: boolean
}) => {
  const recipeImage = getRecipeImage(recipe)

  if (!recipeImage) {
    return null
  }

  const [image, srcSet] = recipeImage

  const imageComponent = (
    <StyledImage
      alt={recipe.title}
      src={image}
      srcSet={srcSet}
      sizes="(max-width: 500px) 700px, (max-width: 991px) 1000px, 700px"
    />
  )

  if (lazy) {
    return (
      <LazyLoad once offset={200} height={0} overflow={insideOverflow}>
        {imageComponent}
      </LazyLoad>
    )
  }

  return imageComponent
}
