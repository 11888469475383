import {
  Accordion,
  AlignItems,
  Box,
  Button,
  ButtonColorVariant,
  ButtonSize,
  FlexDirection,
  FormFieldStatus,
  InputField,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { UserID, fetchPaymentDetails } from '@library/api-payment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { AccountDetailsContext } from '../../../AccountDetailsContext'
import { DataFrame, Title } from '../../../enums'
import { HostedFieldsInputFields } from './HostedFieldsInputFields'

import { ActionType } from '../../../interfaces'
import { Notification } from '../../Notification'
import { RenderedTitle } from '../../RenderedTitle'
import { useBraintreeClientInstance } from '../hooks/useBraintreeClientInstance'
import { useHostedFieldsFrame } from '../hooks/useHostedFieldsFrame'
import { Form } from './Form'
import { Spinner } from './Spinner'

type Props = {
  userId: UserID
}

enum BraintreeCardInputValidation {
  InvalidCardNumber = 'Please enter a valid card number',
  InvalidExpiryDate = 'Please enter a valid expiry date',
  InvalidCVV = 'Please enter a valid CVV code',
}

// Invalidate special characters
const invalidNameOnCardPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

const renderTitle = (isExpanded: boolean, title: string, content: string) => {
  return (
    <RenderedTitle isExpanded={isExpanded} title={title} iconType="credit_card">
      <Text size={2}>{content}</Text>
    </RenderedTitle>
  )
}

const initialState = {
  inputNameOnCard: '',
  inputNameOnCardError: false,
  accordion: 'expanded',
}

type ErrorNotificationProps = {
  isFormSubmitting: boolean
  error: string
}

const ErrorNotification = ({ isFormSubmitting, error }: ErrorNotificationProps) => {
  if (!isFormSubmitting && error) {
    return <Notification state="error" message={error} />
  }

  return null
}

const SuccessfulPaymentUpdate = ({
  isUpdateSuccessful,
  braintreeCardConfirmationError,
  setIsExpanded,
}: {
  isUpdateSuccessful: boolean
  braintreeCardConfirmationError: string
  setIsExpanded: (arg: boolean) => void
}) => {
  useEffect(() => {
    if (isUpdateSuccessful && !braintreeCardConfirmationError) {
      setIsExpanded(false)
    }
  }, [braintreeCardConfirmationError, isUpdateSuccessful, setIsExpanded])

  return null
}

const AccordionHeader = ({ isExpanded, isUpdateSuccessful, lastFourDigits, data }: any) => {
  const lastFour = `############${lastFourDigits}`
  if (isUpdateSuccessful) {
    return renderTitle(isExpanded, Title.CARD, lastFour)
  }

  switch (data?.method) {
    case 'card':
      const paymentCardNumber = isUpdateSuccessful ? lastFourDigits : data?.details.number
      return renderTitle(isExpanded, Title.CARD, paymentCardNumber)
    case 'paypal':
      return renderTitle(isExpanded, Title.PAYPAL, 'PayPal')
    case 'applepay':
      return renderTitle(isExpanded, Title.APPLE_PAY, 'Apple Pay')
    default:
      return renderTitle(isExpanded, Title.CARD, 'No payment data found')
  }
}

export const PaymentCardSection = (props: Props) => {
  const { data } = useSWR(props.userId, fetchPaymentDetails)
  const [inputNameOnCard, setInputNameOnCard] = useState<string>(initialState.inputNameOnCard)
  const [inputNameOnCardError, setInputNameOnCardError] = useState<boolean>(false)
  const { state, dispatch } = useContext(AccountDetailsContext)
  const { braintreeClientInstance, braintreeError, deviceData } = useBraintreeClientInstance()
  const {
    handleCardChange,
    braintreeFormValidationError,
    isUpdateSuccessful,
    isHostedFieldsReady,
    isFormSubmitting,
    braintreeCardConfirmationError,
    isFormValid,
    resetFields,
    lastFourDigits,
  } = useHostedFieldsFrame({
    braintreeClientInstance,
    deviceData,
  })

  const resetState = useCallback(() => {
    resetFields()
    setInputNameOnCard('')
    setInputNameOnCardError(false)
  }, [resetFields])

  const onChangeCallback = useCallback(() => {
    dispatch({
      type: ActionType.RESET_CLICKED_STATE,
    })

    resetState()
  }, [dispatch, resetState])

  const handleNameOnCard = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement
      const invalidField =
        target.value.match(invalidNameOnCardPattern) !== null || target.value.length < 1

      setInputNameOnCardError(invalidField)
      setInputNameOnCard(target.value)
    },
    [setInputNameOnCard, setInputNameOnCardError],
  )

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()
      handleCardChange()
    },
    [handleCardChange],
  )

  const isCardInvalid = !inputNameOnCard || inputNameOnCardError
  const isFormInvalid = !isFormValid || isFormSubmitting
  const showIsDisabled = isFormInvalid || isCardInvalid

  return (
    <>
      <Accordion
        id={'payment-details-accordion'}
        onChange={onChangeCallback}
        initialState={state.paymentInfoSectionExpanded ? 'expanded' : undefined}
        title={(isExpanded) => (
          <AccordionHeader
            isExpanded={isExpanded}
            data={data}
            isUpdateSuccessful={isUpdateSuccessful}
            lastFourDigits={lastFourDigits}
          />
        )}
      >
        {({ setIsExpanded }) => (
          <>
            {!isHostedFieldsReady && (
              <Box
                data-testid="loadingCardPayment"
                display="flex"
                alignItems={AlignItems.Center}
                justifyContent={JustifyContent.Center}
              >
                <Spinner />
              </Box>
            )}

            {/* Form has to be rendered so braintree can read input data-frames when it starts*/}
            <Form
              data-testid="formCardPayment"
              onSubmit={handleSubmit}
              className={isHostedFieldsReady ? 'form--activated' : undefined}
            >
              <SuccessfulPaymentUpdate
                isUpdateSuccessful={isUpdateSuccessful}
                braintreeCardConfirmationError={braintreeCardConfirmationError}
                setIsExpanded={setIsExpanded}
              />
              <ErrorNotification isFormSubmitting={isFormSubmitting} error={braintreeError} />
              <ErrorNotification
                isFormSubmitting={isFormSubmitting}
                error={braintreeCardConfirmationError}
              />
              <Box paddingBottom={2}>
                {/* Name on card is not an input required by braintree - however we will pass in a later epic */}
                <InputField
                  id="nameOnCard"
                  label="Name on card"
                  required
                  value={inputNameOnCard}
                  validationMessage={inputNameOnCardError && 'Please enter a valid name on card'}
                  status={inputNameOnCardError ? FormFieldStatus.Error : undefined}
                  onChange={handleNameOnCard}
                />
              </Box>
              <HostedFieldsInputFields
                label="Card number"
                id={DataFrame.CARD_NUMBER}
                showError={braintreeFormValidationError.showCardNumberError}
                errorMessage={BraintreeCardInputValidation.InvalidCardNumber}
              />
              <Box paddingV={2} display="flex" flexDirection={FlexDirection.Row}>
                <Box paddingRight={2}>
                  <HostedFieldsInputFields
                    label="Expiry date"
                    id={DataFrame.EXPIRY_DATE}
                    showError={braintreeFormValidationError.showExpiryDateError}
                    errorMessage={BraintreeCardInputValidation.InvalidExpiryDate}
                  />
                </Box>
                <Box>
                  <HostedFieldsInputFields
                    label="CVV"
                    id={DataFrame.CVV}
                    showError={braintreeFormValidationError.showCVVError}
                    errorMessage={BraintreeCardInputValidation.InvalidCVV}
                  />
                </Box>
              </Box>
              <Space direction="vertical" size={2} />
              <Button
                colorVariant={ButtonColorVariant.Primary}
                width="100%"
                type="submit"
                size={ButtonSize.Medium}
                disabled={showIsDisabled}
              >
                {isFormSubmitting ? <Spinner data-testid="paymentUpdateSpinner" /> : 'Save changes'}
              </Button>
            </Form>
          </>
        )}
      </Accordion>
    </>
  )
}
