import styled from '@emotion/styled'
import { withCitrusTheme, Box } from '@gousto-internal/citrus-react'
import Svg from 'Svg'

export const InputFrame = withCitrusTheme(Box, (theme) => ({
  fontFamily: theme.tokens.FontFamilies.book,
  width: '100%',
  height: '3rem',
  borderRadius: '3px',
  visibility: 'hidden',
  border: `1px solid ${theme.tokens.colors.ColdGrey_200}`,
  iframe: {
    borderRadius: '3px',
  },
  '&[class~="frame--activated"]': {
    visibility: 'visible',
  },
  '&:hover': {
    borderColor: theme.tokens.colors.Secondary_400,
  },
  '&[class~="frame--focus"]': {
    borderColor: theme.tokens.colors.Secondary_400,
    borderWidth: '2px',
  },
  '&[class~="frame--invalid"]': {
    borderColor: theme.tokens.colors.Error_900,
    borderWidth: '2px',
  },
}))

export const SvgStyled = styled(Svg)({
  width: '24px',
  height: '24px',
})

export const CheckoutStyles = {
  base: {
    fontSize: '15px',
    lineHeight: '24px',
    padding: '0 0.75rem',
    display: 'flex',
    alignItems: 'center',
    minHeight: '3rem',
    height: '3rem',
    letterSpacing: 0,
    fontFamily: 'AxiformaBook, Roboto, sans-serif',
    color: '#333d47',
  },
  placeholder: {
    base: {
      opacity: 0,
    },
  },
}

export const HostedFieldsUI = withCitrusTheme(Box, (theme) => ({
  minHeight: '3rem',
  height: '3rem',
  border: `1px solid ${theme.tokens.colors.ColdGrey_200}`,
  borderRadius: '3px',
  padding: '0 0.75rem',
  '&:hover': {
    borderColor: theme.tokens.colors.Secondary_400,
  },
  '&[class~="braintree-hosted-fields-focused"]': {
    borderColor: theme.tokens.colors.Secondary_400,
    borderWidth: '2px',
  },
  '&[class~="braintree-hosted-fields-invalid"]': {
    borderColor: theme.tokens.colors.Error_900,
    borderWidth: '2px',
  },
}))
