import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FlexDirection,
  Icon,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

const DeliveryEducationBanner = () => (
  <>
    <Box
      bg={Color.Informative_50}
      paddingH={4}
      paddingV={4}
      borderStyle={BorderStyle.Solid}
      borderColor={Color.Informative_200}
      borderWidth={0.5}
      borderRadius={1.5}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
    >
      <Box display={Display.Flex} alignItems={AlignItems.Center} paddingBottom={4}>
        <Icon name="cool" variant={IconVariant.Informative} />
        <Space size={4} direction="horizontal" />
        <Text size={2}>Insulated packaging keeps your ingredients fresh for up to 12 hours.</Text>
      </Box>
      <Box display={Display.Flex} alignItems={AlignItems.Center}>
        <Icon name="delivery" variant={IconVariant.Informative} />
        <Space size={4} direction="horizontal" />
        <Text size={2}>Delivery slot updates on the day via text and email.</Text>
      </Box>
    </Box>
    <Space size={8} />
  </>
)

export { DeliveryEducationBanner }
