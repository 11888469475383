import { endpoint } from '@library/endpoint'

import { fetch, fetchRaw } from 'utils/fetch'

const fetchOrderIssues = (accessToken) => {
  const url = `${endpoint('ssr')}/ssr/categories`

  return fetch(accessToken, url, null, 'GET')
}

const requestRecipeCardsWithIssueReasons = (
  accessToken,
  customerId,
  orderId,
  addressId,
  issues,
) => {
  const url = `${endpoint('ssrrecipecards')}/request-recipe-cards`
  const issuesTransformed = issues.map((issue) => ({
    core_recipe_id: issue.coreRecipeId,
    category_id: issue.complaintCategoryId,
  }))

  return fetchRaw(
    url,
    {
      customer_id: customerId,
      order_id: orderId,
      address_id: addressId,
      issues: issuesTransformed,
    },
    {
      accessToken,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export { fetchOrderIssues, requestRecipeCardsWithIssueReasons }
