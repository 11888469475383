import React from 'react'

import {
  Body2,
  Box,
  Display,
  FlexDirection,
  Heading5,
  Space,
  Visible,
} from '@gousto-internal/citrus-react'

import { LoadingSkeleton } from './LoadingSkeleton'

const DesktopRelevantRecipesSkeleton = () => (
  <>
    {Array.from({ length: 4 }, (_, index) => index).map((value) => (
      <Box display={Display.Flex} key={value} paddingBottom={1}>
        <Box flexShrink={0} paddingRight={4}>
          <LoadingSkeleton style={{ height: '120px', width: '120px' }} />
        </Box>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          paddingRight={12}
          width="100%"
        >
          <LoadingSkeleton style={{ height: '24px', marginBottom: '4px', width: '100%' }} />
          <LoadingSkeleton style={{ height: '24px', marginBottom: '4px', width: '30%' }} />
          <LoadingSkeleton style={{ height: '24px', width: '50%' }} />
        </Box>
        <Box flexShrink={0}>
          <LoadingSkeleton style={{ height: '44px', width: '44px' }} />
        </Box>
      </Box>
    ))}
  </>
)

const MobileRelevantRecipesSkeleton = () => (
  <>
    {Array.from({ length: 2 }, (_, index) => index).map((value) => (
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        key={value}
        paddingBottom={4}
      >
        <Box width="100%">
          <LoadingSkeleton style={{ height: '200px', width: '100%' }} />
        </Box>
        <Box display={Display.Flex} flexDirection={FlexDirection.Column} width="100%">
          <LoadingSkeleton style={{ height: '24px', marginBottom: '4px', width: '100%' }} />
          <LoadingSkeleton style={{ height: '24px', marginBottom: '6px', width: '10%' }} />
          <LoadingSkeleton style={{ height: '24px', width: '50%' }} />
        </Box>
      </Box>
    ))}
  </>
)

export const RelevantRecipesSkeleton = () => (
  <Box>
    <Heading5>Relevant Recipes</Heading5>
    <Body2>On this week&apos;s menu</Body2>
    <Space size={4} />
    <Visible display={[Display.None, Display.Block]}>
      <DesktopRelevantRecipesSkeleton />
    </Visible>
    <Visible display={[Display.Block, Display.None]}>
      <MobileRelevantRecipesSkeleton />
    </Visible>
  </Box>
)
