import {
  DEFAULT_PER_PREFERENCE,
  DIET_TYPE_CLAIMS,
  DIETARY_PREFERENCE_MAPPING,
  NUMBER_OF_REQUIRED_RECIPES,
  PREFERENCES_LOOKUP,
} from './dietaryBasedRecipesConfig'
import {
  DietaryPreference,
  DietaryPreferences,
  MappedDietaryPreference,
  Recipe,
  RecipeId,
} from './recipeTypes'

// FNV-1a (Fowler–Noll–Vo hash function)
function simpleHash(str: string): number {
  const FNV_PRIME = 1099511628211 // 32-bit version number
  let hash = 2166136261 // FNV-1a initial hash offset basis
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash ^= str.charCodeAt(i)
    hash *= FNV_PRIME
    // eslint-disable-next-line no-bitwise
    hash = (hash * 16777619) >>> 0
  }

  return hash
}

export function shuffleRecipes(recipes: Recipe[]): Recipe[] {
  const shuffledRecipes = [...recipes]

  return shuffledRecipes.sort(
    (recipesA, recipeB) => simpleHash(recipesA.uuid) - simpleHash(recipeB.uuid),
  )
}

// Retrieve the number of recipes per preference based on lookup
export function retrieveNumberPerPreference(
  selectedDietaryPreferences: DietaryPreferences,
): number {
  return PREFERENCES_LOOKUP[selectedDietaryPreferences.length] || DEFAULT_PER_PREFERENCE
}

// Map dietary preferences to either dietary claims or dietary type based rules
export function mapDietaryPreference(
  dietaryPreference: DietaryPreference,
  preferenceLookup = DIETARY_PREFERENCE_MAPPING,
): MappedDietaryPreference | null {
  return preferenceLookup[dietaryPreference] || null
}

// Helper function to filter recipes based on dietary claim or diet type
export function filterRecipesByMappedPreference(
  allRecipes: Recipe[],
  mappedPreference: MappedDietaryPreference,
  matchedRecipeIds: Set<RecipeId>,
  numberPerPreference: number,
): Recipe[] {
  const isExclusionPreference = mappedPreference.startsWith('not')
  const preference = isExclusionPreference ? mappedPreference.slice(3) : mappedPreference

  return allRecipes
    .filter((recipe) => {
      if (matchedRecipeIds.has(recipe.id)) return false
      if (DIET_TYPE_CLAIMS.indexOf(preference) !== -1) {
        const matchesType = recipe.dietType === preference

        return isExclusionPreference ? !matchesType : matchesType
      } else {
        const matchesPreference =
          recipe.dietaryClaims && recipe.dietaryClaims.some((claim) => claim.slug === preference)

        return isExclusionPreference ? !matchesPreference : matchesPreference
      }
    })
    .slice(0, numberPerPreference)
}

// Main function to fetch recipes based on dietary preferences
export function getPreferredRecipes(
  allRecipes: Recipe[],
  selectedDietaryPreferences: DietaryPreferences,
  numberRequiredRecipes = NUMBER_OF_REQUIRED_RECIPES,
): Recipe[] {
  const numberPerPreference = retrieveNumberPerPreference(selectedDietaryPreferences)

  const matchedRecipes: Recipe[] = []
  const matchedRecipeIds = new Set<RecipeId>()

  selectedDietaryPreferences.forEach((dietaryPreference: DietaryPreference) => {
    const mappedPreference: MappedDietaryPreference | null = mapDietaryPreference(dietaryPreference)
    if (!mappedPreference) {
      return // Skip invalid preferences
    }
    const selectedRecipes = filterRecipesByMappedPreference(
      allRecipes,
      mappedPreference,
      matchedRecipeIds,
      numberPerPreference,
    )
    selectedRecipes.forEach((recipe) => {
      matchedRecipes.push(recipe)
      matchedRecipeIds.add(recipe.id)
    })
  })

  // Add remaining recipes to meet the required number
  if (matchedRecipes.length < numberRequiredRecipes) {
    const additionalRecipes = allRecipes.filter((recipe) => !matchedRecipeIds.has(recipe.id))
    matchedRecipes.push(
      ...additionalRecipes.slice(0, numberRequiredRecipes - matchedRecipes.length),
    )
  }

  return shuffleRecipes(matchedRecipes)
}
