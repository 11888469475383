import { endpoint } from '@library/endpoint'

import { fetch } from 'utils/fetch'
import { getCheckoutComPublicKey, isProd } from 'utils/isomorphicEnvironment'

import {
  AuthPaymentReqData,
  SignupPaymentReqData,
  SignupPaymentProvider,
} from './models/CheckoutTypes'

const getPaymentsEndpoint = () => `${endpoint('payments')}/payments/`

const HEADERS = {
  'Content-Type': 'application/json',
}

function authPayment(reqData: AuthPaymentReqData, sessionId: string) {
  return fetch(
    null,
    `${getPaymentsEndpoint()}payment-auth?session_id=${sessionId}`,
    reqData,
    'POST',
    undefined,
    HEADERS,
  )
}

function checkPayment(checkoutSessionId: string, goustoSessionId: string) {
  return fetch(
    null,
    `${getPaymentsEndpoint()}payments/${checkoutSessionId}?session_id=${goustoSessionId}`,
    undefined,
    'GET',
    undefined,
    HEADERS,
  )
}

function fetchPayPalToken() {
  const params = { provider: 'paypal' }

  return fetch(null, `${getPaymentsEndpoint()}token`, params, 'GET', undefined, HEADERS)
}

function signupPayment(
  reqData: SignupPaymentReqData,
  provider: SignupPaymentProvider,
  sessionId: string,
) {
  return fetch(
    null,
    `${getPaymentsEndpoint()}signup-payments?provider=${provider}&session_id=${sessionId}`,
    reqData,
    'POST',
    undefined,
    HEADERS,
  )
}

function get3DSCompliantToken(goustoRef: string) {
  return fetch(
    null,
    `${getPaymentsEndpoint()}3ds-compliant/${goustoRef}`,
    {},
    'GET',
    undefined,
    HEADERS,
  )
}

function validateApplePaySession(validationURL: string) {
  return fetch(
    null,
    `${getPaymentsEndpoint()}validate-merchant-session?provider=applepay`,
    {
      apple_url: validationURL,
      display_name: 'Gousto payment',
    },
    'POST',
    undefined,
    HEADERS,
  )
}

/**
 * Converts Apple Pay token to checkout card token.
 */
function convertApplePayTokenToCheckoutToken(token: string) {
  const CHECKOUT_TOKENS_URL = `https://api.${isProd() ? '' : 'sandbox.'}checkout.com/tokens`

  return fetch(
    null,
    CHECKOUT_TOKENS_URL,
    {
      type: 'applepay',
      token_data: token,
    },
    'POST',
    undefined,
    {
      ...HEADERS,
      Authorization: getCheckoutComPublicKey(),
    },
  )
}

export {
  authPayment,
  checkPayment,
  fetchPayPalToken,
  signupPayment,
  get3DSCompliantToken,
  validateApplePaySession,
  convertApplePayTokenToCheckoutToken,
}
