import { getEnvironment } from '@library/environment/src/isomorphicEnvironment'

import { clearRoktData, clearTapjoyData, trackAffiliatePurchase } from 'actions/tracking'
import { gaTrackingConfig } from 'config/head/gaTracking'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { getPromoCode } from 'selectors/basket'
import { canUseWindow } from 'utils/browserEnvironment'

export const trackPurchase: CheckoutActionCreator =
  ({ orderId, pricing }) =>
  (dispatch, getState) => {
    const state = getState()
    const promoCode = getPromoCode(state)
    const { grossTotal: totalPrice, deliveryTotal: shippingPrice, total } = pricing

    const gaIDTracking = gaTrackingConfig[getEnvironment()]

    if (canUseWindow()) {
      const googleAnalytics = window.ga

      if (typeof googleAnalytics !== 'undefined') {
        googleAnalytics('create', gaIDTracking, 'auto', 'gousto')
        googleAnalytics('gousto.require', 'ec')
        googleAnalytics('gousto.ec:setAction', 'purchase', {
          id: orderId,
          revenue: totalPrice,
          shipping: shippingPrice,
          coupon: promoCode,
        })
        googleAnalytics('gousto.send', 'pageview')
      }
    }

    dispatch(
      trackAffiliatePurchase({
        orderId,
        total: total || '',
        commissionGroup: 'FIRSTPURCHASE',
        promoCode,
        isSignup: true,
      }),
    )
    dispatch(clearTapjoyData())
    dispatch(clearRoktData())
  }
