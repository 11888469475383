import Immutable from 'immutable'

import { actionTypes } from 'actions/actionTypes'

/**
 * Legacy code for setting Optimizely Web variants
 *
 * NOTE: Optimizely Web script can be blocked by cookie consent settings if users
 * do not consent to analytics cookies. Adding new flags to this reducer is not recommended.
 */

export const defaultFeatures = () => ({
  /*
   * TODO: remove default features
   * once something becomes default behavior, we should update the code rather than continue to drive it by feature flags
   */
  nextDayDeliveryPaintedDoor: {
    value: false,
  },
  goToMyGousto: {
    value: false,
  },
  goToMyDeliveries: {
    value: false,
  },
  sortMarketProducts: {
    value: false,
  },
  isMobileMenuModalAppAwarenessEnabled: {
    value: false,
  },
  isGoustoOnDemandEnabled: {
    value: false,
  },
  beetrootsDiscountPromoBannerEnabled: {
    value: false,
  },
  beetrootsIsCheckoutRefactoredEnabled: {
    value: false,
  },
})

export const initialState = () => Immutable.fromJS(defaultFeatures())

export const featuresReducers = {
  features: (state = initialState(), action) => {
    switch (action.type) {
    case actionTypes.FEATURES_SET: {
      let interimState = state
      action.features.forEach((f) => {
        interimState = interimState.set(
          f.feature,
          Immutable.fromJS({
            value: f.value,
          }),
        )
      })

      return interimState
    }
    default:
      return state
    }
  },
}
