import { composeParser } from '../compose'
import { ResponseMiddleware } from '../types'
import { hasProp, isNonNullObject } from '../util'
import { BadJSON, ErrorJSON, ErrorResponse, MalformedType } from './errors'
import { jsonParse } from './json'
import { parseObjectKeysToCamelCase } from './objectKeys'

/**
 * Parsing middleware for Core service responses
 * ============================================================================
 */

/**
 * Types
 * ============================================================================
 */

// The structure of Core responses is normally dictated by the BaseController.php and
// ResponseMacroServiceProvider.php (the latter wraps `data` in a `result`) PROVIDED
// that the controller uses Response::api.
//
// However, a handful of routes may use Response::json with the data NOT wrapped in "result".
type CoreData<T> = CoreAPIData<T> | CoreJSONData<T>

// For responses using Response::api()
type CoreAPIData<T> = {
  status: 'ok'
  result: {
    data: T
  }
  meta?: unknown
}

// For responses using Response::json()
type CoreJSONData<T> = {
  status: 'ok'
  data: T
  meta?: unknown
}

type CoreError = {
  status: 'error'
  error?: string
  'error-details'?: unknown
}

/**
 * Parsers
 * ============================================================================
 */

/**
 * Creates a parser to extract 'data' from successful Core responses (or throw
 * errors otherwise)
 *
 * Usage:
 *
 * composeFetch(
 *   userEndpoint,
 *   extractCoreData<User>()
 * )
 */
export function extractCoreData<T>(): ResponseMiddleware<Response, T> {
  return composeParser(
    parseCoreResponse,
    (value) => ('result' in value ? value.result.data : value.data),
    (value) => parseObjectKeysToCamelCase(value) as T,
  )
}

export async function parseCoreResponse(resp: Response): Promise<CoreData<unknown>> {
  const jsonParseResult = await jsonParse(resp)

  // If we receive an error status, begin decoding as an error
  if (!resp.ok) {
    if (jsonParseResult.type === 'success') {
      throw new ErrorJSON(resp, jsonParseResult.value)
    } else {
      // Didn't decode any error JSON
      throw new ErrorResponse(resp)
    }
  }

  // If we receive 2XX but we failed to read JSON, that's invalid
  if (jsonParseResult.type === 'failure') {
    throw new BadJSON(jsonParseResult.error)
  }

  const { value } = jsonParseResult

  // If response had 2XX, it could still encode an error (BaseController::apiDetailedError does this)
  if (isCoreError(value)) {
    throw new ErrorJSON(resp, value)
  }

  if (!isCoreData(value)) {
    throw new MalformedType(`tried to decode a CoreData but got json=${JSON.stringify(value)}`)
  }

  return value
}

function isCoreError(value: unknown): value is CoreError {
  return isNonNullObject(value) && hasProp(value, 'status') && value.status === 'error'
}

function isCoreData(value: unknown): value is CoreData<unknown> {
  if (!isNonNullObject(value) || !hasProp(value, 'status') || value.status !== 'ok') return false

  if (hasProp(value, 'result')) {
    return isNonNullObject(value.result) && hasProp(value.result, 'data')
  } else if (hasProp(value, 'data')) {
    return isNonNullObject(value.data)
  } else {
    return false
  }
}
