import React from 'react'

import {
  BorderStyle,
  Box,
  Color,
  Display,
  FontWeight,
  Icon,
  IconNames,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

const states = {
  error: {
    bg: Color.Error_50,
    borderColor: Color.Error_900,
    iconName: 'error',
    iconVariant: IconVariant.Error,
  },
  success: {
    bg: Color.Success_50,
    borderColor: Color.Success_900,
    iconName: 'success',
    iconVariant: IconVariant.Confirmation,
  },
}

type Props = {
  state: 'error' | 'success'
  message: string
  body?: string
}

const Notification = ({ body, state, message }: Props) => (
  <>
    <Box
      data-testid={`notification-${states[state].iconName}`}
      bg={states[state].bg}
      borderColor={states[state].borderColor}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      paddingH={4}
      paddingV={4}
      width="100%"
      borderRadius={1.5}
    >
      <Box display={Display.Flex}>
        <Icon name={states[state].iconName as IconNames} variant={states[state].iconVariant} />
        <Space size={2} direction="horizontal" />
        <Box paddingV={1}>
          <Text size={1} fontWeight={FontWeight.Bold}>
            {message}
          </Text>
          {body ? <Text size={1}>{body}</Text> : null}
        </Box>
      </Box>
    </Box>
    <Space size={4} direction="vertical" />
  </>
)

export { Notification }
