import {
  Body1,
  Bold,
  Box,
  Display,
  FlexDirection,
  Icon,
  IconVariant,
  Space,
} from '@gousto-internal/citrus-react'
import React from 'react'

export const EnvironmentalBenefitsCopy = ({
  ENVIRONMENTAL_BENEFITS_BULLET,
}: {
  ENVIRONMENTAL_BENEFITS_BULLET: {
    firstLine: string
    secondLine: string
    thirdLine: string
    sup?: string
  }
}) => (
  <Box display={Display.Flex} flexDirection={FlexDirection.Row}>
    <Icon name="tick" variant={IconVariant.Confirmation} />
    <Space direction="horizontal" size={1} />
    <Body1>
      {ENVIRONMENTAL_BENEFITS_BULLET.firstLine}{' '}
      <Bold>{ENVIRONMENTAL_BENEFITS_BULLET.secondLine}</Bold>{' '}
      {ENVIRONMENTAL_BENEFITS_BULLET.thirdLine}
      {ENVIRONMENTAL_BENEFITS_BULLET.sup && <sup>{ENVIRONMENTAL_BENEFITS_BULLET.sup}</sup>}
      {'.'}
    </Body1>
  </Box>
)
