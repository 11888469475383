import { actionTypes } from 'actions/actionTypes'
import Immutable from 'immutable'

const data = Immutable.fromJS({
  orderId: null,
  visibility: false,
})

export const accountReducers = {
  orderCancelledModalVisibility: (state = data, action) => {
    switch (action.type) {
    case actionTypes.ORDER_CANCELLED_MODAL_VISIBILITY_CHANGE: {
      return state.merge({
        visibility: action.data.visibility,
        orderId: action.data.orderId,
      })
    }

    default:
      return state
    }
  },

  expiredBillingModalVisibility: (state = false, action) => {
    switch (action.type) {
    case actionTypes.EXPIRED_BILLING_MODAL_VISIBILITY_CHANGE: {
      return action.visibility
    }

    default: {
      return state
    }
    }
  },
}
