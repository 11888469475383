import { actionTypes } from 'actions/actionTypes'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const saveApplePayDeliveryAddress: CheckoutActionCreator = ({
  addressLines,
  administrativeArea,
  postalCode,
  locality,
}: // eslint-disable-next-line no-undef
ApplePayJS.ApplePayPaymentContact) => ({
  type: actionTypes.SAVE_APPLE_PAY_DELIVERY_ADDRESS,
  deliveryAddress: {
    name: 'My Address',
    line1: addressLines?.[0],
    line2: addressLines?.[1],
    line3: addressLines?.[2] ?? '',
    town: locality,
    county: administrativeArea,
    postcode: postalCode,
  },
})
