import React from 'react'
import { Box, Display, DisplayPosition, Space, ZIndex } from '@gousto-internal/citrus-react'
import { Background } from './components/Background/Background'
import { Carousel } from './components/Carousel/Carousel'
import { CTA } from './components/CTA/CTA'
import { Banner } from './components/Banner/Banner'

export type LandingPageAboveTheFoldProps = {
  onCTAClick: () => void
  signupCTA: string
}

export function LandingPageAboveTheFold({ onCTAClick, signupCTA }: LandingPageAboveTheFoldProps) {
  return (
    <Box data-testid="landing-page-above-the-fold">
      <Box
        position={DisplayPosition.Relative}
        display={Display.Flex}
        flexBasis={'100%'}
        width={'100%'}
        height={'610px'}
      >
        <Box
          width="100%"
          position={DisplayPosition.Absolute}
          zIndex={ZIndex.Mask}
          bottom={['0px', '0px', '0px', '108px']}
        >
          <CTA onCTAClick={onCTAClick} signupCTA={signupCTA} />
        </Box>
        <Box
          minHeight={'610px'}
          width="100%"
          position={DisplayPosition.Absolute}
          zIndex={ZIndex.Masked}
        >
          <Carousel />
        </Box>
        <Box
          minHeight={'610px'}
          width="100%"
          display={Display.Flex}
          position={DisplayPosition.Absolute}
          zIndex={ZIndex.Default}
        >
          <Background />
        </Box>
      </Box>
      <Space size={6} />
      <Box>
        <Banner />
      </Box>
    </Box>
  )
}
