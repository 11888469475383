import React from 'react'
import Immutable from 'immutable'
import {
  FlexDirection,
  Join,
  Space,
  Box,
  Checkbox,
  Radio,
  Color,
  withCitrusTheme,
  Display,
  Text,
} from '@gousto-internal/citrus-react'
import { DIETARY_PREFERENCES_TEXT, ORDERED_DIETARY_PREFERENCES } from '../../config'

const DietariesBox = withCitrusTheme(Box, (theme) => ({
  outline: `1px solid ${theme.tokens.colors.ColdGrey_200}`,
  '&:hover': {
    outlineColor: theme.tokens.colors.Secondary_400,
  },
  '&.active': {
    outline: `2px solid ${theme.tokens.colors.Secondary_400}`,
  },
}))

export type DietaryPreferencesSelectorProps = {
  hasDietaryPreferences: boolean
  onHasDietaryPreferencesChange: (value: boolean) => void
  dietaryPreferences: Immutable.List<string>
  onDietaryPreferencesChange: (id: string) => void
}

export function DietaryPreferencesSelector({
  hasDietaryPreferences,
  onHasDietaryPreferencesChange,
  dietaryPreferences,
  onDietaryPreferencesChange,
}: DietaryPreferencesSelectorProps) {
  const displaySelectOneDietaryInfo = dietaryPreferences.size === 0 && hasDietaryPreferences

  return (
    <Box role="radiogroup">
      <Join with={<Space size={3} />}>
        <Radio
          name="dietary-preferences"
          checked={!hasDietaryPreferences}
          outline
          onChange={() => onHasDietaryPreferencesChange(false)}
          data-testid="dietary-preferences-no-preferences"
          aria-checked={!hasDietaryPreferences}
          role="radio"
        >
          {(focused) => {
            return (
              <Box flexGrow={1} display="flex" flexDirection={FlexDirection.Row}>
                <Space size={2} direction="horizontal" />
                <Box display="flex" flexGrow={1} flexDirection={FlexDirection.Column}>
                  <Text size={4} color={focused ? Color.Secondary_400 : Color.ColdGrey_800}>
                    {DIETARY_PREFERENCES_TEXT.NO_DIETARY_PREFERENCES_LABEL}
                  </Text>
                </Box>
              </Box>
            )
          }}
        </Radio>
        <DietariesBox
          borderRadius={0.5}
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          className={hasDietaryPreferences ? 'active' : undefined}
        >
          <Radio
            name="dietary-preferences"
            checked={hasDietaryPreferences}
            onChange={() => onHasDietaryPreferencesChange(true)}
            data-testid="dietary-preferences-has-preferences"
            aria-checked={hasDietaryPreferences}
            role="radio"
          >
            {(focused) => {
              return (
                <Box>
                  <Box flexGrow={1} display="flex" flexDirection={FlexDirection.Row}>
                    <Space size={2} direction="horizontal" />
                    <Box display="flex" flexGrow={1} flexDirection={FlexDirection.Column}>
                      <Text size={4} color={focused ? Color.Secondary_400 : Color.ColdGrey_800}>
                        {DIETARY_PREFERENCES_TEXT.DIETARY_PREFERENCES_LABEL}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )
            }}
          </Radio>
          {displaySelectOneDietaryInfo && (
            <Box
              display="flex"
              flexDirection={FlexDirection.Row}
              style={{
                transform: 'translateY(-12px) translateX(4px)',
              }}
            >
              <Space size={10} direction="horizontal" />
              <Text display={Display.Flex} size={0} color={Color.ColdGrey_600}>
                {DIETARY_PREFERENCES_TEXT.SELECT_ONE_OR_MORE_LABEL}
              </Text>
            </Box>
          )}
          <Box display="flex" flexDirection={FlexDirection.Column}>
            {ORDERED_DIETARY_PREFERENCES.map(({ id, label }) => (
              <Checkbox
                key={id}
                id={id}
                checked={dietaryPreferences.includes(id)}
                onChange={() => onDietaryPreferencesChange(id)}
                paddingH={12}
                paddingV={2}
                data-testid={`dietary-preferences-${id}`}
              >
                {(focused) => (
                  <Box display={Display.Flex}>
                    <Space size={2} direction="horizontal" />
                    <Text
                      style={{
                        transform: 'translateY(-2px)',
                      }}
                      display={Display.Flex}
                      size={4}
                      color={focused ? Color.Secondary_400 : Color.ColdGrey_800}
                    >
                      {label}
                    </Text>
                  </Box>
                )}
              </Checkbox>
            ))}
          </Box>
        </DietariesBox>
      </Join>
    </Box>
  )
}
