import { connect } from 'react-redux'

import { getBasketDate, getBasketOrderId, getNumPortions, getBasketRecipes } from 'selectors/basket'

import { BoxSummaryContent } from './BoxSummaryContent'

function mapStateToProps(state) {
  return {
    date: getBasketDate(state),
    orderId: getBasketOrderId(state),
    numPortions: getNumPortions(state),
    recipes: getBasketRecipes(state),
  }
}

const BoxSummaryContentContainer = connect(mapStateToProps)(BoxSummaryContent)

export { BoxSummaryContentContainer }
