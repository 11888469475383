import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { checkoutSignup } from 'routes/Checkout/checkoutActions'
import { usePricing } from 'routes/Menu/domains/pricing'

export function useSubmitOrder() {
  const dispatch = useDispatch()
  const { pricing } = usePricing()

  return useCallback(() => {
    dispatch(checkoutSignup({ pricing }))
  }, [dispatch, pricing])
}
