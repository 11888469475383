import React from 'react'

import { FormFieldStatus, InputField } from '@gousto-internal/citrus-react'
import { Field, FieldProps } from 'formik'

export const PhoneInput = () => (
  <Field name="phone">
    {({ field, meta }: FieldProps) => (
      <InputField
        id={field.name}
        data-testid="checkoutPhoneNumberInput"
        autoComplete="tel"
        label="UK phone number"
        hint="Used to update you on your delivery"
        status={meta.touched && meta.error ? FormFieldStatus.Error : undefined}
        validationMessage={meta.touched ? meta.error : undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
    )}
  </Field>
)
