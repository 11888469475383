import React from 'react'

import classnames from 'classnames'

import { use5RecipesAwarenessInlineBanner } from '../use5RecipesAwareness'

import css from './FiveRecipesAwarenessOrderListBanner.css'

export enum OrderState {
  MenuOpen = 'menu open',
  Cancelled = 'cancelled',
  Dispatched = 'dispatched',
  Confirmed = 'confirmed',
  RecipesChosen = 'recipes chosen',
}

interface Props {
  orderState: OrderState
}

export const FiveRecipesAwarenessOrderListBanner: React.FC<Props> = ({ orderState }) => {
  const isEnabled = use5RecipesAwarenessInlineBanner(orderState)
  if (!isEnabled) {
    return null
  }

  return (
    <div className={classnames(css.fiveRecipesContainer, css.leftArrow)}>
      <p className={css.fiveRecipesText}>Choose 5 recipes, Mon-Fri sorted &#127881;</p>
    </div>
  )
}
