import React from 'react'

import { Box, Display, FlexDirection, AlignItems } from '@gousto-internal/citrus-react'

import { useIsOptimizelyFeatureEnabled, OptimizelyFeature } from 'containers/OptimizelyRollouts'

import { BenefitItem } from './BenefitItem'
import { BenefitType, BENEFITS } from './data'

type BenefitsProps = {
  pickFilter: BenefitType[]
}

export const Benefits = ({ pickFilter }: BenefitsProps) => {
  const benefits = BENEFITS.filter(({ type }) => pickFilter.includes(type))

  const isTestAllocationHookEnabled = useIsOptimizelyFeatureEnabled(
    'beetroots_test_allocation_hook_web',
  )

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      width="100%"
      alignItems={[AlignItems.Center, AlignItems.FlexStart]}
    >
      {benefits.map(({ type, iconName, text, strongText, ariaLabel }) => (
        <BenefitItem
          key={type}
          iconName={iconName}
          text={text}
          strongText={strongText}
          ariaLabel={ariaLabel}
        />
      ))}

      <Box
        style={{ visibility: 'hidden' }}
        data-testing={`beetroots_test_allocation_hook_web=${isTestAllocationHookEnabled}`}
      />

      <OptimizelyFeature name="beetroots_test_allocation_container_web" enabled>
        <Box
          style={{ visibility: 'hidden' }}
          data-testing="beetroots_test_allocation_container_web=true"
        />
      </OptimizelyFeature>
      <OptimizelyFeature name="beetroots_test_allocation_container_web" enabled={false}>
        <Box
          style={{ visibility: 'hidden' }}
          data-testing="beetroots_test_allocation_container_web=false"
        />
      </OptimizelyFeature>
    </Box>
  )
}
