import React, { useCallback } from 'react'

import { Button } from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { RemoveRecipeFn, useBasket } from 'routes/Menu/domains/basket'
import { isMobile } from 'utils/view'

import css from './BrowseCTAButton.css'

const handleSetDeliveryDateClick = (
  menuBrowseCTAVisibilityChange: (show: boolean) => void,
  boxDetailsVisibilityChange: (
    show: boolean,
    removeRecipe: RemoveRecipeFn,
    totalPrice?: string,
  ) => void,
  boxSummaryShow: boolean,
  removeRecipe: (recipeId: string, view?: string, position?: string) => void,
) => {
  if (!boxSummaryShow) {
    menuBrowseCTAVisibilityChange(false)
    boxDetailsVisibilityChange(true, removeRecipe)
  } else {
    boxDetailsVisibilityChange(false, removeRecipe)
  }
}

type BrowseCTAButtonProps = {
  boxDetailsVisibilityChange: (
    show: boolean,
    removeRecipe: RemoveRecipeFn,
    totalPrice?: string,
  ) => void
  boxSummaryShow?: boolean
  disable: boolean
  fullWidth?: boolean
  menuBrowseCTAVisibilityChange: (show: boolean) => void
  view: string
}

const BrowseCTAButton = ({
  boxDetailsVisibilityChange,
  boxSummaryShow = true,
  disable,
  fullWidth = false,
  menuBrowseCTAVisibilityChange,
  view,
}: BrowseCTAButtonProps) => {
  const isMobileView = isMobile(view)
  const classes = classnames(css[`setDeliveryDateButton-${view}`], {
    [css.fullWidth]: isMobileView && fullWidth,
  })

  const { removeRecipe } = useBasket()

  const onClick = useCallback(() => {
    if (!disable) {
      handleSetDeliveryDateClick(
        menuBrowseCTAVisibilityChange,
        boxDetailsVisibilityChange,
        boxSummaryShow,
        removeRecipe,
      )
    }
  }, [
    boxDetailsVisibilityChange,
    boxSummaryShow,
    disable,
    menuBrowseCTAVisibilityChange,
    removeRecipe,
  ])

  return (
    <Button className={classes} data-testing="menuBrowseCTAButton" onClick={onClick}>
      Set Delivery Date
    </Button>
  )
}

export { BrowseCTAButton }
