import { actionTypes } from 'actions/actionTypes'
import Immutable from 'immutable'
import { isAdmin } from 'utils/auth'

export const initialState = () => Immutable.fromJS({
  accessToken: '',
  hasRefreshCookie: false,
  isAuthenticated: false,
  isAdmin: false,
  rememberMe: false,
  email: '',
  id: '',
  name: '',
  roles: [],
  expiresAt: '',
})

const auth = {
  auth: (state, action) => {
    if (!state) {
      return initialState()
    }

    switch (action.type) {
    case actionTypes.USER_IDENTIFIED: {
      const { user } = action
      let newState = state.set('id', user.id)
      newState = newState.set('email', user.email)
      newState = newState.set('name', user.name)
      newState = newState.set('roles', Immutable.List(user.roles))
      newState = newState.set('isAdmin', isAdmin(user.roles))

      return newState
    }

    case actionTypes.USER_AUTHENTICATED:
      return state.merge({
        accessToken: action.accessToken,
        expiresAt: action.expiresAt,
        hasRefreshCookie: action.hasRefreshCookie,
      })

    case actionTypes.USER_AUTH_FAILED:
      return state.merge({
        accessToken: '',
        expiresAt: '',
      })

    case actionTypes.USER_REMEMBER_ME: {
      return state.set('rememberMe', action.rememberMe)
    }

    case actionTypes.USER_LOGGED_IN: {
      return state.set('isAuthenticated', true)
    }

    case actionTypes.USER_LOGGED_OUT:
      return initialState()

    default:
      return state
    }
  },
}

export default auth
