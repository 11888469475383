import styled from '@emotion/styled'
import { Text } from '@gousto-internal/citrus-react'

export const UnorderedList = styled.ul({
  display: 'flex',
  listStyle: 'none',
  margin: '0',
  padding: '0',
})

export const ListItem = styled.li({
  display: 'flex',
  alignItems: 'center',
})

export const FutureLink = styled(Text)(() => ({
  cursor: 'default',
  pointerEvents: 'none',
}))
