import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { usePrevious } from 'react-use'

import { actionTypes } from 'actions/actionTypes'
import * as trackingKeys from 'actions/trackingKeys'
import { sendClientMetric } from 'routes/Menu/apis/clientMetrics'
import { useAllCollections, useCurrentCollectionId } from 'routes/Menu/domains/collections'
import {
  getBasketRecipes,
  isFirstRecipeAdded,
  getBasketSlotId,
  getBasketMenuId,
} from 'selectors/basket'
import { getUTMAndPromoCode } from 'selectors/tracking'

import { useStock } from '../../useStock'
import { useRecipeLimitReached } from '../limits'

export const useAddValidRecipeToBasket = () => {
  const menuRecipes = useSelector(getBasketRecipes)
  const firstRecipeAdded = useSelector(isFirstRecipeAdded)
  const slotId = useSelector(getBasketSlotId)
  const { promoCode, UTM } = useSelector(getUTMAndPromoCode)
  const currentMenuId = useSelector(getBasketMenuId)

  const { isRecipeOutOfStock } = useStock()
  const collection = useCurrentCollectionId()
  const prevRecipes = usePrevious(menuRecipes)

  const reachedLimit = useRecipeLimitReached(menuRecipes)

  const dispatch = useDispatch()
  const allCollections = useAllCollections()

  return React.useCallback(
    (
      recipeId: string,
      view?: string,
      recipeInfo?: {
        position: string
      },
      maxRecipesNum?: number,
      orderId?: string,
      isOrderRecipe?: boolean, // if this is true, the recipe was already part of the order and we are just initialising the basket
    ) => {
      const outOfStock = isRecipeOutOfStock(recipeId)

      const indexOfCollection = allCollections.keySeq().indexOf(collection || '')

      if ((reachedLimit || outOfStock) && !isOrderRecipe) {
        return
      }

      if (recipeInfo) {
        Object.assign(recipeInfo, { collection })
      }

      if (!orderId && !firstRecipeAdded) {
        sendClientMetric('menu-first-recipe-add', 1, 'Count')
      }

      dispatch({
        type: actionTypes.BASKET_RECIPE_ADD,
        recipeId,
        ...recipeInfo,
        orderId,
        trackingData: {
          actionType: trackingKeys.addRecipe,
          addType: 'standard',
          recipeId,
          view,
          position: recipeInfo && recipeInfo.position,
          collection,
          currentMenuId,
          collectionPosition: indexOfCollection + 1,
          recipe_count: menuRecipes.size + 1, // The action is performed in the same time so the size is not updated yet
        },
      })

      if (reachedLimit) {
        dispatch({
          type: actionTypes.BASKET_LIMIT_REACHED,
          limitReached: reachedLimit,
          trackingData: {
            actionType: trackingKeys.basketLimit,
            limitReached: reachedLimit,
            view,
            source: actionTypes.RECIPE_ADDED,
          },
        })
      }

      let recipesCount = 0

      menuRecipes.forEach((count = 0) => {
        recipesCount += count
      })

      if (prevRecipes && prevRecipes.size < 2 && recipesCount > 1 && slotId) {
        dispatch({
          type: actionTypes.BASKET_ELIGIBLE_TRACK,
          trackingData: {
            actionType: trackingKeys.basketEligible,
            ...UTM,
            promoCode,
            menuRecipes,
          },
        })
      }
    },
    [
      UTM,
      collection,
      dispatch,
      firstRecipeAdded,
      isRecipeOutOfStock,
      menuRecipes,
      prevRecipes,
      promoCode,
      reachedLimit,
      slotId,
      allCollections,
      currentMenuId,
    ],
  )
}
