import { RootStateOrAny } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { getIsAuthenticated } from 'selectors/auth'
import { getPromoCode } from 'selectors/basket'

export const getPromoBannerState = (state: RootStateOrAny) => {
  const basketPromoCode = getPromoCode(state)
  const currentPromo = state.promoCurrent || basketPromoCode || ''
  const isAuthenticated = getIsAuthenticated(state)

  const hasCurrentPromo = currentPromo.length > 0

  const hasError =
    !!state.error.get(actionTypes.PROMO_GET) || !!state.error.get(actionTypes.PROMO_APPLY)

  const hide = isAuthenticated || hasError || hasCurrentPromo

  const canApplyPromo = !hide

  return {
    canApplyPromo,
  }
}
