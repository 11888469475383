import { isServer } from '@library/environment'
import { getWindow } from '@library/environment/browser'

import { OFXError } from '../errors'
import { Datafile } from '../types'

export function getDatafile(): Datafile {
  if (isServer()) {
    throw new OFXError('Calling browser getDatafile() on server')
  } else {
    return getWindow().__datafile__ || {}
  }
}
