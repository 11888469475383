import { fetch } from 'utils/fetch'
import { endpoint } from '@library/endpoint'

export function fetchOrderSkipContent(accessToken, orderId, orderDate) {
  const reqData = orderId ? {
    order_date: orderDate,
    order_id: orderId
  }
    : {
      order_date: orderDate,
    }

  return fetch(accessToken, `${endpoint('orderskiprecovery')}/orderskiprecovery`, reqData, 'GET')
}

export function fetchSubscriptionPauseContent(accessToken, enableOffer) {
  // TODO replace the 1 with subscription id when a) we have access to it, and b) the back end uses it
  return fetch(accessToken, `${endpoint('subpauseosr')}/subscriptionpauserecovery/1`, {enableOffer}, 'GET')
}
