import { queueTrackingEvent } from '@library/tracking-integration'

export enum TrackingActions {
  CLICK_CONTINUE = 'click_continue',
  CLICK_SKIP = 'click_skip',
}

type DietaryPreferences =
  | 'vegetarian'
  | 'vegan'
  | 'pescatarian'
  | 'gluten-free'
  | 'dairy-free'
  | 'noseafood'

type CTAClickTrackingData = {
  action: TrackingActions
  properties: {
    dietary_preferences?: DietaryPreferences[]
    can_eat_everything?: boolean
    section: 'dietaryPreferences'
  }
}

export function trackCTAClick(
  action: TrackingActions,
  dietary_preferences?: CTAClickTrackingData['properties']['dietary_preferences'],
  can_eat_everything?: CTAClickTrackingData['properties']['can_eat_everything'],
) {
  queueTrackingEvent<CTAClickTrackingData>({
    action,
    properties: {
      dietary_preferences,
      can_eat_everything,
      section: 'dietaryPreferences',
    },
  })
}
