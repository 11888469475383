import React, { FC } from 'react'

import { RecipeRating } from '../../types'
import { Image, withCitrusTheme } from '@gousto-internal/citrus-react'

const StyledImage = withCitrusTheme(Image, () => ({
  display: 'block',
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
  height: '306px',
  transition: 'height 0.3s ease-out',
  transform: 'translateZ(0)',
}))

interface SrcSet {
  url: string
  width: number | string
}

const formatSrcSet = (srcSet: SrcSet[]): string => {
  return srcSet.map(({ url, width }) => `${url} ${width}w`).join()
}

interface Props {
  recipe: RecipeRating
}

export const RecipeImage: FC<Props> = ({ recipe }) => {
  if (!recipe.images.length) {
    return null
  }

  const [featureImage] = recipe.images

  return (
    <StyledImage
      alt={recipe.name}
      loading="lazy"
      src={featureImage.defaultCrop.url}
      srcSet={formatSrcSet(featureImage.crops)}
    />
  )
}
