import React from 'react'
import {
  AlignItems,
  Body2,
  Box,
  Col,
  Color,
  CSSKeywords,
  Display,
  Grid,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
  Visible,
} from '@gousto-internal/citrus-react'
import { ENDLESS_VARIETY } from '../../../config'

export const MenuCollections = () => {
  return (
    <Grid columnGap={1} rowGap={2} width={['100%', CSSKeywords.Unset]}>
      {ENDLESS_VARIETY.collections.map((collection, index) => (
        <Col key={index} size={[6, 3]}>
          <Box
            bg={Color.White}
            paddingV={1}
            paddingH={[2, 1, 2]}
            style={{ borderRadius: '999px' }}
            display={Display.Flex}
            alignItems={AlignItems.Center}
            aria-label="Menu Collection"
            minWidth={[null, null, '180px', '192px']}
          >
            <Visible display={[Display.None, Display.Block]}>
              <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
                <Icon name="tick" size={5} variant={IconVariant.Confirmation} />
                <Space direction="horizontal" size={1} />
              </Box>
            </Visible>
            <Box>
              <Space size={[0.5, null, 1]} />
              <Body2>{collection}</Body2>
              <Space size={[0.5, null, 1]} />
            </Box>
          </Box>
        </Col>
      ))}
    </Grid>
  )
}
