import * as Yup from 'yup'
import {
  DeliveryInstruction,
  DELIVERY_INSTRUCTIONS,
  isCustomDeliveryInstruction,
} from '@library/api-user'

const invalidSpecialCharactersRegex = /[^0-9a-zA-Z\s.\-\/,&'()!]+/

interface ValidationErrorParams {
  path: string
  message: string
}

// Define a function that returns a Yup test function
const validateSpecialCharacters = (message: string) => {
  return function (this: Yup.TestContext, value?: string) {
    if (value === '' || value === undefined || value === null) {
      return true
    }

    if (typeof value !== 'string') {
      return false
    }
    const matches = value.match(invalidSpecialCharactersRegex)
    if (matches) {
      // Use the createError method from the Yup TestContext
      return this.createError({
        path: this.path,
        message: message.replace('${invalidChars}', matches.join(', ')),
      } as ValidationErrorParams)
    }
    return true
  }
}

const $name = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(3, 'Address name must be at least 3 characters')
  .max(20, 'Address name exceeds 20 characters')
  .required('Address name is required')

const $postcode = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(3, 'Postal code must be at least three characters')
  .max(8, 'Postal code exceeds 8 characters')
  .required('Postal code is required')

const $line1 = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(3, 'Address line must be at least 3 characters')
  .max(255, 'Address line exceeds 255 characters')
  .required('Address line is required')

const $line2 = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(3, 'Address line must be at least 3 characters')
  .max(255, 'Address line exceeds 255 characters')

const $line3 = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(3, 'Address line must be at least 3 characters')
  .max(255, 'Address line exceeds 255 characters')

const $town = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(2, 'Town must be at least two characters')
  .max(255, 'Town exceeds 255 characters')
  .required('Town is required')

const $county = Yup.string()
  .test(
    'isValidCharacters',
    'Special characters are not allowed. Please remove "${invalidChars}".',
    validateSpecialCharacters(
      'Special characters are not allowed. Please remove "${invalidChars}".',
    ),
  )
  .min(3, 'Address line must be at least 3 characters')
  .max(255, 'Address line exceeds 255 characters')

const $deliveryInstructionsCustom = Yup.string()
  .max(32, 'Delivery instruction exceeds 32 characters')
  .optional()
  .when('deliveryInstructions', {
    is: isCustomDeliveryInstruction,
    then: (schema) => schema.required('Delivery instruction is a required field'),
  })
  .when('deliveryInstructions', {
    is: (value: DeliveryInstruction) => value === 'Neighbour',
    then: (schema) => schema.min(1, 'Delivery instruction must be at least one character'),
  })
  .when('deliveryInstructions', {
    is: (value: DeliveryInstruction) => value === 'Other',
    then: (schema) => schema.min(5, 'Delivery instruction must be at least five characters'),
  })

const $deliveryInstructions = Yup.string().oneOf(DELIVERY_INSTRUCTIONS).required()

export {
  $name,
  $postcode,
  $line1,
  $line2,
  $line3,
  $town,
  $county,
  $deliveryInstructions,
  $deliveryInstructionsCustom,
}
