import isomorphicFetch from 'isomorphic-fetch'

import { AnnotatedRequest, RequestConfig, RequestMiddleware, ResponseMiddleware } from '../types'
import { getUrl } from './url'

type Params<Input, Output> = {
  reduceRequest: RequestMiddleware<Input>
  reduceResponse: ResponseMiddleware<Response, Output>
  input: Input
  identifier?: symbol
}

export async function makeRequest<Input, Output>(params: Params<Input, Output>): Promise<Output> {
  const { reduceRequest, reduceResponse, input, identifier } = params

  const defaultRequest: RequestConfig = {
    host: '',
    method: 'GET',
    paths: [],
  }

  const req = await reduceRequest(defaultRequest, input)
  const url = getUrl(req)
  const init: AnnotatedRequest = req
  if (identifier) {
    init.__meta__ = {
      identifier,
    }
  }

  const response = await isomorphicFetch(url, init)

  return reduceResponse(response)
}
