import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import css from './SectionHeader.css'

export const SectionHeader = ({ title, children, type, contentAlign, contentSizeMax }) => (
  <header className={css[`${type}Container`]}>
    <div className={classnames(css[`${type}Content`], css[`${type}ContentMax${contentSizeMax}`], css[`${contentAlign}Align`])}>
      <h1 className={css[`${type}Title`]}>{title}</h1>
      {children}
    </div>
  </header>
)

SectionHeader.propTypes = {
  children: PropTypes.node,
  contentAlign: PropTypes.oneOf(['', 'left', 'center', 'right']),
  contentSizeMax: PropTypes.oneOf(['', 'LG']),
  type: PropTypes.oneOf(['', 'page', 'article', 'minorArticle']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

SectionHeader.defaultProps = {
  children: null,
  contentAlign: '',
  contentSizeMax: '',
  type: 'page',
}
