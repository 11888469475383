import React from 'react'

import Loading from 'Loading'
import PropTypes from 'prop-types'

import { myGoustoOrderPropType } from '../../../GetHelp/getHelpPropTypes'
import { NextOrderContainer } from './NextOrder'
import { NextProjectedDelivery } from './NextProjectedDelivery'
import { NoNextOrder } from './NoNextOrder'
import { PreviousOrderContainer } from './PreviousOrder'
import { SubscriberPricingBanner } from './SubscriberPricingBanner'

import css from './Header.css'

const HeaderPresentation = ({
  nextProjectedOrder,
  hasDeliveryToday,
  isLoading,
  nextOrder,
  nextOrderTrackings,
  previousOrder,
  showSubscriberPricingBanner,
  subscriptionStatus,
  maxNumRecipes,
}) => {
  const previousOrderCard = previousOrder ? (
    <PreviousOrderContainer
      hasDeliveryToday={hasDeliveryToday}
      order={previousOrder}
      maxNumRecipes={maxNumRecipes}
    />
  ) : null

  const renderUpcomingOrderCard = () => {
    if (nextOrder) {
      return (
        <NextOrderContainer
          boxTrackingUrls={nextOrderTrackings}
          hasDeliveryToday={hasDeliveryToday}
          order={nextOrder}
          maxNumRecipes={maxNumRecipes}
        />
      )
    }

    if (nextProjectedOrder) {
      return (
        <NextProjectedDelivery
          deliveryDate={nextProjectedOrder.deliveryDate}
          maxNumRecipes={maxNumRecipes}
        />
      )
    }

    return <NoNextOrder />
  }

  return (
    <div>
      {showSubscriberPricingBanner && (
        <SubscriberPricingBanner subscriptionStatus={subscriptionStatus} />
      )}
      {isLoading ? (
        <div className={css.loading}>
          <Loading />
        </div>
      ) : (
        <div className={`${css.cardsContainer}`}>
          {previousOrderCard}
          {renderUpcomingOrderCard()}
        </div>
      )}
    </div>
  )
}

HeaderPresentation.propTypes = {
  hasDeliveryToday: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  nextOrder: myGoustoOrderPropType,
  nextOrderTrackings: PropTypes.arrayOf(PropTypes.string),
  nextProjectedOrder: PropTypes.shape({
    deliveryDate: PropTypes.string.isRequired,
  }),
  previousOrder: myGoustoOrderPropType,
  showSubscriberPricingBanner: PropTypes.bool.isRequired,
  subscriptionStatus: PropTypes.string,
  maxNumRecipes: PropTypes.number,
}

HeaderPresentation.defaultProps = {
  hasDeliveryToday: false,
  nextOrder: null,
  nextOrderTrackings: [],
  nextProjectedOrder: null,
  previousOrder: null,
  subscriptionStatus: 'inactive',
  maxNumRecipes: 4,
}

export { HeaderPresentation }
