import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box,FlexDirection, Space, Bold} from '@gousto-internal/citrus-react'

export const RecipesPerBoxLabel = ({ recipesPerBox, pricePerPortionDiscounted}) => (
  <Box flexGrow={1} display="flex" flexDirection={FlexDirection.Row}>
    <Space size={2} direction="horizontal" />
    <Box display="flex" flexGrow={1} flexDirection={FlexDirection.Column} data-testid="recipes-per-box-label">
      <Bold>
        {`${recipesPerBox} recipes`}
      </Bold>
      <Text>
        {`(£${pricePerPortionDiscounted} per serving)`}
      </Text>
    </Box>
  </Box>
)

RecipesPerBoxLabel.propTypes = {
  recipesPerBox: PropTypes.string.isRequired,
  pricePerPortionDiscounted: PropTypes.string.isRequired,
}
