import React, { useState, useRef } from 'react'
import { FontFamily, Text } from '@gousto-internal/citrus-react'
import styled from '@emotion/styled'

import { useClickOutside } from '../SwapAlternativeOptions/useClickOutside'
import { RecipeAlternativeOptions } from '../SwapAlternativeOptions/RecipeAlternativeOptions'
import {
  cssButton,
  cssDropWrapperV2,
  cssIsExpanded,
  cssOuterWrapper,
  cssFineDineInButton,
} from './styles'
import { useDeviceType, DeviceType } from '@library/dom'
import { Recipe } from '../../model/recipe'
import { AlternativeOptions } from '../../model/alternativeOptions/alternativeOptions'
import { queueTrackingEvent } from '@library/tracking-integration'
import { SwapAlternativeOptionsMobile } from './SwapAlternativeOptionsMobile'

export type TrackRecipeAlternativeOptionsMenuOpen = {
  action: 'recipe-alternative-options-menu-open'
  recipeId: string
  collectionId: string
}

export type TrackRecipeAlternativeOptionsMenuSwapRecipes = {
  action: 'recipe-alternative-options-menu-swap-recipes'
  previousRecipeId: string
  nextRecipeId: string
  collectionId: string
}

const escapeKeyPressed = (e: React.KeyboardEvent) => {
  return e.type === 'keyup' && e.keyCode && e.keyCode === 27
}

const OptionsButton = styled.div(({ isFineDineIn }: { isFineDineIn?: boolean }) => ({
  ...cssButton,
  ...(isFineDineIn ? cssFineDineInButton : {}),
}))

const DropDown = styled.div<{ showOptions: boolean }>(({ showOptions }) => ({
  ...cssDropWrapperV2,
  ...(showOptions ? cssIsExpanded : null),
}))

const OuterWrapper = styled.div(cssOuterWrapper)

export function SwapAlternativeOptions({
  recipeId,
  categoryId: collectionId,
  recipe,
  selectVariant,
  alternativeOptions,
}: {
  recipeId: string
  categoryId: string
  recipe: Recipe
  selectVariant: ({
    variantId,
    variantOutOfStock,
  }: {
    variantId: string
    variantOutOfStock: boolean
  }) => void
  alternativeOptions: AlternativeOptions
}) {
  const [showOptions, setShowOptions] = useState(false)
  const deviceType = useDeviceType()
  const { isFineDineIn } = recipe

  const selectRef = useRef(null)
  useClickOutside(selectRef, () => setShowOptions(false), [showOptions])

  if (deviceType === DeviceType.MOBILE) {
    return (
      <SwapAlternativeOptionsMobile
        recipeId={recipeId}
        categoryId={collectionId}
        selectVariant={selectVariant}
        alternativeOptions={alternativeOptions}
      />
    )
  }

  return (
    <OuterWrapper ref={selectRef}>
      <OptionsButton
        data-testid="SwapAlternativeOptionsButton"
        isFineDineIn={isFineDineIn}
        onClick={(e) => {
          e.stopPropagation()
          setShowOptions(!showOptions)

          if (!showOptions) {
            queueTrackingEvent<TrackRecipeAlternativeOptionsMenuOpen>({
              action: 'recipe-alternative-options-menu-open',
              recipeId,
              collectionId,
            })
          }
        }}
        onKeyUp={(e) => {
          e.stopPropagation()

          if (escapeKeyPressed(e)) {
            setShowOptions(false)
          }
        }}
      >
        <Text fontFamily={FontFamily.Bold}>Options</Text>
      </OptionsButton>
      {showOptions && (
        <DropDown aria-hidden={!showOptions} showOptions={showOptions}>
          <RecipeAlternativeOptions
            recipeId={recipeId}
            selectVariant={selectVariant}
            onChangeCheckedRecipe={({ previousRecipeId, nextRecipeId }) => {
              queueTrackingEvent<TrackRecipeAlternativeOptionsMenuSwapRecipes>({
                action: 'recipe-alternative-options-menu-swap-recipes',
                previousRecipeId,
                nextRecipeId,
                collectionId,
              })
              setShowOptions(false)
            }}
            alternativeOptions={alternativeOptions}
          />
        </DropDown>
      )}
    </OuterWrapper>
  )
}
