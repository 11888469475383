export const deliveryInstructionsFields = {
  selectFieldLabel: "Where can we leave your delivery if you're not in?",
  placeholderText: 'Please select an option',
  leaveBoxOptions: [
    'Front Porch',
    'Back Porch',
    'Garage',
    'Shed/Greenhouse/Outbuilding',
    'Conservatory',
    'Neighbour',
    'Other',
  ],
  leaveBoxOptionsWithMessage: ['Neighbour', 'Other'],
}
