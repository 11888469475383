import { StyledElement } from 'StyledElement'

export const Aside = (props) => StyledElement({ ...props, type: 'aside' })

export const Checkbox = (props) => StyledElement({ ...props, type: 'checkbox' })

export const Div = (props) => StyledElement({ ...props, type: 'div' })

export const Footer = (props) => StyledElement({ ...props, type: 'footer' })

export const Header = (props) => StyledElement({ ...props, type: 'header' })

export const Input = (props) => StyledElement({ ...props, type: 'input' })

export const Li = (props) => StyledElement({ ...props, type: 'li' })

export const Option = (props) => StyledElement({ ...props, type: 'option' })

export const P = (props) => StyledElement({ ...props, type: 'p' })

export const Radio = (props) => StyledElement({ ...props, type: 'radio' })

export const Section = (props) => StyledElement({ ...props, type: 'section' })

export const Span = (props) => StyledElement({ ...props, type: 'span' })

export const Svg = (props) => StyledElement({ ...props, type: 'svg' })

export const TextArea = (props) => StyledElement({ ...props, type: 'textarea' })

export const Ul = (props) => StyledElement({ ...props, type: 'ul' })
