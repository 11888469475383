import React from 'react'

import {
  AlignItems,
  AlignSelf,
  Box,
  Display,
  FlexDirection,
  Icon,
  IconNames,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { HERO_CONFIG } from '../Hero.config'
import { CTAButton } from '../../CTAButton/CTAButton'
import { CTA_TEXT } from '../../../components'
import { heroGetStartedTrackingEvent } from '../../../tracking/trackingConfig'

export const CTABlock = () => (
  <Box
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    alignItems={[AlignItems.Center, AlignItems.Center, AlignItems.FlexStart]}
    data-testing="raf-landing-page-cta-block"
  >
    <CTAButton trackingEvent={heroGetStartedTrackingEvent()} text={CTA_TEXT.GET_STARTED} />
    <Space direction="vertical" size={3} />
    <Box>
      {HERO_CONFIG.benefit.map(({ ariaLabel, iconName, strongText, text }) => (
        <Box
          role="img"
          aria-label={ariaLabel}
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          alignItems={AlignItems.Center}
          key={text}
          paddingBottom={2}
          alignSelf={AlignSelf.Start}
        >
          <Icon name={iconName as IconNames} size={6} />
          <Space direction="horizontal" size={2} />
          <Text display={Display.Inline} size={1}>
            <span style={{ fontWeight: 'bold' }}>{strongText}</span>
            {text}
          </Text>
        </Box>
      ))}
    </Box>
  </Box>
)
