import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { trackUserAddRemoveRecipe } from 'actions/loggingmanager'
import * as trackingKeys from 'actions/trackingKeys'
import { useCurrentCollectionId } from 'routes/Menu/domains/collections'
import { getBasketRecipes } from 'selectors/basket'

import { useRecipeLimitReached } from '../limits'
import { useMenuId } from '../useMenuId'
import { useNumPortions } from '../useNumPortions'

export type RemoveRecipeFn = ReturnType<typeof useRemoveRecipe>
export const useRemoveRecipe = () => {
  const { numPortions } = useNumPortions()
  const recipesInBasket = useSelector(getBasketRecipes)
  const reachedLimit = useRecipeLimitReached(recipesInBasket)
  const currentMenuId = useMenuId()
  const collection = useCurrentCollectionId()
  const dispatch = useDispatch()

  return useCallback(
    (recipeId: string, view?: string, position?: string) => {
      const basketRecipesWithoutRecipeId = Object.keys(recipesInBasket.toJS()).filter(
        (key) => key !== recipeId,
      )
      dispatch({
        type: actionTypes.BASKET_RECIPE_REMOVE,
        recipeId,
        trackingData: {
          actionType: trackingKeys.removeRecipe,
          basketRecipes: basketRecipesWithoutRecipeId,
          boxSize: numPortions,
          boxType: 'chosen',
          currentMenuId,
          recipeId,
          view,
          position,
          collection,
          recipe_count: recipesInBasket.size - 1, // The action is performed in the same time so the size is not updated yet
        },
      })

      if (!reachedLimit) {
        dispatch({
          type: actionTypes.BASKET_LIMIT_REACHED,
          limitReached: reachedLimit,
          trackingData: {
            actionType: trackingKeys.basketLimit,
            limitReached: reachedLimit,
            view,
            source: actionTypes.RECIPE_REMOVED,
          },
        })
      }

      dispatch(trackUserAddRemoveRecipe())
    },
    [dispatch, collection, numPortions, reachedLimit, currentMenuId, recipesInBasket],
  )
}
