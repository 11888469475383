import React from 'react'

import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  Overflow,
  Visible,
} from '@gousto-internal/citrus-react'

import { LovedByBrandLogo } from './LovedByBrandLogo'

import { LOVED_BY_IMAGES } from '../LovedBy.config'

export const LovedByBrandLogoContainer = () => (
  <Box
    display={Display.Flex}
    flexDirection={FlexDirection.Row}
    maxWidth={['100%', null, '76%']}
    gap={[4, 6, 10]}
    justifyContent={JustifyContent.Center}
    alignItems={AlignItems.Center}
    paddingH={[0, 10, null]}
    overflowX={Overflow.Visible}
    style={{
      flexWrap: 'wrap',
    }}
  >
    {LOVED_BY_IMAGES.map((image, index) => {
      if (index < 5) {
        return <LovedByBrandLogo key={image} image={image} />
      } else {
        return (
          <Visible key={image} display={[Display.None, Display.Block, Display.Block]}>
            <LovedByBrandLogo image={image} />
          </Visible>
        )
      }
    })}
  </Box>
)
