import React from 'react'
import { Join, Space } from '@gousto-internal/citrus-react'
import { DeliveryInstruction, DELIVERY_INSTRUCTIONS } from '@library/api-user'
import {
  $county,
  $deliveryInstructions,
  $deliveryInstructionsCustom,
  $line1,
  $line2,
  $line3,
  $name,
  $postcode,
  $town,
} from '../schemas'
import { Form } from '../../Form'
import { AddressNameInput } from '../../Form/Inputs'
import { AddressLookup } from './AddressLookup'
import { DeliveryInstructions } from './DeliveryInstructions'

interface AddressFormData extends Record<string, unknown> {
  id?: string
  name: string
  postcode: string
  line1: string
  line2: string
  line3: string
  town: string
  county: string
  deliveryInstructions: DeliveryInstruction
  deliveryInstructionsCustom?: string
}

interface AddressFormProps {
  address?: AddressFormData
  onSubmit: (data: Record<string, unknown>) => Promise<void>
  onCancel: () => void
}

const schemas = {
  postcode: $postcode,
  name: $name,
  line1: $line1,
  line2: $line2,
  line3: $line3,
  town: $town,
  county: $county,
  deliveryInstructions: $deliveryInstructions,
  deliveryInstructionsCustom: $deliveryInstructionsCustom,
}

const DEFAULT_VALUES = {
  name: '',
  postcode: '',
  line1: '',
  line2: '',
  line3: '',
  town: '',
  county: '',
  deliveryInstructions: DELIVERY_INSTRUCTIONS[0],
  deliveryInstructionsCustom: '',
}

const AddressForm = ({ address = DEFAULT_VALUES, onSubmit, onCancel }: AddressFormProps) => (
  <Form
    defaultValues={address}
    schemas={schemas}
    onFormSubmit={onSubmit}
    onFormCancel={onCancel}
    submitButtonText={address.id ? `Save changes` : `Add address`}
  >
    <Join with={<Space size={4} />}>
      <AddressNameInput />
      <AddressLookup
        deliveryPoint={
          address.id
            ? {
                county: address.county,
                line1: address.line1,
                line2: address.line2,
                line3: address.line3,
                town: address.town,
                postcode: address.postcode,
              }
            : undefined
        }
      />
      <DeliveryInstructions />
    </Join>
  </Form>
)

export { AddressForm }
