export const HEADER_SECTION = {
  title: 'What types of recipes do you like?',
  description: 'Choose recipes you like the look of and we will show recipes we think you’ll love',
  notice: 'Don’t worry you’ll still get to build your box from our full menu!',
}

export const CTAs = {
  seeMenuCTA: 'See your menu',
  skipCTA: 'Skip',
}
