import type { Context } from 'koa'
import moment from 'moment'
import qs, { ParsedQs } from 'qs'

import { FORCED_DECISIONS_COOKIE } from '../cookies'
import { logOFXError } from '../errors'
import { CookiesSource, ForcedDecision } from '../types'

const FORCED_DECISIONS_QUERYSTRING = 'flag'
const NO_FORCED_DECISIONS: ForcedDecision[] = []

/**
 * Sets response feature flag cookies if URL provides forced variation query parameters
 * You can pass these in the form ?flag[my_feature_flag]=variationKey&flag[another_flag]=variationKey
 * @param cookies
 * @param queryString
 */
export function handleForcedDecisions(
  cookies: Context['cookies'],
  queryString?: string,
): ForcedDecision[] {
  const newForcedDecisions = getQueryForcedDecisions(queryString)
  const prevForcedDecisions = getCookieForcedDecisions(cookies)

  if (newForcedDecisions.length) {
    setForcedDecisionCookie(newForcedDecisions, cookies)
    return newForcedDecisions
  }

  return prevForcedDecisions
}

export function setForcedDecisionCookie(
  forcedDecisions: ForcedDecision[],
  cookies: Context['cookies'] | CookiesSource,
) {
  const serialised = JSON.stringify(forcedDecisions)

  cookies.set(FORCED_DECISIONS_COOKIE, serialised, {
    expires: tomorrow(),
    httpOnly: false,
  })
}

/**
 * Parses (qs.parsed) forced variation query param into
 * an array of ForcedDecision
 * Input: `{ 'feature-flag-1': 'on' }`
 * Output: `[{ flagKey: 'feature-flag-1', variationKey: 'on' }]`
 */
export function getQueryForcedDecisions(queryString?: string) {
  if (!queryString) return NO_FORCED_DECISIONS

  const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true })
  const forcedDecisions = queryParams[FORCED_DECISIONS_QUERYSTRING]

  if (!isForcedDecisionRecord(forcedDecisions)) return NO_FORCED_DECISIONS

  return Object.entries(forcedDecisions).reduce<ForcedDecision[]>(
    (parsed, [flagKey, variationKey]) => {
      if (typeof variationKey !== 'string') return parsed

      return [
        ...parsed,
        {
          flagKey,
          variationKey,
        },
      ]
    },
    [],
  )
}

/**
 * Type-guard to narrow type of parsed query params
 */
function isForcedDecisionRecord(input: unknown): input is ParsedQs {
  return typeof input === 'object' && !Array.isArray(input)
}

export function tomorrow() {
  return moment().startOf('day').add(1, 'day').toDate()
}

/**
 * Isomorphic fn to parse forced variation cookie
 * If undefined or throws during parsing, falls back to empty array
 * @param cookies Cookie source
 * @returns ForcedDecision[]
 */
export function getCookieForcedDecisions(cookies: CookiesSource) {
  const forcedDecisionsCookie = cookies.get(FORCED_DECISIONS_COOKIE)
  let parsedForcedDecisions: ForcedDecision[] = NO_FORCED_DECISIONS

  if (!forcedDecisionsCookie) return NO_FORCED_DECISIONS

  try {
    parsedForcedDecisions = JSON.parse(forcedDecisionsCookie)
  } catch (err) {
    logOFXError(err as Error)
  }

  return parsedForcedDecisions
}
