import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import { isOrderBeingDeliveredToday } from 'utils/order'

import { myGoustoOrderPropType } from '../../../GetHelp/getHelpPropTypes'
import { HeaderPresentation } from './Header.presentation'

class Header extends PureComponent {
  componentDidMount() {
    this.fetchOrders()
  }

  componentDidUpdate(prevProps) {
    const { loadNextProjectedOrder, loadOrderTrackingInfo, nextOrder, userId } = this.props

    if (userId && !prevProps.userId) {
      loadNextProjectedOrder(userId)
    }

    if (prevProps.nextOrder !== nextOrder) {
      if (isOrderBeingDeliveredToday(nextOrder.get('deliveryDate'))) {
        loadOrderTrackingInfo(nextOrder.get('id'))
      }
    }
  }

  fetchOrders = async () => {
    const { loadNextProjectedOrder, loadOrders, userId, userLoadData } = this.props

    if (!userId) {
      await userLoadData()
    }

    loadOrders()

    if (userId) {
      loadNextProjectedOrder(userId)
    }
  }

  render() {
    const {
      isOrdersPending,
      isProjectedDeliveriesPending,
      nextOrder,
      nextProjectedOrder,
      nextOrderTrackings,
      previousOrder,
      showSubscriberPricingBanner,
      subscriptionStatus,
      maxNumRecipes,
      isFetchingUserData,
    } = this.props
    const isLoading = isOrdersPending || isProjectedDeliveriesPending || isFetchingUserData
    const hasDeliveryToday = !!(
      nextOrder && isOrderBeingDeliveredToday(nextOrder.get('deliveryDate'))
    )

    return (
      <HeaderPresentation
        nextProjectedOrder={nextProjectedOrder}
        hasDeliveryToday={hasDeliveryToday}
        isLoading={isLoading}
        nextOrder={nextOrder}
        nextOrderTrackings={nextOrderTrackings}
        previousOrder={previousOrder}
        showSubscriberPricingBanner={showSubscriberPricingBanner}
        subscriptionStatus={subscriptionStatus}
        maxNumRecipes={maxNumRecipes}
      />
    )
  }
}

Header.propTypes = {
  isOrdersPending: PropTypes.bool.isRequired,
  isFetchingUserData: PropTypes.bool.isRequired,
  isProjectedDeliveriesPending: PropTypes.bool.isRequired,
  loadNextProjectedOrder: PropTypes.func.isRequired,
  loadOrders: PropTypes.func.isRequired,
  userLoadData: PropTypes.func.isRequired,
  loadOrderTrackingInfo: PropTypes.func,
  nextOrder: myGoustoOrderPropType,
  nextOrderTrackings: PropTypes.arrayOf(PropTypes.string),
  nextProjectedOrder: PropTypes.shape({
    deliveryDate: PropTypes.string.isRequired,
  }),
  previousOrder: myGoustoOrderPropType,
  showSubscriberPricingBanner: PropTypes.bool,
  subscriptionStatus: PropTypes.string,
  userId: PropTypes.string,
  maxNumRecipes: PropTypes.number,
}

Header.defaultProps = {
  nextOrder: null,
  loadOrderTrackingInfo: () => {},
  nextOrderTrackings: [],
  nextProjectedOrder: null,
  previousOrder: null,
  showSubscriberPricingBanner: false,
  subscriptionStatus: 'inactive',
  userId: null,
  maxNumRecipes: 4,
}

export { Header }
