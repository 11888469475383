import Immutable from 'immutable'
import { AnyAction } from 'redux'

import { actionTypes } from 'actions/actionTypes'
import { CheckoutStore } from 'routes/Checkout/models/CheckoutStore'

const checkoutUrgencyInitialState = Immutable.fromJS({})

export const checkoutUrgencyReducers = {
  checkoutUrgency: (state: CheckoutStore['checkoutUrgency'], action: AnyAction) => {
    if (!state) {
      return checkoutUrgencyInitialState
    }

    switch (action.type) {
      case actionTypes.CHECKOUT_URGENCY_SET_CHECKOUT_STARTED_TIME: {
        return state.set('checkoutStartedTime', action.checkoutStartedTime)
      }
      default: {
        return state
      }
    }
  },
}
