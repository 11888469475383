import React from 'react'

import { FormFieldStatus, InputField } from '@gousto-internal/citrus-react'
import { Field, FieldProps } from 'formik'

export const CardHolderName = () => (
  <Field name="cardHolderName">
    {({ field, meta }: FieldProps) => (
      <InputField
        id={field.name}
        data-testing="checkoutCardNameInput"
        data-testid="checkoutNameOnCard"
        autoComplete="given-name"
        label="Name on card"
        status={meta.touched && meta.error ? FormFieldStatus.Error : undefined}
        validationMessage={meta.touched ? meta.error : undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
    )}
  </Field>
)
