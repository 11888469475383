import { withCitrusTheme, Box } from '@gousto-internal/citrus-react'

const InputFrame = withCitrusTheme(Box, (theme) => {
  return {
    fontFamily: theme.tokens.FontFamilies.book,
    width: '100%',
    height: '3rem',
    borderRadius: '3px',
    visibility: 'hidden',
    border: `1px solid ${theme.tokens.colors.ColdGrey_200}`,
    iframe: {
      borderRadius: '3px',
    },
    '&[class~="frame--activated"]': {
      visibility: 'visible',
    },
    '&:hover': {
      borderColor: theme.tokens.colors.Secondary_400,
    },
    '&[class~="frame--focus"]': {
      borderColor: theme.tokens.colors.Secondary_400,
      borderWidth: '2px',
    },
    '&[class~="frame--invalid"]': {
      borderColor: theme.tokens.colors.Error_900,
      borderWidth: '2px',
    },
  }
})

const Form = withCitrusTheme('form', () => {
  return {
    visibility: 'hidden',
    '&[class~="form--activated"]': {
      visibility: 'visible',
    },
  }
})

const HostedFieldsUI = withCitrusTheme(Box, (theme) => ({
  minHeight: '3rem',
  height: '3rem',
  border: `1px solid ${theme.tokens.colors.ColdGrey_200}`,
  borderRadius: '3px',
  padding: '0 0.75rem',
  '&:hover': {
    borderColor: theme.tokens.colors.Secondary_400,
  },
  '&[class~="braintree-hosted-fields-focused"]': {
    borderColor: theme.tokens.colors.Secondary_400,
    borderWidth: '2px',
  },
  '&[class~="braintree-hosted-fields-invalid"]': {
    borderColor: theme.tokens.colors.Error_900,
    borderWidth: '2px',
  },
}))

export { Form, InputFrame, HostedFieldsUI }
