import actions from 'actions'
import { connect } from 'react-redux'

import { userLoadOrderTrackingsInfo } from 'actions/user'
import { getMaxNumRecipes } from 'routes/Account/MyDeliveries/selectors'
import { getIsMyGoustoBannerSubscriberPricingEnabled } from 'selectors/features'
import { getUserId, getUserSubscriptionState, isFetchingUserData } from 'selectors/user'

import { loadNextProjectedOrder } from '../actions/loadNextProjectedOrder'
import { loadOrders } from '../actions/loadOrders'
import {
  getIsOrdersPending,
  getIsProjectedDeliveriesPending,
  getNextOrder,
  getNextProjectedOrder,
  getPreviousOrder,
} from '../selectors'
import { Header } from './Header.logic'

const mapStateToProps = (state) => ({
  isOrdersPending: getIsOrdersPending(state),
  isFetchingUserData: isFetchingUserData(state),
  isProjectedDeliveriesPending: getIsProjectedDeliveriesPending(state),
  nextOrder: getNextOrder(state),
  nextOrderTrackings: state.user.get('nextOrderTrackings'),
  nextProjectedOrder: getNextProjectedOrder(state),
  previousOrder: getPreviousOrder(state),
  showSubscriberPricingBanner: getIsMyGoustoBannerSubscriberPricingEnabled(state),
  subscriptionStatus: getUserSubscriptionState(state),
  userId: getUserId(state),
  maxNumRecipes: getMaxNumRecipes(state),
})

export const HeaderContainer = connect(mapStateToProps, {
  loadNextProjectedOrder,
  loadOrderTrackingInfo: userLoadOrderTrackingsInfo,
  loadOrders,
  userLoadData: actions.userLoadData,
})(Header)
