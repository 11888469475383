import { RootStateOrAny, useSelector } from 'react-redux'

import { CollectionSlug, useCollections } from '../../domains/collections'

export const useShowJFYTutorial = () => {
  const jfyTutorialSeen = useSelector(({ tutorial }: RootStateOrAny) =>
    Boolean(tutorial && tutorial.getIn(['viewed', 'justforyou'])),
  )
  const { collections } = useCollections()

  if (jfyTutorialSeen) {
    return false
  }

  const recommendationCollection = collections.find(
    (collection) => collection?.get('slug') === CollectionSlug.Recommendations,
  )

  if (!recommendationCollection) {
    return false
  }

  const collectionNameForTutorial = recommendationCollection.getIn(['properties', 'tutorial']) || ''

  // The lack of 'jfy' as "tutorial's name" indicate that the Recommendation collection
  // contains general default recommendations offered to all new customers.
  // Its presence means customer has personally tailored recommendations based on purchase history

  return collectionNameForTutorial === 'jfy'
}
