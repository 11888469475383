import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FocusEvent,
  useCallback,
  useRef,
  useState,
} from 'react'

import styled from '@emotion/styled'
import {
  Box,
  Color,
  FontFamily,
  FormFieldStatus,
  Icon,
  IconVariant,
  InputField,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'

import { FieldContainer } from 'routes/Checkout/Components/AboutYou/FieldContainer'
import { PasswordCriteria } from 'routes/Checkout/Components/PasswordCriteria'
import { validatePassword } from 'routes/Checkout/checkoutActions'
import { getIsMobile, getPasswordErrors } from 'routes/Checkout/checkoutSelectors'

type PasswordFieldProps = {
  id: string
  isTouched?: boolean
  value: string
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleBlur: FocusEventHandler<HTMLInputElement>
}

type ShowPasswordButtonProps = {
  isPasswordVisible: boolean
  onClick: () => void
}

const VALIDATION_DEBOUCE_MS = 300

const IconStyled = styled(Icon)({
  display: 'block',
})

const ShowPasswordButton = ({ isPasswordVisible, onClick }: ShowPasswordButtonProps) => (
  <Box color={Color.ColdGrey_400}>
    <IconStyled
      name={isPasswordVisible ? 'hide' : 'show'}
      variant={IconVariant.Inherit}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
    >
      {isPasswordVisible ? 'hide password' : 'show password'}
    </IconStyled>
  </Box>
)

export const PasswordField = ({
  id,
  isTouched,
  value,
  handleChange,
  handleBlur,
}: PasswordFieldProps) => {
  const dispatch = useDispatch()

  const isMobile = useSelector(getIsMobile)
  const passwordErrors = useSelector(getPasswordErrors)

  const ref = useRef<HTMLInputElement>(null)

  const [isVisible, setIsVisible] = useState(false)
  const [isCriteriaVisible, setIsCriteriaVisible] = useState(false)
  const [isFailedCriteriaVisible, setIsFailedCriteriaVisible] = useState(false)

  const isPasswordValid = value.length > 0 && passwordErrors.length === 0
  const passwordFieldStatus = isPasswordValid ? FormFieldStatus.Success : FormFieldStatus.Error

  const onFocus = useCallback(() => {
    setIsCriteriaVisible(true)
    if (isMobile && ref?.current) {
      window.scrollTo({
        top: ref.current.getBoundingClientRect().top,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [setIsCriteriaVisible, isMobile, ref])

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      handleBlur(e)
      setIsFailedCriteriaVisible(true)
    },
    [handleBlur, setIsFailedCriteriaVisible],
  )

  useDebounce(
    () => {
      dispatch(validatePassword(value))
    },
    VALIDATION_DEBOUCE_MS,
    [value],
  )

  return (
    <>
      <FieldContainer>
        <InputField
          ref={ref}
          id={id}
          data-testing="checkoutPasswordInput"
          type={isVisible ? 'text' : 'password'}
          autoComplete="new-password"
          label={<Text fontFamily={FontFamily.Bold}>Password</Text>}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          status={isTouched ? passwordFieldStatus : undefined}
          rightAccessory={
            <ShowPasswordButton
              isPasswordVisible={isVisible}
              onClick={() => setIsVisible((prevValue) => !prevValue)}
            />
          }
        />
      </FieldContainer>

      {isCriteriaVisible && (
        <PasswordCriteria
          password={value}
          passwordErrors={passwordErrors}
          showFailedCriteria={isFailedCriteriaVisible}
        />
      )}
    </>
  )
}
