import { BoxPrices, BoxType } from 'apis/boxPrices'

export interface BoxPrice {
  numberOfRecipes: string
  pricePerPortion: string
}

export function calculateBestBoxPrice(
  boxPrices: BoxPrices,
  numPortions: string,
  boxType: BoxType,
): BoxPrice | undefined {
  const boxPricesForNumberOfPortions = boxPrices[numPortions]

  if (!boxPricesForNumberOfPortions) {
    return
  }

  const boxPricesRankedCheapestToMostExpensive = Object.entries(boxPricesForNumberOfPortions).sort(
    (boxA, boxB) => {
      const [, boxABoxes] = boxA
      const [, boxBBoxes] = boxB

      return (
        Number(boxABoxes[boxType].pricePerPortionDiscounted) -
        Number(boxBBoxes[boxType].pricePerPortionDiscounted)
      )
    },
  )

  const [
    numberOfRecipes,
    {
      [boxType]: { pricePerPortionDiscounted: pricePerPortion },
    },
  ] = boxPricesRankedCheapestToMostExpensive[0]

  // eslint-disable-next-line consistent-return
  return {
    numberOfRecipes,
    pricePerPortion,
  }
}
