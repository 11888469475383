import React, { memo } from 'react'

import {
  Box,
  Text,
  Color,
  Display,
  DisplayPosition,
  AlignItems,
  JustifyContent,
  Heading1,
  Icon,
  IconVariant,
  Space,
  FontWeight,
  TextAlign,
  BorderStyle,
  Visible,
} from '@gousto-internal/citrus-react'
import Loading from 'Loading'
import { PricePerServingMessage } from 'PricePerServingMessage'
import { useDispatch, useSelector } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { Receipt } from 'components/Receipt'
import { deliveryPriceConfig } from 'config/deliveryPrice'
import { client } from 'config/routes'
import { usePricing } from 'routes/Menu/domains/pricing'
import { getBasketRecipesCount, getNumPortions } from 'selectors/basket'

import { PromoCode } from '../../PromoCode'
import { RecipeSummary } from '../../RecipeSummary'
import { TickText } from '../../TickText'
import { summaryTexts } from '../data'
import { checkoutClickEditOrderLink } from '../trackingKeys'
import { StyledLink } from './Summary.styled'

type SummaryProps = {
  isLoading: boolean
}

export const Summary = memo(({ isLoading }: SummaryProps) => {
  const dispatch = useDispatch()

  const { pricing } = usePricing()
  const numRecipes = useSelector(getBasketRecipesCount)
  const numPortions = useSelector(getNumPortions)

  const linkClickTrackingHandler = () => {
    dispatch(trackUTMAndPromoCode(checkoutClickEditOrderLink))
  }

  const deliveryPrice = deliveryPriceConfig.deliveryPriceFormatted
  const {
    orderSummaryTitle,
    orderSummaryTabletAndDesktopTitle,
    highlightedText,
    ordinaryTextStart,
    ordinaryTextEnd,
  } = summaryTexts

  return (
    <Box>
      <Box
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.SpaceBetween}
        position={DisplayPosition.Relative}
        data-testing="checkoutOrderSummary"
      >
        <Visible display={[Display.Block, Display.None]}>
          <Heading1 size={7}>{orderSummaryTitle}</Heading1>
        </Visible>
        <Visible display={[Display.None, Display.Block]}>
          <Heading1
            size={7}
          >{`${orderSummaryTabletAndDesktopTitle}(${numPortions} people)`}</Heading1>
        </Visible>
        <StyledLink to={client.menu} onClick={linkClickTrackingHandler}>
          <Text size={1} fontWeight={FontWeight.Bold}>
            Edit order
          </Text>
        </StyledLink>
      </Box>
      <Space size={4} direction="vertical" />

      {isLoading ? (
        <Box
          display={Display.Flex}
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          minHeight="18.75rem"
          data-testid="loading-modal"
        >
          <Box width="3rem">
            <Loading />
          </Box>
        </Box>
      ) : (
        <Box data-testid="content-container">
          <Box
            bg={Color.Success_50}
            borderColor={Color.Success_200}
            paddingV={2}
            paddingH={3}
            display={Display.Flex}
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.Center}
            borderWidth={0.5}
            borderStyle={BorderStyle.Solid}
            borderRadius={1.5}
          >
            <Text color={Color.Success_900}>
              <Space size={2} direction="vertical" />
              <Icon name="offer_percentage" variant={IconVariant.Inherit} />
            </Text>
            <Space size={2} direction="horizontal" />
            <PricePerServingMessage
              isPriceInCheckout
              fullPrice={pricing?.pricePerPortion}
              discountedPrice={pricing?.pricePerPortionDiscounted}
            />
          </Box>
          <Space size={3} direction="vertical" />
          <Visible display={[Display.None, Display.Block]}>
            <RecipeSummary />
            <Space size={1} />
          </Visible>
          <Box>
            <Receipt
              numRecipes={numRecipes}
              prices={pricing}
              deliveryTotalPrice={pricing?.deliveryTotal}
              surchargeCount={pricing?.surchargeCount}
              surchargeTotal={pricing?.surchargeTotal}
              recipeTotalPrice={pricing?.recipeTotal}
              totalToPay={pricing?.total || ''}
              recipeDiscountAmount={pricing?.recipeDiscount}
              recipeDiscountPercent={pricing?.percentageOff}
              extrasTotalPrice={pricing?.productTotal}
              isDeliveryFree={pricing?.isDeliveryFree}
              isReceiptInCheckout
            />
            <PromoCode />
            {pricing?.isDeliveryFree && (
              <TickText
                textAlign={TextAlign.Left}
                fontWeight={FontWeight.Normal}
                tickPadding={2}
                highlightedText={highlightedText}
                ordinaryText={`${ordinaryTextStart} ${deliveryPrice}${ordinaryTextEnd}`}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
})

Summary.displayName = 'Summary'
