import React from 'react'

import { Box, Link } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import routesConfig from 'config/routes'

import { DeliveryDate } from './DeliveryDate'
import { OrderTotal } from './OrderTotal'
import { SectionHeading, Divider } from './SharedComponents'
import { YourBox, useGetYourBoxData } from './YourBox'
import { trackClickEditOrderLink } from './snowplowActions'

type BoxDetailsHighlightProps = {
  isLoading: boolean
}

export const BoxDetailsHighlight = ({ isLoading }: BoxDetailsHighlightProps) => {
  const dispatch = useDispatch()
  const { recipesIdsList } = useGetYourBoxData()

  const onClickLink = () => {
    dispatch(trackClickEditOrderLink(recipesIdsList))
  }

  return (
    <Box data-testid="BoxDetailsHighlightContainer" paddingV={6} paddingH={6}>
      <YourBox />
      <Divider />

      <SectionHeading text="Order Total">
        <Link href={routesConfig.client.menu} onClick={onClickLink}>
          Edit order
        </Link>
      </SectionHeading>
      <OrderTotal isLoading={isLoading} />
      <Divider />

      <SectionHeading text="Delivery Date" />
      <DeliveryDate />
    </Box>
  )
}
