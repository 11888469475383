import React from 'react'
import PropTypes from 'prop-types'
import {
  Body2,
  Bold,
  Heading3,
  Join,
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalButton,
  ButtonColorVariant,
  ButtonSize,
  Space,
  useModal
} from '@gousto-internal/citrus-react'

export const MultiSkipResultScreen = ({
  onClose,
  nextDeliveryDate,
  isSuccess,
  skippedBoxesCount
}) => {
  const { closeCurrentModal } = useModal()

  return (
    <Modal
      isOpen
      name="multi-skip-result"
      description="Skip multiple boxes"
      onAfterClose={onClose}
    >
      <ModalClose onClose={closeCurrentModal} />
      <ModalHeader>
        <Heading3>
          {isSuccess ? 'Skip successful' : 'Oops! Something went wrong...'}
        </Heading3>
      </ModalHeader>

      <ModalBody>
        <Join with={<Space size={4} />}>
          {!isSuccess && (
          <Body2>
            {'We\'re really sorry, but it seems there\'s an unexpected issue on our end: we\'re currently unable to update the frequency of your subscription.'}
          </Body2>
          )}

          {isSuccess && (
          <Body2 data-testing="multi-skip-subheading">
            {'You\'ve skipped '}
            <Bold>{`${skippedBoxesCount} `}</Bold>
            {`delivery day${skippedBoxesCount > 1 ? 's' : ''}.`}
          </Body2>
          )}

          {isSuccess && nextDeliveryDate && (
          <Body2>
            {'We\'ll deliver your next box on '}
            <Bold>{`${nextDeliveryDate}.`}</Bold>
          </Body2>
          )}
        </Join>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Large}
          onClick={closeCurrentModal}
        >
          Done
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

MultiSkipResultScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  nextDeliveryDate: PropTypes.string,
  isSuccess: PropTypes.bool.isRequired,
  skippedBoxesCount: PropTypes.number
}

MultiSkipResultScreen.defaultProps = {
  skippedBoxesCount: null,
  nextDeliveryDate: null
}
