import React, { forwardRef, useEffect, useState } from 'react'

import {
  NullableReCAPTCHA,
  useChallengeClosingObserver,
} from 'Recaptcha/useChallengeClosingObserver'
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha'

import { fetchFeatures } from 'apis/fetchS3'
import { getRecaptchaPublicKey } from 'utils/isomorphicEnvironment'
import logger from 'utils/logger'

type RecaptchaProps = Omit<ReCAPTCHAProps, 'sitekey'> &
  Partial<Pick<ReCAPTCHAProps, 'sitekey'>> & { onChallengeClose?: () => void }

/**
 * To use the explicit version, see the documentation https://www.npmjs.com/package/react-google-recaptcha.
 * By default, reCAPTCHA is in invisible mode. For it to work it is necessary to provide two props:
 * @property {NullableReCAPTCHA} ref - reference to grecaptcha object that is exposed by reCAPTCHA API,
 *                                   call its execute() method to invoke the verification
 * @property {(token: string | null) => void} onChange - callback that will be called with token when verification
 *                                                       completes, also called with null when captcha expires
 */
export const Recaptcha = forwardRef<NullableReCAPTCHA, RecaptchaProps>(
  (props: RecaptchaProps, ref) => {
    const {
      sitekey = getRecaptchaPublicKey(),
      onChange,
      grecaptcha,
      theme,
      type,
      tabindex,
      onExpired,
      asyncScriptOnLoad,
      onErrored,
      stoken,
      hl,
      size = 'invisible',
      badge,
      onChallengeClose,
    } = props

    const [isEnabled, setIsEnabled] = useState(false)
    const [isScriptLoaded, setIsScriptLoaded] = useState(false)
    const internalRef = useChallengeClosingObserver(ref, isScriptLoaded, onChallengeClose)

    useEffect(() => {
      async function getIsEnabled() {
        try {
          const { data } = await fetchFeatures()
          if (data) {
            const { isRecaptchaEnabled } = data
            setIsEnabled(isRecaptchaEnabled)
          }
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          logger.error({ message: `S3File fetch failed: ${JSON.stringify(err)}` })
        }
      }

      getIsEnabled()
    }, [])

    if (!isEnabled) return null

    return (
      <ReCAPTCHA
        ref={internalRef}
        sitekey={sitekey}
        onChange={onChange}
        grecaptcha={grecaptcha}
        theme={theme}
        type={type}
        tabindex={tabindex}
        onExpired={onExpired}
        asyncScriptOnLoad={() => {
          setIsScriptLoaded(true)
          asyncScriptOnLoad?.()
        }}
        onErrored={onErrored}
        stoken={stoken}
        hl={hl}
        size={size}
        badge={badge}
      />
    )
  },
)

Recaptcha.displayName = 'Recaptcha'
