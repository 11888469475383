import moment from 'moment'

// Orders from the BE come with a state that has nothing to do with what the client needs
// Because of that, we have this kind of logic in all the clients
export const getClientOrderState = (state, deliveryDate, recipeItems, phase, noOfBoxesPerOrder = 1) => {
  const isDeliveryDay = moment().isSame(deliveryDate, 'day')
  const isCancellable = phase === 'awaiting_choices' || phase === 'open'

  if (phase === 'pre_menu') {
    return 'scheduled'
  }

  if (state === 'committed' && isDeliveryDay) {
    return noOfBoxesPerOrder && noOfBoxesPerOrder > 1 ? `dispatched as ${noOfBoxesPerOrder} boxes` : 'dispatched'
  }

  if ((state === 'committed' && !isDeliveryDay) || (state === 'pending' && !isCancellable)) {
    return 'confirmed'
  }

  if (state === 'pending' && !recipeItems.size) {
    return 'menu open'
  }

  if (state === 'pending' && !!recipeItems.size) {
    return 'recipes chosen'
  }

  return state
}
