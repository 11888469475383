import { composeFetch } from '@library/http'
import { addPath, auth, composeRequest, setMethod, setServiceUrl } from '@library/http/requests'
import { extractUserCookbookResponse } from './deserialisers'

export const userCookbook = composeFetch(
  composeRequest<string | number>(
    setServiceUrl('cookbook', 1),
    addPath(() => `user/current/recipes`),
    setMethod('GET'),
    auth,
  ),
  extractUserCookbookResponse,
)
