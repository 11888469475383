import { useCallback, useEffect, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import * as braintree from 'braintree-web'
import { useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { PaymentProps } from 'routes/Checkout/Steps/Payment/Payment'
import {
  checkoutClearErrors,
  fireCheckoutError,
  setPayPalNonce,
} from 'routes/Checkout/checkoutActions'
import { CARD_DETAILS } from 'routes/Checkout/checkoutConfig'
import { sendClientMetric } from 'routes/Menu/apis/clientMetrics'

import { formatAddress } from '../utils/utils'

const initialState = {
  errorStatus: {
    showError: false,
    isEmpty: true,
  },
  isFormValid: false,
  isFormSubmitting: false,
}

const hostedFieldsOptions = {
  number: {
    container: `#${CARD_DETAILS.CARD_NUMBER}`,
    formatInput: true,
  },
  expirationDate: {
    container: `#${CARD_DETAILS.EXPIRY_DATE}`,
  },
  cvv: {
    container: `#${CARD_DETAILS.CVV}`,
  },
}

const hostedFieldStyles = {
  input: {
    color: '#516272',
    'font-family': 'AxiformaBook, Helvetica, sans-serif',
    'font-size': '16px',
    'line-height': '20px',
  },
}
type useHostedFieldsProps = Pick<
  PaymentProps,
  'braintreeClientInstance' | 'submitOrder' | 'setBraintreeClientInstance'
>

export const useHostedFields = ({
  braintreeClientInstance,
  setBraintreeClientInstance,
  submitOrder,
}: useHostedFieldsProps) => {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<braintree.HostedFields | null>(
    null,
  )
  const [threeDSecureInstance, setThreeDSecureInstance] = useState<braintree.ThreeDSecure | null>(
    null,
  )
  const [hostedFieldsFrameLoaded, setHostedFieldsFrameLoaded] = useState(false)
  const [inputCardNumberError, setInputCardNumberError] = useState(initialState.errorStatus)
  const [inputExpiryDateError, setInputExpiryDateError] = useState(initialState.errorStatus)
  const [inputCvvError, setInputCvvError] = useState(initialState.errorStatus)
  const [isFormValid, setIsFormValid] = useState<boolean>(initialState.isFormValid)
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(initialState.isFormSubmitting)
  const dispatch = useDispatch()
  const handleCustomerCancelledThreeDSecure = useCallback(() => {
    if (!threeDSecureInstance) return
    dispatch(fireCheckoutError(actionTypes.CUSTOMER_CANCELLED_THREEDSECURE))
    threeDSecureInstance.cancelVerifyCard(() =>
      datadogLogs.logger.error('Braintree customer cancelled 3DS'),
    )
  }, [dispatch, threeDSecureInstance])

  const handleLookUpComplete = useCallback(
    (data, next) => {
      if (data?.requiresUserAuthentication || data?.threeDSecureInfo?.liabilityShifted) {
        next()
        datadogLogs.logger.info(`look up successful with ${data.requiresUserAuthentication}`)
      } else {
        dispatch(fireCheckoutError(actionTypes.CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE))
        setIsFormSubmitting(false)
        threeDSecureInstance?.cancelVerifyCard(() =>
          datadogLogs.logger.error(
            `Verify card cancelled with status ${data.paymentMethod.threeDSecureInfo.status}`,
          ),
        )
      }
    },
    [dispatch, threeDSecureInstance],
  )

  // when user submits the form
  const handleSubmit = async (options: Record<string, any>) => {
    dispatch(checkoutClearErrors())
    if (!hostedFieldsInstance || !threeDSecureInstance) return
    setIsFormSubmitting(true)

    try {
      setIsFormSubmitting(true)
      const payload = await hostedFieldsInstance.tokenize({
        cardholderName: options.values.cardHolderName,
      })

      threeDSecureInstance.on('lookup-complete', handleLookUpComplete)

      threeDSecureInstance.on('customer-canceled', handleCustomerCancelledThreeDSecure)

      const { deliveryAddress, values } = options
      const address = formatAddress(deliveryAddress)
      const formValues = values.isBillingAddressDifferent ? values : address

      const result = await threeDSecureInstance.verifyCard({
        nonce: payload.nonce,
        amount: '0.0' as any,
        challengeRequested: true,
        bin: payload.details.bin,
        additionalInformation: {
          acsWindowSize: '03',
        },
        billingAddress: {
          streetAddress: formValues.houseNo.substring(0, 50),
          postalCode: formValues.postcode,
          locality: formValues.town,
        },
      })

      if (result.threeDSecureInfo.liabilityShiftPossible) {
        if (result.threeDSecureInfo.liabilityShifted) {
          dispatch(setPayPalNonce(result.nonce))
          submitOrder()
          datadogLogs.logger.info('braintree signup attempt starts')
        } else {
          dispatch(fireCheckoutError(actionTypes.SIGNUP_PAYMENT_FAILED))
          datadogLogs.logger.error(
            `liabilityShifted failed ${result.threeDSecureInfo.liabilityShifted}`,
          )
        }
      } else {
        dispatch(fireCheckoutError(actionTypes.VALID_CARD_DETAILS_NOT_PROVIDED))
        datadogLogs.logger.error(
          `liabilityShiftPossible failed ${result.threeDSecureInfo.liabilityShiftPossible}`,
        )
      }
    } catch (error) {
      dispatch(fireCheckoutError(actionTypes.VALID_CARD_DETAILS_NOT_PROVIDED))
      datadogLogs.logger.error(`braintree signup attempt failed ${JSON.stringify(error)}`)
    } finally {
      setIsFormSubmitting(false)
    }
  }

  // when user clicks submit without filling form details
  const handleCardDetailsValidation = () => {
    if (inputCardNumberError.isEmpty) {
      setInputCardNumberError({ ...inputCardNumberError, showError: true })
    }
    if (inputCvvError.isEmpty) {
      setInputCvvError({ ...inputCvvError, showError: true })
    }
    if (inputExpiryDateError.isEmpty) {
      setInputExpiryDateError({
        ...inputExpiryDateError,
        showError: true,
      })
    }
  }

  const handleOnValidityChanged = useCallback((event: any) => {
    const errorField = event.emittedBy
    const formValid = Object.keys(event.fields).every((key) => event.fields[key].isValid)
    setIsFormValid(formValid)

    const { isEmpty, isValid } = event.fields[errorField]
    switch (event.emittedBy) {
      case 'number':
        return setInputCardNumberError({
          showError: !isValid,
          isEmpty,
        })
      case 'expirationDate':
        return setInputExpiryDateError({
          showError: !isValid,
          isEmpty,
        })
      case 'cvv':
        return setInputCvvError({ showError: !isValid, isEmpty })
      default:
        return undefined
    }
  }, [])

  const initThreeDSecure = useCallback(async () => {
    const createThreeDSecureInstance = async () => {
      if (!braintreeClientInstance) return
      const threeDS = await braintree.threeDSecure.create({
        client: braintreeClientInstance,
        version: 2,
      })
      setThreeDSecureInstance(threeDS)
      datadogLogs.logger.info('threeDSecureInstance created')
    }
    try {
      await createThreeDSecureInstance()
    } catch (error) {
      datadogLogs.logger.error(`threeDSecureInstance failed with ${JSON.stringify(error)}`)
    }
  }, [braintreeClientInstance])

  const initHostedFields = useCallback(async () => {
    const createHostedFieldInstance = async () => {
      if (!braintreeClientInstance) return
      const hostedField = await braintree.hostedFields.create({
        client: braintreeClientInstance,
        fields: hostedFieldsOptions,
        styles: hostedFieldStyles,
      })
      setHostedFieldsInstance(hostedField)
      datadogLogs.logger.info('hostedFieldInstance created')
    }
    try {
      await createHostedFieldInstance()
    } catch (error) {
      datadogLogs.logger.error(`hostedFieldInstance failed with ${JSON.stringify(error)}`)
      sendClientMetric('hostedFieldInstance failed', JSON.stringify(error))
    }
  }, [braintreeClientInstance])

  const initCardDetailsValidation = useCallback(async () => {
    if (!hostedFieldsInstance) return

    hostedFieldsInstance.on('validityChange', handleOnValidityChanged)
    hostedFieldsInstance.on('blur', handleOnValidityChanged)
  }, [handleOnValidityChanged, hostedFieldsInstance])

  // to initalise hostedfields client
  useEffect(() => {
    if (braintreeClientInstance && hostedFieldsFrameLoaded) {
      initHostedFields()
    }
  }, [braintreeClientInstance, initHostedFields, hostedFieldsFrameLoaded])

  // to initalise 3DSecure
  useEffect(() => {
    if (braintreeClientInstance) {
      initThreeDSecure()
    }
  }, [braintreeClientInstance, initThreeDSecure])

  // do card verification
  useEffect(() => {
    if (hostedFieldsInstance) {
      initCardDetailsValidation()
    }
  }, [hostedFieldsInstance, initCardDetailsValidation])

  useEffect(
    () => () => {
      if (braintreeClientInstance) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore the type here is incorrect, callback is optional
        braintreeClientInstance.teardown()
        setBraintreeClientInstance(null)
      }
    },

    [braintreeClientInstance, setBraintreeClientInstance],
  )

  useEffect(
    () => () => {
      if (threeDSecureInstance) {
        threeDSecureInstance.teardown()
        setThreeDSecureInstance(null)
      }
    },

    [threeDSecureInstance],
  )

  useEffect(
    () => () => {
      if (hostedFieldsInstance) {
        hostedFieldsInstance.teardown()
        setHostedFieldsInstance(null)
      }
    },

    [hostedFieldsInstance],
  )

  return {
    formValidationError: {
      showCardNumberError: inputCardNumberError.showError,
      showCVVError: inputCvvError.showError,
      showExpiryDateError: inputExpiryDateError.showError,
    },
    isFormValid,
    handleCardDetailsValidation,
    handleOnValidityChanged,
    setHostedFieldsInstance,
    setThreeDSecureInstance,
    handleSubmit,
    isFormSubmitting,
    handleLookUpComplete,
    handleCustomerCancelledThreeDSecure,
    setHostedFieldsFrameLoaded,
  }
}
