import React from 'react'

import { Box, Body1, Heading1, Join, Space, TextAlign } from '@gousto-internal/citrus-react'

import { CookiePolicyDetails } from './CookiePolicyDetails'

export function CookiesPolicy() {
  return (
    <section id="cookies-policy">
      <Box paddingV={4}>
        <Join with={<Space size={4} />}>
          <Heading1 textAlign={TextAlign.Center}>Cookies Policy</Heading1>
          <Body1>Effective Date: 05/02/2024 (Version 3.0)</Body1>
          <Body1>
            We and our trusted partners use cookies and similar technologies, such as tags and
            pixels (“cookies”) to personalise and improve your experience when you browse our
            website or open a marketing email from us. This policy provides more information about
            how we use cookies and how you can make choices about our use of cookies.
          </Body1>
          <CookiePolicyDetails />
        </Join>
      </Box>
    </section>
  )
}
