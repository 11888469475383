import React from 'react'

import { Body3, Color, FontWeight, Heading3, Space } from '@gousto-internal/citrus-react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { getBasketDate, getBasketSlotId } from 'selectors/basket'
import { getSlotTimes } from 'utils/deliveries'

import { getDeliveryDays } from '../selectors'

export function DeliveryDate() {
  const date = useSelector(getBasketDate)
  const slotId = useSelector(getBasketSlotId)
  const deliveryDays = useSelector(getDeliveryDays)

  const deliveryDate = moment(date).format('dddd Do MMMM')
  const deliveryTime = getSlotTimes({ date, deliveryDays, slotId })

  return (
    <>
      <Heading3 size={5}>Delivery date</Heading3>
      <Space size={4} />
      <Body3 fontWeight={FontWeight.Bold} color={Color.ColdGrey_800}>
        {deliveryDate}
      </Body3>
      <Body3 color={Color.ColdGrey_800}>{deliveryTime.toUpperCase()}</Body3>
    </>
  )
}
