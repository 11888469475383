import Immutable from 'immutable'
import { actionTypes } from 'actions/actionTypes'

export const recipesReducers = {
  recipes: (state = Immutable.Map({}), action) => {
    switch (action.type) {
    case actionTypes.RECIPES_RECEIVE: {
      const newRecipes = (action.recipes || []).reduce((reducerState, recipe) => reducerState.set(recipe.id, Immutable.fromJS(recipe)), Immutable.OrderedMap({}))
      const newState = state.merge(newRecipes)

      return newState
    }

    default: {
      return state
    }
    }
  },
}
