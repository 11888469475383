import React, { FC } from 'react'

import { Box, Checkbox, withCitrusTheme, Body1 } from '@gousto-internal/citrus-react'

const Title = withCitrusTheme(Body1, (theme) => ({
  paddingBottom: theme.spacing(2),
}))
interface Props {
  selectedOTIFs: string[]
  title: string
  onOTIFSelected: (selected: boolean, value: string) => void
}

const checkboxItems = [
  {
    key: '44a8adea-2e10-4ae4-ba55-374277aaf3dc',
    value: 'Recipe taste',
  },
  { key: '0e512cc4-9c97-4591-a19e-953d63b4b7ab', value: 'Recipe method' },
  { key: '999a0e3b-7754-4f1e-8414-72887b4c2ff0', value: 'Portion size' },
  { key: '6cbc0d4b-79f2-4d1e-8306-7ec92f52a6a3', value: 'Delivery/Ingredient quality' },
  { key: '85b96c05-3790-4746-b984-650c32b88ccb', value: 'Other' },
]

export const OTIFList: FC<Props> = (props) => {
  const { onOTIFSelected, selectedOTIFs, title } = props

  return (
    <Box style={{ marginBottom: '1rem' }}>
      <Title>{title}</Title>
      {checkboxItems.map((item) => (
        <Box key={item.key} paddingV={1}>
          <Checkbox
            id={item.key}
            checked={selectedOTIFs.includes(item.key)}
            outline
            paddingV={3}
            paddingH={3}
            onChange={(event) => onOTIFSelected(event.target.checked, item.key)}
          >
            {item.value}
          </Checkbox>
        </Box>
      ))}
    </Box>
  )
}
