import React from 'react'

import {
  Box,
  Display,
  Text,
  Space,
  FontFamily,
  Icon,
  IconVariant,
  Color,
  Bold,
  BorderStyle,
} from '@gousto-internal/citrus-react'

import { signupConfig } from 'routes/Signup/signupConfig'

export const FreeDeliveryCard = () => {
  const {
    postCodeStep: {
      freeDeliveryMain,
      freeDeliveryTextPart1,
      freeDeliveryTextPart2,
      freeDeliveryTextPart3,
    } = {},
  } = signupConfig

  return (
    <Box
      data-testid="freeDeliveryCard"
      display={Display.Flex}
      width={['100%', '351px']}
      bg={Color.Success_50}
      paddingH={4}
      paddingV={4}
      borderColor={Color.Success_200}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      gap={2}
    >
      <Box>
        <Icon size={6} name="offer" variant={IconVariant.Confirmation} />
      </Box>
      <Box>
        <Text color={Color.Success_900} fontFamily={FontFamily.Bold} size={2}>
          {freeDeliveryMain}
        </Text>
        <Space size={1} />
        <Text size={2}>
          {freeDeliveryTextPart1} <Bold>{freeDeliveryTextPart2}</Bold> {freeDeliveryTextPart3}
        </Text>
      </Box>
    </Box>
  )
}
