import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import classNames from 'classnames'

import { formatSurcharge } from '../../../../utils/formatSurcharge'

import css from './Surcharge.css'

type SurchargeProps = {
  surcharge: number
}

export const Surcharge = ({ surcharge }: SurchargeProps) => {
  if (!surcharge) {
    return null
  }

  return (
    <Box className={classNames(css.surcharge, { [css.valueBackground]: surcharge < 0 })}>
      {formatSurcharge(surcharge)} per serving
    </Box>
  )
}
