/**
 * This code was migrated from webclient. For the original file's history, see
 * https://github.com/Gousto/gousto-webclient/blob/1544f0f8/src/apps/webclient/src/config/service-environment/service-url.ts
 */
import { ENVIRONMENT_NAMES, PROTOCOL_PREFIX } from '@library/environment'

import { SERVICE_DOMAINS, OVERRIDDEN_SERVICE_PATTERNS, ServiceUrlProperties } from './types'

const DEFAULT_API_SUFFIX = 'api'
const PRODUCTION_URL = 'https://www.gousto.co.uk'

// TODO: this is potentially a security risk / leaking information.
// Ideally we would not want this to be exposed in the public bundle.
const LOCAL_SERVICE_HOSTNAME = 'staging-api.gousto.info'
// const LOCAL_SERVICE_HOSTNAME = 'radishes-api.gousto.info'
// const LOCAL_SERVICE_HOSTNAME = 'production-api.gousto.co.uk'

function isWebclientProductionService({
  serviceName,
  environmentName,
  protocol,
}: ServiceUrlProperties) {
  return (
    serviceName === OVERRIDDEN_SERVICE_PATTERNS.webclient &&
    environmentName === ENVIRONMENT_NAMES.production &&
    protocol === PROTOCOL_PREFIX.HTTPS
  )
}

function isLoggingManagerService({ serviceName }: ServiceUrlProperties) {
  return serviceName === OVERRIDDEN_SERVICE_PATTERNS.loggingmanager
}

function isDev({ serviceDomain }: ServiceUrlProperties) {
  return serviceDomain.startsWith(SERVICE_DOMAINS.local)
}

function webClientHost({ environmentName, serviceDomain }: ServiceUrlProperties) {
  return environmentName === ENVIRONMENT_NAMES.production
    ? PRODUCTION_URL
    : `${PROTOCOL_PREFIX.HTTPS}//${environmentName}-webclient.${serviceDomain}`
}

function overrideForLocalDev({ basePath }: ServiceUrlProperties) {
  return `${PROTOCOL_PREFIX.HTTPS}//${LOCAL_SERVICE_HOSTNAME}${basePath}`
}

/**
 * Note from Aleh Belski:
 * ----------------------
 * FYI: From information I had:
 * loggingManager should request staging-api on local environment.
 * But it aims local url which don't even have.
 * Removing this override will fix behaviour only for staging and local env
 */
function overrideForLoggingManager({ environmentName, serviceDomain }: ServiceUrlProperties) {
  const envNameOverride = environmentName === 'local' ? 'staging' : environmentName
  const domainOverride = environmentName === 'local' ? 'gousto.info' : serviceDomain

  const address = `${envNameOverride}-${DEFAULT_API_SUFFIX}`

  return `${PROTOCOL_PREFIX.HTTPS}//${address}.${domainOverride}/${OVERRIDDEN_SERVICE_PATTERNS.loggingmanager}`
}

export function serviceOverrides(serviceUrlProperties: ServiceUrlProperties): string | undefined {
  switch (true) {
    case isWebclientProductionService(serviceUrlProperties):
      return webClientHost(serviceUrlProperties)

    case isDev(serviceUrlProperties):
      return overrideForLocalDev(serviceUrlProperties)

    case isLoggingManagerService(serviceUrlProperties):
      return overrideForLoggingManager(serviceUrlProperties)

    default:
      return undefined
  }
}

/**
 * Builds a service URL for the given service url properties.
 *
 * Service url takes all the parts required to generate a service url and creates the service url string.
 * It accepts overrides functions to override the default functionality
 *
 * @param serviceUrlProperties - The service url properties.
 * @param getOverriddenUrl
 * @returns {string}
 *
 * @example
 * const myServiceUrl = serviceUrl({
 *  serviceName: 'orders',
 *  environmentName: 'fef',
 *  serviceDomain: 'gousto.info',
 *  protocol: PROTOCOL_PREFIX.HTTPS,
 *  basePath: '/orders/v1.0.0'
 *  }) // => https://fef-orders.gousto.info/orders/v1.0.0
 *
 */
export function serviceUrl(
  serviceUrlProperties: ServiceUrlProperties,
  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow,no-unused-vars
  getOverriddenUrl: (
    serviceUrlProperties: ServiceUrlProperties,
  ) => string | undefined = serviceOverrides,
) {
  const { protocol, environmentName, serviceName, serviceDomain, basePath, port } =
    serviceUrlProperties

  if (protocol === PROTOCOL_PREFIX.HTTPS && basePath === undefined) {
    throw new Error('basePath must be defined for HTTPS services')
  }

  const override = typeof getOverriddenUrl === 'function' && getOverriddenUrl(serviceUrlProperties)

  if (override) {
    return override
  }

  const subDomainSuffix = typeof basePath === 'undefined' ? serviceName : DEFAULT_API_SUFFIX

  /* this is the service pattern for the majority of services */
  const hostname = `${environmentName}-${subDomainSuffix}.${serviceDomain}`
  const serviceOrigin = `${protocol}//${hostname}${port ? `:${port}` : ''}`

  const url = new URL(`${serviceOrigin}${basePath || ''}`).toString()

  // HACK: The original implementation of endpoint function did not include a trailing slash.
  // Adding this for compatibility
  if (url.endsWith('/')) {
    return url.slice(0, -1)
  }

  return url
}
