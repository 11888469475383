import { Box } from '@gousto-internal/citrus-react'
import React from 'react'

import { HeroCarouselSection } from './components/HeroCarouselSection/HeroCarouselSection'
import { HeroSection } from './components/HeroSection/HeroSection'
import { PromoBanner, PromoBannerText } from './components/PromoBanner'
import type { Recipe } from './types/recipeType'
import { LandingPageContent } from '../../landing-page-content/src/LandingPageContent'
import { LandingPageAboveTheFold } from '../../landing-page-above-the-fold/src/LandingPageAboveTheFold'

export type HomepageProps = {
  applyDiscount: () => void
  isPromoBannerVisible: boolean
  isLandingPageAboveTheFoldEnabled?: boolean
  promoBannerText: string
  onSignupCTAClick: () => void
  signupCTA: string
  recipes: Record<string, Recipe>
}

export function Homepage({
  applyDiscount,
  promoBannerText,
  isPromoBannerVisible,
  isLandingPageAboveTheFoldEnabled = false,
  signupCTA,
  onSignupCTAClick,
  recipes,
}: HomepageProps) {
  return (
    <Box data-testid="homepage-redesigned">
      {isPromoBannerVisible && (
        <PromoBanner applyDiscount={applyDiscount}>
          <PromoBannerText>{promoBannerText}</PromoBannerText>
        </PromoBanner>
      )}
      {isLandingPageAboveTheFoldEnabled ? (
        <LandingPageAboveTheFold onCTAClick={onSignupCTAClick} signupCTA={signupCTA} />
      ) : (
        <>
          <HeroSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
          <HeroCarouselSection />
        </>
      )}
      <LandingPageContent
        onSignupCTAClick={onSignupCTAClick}
        recipes={recipes}
        signupCTA={signupCTA}
      />
    </Box>
  )
}
