import { composeFetch } from '@library/http'
import { composeRequest, addPath, setMethod, setServiceUrl } from '@library/http/requests'
import { extractCoreData } from '@library/http/parsers'

const rafCampaignDetailsRequest = composeRequest(
  setServiceUrl('core'),
  addPath((promocode: string) => `promocode/raf-campaign-details/${promocode}`),
  setMethod('GET'),
)

export const fetchRafCampaignDetails = composeFetch(rafCampaignDetailsRequest, extractCoreData())
