import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  DisplayPosition,
  FlexDirection,
  Image,
  JustifyContent,
  Body2,
  Body3,
  FontWeight,
  TextAlign,
  useModal,
} from '@gousto-internal/citrus-react'

import { useCollections } from 'routes/Menu/domains/collections'

export type SearchCategoryProps = {
  id: string
  imageUrl: string
  name: string
  recipeCount: number
}

export const SearchCategory = ({ id, imageUrl, name, recipeCount }: SearchCategoryProps) => {
  const { changeCollectionById } = useCollections()
  const { closeCurrentModal } = useModal()

  const onClick = () => {
    closeCurrentModal()
    changeCollectionById(id)
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      onClick()
    }
  }

  return (
    <Box
      aria-label="Visit category"
      key={id}
      display={Display.Flex}
      justifyContent={JustifyContent.Center}
      paddingV={2}
      flexDirection={FlexDirection.Column}
      position={DisplayPosition.Relative}
      alignItems={AlignItems.Center}
      data-testid="search-category"
      onClick={onClick}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
      onKeyDown={onKeyDown}
    >
      <Box bg={Color.NeutralGrey_50} width="100%" height="65px" />
      <Box position={DisplayPosition.Absolute} top="0">
        <Image src={imageUrl} alt={name} height="auto" width="90px" />
      </Box>
      <Box display={Display.Flex} paddingV={2} paddingBottom={0} alignItems={AlignItems.Baseline}>
        <Body2 fontWeight={FontWeight.Bold} color={Color.ColdGrey_800} textAlign={TextAlign.Center}>
          {name}
          <Body3 color={Color.ColdGrey_500} display={Display.Inline}>
            &nbsp;&nbsp;{recipeCount}
          </Body3>
        </Body2>
      </Box>
    </Box>
  )
}
