import { useSelector } from 'react-redux'

import { getBasketRecipes } from 'selectors/basket'
import { getRecipes } from 'selectors/root'

export const useGetUnavailableRecipesForPortionSize = (portionSize: number) => {
  const recipes = useSelector(getRecipes)
  const basketRecipes = useSelector(getBasketRecipes)
  const unavailableRecipes: { recipeId: string; recipeTitle: string }[] | [] = basketRecipes
    .keySeq()
    .reduce((acc: { recipeId: string; recipeTitle: string }[], recipeId: string) => {
      const recipe = recipes.get(recipeId)
      if (!recipe.get('portionSizesOffered')?.toArray().includes(portionSize)) {
        acc.push({ recipeId, recipeTitle: recipe.get('title') })
      }

      return acc
    }, [])

  return unavailableRecipes
}
