export const minRule = 'validation.min.string.password'
export const maxRule = 'validation.max.string.password'
export const oneUpperRule = 'validation.one_uppercase_character.password'
export const oneLowerRule = 'validation.one_lowercase_character.password'
export const oneSymbolOrNumberRule = 'validation.one_symbol_or_number.password'
export const policyRule = 'validation.password_policy.password'

export const passwordCriteria = [
  { rule: minRule, message: '8 characters or more' },
  { rule: maxRule, message: 'too long' },
  {
    rule: oneUpperRule,
    message: 'at least one uppercase letter (A-Z)',
  },
  {
    rule: oneLowerRule,
    message: 'at least one lowercase letter (a-z)',
  },
  {
    rule: oneSymbolOrNumberRule,
    message: 'at least one number (0-9) or symbol',
  },
  { rule: policyRule, message: 'not a common word' },
]
