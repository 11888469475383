import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { client as routes } from 'config/routes'
import { BottomFixedContent, Card, CTA } from 'goustouicomponents'
import { GetHelpLayout2 } from '../layouts/GetHelpLayout2'
import layoutCss from '../layouts/GetHelpLayout2/GetHelpLayout2.css'
import css from './IneligibleIngredientsSameDay.css'

const IneligibleIngredientsSameDay = ({
  trackIngredientsGoToMyGousto,
}) => (
  <GetHelpLayout2 headingText="Ingredients" hasBackButton backUrl={routes.myGousto}>
    <Card
      hasLateralBordersOnSmallScreens={false}
      className={layoutCss.sideBordersWhenGetHelpLayoutHasMargins}
    >
      <Fragment>
        <h2 className={css.heading}>
          You have previously complained about this box earlier today
        </h2>
        <p className={css.text}>
          Unfortunately you can’t complain about the same box twice in one day but if you have any other issues, please let us know the next day.
        </p>
      </Fragment>
    </Card>
    <BottomFixedContent>
      <CTA
        isFullWidth
        size="small"
        onClick={() => {
          trackIngredientsGoToMyGousto()
          window.location.assign(routes.myGousto)
        }}
      >
        Go to My Gousto
      </CTA>
    </BottomFixedContent>
  </GetHelpLayout2>
)

IneligibleIngredientsSameDay.propTypes = {
  trackIngredientsGoToMyGousto: PropTypes.func.isRequired,
}

export {
  IneligibleIngredientsSameDay
}
