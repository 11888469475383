const actionTypes = {
  GET_HELP_CHECK_RECIPE_CARDS_ELIGIBILITY: 'GET_HELP_CHECK_RECIPE_CARDS_ELIGIBILITY',
  GET_HELP_CREATE_COMPLAINT: 'GET_HELP_CREATE_COMPLAINT',
  GET_HELP_LOAD_SHIPPING_ADDRESSES: 'GET_HELP_LOAD_SHIPPING_ADDRESSES',
  GET_HELP_LOAD_ORDERS: 'GET_HELP_LOAD_ORDERS',
  GET_HELP_LOAD_ORDER_AND_RECIPES_BY_IDS: 'GET_HELP_LOAD_ORDER_AND_RECIPES_BY_IDS',
  GET_HELP_LOAD_REFUND_AMOUNT: 'GET_HELP_LOAD_REFUND_AMOUNT',
  GET_HELP_LOAD_TRACKING_URL: 'GET_HELP_LOAD_TRACKING_URL',
  GET_HELP_SET_SELECTED_RECIPE_CARDS: 'GET_HELP_SET_SELECTED_RECIPE_CARDS',
  GET_HELP_SET_SELECTED_RECIPE_CARDS_ISSUES: 'GET_HELP_SET_SELECTED_RECIPE_CARDS_ISSUES',
  GET_HELP_STORE_ORDER: 'GET_HELP_STORE_ORDER',
  GET_HELP_STORE_SELECTED_ADDRESS: 'GET_HELP_STORE_SELECTED_ADDRESS',
  TRACKING: 'TRACKING',
}

const trackingKeys = {
  clickContinueAsNewCustomer: 'help_login_modal_click_continue_new',
  clickGetHelpWithThisBox: 'click_get_help_with_this_box',
  continueToSsrClick: 'continue_to_ssr_click',
  helpMassIssueIngredientAlertDisplayed: 'ssr_ingredients_supply_issues_message_displayed',
  helpPreLoginModalDisplayed: 'help_login_modal_displayed',
  openHowCanIAddAddressArticle: 'open_how_can_i_add_address_article',
  ssrClickDoneRefundAccepted: 'ssr_ingredients_done_click',
  ssrDeselectIngredient: 'ssr_deselect_ingredient',
  ssrIngredientsClickGetInTouch: 'ssr_ingredients_click_get_in_touch',
  ssrIngredientsClickGoToMyGousto: 'ssr_ingredients_click_go_to_my_gousto',
  ssrIngredientsOpenRefundArticle: 'ssr_ingredients_open_refund_article',
  ssrIngredientsReasonsConfirmed: 'ssr_ingredients_reasons_confirmed',
  ssrPrintedRecipeCardsClickCookbook: 'ssr_printed_recipe_cards_click_cookbook',
  ssrPrintedRecipeCardsClickDone: 'ssr_printed_recipe_cards_click_done',
  ssrPrintedRecipeCardsClickRecipe: 'ssr_printed_recipe_cards_click_recipe',
  ssrPrintedRecipeCardsClickViewCookbook: 'ssr_printed_recipe_cards_click_view_cookbook',
  ssrPrintedRecipeCardsConfirmOrder: 'ssr_printed_recipe_cards_confirm_order',
  ssrPrintedRecipeCardsContinue: 'ssr_printed_recipe_cards_continue',
  ssrSelectIngredient: 'ssr_select_ingredient',
  ssrClickChoosePrintedRecipeCards: 'ssr_click_choose_printed_recipe_cards',
}

export {
  actionTypes,
  trackingKeys
}
