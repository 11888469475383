import React from 'react'

type Props = {
  score: number
  onClick: () => void
  onKeyPress: () => void
}

export const StarEmpty = ({ score, onClick, onKeyPress }: Props) => (
  <svg
    onKeyPress={onKeyPress}
    onClick={onClick}
    tabIndex={0}
    role="button"
    aria-label={`${score} of 5 stars`}
    width="34"
    height="34"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    data-testid="starEmpty"
  >
    <title>{`${score} of 5 stars`}</title>
    <desc>Rating star to be clicked on to rate recipe</desc>
    <path
      d="M15 21.5875L22.725 26.25L20.675 17.4625L27.5 11.55L18.5125 10.7875L15 2.5L11.4875 10.7875L2.5 11.55L9.325 17.4625L7.275 26.25L15 21.5875Z"
      stroke="#C0C5C9"
      strokeWidth="1.5"
    />
  </svg>
)
