import React from 'react'
import {
  Box,
  Text,
  Color,
  BorderStyle,
  Icon,
  FontWeight,
  Display,
  JustifyContent,
  IconVariant,
  Space,
} from '@gousto-internal/citrus-react'

const BannerMessage = () => (
  <Box /* Citrus: https://github.com/Gousto/citrus/issues/267 */
    display={Display.Flex}
    justifyContent={JustifyContent.FlexStart}
    bg={Color.Warning_50}
    borderColor={Color.Error_900}
    borderWidth={1}
    borderStyle={BorderStyle.Solid}
    paddingH={4}
    paddingV={4}
    width="100%"
  >
    <Icon name="error" variant={IconVariant.Error} />
    <Space direction="horizontal" size={2} />
    <Box>
      <Text size={2} fontWeight={FontWeight.Bold}>
        You have existing orders set to your previous address.
      </Text>
      <Text size={1} fontWeight={FontWeight.Normal}>
        Manage your deliveries to update the address for these orders
      </Text>
    </Box>
  </Box>
)

export { BannerMessage }
