import React from 'react'

import {
  Body1,
  Col,
  Grid,
  GridVariant,
  Heading1,
  Link,
  TextAlign,
  Space,
} from '@gousto-internal/citrus-react'
import Helmet from 'react-helmet'

import { getStaticAssetURL } from '@library/static-assets'

import policy from 'config/policy'

import { CookiesPolicy } from './CookiePolicy/CookiesPolicy'

const PrivacyStatement = () => (
  <Grid variant={GridVariant.Layout}>
    <Col size={12}>
      <Helmet title={policy.head.title} meta={policy.head.meta} />
      <section id="privacy-policy">
        <Space size={4} />
        <Heading1 textAlign={TextAlign.Center}>Privacy Policy</Heading1>
        <Space size={4} />
        <Body1>
          Want to read all about how we handle your data? Check out the{' '}
          <Link href={`${getStaticAssetURL('/pdfs/Gousto_Privacy_Policy_V3.pdf')}`} target="_blank">
            Gousto Privacy Policy
          </Link>{' '}
          for everything you need to know.
        </Body1>
      </section>
      <CookiesPolicy />
    </Col>
  </Grid>
)

export { PrivacyStatement }
