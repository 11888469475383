import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const trackPromoCodeChange: CheckoutActionCreator =
  (promoCode, isCodeApplied) => (dispatch) =>
    dispatch({
      type: 'TRACKING_PROMOCODE_CHANGE',
      trackingData: {
        actionType: isCodeApplied ? 'Promocode Applied' : 'Promocode Removed',
        promoCode,
      },
    })
