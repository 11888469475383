import type { DeliveryInstruction } from './interfaces'

export const DELIVERY_INSTRUCTIONS: DeliveryInstruction[] = [
  'Front Porch',
  'Back Porch',
  'Garage',
  'Shed/Greenhouse/Outbuilding',
  'Conservatory',
  'Neighbour',
  'Other',
]
