import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import logger from 'utils/logger'

export const firePayPalError: CheckoutActionCreator = (err) => (dispatch) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  logger.error({ message: `${actionTypes.PAYPAL_ERROR} - ${err.message}`, errors: [err] })
  dispatch(trackCheckoutError(actionTypes.PAYPAL_ERROR, true, 'firePayPalError'))
  dispatch(statusActions.error(actionTypes.PAYPAL_ERROR, true))
}
