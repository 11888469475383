import React, { memo, useEffect, useState } from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router'

import { trackCheckoutNavigationLinks } from 'actions/tracking'
import {
  Breadcrumbs as BreadcrumbsEnum,
  CHECKOUT_BREADCRUMBS,
  CheckoutStepIds,
} from 'routes/Checkout/checkoutConfig'

import { FutureLink, ListItem, UnorderedList } from './Breadcrumbs.styled'

/**
 * Map of breadcrumb to step in checkout flow. User may visit different steps that belong to same breadcrumb.
 * That object allows tracking what proper step to return.
 * Exports just for tests
 */
export type BreadcrumbsToSteps = {
  [key in BreadcrumbsEnum]?: CheckoutStepIds
}

type BreadcrumbsProps = {
  /**
   * Current step breadcrumb.
   */
  currentBreadcrumb?: BreadcrumbsEnum
  /**
   * Breadcrumbs to step mapping define what exact step for a breadcrumb did user "choose".
   * E.g. for "/delivery" it can be both "Delivery" and "PayWithApplePay" steps. User selects one of them and that info
   * is stored within that mapping.
   */
  breadcrumbsToStepsMapping: BreadcrumbsToSteps
}

/**
 * Breadcrumbs component for Checkout component.
 * Previous steps user has already visited in checkout flow can be navigated to via breadcrumbs.
 */
const Breadcrumbs = ({
  currentBreadcrumb = BreadcrumbsEnum.ACCOUNT,
  breadcrumbsToStepsMapping,
}: BreadcrumbsProps) => {
  const dispatch = useDispatch()
  /**
   * Furthest step breadcrumb user has ever reached.
   * Breadcrumbs before that step would be links to previous steps.
   * That would allow user to revisit previous steps and return to "furthest" via breadcrumbs links.
   */
  const [furthestBreadcrumbIndex, setFurthestBreadcrumbIndex] = useState<number>(0)

  useEffect(() => {
    const currentBreadcrumbIndex = CHECKOUT_BREADCRUMBS.indexOf(currentBreadcrumb)
    if (furthestBreadcrumbIndex < currentBreadcrumbIndex) {
      setFurthestBreadcrumbIndex(currentBreadcrumbIndex)
    }
  }, [currentBreadcrumb, furthestBreadcrumbIndex])

  const breadcrumbs = CHECKOUT_BREADCRUMBS.map((breadcrumb: BreadcrumbsEnum, index: number) => {
    const stepId = breadcrumbsToStepsMapping[breadcrumb] as CheckoutStepIds // stepId serves as route to step

    const isFutureStepBreadcrumb = index > furthestBreadcrumbIndex
    const isNotLastItem = index < CHECKOUT_BREADCRUMBS.length - 1
    const isActive = currentBreadcrumb === breadcrumb

    const linkClickTrackingHandler = () => dispatch(trackCheckoutNavigationLinks(breadcrumb))

    return (
      <ListItem
        key={breadcrumb}
        data-testid={
          breadcrumb === currentBreadcrumb
            ? `ActiveBreadcrumb-${breadcrumb}`
            : `RegularBreadcrumb-${breadcrumb}`
        }
      >
        {isFutureStepBreadcrumb ? (
          <FutureLink size={1} color={Color.ColdGrey_600}>
            {breadcrumb}
          </FutureLink>
        ) : (
          <Link
            to={`/check-out/${stepId}`}
            onClick={linkClickTrackingHandler}
            style={{ textDecoration: 'none' }}
          >
            <Box
              borderStyle={BorderStyle.Solid}
              borderColor={Color.NeutralGrey_200}
              borderBottomWidth={isActive ? 0.5 : 0}
              paddingBottom={isActive ? 0.75 : 0}
            >
              <Text
                color={Color.Secondary_400}
                size={1}
                fontFamily={isActive ? FontFamily.Bold : FontFamily.Book}
              >
                {breadcrumb}
              </Text>
            </Box>
          </Link>
        )}
        {isNotLastItem && (
          <Box
            paddingH={1}
            color={Color.ColdGrey_600}
            display={Display.Flex}
            alignItems={AlignItems.Center}
          >
            <Icon name="chevron_right" variant={IconVariant.Inherit} />
          </Box>
        )}
      </ListItem>
    )
  })

  return (
    <Box paddingH={[2, 2, null]}>
      <UnorderedList data-testid="BreadcrumbsList">{breadcrumbs}</UnorderedList>
    </Box>
  )
}

const BreadcrumbsMemoized = memo(Breadcrumbs)

export { BreadcrumbsMemoized as Breadcrumbs }
