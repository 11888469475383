import React from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  Divider,
  FlexDirection,
  FontFamily,
  Icon,
  IconNames,
  IconVariant,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import classnames from 'classnames'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { boxSummaryVisibilityChange } from 'actions/boxSummary'
import { editBoxSizeOrDate } from 'actions/trackingKeys'
import { getBasketDate, getNumPortions } from 'selectors/basket'

import { BoxSummaryPickerContainer } from './BoxSummaryPickerContainer'
import type { BoxSummaryProps } from './__config__/boxTypes'
import { boxTypes } from './__config__/boxTypes'

import css from '../CollectionsNav.css'

const formatDateToHumanReadable = (dateString: string) => moment(dateString).format('ddd D MMM')

export const trackEditBoxSizeOrDate = (currentSelection: string) => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: editBoxSizeOrDate,
    currentSelection,
  },
})

const BoxSummaryItem = ({ iconName, itemTitle }: BoxSummaryProps) => (
  <>
    <Space direction="vertical" size={2} />
    <Box
      paddingH={2}
      paddingV={2}
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      alignItems={AlignItems.Center}
    >
      <Icon name={iconName as IconNames} />
      <Space direction="horizontal" size={2} />
      <Body2>{itemTitle}</Body2>
    </Box>
  </>
)

const BoxSummaryEditItem = () => (
  <Box
    paddingH={2}
    paddingV={2}
    paddingRight={4}
    paddingBottom={2}
    display={Display.Flex}
    flexDirection={FlexDirection.Row}
    alignItems={AlignItems.Center}
  >
    <Icon size={4} variant={IconVariant.Actionable} name="edit" />
    <Text fontFamily={FontFamily.Bold} size={[1, null, 3]} underline color={Color.Secondary_400}>
      edit
    </Text>
  </Box>
)

export const BoxSummaryPicker = () => {
  const dispatch = useDispatch()
  const numPortions = useSelector(getNumPortions)
  const basketDate = useSelector(getBasketDate)

  const { title } = boxTypes[numPortions]

  const formatedBasketDate = formatDateToHumanReadable(basketDate)

  const showBoxSummary = () => {
    dispatch(boxSummaryVisibilityChange(true, () => {}))
    dispatch(trackEditBoxSizeOrDate(title))
  }

  return (
    <Box className={classnames(css.nav)}>
      <Box paddingV={2}>
        <Divider />
      </Box>
      <BoxSummaryPickerContainer
        style={{ cursor: 'pointer' }}
        onClick={showBoxSummary}
        onKeyDown={(event) =>
          event.key === 'Enter' || event.key === ' ' ? showBoxSummary() : null
        }
        role="button"
        tabIndex={0}
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        alignItems={AlignItems.Center}
        justifyContent={[JustifyContent.SpaceBetween, null, JustifyContent.FlexStart]}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.Center}
        >
          <BoxSummaryItem iconName="meals_per_box" itemTitle={title} />
          <BoxSummaryItem iconName="delivery" itemTitle={formatedBasketDate} />
        </Box>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.Center}
        >
          <Space direction="horizontal" size={8} />
          <BoxSummaryEditItem />
        </Box>
      </BoxSummaryPickerContainer>
    </Box>
  )
}
