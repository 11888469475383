import { getSnowplowDomainUserId } from 'containers/OptimizelyRollouts/getSnowplowDomainUserId'
import { getAuthUserId } from 'selectors/auth'
import Cookies from 'utils/GoustoCookies'
import { get } from 'utils/cookieHelper2'
import logger from 'utils/logger'

import { getOptimizelyInstance, hasValidInstance, timeout } from './optimizelySDK'
import { trackExperimentInSnowplow } from './trackExperimentInSnowplow'

export const getUserIdForOptimizely = async (userId) => {
  let userIdForOptimizely = userId

  if (!userIdForOptimizely) {
    try {
      userIdForOptimizely = await getSnowplowDomainUserId()
    } catch (error) {
      logger.error({ message: error.message })
    }
  }

  return userIdForOptimizely
}

export const isOptimizelyFeatureEnabledFactory = (name) => async (dispatch, getState) => {
  const withVersionPrefixAsFalse = false
  const userId = getAuthUserId(getState())
  const sessionId = get(Cookies, 'gousto_session_id', withVersionPrefixAsFalse, false)
  const userIdForOptimizely = await getUserIdForOptimizely(userId)
  if (!userIdForOptimizely) {
    return false
  }

  const optimizelyInstance = await getOptimizelyInstance()

  await optimizelyInstance.onReady({ timeout })

  if (!hasValidInstance()) {
    return false
  }

  const featureValue = optimizelyInstance.isFeatureEnabled(name, userIdForOptimizely)

  dispatch(trackExperimentInSnowplow(name, featureValue, userId, sessionId, userIdForOptimizely))

  return featureValue
}
