import React, { Fragment } from 'react'

import {
  Box,
  Heading5,
  Icon,
  Button,
  Space,
  ButtonVariant,
  ButtonColorVariant,
  IconNames,
  Display,
  FlexDirection,
  AlignItems,
  FontFamily,
  Text,
  Color,
  DisplayPosition,
} from '@gousto-internal/citrus-react'

import { useMaxNumberOfRecipes } from './hooks/useMaxNumberOfRecipes'
import { getPropositionContent } from './propositionTileUtils'

type PropositionTileProps = {
  onClose: () => void
}

export const PropositionTile = ({ onClose }: PropositionTileProps) => {
  const maxNumRecipes: number = useMaxNumberOfRecipes()

  return (
    <Box
      position={DisplayPosition.Relative}
      paddingH={5}
      paddingV={5}
      bg={Color.White}
      maxHeight="100%"
      data-testid="proposition-tile"
    >
      <Button
        variant={ButtonVariant.Icon}
        colorVariant={ButtonColorVariant.Tertiary}
        style={{ position: 'absolute', top: '0', right: '0' }}
        onClick={() => onClose()}
      />

      <Space size={[0, 2, 4]} direction="vertical" />
      <Heading5>Key things about our menu</Heading5>
      <Space size={3} direction="vertical" />
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {getPropositionContent(maxNumRecipes).content.map((item) => (
          <li key={item.icon}>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              alignItems={AlignItems.Center}
            >
              <Icon style={{ alignSelf: 'flex-start' }} name={item.icon as IconNames} />
              <Space size={2} direction="horizontal" />
              <Box display={Display.Inline}>
                {item.text.map((text, j) => (
                  <Fragment key={item.icon}>
                    <Text size={[0, 1]} display={Display.Inline} fontFamily={FontFamily.Bold}>
                      {text.bold}
                    </Text>
                    <Text size={[0, 1]} display={Display.Inline}>
                      {text.normal}
                    </Text>
                    {j === 0 && '\u00A0'}
                  </Fragment>
                ))}
              </Box>
            </Box>
            <Space size={[2, 3, 3]} direction="vertical" />
          </li>
        ))}
      </ul>
    </Box>
  )
}
