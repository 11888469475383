import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Body2,
  Bold,
  Heading3,
  Join,
  Space,
  Modal,
  ModalButton,
  ModalHeader,
  ModalClose,
  ModalBody,
  ModalFooter,
  ButtonColorVariant,
  ButtonSize,
  Checkbox,
  useModal,
  withCitrusTheme,
  Box,
  AlignItems,
  Display,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import Loading from 'Loading'
import { Loader } from 'goustouicomponents'

const LoadingWithPadding = withCitrusTheme(Loading, theme => ({
  padding: theme.spacing(10),
}))

export const MultiSkipScreen = ({
  alreadySkippedBoxesCount,
  onClose,
  newOrders,
  handleSkipBoxes,
  toggleSkipBox,
  selectedOrderIds,
  modalName,
  isModalOpen,
  isLoadingOrders,
}) => {
  const { closeCurrentModal } = useModal()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSaveClick = useCallback(() => {
    setIsSubmitting(true)
    handleSkipBoxes()
  }, [setIsSubmitting, handleSkipBoxes])

  const handleClose = useCallback(() => {
    onClose()
    closeCurrentModal()
  }, [onClose, closeCurrentModal])

  const handleInputChange = useCallback((event) => {
    const { id } = event.target
    const shouldSkip = event.target.checked

    toggleSkipBox(id, shouldSkip)
  }, [toggleSkipBox])

  const isSkipBoxesDisabled = isSubmitting || selectedOrderIds.length === 0

  return (
    <Modal
      isOpen={isModalOpen}
      name={modalName}
      description="Skip multiple boxes"
    >
      <ModalClose onClose={handleClose} />
      <ModalHeader>
        <Heading3>Taking a short break?</Heading3>
      </ModalHeader>
      {isLoadingOrders ? (
        <Box
          alignItems={AlignItems.Center}
          display={Display.Flex}
          justifyContent={JustifyContent.Center}
        >
          <LoadingWithPadding />
        </Box>
      ) : (
        <ModalBody>
          <Join with={<Space size={4} />}>
            <Body2>
              Select the delivery days you would like to skip
            </Body2>

            {!!alreadySkippedBoxesCount && (
              <Body2 data-testing="multi-skip-subheading">
                {'You\'ve already skipped '}
                <Bold>{`${alreadySkippedBoxesCount} `}</Bold>
                {`box${alreadySkippedBoxesCount > 1 ? 'es' : ''}`}
              </Body2>
            )}

            <Join with={<Space size={3} />}>
              {newOrders.map(({ id, canSkip, deliveryDate }) => (
                <Checkbox
                  key={id}
                  id={id}
                  checked={selectedOrderIds.includes(id)}
                  disabled={!canSkip}
                  onChange={handleInputChange}
                  paddingH={3}
                  paddingV={3}
                  outline
                >
                  {deliveryDate}
                </Checkbox>
              ))}
            </Join>
          </Join>
        </ModalBody>
      )}

      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Large}
          disabled={isSkipBoxesDisabled}
          onClick={handleSaveClick}
          style={{
            paddingBottom: isSubmitting ? '1rem' : null,
            paddingTop: isSubmitting ? '1rem' : null,
          }}
        >
          {isSubmitting ? <Loader /> : 'Skip deliveries'}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

const newOrderShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isProjected: PropTypes.bool.isRequired,
  canSkip: PropTypes.bool.isRequired,
  deliveryDay: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  deliveryDayId: PropTypes.string.isRequired
})

MultiSkipScreen.defaultProps = {
  isModalOpen: false,
  isLoadingOrders: false,
}

MultiSkipScreen.propTypes = {
  alreadySkippedBoxesCount: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  newOrders: PropTypes.arrayOf(newOrderShape).isRequired,
  handleSkipBoxes: PropTypes.func.isRequired,
  toggleSkipBox: PropTypes.func.isRequired,
  selectedOrderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  modalName: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  isLoadingOrders: PropTypes.bool,
}
