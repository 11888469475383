export const getPropositionContent = (noRecipes: number) => ({
  title: 'Key things about our menu',
  content: [
    {
      icon: 'offer_pound',
      text: [
        { normal: `Choose 2 to ${noRecipes} recipes: add more recipes for the` },
        { bold: 'best price per portion' },
      ],
    },
    {
      icon: 'meals_per_box',
      text: [{ bold: 'Over 75 recipes' }, { normal: 'changing weekly' }],
    },
    {
      icon: 'play_pause',
      text: [{ bold: 'No lock in:' }, { normal: 'pause or cancel anytime' }],
    },
  ],
})
