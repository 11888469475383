import React from 'react'

import { RootStateOrAny, useSelector } from 'react-redux'

import { useFetchRecipe } from 'routes/Menu/components/Detail/hooks'
import { getBasketDate, getBasketMenuUuid, getNumPortions } from 'selectors/basket'
import { getCoreRecipeIdByUuid } from 'selectors/recipe'

import { DetailContent } from '../../Detail/DetailContent'

interface RecipeDetailProps {
  onClose: () => void
  recipeId: string
}

export const RecipeDetail = ({ onClose, recipeId }: RecipeDetailProps) => {
  const deliveryDate = useSelector(getBasketDate)
  const menuId = useSelector(getBasketMenuUuid)
  const numPortions = useSelector(getNumPortions)
  const recipeCoreId = useSelector((state: RootStateOrAny) =>
    getCoreRecipeIdByUuid(state, recipeId),
  )

  const { loading, recipe, error, reload } = useFetchRecipe(
    menuId,
    recipeId,
    numPortions,
    deliveryDate,
  )

  if (!recipe) {
    return null
  }

  return (
    <DetailContent
      id={recipeCoreId}
      title={recipe.name}
      loading={loading}
      error={error}
      recipe={recipe}
      reload={reload}
      onClose={onClose}
    />
  )
}
